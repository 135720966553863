import React from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useForm from 'hooks/useLocalizedSimpleForm';
import { PushValidators } from 'validators/ExperienceCanvas/push.validator';
import { isUrlValid } from 'validators/ExperienceCanvas/common.validator';
import { TFormProps } from 'components/ExperienceCanvas/types';
import { PushFormClassPrefix as CLASS_PREFIX } from 'components/ExperienceCanvas/constants';
import { FlightCheckbox, FlightTextInput } from '@flybits/design-system';

const WeblinkForm = ({ refId, activeLang, defaultLang }: TFormProps) => {
  const dispatch = useDispatch();
  const push = useSelector((state) => {
    return state.te.push.byRefId[refId];
  });

  const urlInState = push.url?.[activeLang];

  const {
    formData: payloadData,
    updateField,
    toggleError,
  } = useForm({
    data: {
      url: { value: urlInState ?? '' },
      allowNonHttps: { value: !urlInState || isUrlValid(urlInState) ? false : true },
    },
    validators: PushValidators,
    lang: activeLang,
    defaultLang,
  });

  const handleBlur = () => {
    toggleError('url');
    dispatch({
      type: 'UPDATE_PUSH_SIMPLE',
      payload: {
        refId: push.refId,
        fields: { url: { [activeLang]: payloadData.url.value } },
      },
    });
  };

  // word to the wise: blur fires and stops propgation for some reason
  const onClickUrlBox = () => {
    const currentValue = payloadData.allowNonHttps.value;

    updateField('allowNonHttps', !currentValue);
    toggleError('url');
  };

  return (
    <div className={`${CLASS_PREFIX}__container`}>
      <FlightTextInput
        label={'Weblink URL'}
        hasError={payloadData.url.showError}
        errorMessage={payloadData.url.error}
        value={payloadData.url.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField('url', e.target.value)}
        onBlur={() => {
          handleBlur();
        }}
        width="100%"
      />
      <div className={`${CLASS_PREFIX}__row`}>
        <div className={`${CLASS_PREFIX}__label`}>
          <p className={`${CLASS_PREFIX}__label-primary`}>Allow non-https or custom URL</p>
          <p className={`${CLASS_PREFIX}__label-secondary`}>
            Please ensure that your app can parse the URL appropriately
          </p>
        </div>
        <FlightCheckbox
          className={`${CLASS_PREFIX}__right`}
          checkState={payloadData.allowNonHttps.value ? 'SELECTED' : 'UNSELECTED'}
          onSelect={onClickUrlBox}
        />
      </div>
    </div>
  );
};

export default WeblinkForm;
