/* eslint-disable @typescript-eslint/no-unused-vars */
import { Step, WebhookAction } from 'interface/experience/experience.interface';
import { SetWebhookAction, WebhookState, WebhookStateItem } from './webhook.type';
import { createReducer, getWebhookActionFromStep } from 'store/helper';

import { SetInstanceAction, SetTemplateAction } from 'store/templated-experience/templated-experience.type';
import { WEBHOOK_INIT } from 'constants/webhook.constants';

const initialState: WebhookState = {
  byRefId: {},
  allRefId: [],
  stepRefIdMap: {},
};

export default createReducer(initialState, {
  SET_TEMPLATE: setTemplate,
  SET_INSTANCE: setInstance,
  SET_WEBHOOK: setWebhook,
  CLEAR_JOURNEY: clearJourney,
});

function clearJourney() {
  return initialState;
}

function flattenWebhookAction(webhook: WebhookAction, isTemplate = false): WebhookStateItem {
  const { actionType, refId, isOptional, payload = WEBHOOK_INIT } = webhook;

  const retVal = {
    actionType,
    refId,
    isOptional: isTemplate ? isOptional : false,
    ...payload,
  };

  return retVal;
}

function setTemplate(state: WebhookState, action: SetTemplateAction): WebhookState {
  const preserveInstance = action.payload.preserveInstance;
  const byRefId: WebhookState['byRefId'] = preserveInstance ? state.byRefId : {};
  const allRefId: WebhookState['allRefId'] = preserveInstance ? state.allRefId : [];
  const stepRefIdMap: WebhookState['stepRefIdMap'] = { ...state.stepRefIdMap };

  action.payload?.template?.steps.forEach((step: Step, idx: number) => {
    const webhook = getWebhookActionFromStep(step);

    if (webhook) {
      if (preserveInstance) {
        if (byRefId[webhook.refId]) {
          byRefId[webhook.refId] = {
            ...state.byRefId[webhook.refId],
            isOptional: webhook.isOptional,
          };
        } else {
          byRefId[webhook.refId] = flattenWebhookAction(webhook, true);
          allRefId.push(webhook.refId);
          stepRefIdMap[idx] = webhook.refId;
        }
      } else {
        byRefId[webhook.refId] = flattenWebhookAction(webhook, true);
        allRefId.push(webhook.refId);
        stepRefIdMap[idx] = webhook.refId;
      }
    }
  });

  return {
    byRefId,
    allRefId,
    stepRefIdMap,
  };
}

function setInstance(state: WebhookState, action: SetInstanceAction): WebhookState {
  if (!action.payload.updateNewReducer) return state;

  const byRefId: WebhookState['byRefId'] = { ...state.byRefId };
  const allRefId: WebhookState['allRefId'] = [...state.allRefId];
  const stepRefIdMap: WebhookState['stepRefIdMap'] = { ...state.stepRefIdMap };

  action.payload?.instance?.steps.forEach((step: Step, idx: number) => {
    const webhook = getWebhookActionFromStep(step);

    if (webhook) {
      byRefId[webhook.refId] = flattenWebhookAction(webhook, false);
      allRefId.push(webhook.refId);
      stepRefIdMap[idx] = webhook.refId;
    }
  });

  return {
    ...state,
    byRefId,
    allRefId,
  };
}

function setWebhook(state: WebhookState, action: SetWebhookAction): WebhookState {
  const { refId, webhook } = action.payload;
  const newWebhookStateItem = { ...state.byRefId[refId], ...webhook };

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [refId]: newWebhookStateItem,
    },
  };
}
