import React from 'react';
import { TShapeProps } from '../types';

function ConnectionLineIcon({
  stroke = 'currentColor',
  strokeDasharray = '0',
  className = '',
  width = 80,
}: TShapeProps) {
  return (
    <svg
      className={className}
      fill="none"
      width={width}
      height="2"
      viewBox={`0 0 ${width} 2`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1"
        x2="80"
        y2="1"
        stroke={stroke}
        strokeWidth="2"
        strokeDasharray={strokeDasharray}
        strokeDashoffset="2"
      />
    </svg>
  );
}

export default ConnectionLineIcon;
