/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseAPI from './base.api';

export default class AOAPI extends BaseAPI {
  private ROUTES = {
    getMetrics: '/nestor/ao-learner/training/metrics/:id',
    postMetrics: '/nestor/ao-learner/training/metrics',
    getMetadata: '/nestor/ao-learner/journey/metadata/:id',
    startTraining: '/nestor/ao-learner/training/journey/:id',
    cancelTraining: '/nestor/ao-learner/training/cancel/:id',
    deployModel: '/nestor/ao-learner/training/deploy/:id',
  };

  params = {
    limit: 10000,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc',
  };

  getMetrics(id: string): Promise<any> {
    return this.GET(this.buildURL(this.ROUTES.getMetrics, { id: id }));
    // Enable below line for testing as all instances will by default have no data to show curve
    // return this.GET(this.buildURL(this.ROUTES.getMetrics, {id: 'F4F86E9E-0AAD-4C80-B38C-B0382DCC9BDA'}))
  }

  postMetrics(body: any): Promise<any> {
    return this.POST(this.ROUTES.postMetrics, body);
  }

  getMetadata(id?: string, params: any = this.params): Promise<any> {
    return this.GET(this.buildURL(this.ROUTES.getMetadata, { id: id || '' }, params));
  }

  startTraining(id: string): Promise<any> {
    return this.POST(this.buildURL(this.ROUTES.startTraining, { id: id }));
  }

  cancelTraining(id: string): Promise<any> {
    return this.POST(this.buildURL(this.ROUTES.cancelTraining, { id: id }));
  }

  deployModel(id: string): Promise<any> {
    return this.POST(this.buildURL(this.ROUTES.deployModel, { id: id }));
  }
}
