import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function NotFoundIndex() {
  const history = useHistory();

  useEffect(() => {
    history.replace('404');
  }, [history]);

  return <div> You entered an invalid Route </div>;
}
