import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import cronParser from 'cron-parser';
import cronstrue from 'cronstrue';
import { useDispatch } from 'react-redux';
import {
  FlightNumberInput,
  FlightRadioButton,
  FlightSelect,
  FlightTextInput,
  FlightTimeZone,
} from '@flybits/design-system';
import { Option, SelectOption } from 'types/common';
import { SchedulerStateItem } from 'store/rule/rule.type';
import CalendarImage from 'pages/ExperienceCanvas/assets/images/Calendar';
import { CLASSES as PARENT_CLASSES } from '../classes';
import './CronExpressionSection.scss';
import { getTimezoneList, guessUserTimezone } from 'helpers/templated-experience.helper';

type TCronExpressionSectionProps = {
  stepIdx: number;
  startScheduler: SchedulerStateItem;
  endScheduler: SchedulerStateItem;
};

type TDuration = {
  value: string;
  unit: (typeof unitOfTimeOptions)[number];
  errorMsg: string;
};

type FrequencyOption = SelectOption & {
  // TODO: max duration in hours, build support for other keys
  maxDuration?: number;
};

const DurationErrorMsg = {
  MAX_OVER: 'Using a value past the unit maximum may result in unintentional behaviour.',
  OVERLAP: 'This duration configuration will cause overlaps!',
  CROSS_BOUNDARY: 'This configuration crosses from PM to AM and is not fully supported.',
};

const frequencyTypeOptions: FrequencyOption[] = [
  { key: 'hour', name: 'Hourly' },
  { key: 'day', name: 'Daily' },
  { key: 'week', name: 'Weekly' },
  { key: 'month', name: 'Monthly' },
  { key: 'year', name: 'Yearly' },
];

const hourlyFrequencyOptions: FrequencyOption[] = [
  { key: '*', name: '1 Hour', maxDuration: 0 },
  { key: '*/2', name: '2 Hours', maxDuration: 1 },
  { key: '*/3', name: '3 Hours', maxDuration: 2 },
  { key: '*/4', name: '4 Hours', maxDuration: 3 },
  { key: '*/6', name: '6 Hours', maxDuration: 5 },
  { key: '*/12', name: '12 Hours', maxDuration: 11 },
];

const dailyFrequencyOptions: FrequencyOption[] = [
  { key: '*', name: 'Day' },
  { key: '1-5', name: 'Weekday' },
  { key: '0,6', name: 'Weekend' },
];

const weeklyFrequencyOptions: FrequencyOption[] = [
  { key: '1', name: 'Mon' },
  { key: '2', name: 'Tue' },
  { key: '3', name: 'Wed' },
  { key: '4', name: 'Thu' },
  { key: '5', name: 'Fri' },
  { key: '6', name: 'Sat' },
  { key: '0', name: 'Sun' },
];

const monthlyFrequencyOptions: FrequencyOption[] = [
  { key: '1', name: 'Jan' },
  { key: '2', name: 'Feb' },
  { key: '3', name: 'Mar' },
  { key: '4', name: 'Apr' },
  { key: '5', name: 'May' },
  { key: '6', name: 'Jun' },
  { key: '7', name: 'Jul' },
  { key: '8', name: 'Aug' },
  { key: '9', name: 'Sep' },
  { key: '10', name: 'Oct' },
  { key: '11', name: 'Nov' },
  { key: '12', name: 'Dec' },
];

const monthlyDayOptions: FrequencyOption[] = [
  { key: '1', name: 'First day of the month' },
  { key: 'L', name: 'Last day of the month' },
  { key: '*', name: '1 Day' },
  { key: '*/2', name: '2 Days' },
  { key: '*/3', name: '3 Days' },
  { key: '*/4', name: '4 Days' },
  { key: '*/6', name: '6 Days' },
];

const unitOfTimeOptions = [
  { key: '', name: 'Select a duration', max: 0, divisor: 1 },
  { key: 'hour', name: 'Hours', max: 23 }, // only hours for now
];

const initialHourlyData: { frequency: FrequencyOption; minute: string } = {
  frequency: hourlyFrequencyOptions[0],
  minute: '0',
};

const initialDailyData: { frequency: FrequencyOption; time: string } = {
  frequency: dailyFrequencyOptions[0],
  time: '00:00',
};

const initialWeeklyData: { frequency: string[]; time: string } = { frequency: [], time: '00:00' };

const initialMonthlyData: { frequency: string[]; days: FrequencyOption; time: string } = {
  frequency: [],
  days: monthlyDayOptions[0],
  time: '00:00',
};

const initialYearlyData: { years: string[]; months: string[]; days: string[]; time: string } = {
  years: [],
  months: [],
  days: [],
  time: '00:00',
};

const tzList: Option[] = getTimezoneList().map((tz) => ({
  key: tz.key,
  name: tz.name,
  value: tz.offset,
}));

const MAIN_CLASS = 'cron-expression-section';
const CLASSES = {
  RADIO: `${MAIN_CLASS}__radio`,
  RADIO_LIST: `${MAIN_CLASS}__radio-list`,
  SUMMARY: `${MAIN_CLASS}__summary`,
  DATE_LIST: `${MAIN_CLASS}__date-list`,
  DATE_LIST_TWO_COLUMNS: `${MAIN_CLASS}__date-list--is-two-columns`,
  DATE: `${MAIN_CLASS}__date`,
  DATE_ELLIPSIS: `${MAIN_CLASS}__date ${MAIN_CLASS}__date--is-ellipsis`,
  CALENDAR_IMAGE: `${MAIN_CLASS}__calendar-image`,
  ERROR: `${MAIN_CLASS}__error`,
};

const CronExpressionSection: React.FC<TCronExpressionSectionProps> = ({ startScheduler, endScheduler, stepIdx }) => {
  const guessedTimezone = guessUserTimezone();
  const [hourlyData, setHourlyData] = useState(initialHourlyData);
  const [dailyData, setDailyData] = useState(initialDailyData);
  const [weeklyData, setWeeklyData] = useState(initialWeeklyData);
  const [monthlyData, setMonthlyData] = useState(initialMonthlyData);
  const [yearlyData, setYearlyData] = useState(initialYearlyData);
  const [startCronExpression, setStartCronExpression] = useState(startScheduler?.cronExpression || '0 * * * *');
  const [endCronExpression, setEndCronExpression] = useState(endScheduler?.cronExpression || '');
  const [repeatFrequencyType, setRepeatFrequencyType] = useState<FrequencyOption>(frequencyTypeOptions[0]);
  const [selectedTimezone, setSelectedTimezone] = useState<Option | undefined>(
    tzList.find(
      (tz) =>
        tz.name.split('(')?.[0]?.trim().replaceAll(' ', '_') === startScheduler?.timezone?.name ||
        tz.name === guessedTimezone,
    ),
  );
  const [hasStartEnd, setHasStartEnd] = useState(!!endScheduler?.cronExpression);
  const [duration, setDuration] = useState<TDuration>({
    value: '',
    errorMsg: '',
    unit: !!endScheduler?.cronExpression ? unitOfTimeOptions[1] : unitOfTimeOptions[0],
  });
  const durationMax = useMemo(() => {
    const max = duration.unit.max || 23;
    const startCronParts = startCronExpression.split(' ');
    const hours = startCronParts[1];

    if (hours.includes('*')) {
      const denominator = +hours.replace(/\D/g, '') - 1;
      return denominator > 0 ? denominator : 0;
    }

    return Math.min(max, 23 - +hours);
  }, [duration.unit, startCronExpression]);
  const dispatch = useDispatch();

  const handleFrequencyTypeChange = (option: FrequencyOption) => {
    setRepeatFrequencyType(option);
    setHasStartEnd(false);
    setDuration({
      value: '',
      errorMsg: '',
      unit: unitOfTimeOptions[0],
    });
    setEndCronExpression('');
    switch (option.key) {
      case 'hour': {
        const cronExpression = `${initialHourlyData.minute} ${initialHourlyData.frequency.key} * * *`;
        setHourlyData(initialHourlyData);
        setStartCronExpression(cronExpression);
        break;
      }
      case 'day': {
        const momentTime = moment(initialDailyData.time, 'HH:mm');
        const cronExpression = `${momentTime.minutes()} ${momentTime.hours()} * * ${initialDailyData.frequency.key}`;
        setDailyData(initialDailyData);
        setStartCronExpression(cronExpression);
        break;
      }
      case 'week': {
        const momentTime = moment(initialWeeklyData.time, 'HH:mm');
        const cronExpression = `${momentTime.minutes()} ${momentTime.hours()} * * ${
          initialWeeklyData.frequency.join(',') || '*'
        }`;
        setWeeklyData(initialWeeklyData);
        setStartCronExpression(cronExpression);
        break;
      }
      case 'month':
        const momentTime = moment(initialMonthlyData.time, 'HH:mm');
        const cronExpression = `${momentTime.minutes()} ${momentTime.hours()} ${initialMonthlyData.days.key} ${
          initialMonthlyData.frequency.join(',') || '*'
        } *`;
        setMonthlyData(initialMonthlyData);
        setStartCronExpression(cronExpression);
        break;
      case 'year': {
        const momentTime = moment(initialYearlyData.time, 'HH:mm');
        const cronExpression = `${momentTime.minutes()} ${momentTime.hours()} ${
          initialYearlyData.days.join(',') || '*'
        } ${initialYearlyData.months.join(',') || '*'} * ${initialYearlyData.years.join(',')}`;
        setYearlyData(initialYearlyData);
        setStartCronExpression(cronExpression);
        break;
      }
      default:
        setStartCronExpression('0 * * * *');
    }
  };

  const handleHourlyFrequencyChange = (option: FrequencyOption) => {
    setHourlyData((prevState) => ({ ...prevState, frequency: option }));
    setStartCronExpression(`${hourlyData.minute} ${option.key} * * *`);
  };

  const handleHourlyMinuteChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setHourlyData((prevState) => ({ ...prevState, minute: value }));
    setStartCronExpression(`${value} ${hourlyData.frequency.key} * * *`);
  };

  const handleDailyFrequencyChange = (option: FrequencyOption) => {
    setDailyData((prevState) => ({ ...prevState, frequency: option }));
    const momentTime = moment(dailyData.time, 'HH:mm');
    setStartCronExpression(`${momentTime.minutes()} ${momentTime.hours()} * * ${option.key}`);
  };

  const handleTimeChange = (evt: ChangeEvent<HTMLInputElement> | string) => {
    const value = typeof evt === 'string' ? evt : evt.target.value;
    const momentTime = moment(value, 'HH:mm');
    switch (repeatFrequencyType.key) {
      case 'day':
        setDailyData((prevState) => ({ ...prevState, time: value }));
        setStartCronExpression(`${momentTime.minutes()} ${momentTime.hours()} * * ${dailyData.frequency.key}`);
        break;
      case 'week':
        setWeeklyData((prevState) => ({ ...prevState, time: value }));
        setStartCronExpression(
          `${momentTime.minutes()} ${momentTime.hours()} * * ${weeklyData.frequency.join(',') || '*'}`,
        );
        break;
      case 'month':
        setMonthlyData((prevState) => ({ ...prevState, time: value }));
        setStartCronExpression(
          `${momentTime.minutes()} ${momentTime.hours()} ${monthlyData.days.key} ${
            monthlyData.frequency.join(',') || '*'
          } *`,
        );
        break;
      case 'year':
        setYearlyData((prevState) => ({ ...prevState, time: value }));
        setStartCronExpression(
          `${momentTime.minutes()} ${momentTime.hours()} ${yearlyData.days.join(',') || '*'} ${
            yearlyData.months.join(',') || '*'
          } * ${yearlyData.years.join(',') || ''}`,
        );
    }
  };

  const handleWeeklyFrequencyChange = (value: string | string[]) => {
    const checkedFrequencies = typeof value === 'string' ? [...weeklyData.frequency] : [...value];

    if (typeof value === 'string') {
      if (checkedFrequencies.includes(value)) {
        checkedFrequencies.splice(checkedFrequencies.indexOf(value), 1);
      } else {
        checkedFrequencies.push(value);
      }
    }
    const momentTime = moment(weeklyData.time, 'HH:mm');
    setWeeklyData((prevState) => ({ ...prevState, frequency: checkedFrequencies }));
    setStartCronExpression(`${momentTime.minutes()} ${momentTime.hours()} * * ${checkedFrequencies.join(',') || '*'}`);
  };

  const handleMonthlyDaysChange = (option: FrequencyOption) => {
    setMonthlyData((prevState) => ({ ...prevState, days: option }));
    const momentTime = moment(monthlyData.time, 'HH:mm');
    setStartCronExpression(
      `${momentTime.minutes()} ${momentTime.hours()} ${option.key} ${monthlyData.frequency.join(',') || '*'} *`,
    );
  };

  const handleMonthlyFrequencyChange = (value: string | string[]) => {
    const checkedFrequencies = typeof value === 'string' ? [...monthlyData.frequency] : [...value];

    if (typeof value === 'string') {
      if (checkedFrequencies.includes(value)) {
        checkedFrequencies.splice(checkedFrequencies.indexOf(value), 1);
      } else {
        checkedFrequencies.push(value);
      }
    }

    const momentTime = moment(monthlyData.time, 'HH:mm');
    setMonthlyData((prevState) => ({ ...prevState, frequency: checkedFrequencies }));
    setStartCronExpression(
      `${momentTime.minutes()} ${momentTime.hours()} ${monthlyData.days.key} ${checkedFrequencies.join(',') || '*'} *`,
    );
  };

  const handleYearlyFrequencyChange = (value: string) => {
    const momentTime = moment(yearlyData.time, 'HH:mm');
    const years = value.split(',').map((y) => y.trim());

    setYearlyData((prevState) => ({ ...prevState, years }));

    setStartCronExpression(
      `${momentTime.minutes()} ${momentTime.hours()} ${yearlyData.days.join(',') || '*'} ${
        yearlyData.months.join(',') || '*'
      } * ${years.filter((y) => y.match(/(^(20[0-9]{2})|(20[0-9]{2}[-]20[0-9]{2}))$/) != null).join(',')}`,
    );
  };

  const handleYearlyMonthsChange = (value: string) => {
    const checkedMonths = [...yearlyData.months];

    if (checkedMonths.includes(value)) {
      checkedMonths.splice(checkedMonths.indexOf(value), 1);
    } else {
      checkedMonths.push(value);
    }

    const momentTime = moment(yearlyData.time, 'HH:mm');
    setYearlyData((prevState) => ({ ...prevState, months: checkedMonths }));
    setStartCronExpression(
      `${momentTime.minutes()} ${momentTime.hours()} ${yearlyData.days.join(',') || '*'} ${
        checkedMonths.join(',') || '*'
      } * ${yearlyData.years.join(',') || ''}`,
    );
  };

  const handleYearlyDaysChange = (value: string) => {
    const checkedDays = [...yearlyData.days];

    if (checkedDays.includes(value)) {
      checkedDays.splice(checkedDays.indexOf(value), 1);
    } else {
      checkedDays.push(value);
    }

    const momentTime = moment(yearlyData.time, 'HH:mm');
    setYearlyData((prevState) => ({ ...prevState, days: checkedDays }));
    setStartCronExpression(
      `${momentTime.minutes()} ${momentTime.hours()} ${checkedDays.join(',') || '*'} ${
        yearlyData.months.join(',') || '*'
      } * ${yearlyData.years.join(',') || ''}`,
    );
  };

  const handleHasStartEndChange = (value: string) => {
    setHasStartEnd(value === 'specific-period');
    if (value === 'specific-moment') {
      setDuration({
        value: '',
        errorMsg: '',
        unit: unitOfTimeOptions[0],
      });
      setEndCronExpression('');
      dispatch({
        type: 'CLEAR_SCHEDULE',
        payload: {
          stepIdx,
          type: 'end',
        },
      });
    }
  };

  const handleDurationChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setDuration((prev) => {
      const unit = prev.unit.key === '' ? unitOfTimeOptions[1] : prev.unit;
      return {
        value: evt.target.value,
        unit,
        errorMsg: +evt.target.value > unit.max ? DurationErrorMsg.MAX_OVER : '',
      };
    });
  };

  const handleUnitOfTimeChange = (option: (typeof unitOfTimeOptions)[number]) => {
    setDuration((prev) => ({
      ...prev,
      unit: option,
      errorMsg: +prev.value > option.max ? DurationErrorMsg.MAX_OVER : '',
    }));
  };

  const generateEndCronExpression = (
    repeatFrequencyType: FrequencyOption,
    startCronExpression: string,
    duration: string,
    unitOfTime: SelectOption,
  ) => {
    const startCronParts = startCronExpression.split(' ');
    const endCronParts = [...startCronParts];

    switch (repeatFrequencyType.key) {
      case 'hour': {
        endCronParts[1] = '';
        const hour = startCronParts[1].split('/')[1] || 1;
        let sum = 0;
        while (sum < 24) {
          if (sum + +duration < 24) endCronParts[1] += `${sum + +duration},`;
          sum += +hour;
        }
        endCronParts[1] = endCronParts[1].slice(0, -1);
        break;
      }
      case 'day':
      case 'week':
      case 'month':
      case 'year':
        if (unitOfTime.key === 'hour') {
          const endHour = +startCronParts[1] + +duration;
          if (endHour > 23)
            setDuration((prev) => ({
              ...prev,
              errorMsg:
                prev.errorMsg === DurationErrorMsg.CROSS_BOUNDARY || !prev.errorMsg
                  ? DurationErrorMsg.CROSS_BOUNDARY
                  : prev.errorMsg,
            }));
          endCronParts[1] = `${endHour % 24}`;
        }
        break;
      default:
        return '';
    }

    const endCronExpression = endCronParts.join(' ');

    return endCronExpression;
  };

  const uncapitalizeFirstLetter = (text: string) => text.charAt(0).toLocaleLowerCase() + text.slice(1);

  const isYearValid = (currentYear: number, cronYear: string) => {
    if (cronYear.includes(',')) {
      const years = cronYear.split(',').map(Number);
      return years.includes(currentYear);
    } else if (cronYear.includes('-')) {
      const [start, end] = cronYear.split('-').map(Number);
      return currentYear >= start && currentYear <= end;
    } else {
      return currentYear === +cronYear;
    }
  };

  // TODO: Optimize
  // this is a heavy function to run every time input change fires ...
  const executionDates = useMemo(() => {
    const count = 30;
    if (!startCronExpression) return null;
    try {
      let fakeCount = count + 1;
      const dates = [];
      const startCronWithoutYear = startCronExpression.split(' ').slice(0, 5).join(' ');
      const endCronWithoutYear = endCronExpression ? endCronExpression.split(' ').slice(0, 5).join(' ') : null;
      const startYear = startCronExpression.split(' ')[5] || null;
      const startInterval = cronParser.parseExpression(startCronWithoutYear);
      const endInterval = endCronWithoutYear ? cronParser.parseExpression(endCronExpression) : null;
      let previousEndMoment: moment.Moment | null = null;
      let errorMsg = '';
      while (fakeCount-- > 0) {
        try {
          const startMomentDate = moment(startInterval.next().toDate());

          if (startYear && !isYearValid(startMomentDate.year(), startYear)) {
            continue;
          }

          let endMomentDate = endInterval ? moment(endInterval.next().toDate()) : null;
          while (endInterval && endMomentDate && endMomentDate < startMomentDate) {
            endMomentDate = moment(endInterval.next().toDate());
          }

          if (previousEndMoment && !errorMsg && previousEndMoment.isSameOrAfter(startMomentDate))
            errorMsg = DurationErrorMsg.OVERLAP;
          previousEndMoment = endMomentDate;
          dates.push(
            <li className={CLASSES.DATE} key={startMomentDate.unix()}>
              {startMomentDate.format('DD/MM/YYYY hh:mm A')}
              {endMomentDate && ` - ${endMomentDate.format('hh:mm A')}`}
            </li>,
          );
        } catch (e) {
          break;
        }
      }
      setDuration((prev) => ({
        ...prev,
        errorMsg: prev.errorMsg === DurationErrorMsg.OVERLAP || !prev.errorMsg ? errorMsg : prev.errorMsg,
      }));

      if (dates.length > count) {
        dates.splice(dates.length - 1, 1);
        dates.push(
          <li className={CLASSES.DATE_ELLIPSIS} key="ellipsis">
            ...
          </li>,
        );
      }
      return dates;
    } catch (e) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCronExpression, endCronExpression, cronParser]);

  useEffect(() => {
    if (startCronExpression && duration.value && duration.unit) {
      const newEndCronExpression = generateEndCronExpression(
        repeatFrequencyType,
        startCronExpression,
        duration.value,
        duration.unit,
      );
      setEndCronExpression(newEndCronExpression);

      if (newEndCronExpression) {
        dispatch({
          type: 'UPDATE_SCHEDULE',
          payload: {
            stepIdx,
            type: 'end',
            cronExpression: newEndCronExpression || undefined,
            timezone: {
              name: selectedTimezone?.name.split('(')?.[0]?.trim().replaceAll(' ', '_'),
            },
          },
        });
      }
    }
  }, [repeatFrequencyType, startCronExpression, duration.value, duration.unit, stepIdx, selectedTimezone, dispatch]);

  useEffect(() => {
    if (!startCronExpression) return;

    dispatch({
      type: 'UPDATE_SCHEDULE',
      payload: {
        stepIdx,
        type: 'start',
        cronExpression: startCronExpression,
        timezone: {
          name: selectedTimezone?.name.split('(')?.[0]?.trim().replaceAll(' ', '_'),
        },
      },
    });
  }, [stepIdx, startCronExpression, selectedTimezone, dispatch]);

  // hydrate start data from cron expression
  useEffect(() => {
    if (!startScheduler?.cronExpression) return;
    const cronParts = startScheduler?.cronExpression.split(' ');

    if (cronParts[1].includes('*') && cronParts[2] === '*' && cronParts[3] === '*' && cronParts[4] === '*') {
      // it is hourly
      setRepeatFrequencyType(frequencyTypeOptions[0]);
      setHourlyData({
        frequency: hourlyFrequencyOptions.find((hf) => hf.key === cronParts[1]) || hourlyFrequencyOptions[0],
        minute: cronParts[0],
      });
    } else if (cronParts[3] === '*' && (cronParts[4] === '*' || cronParts[4] === '0,6' || cronParts[4].includes('-'))) {
      // it is daily
      setRepeatFrequencyType(frequencyTypeOptions[1]);
      setDailyData({
        frequency: dailyFrequencyOptions.find((df) => df.key === cronParts[4]) || dailyFrequencyOptions[0],
        time: `${cronParts[1].padStart(2, '0')}:${cronParts[0].padStart(2, '0')}`,
      });
    } else if ((['1', 'L'].includes(cronParts[2]) || cronParts[2].includes('*')) && cronParts[4] === '*') {
      // it is monthly
      setRepeatFrequencyType(frequencyTypeOptions[3]);
      setMonthlyData({
        frequency: cronParts[3].split(',') || [],
        days: monthlyDayOptions.find((md) => md.key === cronParts[2]) || monthlyDayOptions[0],
        time: `${cronParts[1].padStart(2, '0')}:${cronParts[0].padStart(2, '0')}`,
      });
    } else if (cronParts[4] === '*' && (!cronParts[2].includes('*') || !!cronParts[5])) {
      // it is yearly
      setRepeatFrequencyType(frequencyTypeOptions[4]);
      setYearlyData({
        years: cronParts[5]?.split(',') || [],
        months: cronParts[3].split(',') || [],
        days: cronParts[2].split(',') || [],
        time: `${cronParts[1].padStart(2, '0')}:${cronParts[0].padStart(2, '0')}`,
      });
    } else {
      // it is weekly
      setRepeatFrequencyType(frequencyTypeOptions[2]);
      setWeeklyData({
        frequency: cronParts[4].split(','),
        time: `${cronParts[1].padStart(2, '0')}:${cronParts[0].padStart(2, '0')}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hydrate end data from cron expression
  useEffect(() => {
    if (!startScheduler?.cronExpression || !endScheduler?.cronExpression) return;

    const startCronWithoutYear = startCronExpression.split(' ').slice(0, 5).join(' ');
    const endCronWithoutYear = endCronExpression.split(' ').slice(0, 5).join(' ');

    const startInterval = cronParser.parseExpression(startCronWithoutYear);
    const endInterval = cronParser.parseExpression(endCronWithoutYear);
    const nextStartMomentDate = moment(startInterval.next().toDate());
    let nextEndMomentDate = moment(endInterval.next().toDate());

    while (nextEndMomentDate < nextStartMomentDate) {
      nextEndMomentDate = moment(endInterval.next().toDate());
    }

    const hoursDiff = nextEndMomentDate.diff(nextStartMomentDate, 'hours');
    setDuration((prev) => ({ ...prev, value: `${hoursDiff}` }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.RADIO_LIST}>
        {frequencyTypeOptions.map((option) => (
          <div className={CLASSES.RADIO} key={option.key}>
            <input
              type="radio"
              id={option.key}
              name="frequency-type"
              checked={option.key === repeatFrequencyType.key}
              onChange={() => handleFrequencyTypeChange(option)}
            />
            <label htmlFor={option.key}>{option.name}</label>
          </div>
        ))}
      </div>
      <div className={PARENT_CLASSES.SECTION}>
        {repeatFrequencyType.key === 'hour' && (
          <>
            <p>
              When setting up a <strong>hourly</strong> recurrence, the counter <strong>starts at 12:00 AM</strong>.
            </p>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="hourly-frequency" style={{ flex: '0 0 100px' }}>
                Every
              </label>
              <FlightSelect
                ariaControlsId="hourly-frequency"
                options={hourlyFrequencyOptions}
                selected={hourlyData.frequency}
                width="120px"
                handleOptionClick={handleHourlyFrequencyChange}
              />
              <label htmlFor="hourly-minute">on minute</label>
              <FlightNumberInput
                minValue={0}
                maxValue={59}
                width="60px"
                value={hourlyData.minute}
                onChange={handleHourlyMinuteChange}
              />
            </div>
          </>
        )}
        {repeatFrequencyType.key === 'day' && (
          <>
            <p>
              When setting up a <strong>daily</strong> recurrence, the counter{' '}
              <strong>starts on the first day of the month</strong>.
            </p>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="daily-frequency" style={{ flex: '0 0 100px' }}>
                Every
              </label>
              <FlightSelect
                ariaControlsId="daily-frequency"
                options={dailyFrequencyOptions}
                selected={dailyData.frequency}
                handleOptionClick={handleDailyFrequencyChange}
                width="120px"
              />
              <label htmlFor="daily-time">at</label>
              <input type="time" id="daily-time" onChange={handleTimeChange} value={dailyData.time} />
            </div>
          </>
        )}
        {repeatFrequencyType.key === 'week' && (
          <>
            <p>
              When setting up a <strong>weekly</strong> recurrence, it will evaluate only on the selected days of the
              week.
            </p>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="weekly-frequency" style={{ flex: '0 0 100px' }}>
                Evaluate on
              </label>
              <div className={PARENT_CLASSES.CHECKBOX_LIST}>
                {weeklyFrequencyOptions.map((day) => (
                  <div className={PARENT_CLASSES.CHECKBOX} key={`weekly-${day.key}`}>
                    <input
                      type="checkbox"
                      id={`weekly-${day.key}`}
                      name={`weekly-${day.key}`}
                      value={day.key}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => handleWeeklyFrequencyChange(evt.target.value)}
                      checked={weeklyData.frequency.includes(day.key)}
                    />
                    <label htmlFor={`weekly-${day.key}`}>{day.name}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="weekly-time" style={{ flex: '0 0 100px' }}>
                at
              </label>
              <input type="time" id="weekly-time" onChange={handleTimeChange} value={weeklyData.time} />
            </div>
          </>
        )}
        {repeatFrequencyType.key === 'month' && (
          <>
            <p>
              When setting up a <strong>monthly</strong> recurrence, the counter{' '}
              <strong>starts on the first day of the month</strong>.
            </p>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="monthly-days" style={{ flex: '0 0 100px' }}>
                Every
              </label>
              <div className={PARENT_CLASSES.CHECKBOX_LIST}>
                <FlightSelect
                  ariaControlsId="monthly-days"
                  options={monthlyDayOptions}
                  selected={monthlyData.days}
                  handleOptionClick={handleMonthlyDaysChange}
                  width="200px"
                />
              </div>
              <label htmlFor="monthly-time">at</label>
              <input type="time" id="monthly-time" value={monthlyData.time} onChange={handleTimeChange} />
            </div>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="monthly-frequency" style={{ flex: '0 0 100px' }}>
                On
              </label>
              <div className={PARENT_CLASSES.CHECKBOX_LIST}>
                {monthlyFrequencyOptions.map((month) => (
                  <div className={PARENT_CLASSES.CHECKBOX} key={`monthly-${month.key}`}>
                    <input
                      type="checkbox"
                      id={`monthly-${month.key}`}
                      name={`monthly-${month.key}`}
                      value={month.key}
                      checked={monthlyData.frequency.includes(month.key)}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => handleMonthlyFrequencyChange(evt.target.value)}
                    />
                    <label htmlFor={`monthly-${month.key}`}>{month.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {repeatFrequencyType.key === 'year' && (
          <>
            <p>
              When setting up a <strong>yearly</strong> recurrence, the counter{' '}
              <strong>starts on the first day of the year</strong>.
            </p>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="yearly-years" style={{ flex: '0 0 100px' }}>
                On year(s)
              </label>
              <FlightTextInput
                placeholderText="Years separated by comma. E.g. 2023, 2024, 2025"
                width="340px"
                value={yearlyData.years.join(',')}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => handleYearlyFrequencyChange(evt.target.value)}
              />
            </div>
            <div className={PARENT_CLASSES.FIELD} style={{ alignItems: 'flex-start' }}>
              <label htmlFor="yearly-months" style={{ flex: '0 0 100px' }}>
                On month(s)
              </label>
              <div className={PARENT_CLASSES.CHECKBOX_LIST}>
                {monthlyFrequencyOptions.map((month) => (
                  <div className={PARENT_CLASSES.CHECKBOX} key={`yearly-month-${month.key}`}>
                    <input
                      type="checkbox"
                      id={`yearly-month-${month.key}`}
                      name={`yearly-month-${month.key}`}
                      value={month.key}
                      checked={yearlyData.months.includes(month.key)}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => handleYearlyMonthsChange(evt.target.value)}
                    />
                    <label htmlFor={`yearly-month-${month.key}`}>{month.name}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className={PARENT_CLASSES.FIELD} style={{ alignItems: 'flex-start' }}>
              <label htmlFor="yearly-days" style={{ flex: '0 0 100px' }}>
                On day(s)
              </label>
              <div className={PARENT_CLASSES.CHECKBOX_LIST}>
                {[...Array(31)].map((e, i) => (
                  <div className={PARENT_CLASSES.CHECKBOX} key={`yearly-day-${i + 1}`}>
                    <input
                      type="checkbox"
                      id={`yearly-day-${i + 1}`}
                      name={`yearly-day-${i + 1}`}
                      value={i + 1}
                      checked={yearlyData.days.includes(`${i + 1}`)}
                      onChange={(evt: ChangeEvent<HTMLInputElement>) => handleYearlyDaysChange(evt.target.value)}
                    />
                    <label htmlFor={`yearly-day-${i + 1}`}>{`${i + 1}`.padStart(2, '0')}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className={PARENT_CLASSES.FIELD} style={{ margin: 0 }}>
              <div style={{ flex: '0 0 100px' }} />
              <p className={PARENT_CLASSES.WARNING}>
                Please be cautious when selecting days 29, 30 and 31, as not all months have these days.
              </p>
            </div>
            <div className={PARENT_CLASSES.FIELD}>
              <label htmlFor="yearly-time" style={{ flex: '0 0 100px' }}>
                at
              </label>
              <input
                type="time"
                id="yearly-time"
                name="yearly-time"
                value={yearlyData.time}
                onChange={handleTimeChange}
                width={90}
              />
            </div>
          </>
        )}
        <div className={PARENT_CLASSES.FIELD}>
          <label htmlFor="timezone" style={{ flex: '0 0 100px' }}>
            Timezone
          </label>
          <FlightTimeZone
            handleTimeZoneChange={(opt: Option) => setSelectedTimezone(opt)}
            selectedTimeZone={selectedTimezone}
            timeZoneList={tzList}
          />
        </div>
        <div className={PARENT_CLASSES.FIELD}>
          <h3 style={{ margin: '0px' }}>Rule evaluation window</h3>
        </div>
        <div className={PARENT_CLASSES.FIELD} style={{ marginTop: '16px' }}>
          <FlightRadioButton
            key="specific-moment"
            label="Evaluate at every selected time"
            ariaLabel="Evaluate at every selected time"
            value="specific-moment"
            checked={!hasStartEnd}
            onSelect={handleHasStartEndChange}
          />
        </div>
        <div className={PARENT_CLASSES.FIELD} style={{ marginTop: '8px' }}>
          <FlightRadioButton
            key="specific-period"
            label="Available to be evaluated for"
            ariaLabel="Available to be evaluated for"
            value="specific-period"
            checked={hasStartEnd}
            onSelect={handleHasStartEndChange}
            disabled={durationMax <= 0}
          />
          <FlightNumberInput
            placeholderText=""
            minValue={0}
            maxValue={durationMax}
            disabled={!hasStartEnd}
            hasError={!!duration.errorMsg}
            width="60px"
            value={duration.value}
            onChange={handleDurationChange}
          />
          <FlightSelect
            options={unitOfTimeOptions.slice(1)}
            selected={duration.unit}
            disabled={!hasStartEnd}
            hasError={!!duration.errorMsg}
            handleOptionClick={handleUnitOfTimeChange}
          />
        </div>
        {duration.errorMsg && <div className={CLASSES.ERROR}>{duration.errorMsg}</div>}
        <div className={PARENT_CLASSES.FIELD}>
          <div className={CLASSES.SUMMARY}>
            {!!startCronExpression && (
              <p>
                It will run{' '}
                <strong>
                  {uncapitalizeFirstLetter(
                    cronstrue.toString(startCronExpression, { verbose: true, throwExceptionOnParseError: false }),
                  )}
                </strong>
                .
              </p>
            )}
            <p style={{ marginTop: '24px' }}>
              <strong>Here are the next schedule dates:</strong>
            </p>
            <ul className={`${CLASSES.DATE_LIST}${endCronExpression ? ` ${CLASSES.DATE_LIST_TWO_COLUMNS}` : ''}`}>
              {executionDates}
            </ul>
            <CalendarImage className={CLASSES.CALENDAR_IMAGE} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CronExpressionSection;
