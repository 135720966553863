import React, { useState, useEffect } from 'react';
import { FlightRadioButton } from '@flybits/design-system';

import { useCustomModuleAnalyticsContext } from '../../context/CustomModuleAnalyticsContext';
import { VISUALIZER_OPTIONS } from '../../constants';
import BarIcon from 'assets/images/bar-icon.svg';
import PieIcon from 'assets/images/pie-icon.svg';

import '../CustomModuleAnalyticsContent.scss';

const DEFAULT_CLASS = 'custom-module-analytics__main-content';

const visualizerOption = [
  { key: VISUALIZER_OPTIONS.BAR, label: 'Bar', value: VISUALIZER_OPTIONS.BAR, iconSrc: BarIcon, alt: 'Bar Icon' },
  { key: VISUALIZER_OPTIONS.PIE, label: 'Pie chart', value: VISUALIZER_OPTIONS.PIE, iconSrc: PieIcon, alt: 'Pie Icon' },
];

export const CustomModuleVisualizer = () => {
  const { customModuleState, setCustomModuleState } = useCustomModuleAnalyticsContext();
  const {
    visualization: { selectedGraph },
  } = customModuleState;
  const [selectedModuleOption, setSelectedModuleOption] = useState<VISUALIZER_OPTIONS>(
    selectedGraph || VISUALIZER_OPTIONS.LINE,
  );

  useEffect(() => {
    setCustomModuleState({
      ...customModuleState,
      visualization: {
        selectedGraph: selectedModuleOption,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModuleOption]);

  const handleSelectModuleOption = (key: VISUALIZER_OPTIONS) => {
    if (selectedModuleOption === key) return;
    setSelectedModuleOption(key);
  };

  return (
    <>
      {visualizerOption.map((visualizer, idx) => {
        const { key, label, value, iconSrc, alt } = visualizer;
        return (
          <div key={`ca-visualizer-${idx}`} className={`${DEFAULT_CLASS}__radio__container__option-container`}>
            <img className={`${DEFAULT_CLASS}__radio__container__option-container__icon`} src={iconSrc} alt={alt} />
            <FlightRadioButton
              key={key}
              className={`${DEFAULT_CLASS}__radio__container__radio`}
              label={label}
              onSelect={handleSelectModuleOption}
              value={value}
              checked={selectedModuleOption === value}
            />
          </div>
        );
      })}
    </>
  );
};
