import React, { useMemo } from 'react';
import LabelLayersIcon from 'pages/Zones/assets/icons/icon-label-layers.svg';
import { Module } from 'pages/Zones/types';
import { Content } from 'interface/content/content.interface';
import ContentAPI from 'services/api/content.api';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { useQuery } from '@tanstack/react-query';
import PinContentCard from '../PinContentCard/PinContentCard';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import './ModulePrioritizationContainer.scss';

type TModulePrioritizationContainerProps = {
  module: Module;
};

const MAIN_CLASS = 'module-prioritization-container';

const fetchContentInstances = async (contentAPI: ContentAPI, contentIDs: string[]) => {
  const contentList: Promise<Content>[] = [];
  for (let i = 0; i < (contentIDs?.length || 0); i++) {
    const res = contentAPI.getInstance(contentIDs[i]);
    contentList.push(res);
  }
  return await Promise.all(contentList);
};
const ModulePrioritizationContainer: React.FC<TModulePrioritizationContainerProps> = ({ module }) => {
  const contentAPI = useMemo(() => new ContentAPI(), []);

  const { languages } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const { data: contentInstances, status } = useQuery({
    queryKey: ['content-instances', [...(module.pinnedContentIDs || [])].sort()],
    queryFn: async () => await fetchContentInstances(contentAPI, module.pinnedContentIDs),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const hasHTMLContent = (content: Content) => {
    return (
      !!content?.defaultUIConfig?.layoutHTML ||
      !!content?.defaultUIConfig?.styleCSSURL ||
      !!content?.defaultUIConfig?.styleCSS
    );
  };

  return (
    <div className={MAIN_CLASS}>
      {status === 'loading' ? (
        <LoadingIcon />
      ) : (
        <ul>
          {module.pinnedContentIDs?.map((contentId, index) => {
            const contentInstance = contentInstances?.find(
              (contentInstance) => contentInstance.id === contentId,
            ) as Content;
            if (!contentInstance) return null;
            const contentType = hasHTMLContent(contentInstance) ? 'content-instance' : 'image';
            const props:
              | {
                  contentType: 'image';
                  contentURI: string;
                  contentInstanceId?: never;
                }
              | {
                  contentType: 'content-instance';
                  contentInstanceId: string;
                  contentURI?: never;
                } =
              contentType === 'content-instance'
                ? {
                    contentType: 'content-instance',
                    contentInstanceId: contentId || '',
                  }
                : {
                    contentType: 'image',
                    contentURI:
                      contentInstance?.content?.data?.[0]?.media?.localizations?.[defaultLang]?.previewImgURL ||
                      contentInstance?.iconUrl ||
                      '',
                  };
            return (
              <li key={contentId || ''}>
                <div className="number">{index + 1}</div>
                <PinContentCard
                  id={contentId || ''}
                  title={contentInstance.localizations?.[defaultLang].name || ''}
                  isPinned={true}
                  {...props}
                />
              </li>
            );
          })}
          {module?.labelsOrder?.map((label, index) => (
            <li key={label}>
              <div className="number">{index + (contentInstances?.length || 0) + 1}</div>
              <div className="label-container">
                <img src={LabelLayersIcon} alt="" />
                <span className="label">{label}</span>
                <span className="text note">Content displayed to the end-user through labels may differ.</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ModulePrioritizationContainer;
