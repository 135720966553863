import { SnackbarType } from './snackbar.type';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const DISMISS_SNACKBAR = 'DISMISS_SNACKBAR';

export function showSnackbar(payload: SnackbarType) {
  return {
    type: SHOW_SNACKBAR,
    payload,
  };
}

export function dismissSnackbar() {
  return {
    type: DISMISS_SNACKBAR,
  };
}
