import React, { useState } from 'react';
import {
  XYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  DiscreteColorLegend,
  HorizontalGridLines,
  Crosshair,
  LabelSeries,
  MarkSeries,
  makeWidthFlexible,
} from 'react-vis';
import moment from 'moment';
import './STExperiencePerformance.scss';
import { GraphData, LegendGraphData } from 'interface/templated-experience/graph-data.interface';
import { numberWithCommas } from 'helpers/templated-experience.helper';

interface EPGraphData {
  legendGraphData: LegendGraphData[];
  lineGraphData: GraphData[];
  hoveredNode: GraphData;
  xAxisLabelData: number[];
  emptyGraph: boolean;
}

const MAX_X_AXIS_TICK = 7;

export default function STExperiencePerformanceGraph(props: EPGraphData) {
  const FlexibleXYPlot = makeWidthFlexible(XYPlot);
  const [tooltipData, setTooltipData] = useState<boolean>(false);
  //set the max axisLabel data length to 7
  function formatNumber(yAxisLabel: string) {
    if (yAxisLabel.toString().length > 6) {
      return (parseFloat(yAxisLabel) / 1000000).toString() + 'M';
    } else if (yAxisLabel.toString().length > 3) {
      return (parseFloat(yAxisLabel) / 1000).toString() + 'K';
    } else {
      return yAxisLabel;
    }
  }

  function formatXAxisLabel(xAxisLabel: number) {
    return props.xAxisLabelData[xAxisLabel]
      ? moment(props.xAxisLabelData[xAxisLabel] * 1000)
          .utc()
          .format('MMM D')
      : '';
  }

  const tooltipTitleDate = () => {
    const formatDate = moment(props.xAxisLabelData[props.hoveredNode.x] * 1000)
      .utc()
      .format('dddd, MMM D, YYYY');
    return formatDate === 'Invalid date' ? null : formatDate;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const crosshairData = (lineGraphData: any) => {
    if (props.hoveredNode) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return lineGraphData.map((lGData: any, index: number) => {
        const labelData = props.legendGraphData[index].title;
        const lineGraphDataProp = lGData.props;
        const tooltipValue = lineGraphDataProp.data.find((d: GraphData) => d.x === props.hoveredNode.x);
        const keyValue = 'crossHair_' + index.toString();
        if (tooltipValue) {
          return (
            <div className="crossHair_col" key={keyValue}>
              <div className={lineGraphDataProp.className}></div>
              <div className="crossHair_col__name">{labelData}</div>
              <div className="crossHair_col__value">{numberWithCommas(tooltipValue.y)}</div>
            </div>
          );
        } else {
          return null;
        }
      });
    }
  };

  return (
    <div className="experience-performance-graph-container">
      <div className="experience-performance-legend-container">
        <DiscreteColorLegend items={props.legendGraphData} />
      </div>
      <FlexibleXYPlot
        height={300}
        margin={{ left: 70, right: 30 }}
        onMouseLeave={() => setTooltipData(false)}
        onMouseEnter={() => setTooltipData(true)}
      >
        <XAxis
          style={{ strokeWidth: '1px', fontSize: '14px' }}
          tickTotal={Math.min(props.xAxisLabelData?.length || 0, MAX_X_AXIS_TICK)}
          tickFormat={(v: number) => formatXAxisLabel(v)}
        />
        <YAxis
          style={{ strokeWidth: '0px', fontSize: '14px' }}
          left={-80}
          width={150}
          tickTotal={4}
          tickFormat={(yAxisLabel) => formatNumber(yAxisLabel)}
        />
        <ChartLabel
          text="# of users"
          className="alt-y-label"
          includeMargin={true}
          xPercent={0.01}
          yPercent={0.03}
          style={{ transform: 'rotate(-90)', textAnchor: 'end' }}
        />
        <MarkSeries data={[{ x: 0, y: 0 }]} style={{ display: 'none' }} />
        {props.emptyGraph ? (
          //set an empty graph with y axis showing value 4
          <LabelSeries data={[{ x: 0, y: 4, label: '' }]} />
        ) : null}
        <HorizontalGridLines />
        {props.lineGraphData}
        {tooltipData ? (
          <Crosshair values={[props.hoveredNode]} className="crossHair_container">
            <b>{tooltipTitleDate()}</b>
            {crosshairData(props.lineGraphData)}
          </Crosshair>
        ) : null}
      </FlexibleXYPlot>
    </div>
  );
}
