import React from 'react';
import { TIconProps } from 'pages/ExperienceCanvas/assets/types';

export default function ActionsIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_359_23960)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 8H17C18.1 8 19 8.9 19 10V20C19 21.1 18.1 22 17 22H7C5.9 22 5 21.1 5 20V10C5 8.9 5.9 8 7 8ZM6 6.5H18C18 5.67 17.33 5 16.5 5H7.5C6.67 5 6 5.67 6 6.5ZM7 3.5H17C17 2.67 16.33 2 15.5 2H8.5C7.67 2 7 2.67 7 3.5ZM9 19L16 14.5556L9 11V19Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_359_23960">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
