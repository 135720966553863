import './OptionalNodeWrapper.scss';

import React from 'react';
import { FlightToggleSwitch } from '@flybits/design-system';
import { Handle, Position } from 'reactflow';
import { defaultTargetHandleStyle, defaultSourceHandleStyle } from 'pages/FlowBuilder/constants/flow-builder.constants';

const MAIN_CLASS = 'optional-node-wrapper';
const CLASSES = {
  DISABLED: `${MAIN_CLASS}--disabled`,
  TOGGLE: `${MAIN_CLASS}__toggle`,
  TOGGLE_ENABLED: `${MAIN_CLASS}__toggle--enabled`,
  CONTAINER: `${MAIN_CLASS}__container`,
};

type OptionalNodeWrapperProps = {
  children: React.ReactNode;
  isEnabled: boolean;
  onToggle: () => void;
};

function OptionalNodeWrapper(props: OptionalNodeWrapperProps) {
  const { children, isEnabled: isToggleChecked, onToggle } = props;

  return (
    <div className={MAIN_CLASS}>
      <div className={`${CLASSES.TOGGLE} ${isToggleChecked ? CLASSES.TOGGLE_ENABLED : ''}`}>
        <FlightToggleSwitch
          label={isToggleChecked ? 'Enabled' : 'Disabled'}
          checked={isToggleChecked}
          onChange={onToggle}
        />
      </div>
      <div className={CLASSES.CONTAINER}>{children}</div>
      <Handle type="target" position={Position.Top} style={defaultTargetHandleStyle} />
      <Handle type="source" position={Position.Bottom} style={defaultSourceHandleStyle} />
    </div>
  );
}

export default OptionalNodeWrapper;
