import React from 'react';
import { useHistory } from 'react-router-dom';

// Stylings
import './HeaderBreadcrumb.scss';

export type Crumb = {
  name: string;
  path: string;
  isClickable: boolean;
  cbFunc?: () => void;
};

type HeaderBreadcrumbData = {
  crumbs: Crumb[];
};

export const HeaderBreadcrumb = ({ crumbs }: HeaderBreadcrumbData): JSX.Element => {
  const history = useHistory();
  const handleCrumbLinkClick = (path: string): void => {
    if (path) history.push(path);
  };

  return (
    <nav aria-label="Breadcrumb">
      <ol className="overview-breadcrumb-container">
        {crumbs?.map((crumb: Crumb, id: number) => {
          const { name, path, isClickable, cbFunc = null } = crumb;
          const CrumbElement = isClickable ? (
            <button
              type="button"
              onClick={() => {
                cbFunc?.();
                handleCrumbLinkClick(path);
              }}
            >
              {name}
            </button>
          ) : (
            name
          );
          return <li key={`experienceHeaderCrumb-${id}`}>{CrumbElement}</li>;
        })}
      </ol>
    </nav>
  );
};
