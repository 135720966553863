import './ZonesList.scss';

import React from 'react';
import { ZM_LIST_CLASSES } from './constants';

import { Zone } from 'pages/Zones/types';
import { FlightCard, FlightOverflowMenu } from '@flybits/design-system';
import Jdenticon from 'components/Shared/Jdenticon';
type ZoneCardProps = {
  zone: Zone;
  onClick?: () => void;
  onRemove?: () => void;
};
function ZonesListCard({ zone, onClick, onRemove }: ZoneCardProps) {
  const { name, id, modules } = zone;
  return (
    <div className={`${ZM_LIST_CLASSES.CARD}`}>
      <FlightOverflowMenu
        className=""
        direction="left"
        optionGroups={[
          [
            {
              key: 1,
              name: 'View Zone',
              onClick,
            },
            {
              key: 3,
              name: 'Delete Zone',
              onClick: onRemove,
            },
          ],
        ]}
      />
      <FlightCard
        header={name}
        image={<Jdenticon size="48" value={id || name} className={ZM_LIST_CLASSES.CARD_ICON} />}
        body={
          <div className={ZM_LIST_CLASSES.CARD_BODY}>
            <ul>
              {modules?.map((module, num) => {
                if (num === 3 && modules.length > 4) {
                  // more than 3 modules = clip
                  return (
                    <li key={id + module.id} className="no-overflow">
                      + {modules.length - 3} others
                    </li>
                  );
                }
                if (num > 2) return false;
                return <li key={id + module.id}>{module.name}</li>;
              })}
            </ul>
          </div>
        }
        onClick={onClick}
      />
    </div>
  );
}

export default ZonesListCard;
