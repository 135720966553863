import React from 'react';

type TTouchpointEditorCardBodyProps = {
  children: string | JSX.Element;
  isLocked?: boolean;
};

const CLASS_PREFIX = 'card';
const CLASSES = {
  BODY: `${CLASS_PREFIX}__body`,
  LOCKED_OVERLAY: `${CLASS_PREFIX}__locked-overlay`,
};

export default function TouchpointEditorCardBody({ children, isLocked = false }: TTouchpointEditorCardBodyProps) {
  return (
    <div className={CLASSES.BODY}>
      {isLocked && <div className={CLASSES.LOCKED_OVERLAY} />}
      {children}
    </div>
  );
}
