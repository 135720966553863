import React, { useCallback, useState } from 'react';
import './RegisterMerchant.scss';
import FlybitsLogo from 'assets/images/flybits-logo.svg';
import MerchantForm from 'components/MerchantPortal/MerchantForm/MerchantForm';
import Breadcrumbs from 'components/Shared/Breadcrumbs/Breadcrumbs';
import { getIcon } from '@flybits/design-system';
import MerchantReview from 'components/MerchantPortal/MerchantReview/MerchantReview';
import MerchantSubmitted from 'components/MerchantPortal/MerchantSubmitted/MerchantSubmitted';
import { genUUID, sleep } from 'helpers/common.helper';
import { MerchantFormValues } from '../merchant-portal.types';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import useMerchantCategoriesMock from 'hooks/useMerchantCategoriesMock';

const REGISTER_MERCHANT = 'register-merchant';
const CLASSES = {
  HEADER: `${REGISTER_MERCHANT}__header`,
  INFO: `${REGISTER_MERCHANT}__header__info`,
  TITLE: `${REGISTER_MERCHANT}__header__title`,
  SUBTITLE: `${REGISTER_MERCHANT}__header__subtitle`,
  BODY: `${REGISTER_MERCHANT}__body`,
  BREADCRUMBS: `${REGISTER_MERCHANT}__body__breadcrumbs`,
  CONTAINER: `${REGISTER_MERCHANT}__body__container`,
};

// NOTE: These are EXAMPLE icons. Please update to match with designs when implementing
const BREADCRUMB_ITEMS = [
  { icon: <>{getIcon('merchant', {})}</>, forceOutline: true, text: 'Create Merchant profile' },
  { icon: <>{getIcon('eyeOpen', {})}</>, forceOutline: true, text: 'Review and confirm' },
];

const initialValues: MerchantFormValues = {
  name: '',
  externalId: '',
  category: null,
  logo: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  contact: {
    name: '',
    phone: '',
    email: '',
  },
  verification: null,
};
const RegisterMerchant = function () {
  const [selectedStep, setSelectedStep] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { merchantCategories } = useMerchantCategoriesMock();
  const [merchantData, setMerchantData] = useState<MerchantFormValues>(initialValues);
  const [isMerchantFormDirty, setMerchantFormDirty] = useState(false);
  const handleOnFormSubmit = useCallback((values: MerchantFormValues) => {
    setSelectedStep(2);
    setMerchantData(values);
  }, []);
  const handleOnReviewSubmit = useCallback(async () => {
    // mock submit
    setIsSubmitted(false);
    setIsSubmitting(true);
    setSelectedStep(3);
    // MOCK: here goes the API call
    // MOCK: generate random ID for now
    // Later it'll grab the response from the API
    setMerchantData({ ...merchantData, id: genUUID() });
    await sleep(2000);
    // end API call
    setIsSubmitting(false);
    setIsSubmitted(true);
  }, [merchantData]);
  const handleResetForm = useCallback(() => {
    setMerchantData(initialValues);
    setSelectedStep(1);
    setIsSubmitted(false);
  }, []);

  return (
    <>
      <div className={REGISTER_MERCHANT}>
        <header className={CLASSES.HEADER}>
          <img alt="flybits logo" src={FlybitsLogo} />
          <div className={CLASSES.INFO}>
            <div className={CLASSES.TITLE}>Register a Local Merchant</div>
            {!isSubmitted && !isSubmitting && (
              <div className={CLASSES.SUBTITLE}>Please fill out the form below to register a new merchant</div>
            )}
          </div>
        </header>
        {selectedStep !== 3 ? (
          <div className={CLASSES.BODY}>
            <section className={CLASSES.BREADCRUMBS}>
              <Breadcrumbs items={BREADCRUMB_ITEMS} selectedStep={selectedStep} />
            </section>
            {selectedStep === 1 ? (
              <section className={CLASSES.CONTAINER}>
                <MerchantForm
                  data={merchantData}
                  categories={merchantCategories ?? []}
                  onSubmit={handleOnFormSubmit}
                  setDirty={setMerchantFormDirty}
                />
              </section>
            ) : (
              <section className={CLASSES.CONTAINER}>
                <MerchantReview
                  data={merchantData}
                  onBackClick={() => setSelectedStep(1)}
                  onSubmit={handleOnReviewSubmit}
                />
              </section>
            )}
          </div>
        ) : (
          <MerchantSubmitted onReset={handleResetForm} data={merchantData} isSubmitted={isSubmitted} />
        )}
      </div>
      <UnsavedPrompt when={isMerchantFormDirty && selectedStep < 3} unblockPaths={['/register-merchant']} />
    </>
  );
};

export default RegisterMerchant;
