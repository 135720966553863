import './LoadingIcon.scss';
import { getIcon } from '@flybits/design-system';

interface LoadingIconProps {
  className?: string;
}

export const LoadingIcon = (props: LoadingIconProps) => {
  const { className } = props;
  const MAIN_CLASS = 'loading-icon';

  return getIcon('loading', { className: `${MAIN_CLASS} ${className}` });
};

export default LoadingIcon;
