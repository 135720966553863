import React, { Dispatch, SetStateAction, useMemo, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FlightTextInput, FlightButton } from '@flybits/design-system';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import { useCustomModuleAnalyticsContext, serializeGraphData } from '../../context/CustomModuleAnalyticsContext';
import { ENTITY_OPTIONS, COUNTRY_CODES } from '../../constants';
import BarGraph from 'components/Shared/Graphs/BarGraph';
import StreamGraph from 'components/Shared/Graphs/StreamGraph';
import PieGraph from 'components/Shared/Graphs/PieGraph';
import '../CustomModuleAnalyticsContent.scss';
import { uniqRand } from 'helpers/common.helper';

type TRouteParams = {
  pid: string;
  moduleID?: string;
};
type TCustomModuleAnalyticsPreviewProps = {
  moduleName: string;
  setModuleName: Dispatch<SetStateAction<string>>;
};
const DEFAULT_CLASS = 'custom-module-analytics__main-content';
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
// Random country + population for pie empty data
const randCountryCode = () => COUNTRY_CODES.sort(() => 0.5 - Math.random())[0];
const generatePieRandomData = (size = 6) => {
  const countryCode = uniqRand(randCountryCode);
  return COUNTRY_CODES.slice(0, size).map(() => ({
    name: countryCode(),
    value: 200 + Math.round(Math.random() * Math.random() * 1000000),
  }));
};

const emptyStreamData = [
  {
    id: 'Example1',
    color: getRandomColor(),
    data: [
      {
        x: new Date(1683518400 * 1000),
        y: 0,
      },
      {
        x: new Date(1684123200 * 1000),
        y: 0,
      },
      {
        x: new Date(1684728000 * 1000),
        y: 0,
      },
      {
        x: new Date(1685332800 * 1000),
        y: 0,
      },
      {
        x: new Date(1685937600 * 1000),
        y: 1,
      },
      {
        x: new Date(1686542400 * 1000),
        y: 5,
      },
      {
        x: new Date(1687147200 * 1000),
        y: 14,
      },
      {
        x: new Date(1687752000 * 1000),
        y: 31,
      },
      {
        x: new Date(1688356800 * 1000),
        y: 53,
      },
      {
        x: new Date(1688961600 * 1000),
        y: 78,
      },
      {
        x: new Date(1689566400 * 1000),
        y: 110,
      },
      {
        x: new Date(1690171200 * 1000),
        y: 150,
      },
      {
        x: new Date(1690776000 * 1000),
        y: 205,
      },
      {
        x: new Date(1691380800 * 1000),
        y: 265,
      },
      {
        x: new Date(1691985600 * 1000),
        y: 362,
      },
      {
        x: new Date(1692590400 * 1000),
        y: 441,
      },
      {
        x: new Date(1693195200 * 1000),
        y: 572,
      },
      {
        x: new Date(1693800000 * 1000),
        y: 700,
      },
      {
        x: new Date(1694404800 * 1000),
        y: 700,
      },
      {
        x: new Date(1695009600 * 1000),
        y: 700,
      },
      {
        x: new Date(1695614400 * 1000),
        y: 700,
      },
      {
        x: new Date(1696219200 * 1000),
        y: 700,
      },
    ],
  },
  {
    id: 'Example2',
    color: getRandomColor(),
    data: [
      {
        x: new Date(1683518400 * 1000),
        y: 0,
      },
      {
        x: new Date(1684123200 * 1000),
        y: 0,
      },
      {
        x: new Date(1684728000 * 1000),
        y: 0,
      },
      {
        x: new Date(1685332800 * 1000),
        y: 0,
      },
      {
        x: new Date(1685937600 * 1000),
        y: 0,
      },
      {
        x: new Date(1686542400 * 1000),
        y: 0,
      },
      {
        x: new Date(1687147200 * 1000),
        y: 0,
      },
      {
        x: new Date(1687752000 * 1000),
        y: 0,
      },
      {
        x: new Date(1688356800 * 1000),
        y: 0,
      },
      {
        x: new Date(1688961600 * 1000),
        y: 0,
      },
      {
        x: new Date(1689566400 * 1000),
        y: 3,
      },
      {
        x: new Date(1690171200 * 1000),
        y: 5,
      },
      {
        x: new Date(1690776000 * 1000),
        y: 12,
      },
      {
        x: new Date(1691380800 * 1000),
        y: 25,
      },
      {
        x: new Date(1691985600 * 1000),
        y: 39,
      },
      {
        x: new Date(1692590400 * 1000),
        y: 64,
      },
      {
        x: new Date(1693195200 * 1000),
        y: 93,
      },
      {
        x: new Date(1693800000 * 1000),
        y: 127,
      },
      {
        x: new Date(1694404800 * 1000),
        y: 176,
      },
      {
        x: new Date(1695009600 * 1000),
        y: 226,
      },
      {
        x: new Date(1695614400 * 1000),
        y: 302,
      },
      {
        x: new Date(1696219200 * 1000),
        y: 400,
      },
    ],
  },
];
export const CustomModuleAnalyticsPreview = ({ moduleName, setModuleName }: TCustomModuleAnalyticsPreviewProps) => {
  const templatedExperienceAnalyticsAPI = useMemo(() => new TemplatedExperienceAnalyticsAPI(), []);

  const { customModuleState } = useCustomModuleAnalyticsContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pid: projectId, moduleID } = useRouteMatch<TRouteParams>().params;

  const { visualization, measureItems, metrics } = customModuleState;

  const { selectedGraph } = visualization;
  const { metricFactors } = metrics;
  const { filterOption, selectedEntities, selectedLabels } = measureItems;

  const getMeasureItemsTouched = () => {
    let isMeasureItemStepsTouched = false;

    switch (filterOption) {
      case ENTITY_OPTIONS.ALL:
        isMeasureItemStepsTouched = true;
        break;

      case ENTITY_OPTIONS.LABEL:
        isMeasureItemStepsTouched = selectedLabels?.length > 0;
        break;

      case ENTITY_OPTIONS.SPECIFIC:
        isMeasureItemStepsTouched = selectedEntities?.length > 0;
        break;
    }

    return isMeasureItemStepsTouched;
  };

  const selectedEntityList = useMemo(() => {
    return ENTITY_OPTIONS.LABEL === filterOption
      ? selectedLabels?.map((label) => {
          return {
            name: label,
            key: label,
            value: label,
            isSelected: false,
          };
        })
      : selectedEntities?.map((entity) => {
          const { context, name } = entity;
          return {
            name,
            key: name,
            value: context.id || name,
            isSelected: false,
          };
        });
  }, [filterOption, selectedEntities, selectedLabels]);

  const generateRandomStreamData = () => {
    return {
      x: new Date(new Date().valueOf() - Math.random() * 1e9),
      y: Math.floor(Math.random() * 1000),
    };
  };

  const PreviewGraph = useMemo(() => {
    const isChartDataAvailable = selectedEntityList?.length > 0;

    switch (selectedGraph) {
      case 'bar':
        // Add defaults for bar
        const emptyBarData = [
          {
            name: "Doesn't know",
            value: 10,
          },
          {
            name: 'Disagree',
            value: 100,
          },
          {
            name: 'Aggree',
            value: 123,
          },
        ];

        const barData = isChartDataAvailable
          ? selectedEntityList?.map((entity) => {
              const { name } = entity;
              return {
                name: name,
                value: Math.floor(Math.random() * 20),
              };
            })
          : emptyBarData;
        return (
          <BarGraph
            data={barData}
            keys={['value']}
            indexBy="name"
            layout="horizontal"
            reverse={false}
            axisLeft={''}
            axisBottom={''}
            isLegendVisible={true}
          />
        );

      case 'pie':
        // Add defaults for pie to make it prettier
        const emptyPieData = generatePieRandomData(9).map(({ name, ...d }) => ({
          ...d,
          id: name,
          label: name,
          color: getRandomColor(),
        }));
        const pieData = isChartDataAvailable
          ? selectedEntityList?.map((entity) => {
              const { name, key } = entity;
              return {
                id: key,
                label: name,
                value: Math.floor(Math.random() * 20),
                color: getRandomColor(),
              };
            })
          : emptyPieData;
        return <PieGraph data={pieData} legendIcon="square" innerRadius={0} isLegendVisible={true} />;

      default:
        const streamData = isChartDataAvailable
          ? selectedEntityList?.map((entity) => {
              const { key } = entity;
              const color = getRandomColor();
              const randomDataList: {
                x: Date;
                y: number;
              }[] = [];
              for (let index = 0; index < 100; index++) {
                randomDataList.push(generateRandomStreamData());
              }

              return {
                id: key,
                color,
                data: randomDataList.sort((a, b) => b.x.getTime() - a.x.getTime()),
              };
            })
          : emptyStreamData;

        return <StreamGraph data={streamData} isLegendVisible={true} />;
    }
  }, [selectedGraph, selectedEntityList]);

  const saveCustomModule = useCallback(async () => {
    try {
      if (moduleID) {
        await templatedExperienceAnalyticsAPI.updateCustomModule(
          {
            id: moduleID,
            tenantId: projectId,
            ...serializeGraphData(customModuleState, moduleName),
          },
          moduleID,
        );
      } else {
        await templatedExperienceAnalyticsAPI.postCreateCustomModule(serializeGraphData(customModuleState, moduleName));
      }
      dispatch(
        showSnackbar({
          content: 'Custom analytics module saved successfully',
          type: 'success',
        }),
      );
      history.push(`/project/${projectId}/analytics#updated`);
    } catch (reason) {
      // eslint-disable-next-line no-console
      console.error('Error happened while creating Custom Module.', reason);
      dispatch(
        showSnackbar({
          content: 'Something went wrong while creating custom analytics module',
          type: 'error',
        }),
      );
    }
  }, [customModuleState, dispatch, history, moduleID, moduleName, projectId, templatedExperienceAnalyticsAPI]);

  const isMeasureItemStepsTouched = getMeasureItemsTouched();

  const isCustomModuleBtnEnabled = moduleName && isMeasureItemStepsTouched && metricFactors.length;

  return (
    <div className={`${DEFAULT_CLASS}__preview-container`}>
      <div className={`${DEFAULT_CLASS}__preview-container__title`}>Preview</div>
      <div className={`${DEFAULT_CLASS}__preview-container__name`}>
        <div className={`${DEFAULT_CLASS}__preview-container__name__title`}>Name your module</div>
        <FlightTextInput
          label=""
          type="text"
          value={moduleName}
          isLabelAlwaysOn
          width={'232px'}
          placeholderText="Enter Name here"
          className={`${DEFAULT_CLASS}__preview-container__name__input`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setModuleName(event.target.value)}
        />
      </div>
      <div className={`${DEFAULT_CLASS}__preview-container__save`}>
        <FlightButton
          onClick={saveCustomModule}
          disabled={!isCustomModuleBtnEnabled}
          theme="primary"
          label="Save"
          ariaLabel="Save widget"
          className={`${DEFAULT_CLASS}__preview-container__name__create`}
        />
      </div>
      <div className={`${DEFAULT_CLASS}__preview-container__preview`}>
        <div className={`${DEFAULT_CLASS}__preview-container__preview__graph`}>{PreviewGraph}</div>
      </div>
    </div>
  );
};
