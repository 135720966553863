import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';

import { FBComment } from 'interface/approval/approval.interface';
import { Pagination } from 'interface/shared/api.interface';
import { User } from 'interface/auth/auth.interface';
import { ApprovalRequest } from 'interface/approval/approval.interface';

import ApprovalAPI from 'services/api/approval.api';

import Comment from 'components/ExperienceCanvas/Comments/Comment/Comment';
import AddComment from 'components/ExperienceCanvas/Comments/AddComment/AddComment';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';

import './CommentList.scss';

type TCommentListProps = {
  requestId: string;
  comments: FBComment[];
  commentPagination?: Pagination;
  creators: ApprovalRequest['creators'];
  approvalRequirements: ApprovalRequest['approvalRequirements'];
  className?: string;
  user?: User;
  isReviewer?: boolean;
};

type TCommentListWrapperProps = Partial<TCommentListProps>;

const MAIN_CLASS = 'comment-list';

const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  BODY: `${MAIN_CLASS}__body`,
};

// TODO: allow infinite scroll
const CommentList: React.FC<TCommentListProps> = ({
  className,
  comments,
  commentPagination,
  creators,
  approvalRequirements,
  requestId,
  user,
  isReviewer = false,
}) => {
  const approvalAPI = useMemo(() => new ApprovalAPI(), []);

  const dispatch = useDispatch();

  const [displayComments, setDisplayComments] = useState(comments);
  const [pagination] = useState(commentPagination);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['approval-request-comments', requestId],
    queryFn: () => approvalAPI.getComments(requestId),
    initialData: {
      data: comments,
      pagination: {
        limit: 10,
        offset: 0,
        totalRecords: 0,
        ...pagination,
      },
    },
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    if (data) setDisplayComments(data.data);
  }, [data]);

  const addComment = useCallback(
    async (comment: string, mustResolve: boolean, parentId?: string) => {
      if (!requestId) return;
      try {
        await approvalAPI.postComment(requestId, { comment, mustResolve, parentId });
        // TODO: work out with prod what the comment submition workflow should be
        // Should it refetch everything using react query? should we manage it
        // so we don't trigger a refresh for every comment?
        refetch();
      } catch (e) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Could not post comment. Please try again.`, type: 'error' },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, requestId],
  );

  const updateComment = useCallback(
    async (comment: FBComment, resolved: boolean) => {
      if (!requestId || !comment.commentId) return;
      try {
        await approvalAPI.updateComment(requestId, comment.commentId, {
          comment: comment.comment,
          mustResolve: comment.mustResolve,
          resolved,
        });
        refetch();
      } catch (e) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Could not post comment. Please try again.`, type: 'error' },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, requestId],
  );

  const deleteComment = useCallback(
    async (comment: FBComment) => {
      if (!requestId || !comment.commentId) return;
      try {
        await approvalAPI.deleteComment(requestId, comment.commentId);
        refetch();
      } catch (e) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Could not post comment. Please try again.`, type: 'error' },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, requestId],
  );

  return isLoading ? (
    <div className="loading-icon-container" aria-label="approval request is loading">
      <LoadingIcon className="loading-icon-container__icon" />
    </div>
  ) : (
    <div className={`${MAIN_CLASS} ${className ?? ''}`}>
      <div className={CLASSES.HEADER}>
        <h3>Comments</h3>
      </div>
      <div className={CLASSES.BODY}>
        <AddComment addComment={addComment} showHeader={true} user={user} isReviewer={isReviewer} />
        {displayComments.length
          ? displayComments.map((comment) => (
              <Comment
                key={`request-comment-${comment.commentId}`}
                comment={comment}
                creators={creators}
                approvalRequirements={approvalRequirements}
                addComment={addComment}
                user={user}
                isReviewer={isReviewer}
                updateComment={updateComment}
                deleteComment={deleteComment}
              />
            ))
          : null}
      </div>
    </div>
  );
};

const CommentListWrapper: React.FC<TCommentListWrapperProps> = ({
  className,
  comments,
  commentPagination,
  requestId,
  user,
  isReviewer,
  creators,
  approvalRequirements,
}) => {
  const canRender = !!requestId;
  return (
    <>
      {canRender ? (
        <CommentList
          className={className}
          requestId={requestId}
          comments={comments ?? []}
          commentPagination={commentPagination}
          creators={creators ?? []}
          approvalRequirements={approvalRequirements ?? { teams: [], reviewers: [] }}
          user={user}
          isReviewer={isReviewer}
        />
      ) : (
        <div className="loading-icon-container" aria-label="approval request is loading">
          <LoadingIcon className="loading-icon-container__icon" />
        </div>
      )}
    </>
  );
};

export default CommentListWrapper;
