import {
  TEAContentEngagementAnalytics,
  ContentEngagement,
  TEASingleValue,
} from 'interface/templated-experience/analytics.interface';
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import ContentAPI from 'services/api/content.api';
import {
  AnalyticsContentEngagementActionType,
  SetContentEngagementLoadingAction,
  SetContentEngagementDataAction,
  SetContentEngagementErrorAction,
} from './content-engagement.type';

const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
const contentAPI = new ContentAPI();

function setContentEngagementLoading(payload: boolean): SetContentEngagementLoadingAction {
  return {
    type: AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_LOADING,
    payload,
  };
}

function resetContentEngagementData(): SetContentEngagementDataAction {
  return {
    type: AnalyticsContentEngagementActionType.RESET_CONTENT_ENGAGEMENT_DATA,
    payload: { data: [] },
  };
}

function setContentEngagementData(payload: TEAContentEngagementAnalytics): SetContentEngagementDataAction {
  return {
    type: AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_DATA,
    payload,
  };
}

function setContentEngagementErrorMessage(payload: string): SetContentEngagementErrorAction {
  return {
    type: AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_ERROR,
    payload,
  };
}

export const fetchContentEngagementTableData =
  (obj: {
    experienceID: string;
    contentID: string;
    pushID: string;
    params: { [key: string]: string };
    contentIDs: string[];
    isReset: boolean;
    defaultLangauge: string;
  }) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (dispatch: any) => {
    return new Promise(async (resolve) => {
      const contentEngagementArr: ContentEngagement[] = [];
      const contentPromises: Promise<void>[] = [];
      dispatch(setContentEngagementLoading(true));
      if (obj.isReset) {
        dispatch(resetContentEngagementData());
        dispatch(setContentEngagementErrorMessage(''));
      }

      try {
        // fill contentEngagementArr with default values
        obj.contentIDs.forEach((contentID) => {
          contentPromises.push(
            new Promise<void>(async (resolve, reject) => {
              const content = await contentAPI.getInstanceWithoutData(contentID);
              if (content.id) {
                const contentEngagement: ContentEngagement = {
                  key: 0,
                  contentId: content.id,
                  contentName:
                    content.localizations?.[obj.defaultLangauge]?.name ??
                    content.localizations?.[Object.keys(content.localizations)?.[0]]?.name ??
                    '',
                  contentTemplateType: content.templateType ?? '',
                  components: [],
                  totalClickRate: 0,
                  totalClicks: 0,
                  totalViews: 0,
                  uniqueClicks: 0,
                  uniqueViews: 0,
                  uniqueClickRate: 0,
                };
                //contentEngagementMap.set(content.id, contentEngagement)
                contentEngagementArr.push(contentEngagement);
                resolve();
              } else {
                reject();
              }
            }),
          );
        });

        await Promise.all(contentPromises).catch(() => {
          throw new Error('Error fetching content data');
        });

        // add content engagement promises for each content
        const engagementPromises: Promise<{ data: TEASingleValue }>[][] = contentEngagementArr.map((content) => {
          return [
            templatedExperienceAnalyticsAPI.getTotalContentViewsCountFromPush(content.contentId, obj.params),
            templatedExperienceAnalyticsAPI.getTotalContentClicksCountFromPush(content.contentId, obj.params),
            templatedExperienceAnalyticsAPI.getViewedContentInAppCount(content.contentId, obj.params),
            templatedExperienceAnalyticsAPI.getClickedOnContentInApp(content.contentId, obj.params),
          ];
        });

        // wait for promises to resolve - throw error if necessary
        const engagementResults = await Promise.all(
          engagementPromises.map((promiseArr) => Promise.all(promiseArr)),
        ).catch(() => {
          throw new Error('Error fetching content engagement data');
        });

        // use the resolved promises to populate the engagement fields for each content
        engagementResults.forEach((result, index) => {
          contentEngagementArr[index].totalViews = result[0]?.data?.value | 0;
          contentEngagementArr[index].totalClicks = result[1]?.data?.value | 0;
          contentEngagementArr[index].uniqueViews = result[2]?.data?.value | 0;
          contentEngagementArr[index].uniqueClicks = result[3]?.data?.value | 0;
          if (contentEngagementArr[index].totalClicks && contentEngagementArr[index].totalViews) {
            contentEngagementArr[index].totalClickRate =
              contentEngagementArr[index].totalClicks / contentEngagementArr[index].totalViews;
          }
          if (contentEngagementArr[index].uniqueClicks && contentEngagementArr[index].uniqueViews) {
            contentEngagementArr[index].uniqueClickRate =
              contentEngagementArr[index].uniqueClicks / contentEngagementArr[index].uniqueViews;
          }
        });

        const contentEngagementAnalytics: TEAContentEngagementAnalytics = {
          data: contentEngagementArr,
        };

        dispatch(setContentEngagementData(contentEngagementAnalytics));
        resolve(contentEngagementAnalytics);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        dispatch(setContentEngagementErrorMessage(err.message || 'Error requesting content data'));
      } finally {
        dispatch(setContentEngagementLoading(false));
      }
    });
  };
