import './ModuleLabelsList.scss';

import React, { useState } from 'react';

import { cloneDeep } from 'lodash';

import ModuleLabelListItem from 'components/Zones/ModuleLabelListItem/ModuleLabelListItem';
import { ZoneConfig } from 'pages/Zones/types';

const MAIN_CLASS = '.module-labels-list';

type ModuleLabelsListProps = {
  labels: string[];
  zoneId: string;
  moduleId: string;
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
};

// ToDo: Connect with selected zone and selected module state
// right now, this works with first zone's first module
function ModuleLabelsList(props: ModuleLabelsListProps) {
  const { labels, zoneId, moduleId, zoneConfig, setZoneConfig } = props;
  const zoneIndex = zoneConfig.zones.findIndex((zone) => zone.id === zoneId);
  const moduleIndex = zoneConfig.zones[zoneIndex].modules.findIndex((module) => module.id === moduleId);
  const [currentDraggingIndex, setCurrentDraggingIndex] = useState<number>();

  const handleDeleteLabel = (index: number) => {
    const remainingLabels = labels.filter((label, idx) => {
      if (idx !== index) {
        return label;
      }
    });
    const remainingLabelsLimits = Object.keys(zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits).reduce(
      (acc: { [key: string]: number }, key) => {
        if (key !== labels[index]) {
          acc[key] = zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits[key];
        }
        return acc;
      },
      {},
    );

    const _zoneConfig = cloneDeep(zoneConfig);
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsOrder = remainingLabels;
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits = remainingLabelsLimits;
    setZoneConfig(_zoneConfig);
  };

  const handleChangeLabelPosition = (oldIndex: number, newIndex: number) => {
    const _labels = [...labels];
    [_labels[oldIndex], _labels[newIndex]] = [_labels[newIndex], _labels[oldIndex]];

    const _zoneConfig = cloneDeep(zoneConfig);
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsOrder = _labels;
    setZoneConfig(_zoneConfig);
  };

  const handleLimitValueChange = (label: string, value: number) => {
    const _zoneConfig = cloneDeep(zoneConfig);
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits = {
      ..._zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits,
      [label]: value,
    };
    setZoneConfig(_zoneConfig);
  };

  return (
    <div className={MAIN_CLASS}>
      {labels.map((label, index) => (
        <ModuleLabelListItem
          key={label}
          index={index + 1}
          listLength={labels.length}
          name={label}
          limit={zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits[label]}
          onDragStart={setCurrentDraggingIndex}
          onDragEnd={() => setCurrentDraggingIndex(undefined)}
          onLimitValueChange={(value) => handleLimitValueChange(label, value)}
          onDeleteClick={(index) => handleDeleteLabel(index - 1)}
          onChangePosition={(oldIndex, newIndex) => handleChangeLabelPosition(oldIndex - 1, newIndex - 1)}
          showDropzone={
            currentDraggingIndex !== undefined &&
            currentDraggingIndex !== index + 1 &&
            currentDraggingIndex !== index + 2
          }
        />
      ))}
    </div>
  );
}

export default ModuleLabelsList;
