import React from 'react';
import { TIconProps } from '../types';

function BasicIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1992_106365)">
        <path
          d="M22.4309 10.5899L13.4209 1.57993C12.6709 0.829928 11.3509 0.819928 10.5909 1.57993L1.59086 10.5799C0.810859 11.3599 0.810859 12.6199 1.59086 13.3999L10.5909 22.3999C10.9809 22.7899 11.4909 22.9799 12.0009 22.9799C12.5109 22.9799 13.0209 22.7899 13.4109 22.3999L22.4009 13.4099C23.1909 12.6499 23.2009 11.3899 22.4309 10.5899ZM12.0109 20.9899L3.01086 11.9899L12.0109 2.98993L21.0109 11.9899L12.0109 20.9899ZM8.00086 10.9999V14.9999H10.0009V11.9999H14.0009V14.4999L17.5009 10.9999L14.0009 7.49993V9.99993H9.00086C8.45086 9.99993 8.00086 10.4499 8.00086 10.9999Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1992_106365">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BasicIcon;
