type PushNotification = {
  pushId: string;
  totalAllNotifications: number;
  totalDelivery?: number;
  totalFailed: number;
  totalOpen: number;
  totalSent: number;
  uniqueAllNotifications: number;
  uniqueDelivery?: number;
  uniqueFailed: number;
  uniqueOpen: number;
  uniqueOpenRate: number;
  uniqueSent: number;
};

export type GraphData = {
  id: string;
  label?: string;
  value: number;
  color?: string;
};

export type EngagementParams = {
  lastFrom: number;
  lastTo: number;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: string;
  search?: string;
  labelsFormula?: string;
};

export type PushNotificationResponse = {
  tenantID: string;
  keywords?: string;
  body: {
    payload: {
      category: string;
      entity: string;
      action: string;
      alert: {
        [key: string]: string;
      };
      title: {
        [key: string]: string;
      };
      data: {
        actionScheme?: {
          [key: string]: string;
        };
        contentId?: {
          value?: string;
          entity?: string;
          metadata?: {
            [key: string]: string;
          };
          pushPayloadTypeId?: string;
          url: {
            [key: string]: string;
          };
        };
      };
    };
    weight: number;
  }[];
  backgroundPushType: number;
  pushRequestID: string;
  createdAt: number;
  name: string;
  tags?: string;
  labels?: string;
  updatedAt: number;
  pushType: number;
  rules: {
    ruleID: string;
    result: number;
  }[];
  options: {
    max: number;
    delay: number;
    cooldown: number;
    sampling: number;
    limit: number;
  };
  executionHistory: {
    lastStartDate: number;
    timezone: {
      name: string;
      offset: number;
    };
  };
  creatorID: string;
  userScope: boolean;
  isActive: boolean;
  isSent: boolean;
  schedule: {
    scheduler: {
      contextualStart: string;
      start: number;
      end: number;
      repeat: {
        frequencyType: string;
        frequency: number;
        limit: number;
      };
      isRelative: boolean;
      timezone: {
        name: string;
        offset: number;
      };
    };
  };
  isDraft: boolean;
  hasMessageInterval: boolean;
  visibility: string;
  templateId: string;
};

export type PushNotificationsEngagementResponse = {
  data: {
    NotificationFailed: number;
    NotificationOpened: number;
    NotificationSent: number;
    Pushes: PushNotification[];
  };

  pagination: {
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    totalRecords: number;
  };
};

export type ContextRulesEngagementResponse = {
  data: {
    ruleId: string;
    ruleName: string;
    status: string;
    totalReach: number;
    uniqueReach: number;
    uniqueReachRate: number;
  }[];

  pagination: {
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    totalRecords: number;
  };
};

export type ContentsEngagementResponse = {
  data: {
    contentId: string;
    totalViews: number;
    uniqueViews: number;
    uniqueEngagements: number;
    uniqueFulfillments: number;
    engagementRate: number;
    fulfillmentRate: number;
  }[];

  pagination: {
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
    totalRecords: number;
  };
};

export type AnalyticsOverview = {
  totalUsers: {
    value: number;
    change: number;
  };
  experiencesLaunched: {
    value: number;
    change: number;
  };
  avgEngagementRate: {
    value: number;
    change: number;
  };
  pushOptIn: {
    ios: number;
    android: number;
  };
  pushOpenRate: {
    value: number;
    change: number;
    platformSplit: {
      ios: number;
      android: number;
    };
  };
  clickThroughRate: {
    value: number;
    change: number;
    platformSplit: {
      ios: number;
      android: number;
    };
  };
  totalDevices: {
    value: number;
    change: number;
    platformSplit: {
      ios: number;
      android: number;
    };
  };
  locationOptIn: {
    ios: number;
    android: number;
    web: number;
    other: number;
  };
  start: number;
  end: number;
  prevStart: number;
  prevEnd: number;
};

export enum LABEL_RULE {
  CONTAINS_ALL_OF = 0,
  CONTAINS_ANY_OF = 1,
}
