import { ContentActionTypes, ContentInstancesState } from './content.type';
import { SetContentAction, ClearJourneyAction, ActionTypes } from 'store/actionTypes';

const initialState: ContentInstancesState = {
  contentInstances: [],
  createdInstances: [],
};

export default (state: ContentInstancesState = initialState, action: SetContentAction | ClearJourneyAction) => {
  switch (action.type) {
    case ContentActionTypes.SET_CONTENT_INSTANCES:
      return {
        ...state,
        contentInstances: action.payload.contentInstances,
      };
    case ContentActionTypes.SET_CREATED_CONTENT_INSTANCES:
      return {
        ...state,
        createdInstances: action.payload.contentInstances,
      };
    case ActionTypes.CLEAR_JOURNEY:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};
