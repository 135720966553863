import { PushInstancePayload, PushPayloadType, PushConstraints } from 'interface/push/push.interface';
import { Dependencies } from 'interface/common.interface';

// currently this uses preexisting interfaces in the interest of time
// in the future, this should be its own type
export interface PushState {
  byRefId: {
    [refId: string]: PushStateItem;
  };
  allRefId: string[];
  stepRefIdMap: {
    [idx: number]: string;
  };
}

export type PushDependencies = { content: string | undefined; rule: string | undefined };

export enum PUSH_OPTION_DEFAULTS {
  MAX = 1,
  COOLDOWN = 60,
  DELAY = 0,
  LIMIT = 0,
}

export enum PUSH_ACTION_TYPES {
  PUSH_NOTIFICATION = 'push-notification',
  TRIGERRED_PUSH = 'triggered-push',
  BROADCAST_PUSH = 'broadcast-push',
}

export interface PushStateItem extends Omit<PushInstancePayload, 'body' | 'rules' | 'options'> {
  refId: string;
  actionType: PUSH_ACTION_TYPES;
  dependencies?: Dependencies;
  templateId?: string;
  id?: string;
  pushPayloadType?: PushPayloadType;
  ruleId?: string; // rules
  ruleTriggerType?: number; // rules
  contentId?: string; // data
  pushPayloadTypeId?: string; // data
  url?: {
    [language: string]: string;
  }; // data
  actionLinkScheme?: {
    [language: string]: string;
  }; // data
  actionLinkType?: {
    displayName: string;
    val: string;
    validationPrefix: string;
  };
  contentBlueprintId?: string; // data
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customPayloadData?: any; //data, for custom payload types
  action?: string; // body
  alert?: {
    [language: string]: string;
  }; // body
  category?: string; // body
  entity?: string; // body
  title?: {
    [language: string]: string;
  }; // body
  weight?: number; // body
  hasError?: boolean; //UI
  enableOptionalNode?: boolean; //UI
  // adjust to fit back end 400s
  errors?: {
    path: string;
    error: string;
  }[];
  max?: number;
  cooldown?: number;
  sampling?: number;
  remain?: boolean;
  delay?: number;
  limit?: number;
  // template attributes
  constraints?: PushConstraints;
  isOptional?: boolean;
}
