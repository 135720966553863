import { selectStepRule } from 'store/rule/rule.selector';
import { getPrimaryContent } from 'store/content/content.selector';
import { UpdatePushSimpleAction } from 'store/actionTypes';
import { PushStateItem, PUSH_OPTION_DEFAULTS as DEFAULTS, PUSH_ACTION_TYPES } from 'store/push/push.type';
import { PushPayloadType } from 'interface/push/push.interface';
import { Dependency } from 'store/journey/journey.type';

import { getStepRuleSyntheticId } from 'store/helper';
import type { RootState, AppThunkDispatch } from 'store/store';
import { genUUID } from 'helpers/common.helper';

/**
 * Creates a fresh push in state.
 * */
export function insertNewPush(stepIdx: number) {
  return (dispatch: AppThunkDispatch, getState: () => RootState) => {
    const defaults: PushStateItem = generateDefaultPushStateItem();
    const primaryContentRefId = getPrimaryContent(getState().te, stepIdx);
    const stepRuleRefId = selectStepRule(getState().te, stepIdx)?.refId;
    const dependencies: Dependency[] = [];

    if (primaryContentRefId) {
      defaults.contentId = '{{dep.content-id.0}}';
      dependencies.push({ var: '{{dep.content-id.0}}', refId: primaryContentRefId });
    }

    if (stepRuleRefId) {
      defaults.ruleId = '{{dep.rule-id.0}}';
      dependencies.push({ var: '{{dep.rule-id.0}}', refId: getStepRuleSyntheticId(stepIdx) });
    }

    dispatch({ type: 'INSERT_PUSH', payload: { defaults, stepIdx, dependencies } });
  };
}

/**
 * Action creator for updating push fields
 * */
export function updatePushSimple(
  payload: UpdatePushSimpleAction['payload'],
  stepIdx: number,
  pushType: PushPayloadType = 'basic',
) {
  return (dispatch: AppThunkDispatch, getState: () => RootState) => {
    const primaryContentRefId = getPrimaryContent(getState().te, stepIdx);
    dispatch({
      type: 'UPDATE_PUSH_SIMPLE',
      payload: {
        ...payload,
        fields: {
          ...payload.fields,
          contentId: pushType === 'content' && primaryContentRefId ? '{{dep.content-id.0}}' : undefined,
        },
        stepIdx,
      },
    });
  };
}

function generateDefaultPushStateItem(): PushStateItem {
  return {
    name: '',
    refId: genUUID(),
    actionType: PUSH_ACTION_TYPES.TRIGERRED_PUSH,
    weight: 100,
    pushType: 3,
    ruleTriggerType: 1,
    max: DEFAULTS.MAX,
    cooldown: DEFAULTS.COOLDOWN,
  };
}

export type InsertPushPayload = {
  stepIdx?: number;
  pushTypeId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deliveryOptions?: any;
  contentRefId?: string;
};
