import BaseAPI from './base.api';

import { AuditPayload } from 'interface/audit/audit.interface';

export default class AuditApi extends BaseAPI {
  private ROUTES = {
    allAudits: '/analytics/audit',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAudit(opts: any): Promise<AuditPayload> {
    return this.GET(this.ROUTES.allAudits, opts);
  }
}
