import React from 'react';
import { MODULE_LAYOUTS } from '../types';
import { ReactComponent as Vertical } from 'assets/icons/predefined-layout-vertical.svg';
import { ReactComponent as Horizontal } from 'assets/icons/predefined-layout-horizontal.svg';
import { ReactComponent as Expose } from 'assets/icons/predefined-layout-expose.svg';
import { ReactComponent as AndroidPink } from 'assets/icons/android_pink.svg';

type PredefinedLayoutIconType = {
  predefinedIconTitle: string;
};

const PredefinedLayoutIcon = ({ predefinedIconTitle }: PredefinedLayoutIconType) => {
  switch (predefinedIconTitle) {
    case MODULE_LAYOUTS.VERTICAL:
      return <Vertical />;
    case MODULE_LAYOUTS.HORIZONTAL:
      return <Horizontal />;
    case MODULE_LAYOUTS.EXPOSE:
      return <Expose />;
    default:
      return <AndroidPink />;
  }
};

export default PredefinedLayoutIcon;
