// hook for pulling locations

import { useEffect } from 'react';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { fetchLocationsThunk } from 'store/location/location.thunk';

export default function useLocations() {
  const locations = useSelector((state) => state.location.locations);
  const areLocationsLoading = useSelector((state) => state.location.areLocationsLoading);

  const numMatchingLocations = useSelector((state) => state.location.numMatchingLocations);
  const areMatchingLocationsLoading = useSelector((state) => state.location.areMatchingLocationsLoading);

  const isLocationSaving = useSelector((state) => state.location.isLocationSaving);
  const savedLocation = useSelector((state) => state.location.savedLocation);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocationsThunk());
  }, [dispatch]);

  return {
    locations,
    areLocationsLoading,
    numMatchingLocations,
    areMatchingLocationsLoading,
    isLocationSaving,
    savedLocation,
  };
}
