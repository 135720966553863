/* eslint-disable @typescript-eslint/no-unused-vars */
import { RuleState, RuleStateItem, SchedulerStateItem } from 'store/rule/rule.type';
import { createReducer, getStepRuleSyntheticId, updateObject } from 'store/helper';
import { serializeRuleSchedule } from 'helpers/templated-experience.helper';
import { Step } from 'interface/experience/experience.interface';
import { JourneyRule, RuleType, Scheduler } from 'interface/rule/rule.interface';
import { BROADCASTABLE_TEMPLATES } from 'components/ExperienceCanvas/types';
import {
  UpdatePushAction,
  InsertRuleAction,
  RemoveRuleAction,
  SaveRuleAction,
  UpsertScheduleAction,
  UpdateRuleAction,
  SaveContextualSchedulerAction,
  RemoveStepAction,
  UpdateRuleTriggerAction,
  ClearScheduleAction,
} from 'store/actionTypes';
import { SetTemplateAction, SetInstanceAction } from 'store/templated-experience/templated-experience.type';

// temporarily leaving the scheduler object in main rule for compatibility
function _flattenRuleObject(
  rule: JourneyRule,
  ruleType: RuleType,
  isTemplate: boolean,
  enableOptionalNode: boolean,
): RuleStateItem {
  return {
    ...rule.payload,
    name: isTemplate ? '' : rule.payload?.name || '',
    headName: isTemplate ? '' : rule.payload?.headName || '',
    refId: rule.refId ?? '',
    dependencies: rule.dependencies,
    templateId: rule.templateId,
    id: rule.id,
    ruleType,
    enableOptionalNode,
    isOptional: isTemplate ? rule.isOptional : true,
  };
}

function _flattenRuleSchedule(scheduler: Scheduler): SchedulerStateItem {
  const retVal = {
    ...scheduler,
    ...scheduler.contextualRule,
    ...scheduler.repeat,
  };

  delete retVal.contextualRule;
  delete retVal.repeat;

  return retVal;
}

function _generateFlattenedRule(
  rule: JourneyRule,
  stepIdx: number,
  ruleType: RuleType,
  byRefId: RuleState['byRefId'],
  allRefId: RuleState['allRefId'],
  startSchedulerMap: RuleState['startSchedulerMap'],
  endSchedulerMap: RuleState['endSchedulerMap'],
  enableOptionalNode = true,
  isTemplate = false,
  shouldSyncStartScheduleWithRuleBody = false,
) {
  const refId = rule.refId;
  if (!refId) return;
  const synthId = getStepRuleSyntheticId(stepIdx);
  byRefId[refId] = _flattenRuleObject(rule, ruleType, isTemplate, enableOptionalNode);
  const startSched = rule.payload?.schedule?.startScheduler;
  if (startSched) {
    startSchedulerMap[synthId] = _flattenRuleSchedule(startSched);

    if (shouldSyncStartScheduleWithRuleBody) {
      startSchedulerMap[synthId] = {
        ...startSchedulerMap[synthId],
        logic: rule.payload?.logic || '',
        predicates: rule.payload?.predicates || {},
        modifier: '',
        target: '',
      };
    }
  }
  const endSched = rule.payload?.schedule?.endScheduler;
  if (endSched) endSchedulerMap[synthId] = _flattenRuleSchedule(endSched);
  allRefId.push(refId);
}

function _shouldEnableOptionalNode(
  restrictedRule: JourneyRule | null,
  preferredRule: JourneyRule | null,
  templateType?: string,
) {
  const isNotBroadcastable = templateType && !BROADCASTABLE_TEMPLATES.includes(templateType);
  const isRestrictedRuleOptional = !!restrictedRule?.isOptional;
  const isRestrictedRulePopulated = !!restrictedRule?.payload?.logic;
  const shouldEnableRestrictedRule = (restrictedRule && !isRestrictedRuleOptional) || isRestrictedRulePopulated;
  const isPreferredRuleOptional = !!preferredRule?.isOptional;
  const isPreferredRulePopulated = !!preferredRule?.payload?.logic;
  const shouldEnablePreferredRule = (preferredRule && !isPreferredRuleOptional) || isPreferredRulePopulated;

  return isNotBroadcastable || shouldEnableRestrictedRule || shouldEnablePreferredRule;
}

function setInstance(state: RuleState, action: SetInstanceAction) {
  if (!action.payload.updateNewReducer) return state;

  const byRefId: RuleState['byRefId'] = {};
  const allRefId: RuleState['allRefId'] = [];
  const startSchedulerMap: RuleState['startSchedulerMap'] = {};
  const endSchedulerMap: RuleState['endSchedulerMap'] = {};
  const bySyntheticId: RuleState['bySyntheticId'] = {};

  action.payload?.instance?.steps.forEach((step: Step, idx: number) => {
    const restrictedRule = step.audience.restricted;
    const preferredRule = step.audience.preferred;
    const synthId = getStepRuleSyntheticId(idx);
    bySyntheticId[synthId] = [];
    const templateType = action.payload?.instance?.metadata?.templateType;
    const enableOptionalNode = _shouldEnableOptionalNode(restrictedRule, preferredRule, templateType);

    if (preferredRule?.refId) {
      _generateFlattenedRule(
        preferredRule,
        idx,
        'preferred',
        byRefId,
        allRefId,
        startSchedulerMap,
        endSchedulerMap,
        enableOptionalNode,
      );
      bySyntheticId[synthId].push(preferredRule.refId);
    }

    if (restrictedRule?.refId) {
      _generateFlattenedRule(
        restrictedRule,
        idx,
        'restricted',
        byRefId,
        allRefId,
        startSchedulerMap,
        endSchedulerMap,
        enableOptionalNode,
      );
      bySyntheticId[synthId].push(restrictedRule.refId);
    }
  });

  return {
    byRefId,
    allRefId,
    startSchedulerMap,
    endSchedulerMap,
    bySyntheticId,
  };
}

function setTemplate(state: RuleState, action: SetTemplateAction) {
  const preserveInstance = action.payload.preserveInstance;

  const byRefId: RuleState['byRefId'] = preserveInstance ? state.byRefId : {};
  const allRefId: RuleState['allRefId'] = preserveInstance ? state.allRefId : [];
  const startSchedulerMap: RuleState['startSchedulerMap'] = preserveInstance ? state.startSchedulerMap : {};
  const endSchedulerMap: RuleState['endSchedulerMap'] = preserveInstance ? state.endSchedulerMap : {};
  const bySyntheticId: RuleState['bySyntheticId'] = preserveInstance ? state.bySyntheticId : {};

  action.payload?.template?.steps.forEach((step: Step, idx: number) => {
    const restrictedRule = step.audience.restricted;
    const preferredRule = step.audience.preferred;
    const synthId = getStepRuleSyntheticId(idx);
    bySyntheticId[synthId] = preserveInstance ? bySyntheticId[synthId] : [];
    const templateType = action.payload?.template?.metadata?.templateType;
    const enableOptionalNode = _shouldEnableOptionalNode(restrictedRule, preferredRule, templateType);

    const isTemplate = true;
    const shouldSyncStartScheduleWithRuleBody =
      step.constraints?.audienceConstraints?.syncStartScheduleWithRuleBody ?? false;

    if (preferredRule?.refId) {
      if (preserveInstance) {
        if (byRefId[preferredRule.refId]) {
          byRefId[preferredRule.refId] = {
            ...state.byRefId[preferredRule.refId],
            isOptional: preferredRule.isOptional,
            constraints: preferredRule.payload?.constraints,
          };
        } else {
          _generateFlattenedRule(
            preferredRule,
            idx,
            'preferred',
            byRefId,
            allRefId,
            startSchedulerMap,
            endSchedulerMap,
            enableOptionalNode,
            isTemplate,
            shouldSyncStartScheduleWithRuleBody,
          );
          bySyntheticId[synthId].push(preferredRule.refId);
        }
      } else {
        _generateFlattenedRule(
          preferredRule,
          idx,
          'preferred',
          byRefId,
          allRefId,
          startSchedulerMap,
          endSchedulerMap,
          enableOptionalNode,
          isTemplate,
          shouldSyncStartScheduleWithRuleBody,
        );
        bySyntheticId[synthId].push(preferredRule.refId);
      }
    }

    if (restrictedRule?.refId) {
      if (preserveInstance) {
        if (byRefId[restrictedRule.refId]) {
          byRefId[restrictedRule.refId] = {
            ...state.byRefId[restrictedRule.refId],
            isOptional: restrictedRule.isOptional,
            constraints: restrictedRule.payload?.constraints,
          };
        } else {
          _generateFlattenedRule(
            restrictedRule,
            idx,
            'restricted',
            byRefId,
            allRefId,
            startSchedulerMap,
            endSchedulerMap,
            enableOptionalNode,
            isTemplate,
            shouldSyncStartScheduleWithRuleBody,
          );
          bySyntheticId[synthId].push(restrictedRule.refId);
        }
      } else {
        _generateFlattenedRule(
          restrictedRule,
          idx,
          'restricted',
          byRefId,
          allRefId,
          startSchedulerMap,
          endSchedulerMap,
          enableOptionalNode,
          isTemplate,
          shouldSyncStartScheduleWithRuleBody,
        );
        bySyntheticId[synthId].push(restrictedRule.refId);
      }
    }
  });

  return {
    byRefId,
    allRefId,
    startSchedulerMap,
    endSchedulerMap,
    bySyntheticId,
  };
}

function insertRule(state: RuleState, action: InsertRuleAction) {
  // reminder logic/payload copying should technically never happen here.
  const synthId = getStepRuleSyntheticId(action.payload.stepIdx);
  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [action.payload.refId]: {
        ...action.payload.rulePayload,
        refId: action.payload.refId,
        ruleType: action.payload.type,
        enableOptionalNode: true,
        isOptional: true,
      },
    },
    allRefId: [...state.allRefId, action.payload.refId],
    bySyntheticId: {
      ...state.bySyntheticId,
      [synthId]: [...state.bySyntheticId[synthId], action.payload.refId],
    },
  };
}

function updatePush(state: RuleState, action: UpdatePushAction) {
  if (action.payload.templateType === 'reminder') {
    if (action.payload.ruleRefId) {
      const scheduler = state.byRefId[action.payload.ruleRefId].schedule?.startScheduler;
      const delivery = serializeRuleSchedule(action.payload.deliveryOptions);
      return {
        ...state,
        byRefId: {
          ...state.byRefId,
          [action.payload.ruleRefId]: {
            ...state.byRefId[action.payload.ruleRefId],
            schedule: {
              ...state.byRefId[action.payload.ruleRefId].schedule,
              startScheduler: {
                ...scheduler,
                repeat: {
                  ...delivery?.repeat,
                },
                contextualRule: {
                  ...scheduler?.contextualRule,
                  modifier: delivery.modifier.toString(),
                },
              },
            },
          },
        },
      };
    }
  }
  return state;
}

function removeRule(state: RuleState, action: RemoveRuleAction) {
  if (!state.allRefId.includes(action.payload.refId)) return state;
  const synthId = getStepRuleSyntheticId(action.payload.stepIdx);
  const { [action.payload.refId]: _, ...newByRefId } = state.byRefId;
  const { [synthId]: __, ...newSS } = state.startSchedulerMap;
  const { [synthId]: ___, ...newES } = state.endSchedulerMap;
  return {
    byRefId: newByRefId,
    allRefId: state.allRefId.filter((item) => item !== action.payload.refId),
    startSchedulerMap: newSS,
    endSchedulerMap: newES,
    bySyntheticId: {
      ...state.bySyntheticId,
      [synthId]: state.bySyntheticId[synthId]?.filter((item) => item !== action.payload.refId),
    },
  };
}

function removeStep(state: RuleState, action: RemoveStepAction) {
  const pref = action.payload.rule.preferred;
  const res = action.payload.rule.restricted;

  if (!action.payload.rule.preferred && !action.payload.rule.restricted) return state;

  const { [pref]: _, [res]: __, ...newByRefId } = state.byRefId;

  const synthId = getStepRuleSyntheticId(action.payload.stepIdx);
  const { [synthId]: ___, ...newSS } = state.startSchedulerMap;
  const { [synthId]: ____, ...newES } = state.endSchedulerMap;
  const { [synthId]: _____, ...newBSI } = state.bySyntheticId;

  return {
    byRefId: newByRefId,
    allRefId: state.allRefId.filter((item) => item !== pref && item !== res),
    startSchedulerMap: newSS,
    endSchedulerMap: newES,
    bySyntheticId: newBSI,
  };
}

function updateRule(state: RuleState, action: SaveRuleAction) {
  let schedulerMap: RuleState['startSchedulerMap'] | undefined;
  const synthId = getStepRuleSyntheticId(action.payload.stepIdx);

  if (action.payload.shouldSyncStartScheduleWithRuleBody || action.payload.templateType === 'reminder') {
    schedulerMap = {
      ...state.startSchedulerMap,
      [synthId]: {
        ...state.startSchedulerMap[synthId],
        logic: action.payload.rulePayload?.logic || '',
        predicates: action.payload.rulePayload?.predicates || {},
        modifier: '',
        target: '',
      },
    };
  }

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [action.payload.refId]: {
        ...state.byRefId[action.payload.refId],
        refId: action.payload.refId,
        ruleType: action.payload.type,
        visibility: 'system',
        ...action.payload.rulePayload,
      },
    },
    startSchedulerMap: schedulerMap ? schedulerMap : state.startSchedulerMap,
  };
}

function saveContextualScheduler(state: RuleState, action: SaveContextualSchedulerAction) {
  const { stepIdx, type, ...payload } = action.payload;
  const synthId = getStepRuleSyntheticId(stepIdx);
  return {
    ...state,
    startSchedulerMap: {
      ...state.startSchedulerMap,
      [synthId]: ['start', 'both'].includes(type)
        ? {
            ...state.startSchedulerMap[synthId],
            ...payload,
          }
        : state.startSchedulerMap[synthId],
    },
    endSchedulerMap: {
      ...state.endSchedulerMap,
      [synthId]: ['end', 'both'].includes(type)
        ? {
            ...state.endSchedulerMap[synthId],
            ...payload,
          }
        : state.endSchedulerMap[synthId],
    },
  };
}

function upsertSchedule(state: RuleState, action: UpsertScheduleAction) {
  const { stepIdx, type, ...fields } = action.payload;
  const synthId = getStepRuleSyntheticId(stepIdx);

  return {
    ...state,
    startSchedulerMap: {
      ...state.startSchedulerMap,
      [synthId]:
        type === 'start' || type === 'both'
          ? {
              ...state.startSchedulerMap[synthId],
              ...fields,
            }
          : state.startSchedulerMap[synthId],
    },
    endSchedulerMap: {
      ...state.endSchedulerMap,
      [synthId]:
        type === 'end' || type === 'both'
          ? {
              ...state.endSchedulerMap[synthId],
              ...fields,
            }
          : state.endSchedulerMap[synthId],
    },
  };
}

function clearSchedule(state: RuleState, action: ClearScheduleAction) {
  const { stepIdx, type } = action.payload;
  const synthId = getStepRuleSyntheticId(stepIdx);

  const newState = { ...state };

  if (type === 'start') {
    delete newState.startSchedulerMap[synthId];
  } else if (type === 'end') {
    delete newState.endSchedulerMap[synthId];
  } else if (type === 'both') {
    delete newState.startSchedulerMap[synthId];
    delete newState.endSchedulerMap[synthId];
  }

  return newState;
}

function updateRuleSimple(state: RuleState, action: UpdateRuleAction): RuleState {
  const refId = action.payload.refId;
  const updatedFields = action.payload.fields;
  const newRuleStateItem = { ...state.byRefId[refId] };

  for (const [key, value] of Object.entries(updatedFields)) {
    const ruleKey = key as keyof RuleStateItem;
    if (typeof value === 'object') {
      // typescript why.
      (newRuleStateItem[ruleKey] as typeof value) = updateObject(newRuleStateItem[ruleKey], value) as typeof value;
    } else {
      (newRuleStateItem[ruleKey] as typeof value) = value;
    }
  }

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [refId]: newRuleStateItem,
    },
  };
}

function updateRuleTrigger(state: RuleState, action: UpdateRuleTriggerAction) {
  const omitRule = action.payload.type === 'omit';
  const rulesToUpdate = state.bySyntheticId[getStepRuleSyntheticId(action.payload.stepIdx)];
  let byRefId = state.byRefId;

  rulesToUpdate?.forEach((refId) => {
    byRefId = {
      ...byRefId,
      [refId]: {
        ...byRefId[refId],
        enableOptionalNode: !omitRule,
      },
    };
  });

  return {
    ...state,
    byRefId: byRefId,
  };
}

function clearJourney() {
  return initialState;
}

const initialState: RuleState = {
  byRefId: {},
  allRefId: [],
  startSchedulerMap: {},
  endSchedulerMap: {},
  bySyntheticId: {},
};

export default createReducer(initialState, {
  SET_INSTANCE: setInstance,
  SET_TEMPLATE: setTemplate,
  INSERT_RULE: insertRule,
  UPDATE_RULE: updateRule,
  REMOVE_RULE: removeRule,
  UPDATE_RULE_SIMPLE: updateRuleSimple,
  UPDATE_PUSH: updatePush,
  UPDATE_RULE_TRIGGER: updateRuleTrigger,
  UPDATE_SCHEDULE: upsertSchedule,
  CLEAR_SCHEDULE: clearSchedule,
  SAVE_CONTEXTUAL_SCHEDULER: saveContextualScheduler,
  CLEAR_JOURNEY: clearJourney,
  REMOVE_STEP: removeStep,
});
