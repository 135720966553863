export type TEntityType = {
  key: string;
  value: string;
  name: string;
  isSelected?: boolean;
};

export enum ENTITY_DD_ITEMS {
  EXPERIENCE = 'experience',
  AUDIENCES = 'push-content',
  CONTENT = 'content',
  PUSH_NOTIFICATIONS = 'push',
}

export enum ENTITY_OPTIONS {
  ALL = 'all',
  LABEL = 'label',
  SPECIFIC = 'specific',
}

export const entityDropDownOptions: TEntityType[] = [
  // { key: ENTITY_DD_ITEMS.EXPERIENCE, value: ENTITY_DD_ITEMS.EXPERIENCE, name: 'Experience (as a whole)' }, // TODO: In Future experience will be part of the dropdown
  { key: ENTITY_DD_ITEMS.PUSH_NOTIFICATIONS, value: ENTITY_DD_ITEMS.PUSH_NOTIFICATIONS, name: 'Push' },
  { key: ENTITY_DD_ITEMS.CONTENT, value: ENTITY_DD_ITEMS.CONTENT, name: 'Content' },
  { key: ENTITY_DD_ITEMS.AUDIENCES, value: ENTITY_DD_ITEMS.AUDIENCES, name: 'All' },
];

enum RULE_DD_ITEMS {
  AND = 'and',
  OR = 'or',
}

export const ruleDDOption = [
  { key: RULE_DD_ITEMS.AND, value: RULE_DD_ITEMS.AND, name: 'AND' },
  { key: RULE_DD_ITEMS.OR, value: RULE_DD_ITEMS.OR, name: 'OR' },
];

export enum VISUALIZER_OPTIONS {
  LINE = 'line',
  BAR = 'bar',
  PIE = 'pie',
}

export enum TIMEFRAME_INTERVAL_OPTIONS {
  HOURLY = 'hour',
  DAILY = 'day',
  WEEKLY = 'week',
  MONTHLY = 'month',
  ANNUALLY = 'year',
}

export const timeFrameIntervalOption = [
  { label: 'Hourly', value: TIMEFRAME_INTERVAL_OPTIONS.HOURLY, duration: 3600 },
  { label: 'Daily', value: TIMEFRAME_INTERVAL_OPTIONS.DAILY, duration: 3600 * 24 },
  { label: 'Weekly', value: TIMEFRAME_INTERVAL_OPTIONS.WEEKLY, duration: 3600 * 24 * 7 },
  { label: 'Monthly', value: TIMEFRAME_INTERVAL_OPTIONS.MONTHLY, duration: 3600 * 24 * 30.5 },
  { label: 'Annually', value: TIMEFRAME_INTERVAL_OPTIONS.ANNUALLY, duration: 3600 * 24 * 365 },
];

export enum CALC_METHOD_OPTIONS {
  TOTAL = 'total',
  TOTAL_POPULATION_PERCENTAGE = 'total_population',
  TIMEFRAME_INTERVAL_PERCENTAGE = 'timeframe_interval_percentage',
}

export enum CALC_DD_ITEMS {
  ENGAGED = 'engaged',
  SENT = 'sent',
  VIEWED = 'viewed',
}

export const calcMethodDDOptions: TEntityType[] = [
  { key: CALC_DD_ITEMS.ENGAGED, value: CALC_DD_ITEMS.ENGAGED, name: 'Engaged' },
  { key: CALC_DD_ITEMS.SENT, value: CALC_DD_ITEMS.SENT, name: 'Sent' },
  { key: CALC_DD_ITEMS.VIEWED, value: CALC_DD_ITEMS.VIEWED, name: 'Viewed' },
];

export enum METRICS_DD_ITEMS {
  ALL = 'all',
  ANY = 'any',
  CUSTOM = 'custom',
}

export const metricsDDOption = [
  { key: METRICS_DD_ITEMS.ALL, value: METRICS_DD_ITEMS.ALL, name: 'All' },
  { key: METRICS_DD_ITEMS.ANY, value: METRICS_DD_ITEMS.ANY, name: 'Any' },
  { key: METRICS_DD_ITEMS.CUSTOM, value: METRICS_DD_ITEMS.CUSTOM, name: 'Custom' },
];

export const COUNTRY_CODES = [
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AO',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BV',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'UM',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'YE',
  'YT',
  'ZA',
  'ZM',
  'ZW',
];
export const SEARCH_RANKINGS = {
  CASE_SENSITIVE_EQUAL: 7,
  EQUAL: 6,
  STARTS_WITH: 5,
  WORD_STARTS_WITH: 4,
  CONTAINS: 3,
  ACRONYM: 2,
  MATCHES: 1,
  NO_MATCH: 0,
};
