import './LabelsSlidePanel.scss';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { useSelector } from 'react-redux';
import LabelInput from 'components/LabelInput/LabelInput';
import React, { useMemo } from 'react';
import SlidingSidePanel from '../SlidingSidePanel/SlidingSidePanel';

interface TSettingsProps {
  isOpen: boolean;
  onClickClose: () => void;
}

const MAIN_CLASS = 'labels-sliding-panel';
const CLASSES = {
  SECTION: `${MAIN_CLASS}__section`,
  SECTION_TITLE: `${MAIN_CLASS}__section-title`,
  TOGGLE_SWITCH_WRAPPER: `${MAIN_CLASS}__toggle-switch-wrapper`,
};

function LabelsSlidePanel({ isOpen, onClickClose }: TSettingsProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { te } = useSelector((state: any) => state);
  const journeyState = te.journey;

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: 'Manage Labels',
        showCloseButton: true,
      },
      footerInfo: {
        primaryActionText: '',
        secondaryActionText: 'Close',
        secondaryActionHandler: onClickClose,
        showProvideFeedbackButton: true,
      },
      size: 'small',
      showFooter: false,
    }),
    [isOpen, onClickClose],
  );

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={MAIN_CLASS} aria-label="labels">
        <div className={CLASSES.SECTION} aria-label="add or remove experience labels">
          <div className={CLASSES.SECTION_TITLE}>Labels</div>
          <LabelInput experience={journeyState} />
        </div>
      </div>
    </SlidingSidePanel>
  );
}

export default LabelsSlidePanel;
