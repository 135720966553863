import { TEAContentEngagementAnalytics } from 'interface/templated-experience/analytics.interface';

export interface AnalyticsContentEngagmentState {
  records: TEAContentEngagementAnalytics;
  isLoading: boolean;
  errorMessage: string;
}

export const AnalyticsContentEngagementActionType = {
  SET_CONTENT_ENGAGEMENT_LOADING: 'SET_CONTENT_ENGAGEMENT_LOADING',
  SET_CONTENT_ENGAGEMENT_ERROR: 'SET_CONTENT_ENGAGEMENT_ERROR',
  SET_CONTENT_ENGAGEMENT_DATA: 'SET_CONTENT_ENGAGEMENT_DATA',
  RESET_CONTENT_ENGAGEMENT_DATA: 'RESET_CONTENT_ENGAGEMENT_DATA',
};

export interface SetContentEngagementDataAction {
  type: string;
  payload: TEAContentEngagementAnalytics;
}

export interface SetContentEngagementLoadingAction {
  type: string;
  payload: boolean;
}

export interface SetContentEngagementErrorAction {
  type: string;
  payload: string;
}
