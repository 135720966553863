import React from 'react';
import { CONTENT_OPTIONS } from 'components/ExperienceCanvas/types';
import './ContentOptionSelector.scss';
import ContentIcon from 'pages/ExperienceCanvas/assets/icons/ContentIcon';
import TemplateIcon from 'pages/ExperienceCanvas/assets/icons/TemplateIcon';

const MAIN_CLASS = 'content-option-selector';
const CLASSES = {
  OPTIONS: `${MAIN_CLASS}__options`,
  OPTION: `${MAIN_CLASS}__option`,
  OPTION_ICON: `${MAIN_CLASS}__option-icon`,
  OPTION_NAME: `${MAIN_CLASS}__option-name`,
  OPTION_DESC: `${MAIN_CLASS}__option-desc`,
};

enum CONTENT_OPTION {
  NAME = 'name',
  DESC = 'desc',
}

const CONTENT_OPTION_SELECTOR: { [key in CONTENT_OPTIONS]: { [key in CONTENT_OPTION]: string } } = {
  NEW: {
    name: 'Create a new content',
    desc: 'Create a new content from a content template',
  },
  EXISTING: {
    name: 'Use existing content',
    desc: 'Browse through all existing contents in your project to select your content',
  },
};

type ContentOptionSelectorProps = {
  selectedOption: CONTENT_OPTIONS;
  setSelectedOption: React.Dispatch<React.SetStateAction<CONTENT_OPTIONS>>;
};

const ContentOptionSelector: React.FC<ContentOptionSelectorProps> = ({ selectedOption, setSelectedOption }) => {
  const handleContentOptionSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedOption(value as CONTENT_OPTIONS);
  };

  return (
    <div className={MAIN_CLASS}>
      <ul className={CLASSES.OPTIONS}>
        <li className={CLASSES.OPTION} key={CONTENT_OPTIONS.NEW}>
          <input
            type="radio"
            value={CONTENT_OPTIONS.NEW}
            name="new"
            id={CONTENT_OPTIONS.NEW}
            onChange={handleContentOptionSelection}
            checked={selectedOption === CONTENT_OPTIONS.NEW}
          />
          <label htmlFor={CONTENT_OPTIONS.NEW}>
            <div className={CLASSES.OPTION_ICON}>
              <TemplateIcon />
            </div>
            <div className={CLASSES.OPTION_NAME}>{CONTENT_OPTION_SELECTOR.NEW.name}</div>
            <div className={CLASSES.OPTION_DESC}>{CONTENT_OPTION_SELECTOR.NEW.desc}</div>
          </label>
        </li>
        <li className={CLASSES.OPTION} key={CONTENT_OPTIONS.EXISTING}>
          <input
            type="radio"
            value={CONTENT_OPTIONS.EXISTING}
            name="new"
            id={CONTENT_OPTIONS.EXISTING}
            onChange={handleContentOptionSelection}
            checked={selectedOption === CONTENT_OPTIONS.EXISTING}
          />
          <label htmlFor={CONTENT_OPTIONS.EXISTING}>
            <div className={CLASSES.OPTION_ICON}>
              <ContentIcon />
            </div>
            <div className={CLASSES.OPTION_NAME}>{CONTENT_OPTION_SELECTOR.EXISTING.name}</div>
            <div className={CLASSES.OPTION_DESC}>{CONTENT_OPTION_SELECTOR.EXISTING.desc}</div>
          </label>
        </li>
      </ul>
    </div>
  );
};

export default ContentOptionSelector;
