import { Zone } from 'pages/Zones/types';
import { MODULE_LAYOUTS } from './types';

export const checkIfPredefinedLayout = (moduleLayout: MODULE_LAYOUTS, isCustomLayout: boolean) => {
  if (
    !isCustomLayout &&
    (moduleLayout === MODULE_LAYOUTS.VERTICAL ||
      moduleLayout === MODULE_LAYOUTS.HORIZONTAL ||
      moduleLayout === MODULE_LAYOUTS.EXPOSE)
  ) {
    return true;
  } else {
    return false;
  }
};

export const sanitizeZoneConfigPayload = (zones: Zone[]) => {
  // Remove the zone and any of its module if they don't have an `id`
  return zones.filter((zone) => {
    if (zone.id) {
      zone.modules = zone.modules.filter((module) => !!module.id);
      return true;
    } else {
      return false;
    }
  });
};
