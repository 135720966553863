/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { FlightTable, getIcon, FlightTooltip } from '@flybits/design-system';
import { numberWithCommas } from 'helpers/templated-experience.helper';
import { AnalyticsContentEngagmentState } from 'store/analytics/content-engagement/content-engagement.type';
import { ContentEngagement } from 'interface/templated-experience/analytics.interface';
import STErrorState from '../STErrorState/STErrorState';
import STLoadingState from '../STLoadingState/STLoadingState';

import './STContentEngagementTable.scss';

const MAIN_CLASS = 'content-engagement-table';

const contentTableSortByOptions: any = {
  name: 'contentName',
  type: 'contentTemplateType',
  totalViews: 'totalViews',
  uniqueViews: 'uniqueViews',
  totalClicks: 'totalClicks',
  uniqueClicks: 'uniqueClicks',
  uniqueClickRate: 'uniqueClickRate',
};
const contentTableSortOrderOptions = {
  asc: 'asc',
  desc: 'desc',
};
interface TableHeader {
  name: string;
  key: string;
  isVisible: boolean;
  isSortable: boolean;
  hideTooltip: boolean;
}

export default function STContentEngagementTable() {
  const contentEngagementState: AnalyticsContentEngagmentState = useSelector(
    (state) => state.analytics.analyticsContentEngagement,
  );
  const [expandedRow, setExpandedRow] = useState<any>({});
  const [contentTableSortBy, setContentTableSortBy] = useState(contentTableSortByOptions.totalViews);
  const [contentTableSortOrder, setContentTableSortOrder] = useState(contentTableSortOrderOptions.desc);

  const tableHeader: TableHeader[] = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
      isSortable: true,
      hideTooltip: false,
    },
    {
      name: 'Type',
      key: 'type',
      isVisible: true,
      isSortable: true,
      hideTooltip: false,
    },
    {
      name: 'Total Views',
      key: 'totalViews',
      isVisible: true,
      isSortable: true,
      hideTooltip: false,
    },
    {
      name: 'Unique Views',
      key: 'uniqueViews',
      isVisible: true,
      isSortable: true,
      hideTooltip: false,
    },
    {
      name: 'Total Clicks',
      key: 'totalClicks',
      isVisible: true,
      isSortable: true,
      hideTooltip: false,
    },
    {
      name: 'Unique Clicks',
      key: 'uniqueClicks',
      isVisible: true,
      isSortable: true,
      hideTooltip: false,
    },
    {
      name: 'Unique Click Rate',
      key: 'uniqueClickRate',
      isVisible: true,
      isSortable: true,
      hideTooltip: false,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableData: any[] = contentEngagementState.records.data
    .sort((item1: any, item2: any) => {
      if (item1[contentTableSortByOptions[contentTableSortBy]] > item2[contentTableSortByOptions[contentTableSortBy]]) {
        return contentTableSortOrder === contentTableSortOrderOptions.desc ? -1 : 1;
      }
      return contentTableSortOrder === contentTableSortOrderOptions.desc ? 1 : -1;
    })
    .map((tableRow: ContentEngagement) =>
      Object.assign({}, tableRow, {
        name:
          tableRow.components && tableRow.components.length ? (
            <FlightTooltip direction="top" description={tableRow.contentName}>
              {getIcon(expandedRow[tableRow.key] ? 'baselineKeyboardArrowUp' : 'baselineKeyboardArrowDown', {})}
              <span className="content-engagement-table__shrinkable-cell" title={tableRow.contentName}>
                {tableRow.contentName}
              </span>
            </FlightTooltip>
          ) : (
            tableRow.contentName
          ),
        type: (
          <FlightTooltip direction="top" description={tableRow.contentTemplateType}>
            <span className="content-engagement-table__shrinkable-cell">{tableRow.contentTemplateType}</span>
          </FlightTooltip>
        ),
        totalViews: numberWithCommas(tableRow.totalViews),
        uniqueViews: numberWithCommas(tableRow.uniqueViews),
        totalClicks: numberWithCommas(tableRow.totalClicks),
        uniqueClicks: numberWithCommas(tableRow.uniqueClicks),
        uniqueClickRate: `${Math.min(tableRow.uniqueClickRate * 100, 100).toFixed(2)}%`,
      }),
    );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDataSelect = (item: any) => {
    expandedRow[item.key]
      ? setExpandedRow({ ...expandedRow, [item.key]: undefined })
      : setExpandedRow({ ...expandedRow, [item.key]: 1 });
  };
  const handleHeaderSort = (header: TableHeader) => {
    if (!contentTableSortByOptions[header.key]) return;
    if (contentTableSortBy === header.key) {
      setContentTableSortOrder(
        contentTableSortOrder === contentTableSortOrderOptions.asc
          ? contentTableSortOrderOptions.desc
          : contentTableSortOrderOptions.asc,
      );
    } else {
      setContentTableSortOrder(contentTableSortOrderOptions.desc);
    }
    setContentTableSortBy(header.key);
  };

  Object.keys(expandedRow).forEach((rowKey) => {
    if (expandedRow[rowKey]) {
      const currentRowIdx = tableData.findIndex((item) => item.key.toString() === rowKey);
      tableData[currentRowIdx] &&
        tableData[currentRowIdx].components &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableData[currentRowIdx].components.forEach((component: any) => {
          tableData.splice(currentRowIdx + 1, 0, {
            key: `${rowKey}-${component.componentId}-${component.elementName}`,
            name: (
              <FlightTooltip direction="top" description={component.elementName}>
                <span className="content-engagement-table__shrinkable-cell content-engagement-table__component-name">
                  {component.elementName}
                </span>
              </FlightTooltip>
            ),
            type: (
              <FlightTooltip direction="top" description={component.elementType}>
                <span className="content-engagement-table__shrinkable-cell content-engagement-table__component-type">
                  {component.elementType}
                </span>
              </FlightTooltip>
            ),
            totalViews: '-',
            uniqueViews: '-',
            totalClicks: component.totalClicks,
            uniqueClicks: component.uniqueClicks,
            uniqueClickRate: '-',
          });
        });
    }
  });

  return (
    <div className={`${MAIN_CLASS}__container`}>
      <div className="top">
        <p className="headline"> Content Engagements </p>
      </div>
      {!!contentEngagementState.errorMessage ? (
        <STErrorState errorMessage={contentEngagementState.errorMessage} />
      ) : contentEngagementState.isLoading ? (
        <STLoadingState size="small" />
      ) : (
        <FlightTable
          className={MAIN_CLASS}
          tableHeaders={tableHeader}
          tableData={tableData}
          allowRowSelect
          handleDataSelect={handleDataSelect}
          handleHeaderSort={handleHeaderSort}
          sortByKey={contentTableSortBy}
          sortOrder={contentTableSortOrder}
          hasPaginationBeforeTable={false}
        />
      )}
    </div>
  );
}
