import LocationAPI from 'services/api/location.api';
import { setLocations, setMatchingLocationsWithLabels, setSaveLocation } from './location.action';
import { LocationPayload } from 'interface/location/location.interface';
import { isEmpty } from 'lodash';
import { RuleBuilderSaveLocationStatus, RULE_BUILDER_SAVE_LOCATION_STATUSES } from '@flybits/webapp-react-rule-builder';

const locationAPI = new LocationAPI();

export function fetchLocationsThunk(hydrateState?: boolean) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any, getState: any) => {
    const { location } = getState();

    if (!hydrateState && !isEmpty(location.locations)) {
      return location.locations;
    }

    try {
      dispatch(setLocations({ locations: [], areLocationsLoading: true }));
      const { data: locations } = await locationAPI.getLocations();
      dispatch(setLocations({ locations, areLocationsLoading: false }));

      return locations;
    } catch (e) {
      return;
    }
  };
}

export function fetchMatchingLocationsWithLabelsThunk(labelsFormula: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any, getState: any) => {
    const { location } = getState();

    if (location.matchingLocations) {
      return location.matchingLocations.length;
    }

    try {
      dispatch(setMatchingLocationsWithLabels({ numMatchingLocations: 0, areMatchingLocationsLoading: true }));
      const { data: matchingLocations } = await locationAPI.getLocations({ labelsFormula });
      dispatch(
        setMatchingLocationsWithLabels({
          numMatchingLocations: matchingLocations.length,
          areMatchingLocationsLoading: false,
        }),
      );

      return matchingLocations.length;
    } catch (e) {
      return;
    }
  };
}

export function saveLocationThunk(
  locationPayload: LocationPayload,
  callback: (saveStatusInfo?: RuleBuilderSaveLocationStatus) => void,
  locationId?: string,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    try {
      dispatch(setSaveLocation({ savedLocation: null, isLocationSaving: true }));

      const savedLocation = locationId
        ? await locationAPI.updateLocation(locationPayload, locationId)
        : await locationAPI.createLocation(locationPayload);

      dispatch(fetchLocationsThunk(true));
      dispatch(setSaveLocation({ savedLocation, isLocationSaving: false }));

      // Callback with success
      const saveLocationStatus: RuleBuilderSaveLocationStatus = {
        status: RULE_BUILDER_SAVE_LOCATION_STATUSES.SUCCESS,
        statusMessage: 'Location saved successfully',
      };
      callback(saveLocationStatus);

      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: 'New location created', type: 'success' },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(setSaveLocation({ savedLocation: null, isLocationSaving: false }));

      const status = RULE_BUILDER_SAVE_LOCATION_STATUSES.ERROR;
      let statusMessage = 'Something went wrong while saving the location';

      if (error.response?.data?.error) {
        const errorInfo = error.response.data.error;

        if (errorInfo.exceptionMessage?.toLowerCase().includes('loop is not valid')) {
          statusMessage = 'Overlapping edges';
        }
      }

      const saveLocationStatus: RuleBuilderSaveLocationStatus = {
        status,
        statusMessage,
      };

      // Callback with error
      callback(saveLocationStatus);
    }
  };
}
