import { LocationActionTypes, LocationState } from 'store/location/location.type';
import { LocationAction } from './location.action';

const initialState: LocationState = {
  locations: [],
  areLocationsLoading: false,
  numMatchingLocations: 0,
  areMatchingLocationsLoading: false,
  savedLocation: null,
  isLocationSaving: false,
};

export default (state: LocationState = initialState, action: LocationAction) => {
  switch (action.type) {
    case LocationActionTypes.SET_LOCATIONS:
      return {
        ...state,
        locations: action.payload.locations,
        areLocationsLoading: action.payload.areLocationsLoading,
      };
    case LocationActionTypes.SET_MATCHING_LOCATIONS_WITH_LABELS:
      return {
        ...state,
        numMatchingLocations: action.payload.numMatchingLocations,
        areMatchingLocationsLoading: action.payload.areMatchingLocationsLoading,
      };
    case LocationActionTypes.SET_SAVE_LOCATION:
      return {
        ...state,
        savedLocation: action.payload.savedLocation ?? null,
        isLocationSaving: action.payload.isLocationSaving,
      };

    default:
      return {
        ...state,
      };
  }
};
