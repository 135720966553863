import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FlightCheckbox, FlightTextInput } from '@flybits/design-system';

// Hooks and Components
import useDebounce from 'hooks/useDebounce';
import { useCustomModuleAnalyticsContext, TSpecificEntity } from '../../context/CustomModuleAnalyticsContext';
import { TEntityType } from '../../constants';
import '../CustomModuleAnalyticsContent.scss';
import Skeleton from 'react-loading-skeleton';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { rankedSearch } from 'helpers/common.helper';

const DEFAULT_CLASS = 'custom-module-analytics__main-content__measure_items';
const CONTAINER_CLASS = `${DEFAULT_CLASS}_container`;
const CONTAINER_SPECIFIC_CLASS = `${CONTAINER_CLASS}__specific_container`;
const CONTAINER_BOX_SKELETON_CLASS = `${CONTAINER_SPECIFIC_CLASS}__box__skeleton`;

export const SpecificEntityList = ({ selectAll }: { selectedDropdown: TEntityType; selectAll?: boolean }) => {
  const teAPI = useMemo(() => new TemplatedExperienceAPI(), []);

  const { customModuleState, setCustomModuleState } = useCustomModuleAnalyticsContext();
  const { measureItems } = customModuleState;
  const [checkedState, setCheckedState] = useState<TSpecificEntity[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const fetchExperiences = useCallback(async () => {
    try {
      setIsLoading(true);
      const experiences = await teAPI.getTemplatedInstances({
        limit: 1000,
        offset: 0,
      });
      setData(experiences?.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }, [teAPI]);

  useEffect(() => {
    !selectAll && fetchExperiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);
  const itemIsChecked = (nameTocheck: string) => {
    // this is to keep selection after search
    return !!measureItems?.selectedEntities?.find(({ name }) => name === nameTocheck);
  };
  const serializedEntityData = useMemo(() => {
    if (!data?.length) return [];
    return (
      data
        ?.filter((exp) => exp.activatedAt)
        ?.map((experience) => {
          return {
            context: experience,
            name: experience.name,
            isActive: itemIsChecked(experience.name),
          };
        }) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length]);

  useEffect(() => {
    if (serializedEntityData?.length) {
      const checkboxStatus: TSpecificEntity[] = serializedEntityData;
      setCheckedState(checkboxStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedEntityData?.length, checkedState?.length, selectAll]);

  useEffect(() => {
    if (checkedState?.length && measureItems.selectedEntities?.length) {
      const contextEntitiesToUpdate: TSpecificEntity[] = [];
      const checkboxStatus = checkedState?.map((uiEntity) => {
        const [filteredResult] = measureItems.selectedEntities.filter((contextEntity) => {
          const isItem =
            contextEntity.name.toLocaleLowerCase() === uiEntity.name.toLocaleLowerCase() ||
            contextEntity.context.id === uiEntity.context.id;

          if (isItem && Object.keys(contextEntity.context).length === 1) {
            // adds only if its not selected yet
            !measureItems.selectedEntities.find(({ name }) => name === contextEntity.name) &&
              contextEntitiesToUpdate.push({
                ...uiEntity,
                isActive: true,
              });
          }

          return isItem;
        });
        return {
          ...uiEntity,
          isActive: filteredResult?.isActive ? true : false,
        };
      });
      setCheckedState(checkboxStatus);
      // replace id-only entities with the full fetched entity
      if (contextEntitiesToUpdate.length) {
        setCustomModuleState((prev) => ({
          ...prev,
          measureItems: {
            ...measureItems,
            selectedEntities: contextEntitiesToUpdate,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measureItems.selectedEntities?.length, checkedState?.length, selectAll]);

  const handleOnChange = (name: string) => {
    const updatedCheckedState = checkedState.map((item) => {
      if (name === item.name) {
        item.isActive = !item.isActive;
      }
      return item;
    });

    // Update Context value
    const selectedEntities = updatedCheckedState?.filter((entity) => entity.isActive);

    setCustomModuleState({
      ...customModuleState,
      measureItems: {
        ...measureItems,
        selectedEntities,
      },
    });
  };

  return (
    <div className={CONTAINER_SPECIFIC_CLASS}>
      <FlightTextInput
        width="100%"
        iconInput="search"
        hasClearIcon={true}
        value={searchTerm}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(evt.target.value)}
        className={`${CONTAINER_SPECIFIC_CLASS}__specific_box__search`}
        label="Search"
      />
      <div className={`${CONTAINER_SPECIFIC_CLASS}__box`}>
        {isLoading && (
          <div className={CONTAINER_BOX_SKELETON_CLASS}>
            {[...Array(8)].map((item, index) => (
              <Skeleton key={index} width={'90%'} height={18} style={{ margin: '4px' }} />
            ))}
          </div>
        )}
        <ul>
          {!isLoading &&
            rankedSearch(checkedState, 'name', debouncedSearchTerm)?.map((context, idx: number) => {
              return (
                <li key={`ca_custom_entityListItem-${idx}`}>
                  <FlightCheckbox
                    className={`${CONTAINER_SPECIFIC_CLASS}__box__checkbox`}
                    label={context?.name || 'Untitled'}
                    checkState={itemIsChecked(context?.name) ? 'SELECTED' : 'UNSELECTED'}
                    onSelect={() => handleOnChange(context?.name)}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
