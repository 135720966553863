import { useEffect } from 'react';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { setApprovalSettingsThunk } from 'store/approval/approval.thunk';
import useFeatureFlag from './useFeatureFlag';

export default function useApprovalSettings() {
  const { settings, isInitialized, isLoading, error } = useSelector((state) => state.approval);
  const dispatch = useDispatch();
  const { flags } = useFeatureFlag();

  useEffect(() => {
    if (isInitialized || !flags['tx_maker_checker']) {
      return;
    }

    dispatch(setApprovalSettingsThunk());
  }, [dispatch, isInitialized, flags]);

  return { settings, isInitialized, isLoading, error };
}
