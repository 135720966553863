import {
  ApprovalActionTypes,
  ApprovalState,
  SetApprovalSettingsAction,
  SetApprovalSettingsErrorAction,
  SetApprovalSettingsLoadingAction,
} from './approval.type';
import { createReducer } from 'store/helper';

const initialState: ApprovalState = {
  settings: {
    approvalRequired: false,
  },
  isInitialized: false,
  isLoading: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

function setApprovalSettings(state: ApprovalState, action: SetApprovalSettingsAction): ApprovalState {
  const { settings, isInitialized } = action.payload;

  return {
    ...state,
    settings,
    isInitialized,
  };
}

function setApprovalSettingsLoading(state: ApprovalState, action: SetApprovalSettingsLoadingAction): ApprovalState {
  const { isLoading } = action.payload;

  return {
    ...state,
    isLoading,
  };
}

function setApprovalSettingsError(state: ApprovalState, action: SetApprovalSettingsErrorAction): ApprovalState {
  const { error, settings, isInitialized } = action.payload;

  return {
    ...state,
    error,
    settings,
    isInitialized,
  };
}

export default createReducer(initialState, {
  [ApprovalActionTypes.SET_APPROVAL_SETTINGS]: setApprovalSettings,
  [ApprovalActionTypes.SET_APPROVAL_SETTINGS_LOADING]: setApprovalSettingsLoading,
  [ApprovalActionTypes.SET_APPROVAL_SETTINGS_ERROR]: setApprovalSettingsError,
});
