import React from 'react';
import { FlightButton, FlightModal } from '@flybits/design-system';
import Approve from 'assets/images/approve.svg';

import './ConfirmApproveRequestModal.scss';

interface TConfirmApproveRequestModal {
  isVisible: boolean;
  onCancel: () => void;
  onApprove: () => void;
}

const MAIN_CLASS = 'approval-request-confirm-modal';

const ConfirmApproveRequestModal: React.FC<TConfirmApproveRequestModal> = ({ isVisible, onCancel, onApprove }) => {
  return (
    <FlightModal
      size="small"
      isVisible={isVisible}
      toggleModalShown={onCancel}
      content={
        <div className={MAIN_CLASS}>
          <img src={Approve} alt={'approve reqeust icon'} />
          <h3>Approve Request?</h3>
          <p>This means you are approving the experience launch request for you and your team.</p>
          <FlightButton className="confirm-modal__button" onClick={onApprove} theme="primary" label="Yes, approve" />
          <FlightButton className="confirm-modal__button" onClick={onCancel} theme="secondary" label="Cancel" />
        </div>
      }
    />
  );
};

export default ConfirmApproveRequestModal;
