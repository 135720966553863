import React from 'react';
import { TIconProps } from '../types';

function CircleCheckIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 1.33337C4.3335 1.33337 1.3335 4.33337 1.3335 8.00004C1.3335 11.6667 4.3335 14.6667 8.00016 14.6667C11.6668 14.6667 14.6668 11.6667 14.6668 8.00004C14.6668 4.33337 11.6668 1.33337 8.00016 1.33337ZM8.00016 13.3334C5.06016 13.3334 2.66683 10.94 2.66683 8.00004C2.66683 5.06004 5.06016 2.66671 8.00016 2.66671C10.9402 2.66671 13.3335 5.06004 13.3335 8.00004C13.3335 10.94 10.9402 13.3334 8.00016 13.3334ZM11.0602 5.05337L6.66683 9.44671L4.94016 7.72671L4.00016 8.66671L6.66683 11.3334L12.0002 6.00004L11.0602 5.05337Z"
        fill={fill}
      />
    </svg>
  );
}

export default CircleCheckIcon;
