/* eslint-disable @typescript-eslint/no-explicit-any */
import './TemplatesLibrary.scss';

import React, { useEffect, useState } from 'react';

import { ErrorPage } from 'components/Shared/ErrorPage/ErrorPage';
import { FlightButton, FlightCard } from '@flybits/design-system';
import DefaultBanner from 'assets/images/default-banner-element.svg';
import GreaterThan from 'assets/icons/greater-than-icon.svg';
import { Category } from 'interface/templated-experience/templated-experience.interface';
import { EVENT_KEYS } from 'types/events';
import useFeatureFlag from 'hooks/useFeatureFlag';

type Template = {
  key?: string;
  name?: string;
  templates?: any[];
};

type TProps = {
  categories: any;
  templates: any[];
  categoryId?: string;
  subCategoryId?: string;
  onSelect: (id: string) => void;
  onCreate: () => void;
  onViewAll: (id: string) => void;
  onBreadcrumbClick: (id: string) => void;
  onUseFlow: () => void;
  searchText?: string;
  isError?: boolean;
};

export const TemplatesLibraryContent: React.FC<TProps> = ({
  categories,
  templates,
  categoryId = '',
  subCategoryId = '',
  onCreate,
  onSelect,
  onUseFlow,
  onViewAll,
  onBreadcrumbClick,
  searchText = '',
  isError = false,
}) => {
  const DEFAULT_CLASS = 'templates-library__main-content';
  const CONTAINER_CLASS = 'templates-library__main-content__content__container';
  const [categoryList, setCategoryList] = useState<Array<Template>>([]);
  const [bannerInfo, setBannerInfo] = useState<Category>();
  const [bannerSrc, setBannerSrc] = useState('');
  const [isEmptyCategory, setIsEmptyCategory] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState<Array<Template>>([]);
  const isCategoryRecentlyAddedUsed = categoryId === 'recently-added' || categoryId === 'recently-used';
  const isFlybitsStarterCategory = categories?.find((cat: Category) => cat.key === 'flybits-starters');
  const [isLoading, setIsLoading] = useState(false);
  const { flags } = useFeatureFlag();

  useEffect(() => {
    const list: Array<Template> = [];
    if (searchText) {
      list.push({
        templates: [],
      });
    } else {
      if (categoryId) {
        const categoryDetail = categories?.find((cat: Category) => cat.key === categoryId);
        if (categoryId === 'flybits-starters' || isCategoryRecentlyAddedUsed || categoryId === 'byo') {
          list.push({
            key: categoryDetail?.key,
            name: categoryDetail?.name,
            templates: [],
          });
        } else if (subCategoryId) {
          list.push({
            templates: [],
          });
        } else {
          if (categoryDetail?.key === 'unassigned') {
            list.push({
              key: categoryDetail.key,
              name: categoryDetail.name,
              templates: [],
            });
          } else {
            categoryDetail?.subcategories?.forEach((subCat: Category) => {
              list.push({
                key: subCat.key,
                name: subCat.name,
                templates: [],
              });
            });
          }
        }
      } else {
        categories?.forEach((category: Category) => {
          if (category.key !== 'all') {
            list.push({
              key: category.key,
              name: category.name,
              templates: [],
            });
          }
        });
      }
    }

    templates?.forEach((template: any) => {
      const categoryName = template.categories?.[0]?.split('category-')[1];
      const subCategoryName = template.categories?.[1]?.split('subcategory-')[1];

      if (searchText) {
        if (
          template.name?.toLowerCase().includes(searchText.toLowerCase()) ||
          template.description?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          list[0]?.templates?.push(template);
        }
      } else {
        if (categoryId) {
          if (isCategoryRecentlyAddedUsed) {
            list[0]?.templates?.push(template);
          } else if (
            categoryId === 'flybits-starters' &&
            (categoryName === 'flybits-starters' || categoryName === 'byo')
          ) {
            list[0]?.templates?.push(template);
          } else if (categoryId === 'byo' && categoryName === 'byo') {
            list[0]?.templates?.push(template);
          } else if (categoryId === 'unassigned') {
            let currentElement = null;
            if (categoryName === 'byo' && isFlybitsStarterCategory) {
              currentElement = categories?.find((listData: Template) => listData.key === 'flybits-starters');
            } else {
              currentElement = categories?.find((listData: Template) => listData.key === categoryName);
            }
            if (!currentElement) {
              list[0]?.templates?.push(template);
            }
          } else if (categoryId === categoryName || categoryId === 'unassigned') {
            if (subCategoryId) {
              if (subCategoryId === subCategoryName) {
                list[0]?.templates?.push(template);
              } else if (subCategoryId === 'unassigned') {
                const currentElement = categories?.find((listData: Template) => listData.key === categoryName);
                const currentSubElement = currentElement.subcategories.find(
                  (listData: Template) => listData.key === subCategoryName,
                );
                if (!currentSubElement) {
                  list[0]?.templates?.push(template);
                }
              }
            } else {
              if (subCategoryName) {
                const currentElement = list?.find((listData: Template) => listData.key === subCategoryName);
                if (currentElement) {
                  currentElement?.templates?.push(template);
                } else {
                  const currentElement = list?.find((listData: Template) => listData.key === 'unassigned');
                  currentElement?.templates?.push(template);
                }
              } else {
                const currentElement = list?.find((listData: Template) => listData.key === 'unassigned');
                currentElement?.templates?.push(template);
              }
            }
          }
        } else {
          if (categoryName) {
            let currentElement = null;
            if (categoryName === 'byo' && isFlybitsStarterCategory) {
              currentElement = list?.find((listData: Template) => listData.key === 'flybits-starters');
            } else {
              currentElement = list?.find((listData: Template) => listData.key === categoryName);
            }
            if (currentElement) {
              currentElement?.templates?.push(template);
            } else {
              const currentElement = list?.find((listData: Template) => listData.key === 'unassigned');
              currentElement?.templates?.push(template);
            }
          } else {
            const currentElement = list?.find((listData: Template) => listData.key === 'unassigned');
            currentElement?.templates?.push(template);
          }
        }
      }
    });
    if (categoryId === 'recently-added') {
      list?.[0]?.templates?.sort((firstCategory: any, secondCategory: any) =>
        firstCategory.createdAt < secondCategory.createdAt ? 1 : -1,
      );
    } else if (categoryId === 'recently-used') {
      list?.[0]?.templates?.sort((firstCategory: any, secondCategory: any) =>
        firstCategory.updatedAt < secondCategory.updatedAt ? 1 : -1,
      );
    }

    setCategoryList(list);
    const emptyTemplateList = list?.filter((temp: any) => temp?.templates?.length > 0);
    setIsEmptyCategory(emptyTemplateList?.length === 0);
    const breadcrumbList: Array<Template> = [];
    breadcrumbList.push({
      key: 'all',
      name: 'All',
    });
    if (categoryId) {
      const currentCat = categories?.find((cat: Category) => cat.key === categoryId);
      breadcrumbList.push({
        key: currentCat?.key,
        name: currentCat?.name,
      });
      if (subCategoryId) {
        const currentSubCat = currentCat?.subcategories?.find((subCat: Category) => subCat.key === subCategoryId);
        setBannerInfo(currentSubCat);
        breadcrumbList.push({
          key: currentSubCat?.key,
          name: currentSubCat?.name,
        });
      } else {
        setBannerInfo(currentCat);
      }
      setBreadcrumb(breadcrumbList);
    }
  }, [
    templates,
    categoryId,
    subCategoryId,
    categories,
    searchText,
    isCategoryRecentlyAddedUsed,
    isFlybitsStarterCategory,
  ]);

  useEffect(() => {
    if (!bannerInfo) {
      return;
    }

    const { bannerUrl, img } = bannerInfo;
    setBannerSrc(bannerUrl || img || DefaultBanner);
  }, [bannerInfo]);

  return (
    <>
      {isError ? (
        <ErrorPage />
      ) : (
        <div className={`${DEFAULT_CLASS}`}>
          {isEmptyCategory ? (
            <div className={`${DEFAULT_CLASS}__empty`}>No templates under this category</div>
          ) : (
            <div className={`${DEFAULT_CLASS}__content`}>
              {!bannerInfo?.isBannerImageHidden && categoryId && !isCategoryRecentlyAddedUsed && (
                <div className={`${DEFAULT_CLASS}__content__banner`} style={{ backgroundColor: '#ffffff' }}>
                  <img src={`${bannerSrc}`} onError={() => setBannerSrc(`${DefaultBanner}`)} alt="category banner" />
                  <div className={`${DEFAULT_CLASS}__content__banner__details`}>
                    <h3 className={`${DEFAULT_CLASS}__content__banner__details__title`}>{bannerInfo?.name}</h3>
                    <div className={`${DEFAULT_CLASS}__content__banner__details__description`}>
                      {bannerInfo?.description}
                    </div>
                  </div>
                </div>
              )}
              {!(categoryId || searchText) && (
                <div className={`${DEFAULT_CLASS}__content__banner`} style={{ backgroundColor: '#ffffff' }}>
                  <img src={`${DefaultBanner}`} alt="All template banner" />
                  <div className={`${DEFAULT_CLASS}__content__banner__details`}>
                    <h3 className={`${DEFAULT_CLASS}__content__banner__details__title`}>All Templates</h3>
                  </div>
                </div>
              )}
              {categoryId && breadcrumb?.length > 1 && !isCategoryRecentlyAddedUsed && (
                <div className={`${DEFAULT_CLASS}__content__breadcrumb`}>
                  <nav aria-label="breadcrumbs">
                    <ol>
                      {breadcrumb.map((bread: Template, index: number) => {
                        const tlBreadCrumbReactKey = `templateLibBreadCrumb-${index}`;
                        if (index === 0) {
                          return (
                            <li key={tlBreadCrumbReactKey}>
                              <button
                                className="breadcrumb__first"
                                aria-label={bread.name}
                                onClick={() => {
                                  onBreadcrumbClick('');
                                }}
                              >
                                {bread.name}
                              </button>
                            </li>
                          );
                        } else if (index === breadcrumb.length - 1) {
                          return (
                            <li key={tlBreadCrumbReactKey}>
                              <span
                                className="breadcrumb__arrow"
                                style={{ backgroundImage: `url(${GreaterThan})` }}
                              ></span>{' '}
                              <button tabIndex={-1} className="breadcrumb__last">
                                {bread.name}
                              </button>
                            </li>
                          );
                        } else {
                          return (
                            <li key={tlBreadCrumbReactKey}>
                              {' '}
                              <span
                                className="breadcrumb__arrow"
                                style={{ backgroundImage: `url(${GreaterThan})` }}
                              ></span>{' '}
                              <button
                                className="breadcrumb__middle"
                                aria-label={bread.name}
                                onClick={() => {
                                  onBreadcrumbClick(bread?.key || '');
                                }}
                              >
                                {bread.name}
                              </button>
                            </li>
                          );
                        }
                      })}
                    </ol>
                  </nav>
                </div>
              )}
              {categoryList?.map((category: any, index: number) => {
                if (category?.templates?.length) {
                  return (
                    <div key={`category-card-${index}`} className={`${CONTAINER_CLASS}`}>
                      {!(
                        subCategoryId ||
                        categoryId === 'unassigned' ||
                        categoryId === 'flybits-starters' ||
                        categoryId === 'byo' ||
                        isCategoryRecentlyAddedUsed ||
                        searchText
                      ) && (
                        <div className={`${CONTAINER_CLASS}__header`}>
                          <h3 className={`${CONTAINER_CLASS}__header__title`}>{category.name}</h3>
                          {category?.key !== 'unassigned' && (
                            <button
                              className={`${CONTAINER_CLASS}__header__view`}
                              aria-label="View All"
                              onClick={() => {
                                onViewAll(category.key);
                              }}
                            >
                              View All
                            </button>
                          )}
                        </div>
                      )}
                      {searchText && (
                        <div className={`${CONTAINER_CLASS}__result`}>
                          We found <b>{category.templates?.length} Results</b> based on your search tags
                        </div>
                      )}
                      <div className={`${CONTAINER_CLASS}__cards`}>
                        {category.templates?.map((template: any, index: number) => {
                          if (
                            subCategoryId ||
                            category?.key === 'unassigned' ||
                            categoryId === 'unassigned' ||
                            categoryId === '' ||
                            categoryId === 'flybits-starters' ||
                            categoryId === 'byo' ||
                            isCategoryRecentlyAddedUsed ||
                            index < 3 ||
                            searchText
                          ) {
                            return (
                              <div
                                className={`flight-card-container ${
                                  category.templates?.length === 1 ? 'flight-card-container--single-template' : ''
                                }`}
                                key={`flight-card-container-${index}`}
                              >
                                <FlightCard
                                  key={`sub-category-card-${index}`}
                                  className={`${CONTAINER_CLASS}__cards__card`}
                                  header=""
                                  body={template?.name}
                                  image={
                                    template?.imageUrl ? (
                                      <img
                                        className={`${DEFAULT_CLASS}__cards__card__img`}
                                        src={template?.imageUrl}
                                        alt=""
                                      />
                                    ) : null
                                  }
                                  loading={isLoading}
                                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                    if (event.key === EVENT_KEYS.ENTER) {
                                      onSelect(template?.id);
                                      onCreate();
                                    }
                                  }}
                                  onBtnClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                    const cardsList = document.getElementsByClassName(
                                      'templates-library__main-content__content__container__cards__card',
                                    );
                                    for (const card of cardsList) {
                                      card.classList.remove('selected');
                                    }
                                    event?.currentTarget?.classList?.add('selected');
                                    onSelect(template?.id);
                                    setIsLoading(true);
                                    onCreate();
                                    setIsLoading(false);
                                  }}
                                  tabIndex={0}
                                  isImageBgColor
                                  addOverlay
                                  defaultText={template?.name?.charAt(0)?.toUpperCase()}
                                  accessibilityText={`Use ${template?.name} template`}
                                />
                                {flags['tx_enable_flow'] && (
                                  <FlightButton
                                    label="Use Flow"
                                    className="use-flow-button"
                                    onClick={() => {
                                      onSelect(template?.id);
                                      setIsLoading(true);
                                      onUseFlow();
                                      setIsLoading(false);
                                    }}
                                  />
                                )}
                              </div>
                            );
                          }
                          return undefined;
                        })}
                      </div>
                    </div>
                  );
                }
                return undefined;
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};
