import React from 'react';
import { TIconProps } from '../types';

function UniverseIcon({ fill = '#000000', className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 390 390"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M686 3590c-13-35-26-54-42-61-13-5-36-14-51-20l-28-11 52-20c46-18 54-26 69-64 9-24 20-44 25-44 4 0 13 17 20 38 16 50 22 57 69 72 22 7 40 16 40 20s-21 15-46 25c-39 15-48 24-67 67l-22 50-19-52zM1910 3583c-378-60-679-216-935-484-200-211-331-466-386-752-24-126-26-360-4-492 51-317 209-606 443-812 108-95 185-146 318-210 193-91 376-129 587-120 325 13 573 117 798 334 162 156 269 346 320 568 24 104 29 299 10 405-56 308-257 582-526 717-56 28-188 69-269 84-148 26-372-8-509-77-269-136-442-421-423-694 12-175 69-303 184-416 122-121 267-175 426-160 75 7 101 21 90 50-5 14-14 17-38 11-86-18-133-17-214 5-329 89-489 469-339 809 58 133 195 274 334 345 29 14 91 38 140 52 119 36 305 38 428 5 401-108 675-477 675-909 0-281-102-527-305-736-193-199-424-308-717-337-217-22-430 17-634 115-175 84-274 157-397 294-165 184-271 393-324 642-22 106-25 398-5 505 50 258 187 540 350 716 74 80 241 219 319 267 209 125 423 200 655 227 57 7 86 25 73 45-6 10-73 12-125 3z"
        transform="matrix(.1 0 0 -.1 0 390)"
        fill={fill}
      />
      <path
        d="M1970 3180c-321-33-635-206-819-451-156-207-231-424-231-671 0-386 205-729 535-895 240-121 529-128 761-19 159 75 291 201 364 346 60 121 75 185 74 320 0 141-9 180-65 298-67 140-200 253-348 296-61 18-215 22-256 6-17-6-25-16-23-27 3-15 19-17 128-18 125 0 125 0 205-39 229-112 352-368 301-627-66-336-373-578-733-579-291 0-561 146-731 397-114 168-169 367-159 573 14 299 123 534 340 735 153 141 296 219 496 272 141 38 383 44 530 13 393-80 709-327 894-696 96-192 127-323 134-569 5-159 3-192-17-289-42-209-109-368-231-551-231-345-657-601-1067-641-80-7-114-26-90-50 10-10 31-11 98-3 615 74 1140 512 1309 1095 67 230 75 503 20 734-126 536-553 942-1084 1029-94 16-242 20-335 11zM3370 3099c-21-45-32-55-70-70l-45-17 37-16c21-9 42-16 48-16s22-24 35-52l23-53 22 51c18 44 27 53 61 64 22 8 42 17 45 21 2 4-17 15-42 25-39 15-49 24-68 67l-21 49-25-53zM1106 495c-25-63-30-68-74-83-23-7-42-17-42-21s22-15 48-24c41-15 50-23 63-58 8-22 19-41 24-40 5 0 16 19 24 42 13 34 24 44 58 58 23 9 44 19 48 22 3 4-16 15-44 25-45 16-51 22-69 68l-19 51-17-40z"
        transform="matrix(.1 0 0 -.1 0 390)"
        fill={fill}
      />
    </svg>
  );
}

export default UniverseIcon;
