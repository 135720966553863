import React from 'react';
import { TIconProps } from '../types';

function LaunchIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33_2083)">
        <path
          d="M4.01 6.03L11.52 9.25L4 8.25L4.01 6.03ZM11.51 14.75L4 17.97V15.75L11.51 14.75ZM2.01 3L2 10L17 12L2 14L2.01 21L23 12L2.01 3Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_33_2083">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LaunchIcon;
