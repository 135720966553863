import BaseAPI from './base.api';

import axios from 'axios';
import { SystemError } from 'types/errors';
import { PaginationResponse } from 'interface/shared/api.interface';
import {
  ApprovalRequirementSettings,
  ApprovalRequest,
  ApprovalRequestResponse,
  ApprovalRequestPostPayload,
  FBComment,
} from 'interface/approval/approval.interface';

export default class ApprovalAPI extends BaseAPI {
  private ROUTES = {
    defaultApprovalSettings: '/sso/permissions/approval/settings',
    approvalRequests: '/sso/permissions/approval/requests',
    approvalRequest: '/sso/permissions/approval/requests/:id',
    approveRequest: '/sso/permissions/approval/requests/:id/approve',
    comments: '/sso/permissions/approval/requests/:reqId/comments',
    attachments: '/sso/permissions/approval/requests/:reqId/attachments',
  };

  params = {
    limit: 0,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc',
  };

  getDefaultApprovalSettings(): Promise<ApprovalRequirementSettings> {
    return this.GET(this.ROUTES.defaultApprovalSettings);
  }

  async getApprovalRequests(opts?: object): Promise<PaginationResponse<ApprovalRequestResponse>> {
    this.params = Object.assign(this.params, opts);

    try {
      return await this.GET(this.ROUTES.approvalRequests, this.params);
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  postApprovalRequest(body: ApprovalRequestPostPayload): Promise<{ data: ApprovalRequest }> {
    return this.POST(this.ROUTES.approvalRequests, body);
  }

  async updateApprovalStatus(id: string, approved: boolean) {
    try {
      return await this.PATCH(this.buildURL(this.ROUTES.approveRequest, { id }), { approved });
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  async updateApprovalRequest(id: string, body: Partial<ApprovalRequest>): Promise<{ data: ApprovalRequest }> {
    try {
      return await this.PUT(this.buildURL(this.ROUTES.approvalRequest, { id }), body);
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  async getComments(reqId: string, opts?: object): Promise<PaginationResponse<FBComment>> {
    this.params = Object.assign(this.params, opts);

    try {
      return await this.GET(this.buildURL(this.ROUTES.comments, { reqId }), this.params);
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  async postComment(reqId: string, body: Partial<FBComment>): Promise<{ data: FBComment }> {
    try {
      return this.POST(this.buildURL(this.ROUTES.comments, { reqId }), body);
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  async updateComment(reqId: string, commentId: string, body: Partial<FBComment>): Promise<{ data: FBComment }> {
    try {
      return await this.PUT(this.buildURL(`${this.ROUTES.comments}/:commentId`, { reqId, commentId }), body);
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  async deleteComment(reqId: string, commentId: string): Promise<{ data: FBComment }> {
    try {
      return this.DELETE(this.buildURL(`${this.ROUTES.comments}/:commentId`, { reqId, commentId }));
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  async postAttachment(reqId: string, file: File): Promise<{ data: FBComment }> {
    const fm = new FormData();
    fm.append('data', file);
    try {
      return this.POST(this.buildURL(this.ROUTES.attachments, { reqId }), fm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }

  async deleteAttachment(reqId: string, fileId: string): Promise<{ data: FBComment }> {
    try {
      return this.DELETE(this.buildURL(`${this.ROUTES.attachments}/:fileId`, { reqId, fileId }));
    } catch (err) {
      if (axios.isAxiosError(err))
        throw new SystemError(`${err.response?.status}: ${err.response?.data ?? 'unknown error'}`);
      else throw new Error();
    }
  }
}
