import BaseAPI from './base.api';

export default class ContextAPI extends BaseAPI {
  private ROUTES = {
    getParametersbyUid: '/context/ctxrepo/parameters?uid=',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getParameters(ctxVariable: string): Promise<any> {
    return this.GET(`${this.ROUTES.getParametersbyUid}${ctxVariable}`);
  }
}
