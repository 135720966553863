import React, { useState, useCallback } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-solarizedlight.css';
import './CodeEditor.scss';

export enum CODE_LANGUAGE {
  HTML = 'markup',
  CSS = 'css',
}

type EditorProps = {
  title: string;
  code: string;
  codeLanguage: CODE_LANGUAGE;
  disabled?: boolean;
  onChange: (code: string) => void;
  onBlur: (code: string) => void;
};

const CodeEditor = ({ title, code, codeLanguage, disabled, onChange, onBlur }: EditorProps) => {
  const [codeValue, setCodeValue] = useState(code);

  const handleCodeChange = useCallback(
    (code: string | undefined) => {
      setCodeValue(code || '');
      onChange(code || '');
    },
    [onChange],
  );

  const handleBlur = () => {
    onBlur(codeValue);
  };

  const hightlightWithLineNumbers = (input: any, grammar: any, language: any) =>
    highlight(input, grammar, language)
      .split('\n')
      .map((line: any, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join('\n');

  return (
    <div className="panel panel-default">
      <h3 className="panel-heading">{' ' + title}</h3>
      <div className="panel-editor">
        <Editor
          highlight={(code) =>
            hightlightWithLineNumbers(
              code,
              codeLanguage === CODE_LANGUAGE.HTML ? languages.html : languages.css,
              codeLanguage,
            )
          }
          value={codeValue}
          padding={10}
          textareaId="codeArea"
          className={`editor`}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            lineHeight: 1.4,
            fontSize: '12px',
            outline: 0,
          }}
          disabled={disabled}
          onValueChange={handleCodeChange}
          onBlur={handleBlur}
        />
      </div>
      <div className={`code-editor__line-col`}></div>
    </div>
  );
};

export default CodeEditor;
