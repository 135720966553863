import { AxiosRequestConfig } from 'axios';
import http from 'services/axios';

export default class BaseAPI {
  /**
   * Build a custom url by replacing properties (words that begin with a colon (eg, :id))
   * with matching values from the context object having the same key.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  buildURL(url: string, context: { [key: string]: string }, queryParams: object | undefined = undefined): string {
    const properties = url?.match(/(:\w+)/g);
    if (properties) {
      properties?.forEach((property) => {
        const key = property?.substring(1);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        const value = context?.[key]!;
        if (value !== undefined) {
          url = url.replace(property, value);
        } else {
          throw new Error('buildURL: context.' + key + ' expected but undefined');
        }
      });
      return url;
    }
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  GET(url: string, params?: object): Promise<any> {
    return http.get(url, { params });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PUT(url: string, data: object = {}): Promise<any> {
    return http.put(url, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PATCH(url: string, data = {}): Promise<any> {
    return http.patch(url, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  POST(url: string, data = {}, options: AxiosRequestConfig = {}): Promise<any> {
    return http.post(url, data, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DELETE(url: string): Promise<any> {
    return http.delete(url);
  }
}
