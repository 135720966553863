import React from 'react';
import LabelFilterContext from './LabelFilterContext';
import useLabelFilterContext from './useLabelFilterContext';
import { LabelFilter } from 'pages/Zones/types';

export type TLabelFilterProvider = {
  moduleName: string | null;
  labelFilters: LabelFilter[] | null;
  children: JSX.Element[] | JSX.Element | undefined;
};

const LabelFilterProvider = ({ moduleName, labelFilters, children }: TLabelFilterProvider) => {
  const value = useLabelFilterContext(moduleName, labelFilters ? labelFilters.slice(0) : []);
  return <LabelFilterContext.Provider value={value}>{children}</LabelFilterContext.Provider>;
};

export default LabelFilterProvider;
