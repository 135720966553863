import BaseAPI from './base.api';
import { ZoneConfig, ZoneConfigPayload } from 'pages/Zones/types';

export default class ZoneAPI extends BaseAPI {
  private ROUTES = {
    zoneconfigurations: `kernel/experiences/zoneconfigurations`,
    zoneconfiguration: `kernel/experiences/zoneconfigurations/:id`,
  };

  createZoneConfig(zoneConfig: ZoneConfigPayload): Promise<ZoneConfig> {
    return this.POST(this.ROUTES.zoneconfigurations, zoneConfig);
  }

  getZoneConfig(): Promise<ZoneConfig> {
    return this.GET(this.ROUTES.zoneconfigurations);
  }

  updateZoneConfig(zoneConfig: ZoneConfig, id: string): Promise<ZoneConfig> {
    return this.PUT(this.buildURL(this.ROUTES.zoneconfiguration, { id }), zoneConfig);
  }
}
