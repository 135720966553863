import { WEBHOOK_ACTION_TYPES } from 'store/webhook/webhook.type';

export const WEBHOOK_INIT = {
  refId: '',
  actionType: WEBHOOK_ACTION_TYPES.WEBHOOK,
  id: '',
  name: '',
  description: '',
  url: '',
  method: '',
  payload: {},
  payloads: [],
  headers: {},
  certificateName: '',
  contextVariables: [],
};
