import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlightTooltip } from '@flybits/design-system';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import FileAPI from 'services/api/file.api';
import './ProcessContextFileButton.scss';
import ProcessIcon from 'pages/ExperienceCanvas/assets/icons/ProcessIcon';

type TProcessContextFileButton = {
  fileId: string;
  disabled?: boolean;
  size?: 'normal' | 'large';
};

const MAIN_CLASS = 'process-context-file-button';
const CLASSES = {
  IS_LARGE: `${MAIN_CLASS} ${MAIN_CLASS}--is-large`,
  WRAPPER: `${MAIN_CLASS}__wrapper`,
  WRAPPER_DISABLED: `${MAIN_CLASS}__wrapper ${MAIN_CLASS}__wrapper--is-disabled`,
  BUTTON: `${MAIN_CLASS}__button`,
};

const ProcessContextFileButton: React.FC<TProcessContextFileButton> = ({ fileId, size = 'normal' }) => {
  const fileAPI = useMemo(() => new FileAPI(), []);

  const [isBusy, setIsBusy] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isInstanceCreated = useSelector((state) => !!state.te.journey.id);
  const isInstanceActive = useSelector((state) => state.te.journey.status === 'active');
  const hasRulesAdded = useSelector((state) => {
    const predicates = state.te.rule.byRefId[Object.keys(state.te.rule.byRefId)[0]]?.predicates;
    return Object.keys(predicates || {}).length > 0;
  });
  const handleProcessClick = async (fileId: string) => {
    setIsBusy(true);
    try {
      dispatch(
        showSnackbar({
          content: 'Your file will be processed shortly',
          type: 'success',
        }),
      );
      await fileAPI.processFileById(fileId);
      await queryClient.invalidateQueries(['ctx-data-file']);
    } catch {
      dispatch(
        showSnackbar({
          content: 'An error occurred while processing your file',
          type: 'error',
        }),
      );
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <div className={`${MAIN_CLASS}${size === 'large' ? CLASSES.IS_LARGE : ''}`}>
      <FlightTooltip
        isEnabled={!isInstanceCreated || !hasRulesAdded}
        description={
          !isInstanceCreated ? (
            <p>
              To process files, please make sure to save
              <br />
              the experience as a draft or launch it
              <br />
              before proceeding.
            </p>
          ) : (
            <p>
              To process files, please make sure to
              <br />
              add audience criteria by editing a
              <br />
              touchpoint.
            </p>
          )
        }
        direction="left"
      >
        <div className={isInstanceCreated ? CLASSES.WRAPPER : CLASSES.WRAPPER_DISABLED}>
          <button
            type="button"
            className={CLASSES.BUTTON}
            onClick={() => handleProcessClick(fileId)}
            disabled={isBusy || !isInstanceCreated || !hasRulesAdded || !isInstanceActive}
          >
            <ProcessIcon />
            Process Now
          </button>
        </div>
      </FlightTooltip>
    </div>
  );
};

export default ProcessContextFileButton;
