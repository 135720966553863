import React, { useState, useEffect } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useForm from 'hooks/useLocalizedSimpleForm';
import { updatePushSimple } from 'store/push/push.thunk';
import { serializeActionlink, deserializeActionlink, generateActionlinkFieldKey } from 'helpers/push.helper';
import { PushValidators } from 'validators/ExperienceCanvas/push.validator';
import {
  PushUniversalActionTypes as ActionTypes,
  PushFormClassPrefix as CLASS_PREFIX,
} from 'components/ExperienceCanvas/constants';
import { TFormProps, PushUniversalAction } from 'components/ExperienceCanvas/types';
import { EVENT_KEYS } from 'types/events';
import { FlightCheckbox, FlightDropdown, FlightTextInput } from '@flybits/design-system';

const ActionLinkForm = ({ refId, activeLang, defaultLang, stepIdx }: TFormProps) => {
  // const dispatch = useDispatch();
  const dispatch = useDispatch();
  const [pushRefId, deserializedActionLink] = useSelector((state) => {
    const push = state.te.push.byRefId[refId];
    return [push?.refId, deserializeActionlink(push?.actionLinkScheme, activeLang)];
  });

  // action scheme form fields
  const { formData, updateField, toggleError } = useForm({
    data: {
      ...deserializedActionLink.formFields,
      bypassStrictValidation: { value: false },
    },
    validators: PushValidators,
    lang: activeLang,
    defaultLang,
  });

  // action type dropdown
  const [selectedActionType, setSelectedActionType] = useState(deserializedActionLink.actionType);
  const [isActionSelectorOpen, toggleActionSelector] = useState(false);

  const handleChangeActionType = (actionType: PushUniversalAction) => {
    setSelectedActionType(actionType);
    toggleActionSelector(false);
    dispatch(
      updatePushSimple(
        {
          refId: pushRefId,
          fields: {
            actionLinkType: {
              displayName: actionType.name,
              val: 'UNIVERSAL_ACTION',
              validationPrefix: actionType.prefix,
            },
          },
        },
        stepIdx,
        'actionlink',
      ),
    );
  };

  const onChangeBypass = () => {
    const currentValue = formData.bypassStrictValidation.value;

    updateField('bypassStrictValidation', !currentValue);
    selectedActionType.fields.forEach((field, idx) => {
      const fieldKey = generateActionlinkFieldKey(selectedActionType, idx);
      toggleError(fieldKey);
    });
  };

  /**
   * TODO: if we add the actiontype dep into this effect and remove it from the change action handler,
   * the deserialization overwrites the acton type selection as deserialization relies soley on actionLinkScheme
   * as it is the source of truth for mobile as far as we know know.
   * Confirm with mobile if the actionLinkType is used at all, and look into wiping the user's scheme field
   * when type is changed.
   * */
  useEffect(() => {
    setSelectedActionType(deserializedActionLink.actionType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLang]);

  useEffect(() => {
    dispatch(
      updatePushSimple(
        {
          refId: pushRefId,
          fields: { actionLinkScheme: { [activeLang]: serializeActionlink(selectedActionType, formData) } },
        },
        stepIdx,
        'actionlink',
      ),
    );
  }, [activeLang, dispatch, formData, pushRefId, selectedActionType, stepIdx]);

  return (
    <div className={`${CLASS_PREFIX}__container`}>
      <div className={`${CLASS_PREFIX}__row`}>
        <div className={`${CLASS_PREFIX}__label`}>
          <p className={`${CLASS_PREFIX}__label-primary`}>Select Universal Action</p>
        </div>
      </div>
      <FlightDropdown
        className={`${CLASS_PREFIX}__actionlink__field`}
        isActive={isActionSelectorOpen}
        isControlledByIsActive
        handleClickOutside={() => toggleActionSelector(false)}
        trigger={
          <button
            className={`${CLASS_PREFIX}__actionlink__trigger ${
              isActionSelectorOpen ? `${CLASS_PREFIX}__actionlink__trigger--open` : ''
            }`}
            aria-label={`Select Universal Action`}
            onClick={() => toggleActionSelector((prev) => !prev)}
            onKeyDown={(e) => {
              if (e.key === EVENT_KEYS.ENTER) {
                toggleActionSelector((prev) => !prev);
              }
            }}
          >
            {selectedActionType.name}
          </button>
        }
        maxHeight="50vh"
      >
        <>
          <div className={`${CLASS_PREFIX}__actionlink__field__item--header`}>
            Universal Actions (Requires Concierge version {'>'}= 4.4)
          </div>
          {ActionTypes.filter((at) => at.minVersion < 5).map((at) => (
            <button
              key={`push-at-${at.name}`}
              className={`${CLASS_PREFIX}__actionlink__field__item`}
              aria-label={`select ${at.name} form`}
              onClick={() => handleChangeActionType(at)}
              onKeyDown={(e) => {
                if (e.key === EVENT_KEYS.ENTER) handleChangeActionType(at);
              }}
            >
              {at.name}
            </button>
          ))}
          <div className={`${CLASS_PREFIX}__actionlink__field__item--header`}>
            Universal Actions (Requires Concierge version {'>'}= 5)
          </div>
          {ActionTypes.filter((at) => at.minVersion > 4.4).map((at) => (
            <button
              key={`push-at-opt-${at.name}`}
              className={`${CLASS_PREFIX}__actionlink__field__item`}
              aria-label={`select ${at.name} form`}
              onClick={() => handleChangeActionType(at)}
              onKeyDown={(e) => {
                if (e.key === EVENT_KEYS.ENTER) handleChangeActionType(at);
              }}
            >
              {at.name}
            </button>
          ))}
        </>
      </FlightDropdown>
      <div className={`${CLASS_PREFIX}__row`}>
        <div className={`${CLASS_PREFIX}__label`}>
          <p className={`${CLASS_PREFIX}__label-primary`}>Enter Universal Action&apos;s Value</p>
        </div>
      </div>
      {selectedActionType.fields.map((fieldMetadata, idx) => {
        const fieldKey = generateActionlinkFieldKey(selectedActionType, idx);
        const field = formData[fieldKey];

        return (
          <div className={`${CLASS_PREFIX}__row`} key={`push-at-field-${selectedActionType.name}-${idx}`}>
            {fieldMetadata.isBooleanInput ? (
              <>
                <div className={`${CLASS_PREFIX}__label`}>
                  <p className={`${CLASS_PREFIX}__label-primary`}>Overlay</p>
                </div>
                <FlightCheckbox
                  className={`${CLASS_PREFIX}__right`}
                  checkState={field?.value === 'true' ? 'SELECTED' : 'UNSELECTED'}
                  onSelect={() => {
                    updateField(fieldKey, field?.value ? null : 'true');
                    toggleError(fieldKey);
                  }}
                />
              </>
            ) : (
              <FlightTextInput
                className={`${CLASS_PREFIX}__actionlink__field`}
                width="100%"
                value={field?.value ?? ''}
                label={fieldMetadata.name}
                hasError={!!field?.showError}
                errorMessage={field?.error}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField(fieldKey, e.target.value)}
                onBlur={() => toggleError(fieldKey)}
              />
            )}
          </div>
        );
      })}
      <div className={`${CLASS_PREFIX}__actionlink__preview`}>
        {`Action Preview: ${serializeActionlink(selectedActionType, formData)}`}
      </div>
      <div className={`${CLASS_PREFIX}__row`}>
        <div className={`${CLASS_PREFIX}__label`}>
          <p className={`${CLASS_PREFIX}__label-primary`}>Allow custom value for {selectedActionType.name}</p>
        </div>
        <FlightCheckbox
          className={`${CLASS_PREFIX}__right`}
          checkState={formData.bypassStrictValidation.value ? 'SELECTED' : 'UNSELECTED'}
          onSelect={onChangeBypass}
        />
      </div>
    </div>
  );
};

export default ActionLinkForm;
