import { useQuery } from '@tanstack/react-query';
import { MerchantCategory } from 'pages/MerchantPortal/merchant-portal.types';
import merchantService from 'services/api/merchants.api';

const DEFAULT_QUERY_KEY = 'merchant-categories';

export default function useMerchantCategoriesMock() {
  const {
    data: merchantCategories,
    isLoading: isMerchantCategoriesLoading,
    isError: isMerchantCategoriesError,
  } = useQuery<MerchantCategory[]>({
    queryKey: [DEFAULT_QUERY_KEY],
    queryFn: () => merchantService.getCategoriesMock(),
    refetchOnWindowFocus: false,
  });

  return {
    merchantCategories,
    isMerchantCategoriesLoading,
    isMerchantCategoriesError,
    queryKey: [DEFAULT_QUERY_KEY],
  };
}
