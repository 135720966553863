import React, { useCallback, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { ZoneConfig } from '../types';
import './ModuleConfigurationSidePanel.scss';

type TModuleConfigurationSidePanelProps = {
  isOpen: boolean;
  zoneConfig: ZoneConfig;
  zoneId: string;
  onClose: () => void;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
  moduleId?: string;
  form?: { layoutHTML: string; styleCSS: string };
  onChange?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const MAIN_CLASS = 'module-configuration-side-panel';
const CLASSES = {
  SIDE_PANEL: `${MAIN_CLASS}__side-panel`,
  FIELD: `${MAIN_CLASS}__field`,
};

const ModuleConfigurationSidePanel: React.FC<TModuleConfigurationSidePanelProps> = ({
  isOpen,
  zoneConfig,
  zoneId,
  onClose,
  moduleId,
  setZoneConfig,
  onChange,
  form = { layoutHTML: '', styleCSS: '' },
}) => {
  const [initialZoneConfig] = useState(zoneConfig);
  const zoneIndex = zoneConfig.zones.findIndex((zone) => zone.id === zoneId);
  const moduleIndex = !!moduleId
    ? zoneConfig.zones[zoneIndex].modules.findIndex((module) => module.id === moduleId)
    : null;
  const module = moduleIndex !== null ? zoneConfig.zones[zoneIndex].modules[moduleIndex] : null;

  const reset = useCallback(() => setZoneConfig(initialZoneConfig), [initialZoneConfig, setZoneConfig]);

  const handleCancel = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (moduleIndex !== null && moduleIndex >= 0) {
      const _zoneConfig = cloneDeep(zoneConfig);
      _zoneConfig.zones[zoneIndex].modules[moduleIndex][name as 'layoutHTML' | 'styleCSS'] = value;
      setZoneConfig(_zoneConfig);
    } else {
      if (onChange) onChange(e);
    }
  };

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: '',
        goBackTitle: `Module Configuration${module ? ` for ${module.name}` : ''}`,
        goBackSubTitle: 'Select content you wish to run a code script on',
        goBackActionHandler: onClose,
      },
      footerInfo: {
        primaryActionText: 'Save',
        primaryActionHandler: onClose,
        secondaryActionText: 'Cancel',
        secondaryActionHandler: handleCancel,
      },
      size: 'large',
      className: CLASSES.SIDE_PANEL,
    }),
    [isOpen, module, onClose, handleCancel],
  );

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={MAIN_CLASS}>
        <div className={CLASSES.FIELD}>
          <label htmlFor="html">HTML</label>
          <textarea
            name="layoutHTML"
            placeholder="<!-- Enter HTML code here -->
<div>Your content here...</div>"
            onChange={handleTextareaChange}
          >
            {module ? module.layoutHTML : form.layoutHTML}
          </textarea>
        </div>
        <div className={CLASSES.FIELD}>
          <label htmlFor="css">CSS</label>
          <textarea
            name="styleCSS"
            placeholder="/* Enter CSS styles here */
div {
  color: #333;
  font-size: 16px;
}"
            onChange={handleTextareaChange}
          >
            {module ? module.styleCSS : form.styleCSS}
          </textarea>
        </div>
      </div>
    </SlidingSidePanel>
  );
};

export default ModuleConfigurationSidePanel;
