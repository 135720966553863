import React, { useCallback, useMemo, useState } from 'react';
import { ZoneConfig } from 'pages/Zones/types';
import { CLASSES, MAIN_CLASS, CONTAINER } from 'pages/Zones/constants/classes';
import { ReactComponent as IconLabel } from 'pages/Zones/assets/icons/icon-label-white-fill.svg';
import { ReactComponent as IconPin } from 'pages/Zones/assets/icons/icon-pin-white-fill.svg';
import { ReactComponent as IconLabelBlack } from 'pages/Zones/assets/icons/icon-label-black-border.svg';
import { ReactComponent as IconLabelGray } from 'pages/Zones/assets/icons/icon-label-gray-border.svg';
import { ReactComponent as IconPinBlack } from 'pages/Zones/assets/icons/icon-pin-black-border.svg';
import { ReactComponent as IconPinGray } from 'pages/Zones/assets/icons/icon-pin-gray-border.svg';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import LabelsTab from './LabelsTab/LabelsTab';
import PinnedContentTab from './PinnedContentTab/PinnedContentTab';
import './PrioritizationManager.scss';

enum PRIORITIZATION_KEYS {
  LABELS = 'Labels',
  PINNED = 'Pinned',
}

enum PRIORITIZATION_INFO_KEYS {
  GO_BACK_TITLE = 'GO_BACK_TITLE',
  GO_BACK_SUB_TITLE = 'GO_BACK_SUB_TITLE',
}

type PRIORITIZATION_INFO_TYPE = {
  [key in PRIORITIZATION_KEYS]: { [key in PRIORITIZATION_INFO_KEYS]: string } & { GO_BACK_ICON: JSX.Element };
};

const PRIORITIZATION_INFO: PRIORITIZATION_INFO_TYPE = {
  Labels: {
    GO_BACK_TITLE: 'Module Prioritization',
    GO_BACK_SUB_TITLE: 'Prioritize content through labels',
    GO_BACK_ICON: <IconLabel />,
  },
  Pinned: {
    GO_BACK_TITLE: 'Module Prioritization',
    GO_BACK_SUB_TITLE: 'Prioritize content through pinning',
    GO_BACK_ICON: <IconPin />,
  },
};

type PrioritizationManagerProps = {
  isOpen: boolean;
  onClose: () => void;
  zoneId: string;
  moduleId: string;
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
};

const PrioritizationManager: React.FC<PrioritizationManagerProps> = ({
  isOpen,
  zoneId,
  moduleId,
  zoneConfig,
  onClose,
  setZoneConfig,
}) => {
  const [currentPrioritizationType, setCurrentPrioritizationType] = useState(PRIORITIZATION_KEYS.PINNED);
  const [initialZoneConfig] = useState(zoneConfig);

  const reset = useCallback(() => setZoneConfig(initialZoneConfig), [initialZoneConfig, setZoneConfig]);

  const handleCancel = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: '',
        goBackIcon: PRIORITIZATION_INFO[currentPrioritizationType].GO_BACK_ICON,
        goBackTitle: PRIORITIZATION_INFO[currentPrioritizationType].GO_BACK_TITLE,
        goBackSubTitle: PRIORITIZATION_INFO[currentPrioritizationType].GO_BACK_SUB_TITLE,
        goBackActionHandler: onClose,
      },
      footerInfo: {
        primaryActionText: 'Save',
        primaryActionHandler: onClose,
        secondaryActionText: 'Cancel',
        secondaryActionHandler: handleCancel,
      },
      size: 'large',
      className: MAIN_CLASS,
    }),
    [isOpen, currentPrioritizationType, onClose, handleCancel],
  );

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={CONTAINER}>
        <div className={CLASSES.CATEGORIES}>
          <button
            className={`${CLASSES.CATEGORIES}__category ${
              currentPrioritizationType === PRIORITIZATION_KEYS.LABELS
                ? CLASSES.CATEGORIES + '__category--selected'
                : ''
            }`}
            onClick={() => setCurrentPrioritizationType(PRIORITIZATION_KEYS.LABELS)}
          >
            {currentPrioritizationType === PRIORITIZATION_KEYS.LABELS ? <IconLabelBlack /> : <IconLabelGray />}
            {PRIORITIZATION_KEYS.LABELS}
          </button>
          <button
            className={`${CLASSES.CATEGORIES}__category ${
              currentPrioritizationType === PRIORITIZATION_KEYS.PINNED
                ? CLASSES.CATEGORIES + '__category--selected'
                : ''
            }`}
            onClick={() => setCurrentPrioritizationType(PRIORITIZATION_KEYS.PINNED)}
          >
            {currentPrioritizationType === PRIORITIZATION_KEYS.PINNED ? <IconPinBlack /> : <IconPinGray />}
            {PRIORITIZATION_KEYS.PINNED}
          </button>
        </div>
        <div className={CLASSES.CATEGORY_CONTENT}>
          {currentPrioritizationType === PRIORITIZATION_KEYS.LABELS && (
            <LabelsTab zoneId={zoneId} moduleId={moduleId} zoneConfig={zoneConfig} setZoneConfig={setZoneConfig} />
          )}
          {currentPrioritizationType === PRIORITIZATION_KEYS.PINNED && (
            <PinnedContentTab
              zoneId={zoneId}
              moduleId={moduleId}
              zoneConfig={zoneConfig}
              setZoneConfig={setZoneConfig}
            />
          )}
        </div>
      </div>
    </SlidingSidePanel>
  );
};

export default PrioritizationManager;
