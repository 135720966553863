import BaseAPI from './base.api';

import { PaginationResponse } from 'interface/shared/api.interface';
import { Content, ContentTemplate } from 'interface/content/content.interface';

export default class ContentAPI extends BaseAPI {
  private ROUTES = {
    allInstances: '/kernel/content/instances',
    allTemplates: '/kernel/content/templates',
    getTemplate: 'kernel/content/templates/:id',
    getInstance:
      'kernel/content/instances/:id?data=true&bypassAdmin=true&managementMode=true&returnRichText=true&disableContextEmbed=true',
    getInstanceNoData: 'kernel/content/instances/:id?data=false&bypassAdmin=true&managementMode=true',
    createInstance: '/kernel/content/instances',
    updateInstance: 'kernel/content/instances/:id',
    getActiveInstances: 'kernel/experiences/activecontents',
  };

  params = {
    limit: 0,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc',
  };

  getContentInstances(opts = {}): Promise<PaginationResponse<Content>> {
    this.params = Object.assign(this.params, opts);
    return this.GET(this.ROUTES.allInstances, this.params);
  }

  getContentTemplates(opts = {}): Promise<PaginationResponse<Content>> {
    this.params = Object.assign(this.params, opts);
    return this.GET(this.ROUTES.allTemplates, this.params);
  }

  getTemplate(id: string): Promise<ContentTemplate> {
    return this.GET(this.buildURL(this.ROUTES.getTemplate, { id }));
  }

  getInstance(id: string, opts?: object): Promise<Content> {
    return this.GET(this.buildURL(this.ROUTES.getInstance, { id }), opts);
  }

  getInstanceWithoutData(id: string): Promise<Content> {
    return this.GET(this.buildURL(this.ROUTES.getInstanceNoData, { id }));
  }

  getActiveInstances(opts = {}): Promise<PaginationResponse<Content>> {
    return this.GET(this.ROUTES.getActiveInstances, opts);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createInstance(payload: any): Promise<Content> {
    return this.POST(this.ROUTES.createInstance, payload);
  }

  updateInstance(payload: Partial<Content>, id: string): Promise<Content> {
    return this.PUT(this.buildURL(this.ROUTES.updateInstance, { id }), payload);
  }
}
