import { Audience, JourneyRule, RuleSchedule, Scheduler } from 'interface/rule/rule.interface';
// serializers.ts
import { ContentState, ContentStateItem } from 'store/content/content.type';
import { PUSH_OPTION_DEFAULTS as DEFAULTS, PushState, PushStateItem } from 'store/push/push.type';
import { DependencyNode, JourneyState, JourneyStatuses, StateStep } from 'store/journey/journey.type';
import {
  ExperienceInstance,
  InstanceAction,
  InstanceStep,
  WebhookAction,
} from 'interface/experience/experience.interface';
import { PushBody, PushBodyData } from 'interface/push/push.interface';
import { RuleState, RuleStateItem, SchedulerStateItem } from 'store/rule/rule.type';
import { WebhookState, WebhookStateItem } from './webhook/webhook.type';
import { getStepOfRuleSyntheticId, getStepRuleSyntheticId } from 'store/helper';

import { ContentAction } from 'interface/experience/experience.interface';
import { Dependencies } from 'interface/common.interface';
import { PushAction } from 'interface/experience/experience.interface';
import { RootState } from 'store/store';
import { START_TIME } from 'pages/ExperienceCanvas/types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { selectStepRule } from 'store/rule/rule.selector';
import { showContentBox } from 'store/journey/journey.selector';

/**
 * Serializes the current journey's contents into back-end ready payloads
 * takes in an option array of refIds
 * @returns { [refId: string]: ContentAction }
 */
export function serializeContents(contentState: ContentState, refIds?: string[]) {
  const retVal: { [refId: string]: ContentAction } = {};

  if (!refIds) {
    for (const key in contentState.byRefId) {
      retVal[key] = serializeContent(contentState.byRefId[key]);
    }
  } else {
    refIds.forEach((refId) => {
      if (contentState.byRefId[refId]) {
        retVal[refId] = serializeContent(contentState.byRefId[refId]);
      }
    });
  }

  return retVal;
}

export function serializeContent(content: ContentStateItem): ContentAction {
  const { refId, actionType, dependencies, prototypeId, id, payload, isOptional } = content;

  const isContentGrouping = actionType === 'content-grouping';

  return {
    id: (isContentGrouping ? payload?.id : id) || undefined,
    refId,
    actionType,
    templateId: prototypeId,
    dependencies,
    isOptional,
    payload: isContentGrouping ? payload : undefined,
  };
}

export function serializeRule(
  rule?: RuleStateItem,
  journeyName?: string,
  schedule?: RuleSchedule,
  dependencies?: Dependencies,
  isDraft = false,
  stepIdx = 0,
  ruleType: 'restricted' | 'preferred' = 'restricted',
  // Allows the RB memoisers to use raw payloads.
  useRaw = false,
): JourneyRule | undefined {
  if (!rule) return undefined;
  const { refId, dependencies: defaultDeps, templateId, id, name, headName, isOptional, ...rulePayload } = rule;

  return {
    refId,
    templateId,
    dependencies: dependencies ?? defaultDeps,
    id,
    isOptional,
    payload: {
      ...rulePayload,
      name: useRaw ? name : name || `${stepIdx + 1}-${ruleType[0].toUpperCase()}-${journeyName ?? ''}-${Date.now()}`,
      // potentially redundant whitespace trimming - reassess at a later date
      headName: useRaw ? headName : headName?.replaceAll(' ', '') || `s${stepIdx + 1}-${ruleType[0]}-${Date.now()}`,
      schedule: schedule ?? rulePayload.schedule,
      isDraft: useRaw ? rulePayload.isDraft : isDraft,
      visibility: useRaw ? rulePayload.visibility : isDraft ? 'draft' : 'system',
    },
  };
}

/**
 * Serializes the current journey's contents into back-end ready payloads
 * takes in an option array of refIds
 * @returns { [refId: string]: PushAction }
 */
export function serializePushes(pushState: PushState, instanceName?: string, refIds?: string[]) {
  const retVal: { [refId: string]: PushAction } = {};

  if (!refIds) {
    for (const key in pushState.byRefId) {
      retVal[key] = serializePush(pushState.byRefId[key], instanceName);
    }
  } else {
    refIds.forEach((refId) => {
      if (pushState.byRefId[refId]) {
        retVal[refId] = serializePush(pushState.byRefId[refId]);
      }
    });
  }

  return retVal;
}

// these helpers will be non-reusable for now, but maybe abstracted in the future
// these are also shallow copies - potentially need to write deep copy code
function serializePush(
  push: PushStateItem,
  journeyName?: string,
  dependencies?: Dependencies,
  stepIdx = 0,
): PushAction {
  return {
    actionType: push.actionType,
    refId: push.refId,
    dependencies: dependencies ?? push.dependencies,
    templateId: push.templateId,
    id: push.id,
    isOptional: push.isOptional,
    payload: {
      backgroundPushType: push.backgroundPushType,
      createdAt: push.createdAt,
      creatorID: push.creatorID,
      executionHistory: push.executionHistory,
      id: push.id,
      isActive: push.isActive,
      isDraft: push.isDraft,
      isSent: push.isSent,
      keywords: push.keywords,
      labels: push.labels,
      lastBroadcastDate: push.lastBroadcastDate,
      pushRequestID: push.pushRequestID,
      recipients: push.recipients,
      schedule: push.schedule,
      tags: push.tags,
      tenantID: push.tenantID,
      updatedAt: push.updatedAt,
      userScope: push.userScope,
      visibility: push.visibility,
      body: [serializePushBody(push)],
      hasMessageInterval: push.hasMessageInterval,
      name: push.name || `${stepIdx + 1}-${journeyName ?? ''}-${Date.now()}`,
      options: {
        max: push.max ?? DEFAULTS.MAX,
        cooldown: push.cooldown ?? DEFAULTS.COOLDOWN,
        sampling: push.sampling,
        remain: push.remain,
        delay: push.delay ?? DEFAULTS.DELAY,
        limit: push.limit ?? DEFAULTS.LIMIT,
      },
      pushType: push.pushType,
      rules: push.ruleId
        ? [
            {
              ruleID: push.ruleId,
              result: push.ruleTriggerType,
            },
          ]
        : [],
    },
  };
}

function serializePushBody(push: PushStateItem): PushBody {
  return {
    payload: {
      action: push.action,
      alert: push.alert,
      category: push.category,
      data: serializePushData(push),
      entity: push.entity,
      title: push.title,
    },
    weight: push.weight,
  };
}

function serializePushData(push: PushStateItem): PushBodyData {
  const retVal: PushBodyData = {
    pushPayloadTypeId: push.pushPayloadTypeId,
    contentBlueprintId: push.contentBlueprintId,
  };

  if (push.pushPayloadType === 'content' || push.pushPayloadType === undefined) {
    retVal['contentId'] = push.contentId
      ? {
          value: push.contentId,
          entity: 'content',
        }
      : undefined;
  }

  if (push.pushPayloadType === 'weblink' || push.pushPayloadType === undefined) {
    retVal['url'] = push.url;
  }

  if (push.pushPayloadType === 'actionlink' || push.pushPayloadType === undefined) {
    retVal['actionType'] = push.actionLinkType;
    retVal['actionScheme'] = push.actionLinkScheme;
  }

  return retVal;
}

function serializeWebhook(webhook: WebhookStateItem): WebhookAction {
  const { actionType, refId, isOptional, name, ...webhookPayload } = webhook;

  // Hack to make the webhook unique for multiple save on the journey
  const webhookName = `${name}-${Date.now()}`;

  return {
    actionType,
    refId,
    isOptional,
    payload: {
      name: webhookName,
      ...webhookPayload,
    },
  };
}

/**
 * Serializes the current journey state into a back-end ready payload
 * @returns Experience
 */
export function serializeJourney(
  state: RootState,
  isDraft: boolean,
  journeyStatus?: JourneyStatuses,
): ExperienceInstance {
  const journeyState: JourneyState = state.te.journey;
  const pushState: PushState = state.te.push;
  const ruleState: RuleState = state.te.rule;
  const contentState: ContentState = state.te.content;
  const webhookState: WebhookState = state.te.webhook;
  const primaryRuleRefIds = journeyState.steps?.map((_, idx) => selectStepRule(state.te, idx)?.refId);

  const serializedSteps: InstanceStep[] = journeyState.steps?.map((step: StateStep, idx) => {
    const audience: Audience = { restricted: null, preferred: null };
    const actions: InstanceAction[] = [];
    const resStateItem = ruleState.byRefId[step.rule.restricted];
    const prefStateItem = ruleState.byRefId[step.rule.preferred];
    const serializeRestricted = resStateItem && (!resStateItem.isOptional || !!resStateItem.enableOptionalNode);
    const serializePreferred = prefStateItem && (!prefStateItem.isOptional || !!prefStateItem.enableOptionalNode);
    const restrictedEmpty = isEmpty(resStateItem) || !resStateItem.logic;
    const preferredEmpty = isEmpty(prefStateItem) || !prefStateItem.logic;
    const omitRule = !serializeRestricted && !serializePreferred;
    if (step.push) {
      const pushStateItem = pushState.byRefId[step.push];
      if (pushStateItem && (!pushStateItem.isOptional || pushStateItem.enableOptionalNode)) {
        const dependencies = serializeDependency(
          journeyState.dependencyGraph?.[step.push],
          omitRule,
          primaryRuleRefIds,
        );
        actions.push(serializePush(pushStateItem, journeyState.name, dependencies, idx));
      }
    }

    if (step.content.length > 0 && showContentBox(state, idx)) {
      step.content.forEach((refId) => {
        const contentStateItem = contentState.byRefId[refId];
        if (contentStateItem && (!contentStateItem.isOptional || contentStateItem.enableOptionalNode)) {
          actions.push(serializeContent(contentStateItem));
        }
      });
    }

    if (step.webhook) {
      const webhookStateItem = webhookState.byRefId[step.webhook];
      if (webhookStateItem) {
        actions.push(serializeWebhook(webhookStateItem));
      }
    }

    if (!omitRule) {
      const synthId = getStepRuleSyntheticId(idx);
      const stepRuleRefId = primaryRuleRefIds[idx];
      const dependencies = serializeDependency(journeyState.dependencyGraph?.[synthId], false, primaryRuleRefIds);
      const schedule = {
        startScheduler: serializeSchedule(
          ruleState.startSchedulerMap[synthId],
          step.omitStart || step.omitSched,
          step,
          journeyState.steps,
        ),
        endScheduler: serializeSchedule(
          ruleState.endSchedulerMap[synthId],
          step.omitEnd ||
            (step.omitSched && Object.keys(ruleState?.startSchedulerMap[synthId]?.predicates || {}).length === 0),
        ),
      };
      if (!!stepRuleRefId && stepRuleRefId === prefStateItem?.refId) {
        audience.restricted =
          serializeRestricted && !restrictedEmpty
            ? serializeRule(resStateItem, journeyState.name, {}, {}, isDraft, idx, 'restricted') ?? null
            : null;
        audience.preferred =
          serializeRule(prefStateItem, journeyState.name, schedule, dependencies, isDraft, idx, 'preferred') ?? null;
      } else {
        audience.preferred =
          serializePreferred && !preferredEmpty
            ? serializeRule(prefStateItem, journeyState.name, {}, {}, isDraft, idx, 'preferred') ?? null
            : null;
        audience.restricted =
          serializeRule(resStateItem, journeyState.name, schedule, dependencies, isDraft, idx, 'restricted') ?? null;
      }
    }

    return {
      audience,
      actions,
      // Conditionally add the following properties (until proposed branch flow structure is prod-ready)
      ...(step.id ? { id: step.id } : {}),
      ...(step.type ? { type: step.type } : {}),
      ...(step.children ? { children: step.children } : {}),
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { templateName, dependencyGraph, templateType, ...journey } = journeyState;

  return {
    ...journey,
    status: journeyStatus,
    steps: serializedSteps,
  };
}

/**
 * Serializes a dependency
 * @returns Dependencies
 */
export function serializeDependency(
  node?: DependencyNode,
  omitRule?: boolean,
  primaryRuleRefIds?: (string | undefined)[],
): Dependencies | undefined {
  const retVal: Dependencies = {};

  if (!node || node.requires.length === 0) return retVal;

  node.requires.forEach((dep) => {
    if (omitRule && dep.var === '{{dep.rule-id.0}}') {
      return;
    } else {
      const ruleStep = getStepOfRuleSyntheticId(dep.refId);
      const depRefId =
        ruleStep !== undefined && primaryRuleRefIds ? primaryRuleRefIds[ruleStep] ?? dep.refId : dep.refId;
      retVal[depRefId] = dep.var;
    }
  });

  return retVal;
}

/**
 * Serializes a schedule
 * @returns Dependencies
 */
export function serializeSchedule(
  schedule?: SchedulerStateItem,
  resetAbsoluteSched?: boolean,
  step?: StateStep,
  steps?: StateStep[],
): Scheduler | undefined {
  if (!schedule) return undefined;

  const scheduleExists = schedule.logic || schedule.start || schedule.cronExpression;
  const frequency = schedule.frequency ?? 0;
  const frequencyType = schedule.frequencyType ?? 'once';

  // workaround for unsupported start "now" value
  const start = schedule.start === START_TIME.NOW ? moment().add(5, 'minutes').unix() : schedule.start;

  let isChildStepWithoutSchedule = false;
  if (!scheduleExists) {
    if (step?.id && steps) {
      for (const _step of steps) {
        if (_step.id !== step.id) {
          const children = _step.children;
          isChildStepWithoutSchedule = children?.some((childStepId) => childStepId === step.id) ?? false;
        }
      }
    }
  }

  return !scheduleExists
    ? /* Currently the journey creation throws `invalid repeat frequencyType` error if schedule is not configured for child steps */
      isChildStepWithoutSchedule
      ? {
          repeat: {
            frequency: 0,
            frequencyType: 'once',
            limit: 0,
          },
        }
      : undefined
    : {
        isRelative: schedule.isRelative,
        // start should be 0 if we have a cronExpression
        start: schedule.cronExpression ? 0 : resetAbsoluteSched ? 0 : start,
        suspend: schedule.suspend,
        timezone: resetAbsoluteSched && !schedule.cronExpression ? { name: '', offset: 0 } : schedule.timezone,
        contextualRule: !schedule.cronExpression
          ? {
              logic: schedule.logic,
              modifier: schedule.modifier,
              predicates: schedule.predicates,
              reevaluateOnReschedule: schedule.reevaluateOnReschedule,
              target: schedule.target,
            }
          : undefined,
        repeat: !schedule.cronExpression
          ? {
              limit: schedule.limit ?? 0,
              frequency,
              // if the schedule exists, frequency type must be set
              frequencyType: scheduleExists && !frequencyType ? 'once' : frequencyType,
            }
          : undefined,
        cronExpression: schedule.cronExpression || '',
      };
}
