import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';

import { removeJourneyStep } from 'store/journey/journey.thunk';
import HeaderLayout from 'components/ExperienceCanvas/HeaderLayout/HeaderLayout';

// import DeleteIcon from 'pages/ExperienceCanvas/assets/icons/DeleteIcon';
// import PerformanceIcon from 'pages/ExperienceCanvas/assets/icons/PerformanceIcon';
import StartIcon from 'pages/ExperienceCanvas/assets/icons/StartIcon';
import NavigateTouchpointIcon from 'pages/ExperienceCanvas/assets/icons/NavigateTouchpointIcon';

import ErrorListModal, { TErrorListModal } from 'components/Modal/ErrorListModal/ErrorListModal';
import TouchpointCard from 'components/ExperienceCanvas/TouchpointCard/TouchpointCard';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import ErrorSplash from 'components/ExperienceCanvas/ErrorSplash/ErrorSplash';
import useJourneyInstance from 'hooks/useJourneyInstance';
import useContentCreationManager from 'hooks/useContentCreationManager';
import { TExperienceCanvasRouteParams } from '../types';

import './ExperienceOverview.scss';
import { StepMetadata } from 'interface/experience/experience.interface';
import EndIcon from '../assets/icons/EndIcon';

function ExperienceOverview() {
  const history = useHistory();

  const { pid: projectId, id: journeyId } = useRouteMatch<TExperienceCanvasRouteParams>().params;

  const dispatch = useDispatch();
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const createFromId = urlParams.get('createFromId')?.toLowerCase() ?? '';
  const isTemplate = urlParams.get('fromTemplateLibrary')?.toLowerCase() === 'true' || createFromId !== '';
  const {
    journeyInstance,
    isLoading,
    fetch: refetch,
    error: journeyError,
  } = useJourneyInstance(journeyId, isTemplate, createFromId);
  useContentCreationManager(true);

  const handleClickTouchpointFooter = (errorModalProps: Partial<TErrorListModal>) => {
    setErrorModalProps((prev) => {
      return { ...prev, ...errorModalProps };
    });
  };

  const handleClickTouchpointEdit = (stepIdx: number) => {
    if (!projectId) {
      return;
    }

    history.push(`/project/${projectId}/experiences/${journeyId}/touchpoint/${stepIdx}${window.location.search}`);
  };

  const handleClickTouchpointDelete = (stepIdx: number) => {
    dispatch(removeJourneyStep(stepIdx));
  };

  useEffect(() => {
    document.title = `${isTemplate ? 'Create' : 'Edit'} Experience | Experience Studio @ Flybits`;
  }, [isTemplate]);

  useEffect(() => {
    if (!!journeyInstance.id && journeyId !== journeyInstance.id) {
      urlParams.set('fromTemplateLibrary', 'false');
      history.replace(`/project/${projectId}/experiences/${journeyInstance.id}/overview?${urlParams.toString()}`);
    }
  }, [history, projectId, journeyId, journeyInstance.id, urlParams]);

  const startIconFill = '#4992FD';
  const navigateTouchpointIconFill = '#697186';
  const endIconFill = '#FF541E';

  const [errorModalProps, setErrorModalProps] = useState<Omit<TErrorListModal, 'onClose'>>({
    isVisible: false,
    stepIdx: 0,
  });

  const parsedJourneyMetadata = JSON.parse(journeyInstance?.metadata?.stepMetadata ?? '[]') as StepMetadata[];

  return isLoading ? (
    <div className="loading-icon-container" aria-label="experience is loading">
      <LoadingIcon className="loading-icon-container__icon" />
    </div>
  ) : journeyError ? (
    <ErrorSplash error={journeyError} />
  ) : (
    <>
      <div className="overview-container">
        {/* ---------- EXPERIENCE OVERVIEW HEADER ---------- */}
        <HeaderLayout journeyInstance={journeyInstance} refetch={refetch} />

        {/* ---------- TOUCHPOINTS/CANVAS AREA ---------- */}
        <div className="overview-container__body" aria-label="touchpoints">
          <div className="overview-terminal-icon-container" aria-label="start touchpoint">
            <StartIcon fill={startIconFill} />
            <span className="overview-terminal-icon-container__text overview-terminal-icon-container__text--start">
              Start
            </span>
          </div>
          {journeyInstance.steps?.map((step, index: number) => (
            <React.Fragment key={`touchpoint.${index}.card.fragment`}>
              <div className="overview-touchpoint-card-container">
                <TouchpointCard
                  stepIdx={index}
                  title={step.name ?? `Touchpoint ${index + 1}`}
                  subtitle={step.desc}
                  showFooter
                  iconUrl={parsedJourneyMetadata[index]?.iconUrl}
                  onEdit={() => handleClickTouchpointEdit(index)}
                  onDelete={() => handleClickTouchpointDelete(index)}
                  onClickFooter={handleClickTouchpointFooter}
                />
              </div>
              {index < journeyInstance.steps?.length - 1 && (
                <div className="overview-touchpoint-next-icon-container" aria-label="next touchpoint">
                  <NavigateTouchpointIcon fill={navigateTouchpointIconFill} />
                </div>
              )}
            </React.Fragment>
          ))}
          <div className="overview-terminal-icon-container" aria-label="last touchpoint">
            <>
              <EndIcon fill={endIconFill} />
              <span className="overview-terminal-icon-container__text overview-terminal-icon-container__text--end">
                End
              </span>
            </>
          </div>
        </div>
      </div>
      <ErrorListModal
        {...errorModalProps}
        onClose={() =>
          setErrorModalProps((prev) => {
            return { ...prev, isVisible: false };
          })
        }
      />
    </>
  );
}

export default ExperienceOverview;
