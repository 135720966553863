import React, { useContext } from 'react';
import { ModuleSectionHeader, ModuleSectionRoot } from './ModuleSectionComponents';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';

import { FlightToggleSwitch } from '@flybits/design-system';

import { ReactComponent as Deprioritized } from 'assets/icons/deprioritize-content.svg';

import './DeprioritizedSection.scss';
import { Module } from 'pages/Zones/types';

type EnableDeprioritized = {
  sectionNumber: number;
  module: Module;
};
function EnableDeprioritized({ sectionNumber, module }: EnableDeprioritized) {
  const MAIN_CLASS = 'labeled-section';
  const { updateModule, selectedZone } = useContext(PrioritizationContext);
  const { allowDeprioritization } = module;
  const handleOnChangeToggle = (newValue: boolean) =>
    selectedZone.id && updateModule(selectedZone.id, { ...module, allowDeprioritization: newValue });
  function LabeledAndOrderContentHeader() {
    return (
      <div className={`${MAIN_CLASS}`}>
        <ModuleSectionHeader
          icon={<Deprioritized />}
          title={'Enable deprioritize button'}
          subtext={
            <>
              Toggling this feature allows the user the option to defer the content. It will move to the end of the
              priority order. You can also set the content to disappear after an allotted time.
            </>
          }
        />
        <FlightToggleSwitch
          className="deprioritized-section"
          checked={!!allowDeprioritization}
          onChange={handleOnChangeToggle}
        />
      </div>
    );
  }

  return (
    <ModuleSectionRoot sectionNumber={sectionNumber}>
      <LabeledAndOrderContentHeader />
    </ModuleSectionRoot>
  );
}

export default EnableDeprioritized;
