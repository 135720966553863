import { Module } from 'pages/Zones/types';
import React, { useContext } from 'react';
import {
  ModuleSectionHeader,
  ModuleSectionItem,
  ModuleSectionItems,
  ModuleSectionRoot,
  ModuleSectionEmptyItem,
} from './ModuleSectionComponents';

import './LabelsSection.scss';

import { FlightButton, getIcon } from '@flybits/design-system';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import { toOrderBy, toOrderDirection } from '../../zones.constants';

type LabeledAndOrderContentProps = {
  sectionNumber: number;
  module: Module;
};

type LimitProps = {
  limit: number;
};

type LabelSortFields = {
  desc: boolean;
  orderBy: string;
};

const toLabelSortFieldString = (descending: boolean, orderBy: string): string => {
  return `${toOrderDirection(descending).name} (${toOrderBy(orderBy).name})`;
};

function LabeledAndOrderContent({ sectionNumber, module }: LabeledAndOrderContentProps) {
  const CLASS_LABELED_SECTION = 'labeled-section';
  const { setLabelManagementOpen, setLabelAndOrderedContentSelectedModuleId } = useContext(PrioritizationContext);
  const labeledContent = module?.labelFilters ?? [];

  function LabeledAndOrderContentHeader() {
    return (
      <div className={`${CLASS_LABELED_SECTION}`}>
        <div className={`${CLASS_LABELED_SECTION} ${CLASS_LABELED_SECTION}__left`}>
          <ModuleSectionHeader
            icon={getIcon('tag', '') ?? undefined}
            title={'Label and order content'}
            subtext={
              <div>Labels can be used to categorize and manage how your content is organized within the module.</div>
            }
          />
        </div>
        <div className={`${CLASS_LABELED_SECTION} ${CLASS_LABELED_SECTION}__right`}>
          <FlightButton
            iconLeft="tag"
            label="Manage Labels"
            className={`${CLASS_LABELED_SECTION}__manage-labels-button`}
            onClick={() => {
              setLabelManagementOpen(true);
              setLabelAndOrderedContentSelectedModuleId(module.id);
            }}
          />
        </div>
      </div>
    );
  }

  function Limit({ limit }: LimitProps) {
    return <div className={`${CLASS_LABELED_SECTION}__limit`}>Limit to {limit}</div>;
  }

  function LabelSortField({ desc, orderBy }: LabelSortFields) {
    return <div className={`${CLASS_LABELED_SECTION}__label-sort-field`}>{toLabelSortFieldString(desc, orderBy)}</div>;
  }

  return (
    <ModuleSectionRoot sectionNumber={sectionNumber}>
      <LabeledAndOrderContentHeader />
      <ModuleSectionItems>
        {labeledContent.length > 0 ? (
          labeledContent.map((labelInfo, index) => (
            <ModuleSectionItem key={index} index={index + 1} title={labelInfo.label}>
              <LabelSortField desc={labelInfo.orderAsc} orderBy={labelInfo.orderBy} />
              <Limit limit={labelInfo.limit} />
            </ModuleSectionItem>
          ))
        ) : (
          <ModuleSectionEmptyItem>No labels have been added to this module.</ModuleSectionEmptyItem>
        )}
      </ModuleSectionItems>
    </ModuleSectionRoot>
  );
}

export default LabeledAndOrderContent;
