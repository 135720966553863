import React from 'react';

// Custom imports
import { getIcon } from '@flybits/design-system';

// Stylings

type StatusPillType = {
  style: string;
  headIcon: SVGAElement | null;
  statusMsg: string;
  isShowSlideOut: boolean;
  ariaLabel?: string;
};

const StatusPill = (props: StatusPillType): JSX.Element | null => {
  const { style, headIcon, statusMsg, isShowSlideOut, ariaLabel = '' } = props;
  const postIcon = isShowSlideOut ? 'baselineKeyboardArrowRight' : 'infoFilled';
  return (
    <div className={style} aria-label={ariaLabel}>
      <div className="status__label">
        {headIcon && headIcon}
        {statusMsg}
      </div>
      <div className="status__icon--post--container">{getIcon(postIcon, { className: `status__icon--post` })}</div>
    </div>
  );
};

const MemoizedStatusPill = React.memo(StatusPill);

export default MemoizedStatusPill;
