import React, { useState } from 'react';

import { User } from 'interface/auth/auth.interface';

import { FlightButton, FlightAvatar } from '@flybits/design-system';

import './AddComment.scss';

type TAddCommentProps = {
  addComment: (comment: string, mustResolve: boolean, parentId?: string) => void;
  user?: User;
  showHeader?: boolean;
  parentId?: string;
  placeholder?: string;
  size?: 'small' | 'large';
  isReviewer: boolean;
};

const MAIN_CLASS = 'add-comment';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  HEADER_BUTTON: `${MAIN_CLASS}__header__button`,
  INPUT_CONTAINER: `${MAIN_CLASS}__input`,
  INPUT_WRAPPER: `${MAIN_CLASS}__input__wrapper`,
  INPUT: `${MAIN_CLASS}__input__input`,
  INPUT_ICON: `${MAIN_CLASS}__input__icon`,
  INPUT_ACTIONS: `${MAIN_CLASS}__input__actions`,
  INPUT_SUBMIT: `${MAIN_CLASS}__input__submit`,
};

const AddComment: React.FC<TAddCommentProps> = ({
  addComment,
  user,
  isReviewer,
  showHeader = false,
  placeholder = 'Type a comment',
  parentId,
  size = 'large',
}) => {
  const [mustResolve, setMustResolve] = useState(false);
  const [comment, setComment] = useState('');

  return (
    <div className={`${MAIN_CLASS} ${MAIN_CLASS}--${size}`}>
      {showHeader && isReviewer && (
        <div className={CLASSES.HEADER}>
          <FlightButton
            className={`${CLASSES.HEADER_BUTTON}${mustResolve ? '' : ' selected'}`}
            iconLeft="textBubble"
            onClick={() => setMustResolve(false)}
            size="small"
            label="Leave a comment"
          />
          <FlightButton
            className={`${CLASSES.HEADER_BUTTON}${mustResolve ? ' selected' : ''}`}
            iconLeft="clipboardCheck"
            onClick={() => setMustResolve(true)}
            size="small"
            label="Request Change"
          />
        </div>
      )}
      <div className={CLASSES.INPUT_CONTAINER}>
        <div className={CLASSES.INPUT_ICON}>
          <FlightAvatar firstName={user?.firstName} lastName={user?.lastName} />
        </div>
        <div className={CLASSES.INPUT_WRAPPER}>
          <textarea
            className={CLASSES.INPUT}
            onChange={(e) => setComment(e.target.value)}
            placeholder={placeholder}
            value={comment}
          />
          <div className={CLASSES.INPUT_ACTIONS}>
            <FlightButton
              className={CLASSES.INPUT_SUBMIT}
              iconLeft="send"
              onClick={async () => {
                await addComment(comment, mustResolve, parentId);
                setComment('');
              }}
              label=""
              ariaLabel="submit comment"
              size="small"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
