import React from 'react';
import { TIconProps } from '../types';

function IncompleteIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
    >
      <g clipPath="url(#clip0_2129_153174)">
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
          fill={fill}
        />
        <path
          d="M18 12C18 11.175 17.325 10.5 16.5 10.5C15.675 10.5 15 11.175 15 12C15 12.825 15.675 13.5 16.5 13.5C17.325 13.5 18 12.825 18 12Z"
          fill={fill}
        />
        <path
          d="M12 13.5C11.175 13.5 10.5 12.825 10.5 12C10.5 11.175 11.175 10.5 12 10.5C12.825 10.5 13.5 11.175 13.5 12C13.5 12.825 12.825 13.5 12 13.5Z"
          fill={fill}
        />
        <path
          d="M7.5 13.5C6.675 13.5 6 12.825 6 12C6 11.175 6.675 10.5 7.5 10.5C8.325 10.5 9 11.175 9 12C9 12.825 8.325 13.5 7.5 13.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2129_153174">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IncompleteIcon;
