import { TEATimeline } from 'interface/templated-experience/analytics.interface';

export const AnalyticsPerformanceActionType = {
  SET_PERFORMANCE_TA_LOADING: 'SET_PERFORMANCE_TA_LOADING',
  SET_PERFORMANCE_CV_LOADING: 'SET_PERFORMANCE_CV_LOADING',
  SET_PERFORMANCE_OP_LOADING: 'SET_PERFORMANCE_OP_LOADING',
  SET_PERFORMANCE_PD_LOADING: 'SET_PERFORMANCE_PD_LOADING',
  SET_PERFORMANCE_CC_LOADING: 'SET_PERFORMANCE_CC_LOADING',
  SET_PERFORMANCE_ERROR: 'SET_PERFORMANCE_ERROR',
  SET_PERFORMANCE_TA_DATA: 'SET_PERFORMANCE_TA_DATA',
  SET_PERFORMANCE_CV_DATA: 'SET_PERFORMANCE_CV_DATA',
  SET_PERFORMANCE_OP_DATA: 'SET_PERFORMANCE_OP_DATA',
  SET_PERFORMANCE_PD_DATA: 'SET_PERFORMANCE_PD_DATA',
  SET_PERFORMANCE_CC_DATA: 'SET_PERFORMANCE_CC_DATA',
};

export interface AnalyticsPerformanceState {
  targetAudience: TEATimeline;
  contentViews: TEATimeline;
  pushDelivered: TEATimeline;
  openedPush: TEATimeline;
  contentClicks: TEATimeline;
  isTargetAudienceLoading: boolean;
  isContentViewsLoading: boolean;
  isOpenedPushLoading: boolean;
  isPushDeliveredLoading: boolean;
  isContentClicksLoading: boolean;
  errorMessage: string;
}

export interface SetPerformanceDataAction {
  type: string;
  payload: TEATimeline;
}

export interface SetPerformanceLoadingAction {
  type: string;
  payload: boolean;
}

export interface SetPerformanceErrorAction {
  type: typeof AnalyticsPerformanceActionType.SET_PERFORMANCE_ERROR;
  payload: string;
}
