import { Plugin } from 'interface/plugin/plugin.interface';

export enum PluginActionType {
  SET_PLUGINS = 'SET_PLUGINS',
}

export interface PluginState {
  plugins: Plugin[] | null;
}

export interface SetPluginAction {
  type: PluginActionType.SET_PLUGINS;
  payload: {
    plugins: Plugin[];
  };
}
