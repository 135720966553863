import './PushSidePanel.scss';

import React, { useCallback, useMemo } from 'react';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import IconPush from 'pages/FlowBuilder/assets/icons/IconPush';
import TouchpointEditor from 'pages/ExperienceCanvas/TouchpointEditor/TouchpointEditor';

const MAIN_CLASS = 'push-side-panel';

function PushSidePanel() {
  const flowBuilderContext = useFlowBuilderContext();
  const flowPushContext = flowBuilderContext.sidePanels.push;
  const closeSidePanel = useCallback(() => {
    flowPushContext.setPushContext({ isOpen: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowPushContext.setPushContext]);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: flowPushContext.isOpen,
      headerInfo: {
        mainTitle: '',
        goBackIcon: flowPushContext.iconOverride ?? <IconPush />,
        goBackTitle: flowPushContext.titleOverride ?? 'Push Notification',
        goBackSubTitle: 'Currently editing from Flow Builder',
        goBackActionHandler: closeSidePanel,
      },
      showFooter: false,
      size: 'large',
    }),
    [flowPushContext.isOpen, flowPushContext.iconOverride, flowPushContext.titleOverride, closeSidePanel],
  );

  return (
    <div className={MAIN_CLASS}>
      <SlidingSidePanel {...slidingSidePanelProps}>
        <TouchpointEditor
          isFromFlow
          stepIdx={flowPushContext.stepIndex}
          activeTabIdx={1}
          closeFlowSidePanel={closeSidePanel}
        />
      </SlidingSidePanel>
    </div>
  );
}

export default PushSidePanel;
