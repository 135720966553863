export const ZM_LIST = 'zone-list';

export const ZM_LIST_CLASSES = {
  HEADER: `${ZM_LIST}__header`,
  CONTAINER: `${ZM_LIST}__container`,
  CARD: `${ZM_LIST}__card`,
  CARD_ICON: `${ZM_LIST}__card-icon`,
  CARD_BODY: `${ZM_LIST}__card-body`,
  ADD: `${ZM_LIST}__add`,
};
