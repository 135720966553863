import React, { useEffect, useRef } from 'react';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import { TContentPreviewCardProps } from '../types';
import './ContentPreviewCard.scss';
import { TemplateType, streamlinedContentTypes } from 'interface/content/content.interface';

const MAIN_CLASS = 'content-preview-card';
const CLASSES = {
  IMAGE: `${MAIN_CLASS}__image`,
  CONTENT: `${MAIN_CLASS}__content`,
  HEADER: `${MAIN_CLASS}__header`,
  TITLE: `${MAIN_CLASS}__title`,
  DESCRIPTION: `${MAIN_CLASS}__description`,
  ACTIONS: `${MAIN_CLASS}__actions`,
  BUTTON_LINK: `${MAIN_CLASS}__button-link`,
  TEXT_LINK: `${MAIN_CLASS}__text-link`,
  NO_PREVIEW: `${MAIN_CLASS}--no-preview`,
};

export default function ContentPreviewCard({
  imageUrl,
  type = '',
  header = '',
  title = '',
  description = '',
  actions = [],
  isLoading = false,
}: TContentPreviewCardProps) {
  const descriptionRef = useRef<HTMLDivElement>(null);

  const safeDecodeURIComponent = (text: string) => {
    try {
      return decodeURIComponent(text);
    } catch {
      return text;
    }
  };

  useEffect(() => {
    const descriptionEl = descriptionRef.current;
    const decodedDescription = safeDecodeURIComponent(description);
    if (descriptionEl) {
      const isDescriptionHTML = /<\/?[a-z][\s\S]*>/i.test(decodedDescription || '');
      if (isDescriptionHTML) {
        descriptionEl.innerHTML = decodedDescription || '';
      } else {
        descriptionEl.innerText = decodedDescription || '';
      }
    }
  }, [description]);

  if (isLoading) {
    return (
      <div className={`${MAIN_CLASS} ${CLASSES.NO_PREVIEW}`} aria-label="content preview is loading">
        <div className={CLASSES.DESCRIPTION}>
          <LoadingIcon />
        </div>
      </div>
    );
  }

  return streamlinedContentTypes.includes(type as TemplateType) &&
    (!!imageUrl || !!header || !!title || !!description) ? (
    <div className={MAIN_CLASS} aria-label="content preview">
      {imageUrl && (
        <div className={CLASSES.IMAGE} aria-label="content image">
          <img src={imageUrl} alt="preview" />
        </div>
      )}
      <div className={CLASSES.CONTENT}>
        {header && (
          <div className={CLASSES.HEADER} aria-label="content header">
            {header}
          </div>
        )}
        {title && (
          <div className={CLASSES.TITLE} aria-label="content title">
            {title}
          </div>
        )}
        {description && (
          <div className={CLASSES.DESCRIPTION} aria-label="content description" ref={descriptionRef}></div>
        )}
      </div>
      {actions && actions.length > 0 && (
        <div className={CLASSES.ACTIONS}>
          {actions.map((label, index) => (
            <button
              type="button"
              key={`${label}-${index}`}
              className={type === TemplateType.CONCIERGE_CARD_BUTTONS ? CLASSES.BUTTON_LINK : CLASSES.TEXT_LINK}
            >
              {label}
            </button>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div className={`${MAIN_CLASS} ${CLASSES.NO_PREVIEW}`} aria-label="content preview">
      <div className={CLASSES.CONTENT}>
        {title && (
          <div className={CLASSES.TITLE} aria-label="content title">
            {title}
          </div>
        )}
        <div className={CLASSES.DESCRIPTION} aria-label="content description">
          No preview available
        </div>
      </div>
    </div>
  );
}
