import React from 'react';
import { IconProps } from './icon.types';

function IconStartNode(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99984 0.583374C4.094 0.583374 0.916504 3.76087 0.916504 7.66671C0.916504 11.8192 4.774 14.3675 7.3265 16.0533L7.76817 16.3467C7.83817 16.3934 7.919 16.4167 7.999 16.4167C8.079 16.4167 8.15984 16.3934 8.22984 16.3467L8.6715 16.0533C11.224 14.3675 15.0815 11.8192 15.0815 7.66671C15.0832 3.76087 11.9057 0.583374 7.99984 0.583374ZM7.99984 9.75004C6.849 9.75004 5.9165 8.81754 5.9165 7.66671C5.9165 6.51587 6.849 5.58337 7.99984 5.58337C9.15067 5.58337 10.0832 6.51587 10.0832 7.66671C10.0832 8.81754 9.15067 9.75004 7.99984 9.75004Z"
        fill="white"
      />
    </svg>
  );
}

export default IconStartNode;
