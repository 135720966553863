import React from 'react';

function ModuleCreateDetailsIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#2371F1" />
      <path
        d="M17.5625 26.3751H17.8925C17.9301 26.371 17.9658 26.3561 17.9951 26.3321C18.0244 26.3082 18.0461 26.2762 18.0575 26.2401C18.0776 26.1988 18.0819 26.1515 18.0694 26.1073C18.057 26.063 18.0287 26.0249 17.99 26.0001C17.0585 25.3015 16.3025 24.3956 15.7818 23.3542C15.2611 22.3128 14.99 21.1644 14.99 20.0001C14.99 18.8358 15.2611 17.6874 15.7818 16.646C16.3025 15.6046 17.0585 14.6987 17.99 14.0001C18.0205 13.9772 18.0433 13.9456 18.0554 13.9094C18.0674 13.8732 18.0682 13.8342 18.0575 13.7976C18.0461 13.7615 18.0244 13.7295 17.9951 13.7056C17.9658 13.6816 17.9301 13.6667 17.8925 13.6626H17.5625C15.8717 13.6626 14.2502 14.3342 13.0547 15.5298C11.8591 16.7253 11.1875 18.3468 11.1875 20.0376C11.1875 21.7284 11.8591 23.3499 13.0547 24.5454C14.2502 25.7409 15.8717 26.4126 17.5625 26.4126V26.3751Z"
        fill="white"
      />
      <path
        d="M22.4375 13.625C21.1766 13.625 19.9441 13.9989 18.8957 14.6994C17.8474 15.3999 17.0303 16.3955 16.5478 17.5604C16.0653 18.7253 15.939 20.0071 16.185 21.2437C16.431 22.4803 17.0381 23.6162 17.9297 24.5078C18.8213 25.3994 19.9572 26.0065 21.1938 26.2525C22.4304 26.4985 23.7122 26.3722 24.8771 25.8897C26.042 25.4072 27.0376 24.5901 27.7381 23.5418C28.4386 22.4934 28.8125 21.2609 28.8125 20C28.8105 18.3099 28.1382 16.6895 26.9431 15.4944C25.748 14.2993 24.1276 13.627 22.4375 13.625ZM26.1875 20C26.1875 20.0985 26.1681 20.196 26.1304 20.287C26.0927 20.378 26.0375 20.4607 25.9678 20.5303C25.8982 20.6 25.8155 20.6552 25.7245 20.6929C25.6335 20.7306 25.536 20.75 25.4375 20.75H23.375C23.3253 20.75 23.2776 20.7698 23.2424 20.8049C23.2073 20.8401 23.1875 20.8878 23.1875 20.9375V23C23.1875 23.1989 23.1085 23.3897 22.9678 23.5303C22.8272 23.671 22.6364 23.75 22.4375 23.75C22.2386 23.75 22.0478 23.671 21.9072 23.5303C21.7665 23.3897 21.6875 23.1989 21.6875 23V20.9375C21.6875 20.9129 21.6827 20.8885 21.6732 20.8657C21.6638 20.843 21.65 20.8223 21.6326 20.8049C21.6152 20.7875 21.5945 20.7737 21.5718 20.7643C21.549 20.7549 21.5246 20.75 21.5 20.75H19.4375C19.2386 20.75 19.0478 20.671 18.9072 20.5303C18.7665 20.3897 18.6875 20.1989 18.6875 20C18.6875 19.8011 18.7665 19.6103 18.9072 19.4697C19.0478 19.329 19.2386 19.25 19.4375 19.25H21.5C21.5497 19.25 21.5974 19.2302 21.6326 19.1951C21.6677 19.1599 21.6875 19.1122 21.6875 19.0625V17C21.6875 16.8011 21.7665 16.6103 21.9072 16.4697C22.0478 16.329 22.2386 16.25 22.4375 16.25C22.6364 16.25 22.8272 16.329 22.9678 16.4697C23.1085 16.6103 23.1875 16.8011 23.1875 17V19.0625C23.1875 19.1122 23.2073 19.1599 23.2424 19.1951C23.2776 19.2302 23.3253 19.25 23.375 19.25H25.4375C25.6364 19.25 25.8272 19.329 25.9678 19.4697C26.1085 19.6103 26.1875 19.8011 26.1875 20Z"
        fill="white"
      />
    </svg>
  );
}

export default ModuleCreateDetailsIcon;
