import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { TExperienceActionType } from 'store/templated-experience/templated-experience.type';

export function fetchPushTypesThunk() {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any, getState: any) => {
    const { templatedExperience } = getState();

    if (templatedExperience.pushTypes.length) {
      return templatedExperience.pushTypes;
    }

    try {
      const { data: pushTypes } = await templatedExperienceAPI.getPushTypes();
      // in reality the fetchTemplates action should be moved here, but it might be
      // throw away code anyway after we clean up the old canvas.
      dispatch({
        type: TExperienceActionType.SET_PUSH_TYPES,
        payload: pushTypes,
      });
      return pushTypes;
    } catch (e) {
      return;
    }
  };
}
