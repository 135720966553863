import { Zone } from 'pages/Zones/types';

import { MODULE_LAYOUTS } from 'components/Zones/v2/types';

const HTML_ERROR_TITLE = 'HTML Input Error';

// Internal helpers
const basicStringValidator = (field?: string, fieldName?: string) => {
  if (!field) return `Enter ${fieldName ?? 'value'}`;
  if (field.length > 40) return `Enter a ${fieldName ?? 'value'} that is 40 characters or less`;

  return '';
};

const basicNumberValidator = (field?: number, fieldName?: string) => {
  if (!field) return `Enter ${fieldName ?? 'value'}`;
  return '';
};

const specialCharacterValidator = (field?: string, fieldName?: string) => {
  if (!field || !/[\w-]+/.test(field))
    return `Enter a unique ${
      fieldName ?? 'value'
    } that only contains letters, numbers, dashes, and underscores. Avoid using any special characters.`;
  return '';
};

const emptyArrayValidator = <T>(arr?: T[], fieldName?: string) => {
  if (!arr?.length) return `Add at least one ${fieldName ?? 'value'}`;
  return '';
};

const refIdUniquenessValidator = (id?: string, refId?: string, zones?: Zone[]) => {
  if (refId && zones) {
    if (zones.some((zone) => zone.id !== id && zone.referenceID === refId)) {
      return 'Reference identifier is not unique';
    } else {
      return '';
    }
  }

  return '';
};

// Exports
export const validateZoneAttributes = (
  name?: string,
  refId?: string,
  labels?: string[],
  id?: string,
  zones?: Zone[],
) => {
  return {
    name: basicStringValidator(name, 'name'),
    refId:
      basicStringValidator(refId, 'reference identifier') ||
      specialCharacterValidator(refId, 'reference identifier') ||
      refIdUniquenessValidator(id, refId, zones),
    labels: emptyArrayValidator<string>(labels, 'label'),
  };
};

// TODO: add `layout` field to this validator
export const validateModuleInfo = (layout: MODULE_LAYOUTS, name?: string, height?: number) => {
  return {
    name: basicStringValidator(name, 'name'),
    height: basicNumberValidator(height, 'height'),
    layout: !layout ? 'Select a layout' : '',
  };
};

export const validateModuleLayout = async (layoutHTML?: string) => {
  return {
    layoutHTML: await htmlValidator(layoutHTML),
  };
};

// ToDo: Replace with actual validation
export function htmlValidator(code?: string) {
  if (!code) {
    return `${HTML_ERROR_TITLE}: the HTML box is empty`;
  }

  return '';
}

// Object comparison
export interface ObjectComparison {
  added: any;
  updated: {
    [propName: string]: any;
  };
  removed: any;
  unchanged: any;
}
/**
 * @return if obj is an Object, including an Array.
 */
export const isObject = (obj: any) => {
  return obj !== null && typeof obj === 'object';
};

export const diffObject = (o1: any, o2: any, deep = false): ObjectComparison => {
  const added: any = {};
  const updated: any = {};
  const removed: any = {};
  const unchanged: any = {};
  for (const prop in o1) {
    if (o1.hasOwnProperty(prop)) {
      const o2PropValue = o2[prop];
      const o1PropValue = o1[prop];
      if (o2.hasOwnProperty(prop)) {
        if (o2PropValue === o1PropValue) {
          unchanged[prop] = o1PropValue;
        } else {
          updated[prop] =
            deep && isObject(o1PropValue) && isObject(o2PropValue)
              ? diffObject(o1PropValue, o2PropValue, deep)
              : { newValue: o2PropValue };
        }
      } else {
        removed[prop] = o1PropValue;
      }
    }
  }
  for (const prop in o2) {
    if (o2.hasOwnProperty(prop)) {
      const o1PropValue = o1[prop];
      const o2PropValue = o2[prop];
      if (o1.hasOwnProperty(prop)) {
        if (o1PropValue !== o2PropValue) {
          if (!deep || !isObject(o1PropValue)) {
            updated[prop].oldValue = o1PropValue;
          }
        }
      } else {
        added[prop] = o2PropValue;
      }
    }
  }
  return { added, updated, removed, unchanged };
};
