import { PluginActionType, PluginState } from 'store/plugin/plugin.type';

const initialState: PluginState = {
  plugins: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: PluginState = initialState, action: any) => {
  switch (action.type) {
    case PluginActionType.SET_PLUGINS:
      return {
        ...state,
        plugins: action.payload.plugins,
      };
    default:
      return {
        ...state,
      };
  }
};
