import React from 'react';
import { IconProps } from '../../../pages/ZonesV2/Icons/icon.types';

function IconDiscardChanges(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="114"
      height="115"
      viewBox="0 0 114 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="114" height="115" fill="#E5E5E5" />
      <g id="svg-remove-zm-confirm-modal-element-1">
        <path
          d="M-14282 -3737C-14282 -3738.1 -14281.1 -3739 -14280 -3739H7055C7056.11 -3739 7057 -3738.1 7057 -3737V5469C7057 5470.1 7056.11 5471 7055 5471H-14280C-14281.1 5471 -14282 5470.1 -14282 5469V-3737Z"
          fill="#25314C"
        />
        <path
          d="M-14280 -3738H7055V-3740H-14280V-3738ZM7056 -3737V5469H7058V-3737H7056ZM7055 5470H-14280V5472H7055V5470ZM-14281 5469V-3737H-14283V5469H-14281ZM-14280 5470C-14280.6 5470 -14281 5469.55 -14281 5469H-14283C-14283 5470.66 -14281.7 5472 -14280 5472V5470ZM7056 5469C7056 5469.55 7055.55 5470 7055 5470V5472C7056.66 5472 7058 5470.66 7058 5469H7056ZM7055 -3738C7055.55 -3738 7056 -3737.55 7056 -3737H7058C7058 -3738.66 7056.66 -3740 7055 -3740V-3738ZM-14280 -3740C-14281.7 -3740 -14283 -3738.66 -14283 -3737H-14281C-14281 -3737.55 -14280.6 -3738 -14280 -3738V-3740Z"
          fill="black"
          fillOpacity="0.1"
        />
        <g id="svg-remove-zm-confirm-modal-element-2" clipPath="url(#clip0_2289_31784)">
          <rect width="1440" height="1024" transform="translate(-663 -379)" fill="white" />
          <g id="Frame 427320678">
            <g id="Module">
              <g clipPath="url(#clip1_2289_31784)">
                <rect x="-631" y="-211" width="1376" height="693" rx="4" fill="#F2F3F5" />
                <g id="Frame 427320532">
                  <rect width="1376" height="625" transform="translate(-631 -143)" fill="white" />
                  <g id="Frame 427320448">
                    <g id="Frame 427320572">
                      <g id="Frame 427320571">
                        <g id="Frame 427320565">
                          <g id="Frame 427320496">
                            <g id="Frame 427320495">
                              <g id="Frame 427320577">
                                <g id="Pinned Content">
                                  <rect x="-586.5" y="-2.5" width="1315" height="44" rx="3.5" fill="#F2F3F5" />
                                  <rect x="-586.5" y="-2.5" width="1315" height="44" rx="3.5" stroke="#B4B8C3" />
                                  <g id="Frame 427320494"></g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Frame 427320567">
                    <mask id="path-8-inside-1_2289_31784" fill="white">
                      <path d="M-615 112H729V374H-615V112Z" />
                    </mask>
                    <path
                      d="M-615 113H729V111H-615V113Z"
                      fill="#CCCCCC"
                      fillOpacity="0.8"
                      mask="url(#path-8-inside-1_2289_31784)"
                    />
                  </g>
                </g>
              </g>
              <rect x="-630" y="-210" width="1374" height="691" rx="3" stroke="#B4B8C3" strokeWidth="2" />
            </g>
          </g>
          <g id="Frame 427320576">
            <rect width="1440" height="1024" transform="translate(-663 -379)" fill="black" fillOpacity="0.25" />
            <g id="svg-remove-zm-confirm-modal-element-3" filter="url(#filter0_d_2289_31784)">
              <rect x="-81" y="-40.5" width="276" height="347" rx="4" fill="white" />
              <g id="Frame 427319603">
                <g id="Mask Group 5">
                  <path
                    id="Ellipse 653"
                    d="M57 114.5C88.4803 114.5 114 88.9803 114 57.5C114 26.0198 88.4803 0.5 57 0.5C25.5198 0.5 0 26.0198 0 57.5C0 88.9803 25.5198 114.5 57 114.5Z"
                    fill="#E6E7EB"
                  />
                  <path
                    id="Subtraction 1"
                    d="M91.1998 114.5H22.7998V40.7801C26.0237 40.7765 29.1146 39.4942 31.3942 37.2146C33.6739 34.9349 34.9562 31.844 34.9598 28.6201H79.0398C79.0364 30.2172 79.3497 31.799 79.9617 33.2742C80.5737 34.7494 81.4721 36.0885 82.605 37.2142C83.7306 38.3474 85.0698 39.2461 86.5452 39.8582C88.0205 40.4703 89.6026 40.7837 91.1998 40.7801V114.5Z"
                    fill="white"
                  />
                  <path
                    id="Ellipse 665"
                    d="M57.0007 78.0196C67.0744 78.0196 75.2407 69.8532 75.2407 59.7796C75.2407 49.7059 67.0744 41.5396 57.0007 41.5396C46.9271 41.5396 38.7607 49.7059 38.7607 59.7796C38.7607 69.8532 46.9271 78.0196 57.0007 78.0196Z"
                    fill="#82899B"
                  />
                  <g id="ic_add_48px">
                    <path
                      id="Path 25936"
                      d="M63.4488 68.3781L57 61.9293L50.5512 68.3781L48.4016 66.2285L54.8504 59.7797L48.4016 53.3308L50.5512 51.1812L57 57.6301L63.4488 51.1812L65.5984 53.3308L59.1496 59.7797L65.5984 66.2285L63.4488 68.3781Z"
                      fill="white"
                    />
                  </g>
                  <path
                    id="Rectangle 5617"
                    d="M66.8799 82.5796H47.1198C45.8606 82.5796 44.8398 83.6004 44.8398 84.8596C44.8398 86.1188 45.8606 87.1396 47.1198 87.1396H66.8799C68.1391 87.1396 69.1599 86.1188 69.1599 84.8596C69.1599 83.6004 68.1391 82.5796 66.8799 82.5796Z"
                    fill="#CDD0D7"
                  />
                  <path
                    id="Rectangle 5618"
                    d="M73.72 91.6997H40.28C39.0208 91.6997 38 92.7205 38 93.9797C38 95.2389 39.0208 96.2597 40.28 96.2597H73.72C74.9792 96.2597 76 95.2389 76 93.9797C76 92.7205 74.9792 91.6997 73.72 91.6997Z"
                    fill="#CDD0D7"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2289_31784"
          x="-84"
          y="-42.5"
          width="282"
          height="353"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2289_31784" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2289_31784" result="shape" />
        </filter>
        <clipPath id="clip0_2289_31784">
          <rect width="1440" height="1024" fill="white" transform="translate(-663 -379)" />
        </clipPath>
        <clipPath id="clip1_2289_31784">
          <rect x="-631" y="-211" width="1376" height="693" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconDiscardChanges;
