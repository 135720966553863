import React, { useMemo } from 'react';
import { getUserApprovalPermissions } from 'helpers/common.helper';

import { FBComment } from 'interface/approval/approval.interface';
import { User } from 'interface/auth/auth.interface';
import { DropdownOptions } from 'interface/shared/dropdown.interface';
import { ApprovalRequest } from 'interface/approval/approval.interface';

import AddComment from 'components/ExperienceCanvas/Comments/AddComment/AddComment';
import { FlightOverflowMenu, FlightButton, FlightAvatar } from '@flybits/design-system';

import './Comment.scss';

type TCommentProps = {
  comment: FBComment;
  creators: ApprovalRequest['creators'];
  approvalRequirements: ApprovalRequest['approvalRequirements'];
  addComment: (comment: string, mustResolve: boolean, parentId?: string) => void;
  user?: User;
  isReviewer: boolean;
  updateComment: (comment: FBComment, resolved: boolean) => void;
  deleteComment: (comment: FBComment) => void;
};

const MAIN_CLASS = 'comment';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  HEADER_BUTTON: `${MAIN_CLASS}__header__button`,
  ACTIONS: `${MAIN_CLASS}__actions`,
  OVERFLOW: `${MAIN_CLASS}__actions__overflow`,
  METADATA: `${MAIN_CLASS}__metadata`,
  METADATA_TEXT: `${MAIN_CLASS}__metadata__text`,
  METADATA_SUBTEXT: `${MAIN_CLASS}__metadata__subtext`,
  METADATA_BADGE: `${MAIN_CLASS}__metadata__badge`,
  COMMENT: `${MAIN_CLASS}__comment`,
};

const getTimePassedString = (time: number) => {
  const timeDiff = ((Date.now() / 1000) | 0) - time;
  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (timeDiff > year) return 'over a year ago';
  if (timeDiff > month) return `${(timeDiff / month) | 0} month(s) ago`;
  if (timeDiff > week) return `${(timeDiff / week) | 0} week(s) ago`;
  if (timeDiff > day) return `${(timeDiff / day) | 0} day(s) ago`;
  if (timeDiff > hour) return `${(timeDiff / hour) | 0} hour(s) ago`;
  if (timeDiff > minute) return `${(timeDiff / minute) | 0} minutes(s) ago`;

  return 'just now';
};

/*
  TODO: Very awkward, but without adjusting flight-overflow-menu to advertise
  when it opens and closes the dropdown or using some ref shenanigans, I don't
  think there's an easy way to make an accessible actions menu that only opens
  when this component (or any of its children) has focus.
*/
const Comment: React.FC<TCommentProps> = ({
  comment,
  creators,
  approvalRequirements,
  addComment,
  user,
  isReviewer,
  updateComment,
  deleteComment,
}) => {
  const isPoster = comment.userId === user?.id;
  const { isAuthor: fromAuthor, isReviewer: fromReviewer } = getUserApprovalPermissions(
    comment.userId ?? '',
    creators,
    approvalRequirements,
  );
  const commentOptionGroups = useMemo(() => {
    const options: DropdownOptions[] = [];

    if (isPoster && comment.mustResolve && !comment.resolved)
      options.push({
        key: 1,
        name: 'Mark as resolved',
        disabled: false,
        onClick: () => updateComment(comment, true),
      });

    if (isPoster && comment.mustResolve && comment.resolved)
      options.push({
        key: 2,
        name: 'Mark as unresolved',
        disabled: false,
        onClick: () => updateComment(comment, false),
      });

    if (isPoster)
      options.push({
        key: 3,
        name: 'Delete comment',
        disabled: false,
        onClick: () => deleteComment(comment),
      });

    return options.length ? [options] : [];
  }, [isPoster, comment, updateComment, deleteComment]);
  const badge = fromAuthor ? 'Creator' : fromReviewer ? 'Reviewer' : '';

  return (
    <div
      className={`${MAIN_CLASS} ${comment.mustResolve && !comment.resolved ? `${MAIN_CLASS}--must-resolve` : ''}`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <div className={CLASSES.ACTIONS}>
        <FlightButton label="&#x263A;" theme="minor" onClick={() => null} />
        <FlightOverflowMenu
          className={CLASSES.OVERFLOW}
          ariaLabel="more comment options"
          optionGroups={commentOptionGroups}
        />
      </div>
      {comment.mustResolve && !comment.resolved && (
        <div className={`${CLASSES.HEADER}`}>
          <FlightButton
            className={CLASSES.HEADER_BUTTON}
            onClick={() => null}
            iconLeft="clipboardCheck"
            size="small"
            label="Requested change"
          />
          {isPoster && (
            <FlightButton
              className={CLASSES.HEADER_BUTTON}
              iconLeft="baselineCheck"
              theme="secondary"
              onClick={() => updateComment(comment, true)}
              size="small"
              label="Mark as resolved"
            />
          )}
        </div>
      )}
      {comment.mustResolve && comment.resolved && (
        <div className={`${CLASSES.HEADER}`}>
          <FlightButton
            className={`${CLASSES.HEADER_BUTTON} blue`}
            onClick={() => null}
            iconLeft="baselineCheck"
            size="small"
            label="Resolved"
          />
        </div>
      )}
      <div className={CLASSES.METADATA}>
        <FlightAvatar firstName={comment.name} />
        <span className={CLASSES.METADATA_TEXT}>{comment.name}</span>
        <span className={CLASSES.METADATA_SUBTEXT}>{getTimePassedString(comment.updatedAt)}</span>
        {!!badge && <span className={CLASSES.METADATA_BADGE}>{badge}</span>}
      </div>
      <p className={CLASSES.COMMENT}>{comment.comment}</p>
      <AddComment
        addComment={addComment}
        user={user}
        isReviewer={isReviewer}
        parentId={comment.commentId}
        placeholder="Type a reply"
        size="small"
      />
    </div>
  );
};

export default Comment;
