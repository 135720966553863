import { PaginationResponse } from 'interface/shared/api.interface';
import { Experience } from 'interface/experience/experience.interface';

export const TExperienceActionType = {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_ERRORS: 'SET_ERRORS',
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_INSTANCES: 'SET_INSTANCES',
  SET_INSTANCE: 'SET_INSTANCE',
  UPDATE_INSTANCES: 'UPDATE_INSTANCES',
  SET_PUSH_TYPES: 'SET_PUSH_TYPES',
};

export interface TExperienceState {
  instances: PaginationResponse<Experience> | null;
  instance: Experience | null;
  template: Experience | null;
  isLoading: boolean;
  errorMessage: string;
  errors: Array<string>;
  pushTypes: Array<object>;
}

// legacy delivery payload for the push reminder form
export type Delivery = {
  trigger: string;
  frequencyTitle: string;
  frequencyDescr: string;
  frequencyString: Array<string>;
  frequency: {
    total: string;
    value: string;
    period: {
      key: string;
      name: string;
    };
  };
  limitString: Array<string>;
  limit: {
    key: string;
    name: string;
  };
  remain: string;
  delayString: Array<string>;
  delay: {
    selected: string;
    value: string;
    period: {
      key: string;
      name: string;
    };
  };
};

export interface SetTemplateAction {
  type: typeof TExperienceActionType.SET_TEMPLATE;
  payload: {
    template: Experience | null;
    preserveInstance?: boolean;
  };
}

export interface SetInstanceAction {
  type: typeof TExperienceActionType.SET_INSTANCE;
  payload: {
    instance: Experience | null;
    updateNewReducer?: boolean;
  };
}

export interface SetInstancesAction {
  type: typeof TExperienceActionType.SET_INSTANCES;
  payload: {
    instances: PaginationResponse<Experience>;
  };
}

export interface SetLoadingAction {
  type: typeof TExperienceActionType.SET_LOADING;
  payload: {
    flag: boolean;
  };
}

export interface SetErrorAction {
  type: typeof TExperienceActionType.SET_ERRORS;
  payload: {
    errorMessage: string;
  };
}

export interface SetErrorsAction {
  type: typeof TExperienceActionType.SET_ERRORS;
  payload: {
    errors: Array<string>;
  };
}

export interface UpdateInstancesAction {
  type: typeof TExperienceActionType.UPDATE_INSTANCES;
  payload: {
    id: string;
    status: string;
  };
}
