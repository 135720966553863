// hook for pulling context plugins

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPluginsThunk } from 'store/plugin/plugin.thunk';

export default function usePlugins() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const plugins = useSelector((state: any) => state.plugin.plugins);
  const dispatch = useDispatch();
  // TODO: when we add proper pagination support, real loading states are needed
  // right now too many requests come in before plugins have updated in store
  const isInitialized = plugins;

  useEffect(() => {
    if (isInitialized) return;
    dispatch(fetchPluginsThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    plugins,
    isInitialized,
  };
}
