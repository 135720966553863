import React from 'react';
import { TIconProps } from '../types';

function PushNotificationIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1C5.895 1 5 1.895 5 3V21C5 22.105 5.895 23 7 23H17C18.105 23 19 22.105 19 21V18H17V20H7V4H17V6H19V3C19 1.895 18.105 1 17 1H7ZM15 8C13.9 8 13 8.9 13 10V18L15 16H21C22.1 16 23 15.1 23 14V10C23 8.9 22.1 8 21 8H15Z"
        fill={fill}
      />
    </svg>
  );
}

export default PushNotificationIcon;
