import { convertPeriodToSeconds } from 'helpers/templated-experience.helper';

type CtxParam = {
  name: string;
  valueOptions?: {
    key: string;
    name: string;
  }[];
};

export type TimeContextAttribute = {
  key: string;
  name: string;
  ctxParams: CtxParam[];
};

/**
 * Filters for plugin attributes with type dateTime.
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTimeCtxPluginAttributes = (plugins: any) => {
  const _timeCtxAttrs: TimeContextAttribute[] = [];

  if (plugins?.length) {
    for (const plugin of plugins) {
      for (const attr in plugin.values) {
        if (plugin.values[attr]?.isTimeContext) {
          const key = plugin.values[attr].uid;
          const ctxParams: CtxParam[] = [];
          if (plugin.values[attr].parameters?.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            plugin.values[attr].parameters.forEach((param: any) =>
              ctxParams.push({
                name: param.key,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                valueOptions: param.valueTypeOptions?.map((opt: any) => {
                  return { key: opt.value, name: opt.displayName || opt.value };
                }),
              }),
            );
          }

          _timeCtxAttrs.push({ key, name: `${plugin.name} / ${plugin.values[attr].name}`, ctxParams });
        }
      }
    }
  }

  return _timeCtxAttrs;
};

/**
 * Prepare payload for updating after/before preconditions.
 * */
export const genBeforeAfterPayload = (
  stepIdx: number,
  type: 'start' | 'end',
  value: number,
  period: string,
  attribute: string,
  params: string[],
  preconditionKey?: string,
) => {
  const splitModifier = attribute.split('.');
  const sanitizedParams = params.map((param) => param || '*');
  const operator =
    preconditionKey === 'before-unique-event' ? ' - ' : preconditionKey === 'after-unique-event' ? ' + ' : '';
  const timeValue = convertPeriodToSeconds(value, period);
  const modifier = splitModifier.concat(sanitizedParams).join('.') + operator + timeValue;

  return {
    stepIdx,
    type,
    modifier,
    target: 'context',
    // logic and predicates are necessary, so they're just set to be true for
    // any time value that comes in for the specified plugin/attribute
    logic: '1',
    predicates: {
      '1': {
        name: 'greq',
        parameters: [
          {
            type: 'context',
            plugin: splitModifier.slice(0, 3).join('.'),
            attribute: splitModifier.slice(3).concat(sanitizedParams).join('.'),
          },
          {
            type: 'constant',
            value: '0',
            dataType: 'int',
          },
        ],
      },
    },
  };
};
