import React from 'react';
import { TShapeProps } from '../types';

function Triangle({ fill = 'currentColor', className = '' }: TShapeProps) {
  return (
    <svg className={className} fill="none" width="8" height="10" viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill={fill} />
    </svg>
  );
}

export default Triangle;
