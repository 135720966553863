/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';
import Storage from 'services/storage';
import moment from 'moment';
import { size } from 'lodash';
import { FlightButton, FlightTable, FlightModal, FlightSearch } from '@flybits/design-system';
import './UploadLocation.scss';
import LocationAPI from 'services/api/location.api';
import { getTenantId } from 'helpers/templated-experience.helper';
import DragDropArea from 'assets/images/drag_and_drop_area.png';

type TableData = {
  key: string;
  name: string;
  size: string;
  upload: string;
  status: JSX.Element | string;
  delete: JSX.Element;
};
const tableHeaders = [
  {
    name: '',
    key: 'key',
    isVisible: false,
    hideTooltip: true,
  },
  {
    name: 'File Name',
    key: 'name',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Size',
    key: 'size',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Uploaded on',
    key: 'upload',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Status',
    key: 'status',
    isVisible: true,
    hideTooltip: true,
  },
  {
    name: 'Action',
    key: 'delete',
    isVisible: true,
    hideTooltip: true,
  },
];
export default function Upload() {
  const locationsAPI = useMemo(() => new LocationAPI(), []);
  const storage = useMemo(() => new Storage(), []);

  const inputRef = useRef<any>(null);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectDeletedID, setSelectDeletedID] = useState<string>('');
  const [isProcessingFile, setIsProcessingFile] = useState<boolean>(false);
  const [dragActive, setDragActive] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    sortBy: 'desc',
    sortOrder: 'createdAt',
    search: '',
  });
  const handleUploadLocationButton = () => {
    inputRef.current.value = null;
    inputRef.current.click();
  };
  const getAllLocationFiles = () => {
    locationsAPI.getLocationFiles({ ...pagination }).then((res: any) => {
      const file = [];
      if (res?.data?.length > 0) {
        for (let count = 0; count < res.data.length; count++) {
          file.push({
            key: res.data[count].id,
            name: res.data[count].friendlyName,
            size: getSizeValue(Number(res.data[count].size) || 0),
            upload: getDateValue(res.data[count].modifiedAt),
            status: getStatusValue(res.data[count]),
            delete: handleDeleteConfirmFile(res.data[count]),
          });
        }
      }
      setTableData(file);
      setMaxPage(Math.ceil(res.pagination.totalRecords / res.pagination.limit));
      const processingFile = file.filter((attr: any) => attr?.status === 'PROCESSING_STARTED');
      if (processingFile?.length > 0 || isProcessingFile) {
        setTimeout(() => {
          setIsProcessingFile(false);
          getAllLocationFiles();
        }, 3000);
      }
    });
  };
  const getSizeValue = (value: number) => {
    if (value < 1000) {
      return value?.toString().concat(' B');
    } else if (value < 1000000) {
      value = value / 1000;
      return value?.toString().concat(' KB');
    } else {
      value = value / 1000000;
      return value?.toString().concat(' MB');
    }
  };
  const getDateValue = (timestamp: number) => {
    const date = moment?.unix(timestamp);
    return date?.utc()?.format('MMMM Do YYYY, h:mm:ss a');
  };
  const handleUploadLocation = async (fileObj: any) => {
    const tenantId = getTenantId();
    const token = await storage.getItem(`${tenantId}+token`);
    const fm = new FormData();
    fm.append('data', fileObj);
    fm.append('isPrivate', 'true');
    fm.append('tags', JSON.stringify(['location']));

    try {
      const origin = process.env.REACT_APP_API_URL;
      const url = `${origin}/kernel/file-manager/files/upload`;
      await axios({
        method: 'post',
        url: url,
        data: fm,
        headers: { 'X-Authorization': token },
      }).catch(function () {
        // TODO: Handle exception
      });
    } catch (err) {
      // TODO: Handle exception
    } finally {
      getAllLocationFiles();
    }
  };
  const handleProcessLocationButton = async (file: any) => {
    try {
      const origin = process.env.REACT_APP_API_URL;
      const url = `${origin}/context/location/files/${file.id}`;
      const tenantId = getTenantId();
      const token = await storage.getItem(`${tenantId}+token`);
      const fm = new FormData();
      fm.append('fileId', file.id);
      setIsProcessingFile(true);
      await axios({
        method: 'post',
        url: url,
        data: fm,
        headers: { 'X-Authorization': token },
      });
    } catch (exception) {
      getAllLocationFiles();
    } finally {
      getAllLocationFiles();
    }
  };

  const getCurrentPageNumber = () => {
    const totalPages = Math.ceil(size(tableData) / pagination.limit);
    return totalPages + 1 - Math.ceil((size(tableData) - pagination.offset) / pagination.limit);
  };
  const handleOnPageChange = (page: number) => {
    const nextOffset = page - 1;
    setPagination({
      ...pagination,
      offset: pagination.limit * nextOffset,
    });
  };
  const handleOnRowPerPageChange = (amount: number) => {
    setPagination({
      ...pagination,
      limit: amount,
      offset: 0,
    });
  };
  const onCancel = () => {
    setIsVisible(false);
  };
  const getStatusValue = (data: any) => {
    if (data.status) {
      return data.status;
    } else {
      return (
        <div className="upload-location-process-button">
          <FlightButton
            className="upload-location-process-button"
            type="button"
            theme="primary"
            label="Process now"
            onClick={() => {
              handleProcessLocationButton(data);
            }}
          />
        </div>
      );
    }
  };
  const handleDeleteConfirmFile = (data: any) => {
    return (
      <div className="upload-location-delete-button">
        <FlightButton
          className="upload-location-delete-button"
          type="button"
          theme="secondary"
          ariaLabel="Delete this location file"
          onClick={() => {
            handleDeleteFile(data);
          }}
          iconLeft="trashCan"
        />
      </div>
    );
  };
  const handleDeleteFile = (data: any) => {
    setSelectDeletedID(data.id);
    setIsVisible(true);
  };
  const onConfirm = async () => {
    setIsVisible(false);
    try {
      const origin = process.env.REACT_APP_API_URL;
      const url = `${origin}/context/location/files/${selectDeletedID}`;
      const tenantId = getTenantId();
      const token = await storage.getItem(`${tenantId}+token`);
      const fm = new FormData();
      fm.append('fileId', selectDeletedID);
      await axios({
        method: 'delete',
        url: url,
        data: fm,
        headers: { 'X-Authorization': token },
      });
    } catch (exception) {
      getAllLocationFiles();
    } finally {
      getAllLocationFiles();
    }
  };

  useEffect(() => {
    getAllLocationFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleOnSearch = (value: string) => {
    setPagination({
      ...pagination,
      search: value,
    });
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleUploadLocation(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }
    e.target.value = null;
    handleUploadLocation(fileObj);
  };

  return (
    <div className="upload-location">
      <div
        className={`upload-location__drop-area-content ${dragActive ? 'drag-active' : ''}`}
        id="form-file-upload"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <img alt="logo" src={DragDropArea} />
        <input
          className="upload-location__drop-area-content__input-field"
          ref={inputRef}
          type="file"
          id="input-file-upload"
          accept=".csv"
          multiple={false}
          onChange={handleChange}
        />
        <div className="upload-location__drop-area-content__text">
          <p>
            Drag and drop your files here or
            <FlightButton label="Browse" size="large" onClick={handleUploadLocationButton} theme="link" />
          </p>
          <div className="upload-location__text-small">Importing requires .csv format only</div>
        </div>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
      <div className="upload-location__filter">
        <FlightSearch className="upload-location__filter__select" onSearch={handleOnSearch} label="Search for a file" />
      </div>
      <FlightTable
        className="upload-location__files__table"
        tableHeaders={tableHeaders}
        tableData={tableData}
        emptyState={<span>No locations found.</span>}
        hasPaginationAfterTable
        hasPaginationBeforeTable={false}
        paginationProps={{
          totalPageNumber: maxPage,
          currentPageNumber: getCurrentPageNumber(),
          rowsPerPageOptions: [10, 20, 30, 40, 50],
          currentRowsPerPage: Number(pagination.limit),
          handlePageChange: handleOnPageChange,
          handleRowsPerPageChange: handleOnRowPerPageChange,
        }}
      />
      <FlightModal
        className="confirm-modal"
        size="medium"
        isVisible={isVisible}
        scrollable={true}
        toggleModalShown={onCancel}
        header={
          <div className="confirm-modal__header">
            <p>Confirm delete</p>
          </div>
        }
        content={
          <div className="confirm-modal__content">
            <p>Do you wish to delete?</p>
          </div>
        }
        footer={
          <div className="confirm-modal__footer">
            <FlightButton theme="secondary" label="Cancel" onClick={onCancel} />
            <FlightButton label="Confirm" onClick={onConfirm} />
          </div>
        }
      />
    </div>
  );
}
