/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseAPI from './base.api';
import {
  ModuleConfigSingle,
  ModuleConfigs,
  ModuleConfig,
  TEASingleValue,
  TEATimeline,
} from 'interface/templated-experience/analytics.interface';

export default class TemplatedExperienceAnalyticsAPI extends BaseAPI {
  private ROUTES = {
    // Single value
    totalUsers: '/analytics/analytics/users/total',
    targetAudience: '/analytics/analytics/rules/:ruleID/targetAudience/count',
    openedPush: '/analytics/analytics/pushes/:pushID/openedPush/count',
    sentPush: '/analytics/analytics/pushes/:pushID/sentPush/count',
    deliveredPush: '/analytics/analytics/pushes/:pushID/deliveredPush/count',

    // Timeline
    totalUsersTimeline: '/analytics/analytics/users/timeline/total',
    targetAudienceTimeline: '/analytics/analytics/rules/:ruleID/targetAudience/timeline',
    openedPushTimeline: '/analytics/analytics/pushes/:pushID/openedPush/timeline',
    contentViewsTimeline: '/analytics/analytics/contents/:contentID/uniqueViewed/timeline',
    contentClicksTimeline: '/analytics/analytics/contents/:contentID/uniqueEngaged/timeline',
    deliveredPushTimeline: '/analytics/analytics/pushes/:pushID/deliveredPush/timeline',

    // Content Viewed
    uniqueViewedCount: '/analytics/analytics/contents/:contentID/uniqueViewed/count',
    totalViewedCount: '/analytics/analytics/contents/:contentID/totalViewed/count',

    // Content Clicked
    uniqueClickedCount: '/analytics/analytics/contents/:contentID/uniqueClicked/count',
    totalClickedCount: '/analytics/analytics/contents/:contentID/totalClicked/count',

    // Get/Create custom modules configs
    createModuleConfig: '/analytics/analytics/moduleconfig',

    // Export engagement
    analyticsExport: '/analytics/analytics-export/exports',

    // Get custom module data
    moduleConfigData: '/analytics/analytics/moduleconfig/:moduleID/data',
    moduleConfigInfo: '/analytics/analytics/moduleconfig/:moduleID',
  };

  getTotalUsersCount(): Promise<{ data: TEASingleValue }> {
    return this.GET(this.ROUTES.totalUsers);
  }

  getTargetAudienceCount(id: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.targetAudience, { ruleID: id }), params);
  }

  getOpenedPushCount(id: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.openedPush, { pushID: id }), params);
  }

  getSentPushCount(id: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.sentPush, { pushID: id }), params);
  }

  getDeliveredPushCount(id: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.deliveredPush, { pushID: id }), params);
  }

  getTotalUsersTimeline(params: any): Promise<TEATimeline> {
    return this.GET(this.ROUTES.totalUsersTimeline, params);
  }

  getTargetAudienceTimeline(ruleID: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.targetAudienceTimeline, { ruleID }), params);
  }

  getOpenedPushTimeline(id: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.openedPushTimeline, { pushID: id }), params);
  }

  getDeliveredPushTimeline(id: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.deliveredPushTimeline, { pushID: id }), params);
  }

  getContentViewsTimeline(contentID: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.contentViewsTimeline, { contentID }), params);
  }

  getContentClicksTimeline(contentID: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.contentClicksTimeline, { contentID }), params);
  }

  getViewedContentInAppCount(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.uniqueViewedCount, { contentID }), params);
  }

  getContentViewedCountWithoutPushDelivered(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalViewedCount, { contentID }), params);
  }

  getContentViewedCountWithoutPushOpened(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalViewedCount, { contentID }), params);
  }

  getTotalContentViewsCountFromPush(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalViewedCount, { contentID }), params);
  }

  getTotalContentViewsCountFromExperience(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalViewedCount, { contentID }), params);
  }

  getClickedOnContentInApp(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.uniqueClickedCount, { contentID }), params);
  }

  getClickedOnContentFromPush(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalClickedCount, { contentID }), params);
  }

  getContentEngagedCountWithoutPushOpened(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalClickedCount, { contentID }), params);
  }

  getTotalContentClicksCountFromPush(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalClickedCount, { contentID }), params);
  }

  getTotalContentClicksCountFromExperience(contentID: string, params: any): Promise<{ data: TEASingleValue }> {
    return this.GET(this.buildURL(this.ROUTES.totalClickedCount, { contentID }), params);
  }
  // Custom Module Configs
  getModuleConfigs(params: Record<string, never>): Promise<ModuleConfigs> {
    return this.GET(this.ROUTES.createModuleConfig, params);
  }

  getModuleConfigData(id: string): Promise<ModuleConfigSingle> {
    return this.GET(this.buildURL(this.ROUTES.moduleConfigData, { moduleID: id }));
  }

  removeModuleConfig(id: string): Promise<void> {
    return this.DELETE(this.buildURL(this.ROUTES.moduleConfigInfo, { moduleID: id }));
  }

  postCreateCustomModule(payload: any): Promise<any> {
    return this.POST(this.ROUTES.createModuleConfig, payload);
  }

  updateCustomModule(payload: Partial<ModuleConfig>, moduleID: string): Promise<{ data: ModuleConfig }> {
    return this.PUT(this.buildURL(this.ROUTES.moduleConfigInfo, { moduleID }), payload);
  }

  exportData(payload: any): Promise<any> {
    return this.POST(this.ROUTES.analyticsExport, payload);
  }

  // are not used in the codebase anymore, can be removed in the future
  // getContentEngagementRecordsByExperience(contentID: string, params: any): Promise<TEAContentEngagementAnalytics> {
  //   return this.GET(this.buildURL(this.ROUTES.uniqueViewedCount, { contentID }), params);
  // }

  // getContentEngagementRecordsByPush(contentID: string, params: any): Promise<TEAContentEngagementAnalytics> {
  //   return this.GET(this.buildURL(this.ROUTES.uniqueViewedCount, { contentID }), params);
  // }
}
