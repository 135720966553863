export const MAIN_CLASS = 'engagement';

export const CLASSES = {
  CARD: `${MAIN_CLASS}__card`,
  CARD_HEADER: `${MAIN_CLASS}__card-header`,
  DATE_RANGE: `${MAIN_CLASS}__date-range`,
  DATE_RANGE_DD: `${MAIN_CLASS}__date-range__dropdown`,
  OVERVIEW: `${MAIN_CLASS}__overview`,
  OVERVIEW_ITEM: `${MAIN_CLASS}__overview-item`,
  OVERVIEW_ITEM_TITLE: `${MAIN_CLASS}__overview-item-title`,
  OVERVIEW_ITEM_DATA: `${MAIN_CLASS}__overview-item-data`,
  SEARCH_WRAPPER: `${MAIN_CLASS}__search-wrapper`,
  TABLE_WRAPPER: `${MAIN_CLASS}__table-wrapper`,
  SUBNAME: `${MAIN_CLASS}__subname`,
  EMPTY_DATA: `${MAIN_CLASS}__empty-data`,
  SPINNER: `${MAIN_CLASS}__spinner`,
  LABEL_LIST: `${MAIN_CLASS}__label-list`,
  LABEL: `${MAIN_CLASS}__label`,
};
