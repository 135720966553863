/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState, useEffect } from 'react';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import useActions from 'hooks/useActions';
import useSetting from 'hooks/useSetting';

import { FlightButton, FlightDropdown, FlightModal, getIcon } from '@flybits/design-system';
import SlidingSidePanel from '../SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import CurrentlyOptimizing from './CurrentlyOptimizing/CurrentlyOptimizing';
import ProjectedPerformance from './ProjectedPerformance/ProjectedPerformance';
import ContextAttributeChart from './ContextAttributes/ContextAttributeChart';
import STExperienceFunnel from './STExperienceFunnel/STExperienceFunnel';
import STExperiencePerformance from './STExperiencePerformance/STExperiencePerformance';
import STContentEngagementTable from './STContentEngagement/STContentEngagementTable';

import SmartTargetingIcon from 'pages/ExperienceCanvas/assets/icons/SmartTargetingIcon';
import { ReactComponent as TargetImage } from 'assets/images/targets-bw.svg';

import { updateJourneyAO } from 'store/journey/journey.thunk';
import { JourneyState } from 'store/journey/journey.type';
import { ContentState } from 'store/content/content.type';
import { PushState } from 'store/push/push.type';
import { RuleState } from 'store/rule/rule.type';
import { AudienceOptimization } from 'interface/experience/experience.interface';
import {
  SmartTargetStatusType,
  SMART_TARGET_STATUS,
  SmartTargetingMetadata,
  SmartTargetingMetrics,
} from 'interface/smart-targeting/smart-targeting.interface';

import AOApi from 'services/api/ao.api';
import * as AnalyticsFunnelActions from 'store/analytics/experience-funnel/experience-funnel.action';
import * as AnalyticsPerformanceActions from 'store/analytics/experience-performance/experience-performance.action';
import * as AnalyticsEngagementActions from 'store/analytics/content-engagement/content-engagement.action';
import './SmartTargeting.scss';
import { JOURNEY_TEMPLATE_TYPES } from 'pages/ExperienceCanvas/types';

type TSmartTargetingProps = {
  isOpen: boolean;
  onClickClose: () => void;
  journeyInstance: JourneyState;
  status: SmartTargetStatusType;
  setStatus: (status: SmartTargetStatusType) => void;
};

type teState = {
  journey: JourneyState;
  push: PushState;
  content: ContentState;
  rule: RuleState;
};

interface GraphDisplayInfo {
  type: string | null;
  experienceID?: string | null;
  pushID?: string | null;
  experienceContentIDs?: string[] | null;
  pushContentID?: string | null;
  ruleID?: string | null;
}

const MAIN_CLASS = 'smart-targeting';

const GRAPHS_DISPLAY_TYPES = {
  IN_APP_CONTENT: 'IN_APP_CONTENT',
  IN_APP_MULTI_CONTENT: 'IN_APP_MULTI_CONTENT',
  IN_APP_AMPLIFIED_CONTENT: 'IN_APP_AMPLIFIED_CONTENT',
  IN_APP_AMPLIFIED_MULTI_CONTENT: 'IN_APP_AMPLIFIED_MULTI_CONTENT',
  PUSH_BROADCAST: 'PUSH_BROADCAST',
  PUSH_BROADCAST_CONTENT: 'PUSH_BROADCAST_CONTENT',
  TRIGGERED_PUSH: 'TRIGGERED_PUSH',
  TRIGGERED_PUSH_CONTENT: 'TRIGGERED_PUSH_CONTENT',
};

const AGGREGATE_DROPDOWN_MAP: Record<string, string> = {
  Daily: 'day',
  Weekly: 'week',
  Monthly: 'month',
};

const DEFAULT_MODEL_STATUS = {
  status: '',
  modelVersion: 0,
  updatedAt: 0,
};

const INITIAL_METADATA: SmartTargetingMetadata = {
  tenantId: '',
  journeyId: '',
  journeyName: '',
  engagementRateBasedOnRules: 0,
  populationReachBasedOnRules: 0,
  scheduleId: '',
  prodModelStatus: DEFAULT_MODEL_STATUS,
  statingModelStatus: DEFAULT_MODEL_STATUS,
  devModelStatus: DEFAULT_MODEL_STATUS,
  updatedAt: 0,
};

const INITIAL_METRICS: SmartTargetingMetrics = {
  activeModelMetrics: {
    tenantId: '',
    journeyId: '',
    optimizationGraph: null,
    featureMetrics: null,
    updatedAt: 0,
    isValidation: false,
    createdByJobName: '',
    trainingJobName: '',
    modelVersion: 0,
    modelFile: '',
    dataFile: '',
    metricsFile: '',
  },
  candidateModelMetrics: {
    tenantId: '',
    journeyId: '',
    optimizationGraph: null,
    featureMetrics: null,
    updatedAt: 0,
    isValidation: false,
    createdByJobName: '',
    trainingJobName: '',
    modelVersion: 0,
    modelFile: '',
    dataFile: '',
    metricsFile: '',
  },
};

const normalizeDate = (start: Date, end: Date): { startTime: number; endTime: number } => {
  const endDate = new Date(end);
  // set endDate to beginning of the next day (current timezone)
  endDate.setHours(24, 0, 0, 0);
  return {
    startTime: start.getTime() / 1000,
    endTime: endDate.getTime() / 1000,
  };
};

const getGraphDisplayInfo = (instance: teState): GraphDisplayInfo => {
  const graphDisplayInfo: GraphDisplayInfo = { type: null };
  const journeyStep = instance.journey.steps[0];
  if (journeyStep) {
    const journeyTypeValue = instance.journey.templateType;
    const pushState = instance.push;
    const contentState = instance.content;
    const ruleState = instance.rule;
    const experienceId = journeyStep?.experience;
    const ruleRefId = journeyStep?.rule.restricted !== '' ? journeyStep?.rule.restricted : journeyStep?.rule.preferred;
    if (ruleRefId) graphDisplayInfo.ruleID = ruleState.byRefId[ruleRefId].id || undefined;
    graphDisplayInfo.pushID = journeyStep?.push || undefined;
    if (graphDisplayInfo.pushID) graphDisplayInfo.pushContentID = pushState.byRefId[graphDisplayInfo.pushID].contentId;
    switch (journeyTypeValue) {
      case JOURNEY_TEMPLATE_TYPES.BROADCAST:
      case JOURNEY_TEMPLATE_TYPES.LIST_BROADCAST:
        if (graphDisplayInfo.pushID && graphDisplayInfo.pushContentID) {
          graphDisplayInfo.experienceContentIDs = contentState.allRefId.map(
            (refId: string) => contentState.byRefId[refId].id || '',
          );
          graphDisplayInfo.experienceID = experienceId;
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST_CONTENT;
        } else if (graphDisplayInfo.pushID) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST;
        }
        break;
      case JOURNEY_TEMPLATE_TYPES.TRIGGERED:
      case JOURNEY_TEMPLATE_TYPES.REMINDER:
        if (graphDisplayInfo.pushID && graphDisplayInfo.pushContentID) {
          graphDisplayInfo.experienceContentIDs = contentState.allRefId.map(
            (refId: string) => contentState.byRefId[refId].id || '',
          );
          graphDisplayInfo.experienceID = experienceId;
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH_CONTENT;
        } else if (graphDisplayInfo.pushID) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH;
        }
        break;
      case JOURNEY_TEMPLATE_TYPES.CONTENT:
      default:
        graphDisplayInfo.experienceContentIDs = contentState.allRefId.map(
          (refId: string) => contentState.byRefId[refId].id || '',
        );
        graphDisplayInfo.experienceID = experienceId;
        if (
          graphDisplayInfo.experienceID &&
          graphDisplayInfo.pushID &&
          graphDisplayInfo.experienceContentIDs?.length &&
          graphDisplayInfo.experienceContentIDs?.length > 1
        ) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT;
        } else if (
          graphDisplayInfo.experienceID &&
          graphDisplayInfo.pushID &&
          graphDisplayInfo.experienceContentIDs?.length
        ) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT;
        } else if (
          graphDisplayInfo.experienceID &&
          graphDisplayInfo.experienceContentIDs?.length &&
          graphDisplayInfo.experienceContentIDs?.length > 1
        ) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT;
        } else if (graphDisplayInfo.experienceID && graphDisplayInfo.experienceContentIDs?.length) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT;
        }
        break;
    }
  }
  return graphDisplayInfo;
};

const SmartTargeting: React.FunctionComponent<TSmartTargetingProps> = ({
  isOpen,
  onClickClose,
  journeyInstance,
  status,
  setStatus,
}) => {
  const AoAPI = useMemo(() => new AOApi(), []);

  const teState = useSelector((state) => state.te);
  const dispatch = useDispatch();
  const { languages } = useSetting();
  const graphDisplayInfo = getGraphDisplayInfo(teState);
  const funnelActions = useActions(AnalyticsFunnelActions);
  const performanceActions = useActions(AnalyticsPerformanceActions);
  const engagementActions = useActions(AnalyticsEngagementActions);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);
  const [dropdownValue, setDropdownValue] = useState('Daily');
  const [metrics, setMetrics] = useState(INITIAL_METRICS);
  const [metadata, setMetadata] = useState(INITIAL_METADATA);
  const [engagement, setEngagement] = useState(0);
  const [isTrainingNewModel, setIsTrainingNewModel] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const startDate = teState.journey?.createdAt ? new Date(teState.journey.createdAt * 1000) : null;
    const endDate = teState.journey ? new Date() : null;
    if (startDate && endDate) {
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(0, 0, 0, 0);
      if (graphDisplayInfo) {
        const range = normalizeDate(startDate, endDate);
        fetchExperienceFunnelFlowData(range.startTime, range.endTime);
        fetchExperiencePerformanceData(range.startTime, range.endTime);
        fetchContentEngagementTableData(range.startTime, range.endTime);
      }
      setDateRange([startDate, endDate]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch only content performance data when performance dropdown value changes
  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      const range = normalizeDate(dateRange[0], dateRange[1]);
      fetchExperiencePerformanceData(range.startTime, range.endTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue]);

  const fetchExperienceFunnelFlowData = (startTime: number, endTime: number) => {
    if (!graphDisplayInfo?.type) return;
    const funnelParams = { startdate: startTime, enddate: endTime };
    // will not fetch funnel data for an in-app multi/amplified content journey
    if (
      ![
        GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT,
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT,
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT,
      ].includes(graphDisplayInfo.type)
    ) {
      // fetch target audience count for all types but in-app multi content journey
      funnelActions.fetchFunnelTAData({
        ruleID: graphDisplayInfo.ruleID,
        params: funnelParams,
      });
      // for valid in-app content journey with only one content instance
      if (
        graphDisplayInfo.type === GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT &&
        graphDisplayInfo.experienceID &&
        graphDisplayInfo.experienceContentIDs?.length
      ) {
        // fetch viewed content count
        funnelActions.fetchFunnelVCData({
          contentID: graphDisplayInfo.experienceContentIDs[0],
          params: {
            ...funnelParams,
            experienceid: graphDisplayInfo.experienceID,
          },
        });
        // fetch clicked on content in-app fetchFunnelCOCInAppData
        funnelActions.fetchFunnelCOCInAppData({
          contentID: graphDisplayInfo.experienceContentIDs[0],
          params: {
            ...funnelParams,
            experienceid: graphDisplayInfo.experienceID,
          },
        });
      } else if (graphDisplayInfo.type !== GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT) {
        // fetch sent push, push delivered, opened push counts
        funnelActions.fetchFunnelSPData({ pushID: graphDisplayInfo.pushID, params: funnelParams });
        funnelActions.fetchFunnelPDData({ pushID: graphDisplayInfo.pushID, params: funnelParams });
        funnelActions.fetchFunnelOPData({ pushID: graphDisplayInfo.pushID, params: funnelParams });
        // fetch clicked on content from push if the push contains a content
        if (
          ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH].includes(graphDisplayInfo.type)
        ) {
          funnelActions.fetchFunnelCOCFromPushData({
            contentID: graphDisplayInfo.pushContentID,
            params: {
              ...funnelParams,
              pushid: graphDisplayInfo.pushID,
            },
          });
        }
      }
    }
  };

  const fetchExperiencePerformanceData = (startTime: number, endTime: number) => {
    if (!graphDisplayInfo?.type) return;
    const performanceParams = {
      startdate: startTime,
      enddate: endTime,
      aggregate: AGGREGATE_DROPDOWN_MAP[dropdownValue],
    };
    // broadcast type journey will not need experience performance graph
    if (
      ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST_CONTENT].includes(
        graphDisplayInfo.type,
      )
    ) {
      performanceActions.fetchPerformanceTAData({
        ruleID: graphDisplayInfo.ruleID,
        params: performanceParams,
      });
    }
    // fetch content view/click performance data
    if (
      [GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT, GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT].includes(
        graphDisplayInfo.type,
      ) &&
      graphDisplayInfo.experienceContentIDs &&
      graphDisplayInfo.experienceContentIDs[0]
    ) {
      const parentIDs = graphDisplayInfo.pushID
        ? [graphDisplayInfo.experienceID, graphDisplayInfo.pushID]
        : [graphDisplayInfo.experienceID];
      // fetch content viewed performance only once for in-app type journey
      performanceActions.fetchPerformanceCVData({
        contentID: graphDisplayInfo.experienceContentIDs[0],
        experienceID: parentIDs[0],
        parentIDs,
        params: performanceParams,
      });
      // fetch content clicked performance only once for in-app type journey
      performanceActions.fetchPerformanceCCData({
        contentID: graphDisplayInfo.experienceContentIDs[0],
        experienceID: parentIDs[0],
        parentIDs,
        params: performanceParams,
      });
    } else if (graphDisplayInfo.type === GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH_CONTENT) {
      // fetch content viewed performance only once for triggered push type journey with content
      performanceActions.fetchPerformanceCCData({
        contentID: graphDisplayInfo.pushContentID,
        pushID: graphDisplayInfo.pushID,
        parentIDs: [graphDisplayInfo.pushID],
        params: performanceParams,
      });
    }
    // fetch push delivered performance data
    if (
      [
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT,
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT,
        GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH,
        GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH_CONTENT,
      ].includes(graphDisplayInfo.type)
    ) {
      performanceActions.fetchPerformancePDData({
        pushID: graphDisplayInfo.pushID,
        params: performanceParams,
      });
      // fetch push opened performance data
      if (graphDisplayInfo.type !== GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT) {
        performanceActions.fetchPerformanceOPData({
          pushID: graphDisplayInfo.pushID,
          params: performanceParams,
        });
      }
    }
  };

  const fetchContentEngagementTableData = (startTime: number, endTime: number) => {
    const engagementParams = { startdate: startTime, enddate: endTime };

    if (!graphDisplayInfo?.type) return;
    if (![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH].includes(graphDisplayInfo.type)) {
      const contentIDs: string[] = graphDisplayInfo.experienceContentIDs || [];
      graphDisplayInfo.pushContentID &&
        !contentIDs.includes(graphDisplayInfo.pushContentID) &&
        contentIDs.push(graphDisplayInfo.pushContentID);
      engagementActions.fetchContentEngagementTableData({
        experienceID: graphDisplayInfo.experienceID,
        contentID: graphDisplayInfo.pushContentID,
        pushID: graphDisplayInfo.pushID,
        contentIDs,
        params: {
          ...engagementParams,
          experienceid: graphDisplayInfo.experienceID,
          pushid: graphDisplayInfo.pushID,
        },
        isReset: true,
        defaultLanguage: languages[0] || 'en',
      });
    }
  };

  let showExperienceFunnel = false;
  let showExperiencePerformance = false;
  let showContentEngagementTable = false;

  if (
    graphDisplayInfo?.type &&
    ![
      GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT,
      GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT,
      GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT,
    ].includes(graphDisplayInfo.type)
  ) {
    showExperienceFunnel = true;
  }
  if (
    graphDisplayInfo?.type &&
    ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST_CONTENT].includes(graphDisplayInfo.type)
  ) {
    showExperiencePerformance = true;
  }
  if (
    graphDisplayInfo?.type &&
    ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH].includes(graphDisplayInfo.type)
  ) {
    showContentEngagementTable = true;
  }

  const renderStatusDropdown = () => {
    return (
      <FlightDropdown
        className="header-dropdown"
        trigger={
          <div className="header-status">
            Status:
            <div className="header-status__label">
              {getIcon('checkCircle', { className: `header-status__label__icon ${status}` })}
              Active
            </div>
            {getIcon('downCarrot', { className: `header-status__dropdown__icon` })}
          </div>
        }
        isActive={isDropdownOpen && !isConfirmationModalOpen}
        isControlledByIsActive={true}
        handleTriggerClick={() => setIsDropdownOpen(true)}
        handleClickOutside={() => setIsDropdownOpen(false)}
      >
        <div className="header-status__dropdown__option__container">
          <div
            className="header-status__dropdown__option"
            onClick={() => {
              setIsDropdownOpen(false);
              setIsConfirmationModalOpen(true);
            }}
          >
            <p>
              Turn <b>off</b> smart targeting
            </p>
          </div>
        </div>
      </FlightDropdown>
    );
  };

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: '',
        goBackTitle: 'Smart Targeting',
        goBackIcon: <SmartTargetingIcon fill="#ffffff" />,
        goBackActionHandler: () => onClickClose(),
        statusComponent: status === 'active' ? renderStatusDropdown() : undefined,
      },
      footerInfo: {
        secondaryActionHandler: onClickClose,
      },
      size: 'large',
      showFooter: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, onClickClose, status, isDropdownOpen, isConfirmationModalOpen],
  );

  // getting metrics and metadata
  // needs to re-get when the journey changes (usually audience optimization field)
  // or when the training status changes
  useEffect(() => {
    if (journeyInstance.id) {
      AoAPI.getMetrics(journeyInstance.id).then((response: SmartTargetingMetrics) => {
        setMetrics(response);
      });
      AoAPI.getMetadata(journeyInstance.id).then((response: SmartTargetingMetadata) => {
        setMetadata(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyInstance]);

  useEffect(() => {
    // when training a new model, wait until training is done
    if (isTrainingNewModel) {
      const interval = setInterval(() => {
        journeyInstance.id &&
          AoAPI.getMetadata(journeyInstance.id).then((response: SmartTargetingMetadata) => {
            if (response?.devModelStatus.status.toLowerCase() !== 'inprogress') {
              setMetadata(response);
              setIsTrainingNewModel(false);
              journeyInstance.id &&
                AoAPI.getMetrics(journeyInstance.id).then((response: SmartTargetingMetrics) => {
                  if (!!response?.candidateModelMetrics.optimizationGraph?.length) {
                    setMetrics(response);
                  }
                });
              clearInterval(interval);
            }
          });
      }, 60000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrainingNewModel]);

  // set status based on metadata/metrics
  useEffect(() => {
    const journeyMode = journeyInstance?.audienceOptimization?.journeyMode || '';
    const devModelStatus = metadata.devModelStatus.status.toLowerCase();
    const prodModelStatus = metadata.prodModelStatus.status.toLowerCase();
    const activeModelExists = !!metrics.activeModelMetrics.optimizationGraph?.length;
    const candidateModelExists = !!metrics.candidateModelMetrics.optimizationGraph?.length;

    /**
     * TODO: Fix the logic/ui/ux for setting the status, and what each status means.
     * Right now this logic excludes certain combinations of the variables above from even accessing the slideout
     * (ex. when prodModelStatus is completed but automation is turned to manual), since inactive prevents opening
     * the slideout altogether.
     **/
    // if dev model status is in progress and any model exists, model is retraining
    setIsTrainingNewModel(devModelStatus === 'inprogress' && (activeModelExists || candidateModelExists));
    if ((prodModelStatus === 'mocked' || prodModelStatus === 'completed') && journeyMode === 'automation') {
      // active when there is a prod model and journeyMode automation
      setStatus(SMART_TARGET_STATUS.ACTIVE);
    } else if (prodModelStatus !== 'completed' && candidateModelExists) {
      // ready to activate when there is a candidate model, and no prod model
      setStatus(SMART_TARGET_STATUS.READY_ACTIVATE);
    } else if (
      devModelStatus === 'inprogress' &&
      prodModelStatus !== 'completed' &&
      !candidateModelExists &&
      journeyMode !== 'automation'
    ) {
      if (status === SMART_TARGET_STATUS.IN_TRAINING) return;
      // in training when there is no candidate or prod model, and dev status is inprogress
      setStatus(SMART_TARGET_STATUS.IN_TRAINING);
      // when in training, continue getting metrics until candidate model is available
      const interval = setInterval(() => {
        AoAPI.getMetadata(journeyInstance.id)
          .then((response: SmartTargetingMetadata) => {
            if (response?.devModelStatus.status.toLowerCase() !== 'inprogress') {
              setMetadata(response);
              journeyInstance.id &&
                AoAPI.getMetrics(journeyInstance.id).then((response: SmartTargetingMetrics) => {
                  if (!!response?.candidateModelMetrics.optimizationGraph?.length) {
                    setMetrics(response);
                  }
                });
              clearInterval(interval);
            }
          })
          .catch(() => clearInterval(interval));
      }, 60000);
    } else {
      setStatus(SMART_TARGET_STATUS.INACTIVE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, metrics]);

  const stopSmartTargeting = async () => {
    setIsConfirmationModalOpen(false);
    const updatedAOPayload: AudienceOptimization = {
      ...journeyInstance.audienceOptimization,
      audienceOptimizationEligible: true,
      isEnabled: false,
      journeyMode: journeyInstance?.steps[0]?.rule?.preferred?.length ? 'exploration' : 'manual',
      automationBudget: {
        ...journeyInstance.audienceOptimization?.automationBudget,
        cutOffPercentage: 0,
      },
    };

    await dispatch(updateJourneyAO(updatedAOPayload));
  };

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={MAIN_CLASS} aria-label="smart targeting panel">
        <CurrentlyOptimizing engagement={engagement} status={status} />
        <ProjectedPerformance
          metrics={metrics}
          isTrainingNewModel={isTrainingNewModel}
          setIsTrainingNewModel={setIsTrainingNewModel}
          targetEngagementRate={engagement}
          setTargetEngagementRate={setEngagement}
        />
        <ContextAttributeChart
          metrics={metrics}
          isTrainingNewModel={isTrainingNewModel}
          setIsTrainingNewModel={setIsTrainingNewModel}
        />
        {showExperienceFunnel && <STExperienceFunnel journeyType={graphDisplayInfo.type || ''} />}

        {showExperiencePerformance && (
          <STExperiencePerformance
            journeyType={graphDisplayInfo.type || ''}
            dropdownValue={dropdownValue}
            setDropdownValue={(val: string) => setDropdownValue(val)}
          />
        )}
        {showContentEngagementTable && <STContentEngagementTable />}
      </div>
      <div className={`${MAIN_CLASS}__modal__container`}>
        <FlightModal
          size="small"
          className={`${MAIN_CLASS}__modal`}
          header={
            <div className="modal-header">
              <TargetImage />
              <p>Turn off Smart Targeting?</p>
            </div>
          }
          content="This means that you will no longer be given suggestions to optimize your experience, you can always turn this feature on again."
          footer={
            <div className="modal-footer">
              <FlightButton
                className="modal-footer-confirm"
                label="Yes, turn off Smart Targeting"
                theme="secondary"
                onClick={stopSmartTargeting}
              />
              <FlightButton
                className="modal-footer-cancel"
                label="Cancel"
                theme="secondary"
                onClick={() => setIsConfirmationModalOpen(false)}
              />
            </div>
          }
          isVisible={isConfirmationModalOpen}
          toggleModalShown={() => setIsConfirmationModalOpen(false)}
        />
      </div>
    </SlidingSidePanel>
  );
};

export default SmartTargeting;
