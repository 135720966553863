// Library
import { useState, useEffect, useMemo } from 'react';

// Custom Hooks
import { useAppSelector as useSelector } from './reduxHooks';

// Service Class
import SettingsAPI from 'services/api/setting.api';

// Interface
import { Category } from 'interface/templated-experience/templated-experience.interface';
import { useQuery } from '@tanstack/react-query';

// Settings API Class Instance

type CategoryMeta = {
  [key: string]: string | undefined;
};

const fetchCategories = async (settingsApi: SettingsAPI) => {
  try {
    const categoriesResult = await settingsApi.getCategories();
    const categories: Category[] = categoriesResult?.settings?.categories || [];
    return categories;
  } catch {
    return []; // we want to return a empty array if there is an error
  }
};

// useCategoryLibrary hook
export default function useCategoryLibrary() {
  const settingsApi = useMemo(() => new SettingsAPI(), []);

  const [categoryData, setCategoryData] = useState<CategoryMeta>({
    selectedCategoryName: '',
    catId: '',
  });
  const [subCategoryData, setSubCategoryData] = useState<CategoryMeta>({
    selectedSubCategoryName: '',
    subCatId: '',
  });

  const journeyInstance = useSelector((state) => state.te.journey);

  const {
    data: categories,
    isLoading,
    isError,
  } = useQuery<Category[]>({ queryKey: ['categories'], queryFn: () => fetchCategories(settingsApi) });

  useEffect(() => {
    if (isLoading || isError) return;

    if (journeyInstance?.categories) {
      journeyInstance?.categories.forEach((categoryMeta) => {
        const catMetaName = categoryMeta.toLowerCase();

        if (categoryMeta.startsWith('category')) {
          const selectedCategoryObj = categories?.find(
            (cat: Category) => `category-${cat.key.toLowerCase()}` === catMetaName,
          );

          setCategoryData({
            selectedCategoryName: selectedCategoryObj?.name || 'Templates',
            catId: selectedCategoryObj?.key,
          });
        } else if (categoryMeta.startsWith('subcategory')) {
          const selectedSubCategoryObj = categories
            ?.map((cat: Category) => cat.subcategories)
            ?.flat(1)
            ?.find((subCat) => `subcategory-${subCat?.key.toLowerCase()}` === catMetaName);

          setSubCategoryData({
            selectedSubCategoryName: selectedSubCategoryObj?.name,
            subCatId: selectedSubCategoryObj?.key,
          });
        }
      });
    }
  }, [categories, isLoading, isError, journeyInstance]);

  return {
    categories,
    category: { categoryData, subCategoryData },
    isLoading,
    isError,
  };
}
