import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';

import { getIcon } from '@flybits/design-system';
import { validateJourney } from 'validators/ExperienceCanvas/journey.validator';

import { TExperienceCanvasRouteParams } from 'pages/ExperienceCanvas/types';

import './ApprovalStatusData.scss';

type TApprovalStatusDataProps = {
  toggleApprovalSlideOut: () => void;
};

const MAIN_CLASS = 'approval-status-data';
const CLASSES = {
  BADGE: `${MAIN_CLASS}__badge`,
  TITLE: `${MAIN_CLASS}__title`,
};

const ApprovalStatusMap = {
  na: {
    label: 'Send for Approval',
    ariaLabel: '',
    class: `${MAIN_CLASS} ${MAIN_CLASS}--na`,
    icon: getIcon('export', {}),
    badge: undefined,
    action: undefined,
  },
  allowed: {
    label: 'Send for Approval',
    ariaLabel: 'open send for approval slide out',
    class: `${MAIN_CLASS} ${MAIN_CLASS}--allowed`,
    icon: getIcon('export', {}),
    badge: undefined,
    action: 'toggleSlideout',
  },
  review: {
    label: 'Sent for Approval',
    ariaLabel: 'open approval review report',
    class: `${MAIN_CLASS} ${MAIN_CLASS}--review`,
    icon: getIcon('openInNew', {}),
    badge: {
      title: 'In review',
      icon: getIcon('education', {}),
    },
    action: 'link',
  },
  approved: {
    label: 'Sent for Approval',
    ariaLabel: 'open approval review report',
    class: `${MAIN_CLASS} ${MAIN_CLASS}--approved`,
    icon: getIcon('openInNew', {}),
    badge: {
      title: 'Approved',
      icon: getIcon('checkCircleOutline', {}),
    },
    action: 'link',
  },
};

const ApprovalStatusData: React.FC<TApprovalStatusDataProps> = ({ toggleApprovalSlideOut }) => {
  const { pid, id } = useParams<TExperienceCanvasRouteParams>();
  const history = useHistory();
  const { isApproved, isReadyToLaunch } = useSelector((state) => {
    return {
      isApproved: state.te.journey.isApproved,
      // TODO: Same as slidout, check if BE object is in launchable state
      isReadyToLaunch: !!state.te.journey.id && state.te.journey.status === 'draft' && validateJourney(state),
    };
  });

  const status =
    isApproved === true
      ? ApprovalStatusMap['approved']
      : isApproved === false
      ? ApprovalStatusMap['review']
      : isReadyToLaunch
      ? ApprovalStatusMap['allowed']
      : ApprovalStatusMap['na'];

  const handleOnClick = (action?: string) => {
    switch (action) {
      case 'toggleSlideout':
        toggleApprovalSlideOut();
        break;
      case 'link':
        history.push(`/project/${pid}/experiences/${id}/approval`);
        break;
      default:
        return;
    }
  };

  return (
    <button
      className={status.class}
      aria-label={status.ariaLabel}
      disabled={!status.action}
      onClick={() => handleOnClick(status.action)}
    >
      <div className={CLASSES.TITLE}>{status.label}</div>
      {status.badge && (
        <div className={CLASSES.BADGE}>
          {status.badge.icon}
          {status.badge.title}
        </div>
      )}
      {status.icon}
    </button>
  );
};

export default ApprovalStatusData;
