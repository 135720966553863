import './Zones.scss';
import { FlightButton, FlightTextInput } from '@flybits/design-system';
import { getTenantId } from 'helpers/templated-experience.helper';
import { initialZonePayload } from './constants/zone-payloads';
import { Module, Zone, ZoneConfig } from 'pages/Zones/types';
// import { useDispatch } from 'react-redux';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import AddModuleBox from 'components/Zones/AddModuleBox/AddModuleBox';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import ModuleBox from 'components/Zones/ModuleBox/ModuleBox';
import ModulePositionManager from 'components/Zones/ModulePositionManager/ModulePositionManager';
import NewZone from 'components/Zones/NewZone/NewZone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Session from 'services/session';
import SetupZones from 'components/Zones/SetupZones/SetupZones';
import ZoneAPI from 'services/api/zone.api';

export type ZonesRouteParams = {
  pid: string;
};

type FooterMessage = {
  message: string;
  type: 'success' | 'error';
};

const Zones = function () {
  const zoneAPI = useMemo(() => new ZoneAPI(), []);
  const session = useMemo(() => new Session(getTenantId()), []);

  const [footerMessage, setFooterMessage] = useState<FooterMessage>();
  const [selectedZoneId, setSelectedZoneId] = useState<string>();
  const [isModulePositionManagerOpen, setIsModulePositionManagerOpen] = useState(false);
  const { data: zoneConfig, status } = useQuery({
    queryKey: ['zoneConfig'],
    queryFn: async () => await zoneAPI.getZoneConfig(),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [memoryZoneConfig, setMemoryZoneConfig] = useState<ZoneConfig>();
  const { pid: projectId } = useRouteMatch<ZonesRouteParams>().params;
  const history = useHistory();
  const [isLaunchingZone, setLaunchingZone] = useState(false);
  const isZonesInitialized = useRef(false);
  const dispatch = useDispatch();
  const selectedZone = memoryZoneConfig?.zones?.find((zone) => zone.id === selectedZoneId);
  const [selectedZoneReferenceId, setSelectedZoneReferenceId] = useState<string>('');
  const [isZoneConfigSaving, setIsZoneConfigSaving] = useState(false);

  const saveConfig = async () => {
    if (!memoryZoneConfig) return;

    setIsZoneConfigSaving(true);

    try {
      const payload = memoryZoneConfig;
      payload.zones.forEach((zone) => {
        zone.modules.forEach((module) => delete module.labelFilters);
      });
      const data = await zoneAPI.updateZoneConfig(payload, memoryZoneConfig.id);
      isZonesInitialized.current = false;
      setMemoryZoneConfig(data);
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: 'Your changes have been saved', type: 'success' },
      });
      setIsFooterVisible(false);
    } catch {
      setFooterMessage({ message: 'An error occurred while saving your changes', type: 'error' });
    } finally {
      setIsZoneConfigSaving(false);
    }
  };

  const handleLaunchZone = async () => {
    setLaunchingZone(true);
    await zoneAPI.createZoneConfig(initialZonePayload);

    // ToDo: zoneconfig is not available in redux state yet
    // Re-loading the page to update the state
    history.go(0);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const openConciergeSimulator = async (data: any) => {
    const host = process.env.REACT_APP_API_URL;
    const referenceID = data.zone.referenceID;
    const conciergeSimulatorURL = process.env.VUE_CONCIERGE_SIMULATOR_URL;
    const token = await session.getToken();

    const conciergeSimulatorWindow = window.open(
      conciergeSimulatorURL,
      'Concierge Simulator',
      `popup,width=600,height=${window.innerHeight}`,
    );

    if (!conciergeSimulatorWindow) {
      // TODO: Implement later
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const messageCallback = (event: any) => {
        if (event.origin !== 'https://simulator.flybits.app') {
          return;
        }

        // TODO: Debug later why this is not working
      };

      window.addEventListener('message', messageCallback, false);

      setTimeout(() => {
        conciergeSimulatorWindow.postMessage(
          {
            flb: true,
            type: 'flb.seed',
            host: host,
            refID: referenceID,
            jwt: token,
          },
          '*',
        );
      }, 5000);
    }
  };

  const handleSelectedZoneReferenceId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedZoneReferenceId(value);

    if (selectedZone) {
      selectedZone.referenceID = value;
    }
  };

  useEffect(() => {
    if (!zoneConfig || zoneConfig.zones.length <= 0) {
      return;
    }

    if (selectedZoneId) {
      const filteredZones = zoneConfig.zones.filter((z) => z.id === selectedZoneId);

      if (filteredZones.length > 0) {
        setSelectedZoneReferenceId(filteredZones[0].referenceID ?? '');
      }
    } else {
      setSelectedZoneReferenceId(zoneConfig.zones[0].referenceID ?? '');
    }
  }, [selectedZoneId, zoneConfig]);

  useEffect(() => {
    document.title = `Zones & Modules | Experience Studio @Flybits`;
  }, []);

  useEffect(() => {
    if (!memoryZoneConfig) return;
    if (memoryZoneConfig && !isZonesInitialized.current) {
      isZonesInitialized.current = true;
      return;
    }
    setIsFooterVisible(true);
  }, [memoryZoneConfig]);

  useEffect(() => {
    if (!zoneConfig?.zones[0]) return;
    setMemoryZoneConfig(zoneConfig);
    setSelectedZoneId(zoneConfig?.zones[0]?.id);
  }, [zoneConfig]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (footerMessage) {
      timeout = setTimeout(() => {
        setFooterMessage(undefined);
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [footerMessage]);

  if (status === 'loading') {
    return (
      <div className="zones-loading-wrapper">
        <LoadingIcon />
      </div>
    );
  }

  if (status === 'error' || !memoryZoneConfig || !selectedZoneId) {
    return <SetupZones isLaunchingZone={isLaunchingZone} onLaunchZone={handleLaunchZone} />;
  }

  return (
    <>
      <div className="zones-container">
        <header>
          <h1>Zones & Modules</h1>
          <p className="description">Select a module you want to edit</p>
          <ul className="zones-container__tabs">
            {memoryZoneConfig?.zones?.map((zone: Zone, index) => (
              <li key={zone?.id || index}>
                <button
                  role="tab"
                  className={`zones-container__tabs__tab ${selectedZoneId === zone.id ? 'active' : ''}`}
                  aria-selected={selectedZoneId === zone.id}
                  onClick={() => setSelectedZoneId(zone.id)}
                >
                  {zone.name}
                </button>
              </li>
            ))}
            <li>
              <NewZone zoneConfig={memoryZoneConfig} setZoneConfig={setMemoryZoneConfig} />
            </li>
          </ul>
        </header>
        <main key={selectedZoneId}>
          {!!selectedZoneId && (
            <>
              <div className="concierge-simulator-header">
                <div className="test">
                  <label htmlFor="referenceID">Reference ID</label>
                  <FlightTextInput
                    name="referenceID"
                    value={selectedZoneReferenceId}
                    disabled={isZoneConfigSaving}
                    onChange={handleSelectedZoneReferenceId}
                    onBlur={saveConfig}
                  />
                </div>
                <FlightButton
                  className="redirect-devportal"
                  onClick={() => {
                    openConciergeSimulator({ zone: selectedZone });
                  }}
                  iconRight="openInNew"
                  label="Concierge Simulator"
                  size="small"
                />
              </div>
              {selectedZone?.modules?.map((module: Module) => (
                <ModuleBox
                  key={module.id}
                  selectedZoneId={selectedZone.id as string}
                  zoneConfig={memoryZoneConfig}
                  setZoneConfig={setMemoryZoneConfig}
                  module={module}
                  toggleModulePositionManager={() => setIsModulePositionManagerOpen((prevState) => !prevState)}
                />
              ))}
              <AddModuleBox
                selectedZoneId={selectedZoneId}
                zoneConfig={memoryZoneConfig}
                setZoneConfig={setMemoryZoneConfig}
              />
            </>
          )}
          {!selectedZone && <div className="no-zone-selected">Select a zone</div>}
        </main>
        {/* <aside className="mobile-hidden">
        <h2>Previewer</h2>
        <p className="description">
          The preview mimics the look of a mobile device but doesn&apos;t show custom theming. Your final implementation
          may vary.
        </p>
      </aside> */}
        {isFooterVisible && (
          <footer>
            {footerMessage && <div className={`${footerMessage.type}-message`}>{footerMessage.message}</div>}
            <FlightButton label={'Cancel'} theme="secondary" onClick={() => history.push(`/project/${projectId}/`)} />
            <FlightButton label={'Save'} onClick={saveConfig} />
          </footer>
        )}
        <ModulePositionManager
          isOpen={isModulePositionManagerOpen}
          onClose={() => setIsModulePositionManagerOpen(false)}
          zoneId={selectedZoneId}
          zoneConfig={memoryZoneConfig}
          setZoneConfig={setMemoryZoneConfig}
        />
      </div>
    </>
  );
};

export default Zones;
