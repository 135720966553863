// hook for pulling pushtypes

import { useEffect } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { fetchPushTypesThunk } from 'store/templated-experience/templated-experience.thunk';

export default function usePushTypes() {
  const pushTypes = useSelector((state) => state.templatedExperience.pushTypes);
  const dispatch = useDispatch();
  // does not guard for the (probably impossible) case of 0 types on a tenant
  const isInitialized = !!(pushTypes && pushTypes.length);

  useEffect(() => {
    if (isInitialized) return;
    dispatch(fetchPushTypesThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pushTypes,
    isInitialized,
  };
}
