import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlightSelect } from '@flybits/design-system';
import { convertEpochToTime } from 'helpers/common.helper';
import { getTimezoneList, guessUserTimezone } from 'helpers/templated-experience.helper';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import useJourneyStatus from 'hooks/useJourneyStatus';
import { Option, SelectOption } from 'types/common';
import { getIsAudienceEmpty, selectStepRule, selectStepSchedules } from 'store/rule/rule.selector';
import { getStepRuleSyntheticId } from 'store/helper';
import { saveContextualSchedulerAction } from 'store/rule/rule.action';
import { TTimelineBoxProps } from 'pages/ExperienceCanvas/types';
import CalendarWithClockIcon from 'pages/ExperienceCanvas/assets/icons/CalendarWithClockIcon';
import TouchpointEditorCard from 'components/Shared/TouchpointEditorCard/TouchpointEditorCard';
import { getAbsoluteScheduleErrors } from 'validators/ExperienceCanvas/rule.validator';
import EventsSchedule from './EventsSchedule/EventsSchedule';
import TimeSchedule from './TimeSchedule/TimeSchedule';
import { MAIN_CLASS, CLASSES } from './classes';
import './ScheduleEditorBox.scss';
import { PUSH_ACTION_TYPES } from 'store/push/push.type';

const basedOnOptions: SelectOption[] = [
  { key: 'time', name: 'Time' },
  { key: 'events', name: 'Events' },
];

const tzList: Option[] = getTimezoneList().map((tz) => ({
  key: tz.key,
  name: tz.name,
  value: tz.offset,
}));

const ScheduleEditorBox: React.FC<TTimelineBoxProps> = ({ stepIdx, timelineIndex }) => {
  const dispatch = useDispatch();
  const { isJourneyLocked } = useJourneyStatus();
  const isScheduleDisabled = useSelector((state) => {
    const templateType = state.te.journey.templateType;
    if (templateType) {
      return templateType !== 'broadcast' && templateType !== 'reminder';
    } else {
      const pushRefId = state.te.journey.steps[stepIdx]?.push;

      if (!pushRefId) {
        return false;
      }

      const pushStateItem = state.te.push.byRefId[pushRefId];
      const isBroadcast = pushStateItem.actionType === PUSH_ACTION_TYPES.BROADCAST_PUSH;

      const step = state.te.journey.steps[stepIdx];
      const isReminder = step?.constraints?.audienceConstraints?.syncStartScheduleWithRuleBody ?? false;

      return isBroadcast || isReminder;
    }
  });
  const isTouchpointDirty = useSelector((state) => state.te.journey.steps[stepIdx]?.isDirty);
  const { status, schedule: journeySchedule } = useSelector((state) => state.te.journey);
  const { startScheduler, endScheduler, operationType, isAudienceEveryone } = useSelector((state) => {
    const synthId = getStepRuleSyntheticId(stepIdx);
    const primaryRule = selectStepRule(state.te, stepIdx);
    const isAudienceEveryone = !primaryRule?.enableOptionalNode;
    return {
      startScheduler: state.te.rule.startSchedulerMap[synthId],
      endScheduler: state.te.rule.endSchedulerMap[synthId],
      operationType: state.te.journey.steps[stepIdx]?.operationType,
      isAudienceEveryone,
    };
  });
  const [storeDateTime, err] = useSelector((state) => {
    const stepSchedule = selectStepSchedules(state.te, stepIdx);
    const timezone = stepSchedule.start?.timezone || stepSchedule.end?.timezone || guessUserTimezone();
    const start = convertEpochToTime(stepSchedule.start?.value, timezone, state.te.journey.steps[stepIdx]?.omitStart);
    const end = convertEpochToTime(stepSchedule.end?.value, timezone, state.te.journey.steps[stepIdx]?.omitEnd);
    return [
      {
        start,
        end,
        timezone: tzList?.find((tz) => tz.name === timezone),
      },
      status === 'active' || status === 'scheduled'
        ? { start: [], end: [], journey: [] }
        : getAbsoluteScheduleErrors(
            state,
            {
              isActive: !!start.isActive,
              value: stepSchedule.start?.value,
              hasCronExpression: !!stepSchedule?.start?.cronExpression,
            },
            { isActive: !!end.isActive, value: stepSchedule.end?.value },
            journeySchedule,
          ),
    ];
  });
  const [dateTime, setDateTime] = useState(storeDateTime);
  const hasSchedule = !!dateTime.start.date || !!dateTime.end.date || !!startScheduler?.cronExpression;

  const clearDateTime = () => {
    setDateTime({
      start: {
        isActive: true,
        date: undefined,
        time: undefined,
      },
      end: {
        isActive: false,
        date: undefined,
        time: undefined,
      },
      timezone: dateTime.timezone,
    });
  };

  const getBasedOnInitialValue = () => {
    if (Object.keys(startScheduler?.predicates || {}).length > 0) return basedOnOptions[1];
    if (!!dateTime.start.date || !!dateTime.end.date || !!startScheduler?.cronExpression) return basedOnOptions[0];
    return undefined;
  };

  const [basedOn, setBasedOn] = useState<SelectOption | undefined>(getBasedOnInitialValue);
  const [isExpanded, setIsExpanded] = useState(!isAudienceEveryone && (hasSchedule || !!basedOn));

  const handleScheduleExpanded = (value: boolean) => {
    setIsExpanded(value);

    if (!value) setBasedOn(undefined);

    dispatch({
      type: 'UPDATE_STEP',
      payload: {
        stepIdx,
        fields: {
          omitSched: !value,
          omitStart: true,
          omitEnd: true,
        },
      },
    });

    if (!value) {
      dispatch({
        type: 'CLEAR_SCHEDULE',
        payload: {
          stepIdx,
          type: 'both',
        },
      });
      clearDateTime();
    }
  };

  const handleBasedOnChange = (option: SelectOption) => {
    const omit = option.key === 'events';

    dispatch({
      type: 'CLEAR_SCHEDULE',
      payload: {
        stepIdx,
        type: 'both',
      },
    });

    dispatch({
      type: 'UPDATE_STEP',
      payload: {
        stepIdx,
        fields: {
          omitSched: omit,
          omitStart: omit,
          omitEnd: omit,
        },
      },
    });

    if (option.key === 'time') {
      dispatch(
        saveContextualSchedulerAction({
          stepIdx,
          type: 'both',
          modifier: '',
          predicates: null,
          target: 'now',
          logic: '',
        }),
      );
    } else {
      clearDateTime();
    }

    setBasedOn(option);
  };

  useEffect(() => {
    if (isAudienceEveryone) {
      dispatch({
        type: 'UPDATE_STEP',
        payload: {
          stepIdx,
          fields: {
            omitSched: true,
            omitStart: true,
            omitEnd: true,
          },
        },
      });
    }
  }, [isAudienceEveryone, dispatch, stepIdx]);

  const isAudienceEmpty = useSelector((state) => getIsAudienceEmpty(state.te, stepIdx));

  return (
    <TouchpointEditorCard
      title="Schedule"
      icon={CalendarWithClockIcon}
      timelineIndex={timelineIndex}
      subtitle="Customize the timing of this touchpoint, allowing you to define the desired delivery period."
      showExpandToggle={true}
      isToggleExpandedDisabled={isAudienceEveryone || isAudienceEmpty || isScheduleDisabled}
      toggleTooltip={isAudienceEveryone ? 'Requires audience configuration' : undefined}
      toggleExpanded={handleScheduleExpanded}
      isExpanded={isExpanded}
      isLocked={isJourneyLocked}
      hasError={isTouchpointDirty && (!!err.start.length || !!err.end.length || !!err.journey.length)}
    >
      <div className={MAIN_CLASS}>
        <div className={CLASSES.SECTION_HIGHLIGHT}>
          <div className={CLASSES.FIELD}>
            <label htmlFor="schedule-type">The schedule will be based on</label>
            <FlightSelect
              ariaControlsId="schedule-type"
              options={basedOnOptions}
              selected={
                basedOn || {
                  key: '',
                  name: (
                    <span className={CLASSES.SELECT_PLACEHOLDER}>
                      Select how the schedule will work <span className={CLASSES.REQUIRED_SYMBOL}>*</span>
                    </span>
                  ),
                }
              }
              handleOptionClick={handleBasedOnChange}
              width="100%"
            />
          </div>
        </div>
        {basedOn?.key === 'time' && (
          <TimeSchedule
            stepIdx={stepIdx}
            storeDateTime={storeDateTime}
            dateTime={dateTime}
            startScheduler={startScheduler}
            endScheduler={endScheduler}
            setDateTime={setDateTime}
            clearDateTime={clearDateTime}
            operationType={operationType}
            errors={err}
          />
        )}
        {basedOn?.key === 'events' && (
          <EventsSchedule stepIdx={stepIdx} dateTime={dateTime} errors={err} setDateTime={setDateTime} />
        )}
      </div>
    </TouchpointEditorCard>
  );
};

export default ScheduleEditorBox;
