import React from 'react';
import { TIconProps } from '../types';

function AudienceIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.94 11C20.48 6.83 17.17 3.52 13 3.06V1H11V3.06C6.83 3.52 3.52 6.83 3.06 11H1V13H3.06C3.52 17.17 6.83 20.48 11 20.94V23H13V20.94C17.17 20.48 20.48 17.17 20.94 13H23V11H20.94ZM12 19C8.13 19 5 15.87 5 12C5 8.13 8.13 5 12 5C15.87 5 19 8.13 19 12C19 15.87 15.87 19 12 19Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3359 12.5649C15.0209 13.0299 15.5009 13.6599 15.5009 14.4999V15.9999H16.5009C17.0532 15.9999 17.5009 15.5522 17.5009 14.9999V14.4999C17.5009 13.4099 15.7159 12.7649 14.3359 12.5649Z"
        fill={fill}
      />
      <path
        d="M10.5 12C11.6046 12 12.5 11.1046 12.5 10C12.5 8.89543 11.6046 8 10.5 8C9.39543 8 8.5 8.89543 8.5 10C8.5 11.1046 9.39543 12 10.5 12Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5009 12C14.6059 12 15.5009 11.105 15.5009 10C15.5009 8.895 14.6059 8 13.5009 8C13.2659 8 13.0459 8.05 12.8359 8.12C13.2509 8.635 13.5009 9.29 13.5009 10C13.5009 10.71 13.2509 11.365 12.8359 11.88C13.0459 11.95 13.2659 12 13.5009 12Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 12.5C9.165 12.5 6.5 13.17 6.5 14.5V15C6.5 15.5523 6.94772 16 7.5 16H13.5C14.0523 16 14.5 15.5523 14.5 15V14.5C14.5 13.17 11.835 12.5 10.5 12.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default AudienceIcon;
