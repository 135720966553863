import { Experience, StepConstraints } from 'interface/experience/experience.interface';

export type DependencyGraph = {
  [refId: string]: DependencyNode;
};

export type RuleLookUpTable = {
  [refId: string]: string;
};

export enum DEPENDENCY_NODE_TYPES {
  RULE = 'rule',
  ACTION = 'action',
}

export type DependencyNodeType = DEPENDENCY_NODE_TYPES.RULE | DEPENDENCY_NODE_TYPES.ACTION;

export type DependencyNode = {
  // may need more specificity later
  type: DependencyNodeType;
  requires: Dependency[];
  isRefBy: string[];
  stepIdx: number;
};

export type Dependency = {
  var: string;
  refId: string;
  isTemplate?: boolean;
};

// ToDo: Merge the two state step types after flow journey payload is ready for production
export type StateStep = StateLinearStep & Partial<StateBranchStep>;

type StateBranchStep = {
  index: number;
  id: string;
  children: string[];
  type: string;
  parents: string[];
};

type StateLinearStep = {
  name?: string;
  desc?: string;
  iconUrl?: string;
  isLocked?: boolean;
  type?: string;
  push: string;
  rule: {
    restricted: string;
    preferred: string;
  };
  content: string[];
  experience?: string;
  webhook?: string;
  // ui states
  /*
    TODO: Not sure what the best validation flow is. Right now the isDirty is false
    when loading up a template, and turned true when the user exits the touchpoint
    page using an on-page button (i.e. not the browser back).
    If we want to support a better validation flow where the validation only triggers
    for individual boxes that have been dirtied, we'd need a validator for each
    box, instead of the step level isDirty we have here.
  */
  isDirty?: boolean;
  // TODO: will migrate these out later
  omitSched?: boolean;
  omitStart?: boolean;
  omitEnd?: boolean;
  isRequired?: boolean;
  operationType?: string;
  constraints?: StepConstraints;
};

export enum JOURNEY_STATUSES {
  INITIAL = 'initial',
  ACTIVE = 'active',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  INACTIVE = 'inactive',
  ERROR = 'error',
}

export type JourneyStatuses =
  | JOURNEY_STATUSES.INITIAL
  | JOURNEY_STATUSES.ACTIVE
  | JOURNEY_STATUSES.DRAFT
  | JOURNEY_STATUSES.SCHEDULED
  | JOURNEY_STATUSES.INACTIVE
  | JOURNEY_STATUSES.ERROR;

export enum JOURNEY_STATUS_LABELS {
  SAVE = 'Save',
  SCHEDULE = 'Schedule',
  LAUNCH = 'Launch',
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  ERROR = "Can't Launch",
}

export type JourneyStatusLabels = {
  save: {
    statusLabel: JOURNEY_STATUS_LABELS.SAVE;
    isDisabled: boolean;
  };
  launch: {
    statusLabel:
      | JOURNEY_STATUS_LABELS.LAUNCH
      | JOURNEY_STATUS_LABELS.SCHEDULE
      | JOURNEY_STATUS_LABELS.ACTIVATE
      | JOURNEY_STATUS_LABELS.ERROR;
    isDisabled: boolean;
  };
};

// trigger off of the SET_INSTANCE dispatch call
// but to avoid mutating the state too much at this point, we are accessing
// thesese new reducers through an all new branch of the root reducer
// currently this uses preexisting interfaces in the interest of time
// in the future, this should be its own type
export interface JourneyState extends Omit<Experience, 'steps' | 'status' | 'id'> {
  id?: string;
  templateType?: string;
  templateName?: string;
  steps: StateStep[];
  status: JourneyStatuses;
  dependencyGraph?: DependencyGraph;
}
