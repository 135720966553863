import './WebhookForm.scss';

import { FlightButton, FlightSelect, FlightTextArea, FlightTextInput } from '@flybits/design-system';

import React from 'react';

type MethodOption = { key: string; name: string };
const methodOptions: MethodOption[] = [
  {
    key: 'POST',
    name: 'POST',
  },
  {
    key: 'PUT',
    name: 'PUT',
  },
  {
    key: 'DELETE',
    name: 'DELETE',
  },
];

export type WebhookFormValues = {
  name: string;
  description: string;
  method: string;
  url: string;
  headers: { key: string; value: string }[];
  payload: string;
  certificateName: string;
};

type WebhookFormProps = {
  formValues: WebhookFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<WebhookFormValues>>;
  isViewOnly: boolean;
};

function WebhookForm({ formValues, setFormValues, isViewOnly }: WebhookFormProps) {
  return (
    <div className={isViewOnly ? 'webhook-form webhook-form--is-view-only' : 'webhook-form'}>
      <div className="layout-left">
        <label className="form-label" htmlFor="wh-name">
          Name
        </label>
        <FlightTextInput
          label="Enter name"
          labelId="wh-name"
          width="100%"
          value={formValues.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFormValues((prev) => ({ ...prev, name: e.target.value }));
          }}
        />
        <label className="form-label" htmlFor="wh-name">
          Description
        </label>
        <FlightTextArea
          label="Description"
          value={formValues.description}
          width="100%"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setFormValues((prev) => ({ ...prev, description: e.target.value }));
          }}
        />

        <label className="form-label" htmlFor="wh-request-url">
          Request URL<span>*</span>
        </label>
        <div className="section-request-url">
          <FlightSelect
            label="Method"
            width="120px"
            options={methodOptions}
            selected={methodOptions.find((method) => method.key === formValues.method) ?? methodOptions[0]}
            handleOptionClick={(option: MethodOption) => {
              setFormValues((prev) => ({
                ...prev,
                method: option.key,
              }));
            }}
          />
          <FlightTextInput
            label="Enter request URL"
            labelId="wh-request-url"
            width="100%"
            value={formValues.url}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues((prev) => ({ ...prev, url: e.target.value }));
            }}
          />
        </div>

        <p className="section-label">
          Request Headers<span>*</span>
        </p>
        <div className="section-request-headers">
          {formValues.headers.map((header, idx) => (
            <div key={`wh-rh-k-${idx}`} className="row">
              <FlightTextInput
                label="Key"
                value={header.key}
                width="100%"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormValues((prev) => ({
                    ...prev,
                    headers: prev.headers.map((rh, rhi) => {
                      if (rhi !== idx) return rh;
                      return { ...rh, key: e.target.value };
                    }),
                  }));
                }}
              />
              <FlightTextInput
                label="Value"
                value={header.value}
                width="100%"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormValues((prev) => ({
                    ...prev,
                    headers: prev.headers.map((rh, rhi) => {
                      if (rhi !== idx) return rh;
                      return { ...rh, value: e.target.value };
                    }),
                  }));
                }}
              />
              <FlightButton
                label="Remove"
                iconLeft="remove"
                theme="secondary"
                onClick={() =>
                  setFormValues((prev) => ({
                    ...prev,
                    headers: prev.headers.filter((_, i) => i !== idx),
                  }))
                }
              ></FlightButton>
            </div>
          ))}
          <FlightButton
            label="Add Request Header"
            iconLeft="add"
            onClick={() => setFormValues((prev) => ({ ...prev, headers: [...prev.headers, { key: '', value: '' }] }))}
          ></FlightButton>
        </div>

        <p className="section-label">Payload</p>
        <FlightTextArea
          className="payload"
          label="Payload"
          value={formValues.payload}
          width="100%"
          maxLength={5000}
          hideCharCounter={true}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setFormValues((prev) => ({ ...prev, payload: e.target.value }));
          }}
        />
      </div>
      <div className="layout-center">&nbsp;</div>
      <div className="layout-right">
        <p className="section-label">Certificate</p>
        <p>To make changes, please visit the developer portal.</p>
        <FlightTextInput
          label="Certificate"
          value={formValues.certificateName}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setFormValues((prev) => ({ ...prev, certificateName: e.target.value }));
          }}
        />
      </div>
    </div>
  );
}

export default WebhookForm;
