import { TExperienceActionType, TExperienceState } from 'store/templated-experience/templated-experience.type';
import { updateTemplatedInstanceStatus } from 'helpers/templated-experience.helper';

const initialState: TExperienceState = {
  instances: {
    data: [],
    pagination: {
      limit: 0,
      offset: 0,
      sortBy: '',
      sortOrder: '',
      totalRecords: 0,
    },
  }, // instances in the list view
  instance: null,
  template: null, // individual template for /create/:id route
  isLoading: true,
  errorMessage: '',
  errors: [],
  pushTypes: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: TExperienceState = initialState, action: any) => {
  switch (action.type) {
    case TExperienceActionType.SET_INSTANCES:
      return {
        ...state,
        instances: Object.assign({}, action.payload.instances),
      };
    case TExperienceActionType.SET_TEMPLATE:
      return {
        ...state,
        template: Object.assign({}, action.payload.template),
      };
    case TExperienceActionType.SET_INSTANCE:
      return {
        ...state,
        instance: Object.assign({}, action.payload.instance),
      };
    case TExperienceActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.flag,
      };
    case TExperienceActionType.UPDATE_INSTANCES:
      const updatedInstances = updateTemplatedInstanceStatus(state.instances, action.payload.id, action.payload.status);
      return {
        ...state,
        instances: { ...state.instances, data: [...updatedInstances] },
      };
    case TExperienceActionType.SET_PUSH_TYPES:
      return {
        ...state,
        pushTypes: action.payload,
      };
    case TExperienceActionType.SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    case TExperienceActionType.SET_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      };
    default:
      return {
        ...state,
      };
  }
};
