/*
  content.selector.ts
  a set of functions that take in the current state related to the currently
  displayed journey's contents and derives useful data.
*/

import { ContentListItem } from 'components/ExperienceCanvas/types';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { mapStateItemToListItem } from 'helpers/content.helper';
import { PushState } from 'store/push/push.type';
import { RootState } from 'store/store';

/**
 * Returns the refId of the content referred to by a push, or the first content
 * if none have push attached.
 * */
export function getPrimaryContent(teState: RootState['te'], stepIdx: number) {
  const journeyStep = teState.journey.steps[stepIdx];
  if (!journeyStep) return undefined;
  const pushRefId = journeyStep.push;
  if (!pushRefId || !teState.push.byRefId[pushRefId]?.enableOptionalNode) return journeyStep.content[0];
  const pushDependencies = teState.journey.dependencyGraph?.[pushRefId].requires;
  return pushDependencies?.find((dep) => dep.var.includes('content'))?.refId ?? journeyStep.content[0];
}

/**
 * Returns the refId of the push amplifying the content passed in
 * @return string | undefined;
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAmplifyingPushRefId(teState: any, contentRefId: string, stepIdx?: number) {
  if (!contentRefId) return undefined;
  const pushByRefId: PushState['byRefId'] = teState.push.byRefId;
  const pushAllRefId: PushState['allRefId'] = teState.push.allRefId;
  const stepPushId = teState.journey.steps[stepIdx ?? 0]?.push ?? '';

  if (pushAllRefId.includes(stepPushId)) {
    const push = pushByRefId[stepPushId];
    const pushDeps = push.dependencies ?? {};

    if (contentRefId in pushDeps) return stepPushId;
  }

  return undefined;
}

/**
 * Returns the list of contents that will be rendered in the canvas ui
 * @return ContentListItem[];
 */
export function getContentListItems(state: RootState, stepIdx: number) {
  const contentRefIds = state.te.journey.steps[stepIdx]?.content.map((c) => c) ?? [];
  // push primary content onto the list if its from another step
  const primaryContentRefId = getPrimaryContent(state.te, stepIdx);
  if (primaryContentRefId && !contentRefIds.includes(primaryContentRefId)) contentRefIds.push(primaryContentRefId);
  const languages = state.settings.languages;
  const defaultLang = getDefaultLanguage(languages);

  const contentListItems: ContentListItem[] = [];

  contentRefIds.forEach((refId) => {
    const contentStateItem = state.te.content.byRefId[refId];

    if (!contentStateItem) return;
    const contentListItem = mapStateItemToListItem(
      contentStateItem,
      defaultLang,
      contentStateItem.refId === primaryContentRefId ? 'primary' : 'secondary',
    );

    if (!contentListItem) return;
    if (contentStateItem.stepIdx !== stepIdx) {
      const pushRefId = state.te.journey.steps[stepIdx]?.push;
      contentListItem.isReadOnly = !!state.te.journey.dependencyGraph?.[pushRefId]?.requires?.find(
        (d) => d.refId === contentStateItem.refId,
      )?.isTemplate;
    }

    contentListItems.push(contentListItem);
  });

  return contentListItems;
}
