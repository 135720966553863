import React from 'react';
import { IconProps } from './icon.types';

function IconPush(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_1944_34422)">
        <path
          d="M18.7495 26.6967C18.7495 27.0029 18.8711 27.2965 19.0877 27.5131C19.3042 27.7296 19.5978 27.8512 19.904 27.8512C20.2102 27.8512 20.5039 27.7296 20.7204 27.5131C20.9369 27.2965 21.0585 27.0029 21.0585 26.6967V15.7288C20.2777 15.7275 19.5007 15.6187 18.7495 15.4055V26.6967Z"
          fill="#7C4DFF"
        />
        <path
          d="M16.4407 16.3059C16.4407 15.9997 16.319 15.706 16.1025 15.4895C15.886 15.273 15.5924 15.1514 15.2862 15.1514H6.05002C5.74383 15.1514 5.45017 15.273 5.23366 15.4895C5.01714 15.706 4.89551 15.9997 4.89551 16.3059V20.0581H16.4407V16.3059Z"
          fill="#7C4DFF"
        />
        <path
          d="M4.89551 23.2332C4.89551 23.5394 5.01714 23.833 5.23366 24.0496C5.45017 24.2661 5.74383 24.3877 6.05002 24.3877H15.2862C15.5924 24.3877 15.886 24.2661 16.1025 24.0496C16.319 23.833 16.4407 23.5394 16.4407 23.2332V21.79H4.89551V23.2332Z"
          fill="#7C4DFF"
        />
        <path
          d="M12.723 4.76074H3.74089C2.8223 4.76074 1.94134 5.12565 1.29179 5.77519C0.642252 6.42473 0.277344 7.3057 0.277344 8.22429L0.277344 26.6966C0.277344 27.0028 0.39898 27.2964 0.615494 27.5129C0.832008 27.7294 1.12566 27.8511 1.43186 27.8511C1.73806 27.8511 2.03171 27.7294 2.24823 27.5129C2.46474 27.2964 2.58638 27.0028 2.58638 26.6966V10.5333H13.1271C12.6485 9.4413 12.4008 8.26209 12.3998 7.06978C12.4011 6.28891 12.5098 5.51194 12.723 4.76074Z"
          fill="#7C4DFF"
        />
        <path
          d="M21.0585 0.142822C19.6884 0.142822 18.3491 0.54909 17.21 1.31025C16.0708 2.07141 15.1829 3.15327 14.6586 4.41904C14.1343 5.6848 13.9972 7.07761 14.2645 8.42133C14.5317 9.76506 15.1915 10.9993 16.1603 11.9681C17.129 12.9369 18.3633 13.5966 19.707 13.8639C21.0508 14.1312 22.4436 13.994 23.7093 13.4697C24.9751 12.9454 26.057 12.0576 26.8181 10.9184C27.5793 9.77926 27.9856 8.43997 27.9856 7.06992C27.9856 5.23274 27.2557 3.47081 25.9567 2.17172C24.6576 0.87264 22.8956 0.142822 21.0585 0.142822ZM21.0585 2.45186C21.2872 2.45485 21.5057 2.54703 21.6674 2.70877C21.8292 2.87052 21.9213 3.08903 21.9243 3.31774V6.78129C21.9243 7.01094 21.8331 7.23118 21.6707 7.39357C21.5083 7.55595 21.2881 7.64718 21.0585 7.64718C20.8288 7.64718 20.6086 7.55595 20.4462 7.39357C20.2838 7.23118 20.1926 7.01094 20.1926 6.78129V3.31774C20.1956 3.08903 20.2877 2.87052 20.4495 2.70877C20.6112 2.54703 20.8297 2.45485 21.0585 2.45186ZM21.0585 11.3994C20.8776 11.4164 20.6952 11.3955 20.5229 11.338C20.3505 11.2805 20.1921 11.1877 20.0578 11.0654C19.9234 10.9431 19.8161 10.7941 19.7426 10.628C19.6692 10.4618 19.6313 10.2822 19.6313 10.1005C19.6313 9.91887 19.6692 9.73921 19.7426 9.57306C19.8161 9.40691 19.9234 9.25793 20.0578 9.13566C20.1921 9.0134 20.3505 8.92053 20.5229 8.86302C20.6952 8.80552 20.8776 8.78463 21.0585 8.8017C21.3818 8.83222 21.6821 8.98224 21.9007 9.22245C22.1193 9.46265 22.2405 9.77575 22.2405 10.1005C22.2405 10.4253 22.1193 10.7384 21.9007 10.9786C21.6821 11.2188 21.3818 11.3688 21.0585 11.3994Z"
          fill="#7C4DFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1944_34422">
          <rect width="27.7084" height="27.7084" fill="white" transform="translate(0.277344 0.142822)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPush;
