import React from 'react';
import { TIconProps } from '../types';

function CalendarIcon({ fill = '#000000', className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 4.25H16.75V3.5C16.75 3.086 16.414 2.75 16 2.75C15.586 2.75 15.25 3.086 15.25 3.5V4.25H8.75V3.5C8.75 3.086 8.414 2.75 8 2.75C7.586 2.75 7.25 3.086 7.25 3.5V4.25H6C3.582 4.25 2.25 5.582 2.25 8V18.5C2.25 20.918 3.582 22.25 6 22.25H18C20.418 22.25 21.75 20.918 21.75 18.5V8C21.75 5.582 20.418 4.25 18 4.25ZM6 5.75H7.25V6.5C7.25 6.914 7.586 7.25 8 7.25C8.414 7.25 8.75 6.914 8.75 6.5V5.75H15.25V6.5C15.25 6.914 15.586 7.25 16 7.25C16.414 7.25 16.75 6.914 16.75 6.5V5.75H18C19.577 5.75 20.25 6.423 20.25 8V8.75H3.75V8C3.75 6.423 4.423 5.75 6 5.75ZM18 20.75H6C4.423 20.75 3.75 20.077 3.75 18.5V10.25H20.25V18.5C20.25 20.077 19.577 20.75 18 20.75ZM9.02002 13.5C9.02002 14.052 8.57302 14.5 8.02002 14.5C7.46802 14.5 7.01489 14.052 7.01489 13.5C7.01489 12.948 7.45801 12.5 8.01001 12.5H8.02002C8.57202 12.5 9.02002 12.948 9.02002 13.5ZM13.02 13.5C13.02 14.052 12.573 14.5 12.02 14.5C11.468 14.5 11.0149 14.052 11.0149 13.5C11.0149 12.948 11.458 12.5 12.01 12.5H12.02C12.572 12.5 13.02 12.948 13.02 13.5ZM17.02 13.5C17.02 14.052 16.573 14.5 16.02 14.5C15.468 14.5 15.0149 14.052 15.0149 13.5C15.0149 12.948 15.458 12.5 16.01 12.5H16.02C16.572 12.5 17.02 12.948 17.02 13.5ZM9.02002 17.5C9.02002 18.052 8.57302 18.5 8.02002 18.5C7.46802 18.5 7.01489 18.052 7.01489 17.5C7.01489 16.948 7.45801 16.5 8.01001 16.5H8.02002C8.57202 16.5 9.02002 16.948 9.02002 17.5ZM13.02 17.5C13.02 18.052 12.573 18.5 12.02 18.5C11.468 18.5 11.0149 18.052 11.0149 17.5C11.0149 16.948 11.458 16.5 12.01 16.5H12.02C12.572 16.5 13.02 16.948 13.02 17.5ZM17.02 17.5C17.02 18.052 16.573 18.5 16.02 18.5C15.468 18.5 15.0149 18.052 15.0149 17.5C15.0149 16.948 15.458 16.5 16.01 16.5H16.02C16.572 16.5 17.02 16.948 17.02 17.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default CalendarIcon;
