import React from 'react';
import ImageSubmitted from 'assets/images/offer-submission.svg';

import { OfferAllFormValues, MerchantsRouteParams } from 'pages/MerchantPortal/merchant-portal.types';
import { useHistory, useParams } from 'react-router-dom';
import { FlightButton } from '@flybits/design-system';
import './OfferSubmitted.scss';

const MAIN_CLASS = 'offer-submitted';
const CLASSES = {
  IMAGE: `${MAIN_CLASS}__image`,
  TEXT_CONTAINER: `${MAIN_CLASS}__text-container`,
  BUTTONS_CONTAINER: `${MAIN_CLASS}__buttons`,
  BUTTON: `${MAIN_CLASS}__button`,
  EXTRA_INFO: `${MAIN_CLASS}__info`,
  BAR: `${MAIN_CLASS}__bar`,
  BAR_WRAPPER: `${MAIN_CLASS}__bar__wrapper`,
};

type OfferSubmittedProps = {
  isSubmitted: boolean;
  data: OfferAllFormValues;
  onReset: () => void;
};

function OfferSubmitted(props: OfferSubmittedProps) {
  const { isSubmitted, onReset } = props;
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();

  const goToMerchantList = () => {
    history.replace(`/project/${projectId}/merchants`);
  };
  const goToCreateOffer = () => {
    onReset();
  };
  return (
    <div className={`${MAIN_CLASS}`}>
      <div className={CLASSES.IMAGE}>
        <img src={ImageSubmitted} alt="Merchant information submitted" />
      </div>
      {!isSubmitted ? (
        <div className={CLASSES.BAR_WRAPPER}>
          <div className={CLASSES.BAR}>
            <div></div>
          </div>
        </div>
      ) : (
        <div className={CLASSES.TEXT_CONTAINER}>
          <div className={`${CLASSES.TEXT_CONTAINER}__title`}>Local Merchant Offer Submitted Successfully!</div>
          <div className={`${CLASSES.TEXT_CONTAINER}__sub-title`}>
            Your offer has been successfully submitted. The Flybits team will review your submission within 3-5 business
            days.
          </div>
          <div className={`${CLASSES.BUTTONS_CONTAINER}`}>
            <FlightButton label="Submit another offer" theme="secondary" onClick={goToCreateOffer} />
            <FlightButton label="Go to merchant list" onClick={goToMerchantList} />
          </div>
          <aside className={`${CLASSES.EXTRA_INFO}`}>
            If you need to make any changes to the submitted information, please contact the Flybits Solution Team for
            assistance.
          </aside>
        </div>
      )}
    </div>
  );
}

export default OfferSubmitted;
