import React from 'react';
import './Graphs.scss';
import { ResponsiveLine } from '@nivo/line';
import { DatumValue } from '@nivo/core';
type TGraphProps = {
  data: Serie[];
  isLegendVisible?: boolean;
  xAxis?: string;
  yAxis?: string;
  timeInterval?: 'day' | 'week' | 'millisecond' | 'second' | 'minute' | 'hour' | 'month' | 'year' | undefined;
};
interface Serie {
  id: string | number;
  data: Datum[];
  [key: string]: unknown;
}
interface Datum {
  x?: DatumValue | null;
  y?: DatumValue | null;
  [key: string]: unknown;
}
export default function StreamGraph(props: TGraphProps) {
  const { data, isLegendVisible, yAxis, timeInterval } = props;

  const renderResponsiveStream = () => (
    <ResponsiveLine
      data={data}
      animate
      margin={{ top: 20, right: 20, bottom: 50, left: 80 }}
      xScale={{
        format: '%Y-%m-%d',
        precision: timeInterval === 'week' || !timeInterval ? 'day' : timeInterval,
        type: 'time',
        useUTC: false,
      }}
      colors={{ datum: 'color' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 65,
        legendPosition: 'middle',
        format: '%b %d',
        tickValues: !timeInterval || timeInterval === 'day' ? `every 1 month` : `every 2 weeks`,
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yAxis,
        legendOffset: -70,
        legendPosition: 'middle',
        tickValues: 8,
      }}
      areaBlendMode="multiply"
      enableGridX={true}
      enableGridY={true}
      enablePoints={true}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
      xFormat="time:%Y-%m-%d"
      pointLabelYOffset={-12}
      enableArea={true}
      areaOpacity={0.2}
      isInteractive={true}
      useMesh
      legends={
        isLegendVisible
          ? [
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: -40,
                translateY: 40,
                itemsSpacing: 12,
                itemDirection: 'top-to-bottom',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : []
      }
    />
  );
  return <div className="stream-chart">{renderResponsiveStream()}</div>;
}
