import './ZonesList.scss';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FlightButton } from '@flybits/design-system';
import { ZM_LIST, ZM_LIST_CLASSES } from './constants';

import ZonesListCard from './ZonesListCard';
import { useHistory, useParams } from 'react-router-dom';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import MemoizedSlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import ZoneAttributes from '../ZoneAttributes/ZoneAttributes';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import useConfirm, { ConfirmationDialogProps } from 'hooks/useConfirm';
import IconDiscardChanges from 'components/Shared/Icons/IconDiscardChanges';
import { Zone } from 'pages/Zones/types';
import { useDispatch } from 'react-redux';
import { validateZoneAttributes } from '../zone.validators';
import { ConfirmationModalTypes } from 'components/Shared/shared.types';

const MAIN_CLASS = 'zone-list';
const CLASS_MAIN_SLIDINGSLIDEOUT_BODY = `${MAIN_CLASS}__slidingSidePanel`;

type ZonesListPageParams = {
  pid: string;
};
const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.DISCARD,
  icon: <IconDiscardChanges />,
  title: 'Remove Zone',
  description: `Removing this zone will also remove any modules within it. Once you remove the zone you can't undo it.`,
  primaryAction: {
    value: 'Remove Zone',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};
function ZonesList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pid } = useParams<ZonesListPageParams>();

  const { zones, selectedZone, addZone, removeZone, resetSelectedZone } = useContext(PrioritizationContext);

  const handleClickZone = (id?: string) => {
    if (!id) return;
    const selectedZone = zones?.find((zone) => zone.id === id);
    if (selectedZone) {
      history.push(`/project/${pid}/zones/v2/${selectedZone.id}`);
    }
  };

  const [showSlideOut, setShowSlideOut] = useState(false);
  const [slideoutApplyDisabled, setSlideoutApplyDisabled] = useState(true);

  const handleSlideoutPrimaryAction = useCallback(() => {
    addZone(selectedZone as Zone);
    dispatch({
      type: 'SHOW_SNACKBAR',
      payload: {
        content: `Your new zone, ${selectedZone?.name ?? ''}, is ready to be configured`,
        type: 'success',
      },
    });
    setShowSlideOut(false);
  }, [dispatch, addZone, selectedZone]);

  const handleSlideoutSecondaryAction = useCallback(() => {
    setShowSlideOut(false);
  }, []);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: showSlideOut,
      headerInfo: {
        mainTitle: 'Creating New Zone',
        showCloseButton: true,
      },
      footerInfo: {
        primaryActionText: 'Apply',
        primaryActionHandler: handleSlideoutPrimaryAction,
        primaryActionDisabled: slideoutApplyDisabled,
        secondaryActionText: 'Cancel',
        secondaryActionHandler: handleSlideoutSecondaryAction,
      },
    }),
    [showSlideOut, handleSlideoutPrimaryAction, handleSlideoutSecondaryAction, slideoutApplyDisabled],
  );

  const handleNewZoneButton = () => {
    resetSelectedZone();
    setShowSlideOut((show) => !show);
  };

  const [ConfirmationDialog, confirmLeave] = useConfirm(confirmationDialogProps);
  const handleRemoveZone = useCallback(
    async (zone: Zone) => {
      if (!zone) {
        return;
      }
      if (await (confirmLeave() as Promise<boolean>)) {
        removeZone(zone);
        resetSelectedZone();
      }
    },
    [removeZone, resetSelectedZone, confirmLeave],
  );

  // Check if the primary action button in slideout footer should be disabled
  useEffect(() => {
    const checkDisabled = async () => {
      const { name, refId, labels } = validateZoneAttributes(
        selectedZone?.name,
        selectedZone?.referenceID ?? '',
        selectedZone?.labels,
        selectedZone?.id,
        zones,
      );

      setSlideoutApplyDisabled(!!(name || refId || labels));
    };

    setSlideoutApplyDisabled(true);
    checkDisabled();
  }, [selectedZone, zones]);

  return (
    <>
      <div className={ZM_LIST}>
        <div className={`${ZM_LIST_CLASSES.HEADER}`}>
          <h1 className={`${ZM_LIST_CLASSES.HEADER}__title`}>Zones & Modules</h1>
          <div className={`${ZM_LIST_CLASSES.HEADER}__subtitle`}>
            <em className={`${ZM_LIST_CLASSES.HEADER}__italics`}>Zones</em> are a container that hold content within it.{' '}
            <em className={`${ZM_LIST_CLASSES.HEADER}__italics`}>Modules</em> live within zones and represent the layout
            of the content. Get started by accessing or managing your zones.
          </div>
        </div>
        <div className={`${ZM_LIST_CLASSES.CONTAINER}`}>
          <FlightButton
            theme="minor"
            label={'Add new zone'}
            className={ZM_LIST_CLASSES.ADD}
            iconLeft="add"
            onClick={handleNewZoneButton}
          />
          {zones?.map((zone) => {
            return (
              <ZonesListCard
                key={zone.id}
                zone={zone}
                onClick={() => handleClickZone(zone.id)}
                onRemove={() => handleRemoveZone(zone)}
              />
            );
          })}
        </div>

        <MemoizedSlidingSidePanel {...slidingSidePanelProps}>
          <div className={CLASS_MAIN_SLIDINGSLIDEOUT_BODY}>
            <ZoneAttributes />
          </div>
        </MemoizedSlidingSidePanel>
      </div>
      {ConfirmationDialog()}
    </>
  );
}

export default ZonesList;
