import { useState } from 'react';

import { FormData, FormDataParam as DataParam } from 'components/ExperienceCanvas/types';
import { ValidatorObject } from 'validators/ExperienceCanvas/types';

export default function useForm({
  data,
  validators,
  showErrors = false,
  isDraft = false,
}: {
  data: DataParam;
  validators?: ValidatorObject;
  //errors from store - to be melded in for rich errors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  showErrors?: boolean;
  isDraft?: boolean;
}) {
  const [formData, setFormData] = useState(_generateForm());

  function _generateForm() {
    const formObject: FormData = {};

    for (const [key, field] of Object.entries(data)) {
      formObject[key] = {
        value: field.value,
        label: field.label,
        path: field.path,
        isDirty: false,
        // can be depreciated
        showError: showErrors,
        error: '',
      };
    }
    return formObject;
  }

  // this will work to take in new fields, but to really take this to the next
  // level, consider a field factory of sorts
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateField = (key: string, value: any, showError = false) => {
    setFormData((prevForm) => {
      const newForm: FormData = {
        ...prevForm,
        [key]: {
          ...prevForm[key],
          value,
          isDirty: true,
        },
      };

      const err = validators?.[key]?.(newForm, key, isDraft);
      newForm[key].error = err?.err ?? '';
      newForm[key].showError = showError;

      return newForm;
    });
  };

  const toggleError = (key: string) => {
    setFormData((prevForm) => {
      const err = validators?.[key]?.(prevForm, key, isDraft);

      return {
        ...prevForm,
        [key]: {
          ...prevForm[key],
          showError: !!err,
          error: err?.err ?? '',
        },
      };
    });
  };

  const toggleErrors = (override: boolean) => {
    setFormData((prevForm) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newForm: { [key: string]: any } = {};

      for (const [key, value] of Object.entries(prevForm)) {
        newForm[key] = {
          ...value,
          showError: override,
        };
      }

      return newForm;
    });
  };

  const refreshData = (replacementFormData?: FormData) => {
    if (replacementFormData) setFormData(replacementFormData);
    else setFormData(_generateForm());
  };

  return {
    formData,
    updateField,
    toggleError,
    toggleErrors,
    refreshData,
  };
}
