export const MAIN_CLASS = 'schedule-editor-box';

export const CLASSES = {
  SECTION: `${MAIN_CLASS}__section`,
  SECTION_HIGHLIGHT: `${MAIN_CLASS}__section ${MAIN_CLASS}__section--is-highlighted`,
  SECTION_SECONDARY: `${MAIN_CLASS}__section ${MAIN_CLASS}__section--is-secondary`,
  FIELD: `${MAIN_CLASS}__field`,
  FIELD_RIGHT_ALIGNED: `${MAIN_CLASS}__field ${MAIN_CLASS}__field--is-right-aligned`,
  FIELD_MULTI_LINE: `${MAIN_CLASS}__field ${MAIN_CLASS}__field--is-multi-line`,
  SELECT_PLACEHOLDER: `${MAIN_CLASS}__select-placeholder`,
  REQUIRED_SYMBOL: `${MAIN_CLASS}__required-symbol`,
  DATETIME: `${MAIN_CLASS}__datetime`,
  DATETIME_LEFT_ALIGNED: `${MAIN_CLASS}__datetime ${MAIN_CLASS}__datetime--is-left-aligned`,
  RADIO_WRAPPER: `${MAIN_CLASS}__radio-wrapper`,
  CHECKBOX_LIST: `${MAIN_CLASS}__checkbox-list`,
  CHECKBOX: `${MAIN_CLASS}__checkbox`,
  TEMP_RULE_PREVIEW_LIST: `${MAIN_CLASS}__temp-rule-preview-list`,
  TEMP_RULE_PREVIEW_ITEM: `${MAIN_CLASS}__temp-rule-preview-item`,
  TEMP_RULE_PREVIEW_NUMBER: `${MAIN_CLASS}__temp-rule-preview-number`,
  RULE_PREVIEW_WRAPPER: `${MAIN_CLASS}__rule-preview-wrapper`,
  WARNING: `${MAIN_CLASS}__warning`,
  HAS_ERROR: `has-error`,
};
