export const STATUS_VERIFIED = {
  TITLE: 'Verified',
  DESCRIPTION: 'The merchant information and payment details have been successfully verified',
};
export const STATUS_PENDING = {
  TITLE: 'Pending Verification',
  DESCRIPTION: 'The merchant information and payment details are awaiting verification',
};
export const STATUS_FAILED = {
  TITLE: 'Verification Failed',
  DESCRIPTION: 'The merchant information and payment details could not be verified',
};
export const STATUS_DELETED = {
  TITLE: 'Deleted',
  DESCRIPTION: 'The merchant has been successfully deleted from both Flybits and Affinity',
};
export const STATUS_PENDING_DELETION = {
  TITLE: 'Pending Deletion',
  DESCRIPTION: 'The deletion request has been sent to Affinity and is awaiting confirmation',
};
