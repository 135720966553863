import React from 'react';
import { IconProps } from './icon.types';

function IconContent(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
    >
      <g clipPath="url(#clip0_1944_34541)">
        <path
          d="M19.6415 0.545166H8.09636C7.17777 0.545166 6.2968 0.910075 5.64726 1.55962C4.99772 2.20916 4.63281 3.09013 4.63281 4.00872V24.79C4.63281 25.7086 4.99772 26.5896 5.64726 27.2391C6.2968 27.8887 7.17777 28.2536 8.09636 28.2536H19.6415C20.5601 28.2536 21.4411 27.8887 22.0906 27.2391C22.7402 26.5896 23.1051 25.7086 23.1051 24.79V4.00872C23.1051 3.09013 22.7402 2.20916 22.0906 1.55962C21.4411 0.910075 20.5601 0.545166 19.6415 0.545166ZM20.796 22.481C20.796 22.6341 20.7352 22.7809 20.627 22.8892C20.5187 22.9974 20.3719 23.0582 20.2188 23.0582H7.5191C7.4433 23.0582 7.36823 23.0433 7.2982 23.0143C7.22816 22.9853 7.16452 22.9428 7.11092 22.8892C7.05732 22.8356 7.0148 22.7719 6.98579 22.7019C6.95678 22.6319 6.94185 22.5568 6.94185 22.481V4.58597C6.94185 4.43288 7.00266 4.28605 7.11092 4.17779C7.21918 4.06953 7.36601 4.00872 7.5191 4.00872H20.2188C20.3719 4.00872 20.5187 4.06953 20.627 4.17779C20.7352 4.28605 20.796 4.43288 20.796 4.58597V22.481Z"
          fill="#1194DA"
        />
        <path
          d="M17.9208 13.1639C17.8193 13.0172 17.6851 12.896 17.5289 12.8099C17.3726 12.7238 17.1986 12.675 17.0203 12.6675C16.843 12.6602 16.6663 12.6939 16.5042 12.766C16.342 12.8381 16.1986 12.9466 16.0851 13.0831L14.1686 15.5538L12.1136 13.9836C11.8798 13.8449 11.6045 13.7932 11.3363 13.8376C11.0681 13.8819 10.8241 14.0195 10.6474 14.2261L8.93868 16.9854C8.88525 17.0731 8.85621 17.1736 8.85457 17.2763C8.85292 17.3791 8.87873 17.4804 8.92933 17.5698C8.97993 17.6592 9.05349 17.7335 9.1424 17.7851C9.23132 17.8366 9.33237 17.8634 9.43512 17.8628H19.0638C19.1598 17.8631 19.2544 17.8395 19.339 17.7941C19.4236 17.7486 19.4955 17.6828 19.5482 17.6026C19.601 17.5223 19.6328 17.4302 19.641 17.3345C19.6491 17.2388 19.6333 17.1426 19.5949 17.0546L17.9208 13.1639Z"
          fill="#1194DA"
        />
        <path
          d="M8.97363 9.20394C8.97363 9.66324 9.15609 10.1037 9.48086 10.4285C9.80563 10.7533 10.2461 10.9357 10.7054 10.9357C11.1647 10.9357 11.6052 10.7533 11.93 10.4285C12.2547 10.1037 12.4372 9.66324 12.4372 9.20394C12.4372 8.74465 12.2547 8.30416 11.93 7.97939C11.6052 7.65462 11.1647 7.47217 10.7054 7.47217C10.2461 7.47217 9.80563 7.65462 9.48086 7.97939C9.15609 8.30416 8.97363 8.74465 8.97363 9.20394Z"
          fill="#1194DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1944_34541">
          <rect width="27.7084" height="27.7084" fill="white" transform="translate(0.0146484 0.545166)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconContent;
