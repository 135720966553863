import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FlightTextInput, FlightButton, FlightTag } from '@flybits/design-system';
import './DeveloperHubIndex.scss';

export default function DeveloperHubIndex() {
  const history = useHistory();
  const [token, setToken] = useState('');
  const [path, setPath] = useState('templated-experiences/template-selection');
  const [origin, setOrigin] = useState('https://www.google.com');

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'development') {
      history.replace('/');
    }
  });

  function onRedirectClick() {
    const route = `redirect?path=${encodeURIComponent(path)}&token=${token}&origin=${encodeURIComponent(origin)}`;
    history.replace(route);
  }

  return (
    <div className="developer-hub">
      <h2> Developer Hub </h2>
      <FlightTag label="Developer Mode" type="info" />
      <div className="developer-hub__section">
        <h3>
          {' '}
          <u> Redirect Endpoint Simulator </u>{' '}
        </h3>
        <small>
          <p> This is a tool to simulate /redirect route. </p>
        </small>
        <FlightTextInput
          label="Token (Development Token w/o quotations)"
          width="100%"
          value={token}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => setToken(e.target.value)}
        />
        <FlightTextInput
          label="Origin (Ember url: Link to redirect to after creating templated experience)"
          width="100%"
          value={origin}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => setOrigin(e.target.value)}
        />
        <FlightTextInput
          label="Path (React path)"
          width="100%"
          value={path}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => setPath(e.target.value)}
        />
        <div>
          <b> Generated URL </b>
          <p>
            {' '}
            localhost:3000/redirect?path=<u>{encodeURIComponent(path)}</u>&token=<u>{token}</u>&origin=
            <u>{encodeURIComponent(origin)}</u>{' '}
          </p>
        </div>
        <FlightButton onClick={onRedirectClick} label="Redirect" />
      </div>
    </div>
  );
}
