import analyticsContentEngagementBySourceReducer from 'store/analytics/content-engagement-by-source/content-engagement-by-source.reducer';
import analyticsContentEngagementReducer from 'store/analytics/content-engagement/content-engagement.reducer';
import analyticsContentPerformanceReducer from 'store/analytics/content-performance/content-performance.reducer';
import analyticsFunnelReducer from 'store/analytics/experience-funnel/experience-funnel.reducer';
import analyticsPerformanceReducer from 'store/analytics/experience-performance/experience-performance.reducer';
import approvalReducer from './approval/approval.reducer';
import auditReducer from 'store/settings/audit/audit.reducer';
import authReducer from 'store/auth/auth.reducer';
import { combineReducers } from 'redux';
import contentReducer from 'store/content/content.reducer';
import featureFlagReducer from 'store/feature-flag/feature-flag.reducer';
import journeyReducer from 'store/journey/journey.reducer';
import locationReducer from 'store/location/location.reducer';
import pluginReducer from 'store/plugin/plugin.reducer';
import pushReducer from 'store/push/push.reducer';
import { routerReducer } from 'react-router-redux';
import ruleReducer from 'store/rule/rule.reducer';
import settingsReducer from 'store/settings/settings.reducer';
import snackbarReducer from 'store/snackbar/snackbar.reducer';
import teContentReducer from 'store/content/te.content.reducer';
import templatedExperienceReducer from 'store/templated-experience/templated-experience.reducer';
import webhookReducer from './webhook/webhook.reducer';

export default combineReducers({
  auth: authReducer,
  audit: auditReducer,
  approval: approvalReducer,
  plugin: pluginReducer,
  location: locationReducer,
  content: contentReducer,
  snackbar: snackbarReducer,
  templatedExperience: templatedExperienceReducer,
  featureFlag: featureFlagReducer,
  settings: settingsReducer,
  router: routerReducer,
  analytics: combineReducers({
    analyticsFunnel: analyticsFunnelReducer,
    analyticsPerformance: analyticsPerformanceReducer,
    analyticsContentEngagement: analyticsContentEngagementReducer,
    analyticsContentPerformance: analyticsContentPerformanceReducer,
    analyticsContentEngagementBySource: analyticsContentEngagementBySourceReducer,
  }),
  te: combineReducers({
    journey: journeyReducer,
    push: pushReducer,
    content: teContentReducer,
    rule: ruleReducer,
    webhook: webhookReducer,
  }),
});
