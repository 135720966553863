import React from 'react';
import { Attachment } from 'interface/approval/approval.interface';
import { FlightButton, FlightModal } from '@flybits/design-system';

import './GalleryModal.scss';

// TODO: turn into carousel - single item for now.
interface TGalleryModalProps {
  isAuthor?: boolean;
  attachment: Attachment | null;
  isVisible: boolean;
  onCancel: () => void;
  onRemove: (id?: string) => void;
}

const MAIN_CLASS = 'gallery-modal';

const GalleryModal: React.FC<TGalleryModalProps> = ({
  isAuthor = false,
  attachment,
  isVisible,
  onCancel,
  onRemove,
}) => {
  return (
    <FlightModal
      size="large"
      isVisible={isVisible}
      toggleModalShown={onCancel}
      content={
        <div className={MAIN_CLASS}>
          <h3>{attachment?.friendlyName}</h3>
          <img src={attachment?.url} alt={attachment?.friendlyName} />
          {isAuthor && (
            <FlightButton
              onClick={() => onRemove(attachment?.fileId)}
              iconRight="trashCan"
              theme="minor"
              ariaLabel="delete attachment"
            />
          )}
        </div>
      }
    />
  );
};

export default GalleryModal;
