import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchContentInstancesThunk } from 'store/content/content.thunk';

export default function useContentInstances() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contentInstances = useSelector((state: any) => state.content.contentInstances);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContentInstancesThunk());
  }, [dispatch]);

  return {
    contentInstances,
  };
}
