import React, { useState } from 'react';
import { getIcon } from '@flybits/design-system';
import { ReactComponent as DragIcon } from 'pages/Zones/assets/icons/icon-drag.svg';
import './ModuleListItem.scss';

type TModuleListItem = {
  index: number;
  listLength: number;
  name: string;
  onChangePosition: (oldIndex: number, newIndex: number) => void;
  onDragStart: (index: number) => void;
  onDragEnd: () => void;
  showDropzone?: boolean;
};

const MAIN_CLASS = 'module-list-item';
const CLASSES = {
  LIGHT_BACKGROUND: `${MAIN_CLASS} ${MAIN_CLASS}--is-light`,
  DARK_BACKGROUND: `${MAIN_CLASS} ${MAIN_CLASS}--is-dark`,
  DRAGGING: `${MAIN_CLASS}--is-dragging`,
  DRAG_ICON: `${MAIN_CLASS}__drag-icon`,
  INDEX: `${MAIN_CLASS}__index`,
  NAME: `${MAIN_CLASS}__name`,
  MOVE_ACTIONS: `${MAIN_CLASS}__move-actions`,
  DRAG_DESCRIPTION: `${MAIN_CLASS}__drag-description`,
  DROPZONE: `${MAIN_CLASS}__dropzone`,
  DROPZONE_TOP: `${MAIN_CLASS}__dropzone ${MAIN_CLASS}__dropzone--is-top`,
  DROPZONE_DRAGGING: `${MAIN_CLASS}__dropzone--is-dragging`,
};

const ModuleListItem: React.FC<TModuleListItem> = ({
  index,
  listLength,
  name,
  onChangePosition,
  onDragStart,
  onDragEnd,
  showDropzone = false,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.dataTransfer.setData('text/plain', index.toString());
    setIsDragging(true);
    onDragStart(index);
  };

  const handleDragEnd = () => {
    onDragEnd();
    setIsDragging(false);
  };

  const handleMoveUpClick = () => {
    onChangePosition(index, index - 1);
  };

  const handleMoveDownClick = () => {
    onChangePosition(index, index + 1);
  };

  const handleDrop = (evt: React.DragEvent<HTMLDivElement>, position: 'top' | 'bottom') => {
    evt.preventDefault();
    const oldIndex = +evt.dataTransfer.getData('text/plain');
    let newIndex;

    if (position === 'bottom') newIndex = index;
    else newIndex = index - 1;
    if (newIndex < oldIndex) newIndex = newIndex + 1;

    onChangePosition(oldIndex, newIndex);
  };

  const handleDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
  };

  return (
    <div
      className={`${index % 2 !== 0 ? `${CLASSES.LIGHT_BACKGROUND}` : `${CLASSES.DARK_BACKGROUND}`}${
        isDragging ? ` ${CLASSES.DRAGGING}` : ''
      }`}
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {showDropzone && index === 1 && (
        <div className={CLASSES.DROPZONE_TOP} onDrop={(evt) => handleDrop(evt, 'top')} onDragOver={handleDragOver} />
      )}
      <div className={CLASSES.DRAG_ICON}>
        <DragIcon />
      </div>
      <div className={CLASSES.INDEX}>{index}</div>
      <div className={CLASSES.NAME}>{name}</div>
      <div className={CLASSES.MOVE_ACTIONS}>
        <button type="button" onClick={handleMoveUpClick} disabled={index === 1}>
          {getIcon('baselineKeyboardArrowUp', {})} Move Up
        </button>
        <button type="button" onClick={handleMoveDownClick} disabled={index === listLength}>
          {getIcon('baselineKeyboardArrowDown', {})} Move Down
        </button>
      </div>
      <div className={CLASSES.DRAG_DESCRIPTION}>Drag to desired position</div>
      {showDropzone && (
        <div className={CLASSES.DROPZONE} onDrop={(evt) => handleDrop(evt, 'bottom')} onDragOver={handleDragOver} />
      )}
    </div>
  );
};

export default ModuleListItem;
