// a wrapper for simple form that takes a list of localizations
import { useState, useEffect, useRef } from 'react';
import useForm from 'hooks/useSimpleForm';
import { FormData, FormDataParam as DataParam } from 'components/ExperienceCanvas/types';
import { ValidatorObject } from 'validators/ExperienceCanvas/types';

type Storage = {
  [key: string]: FormData;
};

export default function useLocalizedSimpleForm({
  data,
  validators,
  errors,
  lang,
  defaultLang,
  showErrors = false,
}: {
  data: DataParam;
  validators: ValidatorObject;
  lang: string;
  //errors from store
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any;
  defaultLang: string;
  showErrors?: boolean;
}) {
  const storage = useRef<Storage>({});
  const [activeLang, setActiveLang] = useState(lang);
  const [isDraft, setIsDraft] = useState(lang !== defaultLang);
  const activeForm = useForm({ data, validators, errors, showErrors, isDraft });

  useEffect(() => {
    // save current form
    if (activeLang) {
      storage.current[activeLang] = { ...activeForm.formData };
    }
    // pull old localization data if it exists, else, create a new form
    if (storage.current[lang]) {
      activeForm.refreshData(storage.current[lang]);
    } else {
      activeForm.refreshData();
    }
    setActiveLang(lang);
    setIsDraft(lang !== defaultLang);

    // we can look into memoization of the useSimpleForm hook later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return {
    formData: activeForm.formData,
    updateField: activeForm.updateField,
    toggleError: activeForm.toggleError,
    toggleErrors: activeForm.toggleErrors,
    refreshData: activeForm.refreshData,
  };
}
