// Audience Constraints Box
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ForbiddenIcon from 'assets/icons/forbidden.svg';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useLocations from 'hooks/useLocations';
import usePlugins from 'hooks/useContextPlugins';
import useContentInstances from 'hooks/useContentInstances';
import useJourneyStatus from 'hooks/useJourneyStatus';
import {
  RuleContainer,
  RulePreview,
  RuleBuilderLocationPayload,
  RuleBuilderSaveLocationStatus,
  RuleBuilderContentAnalyticsProps,
} from '@flybits/webapp-react-rule-builder';
import { FlightButton, FlightRadioButton, FlightTooltip } from '@flybits/design-system';
import { selectStepRule, getIsAudienceEmpty, getStepRuleRefIds } from 'store/rule/rule.selector';
import { serializeRule } from 'store/serializers';

import { BROADCASTABLE_TEMPLATES } from 'components/ExperienceCanvas/types';
import { EVENT_KEYS } from 'types/events';
import { TTimelineBoxProps } from 'pages/ExperienceCanvas/types';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';

import AudienceIcon from 'pages/ExperienceCanvas/assets/icons/AudienceIcon';
import TriggerIcon from 'pages/ExperienceCanvas/assets/icons/TriggerIcon';
import TouchpointEditorCard from 'components/Shared/TouchpointEditorCard/TouchpointEditorCard';

import { fetchMatchingLocationsWithLabelsThunk, saveLocationThunk } from 'store/location/location.thunk';
import { saveRulesThunk } from 'store/rule/rule.thunk';
import { RuleBuilderAudience } from 'interface/rule/rule.interface';
import { SaveRuleAction } from 'store/actionTypes';
import { getRuleErrorList } from 'validators/ExperienceCanvas/rule.validator';
import useContentAnalytics, { useContentAnalyticsComponents } from 'hooks/useContentAnalytics';

import LabelsAPI from 'services/api/labels.api';
import PluginAPI from 'services/api/plugin.api';
import './TouchpointTimelineBox.scss';

const DEFAULT_CLASS = 'audience-viewer';

type TTargetAudienceTypes = {
  key: string;
  label: string;
  isDisabled: boolean;
};

export default function AudienceConstraintsBox({ stepIdx, timelineIndex }: TTimelineBoxProps) {
  const labelsAPI = useMemo(() => new LabelsAPI(), []);
  const pluginAPI = useMemo(() => new PluginAPI(), []);

  const { isJourneyLocked, hasActivationDate } = useJourneyStatus();
  const templateType = useSelector((state) => state.te.journey.templateType);
  const isAOEnabled = useSelector(
    (state) =>
      !!state.te.journey?.audienceOptimizationEligible ||
      !!state.te.journey?.audienceOptimization?.audienceOptimizationEligible,
  );
  const { primaryRuleType, isProductionAudience, omitRule, hasError } = useSelector((state) => {
    const primaryRule = selectStepRule(state.te, stepIdx);
    const omitRule = primaryRule ? !primaryRule.enableOptionalNode : undefined;
    // TODO: replace this with getAudienceErrors (to be implemented)
    const errors = Object.values(getStepRuleRefIds(state.te, stepIdx))
      .map((ruleRefId) => getRuleErrorList(state, ruleRefId, primaryRule?.refId === ruleRefId && !omitRule))
      .flat();

    return {
      primaryRuleType: primaryRule?.ruleType,
      isProductionAudience: !!primaryRule?.visibility && primaryRule.visibility !== 'draft',
      omitRule,
      hasError: !!state.te.journey.steps[stepIdx]?.isDirty && !!errors.length,
    };
  });
  const isAudienceEmpty = useSelector((state) => getIsAudienceEmpty(state.te, stepIdx, true));

  const shouldSyncStartScheduleWithRuleBody = useSelector((state) => {
    const step = state.te.journey.steps[stepIdx];

    return step?.constraints?.audienceConstraints?.syncStartScheduleWithRuleBody ?? false;
  });
  const [selectedAudienceType, setSelectedAudienceType] = useState(
    omitRule || omitRule === undefined ? 'none' : 'rule',
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRuleBuilderOpen, setIsRuleBuilderOpen] = useState(false);
  const [activeRuleTab, setActiveRuleTab] = useState(primaryRuleType ?? 'restricted');
  const canEditRule = selectedAudienceType !== 'none' && !isJourneyLocked && !hasActivationDate;

  const te = useSelector((state) => state.te);

  const memoizedActiveRuleJSON = useMemo(() => {
    const stepRuleRefId = getStepRuleRefIds(te, stepIdx)[activeRuleTab];
    const serializedRule = serializeRule(
      te.rule.byRefId[stepRuleRefId],
      undefined,
      undefined,
      undefined,
      undefined,
      stepIdx,
      activeRuleTab,
      true,
    );

    return serializedRule;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRuleBuilderOpen, activeRuleTab, te, stepIdx]);

  const {
    locations,
    areLocationsLoading: areLocationsWaiting,
    numMatchingLocations,
    areMatchingLocationsLoading,
    isLocationSaving,
  } = useLocations();
  const { plugins } = usePlugins();
  const { contentInstances } = useContentInstances();
  const [contentInstanceSearchVal, setContentInstanceSearchVal] = useState('');
  const {
    isLoading,
    isFetchingNextPage,
    contentInstances: contentAnalyticsSuggestionList,
    fetchNextContentPage,
    isError,
    serverErrorMessage,
  } = useContentAnalytics(contentInstanceSearchVal);
  const [componentHookProps, setComponentHookProps] = useState({
    contentId: '',
    uid: '',
    nodeId: '',
  });
  const { isLoading: areComponentsLoading, ...componentsObject } = useContentAnalyticsComponents(
    componentHookProps.contentId,
    componentHookProps.uid,
  );

  const dispatch = useDispatch();
  const [waitingNodeId, setWaitingNodeId] = useState('');
  const [areLocationsLabelsWaiting, setAreLocationsLabelsWaiting] = useState({
    waitingNodeId: '',
    isWaiting: false,
  });
  const [matchingLocations, setMatchingLocations] = useState({
    matchingLocationsNodeId: '',
    numMatchingLocations: 0,
  });

  useEffect(() => {
    setAreLocationsLabelsWaiting({
      waitingNodeId,
      isWaiting: areMatchingLocationsLoading,
    });

    setMatchingLocations({
      matchingLocationsNodeId: waitingNodeId,
      numMatchingLocations,
    });
  }, [waitingNodeId, numMatchingLocations, areMatchingLocationsLoading]);

  const [contextLabels, setContextLabels] = useState<string[]>([]);
  const [historicalQueries, setHistoricalQueries] = useState([]);

  useEffect(() => {
    // ToDo
    // Move this to a custom hook so that it can used across components
    // Also add error handling for working with context labels api
    (async () => {
      const { data: labels = [] } = await labelsAPI.getLabels();
      setContextLabels(labels);
      const { data } = await pluginAPI.getAllHistoricalPlugins();
      setHistoricalQueries(data);
    })();
  }, [labelsAPI, pluginAPI]);

  const handleLocationLabelSelection = async (nodeId: string, labelsFormula: string) => {
    setWaitingNodeId(nodeId);

    dispatch(fetchMatchingLocationsWithLabelsThunk(labelsFormula));
  };

  const handleSaveLocation = (
    locationPayload: RuleBuilderLocationPayload,
    callback: (saveStatusInfo?: RuleBuilderSaveLocationStatus) => void,
    locationId?: string,
  ) => {
    dispatch(saveLocationThunk(locationPayload, callback, locationId));
  };

  const [isSerializeUiModel, setIsSerializeUiModel] = useState(false);

  const handleSaveRules = useCallback(() => {
    setIsSerializeUiModel(true);
  }, []);

  const saveRuleBuilderPredicates = useCallback(
    (currentRule: RuleBuilderAudience) => {
      if (!currentRule) {
        return;
      }

      const saveRulePayload: SaveRuleAction['payload'] = {
        templateId: currentRule.templateId,
        refId: currentRule.refId,
        id: currentRule.id,
        rulePayload: currentRule.payload,
        type: activeRuleTab,
        templateType: templateType ?? '',
        stepIdx,
        shouldSyncStartScheduleWithRuleBody,
      };

      dispatch(saveRulesThunk(saveRulePayload));

      setIsRuleBuilderOpen(false);
      setIsSerializeUiModel(false);
    },
    [activeRuleTab, dispatch, templateType, stepIdx, shouldSyncStartScheduleWithRuleBody],
  );

  const handleDismissRuleBuilder = useCallback(() => {
    setIsRuleBuilderOpen(false);
    setIsSerializeUiModel(false);
  }, []);

  const slidingSidePanelProps: TSlidingSidePanelProps = {
    show: isRuleBuilderOpen,
    headerInfo: {
      mainTitle: 'Rule Builder',
      goBackIcon: <TriggerIcon fill="#ffffff" />,
      goBackTitle: 'Triggers',
      goBackSubTitle: 'Set up the experience',
      goBackActionHandler: handleDismissRuleBuilder,
    },
    footerInfo: {
      primaryActionText: 'Save',
      secondaryActionText: 'Cancel',
      primaryActionHandler: handleSaveRules,
      secondaryActionHandler: handleDismissRuleBuilder,
    },
  };

  const isAnyAudienceDisabled = useSelector((state) => {
    const stepRule = selectStepRule(state.te, stepIdx);

    return stepRule !== undefined && stepRule.isOptional !== undefined && !stepRule.isOptional;
  });
  const targetAudienceTypes = useSelector((state) => {
    const templateType = state.te.journey.templateType ?? 'broadcast';
    const optionsArr: TTargetAudienceTypes[] = [];

    if (BROADCASTABLE_TEMPLATES.includes(templateType) && stepIdx === 0) {
      optionsArr.push({
        key: 'none',
        isDisabled: isAnyAudienceDisabled,
        label: 'Everyone',
      });
    }

    optionsArr.push({
      key: 'rule',
      isDisabled: false,
      label: 'Target Audience',
    });

    return optionsArr;
  });

  // actually only necessary because of race when loading on the page
  // not necessary if we block rendering of touchpoint if state isn't ready yet
  useEffect(() => {
    setSelectedAudienceType((prev) => (omitRule === undefined ? prev : omitRule ? 'none' : 'rule'));
  }, [omitRule]);

  const handleAudienceTypeSelection = (key: string) => {
    if (selectedAudienceType === key) return;
    setSelectedAudienceType(key);
    // only set to empty if it DNE, rather than if it has a refId but is empty
    const isEmptyRule = isAudienceEmpty;

    if (key === 'none') {
      dispatch({ type: 'UPDATE_RULE_TRIGGER', payload: { stepIdx, type: 'omit' } });
    } else if (key === 'rule') {
      dispatch({ type: 'UPDATE_RULE_TRIGGER', payload: { stepIdx, omitRule: 'default', isEmptyRule } });
    }
  };

  const isCurrentSelectionWithEmptyRule = !memoizedActiveRuleJSON?.payload?.logic;

  const handleEditActiveAudience = useCallback(() => {
    if (canEditRule) setIsRuleBuilderOpen((prevState) => !prevState);
  }, [canEditRule, setIsRuleBuilderOpen]);

  const handleContentSearch = useCallback((searchVal: string) => {
    setContentInstanceSearchVal(searchVal);
  }, []);

  const handleComponentsDropdownFocus = useCallback((nodeId: string, contentId: string, uid: string) => {
    setComponentHookProps({ contentId, uid, nodeId });
  }, []);

  const memoizedContentAnalyticsProps: RuleBuilderContentAnalyticsProps = useMemo(
    () => ({
      contentData: {
        data: {
          options: contentAnalyticsSuggestionList,
          width: '600px',
          ariaControlsId: 'contentAnalyticsSuggestionBox',
          noDataAvailable: false,
          loading: isLoading || isFetchingNextPage,
          disabled: false,
          isServerError: isError,
          clearOnClickOutside: true,
          content: {
            errorMessage: 'Select content instance from dropdown',
            serverErrorMsg: {
              text: serverErrorMessage || 'Could not load content',
            },
          },
        },
        methods: {
          handleContentSearch,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleContentOptionClick: () => {},
          fetchNextContentPage,
        },
      },
      componentData: {
        nodeId: componentHookProps.nodeId,
        data: {
          options: componentsObject.data,
          width: '600px',
          ariaControlsId: 'contentAnalyticsComponentsSuggestionBox',
          noDataAvailable: false,
          loading: areComponentsLoading,
          disabled: false,
          isServerError: componentsObject.isError,
          clearOnClickOutside: true,
          content: {
            errorMessage: 'Select component from dropdown',
            serverErrorMsg: {
              text: 'Could not load content',
            },
          },
        },
        methods: {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleComponentSearch: () => {},
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleComponentOptionClick: () => {},
        },
      },
      handleComponentsDropdownFocus,
    }),
    [
      contentAnalyticsSuggestionList,
      handleContentSearch,
      fetchNextContentPage,
      isError,
      serverErrorMessage,
      isLoading,
      isFetchingNextPage,
      componentHookProps,
      componentsObject,
      areComponentsLoading,
      handleComponentsDropdownFocus,
    ],
  );

  return (
    <>
      <TouchpointEditorCard
        title="Audience Constraints"
        subtitle="The following are constraints for your audience segmentation to ensure a personalized experience:"
        icon={AudienceIcon}
        timelineIndex={timelineIndex}
        lockedMessage={
          isJourneyLocked ? 'You cannot modify an active experience.' : 'Once-active audiences cannot be edited.'
        }
        isLocked={isJourneyLocked || hasActivationDate || isProductionAudience}
        hasError={hasError}
      >
        <div className={DEFAULT_CLASS}>
          <div className={`${DEFAULT_CLASS}__audience-type`}>
            <span className={`${DEFAULT_CLASS}__audience-type__option`}>Applies to:</span>
            {targetAudienceTypes.map((type, i) => (
              <FlightRadioButton
                key={`target-audience-type-${i}`}
                className={`${DEFAULT_CLASS}__audience-type__option`}
                label={type.label}
                disabled={type.isDisabled}
                onSelect={handleAudienceTypeSelection}
                value={type.key}
                checked={selectedAudienceType === type.key}
              />
            ))}
          </div>
          {selectedAudienceType !== 'none' && (
            <>
              <div className={`${DEFAULT_CLASS}__tabs`}>
                <FlightTooltip
                  description="The user that you want to target. For example, a customer that is at home and stationary, with their phone plugged in and connected to Wi-Fi."
                  direction="bottom"
                >
                  <div
                    className={`${DEFAULT_CLASS}__tab ${activeRuleTab === 'restricted' ? 'selected' : ''}`}
                    onClick={() => setActiveRuleTab('restricted')}
                    aria-label={'show restricted rules'}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key !== EVENT_KEYS.ENTER) return;
                      setActiveRuleTab('restricted');
                    }}
                  >
                    Restricted
                    {canEditRule && (
                      <FlightButton
                        className={`${DEFAULT_CLASS}__tab__edit-restricted-audience-icon`}
                        iconLeft="pencil"
                        theme="minor"
                        ariaLabel="edit audience constraints"
                        onClick={() => {
                          setIsRuleBuilderOpen((prevState) => !prevState);
                        }}
                        key="edit"
                      />
                    )}
                  </div>
                </FlightTooltip>

                <FlightTooltip
                  description={
                    isAOEnabled
                      ? 'Ensures the most relevant users see the content in their feeds; smart targeting optimizes the audience as data comes in.'
                      : 'Currently disabled due to smart targeting being inactive.'
                  }
                  direction="bottom"
                >
                  <div
                    className={`${DEFAULT_CLASS}__tab
                    ${isAOEnabled ? '' : `${DEFAULT_CLASS}__tab--disabled`} ${
                      activeRuleTab === 'preferred' ? 'selected' : ''
                    }`}
                    onClick={() => setActiveRuleTab('preferred')}
                    aria-label={'show preferred rules'}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key !== EVENT_KEYS.ENTER) return;
                      setActiveRuleTab('preferred');
                    }}
                  >
                    Preferred
                    {canEditRule && (
                      <FlightButton
                        className={`${DEFAULT_CLASS}__tab__edit-preferred-audience-icon`}
                        iconLeft="pencil"
                        theme="minor"
                        ariaLabel="edit audience constraints"
                        onClick={() => {
                          setIsRuleBuilderOpen((prevState) => !prevState);
                        }}
                        key="edit"
                      />
                    )}
                  </div>
                </FlightTooltip>
              </div>
              <div
                className={`${DEFAULT_CLASS}__tab-content${canEditRule ? '' : '--disabled'}`}
                aria-label={`showing ${activeRuleTab} audience constraints, click to edit`}
                tabIndex={0}
                role="button"
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key !== EVENT_KEYS.ENTER) return;
                  handleEditActiveAudience();
                }}
              >
                {!isCurrentSelectionWithEmptyRule && (
                  <RulePreview
                    className={`${DEFAULT_CLASS}__preview`}
                    responseJSON={memoizedActiveRuleJSON}
                    locations={locations}
                    contextPlugins={plugins}
                    historicalQueries={historicalQueries}
                  />
                )}

                {activeRuleTab === 'preferred' && isCurrentSelectionWithEmptyRule && (
                  <div className={`${DEFAULT_CLASS}__empty-audience-container`}>
                    <img src={ForbiddenIcon} alt="No audience icon" />
                    <strong>Set up preferred audience constraints</strong>
                    <p>
                      Ensures relevant user see the content. Smart targeting feature may offer altercations to optimize
                      engagement.
                    </p>
                    <FlightButton
                      iconLeft="add"
                      theme="primary"
                      ariaLabel="edit audience constraints"
                      label={'Add rules'}
                      onClick={() => {
                        handleEditActiveAudience();
                      }}
                      key="edit"
                    />
                  </div>
                )}

                {activeRuleTab === 'restricted' && isCurrentSelectionWithEmptyRule && (
                  <div className={`${DEFAULT_CLASS}__empty-audience-container`}>
                    <img src={ForbiddenIcon} alt="No audience icon" />
                    <strong>Set up restricted audience constraints</strong>
                    <p>Ensures relevant user see the content.</p>
                    <FlightButton
                      iconLeft="add"
                      theme="primary"
                      ariaLabel="edit audience constraints"
                      label={'Add rules'}
                      onClick={() => {
                        handleEditActiveAudience();
                      }}
                      key="edit"
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </TouchpointEditorCard>
      <SlidingSidePanel {...slidingSidePanelProps}>
        <RuleContainer
          isSerializeUiModel={isSerializeUiModel}
          serializeUiModel={saveRuleBuilderPredicates}
          responseJSON={memoizedActiveRuleJSON}
          contextPlugins={plugins}
          contentInstances={contentInstances}
          contextLabels={contextLabels}
          locationProps={{
            locations,
            googleMapAPIKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
            areLocationsWaiting,
            areLocationsLabelsWaiting,
            matchingLocations,
            onLocationLabelSelection: handleLocationLabelSelection,
            isLocationSaving,
            onSaveLocation: handleSaveLocation,
            hideAddNewLocationCTA: false,
          }}
          historicalQueries={historicalQueries}
          contentAnalyticsProps={memoizedContentAnalyticsProps}
          expandAllConditions
        />
      </SlidingSidePanel>
    </>
  );
}
