import React, { useCallback, useEffect, useState } from 'react';
import './Engagement.scss';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { MAIN_CLASS } from '../constants';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import {
  FlightButton,
  FlightCheckbox,
  FlightDatePicker,
  FlightModal,
  FlightRadioButton,
  FlightTextInput,
} from '@flybits/design-system';
import { isEmailValid } from 'validators/ExperienceCanvas/common.validator';
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';

interface ExportData {
  start: string | number | Date;
  end: string | number | Date;
  tablesIncluded: string[];
  format: string;
  email: string;
}
const maxDate = new Date();
maxDate.setHours(0, 0, 0, 0);
const minDate = new Date();
minDate.setHours(0, 0, 0, 0);
minDate.setDate(minDate.getDate() - 6);
const initialData = {
  start: minDate,
  end: 0,
  tablesIncluded: [],
  format: 'CSV',
  email: '',
};
const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
const ExportEngagement: React.FC = () => {
  const { flags } = useFeatureFlag();
  const dispatch = useDispatch();
  const [showExportModal, setShowExportModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState<ExportData>(initialData);

  const { start, end, tablesIncluded, format, email } = exportData;
  const maxToDate = useCallback(() => {
    if (!start) return maxDate;
    const maxToDate = new Date(start);
    // Allow max 7 days to export, last day inclusive
    maxToDate.setDate(maxToDate.getDate() + 6);
    return maxToDate < maxDate ? maxToDate : maxDate;
  }, [start]);
  useEffect(() => {
    updateFields('end', maxToDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);
  if (!flags['tx_show_analytics_engagement']) return false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateFields = (field: string, data: any) => {
    setExportData((old) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let normalized: any = data;
      if (field === 'tablesIncluded') {
        normalized = tablesIncluded;
        if (!normalized.includes(data)) {
          normalized.push(data);
        } else {
          normalized.splice(
            normalized.findIndex((item: string) => item === data),
            1,
          );
        }
      }
      return {
        ...old,
        [field]: normalized,
      };
    });
  };
  const handleExport = async () => {
    setIsLoading(true);
    const endDate = new Date(end);
    endDate.setHours(23, 59, 59);
    try {
      await templatedExperienceAnalyticsAPI.exportData({
        startTime: new Date(start).getTime() / 1000,
        endTime: endDate.getTime() / 1000,
        dataTypes: tablesIncluded,
        email: email.trim(),
        dataFormat: format,
        logLevel: 'info',
      });
      // api call
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Export successful',
          content: `Data will be sent to '${email}'`,
          type: 'success',
        },
      });
      closeExportModal();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // show snackbar
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Problem exporting data',
          content: `${error.response.data.error.exceptionMessage || error.message}`,
          type: 'error',
        },
      });
    }
    setIsLoading(false);
  };
  const closeExportModal = () => {
    setExportData(initialData);
    setShowExportModal(false);
  };
  return (
    <>
      <FlightModal
        className={MAIN_CLASS}
        isVisible={showExportModal}
        size={'medium'}
        toggleModalShown={closeExportModal}
        header={<div>Export engagements</div>}
        content={
          <div>
            <h3>Date range:</h3>
            <div className={`${MAIN_CLASS}-export-section`}>
              <FlightDatePicker
                className="audit__content__header__date-picker"
                selected={start}
                maxDate={maxDate}
                disabled={isLoading}
                onSelect={(e: string) => {
                  updateFields('start', e);
                }}
              />{' '}
              to{' '}
              <FlightDatePicker
                className="audit__content__header__date-picker"
                selected={end}
                maxDate={maxToDate()}
                minDate={start}
                disabled={isLoading}
                onSelect={(e: string) => {
                  updateFields('end', e);
                }}
              />
            </div>
            <h3>Tables to export:</h3>
            <FlightCheckbox
              key={'push'}
              checkState={tablesIncluded.includes('Push') ? 'SELECTED' : 'UNSELECTED'}
              onSelect={() => updateFields('tablesIncluded', 'Push')}
              disabled={isLoading}
              label={'Push Notifications'}
            />
            <FlightCheckbox
              key={'context'}
              checkState={tablesIncluded.includes('Rules') ? 'SELECTED' : 'UNSELECTED'}
              onSelect={() => updateFields('tablesIncluded', 'Rules')}
              disabled={isLoading}
              label={'Context Rules'}
            />
            <FlightCheckbox
              key={'content'}
              checkState={tablesIncluded.includes('Content') ? 'SELECTED' : 'UNSELECTED'}
              onSelect={() => updateFields('tablesIncluded', 'Content')}
              disabled={isLoading}
              label={'Content'}
            />
            <h3>Export format:</h3>
            <FlightRadioButton
              label="CSV"
              value={'CSV'}
              onSelect={() => updateFields('format', 'CSV')}
              disabled={isLoading}
              checked={format === 'CSV'}
            />
            <FlightRadioButton
              label="JSON"
              value={'JSON'}
              onSelect={() => updateFields('format', 'JSON')}
              disabled={isLoading}
              checked={format === 'JSON'}
            />
            <h3>Send export to:</h3>
            <FlightTextInput
              isAriaRequired
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFields('email', e.target.value)}
              placeholderText="Enter email"
              hasError={!email || !isEmailValid(email.trim())}
              errorMessage={`A valid email is required`}
              value={email}
              disabled={isLoading}
              width="100%"
            />
          </div>
        }
        footer={
          <div className={`${MAIN_CLASS}-export-section__footer`}>
            <FlightButton label="Cancel" theme="secondary" onClick={closeExportModal} disabled={isLoading} />
            <FlightButton
              label="Export"
              onClick={handleExport}
              disabled={!tablesIncluded.length || !start || !end || !email || !isEmailValid(email.trim()) || isLoading}
              loading={isLoading}
            />
          </div>
        }
      />
      <section className={`analytics-overview__bottom-side__graphs__module-layout`}>
        <FlightButton
          theme="primary"
          label={'Export engagements'}
          onClick={() => setShowExportModal(true)}
          onKeyDown={() => setShowExportModal(true)}
        />
      </section>
    </>
  );
};

export default ExportEngagement;
