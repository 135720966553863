import { RootState } from 'store/store';
import { getBackendJourneyErrors } from 'helpers/error.helper';
import { getTemplateConstraint, getSchemaConstraint } from 'helpers/content.helper';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { JourneyError } from 'validators/ExperienceCanvas/types';

export function getContentErrorList(state: RootState, refId: string, checkJourneyErrors = true): JourneyError[] {
  const contentState = state.te.content;

  const content = contentState.byRefId[refId];
  const templateConstraint = getTemplateConstraint(content) || getSchemaConstraint(content);

  const retVal: JourneyError[] = [];

  if (!content) retVal.push({ type: ERROR_TYPES.ERROR, err: 'Fatal Content Error' });
  // give exception to 'content-grouping' as it is a template configured action.
  else if (content.actionType === 'content-grouping') return retVal;
  else if (!content.id) retVal.push({ type: ERROR_TYPES.INCOMPLETE, err: 'Unconfigured Content' });
  // if this feels insufficient, we can also check the list of constant instances
  else if (templateConstraint && content.templateId !== templateConstraint)
    retVal.push({ type: ERROR_TYPES.ERROR, err: 'Content does not abide template constraints.' });

  if (!checkJourneyErrors) return retVal;
  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { filteredErrors } = getBackendJourneyErrors(beErrors, state, (err: any) => {
    return err.component && err.component === 'content' && err.refId && err.refId === refId;
  });
  retVal.push(...filteredErrors);

  return retVal;
}
