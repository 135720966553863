import { Language } from 'interface/settings/settings.interface';

export const SettingActionType = {
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_PREVIEW_LANGUAGE: 'SET_PREVIEW_LANGUAGE',
};

export type SettingState = {
  languages: Language[];
  isInitialized: boolean;
  previewTextLanguage: string;
  previewTextDirection: Language['direction'];
};

export type SetLanguagesAction = {
  type: typeof SettingActionType.SET_LANGUAGES;
  payload: {
    languages: Language[];
  };
};

export type SetPreviewLanguageAction = {
  type: typeof SettingActionType.SET_PREVIEW_LANGUAGE;
  payload: {
    previewTextLanguage: string;
    previewTextDirection: Language['direction'];
  };
};
