import { createContext, useContext } from 'react';

import { CONTENT_MANAGER_STEPS } from 'components/ExperienceCanvas/types';
import { ContentStateItem } from 'store/content/content.type';
import { JourneyComponentTypes } from '../types/flow-builder.types';
import { PushPayloadType } from 'interface/push/push.interface';
import { PushStateItem } from 'store/push/push.type';
import { RuleStateItem } from 'store/rule/rule.type';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { WebhookStateItem } from 'store/webhook/webhook.type';

export type RenameModalData = {
  stepIndex: number;
  entity: ContentStateItem | PushStateItem | RuleStateItem | null;
  entityType: JourneyComponentTypes;
  entityNodeName?: string;
  // ToDo: Re-factor RenameModalData type to encapsulate all types of entity props
  pushType?: PushPayloadType;
};

export type TContentSlideoutProps = {
  isOpen: boolean;
  contentRefId?: string;
  startingStep?: CONTENT_MANAGER_STEPS;
  headerInfo: Partial<TSlidingSidePanelProps['headerInfo']>;
};

export type TPushSlideoutProps = {
  isOpen: boolean;
  iconOverride?: JSX.Element;
  titleOverride?: string;
};

export type TAudienceSlideoutProps = {
  isOpen: boolean;
  isViewOnlyMode: boolean;
};

export type TWebhookSlideoutProps = {
  isOpen: boolean;
  isViewOnly?: boolean;
  webhook: WebhookStateItem;
};

type FlowBuilderContext = {
  modals: {
    rename: {
      data: RenameModalData | null;
      setData: React.Dispatch<React.SetStateAction<RenameModalData | null>>;
    };
  };
  sidePanels: {
    // ToDo: Merge the types later and add a sidepanel type to distinguish
    targetAudience: TAudienceSlideoutProps & {
      isOpen: boolean;
      setAudienceContext: React.Dispatch<React.SetStateAction<TAudienceSlideoutProps>>;
      setStepIndex: React.Dispatch<React.SetStateAction<number>>;
      stepIndex: number;
    };
    schedule: {
      isOpen: boolean;
      setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
      setStepIndex: React.Dispatch<React.SetStateAction<number>>;
      stepIndex: number;
    };
    push: TPushSlideoutProps & {
      isOpen: boolean;
      setPushContext: React.Dispatch<React.SetStateAction<TPushSlideoutProps>>;
      setStepIndex: React.Dispatch<React.SetStateAction<number>>;
      stepIndex: number;
    };
    content: TContentSlideoutProps & {
      isOpen: boolean;
      setContentContext: React.Dispatch<React.SetStateAction<TContentSlideoutProps>>;
      setStepIndex: React.Dispatch<React.SetStateAction<number>>;
      stepIndex: number;
    };
    webhook: TWebhookSlideoutProps & {
      isOpen: boolean;
      setWebhookContext: React.Dispatch<React.SetStateAction<TWebhookSlideoutProps>>;
      setStepIndex: React.Dispatch<React.SetStateAction<number>>;
      stepIndex: number;
    };
  };
};

const FlowBuilderContext = createContext<FlowBuilderContext | null>(null);

const useFlowBuilderContext = () => {
  const context = useContext(FlowBuilderContext);

  if (!context) {
    throw new Error('useFlowBuilderContext must be used within FlowBuilderContextProvider');
  }

  return context;
};

export { FlowBuilderContext, useFlowBuilderContext };
