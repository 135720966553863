import React from 'react';
import { TIconProps } from '../types';

function InfoIcon({ fill, className = '', ariaHidden = false }: TIconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      aria-hidden={ariaHidden}
    >
      <path
        transform="translate(2, 2)"
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="#2371F1"
        d="M10.5 5.5v1h-1v-1h1zm0 4v5h-1v-5h1zM.5 10C.5 4.756 4.756.5 10 .5s9.5 4.256 9.5 9.5-4.256 9.5-9.5 9.5S.5 15.244.5 10zm1 0c0 4.686 3.814 8.5 8.5 8.5s8.5-3.814 8.5-8.5-3.814-8.5-8.5-8.5S1.5 5.314 1.5 10z"
        fill={fill}
      ></path>
    </svg>
  );
}

export default InfoIcon;
