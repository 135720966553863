import './LabelInput.scss';

import { Experience } from 'interface/experience/experience.interface';
import { FlightSelectSearchable, getIcon } from '@flybits/design-system';
import { isEmpty } from 'lodash';
import { Label } from 'interface/experience/experience.interface';
import { useDispatch } from 'react-redux';
import LabelsAPI from 'services/api/labels.api';
import React, { useEffect, useMemo, useState } from 'react';

interface IProps {
  entities?: Set<string> | undefined;
  experience?: Experience | undefined;
  labels?: string[];
  setLabels?: (labels: string[]) => void;
}

export default function LabelInput(props: IProps) {
  const labelsAPI = useMemo(() => new LabelsAPI(), []);

  const [allLabels, setAllLabels] = useState<Label[]>([]);
  const [experienceLabels, setExperienceLabels] = useState<string[]>(props.experience?.labels || []);
  const [filteredLabels, setFilteredLabels] = useState<Label[]>([]);
  const [isFetchingAllLabels, setIsFetchingAllLabels] = useState(true);
  const [labelError, setLabelError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch data for the initial render()

    const fetchData = async () => {
      const response = await labelsAPI.get();

      let labels = response.data.map((value) => {
        return {
          key: value,
          name: value,
        };
      });

      labels = sortLabels(labels);

      setAllLabels(labels);
      setIsFetchingAllLabels(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingAllLabels || experienceLabels.length <= 0) {
      return;
    }

    // Filter experience labels from all labels
    const labels = allLabels.filter((label) => !experienceLabels.includes(label.name));

    setFilteredLabels(labels);
  }, [allLabels, experienceLabels, isFetchingAllLabels]);

  const sortLabels = (labels: Label[]) => {
    return labels.sort((a: Label, b: Label) => {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
  };

  const updateExperienceLabels = (labels: string[]) => {
    if (!isEmpty(props.experience)) {
      dispatch({
        type: 'UPDATE_JOURNEY',
        payload: {
          labels,
        },
      });
    }
    if (!isEmpty(props.entities) && props.setLabels) {
      props.setLabels(labels);
    }
  };

  const checkLabelErrors = (label: string) => {
    if (label.length > 35) {
      return 'Label is too long.';
    }
    const labelRE = /^[a-zA-Z0-9-]*$/;
    return label.match(labelRE) ? '' : "Labels can only contain alphanumeric characters and '-'";
  };

  const handleSearchLabel = (labelName: string) => {
    if (labelName) {
      setLabelError(checkLabelErrors(labelName) ?? '');
    }
    const filteredLabels = allLabels.filter(
      (label) => label.name.includes(labelName) && !experienceLabels.includes(label.name),
    );
    setFilteredLabels(filteredLabels);
  };

  const handleSelectLabel = (label: { key: string; name: string }) => {
    if (label && checkLabelErrors(label.name)) {
      return;
    }
    const labelExists = experienceLabels.find((expLabel: string) => expLabel === label?.name);
    if (label && !labelExists) {
      setExperienceLabels([...experienceLabels, label?.name]);
      updateExperienceLabels([...experienceLabels, label?.name]);
      if (props.setLabels) {
        props.setLabels([...(props.labels || []), label.name]);
      }
    }
  };

  const handleRemoveLabel = (label: string) => {
    const updatedLabels = experienceLabels.filter((expLabel: string) => expLabel !== label);
    setExperienceLabels(updatedLabels);
    updateExperienceLabels(updatedLabels);
    if (props.setLabels) {
      props.setLabels([...updatedLabels]);
    }
    const sortedFilteredLabels = sortLabels([...filteredLabels, { key: label, name: label }]);
    setFilteredLabels(sortedFilteredLabels);
  };

  return (
    <div className="label-input">
      <div className="label-input__text">
        <FlightSelectSearchable
          className="label-input__search"
          clearOnClickOutside={true}
          dropdownMaxHeight="200px"
          errorMessage={labelError}
          handleOptionClick={handleSelectLabel}
          handleSearch={handleSearchLabel}
          hasError={!!labelError}
          label="Search labels or create new"
          options={filteredLabels}
        />
      </div>
      <div className="label-input__description">
        <small>
          <strong>Tip:</strong> To create a new label, type label name and press enter
        </small>
      </div>
      <div className="label-input__text">
        {experienceLabels?.map((label: string, idx: number) => (
          <div key={idx} className="label-input__label">
            {label} <button onClick={() => handleRemoveLabel(label)}>{getIcon('clear', {})}</button>
          </div>
        ))}
      </div>
    </div>
  );
}
