import React from 'react';
import './VerificationStatus.scss';
import { FlightTooltip } from '@flybits/design-system';
import { STATUS_DELETED, STATUS_FAILED, STATUS_PENDING, STATUS_PENDING_DELETION, STATUS_VERIFIED } from './constants';
import { VERIFICATION_STATUSES } from 'pages/MerchantPortal/merchant-portal.types';

const CLASS_MAIN = 'verification-status';
const CLASSES = {
  ICON: `${CLASS_MAIN}__icon`,
};

type VerificationStatusProps = {
  type?: VERIFICATION_STATUSES;
  showTooltip?: boolean;
  customTooltipText?: string;
};

export default function VerificationStatus(props: VerificationStatusProps) {
  const { type = VERIFICATION_STATUSES.VERIFIED, showTooltip, customTooltipText } = props;
  // Default to "Pending"
  let info = {
    type,
    text: STATUS_PENDING.DESCRIPTION,
    title: STATUS_PENDING.TITLE,
  };
  switch (type) {
    case VERIFICATION_STATUSES.VERIFIED:
      info = {
        ...info,
        text: STATUS_VERIFIED.DESCRIPTION,
        title: STATUS_VERIFIED.TITLE,
      };
      break;
    case VERIFICATION_STATUSES.PENDING:
      info = {
        ...info,
        text: STATUS_PENDING.DESCRIPTION,
        title: STATUS_PENDING.TITLE,
      };
      break;
    case VERIFICATION_STATUSES.FAILED:
      info = {
        ...info,
        text: STATUS_FAILED.DESCRIPTION,
        title: STATUS_FAILED.TITLE,
      };
      break;
    case VERIFICATION_STATUSES.DELETED:
      info = {
        ...info,
        text: STATUS_DELETED.DESCRIPTION,
        title: STATUS_DELETED.TITLE,
      };
      break;
    case VERIFICATION_STATUSES.PENDING_DELETION:
      info = {
        ...info,
        text: STATUS_PENDING_DELETION.DESCRIPTION,
        title: STATUS_PENDING_DELETION.TITLE,
      };
      break;
  }
  return (
    <FlightTooltip description={customTooltipText || info.text} direction="bottom" isEnabled={showTooltip}>
      <div className={`${CLASS_MAIN} ${CLASSES.ICON}-${info.type}`}>
        <i className={`${CLASSES.ICON}`}></i>
        {info.title}
      </div>
    </FlightTooltip>
  );
}
