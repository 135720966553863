import React from 'react';
import { FlightToggleSwitch, FlightTooltip, getIcon } from '@flybits/design-system';
import { TIconProps } from 'pages/ExperienceCanvas/assets/types';
import ErrorIcon from 'pages/ExperienceCanvas/assets/icons/ErrorIcon';

type TTouchpointEditorCardHeaderProps = {
  title: string;
  isExpanded: boolean;
  showExpandToggle: boolean;
  icon: React.FC<TIconProps>;
  setIsExpanded: (value: boolean) => void;
  isToggleExpandedDisabled: boolean;
  actions: JSX.Element[];
  info?: string;
  toggleTooltip?: string;
  subtitle?: string;
  isLocked?: boolean;
  lockedMessage?: string;
};

const CLASS_PREFIX = 'card';
const CLASSES = {
  HEADER: `${CLASS_PREFIX}__header`,
  HEADER_EXPANDED: `${CLASS_PREFIX}__header--is-expanded`,
  HEADER_ROW: `${CLASS_PREFIX}__header__row`,
  HEADER_TITLE: `${CLASS_PREFIX}__header__title`,
  HEADER_SUBTITLE: `${CLASS_PREFIX}__header__subtitle`,
  HEADER_ICON: `${CLASS_PREFIX}__header__icon`,
  HEADER_INFO_ICON: `${CLASS_PREFIX}__header__info-icon`,
  HEADER_TOOLTIP: `${CLASS_PREFIX}__header__info`,
  HEADER_TOGGLE: `${CLASS_PREFIX}__header__toggle`,
  HEADER_ACTIONS: `${CLASS_PREFIX}__header__actions`,
  LOCKED_MESSAGE: `${CLASS_PREFIX}__header__locked-message`,
  LOCK_ICON: `${CLASS_PREFIX}__header__lock-icon`,
};

export default function TouchpointEditorCardHeader({
  title,
  isExpanded,
  showExpandToggle,
  icon: Icon,
  setIsExpanded,
  isToggleExpandedDisabled,
  actions,
  info,
  toggleTooltip = '',
  subtitle,
  isLocked = false,
  lockedMessage = 'You cannot modify an active experience.',
}: TTouchpointEditorCardHeaderProps) {
  return (
    <div className={`${CLASSES.HEADER}${isExpanded !== false ? ` ${CLASSES.HEADER_EXPANDED}` : ''}`}>
      <div className={`${CLASSES.HEADER_ROW}`}>
        <div className={CLASSES.HEADER_ICON}>
          <Icon />
        </div>
        <h3 className={CLASSES.HEADER_TITLE}>{title}</h3>
        {!!info && (
          <FlightTooltip className={`${CLASSES.HEADER_TOOLTIP}`} description={info} direction="right">
            <ErrorIcon className={`${CLASSES.HEADER_INFO_ICON}`} />
          </FlightTooltip>
        )}
        {showExpandToggle && (
          <FlightTooltip
            className={`${CLASSES.HEADER_TOGGLE}`}
            description={toggleTooltip}
            isEnabled={!!toggleTooltip}
            direction="left"
          >
            <FlightToggleSwitch
              label="On"
              checked={isExpanded}
              disabled={isToggleExpandedDisabled}
              onChange={() => setIsExpanded(!isExpanded)}
            />
          </FlightTooltip>
        )}
        {actions.length > 0 && <div className={CLASSES.HEADER_ACTIONS}>{actions}</div>}
      </div>
      {subtitle && <h5 className={CLASSES.HEADER_SUBTITLE}>{subtitle}</h5>}
      {isLocked && (
        <div className={CLASSES.LOCKED_MESSAGE}>
          <div className={CLASSES.LOCK_ICON}>{getIcon('lock', {})}</div>
          <strong>Locked:</strong> {lockedMessage}
        </div>
      )}
    </div>
  );
}
