import { MarkerType } from 'reactflow';
import { EdgeStyle, EdgeMarkerEnd, EdgeProps } from 'pages/FlowBuilder/types/flow-builder.types';

const blues = {
  '200': '#7CB4FF',
  '700': '#182587',
};

const edgeStyle: EdgeStyle = {
  stroke: blues['200'],
  strokeWidth: 3,
};
const dashedEdgeStyle: EdgeStyle = {
  stroke: blues['200'],
  strokeWidth: 2,
  strokeDasharray: '5 5',
};

export const edgeMarkerEnd: EdgeMarkerEnd = {
  type: MarkerType.Arrow,
  width: 20,
  height: 20,
  color: blues['200'],
};

export const edgeProps: Partial<EdgeProps> = {
  type: 'default',
  style: edgeStyle,
  markerEnd: edgeMarkerEnd,
};

export const dashedEdgeProps: Partial<EdgeProps> = {
  type: 'default',
  style: dashedEdgeStyle,
  markerEnd: edgeMarkerEnd,
};

export const startEdgeProps: Partial<EdgeProps> = {
  ...dashedEdgeProps,
  style: { ...dashedEdgeStyle, strokeWidth: 3 },
  animated: true,
};

const neutralColor200 = '#CDD0D7';

export const defaultTargetHandleStyle: React.CSSProperties = {
  visibility: 'hidden',
};

export const defaultSourceHandleStyle: React.CSSProperties = {
  bottom: '-9px',
  width: '30px',
  height: '8px',
  border: 0,
  borderRadius: '0 0 30px 30px',
  backgroundColor: neutralColor200,
};
