import './ZoneModuleSetup.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ZoneModuleTabs from './ZoneModuleTabs/ZoneModuleTabs';
import ZoneAttributes from '../ZoneAttributes/ZoneAttributes';
import ModuleInfo from '../ModuleInfo/ModuleInfo';
import ModuleLayout from '../ModuleLayout/ModuleLayout';
import { ZM_DETAILS_CLASSES, ZoneDefaults, ModuleDefaults } from './constants';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import { validateModuleInfo, validateModuleLayout, validateZoneAttributes } from '../zone.validators';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { debounce, disableLoading, genUUID, TTimeoutId } from 'helpers/common.helper';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { TExperienceCanvasRouteParams } from 'pages/ExperienceCanvas/types';
import { isAxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { MODULE_LAYOUTS, ZoneModuleTabKeys } from '../types';
import OnboardingLoader from '../OnboardingLoader/OnboardingLoader';

const tabs = [
  {
    key: 'zone',
    name: 'Zone Setup',
    title: `Let's set up your first zone`,
  },
  {
    key: 'modinfo',
    name: 'Module Info',
    title: `Now let’s set up your module info`,
  },
  {
    key: 'modlayout',
    name: 'Module Layout',
    title: `Now let’s set up your module layout`,
  },
];

/**
 * The landing page for when users first configure their tenant's Zones & Modules.
 * Should contain the logic for navigating between the steps,
 * as well as swapping what's in the tab content children (content area)
 */
const ZoneModuleSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const debounceTimeoutId = useRef<TTimeoutId>({ timeoutId: undefined });
  const { pid: projectId } = useRouteMatch<TExperienceCanvasRouteParams>().params;
  const queryClient = useQueryClient();
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const {
    zones,
    selectedZone: zone,
    selectedModule: module,
    publishZoneConfigs,
    queryKey,
    isCustomLayout,
  } = useContext(PrioritizationContext);

  // Set `vertical` as the default pre-defined layout
  const moduleLayout = (module?.layout as MODULE_LAYOUTS) || MODULE_LAYOUTS.VERTICAL;

  const [isNavigateNextDisabled, setNavigateNextDisabled] = useState(false);
  const [isLaunching, setLaunching] = useState(false);

  const navigateNext = () => {
    setActiveTabIdx((prev) => ++prev);
  };

  const navigatePrev = () => {
    setActiveTabIdx((prev) => --prev);
  };

  // theoretically this should never be called before each step has been validated, so i'm not calling it here for now
  const onPublish = async () => {
    setLaunching(true);
    try {
      const startTime = Date.now();
      await publishZoneConfigs([
        {
          ...ZoneDefaults,
          ...zone,
          id: genUUID(),
          modules: [
            {
              ...ModuleDefaults,
              ...module,
              id: genUUID(),
            },
          ],
        },
      ]);
      await queryClient.invalidateQueries({ queryKey: [queryKey] });

      await disableLoading(
        startTime,
        () => {
          setLaunching(false);
          dispatch(
            showSnackbar({
              content: `You're ready to get started with zones and modules!`,
              type: 'default',
            }),
          );
          history.replace(`/project/${projectId}/zones/v2`);
        },
        2200,
      );
    } catch (e: unknown) {
      const errorMessage = isAxiosError(e) ? e.response?.data?.error?.exceptionMessage : undefined;
      dispatch(
        showSnackbar({
          content: `Your zones and modules could not be saved! \n Error: ${errorMessage ?? 'unknown'}`,
          type: 'error',
        }),
      );
    }
    setLaunching(false);
  };

  // because we need to call validations here, the in-line validations in the form components are very redundant.
  useEffect(() => {
    const checkDisabled = async () => {
      const { name, refId, labels } = validateZoneAttributes(
        zone?.name,
        zone?.referenceID ?? '',
        zone?.labels,
        zone?.id,
        zones,
      );
      if (activeTabIdx === 0) setNavigateNextDisabled(!!(name || refId || labels));
      else if (activeTabIdx === 1) {
        const {
          name: mName,
          height,
          layout,
        } = validateModuleInfo(
          // Override layout option validation if users select `Custom` layout
          moduleLayout || isCustomLayout,
          module?.name,
          module?.height,
        );
        setNavigateNextDisabled(!!(mName || height || layout));
      } else {
        const { layoutHTML } = await debounce(validateModuleLayout, 500, debounceTimeoutId.current)(module?.layoutHTML);
        setNavigateNextDisabled(!!layoutHTML);
      }
    };
    setNavigateNextDisabled(true);
    checkDisabled();
  }, [activeTabIdx, module, zone, zones, moduleLayout, isCustomLayout]);

  return (
    <>
      <OnboardingLoader isLaunchingZone={isLaunching} />
      <ZoneModuleTabs tabs={tabs} activeIdx={activeTabIdx}>
        <div className={ZM_DETAILS_CLASSES.CONTAINER}>
          <div className={ZM_DETAILS_CLASSES.HEADER}>{tabs[activeTabIdx].title}</div>
          {tabs[activeTabIdx].key === ZoneModuleTabKeys.ZONE && (
            <ZoneAttributes
              navigateToNext={{
                label: 'Next Step',
                cta: navigateNext,
                isDisabled: isNavigateNextDisabled,
              }}
            />
          )}
          {tabs[activeTabIdx].key === ZoneModuleTabKeys.INFO && (
            <ModuleInfo
              navigateToPrev={{
                label: 'Previous Step',
                cta: navigatePrev,
              }}
              navigateToNext={{
                label: !isCustomLayout ? 'Publish' : 'Next Step',
                cta: !isCustomLayout ? onPublish : navigateNext,
                isDisabled: isNavigateNextDisabled,
              }}
            />
          )}
          {tabs[activeTabIdx].key === ZoneModuleTabKeys.LAYOUT && (
            <ModuleLayout
              navigateToPrev={{
                label: 'Previous Step',
                cta: navigatePrev,
              }}
              navigateToNext={{
                label: 'Publish',
                cta: onPublish,
                isDisabled: isNavigateNextDisabled,
              }}
            />
          )}
        </div>
      </ZoneModuleTabs>
    </>
  );
};

export default ZoneModuleSetup;
