import { fetchMatchingLocationsWithLabelsThunk, saveLocationThunk } from 'store/location/location.thunk';
import { getStepRuleRefIds } from 'store/rule/rule.selector';
import { serializeRule } from 'store/serializers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import {
  RuleBuilderLocationPayload,
  RuleBuilderSaveLocationStatus,
  RuleBuilderContentAnalyticsProps,
} from '@flybits/webapp-react-rule-builder';
import LabelsAPI from 'services/api/labels.api';
import PluginAPI from 'services/api/plugin.api';
import useContentAnalytics, { useContentAnalyticsComponents } from 'hooks/useContentAnalytics';
import useContentInstances from 'hooks/useContentInstances';
import useLocations from 'hooks/useLocations';
import usePlugins from 'hooks/useContextPlugins';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';

export function useRuleBuilderProps() {
  const labelsAPI = useMemo(() => new LabelsAPI(), []);
  const pluginAPI = useMemo(() => new PluginAPI(), []);

  const [contextLabels, setContextLabels] = useState<string[]>([]);
  const [historicalQueries, setHistoricalQueries] = useState([]);
  const [isLabelsLoaded, setIsLabelsLoaded] = useState(false);
  const [isPluginsLoaded, setIsPluginsLoaded] = useState(false);
  const [isSerializeUiModel, setIsSerializeUiModel] = useState(false);
  const { contentInstances } = useContentInstances();
  const { plugins } = usePlugins();
  const dispatch = useDispatch();
  const flowBuilderContext = useFlowBuilderContext();
  const te = useSelector((state) => state.te);

  const stepIndex = flowBuilderContext.sidePanels.targetAudience.stepIndex;

  const responseJSON = useMemo(() => {
    const activeRuleTab = 'restricted';

    const stepRuleRefId = getStepRuleRefIds(te, stepIndex)[activeRuleTab];

    return serializeRule(
      te.rule.byRefId[stepRuleRefId],
      undefined,
      undefined,
      undefined,
      undefined,
      stepIndex,
      activeRuleTab,
      true,
    );
  }, [te, stepIndex]);

  // Labels and Plugins --------------------------------------------------------

  useEffect(() => {
    if (!isLabelsLoaded && !contextLabels.length) {
      setIsLabelsLoaded(true);

      (async () => {
        const { data: labels = [] } = await labelsAPI.getLabels();
        setContextLabels(labels);
      })();
    }

    if (!isPluginsLoaded && !historicalQueries.length) {
      setIsPluginsLoaded(true);

      (async () => {
        const { data } = await pluginAPI.getAllHistoricalPlugins();
        setHistoricalQueries(data);
      })();
    }
  }, [contextLabels, historicalQueries, isLabelsLoaded, isPluginsLoaded, labelsAPI, pluginAPI]);

  // Content Analytics ---------------------------------------------------------

  const [contentInstanceSearchVal, setContentInstanceSearchVal] = useState('');

  const {
    isLoading,
    isFetchingNextPage,
    contentInstances: contentAnalyticsSuggestionList,
    fetchNextContentPage,
    isError,
    serverErrorMessage,
  } = useContentAnalytics(contentInstanceSearchVal);

  const [componentHookProps, setComponentHookProps] = useState({
    contentId: '',
    uid: '',
    nodeId: '',
  });

  const { isLoading: areComponentsLoading, ...componentsObject } = useContentAnalyticsComponents(
    componentHookProps.contentId,
    componentHookProps.uid,
  );

  const handleContentSearch = useCallback((searchVal: string) => {
    setContentInstanceSearchVal(searchVal);
  }, []);

  const handleComponentsDropdownFocus = useCallback((nodeId: string, contentId: string, uid: string) => {
    setComponentHookProps({ contentId, uid, nodeId });
  }, []);

  const contentAnalyticsProps: RuleBuilderContentAnalyticsProps = useMemo(
    () => ({
      contentData: {
        data: {
          options: contentAnalyticsSuggestionList,
          width: '600px',
          ariaControlsId: 'contentAnalyticsSuggestionBox',
          noDataAvailable: false,
          loading: isLoading || isFetchingNextPage,
          disabled: false,
          isServerError: isError,
          clearOnClickOutside: true,
          content: {
            errorMessage: 'Select content instance from dropdown',
            serverErrorMsg: {
              text: serverErrorMessage || 'Could not load content',
            },
          },
        },
        methods: {
          handleContentSearch,
          handleContentOptionClick: () => null,
          fetchNextContentPage,
        },
      },
      componentData: {
        nodeId: componentHookProps.nodeId,
        data: {
          options: componentsObject.data,
          width: '600px',
          ariaControlsId: 'contentAnalyticsComponentsSuggestionBox',
          noDataAvailable: false,
          loading: areComponentsLoading,
          disabled: false,
          isServerError: componentsObject.isError,
          clearOnClickOutside: true,
          content: {
            errorMessage: 'Select component from dropdown',
            serverErrorMsg: {
              text: 'Could not load content',
            },
          },
        },
        methods: {
          handleComponentSearch: () => null,
          handleComponentOptionClick: () => null,
        },
      },
      handleComponentsDropdownFocus,
    }),
    [
      contentAnalyticsSuggestionList,
      handleContentSearch,
      fetchNextContentPage,
      isError,
      serverErrorMessage,
      isLoading,
      isFetchingNextPage,
      componentHookProps,
      componentsObject,
      areComponentsLoading,
      handleComponentsDropdownFocus,
    ],
  );

  // Locations -----------------------------------------------------------------

  const {
    locations,
    areLocationsLoading: areLocationsWaiting,
    numMatchingLocations,
    areMatchingLocationsLoading,
    isLocationSaving,
  } = useLocations();

  const [areLocationsLabelsWaiting, setAreLocationsLabelsWaiting] = useState({
    waitingNodeId: '',
    isWaiting: false,
  });

  const [matchingLocations, setMatchingLocations] = useState({
    matchingLocationsNodeId: '',
    numMatchingLocations: 0,
  });

  const [waitingNodeId, setWaitingNodeId] = useState('');

  const handleLocationLabelSelection = async (nodeId: string, labelsFormula: string) => {
    setWaitingNodeId(nodeId);
    dispatch(fetchMatchingLocationsWithLabelsThunk(labelsFormula));
  };
  const handleSaveLocation = (
    locationPayload: RuleBuilderLocationPayload,
    callback: (saveStatusInfo?: RuleBuilderSaveLocationStatus) => void,
    locationId?: string,
  ) => {
    dispatch(saveLocationThunk(locationPayload, callback, locationId));
  };

  const locationProps = {
    areLocationsLabelsWaiting,
    areLocationsWaiting,
    googleMapAPIKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
    hideAddNewLocationCTA: false,
    isLocationSaving,
    locations,
    matchingLocations,
    onLocationLabelSelection: handleLocationLabelSelection,
    onSaveLocation: handleSaveLocation,
  };

  useEffect(() => {
    setAreLocationsLabelsWaiting({
      waitingNodeId,
      isWaiting: areMatchingLocationsLoading,
    });

    setMatchingLocations({
      matchingLocationsNodeId: waitingNodeId,
      numMatchingLocations,
    });
  }, [waitingNodeId, numMatchingLocations, areMatchingLocationsLoading]);

  return {
    contentAnalyticsProps,
    contentInstances,
    contextLabels,
    contextPlugins: plugins,
    historicalQueries,
    isSerializeUiModel,
    locationProps,
    responseJSON,
    setIsSerializeUiModel,
  };
}
