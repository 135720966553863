import React from 'react';
import SlidingSidePanel from '../SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import FileUpload from '../TouchpointEditor/TouchpointTimelineBox/UploadContextDataBox/FileUpload/FileUpload';
import FileUploadHistory from '../TouchpointEditor/TouchpointTimelineBox/UploadContextDataBox/FileUploadHistory/FileUploadHistory';
import './ContextDataSlideOut.scss';

type TContextDataslideOutProps = {
  isOpen: boolean;
  onClickClose: () => void;
};

const MAIN_CLASS = 'context-data-slide-out';
const CLASSES = {
  FILE_HISTORY_WRAPPER: `${MAIN_CLASS}__file-history-wrapper`,
  FILE_UPLOAD_WRAPPER: `${MAIN_CLASS}__file-upload-wrapper`,
};

const ContextDataSlideOut: React.FC<TContextDataslideOutProps> = ({ isOpen, onClickClose }) => {
  const slidingSidePanelProps: TSlidingSidePanelProps = {
    headerInfo: {
      mainTitle: 'Upload Context Data',
      showCloseButton: true,
      goBackActionHandler: onClickClose,
    },
    footerInfo: {
      secondaryActionHandler: onClickClose,
    },
    showFooter: false,
    show: isOpen,
  };

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={MAIN_CLASS}>
        <div className={CLASSES.FILE_UPLOAD_WRAPPER}>
          <FileUpload allowedTypes={['text/csv']} showDownloadSampleFile />
        </div>
        <div className={CLASSES.FILE_HISTORY_WRAPPER}>
          <FileUploadHistory />
        </div>
      </div>
    </SlidingSidePanel>
  );
};

export default ContextDataSlideOut;
