import './ScheduleSidePanel.scss';

import React, { useCallback, useMemo } from 'react';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import IconSchedule from 'pages/FlowBuilder/assets/icons/IconSchedule';
import TouchpointEditor from 'pages/ExperienceCanvas/TouchpointEditor/TouchpointEditor';

const MAIN_CLASS = 'schedule-side-panel';

function ScheduleSidePanel() {
  const flowBuilderContext = useFlowBuilderContext();
  const flowScheduleContext = flowBuilderContext.sidePanels.schedule;

  const closeSidePanel = useCallback(() => {
    flowScheduleContext.setIsOpen(false);
  }, [flowScheduleContext]);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: flowScheduleContext.isOpen,
      headerInfo: {
        mainTitle: '',
        goBackIcon: <IconSchedule />,
        goBackTitle: 'Schedule',
        goBackSubTitle: 'Currently editing from Flow Builder',
        goBackActionHandler: closeSidePanel,
      },
      showFooter: false,
      size: 'large',
    }),
    [flowScheduleContext, closeSidePanel],
  );

  return (
    <div className={MAIN_CLASS}>
      <SlidingSidePanel {...slidingSidePanelProps}>
        <TouchpointEditor
          isFromFlow
          stepIdx={flowScheduleContext.stepIndex}
          activeTabIdx={0}
          closeFlowSidePanel={closeSidePanel}
        />
      </SlidingSidePanel>
    </div>
  );
}

export default ScheduleSidePanel;
