/*
  rule.selector.ts
  a set of functions that take in the current state related to the currently
  displayed journey's rules and derives useful data.
*/

import { isEmpty } from 'lodash';
import { RuleStateItem } from 'store/rule/rule.type';
import { RootState } from 'store/store';
import { getStepRuleSyntheticId } from 'store/helper';
import { START_TIME } from 'pages/ExperienceCanvas/types';

/**
 * Selects dominant audience rule
 * This function may be less useful once dependencies are more made use of.
 * Takes in the state and a step index
 * @returns RuleStateItem | undefined
 */
export function selectStepRule(teState: RootState['te'], stepIndex: number): RuleStateItem | undefined {
  const resRefId = teState.journey.steps[stepIndex]?.rule?.restricted;
  const prefRefId = teState.journey.steps[stepIndex]?.rule?.preferred;
  const restrictedRule = teState.rule.byRefId[resRefId];
  const preferredRule = teState.rule.byRefId[prefRefId];
  const isLooselyEnabled =
    restrictedRule?.isOptional &&
    preferredRule?.isOptional &&
    restrictedRule.enableOptionalNode &&
    preferredRule.enableOptionalNode;
  const restrictedEmpty = isEmpty(restrictedRule) || !restrictedRule.logic;
  const preferredEmpty = isEmpty(preferredRule) || !preferredRule.logic;
  const bothEmpty = restrictedEmpty && preferredEmpty;

  if (
    restrictedRule &&
    (!restrictedRule.isOptional ||
      (restrictedRule.enableOptionalNode && (!isLooselyEnabled || bothEmpty || !restrictedEmpty)))
  ) {
    return restrictedRule;
  } else if (preferredRule && (!preferredRule.isOptional || preferredRule.enableOptionalNode)) {
    return preferredRule;
  }
  return undefined;
}

type StepRuleSchedule = {
  start?: {
    value: number | START_TIME;
    timezone: string;
    cronExpression?: string;
  };
  end?: {
    value: number;
    timezone: string;
    cronExpression?: string;
  };
};

/**
 * Selects a step's schedulers (rule)
 * Takes in the state and a step index
 * @returns StepRuleSchedule;
 */
export function selectStepSchedules(teState: RootState['te'], stepIdx: number): StepRuleSchedule {
  const synthId = getStepRuleSyntheticId(stepIdx);
  const startSched = teState.rule.startSchedulerMap[synthId];
  const endSched = teState.rule.endSchedulerMap[synthId];
  return {
    start: startSched
      ? {
          value: startSched.start ?? 0,
          timezone: startSched.timezone?.name ?? '',
          cronExpression: startSched?.cronExpression ?? '',
        }
      : undefined,
    end: endSched
      ? {
          value: (endSched.start as number) ?? 0,
          timezone: endSched.timezone?.name ?? '',
          cronExpression: endSched?.cronExpression ?? '',
        }
      : undefined,
  };
}

/**
 * Gets a step's rules' ids.
 * Takes in the state and a step index
 * @returns { restricted: string, preferred: string }
 */
export function getStepRuleRefIds(teState: RootState['te'], stepIndex: number) {
  return teState.journey.steps[stepIndex]?.rule ?? { restricted: '', preferred: '' };
}

/**
 * Checks if the audience is unconfigured, non-existent, or omitted.
 * Optional flag checkOnlyExistence only checks if audience does not exist.
 * */
export function getIsAudienceEmpty(teState: RootState['te'], stepIdx: number, checkOnlyExistence = false) {
  const preferredRefId = teState.journey.steps[stepIdx]?.rule.preferred;
  const restrictedRefId = teState.journey.steps[stepIdx]?.rule.restricted;
  const preferredRule = teState.rule.byRefId[preferredRefId];
  const restrictedRule = teState.rule.byRefId[restrictedRefId];
  const isPreferredEmpty = isEmpty(preferredRule);
  const isRestrictedEmpty = isEmpty(restrictedRule);

  if (checkOnlyExistence) return isPreferredEmpty && isRestrictedEmpty;

  const isPreferredBlank = !preferredRule?.logic;
  const isRestrictedBlank = !restrictedRule?.logic;
  const omitPreferred = isPreferredEmpty || (preferredRule.isOptional && !preferredRule.enableOptionalNode);
  const omitRestricted = isRestrictedEmpty || (restrictedRule.isOptional && !restrictedRule.enableOptionalNode);

  const isAudienceEmpty = isPreferredEmpty && isRestrictedEmpty;
  const isAudienceBlank = isPreferredBlank && isRestrictedBlank;

  const omitRule = omitPreferred && omitRestricted;

  return isAudienceEmpty || isAudienceBlank || omitRule;
}
