import { MarkerType } from 'reactflow';

type EdgeStyleProps = {
  stroke: string;
  strokeWidth: number;
  strokeDasharray: string;
};

export type EdgeStyle = Partial<EdgeStyleProps>;

export type EdgeMarkerEnd = {
  type: MarkerType;
  width: number;
  height: number;
  color: string;
};

export type EdgeProps = {
  type: string;
  style: EdgeStyle;
  markerEnd: EdgeMarkerEnd;
  animated: boolean;
};

export type FlowBuilderRouteParams = {
  pid: string;
  id: string;
};

export enum JourneyComponentTypes {
  RULE = 'RULE',
  PUSH = 'PUSH',
  CONTENT = 'CONTENT',
  WEBHOOK = 'WEBHOOK',
}
