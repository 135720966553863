import { Log, Pagination } from 'interface/audit/audit.interface';
import { User, ServiceAccount } from 'interface/auth/auth.interface';

export type AuditState = {
  users: User[];
  serviceAccounts: ServiceAccount[];
  logs: Log[];
  pagination: Pagination;
};

export const AuditActionType = {
  SET_AUDIT_LOG_DATA: 'SET_AUDIT_LOG_DATA',
  SET_AUDIT_PAGINATION_DATA: 'SET_AUDIT_PAGINATION_DATA',
  SET_AUDIT_USERS_DATA: 'SET_AUDIT_USERS_DATA',
  SET_AUDIT_SERVICE_ACCOUNTS_DATA: 'SET_AUDIT_SERVICE_ACCOUNTS_DATA',
};

export type SetAuditUsers = {
  type: string;
  payload: User[];
};

export type SetAuditServiceAccounts = {
  type: string;
  payload: ServiceAccount[];
};

export type SetAuditLogData = {
  type: string;
  payload: Log[];
};

export type SetAuditPaginationData = {
  type: string;
  payload: Pagination;
};
