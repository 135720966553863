import React from 'react';
import './AnalyticsGraphs.scss';
import BarGraph from 'components/Shared/Graphs/BarGraph';
import { FlightOverflowMenu, FlightTooltip } from '@flybits/design-system';
import { useHistory } from 'react-router-dom';
import { DropdownOptions } from 'interface/shared/dropdown.interface';
import PieGraph from 'components/Shared/Graphs/PieGraph';
import { GraphData } from '../types';
import { EngagementModuleData, EngagementModuleOverTimeData } from 'interface/templated-experience/analytics.interface';
import StreamGraph from 'components/Shared/Graphs/StreamGraph';
import { groupBy } from 'helpers/common.helper';

type TProps = {
  title: string;
  id: string;
  data: EngagementModuleData[] | EngagementModuleOverTimeData[] | GraphData[];
  keys: string[];
  graph: string;
  indexBy?: string;
  layout?: 'horizontal' | 'vertical' | undefined;
  reverse?: boolean;
  xAxis?: string;
  yAxis?: string;
  onRemove?: (id: string) => void;
  hasError?: boolean;
  timeInterval?: 'day' | 'millisecond' | 'second' | 'minute' | 'hour' | 'month' | 'year' | undefined;
};
const roundToX = (num = 0, decimals = 2) => Math.round(num * 10 ** decimals) / 10 ** decimals;
export const AnalyticsGraph = (props: TProps) => {
  const DEFAULT_CLASS = 'analytics-overview__bottom-side__graphs__graph';
  const { id, title, hasError, onRemove } = props;
  const history = useHistory();
  const pathName = history.location.pathname.replace(/\/$/, '');
  const graphMargin = { top: 8, right: 20, bottom: 50, left: 100 };
  function getGraph(prop: TProps) {
    const { data, keys, graph, indexBy, layout, reverse, xAxis, yAxis, timeInterval } = prop;
    if (!data) return <>No data found</>;
    if (['stream', 'line'].includes(graph)) {
      const formattedData = Object.entries(
        groupBy(data as EngagementModuleOverTimeData[], ({ name }) => name || title),
      ).map(([key, value]) => {
        const color = `hsl(${Math.floor(Math.random() * 360)}, ${Math.floor(Math.random() * 100)}%, 60%)`;
        return {
          id: key,
          color: color,
          data: value.map(({ x, y }) => {
            return { x: new Date(x * 1000), y: roundToX(y), color: color };
          }),
        };
      });
      return (
        <StreamGraph
          data={formattedData}
          isLegendVisible={true}
          xAxis={xAxis}
          yAxis={yAxis}
          timeInterval={timeInterval}
        />
      );
    } else if (['pie', 'doughnut'].includes(graph)) {
      const formattedData = (data as EngagementModuleData[]).map(({ name, value }) => ({
        id: name || xAxis || title,
        label: name || xAxis || title,
        value: typeof value === 'number' ? roundToX(value) : value,
        color: `hsl(${Math.floor(Math.random() * 360)}, ${Math.floor(Math.random() * 100)}%, 60%)`,
      }));
      return <PieGraph data={formattedData} legendIcon="square" innerRadius={0.4} />;
    } else if (graph === 'bar' && indexBy) {
      return (
        <BarGraph
          data={data as EngagementModuleData[]}
          keys={keys}
          indexBy={indexBy}
          layout={layout}
          grouped
          reverse={reverse}
          graphMargin={graphMargin}
          axisBottom={xAxis}
          axisLeft={yAxis}
        />
      );
    }
  }
  function ModuleActions() {
    const optionGroups: Array<Array<DropdownOptions>> = [
      [
        {
          key: 1,
          name: 'Edit',
          disabled: false,
          onClick: () => history.push(`${pathName}/module/${id}/edit`),
        },
        {
          key: 2,
          name: 'Delete',
          disabled: false,
          onClick: () => {
            if (window.confirm('Are you sure you want to delete this widget?')) {
              if (typeof onRemove === 'function') onRemove(id);
            }
          },
        },
      ],
    ];

    return (
      <FlightOverflowMenu
        className={`${DEFAULT_CLASS}__header__settings-actions no-drag`}
        direction={'bottom'}
        iconRight={'cog'}
        isOpenRight={false}
        disabled={false}
        optionGroups={optionGroups}
      />
    );
  }
  return (
    <div className={`${DEFAULT_CLASS} ${hasError ? 'error' : ''}`} key={id}>
      <div className={`${DEFAULT_CLASS}__header`}>
        <div className={`${DEFAULT_CLASS}__header__title-container`}>
          <FlightTooltip isEnabled={true} description={title} className={`${DEFAULT_CLASS}__header__tooltip-width`}>
            <div className={`${DEFAULT_CLASS}__header__title-container__title`}>{title}</div>
          </FlightTooltip>
        </div>
        <div className={`${DEFAULT_CLASS}__header__settings no-drag`}>
          <ModuleActions />
        </div>
      </div>
      <div className={`${DEFAULT_CLASS}__body`}>{!hasError ? getGraph(props) : <>Error loading module</>}</div>
    </div>
  );
};
