import React from 'react';
import { ReactComponent as YourAppIcon } from 'assets/icons/your-app-icon.svg';
import './PushPreviewCard.scss';

type TPushPreviewCardProps = {
  title: string;
  body: string;
  direction?: string;
};

function PushPreviewCard({ title, body, direction }: TPushPreviewCardProps) {
  return (
    <div className="push-preview-card" aria-label="push notification preview">
      <div className="push-preview-card__header">
        <div className="push-preview-card__header__app-name" aria-label="push notification app name">
          <YourAppIcon /> Your app
        </div>
        <div className="push-preview-card__header__time" aria-label="push notification time">
          now
        </div>
      </div>
      <div className="push-preview-card__title" aria-label="push notification title" dir={direction}>
        {title || '<Push Notification Title>'}
      </div>
      <div className="push-preview-card__body" aria-label="push notification body" dir={direction}>
        {body || '<Push Notification Body>'}
      </div>
    </div>
  );
}

export default PushPreviewCard;
