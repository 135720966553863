import React from 'react';
import { TIconProps } from '../types';

function TriggerIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2345_148632)">
        <path d="M7 2V13H10V22L17 10H13L16 2H7Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_2345_148632">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TriggerIcon;
