/* eslint-disable @typescript-eslint/no-explicit-any */
/*
  push.selector.ts
  a set of functions that take in the current state related to the currently
  displayed journey's pushes and derives useful data.
*/

import { PushState, PushStateItem, PushDependencies, PUSH_OPTION_DEFAULTS as DEFAULTS } from 'store/push/push.type';
import { selectStepRule } from 'store/rule/rule.selector';
import { getStepRuleSyntheticId } from 'store/helper';
import { convertSecondsToPeriod } from 'helpers/templated-experience.helper';
import { PushPayloadType } from 'interface/push/push.interface';
import { ContentState } from 'store/content/content.type';
import { RuleState } from 'store/rule/rule.type';
import { RootState } from 'store/store';
import { upperFirst } from 'lodash';
import pluralize from 'pluralize';

/**
 * Checks and returns the full delivery form object required by push context.
 * For the reminder template, pulls the scheduler options instead.
 * getDeliveryFormOptions and getDeliveryOptions can be re-organized or merged
 * pending the purge of the legacy canvas.
 * Requires the push refId for now to support older canvas.
 * @return {}
 */
export function getDeliveryFormOptions(teState: any, pushRefId: string, stepIdx: number) {
  const delivery = getDeliveryOptions(teState, pushRefId, stepIdx);
  const delay = convertSecondsToPeriod(getDelay(teState, stepIdx, pushRefId));
  return {
    max: delivery?.max ?? DEFAULTS.MAX,
    frequency: {
      total: 1,
      value: delivery?.frequency.value ?? 1,
      period: {
        key: delivery?.frequency.period ?? 'day',
        name: upperFirst(
          delivery?.frequency.period !== 'once'
            ? pluralize(delivery?.frequency.period ?? 'days')
            : delivery?.frequency.period,
        ),
      },
    },
    limitCheck: delivery && delivery.limit > 0 ? 'SELECTED' : 'UNSELECTED',
    limit: {
      key: delivery?.limit !== undefined ? delivery?.limit?.toString() : '',
      name: delivery?.limit === 0 ? 'Unlimited' : delivery?.limit === 1 ? 'One' : '',
    },
    // slightly confused as to if this needs to account for emitEveryEvaluationResult
    remain: delivery?.remain ? 'SELECTED' : 'UNSELECTED',
    delay: {
      selected: delay.value ? 'SELECTED' : 'UNSELECTED',
      value: delay.value,
      period: {
        key: delay.period,
        name: upperFirst(delay.period),
      },
    },
  };
}

/**
 * Determines if a particular push notification is "amplifying" a content or not
 * Requires the push refId
 * @return boolean
 */
export function isAmplified(teState: any, pushRefId: string) {
  if (!teState.push.allRefId.includes(pushRefId)) return false;

  const dependencies = teState.push.byRefId[pushRefId].dependencies;

  for (const key in dependencies) {
    if (dependencies[key].includes('dep.content-id') && teState.journey.templateType === 'app-content') return true;
  }

  return false;
}

/**
 * Checks and returns the delivery options for a push notification.
 * For the reminder template, pulls the scheduler options instead.
 * If all options are empty, return undefined.
 * Requires the push refId (and the step id for now, to be debated later)
 * @return {}
 */
export function getDeliveryOptions(teState: any, pushRefId: string, stepIdx: number): Delivery | undefined {
  if (!teState.push.allRefId.includes(pushRefId)) return undefined;

  const templateType: string = teState.journey.templateType;
  const push: PushStateItem = teState.push.byRefId[pushRefId];
  const rule = selectStepRule(teState, stepIdx);

  if (templateType === 'reminder') {
    if (!rule) return undefined;
    const teScheduler = teState.rule.startSchedulerMap[getStepRuleSyntheticId(stepIdx)];
    // legacy support
    const scheduler = rule.schedule?.startScheduler;
    if (!scheduler?.repeat?.frequency && !scheduler?.repeat?.limit) return undefined;

    return {
      frequency: {
        value: teScheduler.frequency ?? scheduler?.repeat?.frequency ?? 0,
        period: teScheduler.frequencyType ?? scheduler?.repeat?.frequencyType ?? 'once',
      },
      remain: true,
      limit: teScheduler.limit + 1 ?? scheduler?.repeat?.limit + 1,
    };
  } else if (templateType !== 'broadcast') {
    if (!push) return undefined;
    return {
      max: push.max ?? DEFAULTS.MAX,
      frequency: convertSecondsToPeriod(push.cooldown ?? DEFAULTS.COOLDOWN),
      remain: rule?.emitEveryEvaluationResult ?? false,
      limit: push.limit ?? DEFAULTS.LIMIT,
    };
  }

  return undefined;
}

/**
 * Checks if a dependency exists on a push and the refId of that
 * action (if it exists in application state already). Else returns undefined.
 * Requires a reference to the full state and the push refId to be checked.
 * @returns PushDependencies
 */
export function checkPushRefIds(state: any, refId: string) {
  const pushState: PushState = state.te.push;
  const contentState: ContentState = state.te.content;
  const ruleState: RuleState = state.te.rule;
  const returnState: PushDependencies = { rule: undefined, content: undefined };

  if (!pushState.allRefId.includes(refId)) return returnState;

  const push = pushState.byRefId[refId];
  let contentRefId = '';
  let ruleRefId = '';

  if (!push.dependencies) return returnState;

  for (const depId in push.dependencies) {
    if (push.dependencies[depId].includes('content')) {
      contentRefId = depId;
    }
    if (push.dependencies[depId].includes('rule')) {
      ruleRefId = depId;
    }
  }

  if (contentRefId && contentState.allRefId.includes(contentRefId)) {
    returnState.content = contentRefId;
  }
  if (ruleRefId && ruleState.allRefId.includes(ruleRefId)) {
    returnState.rule = ruleRefId;
  }

  return returnState;
}

/**
 * Checks a push's payload against payload types to determine the type and name.
 * Requires a reference to the full state object and the refId of the push.
 * @returns { type: PushPayloadType, leadsTo: string, id: string } | undefined
 */
export function getPushPayloadType(
  state: RootState,
  refId: string,
):
  | {
      type: PushPayloadType;
      leadsTo: string;
      id: string;
    }
  | undefined {
  const pushState = state.te.push;
  const pushPayloadTypes = state.templatedExperience.pushTypes;

  if (!pushState.allRefId.includes(refId)) return undefined;
  const push = pushState.byRefId[refId] as PushStateItem;
  if (!pushPayloadTypes.length) {
    if (push.url || push.pushPayloadType === 'weblink') {
      return { type: 'weblink', leadsTo: 'Weblink', id: '' };
    } else if (push.contentId || push.pushPayloadType === 'content') {
      return { type: 'content', leadsTo: 'In-app content', id: '' };
    } else if (push.actionLinkScheme || push.pushPayloadType === 'actionlink') {
      return { type: 'actionlink', leadsTo: 'Univeral Link', id: '' };
    } else if (push.pushPayloadTypeId || push.pushPayloadType === 'custom') {
      return { type: 'custom', leadsTo: '', id: '' };
    } else {
      return { type: 'basic', leadsTo: 'App home screen', id: '' };
    }
  }

  const pushPayloadType = pushPayloadTypes.find((item: any) => {
    return item.id === push.pushPayloadTypeId?.toUpperCase();
  });

  if (pushPayloadType?.name === 'Web Link' || push.url) {
    return { type: 'weblink', leadsTo: 'Weblink', id: pushPayloadType?.id };
  } else if (pushPayloadType?.name === 'Content' || push.contentId) {
    return { type: 'content', leadsTo: 'In-app content', id: pushPayloadType?.id };
  } else if (pushPayloadType?.name === 'Action Link' || push.actionLinkScheme) {
    return { type: 'actionlink', leadsTo: 'Universal Link', id: pushPayloadType?.id };
  } else if (pushPayloadType) {
    return { type: 'custom', leadsTo: pushPayloadType?.name, id: pushPayloadType?.id };
  } else {
    return { type: 'basic', leadsTo: 'App home screen', id: '' };
  }
}

/**
 * Checks a step's rule and push to determine a step's delay
 * Requires a reference to the te state object and the step index
 * @returns number
 */
export function getDelay(teState: RootState['te'], stepIndex: number, pushId?: string): number {
  const templateType = teState.journey.templateType;
  let retval = 0;

  if (templateType === 'reminder') {
    const rule = selectStepRule(teState, stepIndex);
    retval = parseInt(
      teState.rule.startSchedulerMap[getStepRuleSyntheticId(stepIndex)]?.modifier ||
        rule?.schedule?.startScheduler?.contextualRule?.modifier ||
        '0',
    );
  } else {
    const push = pushId ? (teState.push.byRefId[pushId] as PushStateItem) : undefined;

    retval = push?.delay ?? DEFAULTS.LIMIT;
  }

  return retval;
}

export interface Delivery {
  frequency: {
    value: number;
    period: string;
  };
  remain: boolean;
  limit: number;
  max?: number;
}
