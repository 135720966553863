import { Location } from 'interface/location/location.interface';

export enum LocationActionTypes {
  SET_LOCATIONS = 'SET_LOCATIONS',
  SET_MATCHING_LOCATIONS_WITH_LABELS = 'SET_MATCHING_LOCATIONS_WITH_LABELS',
  SET_SAVE_LOCATION = 'SET_SAVE_LOCATION',
}

export interface LocationState {
  locations: Location[];
  areLocationsLoading: boolean;
  numMatchingLocations: number;
  areMatchingLocationsLoading: boolean;
  savedLocation: Location | null;
  isLocationSaving: boolean;
}

export type SetLocationPayload = {
  locations: Location[] | [];
  areLocationsLoading: boolean;
};

export type SetMatchingLocationsWithLabelsPayload = {
  numMatchingLocations: number;
  areMatchingLocationsLoading: boolean;
};

export type SetSaveLocationPayload = {
  isLocationSaving: boolean;
  savedLocation: Location | null;
};
