import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';

import * as AuthActions from 'store/auth/auth.action';
import useActions from 'hooks/useActions';
import useFeatureFlag from 'hooks/useFeatureFlag';

type TPrivateRouterProps = {
  exact: boolean;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<RouteComponentProps<any>> | React.FC<any>;
  requiredFlag?: string;
};

export default function PrivateRoute(props: TPrivateRouterProps) {
  const history = useHistory();
  const [hasRequiredFlags, setHasRequiredFlags] = useState(false);
  const authActions = useActions(AuthActions);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const auth = useSelector((state: any) => state.auth);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const featureFlag = useSelector((state: any) => state.featureFlag);
  const { flags } = useFeatureFlag();
  const { exact, path, component: RenderComponent, requiredFlag = '' } = props;

  // authenticate user
  useEffect(() => {
    if (!auth.authenticated) {
      authActions.authenticateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if feature flag matches
  useEffect(() => {
    if (!featureFlag.isInitialized) return;
    if (!requiredFlag) {
      setHasRequiredFlags(true);
    } else {
      if (flags[requiredFlag]) {
        setHasRequiredFlags(true);
      } else {
        history.push('/no-access');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags, requiredFlag, featureFlag.isInitialized]);

  return auth.authenticated && featureFlag.isInitialized && hasRequiredFlags ? (
    <Route exact={exact} path={path} render={(props) => <RenderComponent {...props} />} />
  ) : (
    <></>
  );
}
