import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import type { AppThunkDispatch } from 'store/store';
import {
  getApprovalSettingsAction,
  getApprovalSettingsErrorAction,
  getApprovalSettingsLoadingAction,
} from './approval.action';

const templatedExperienceAPI = new TemplatedExperienceAPI();

export function setApprovalSettingsThunk() {
  return async (dispatch: AppThunkDispatch) => {
    try {
      dispatch(getApprovalSettingsLoadingAction({ isLoading: true }));
      const settings = await templatedExperienceAPI.getApprovalSettings();
      dispatch(getApprovalSettingsAction({ settings, isInitialized: true }));
    } catch {
      dispatch(
        getApprovalSettingsErrorAction({
          error: { hasError: true, errorMessage: 'Unable to get approval settings' },
          settings: null,
          isInitialized: false,
        }),
      );
    } finally {
      dispatch(getApprovalSettingsLoadingAction({ isLoading: false }));
    }
  };
}
