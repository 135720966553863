import { useCallback, useEffect } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { createContentInstances } from 'store/content/content.thunk';
import { Content, ContentCreationPayload } from 'interface/content/content.interface';
import { ContentStateItem, LoadingStatus as LOAD_STATUS } from 'store/content/content.type';
import {
  generateContentCreationPayloadFromContentInstance,
  generateContentCreationPayloadFromSchemaConstraints,
} from 'helpers/content.helper';
import { isEmpty } from 'lodash';

/**
 * Exposes functions related to transforming, creating, and editing contents.
 * The flag pregenerate allows for the automatic generation of content instances
 * upon loading a page.
 * */
export default function useContentCreationManager(pregenerate = false) {
  const creationStatus = useSelector((state) => state.te.content.creationStatus);
  const allContentActions: ContentStateItem[][] = useSelector(
    (state) =>
      state.te.journey.steps?.map((step) => step.content.map((refId) => state.te.content.byRefId[refId]) || []) || [],
  );
  const dispatch = useDispatch();

  const createContent = useCallback(
    async (contents: ContentCreationPayload[], refIds: string[]) => {
      const createdContents = await dispatch(createContentInstances(contents, refIds));
      if (!createdContents) return;

      const payload: { [refId: string]: Content } = {};
      refIds.forEach((refId, idx) => {
        payload[refId] = createdContents[idx];
      });
      dispatch({ type: 'UPDATE_CONTENT', payload });
    },
    [dispatch],
  );

  useEffect(() => {
    (async () => {
      if (pregenerate && creationStatus === LOAD_STATUS.INITIAL) {
        const contentInstances: ContentCreationPayload[] = [];
        const refIds: string[] = [];

        for (const stepContentActions of allContentActions) {
          for (const stepContentAction of stepContentActions) {
            // Skip creating the content instance if already created
            if (stepContentAction.id) {
              continue;
            }

            if (!isEmpty(stepContentAction.constraints?.schemaConstraints)) {
              contentInstances.push(generateContentCreationPayloadFromSchemaConstraints(stepContentAction));
              refIds.push(stepContentAction.refId);
            } else if (stepContentAction.constraints?.contentInstance?.defaultValue) {
              contentInstances.push(await generateContentCreationPayloadFromContentInstance(stepContentAction));
              refIds.push(stepContentAction.refId);
            }
          }
        }

        if (contentInstances.length) {
          createContent(contentInstances, refIds);
        }
      }
    })();
  }, [allContentActions, pregenerate, creationStatus, createContent]);
}
