import {
  ContentState,
  ContentStateItem,
  CONTENT_ACTION_TYPES,
  LoadingStatus as LOAD_STATUS,
} from 'store/content/content.type';
import { createReducer, getContentActionsFromStep } from 'store/helper';
import { Step, InstanceContentAction, TemplateContentAction } from 'interface/experience/experience.interface';
import { SetTemplateAction, SetInstanceAction } from 'store/templated-experience/templated-experience.type';
import {
  ContentFetchedAction,
  RemoveContentAction,
  UpdateContentAction,
  UpdateContentCreationStatusAction,
  BatchInsertContentsAction,
  RemoveStepAction,
  SetUINodeAttribute,
  ActionTypes,
} from 'store/actionTypes';

const initialState: ContentState = {
  creationStatus: LOAD_STATUS.NA,
  byRefId: {},
  allRefId: [],
  // contentInstances: [],
  // contentInstanceById: {},
  // contentTemplates: [],
  // contentTemplateById: {},
};

function updateCreationStatus(state: ContentState, action: UpdateContentCreationStatusAction) {
  return {
    ...state,
    creationStatus: action.payload.creationStatus,
  };
}

function setInstance(state: ContentState, action: SetInstanceAction) {
  if (!action.payload.updateNewReducer) return state;

  const byRefId: ContentState['byRefId'] = {};
  const allRefId: ContentState['allRefId'] = [];

  action.payload?.instance?.steps.forEach((step: Step, idx: number) => {
    const contents = getContentActionsFromStep(step);

    contents.forEach((content: InstanceContentAction) => {
      byRefId[content.refId] = flattenInstance(content, idx);
      allRefId.push(content.refId);
    });
  });

  return {
    ...state,
    byRefId,
    allRefId,
  };
}

function setTemplate(state: ContentState, action: SetTemplateAction) {
  const preserveInstance = action.payload.preserveInstance;
  const byRefId: ContentState['byRefId'] = preserveInstance ? state.byRefId : {};
  const allRefId: ContentState['allRefId'] = preserveInstance ? state.allRefId : [];
  const creationStatus = preserveInstance ? state.creationStatus : LOAD_STATUS.INITIAL;

  action.payload?.template?.steps.forEach((step, idx: number) => {
    const contents = getContentActionsFromStep(step);

    if (preserveInstance) {
      contents.forEach((content: TemplateContentAction) => {
        if (byRefId[content.refId]) {
          byRefId[content.refId] = {
            ...state.byRefId[content.refId],
            constraints: content.payload?.payload?.constraints,
            isOptional: content.isOptional,
          };
        } else {
          byRefId[content.refId] = flattenTemplate(content, idx);
          allRefId.push(content.refId);
        }
      });
    } else {
      contents.forEach((content: TemplateContentAction) => {
        byRefId[content.refId] = flattenTemplate(content, idx);
        allRefId.push(content.refId);
      });
    }
  });

  return {
    ...state,
    byRefId,
    allRefId,
    creationStatus,
  };
}

function updateContents(state: ContentState, action: UpdateContentAction) {
  const updatedContentActions: ContentState['byRefId'] = {};

  for (const refId in action.payload) {
    if (state.allRefId.includes(refId)) {
      const content = action.payload[refId];
      updatedContentActions[refId] = {
        ...state.byRefId[refId],
        id: content.id,
        templateId: content.templateId,
        payload: content,
        localizations: content.localizations,
        templateType: content.templateType,
        iconUrl: content.iconUrl,
      };
    }
  }
  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      ...updatedContentActions,
    },
  };
}

function contentFetched(state: ContentState, action: ContentFetchedAction) {
  if (!state.allRefId.includes(action.payload.refId)) return state;

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [action.payload.refId]: {
        ...state.byRefId[action.payload.refId],
        payload: action.payload.content,
        id: action.payload.content.id,
        templateId: action.payload.content.templateId,
      },
    },
  };
}

function insertContents(state: ContentState, action: BatchInsertContentsAction) {
  const defaultContents: ContentState['byRefId'] = {};

  action.payload.contents.forEach((content, idx) => {
    const refId = action.payload.refIds[idx];
    defaultContents[refId] = {
      refId: refId,
      actionType: CONTENT_ACTION_TYPES.INSTANCE,
      stepIdx: action.payload.stepIdx,
      id: content.id,
      templateId: content.templateId,
      payload: content,
      localizations: content.localizations,
      templateType: content.templateType,
      isOptional: true,
      enableOptionalNode: true,
    };
  });

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      ...defaultContents,
    },
    allRefId: [...state.allRefId, ...action.payload.refIds],
  };
}

function removeContent(state: ContentState, action: RemoveContentAction) {
  if (action.payload.refIds.length === 0) return state;

  const refIdsToDelete: string[] = [];
  let byRefId: ContentState['byRefId'] = { ...state.byRefId };

  action.payload.refIds.forEach((refId, idx) => {
    if (!action.payload.required?.[idx]) refIdsToDelete.push(refId);
  });

  action.payload.refIds.forEach((refId) => {
    if (!refIdsToDelete.includes(refId)) {
      byRefId = {
        ...byRefId,
        [refId]: {
          ...byRefId[refId],
          id: '',
          templateId: '',
          payload: undefined,
          templateType: undefined,
          localizations: undefined,
        },
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [refId]: value, ...newByRefId } = byRefId;
      byRefId = newByRefId;
    }
  });

  return {
    ...state,
    byRefId,
    allRefId: state.allRefId.filter(
      (item) =>
        !state.byRefId[item].isOptional || !refIdsToDelete.includes(item) || !action.payload.refIds.includes(item),
    ),
  };
}

function removeStep(state: ContentState, action: RemoveStepAction) {
  if (action.payload.content.length === 0) return state;
  let byRefId: ContentState['byRefId'] = { ...state.byRefId };

  action.payload.content.forEach((refId) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [refId]: value, ...newByRefId } = byRefId;
    byRefId = newByRefId;
  });
  return {
    ...state,
    byRefId,
    allRefId: state.allRefId.filter((item) => !action.payload.content.includes(item)),
  };
}

function clearJourney() {
  return initialState;
}

function SetNodeAttribute(state: ContentState, action: SetUINodeAttribute) {
  const contentToUpdate = state.byRefId[action.payload.refId];

  if (contentToUpdate) {
    return {
      ...state,
      byRefId: {
        ...state.byRefId,
        [action.payload.refId]: {
          ...contentToUpdate,
          ...action.payload.fields,
        },
      },
    };
  }

  return state;
}

// these flattenings focus only on bringing forward important fields
// but leave payloads alone
function flattenTemplate(content: TemplateContentAction, stepIdx: number): ContentStateItem {
  return {
    ...content,
    stepIdx,
    dependencies: content.dependencies,
    constraints: content.payload?.payload?.constraints,
    refId: content.refId,
    prototypeId: content.templateId,
    templateId: content.payload?.payload?.contentTemplate?.id,
    enableOptionalNode: !content.isOptional,
    isOptional: content.isOptional,
    iconUrl: content.payload?.iconUrl,
  };
}

function flattenInstance(content: InstanceContentAction, stepIdx: number): ContentStateItem {
  return {
    ...content,
    stepIdx,
    dependencies: content.dependencies,
    localizations: content.payload?.localizations,
    templateType: content.payload?.templateType,
    refId: content.refId,
    prototypeId: content.templateId,
    templateId: content.payload?.templateId,
    id: content.id,
    enableOptionalNode: true,
    isOptional: true,
    iconUrl: content.payload?.iconUrl,
  };
}

// TODO: Add UpdateContentSimple action to handle toggling enableOptionalNode
// Alternatively, add a cross-reducer UpdateEnableState to allow a generic toggling
export default createReducer(initialState, {
  SET_INSTANCE: setInstance,
  SET_TEMPLATE: setTemplate,
  UPDATE_CONTENT: updateContents,
  CONTENT_FETCHED: contentFetched,
  INSERT_CONTENTS: insertContents,
  REMOVE_CONTENT: removeContent,
  UPDATE_CONTENT_CREATE_STATUS: updateCreationStatus,
  CLEAR_JOURNEY: clearJourney,
  REMOVE_STEP: removeStep,
  [ActionTypes.SET_UI_NODE_ATTR]: SetNodeAttribute,
});
