import React, { useEffect } from 'react';
import './MerchantPortal.scss';
import MerchantEmpty from 'components/MerchantPortal/MerchantEmpty/MerchantEmpty';
import useMerchantsMock from 'hooks/useMerchantsMock';
import MerchantList from 'components/MerchantPortal/MerchantList/MerchantList';

const CLASS_MAIN = 'merchant-portal';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  EMPTY_CONTAINER: `${CLASS_MAIN}__empty-container`,
};

const MerchantPortal: React.FC<React.ComponentProps<'div'>> = () => {
  const { merchantsData, areMerchantsLoading } = useMerchantsMock();
  useEffect(() => {
    document.title = 'Merchant Portal | Experience Studio @ Flybits';
  }, []);

  return (
    <div className={CLASS_MAIN}>
      {!areMerchantsLoading && !merchantsData.length ? (
        <div className={CLASSES.EMPTY_CONTAINER}>
          <MerchantEmpty />
        </div>
      ) : (
        <div className={CLASSES.BODY}>
          <MerchantList />
        </div>
      )}
    </div>
  );
};
export default MerchantPortal;
