import React from 'react';
import { TIconProps } from '../types';

function PendingIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6667 1.35327V2.69994C11.5934 3.05994 13.6667 5.71994 13.3067 8.6466C13 11.0733 11.0934 12.9999 8.6667 13.2866V14.6199C12.3334 14.2533 15 10.9999 14.6334 7.33327C14.3334 4.1666 11.82 1.6666 8.6667 1.35327ZM7.33337 1.37327C6.03337 1.49994 4.79337 1.99994 3.78003 2.83994L4.73337 3.8266C5.48003 3.2266 6.38003 2.83994 7.33337 2.7066V1.37327ZM2.84003 3.77994C2.00647 4.7915 1.4935 6.02866 1.3667 7.33327H2.70003C2.8267 6.3866 3.20003 5.4866 3.79337 4.73327L2.84003 3.77994ZM1.37337 8.6666C1.5067 9.97327 2.02003 11.2066 2.8467 12.2199L3.79337 11.2666C3.20465 10.513 2.82934 9.615 2.7067 8.6666H1.37337ZM4.73337 12.2466L3.78003 13.1599C4.78989 14.0017 6.02621 14.526 7.33337 14.6666V13.3333C6.38497 13.2106 5.48697 12.8353 4.73337 12.2466ZM8.33337 4.6666V8.1666L11.3334 9.9466L10.8334 10.7666L7.33337 8.6666V4.6666H8.33337Z"
        fill={fill}
      />
    </svg>
  );
}

export default PendingIcon;
