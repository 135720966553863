import Storage from './storage';
import { store } from 'store/store';
import { AuthActionType } from 'store/auth/auth.type';

class Session {
  private storage: Storage;
  private tenantId?: string;

  constructor(tenantId?: string) {
    this.storage = new Storage();
    if (tenantId) this.tenantId = tenantId;
  }

  getToken(tenantIdFromParams?: string): Promise<string> {
    return this.storage.getItem(`${tenantIdFromParams || this.tenantId}+token`);
  }

  getOrigin(tenantIdFromParams?: string): Promise<string> {
    return this.storage.getItem(`${tenantIdFromParams || this.tenantId}+origin`);
  }

  async setToken(tenantId: string, token: string) {
    await this.storage.setItem(`${tenantId}+token`, token);
  }

  async removeToken() {
    const tenantId = window?.location?.pathname?.split('/')[2];
    await this.storage.removeItem(`${tenantId}+token`);
  }

  async unauthorizeUser() {
    await this.removeToken();
    store.dispatch({ type: AuthActionType.UNAUTHORIZE_USER });
  }
}

export default Session;
