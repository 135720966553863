import { TEASingleValue } from 'interface/templated-experience/analytics.interface';

export const AnalyticsContentEngagementBySourceActionType = {
  SET_CONTENT_VIEW_PUSH_LOADING: 'SET_CONTENT_VIEW_PUSH_LOADING',
  SET_CONTENT_CLICK_PUSH_LOADING: 'SET_CONTENT_CLICK_PUSH_LOADING',
  SET_CONTENT_VIEW_EXPERIENCE_LOADING: 'SET_CONTENT_VIEW_EXPERIENCE_LOADING',
  SET_CONTENT_CLICK_EXPERIENCE_LOADING: 'SET_CONTENT_CLICK_EXPERIENCE_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_CONTENT_VIEW_PUSH_DATA: 'SET_CONTENT_VIEW_PUSH_DATA',
  SET_CONTENT_CLICK_PUSH_DATA: 'SET_CONTENT_CLICK_PUSH_DATA',
  SET_CONTENT_VIEW_EXPERIENCE_DATA: 'SET_CONTENT_VIEW_EXPERIENCE_DATA',
  SET_CONTENT_CLICK_EXPERIENCE_DATA: 'SET_CONTENT_CLICK_EXPERIENCE_DATA',
};

export interface AnalyticsContentEngagementBySourceState {
  totalContentViewsCountFromPush: TEASingleValue;
  totalContentClicksCountFromPush: TEASingleValue;
  totalContentViewsCountFromExperience: TEASingleValue;
  totalContentClicksCountFromExperience: TEASingleValue;
  isTotalContentViewsCountFromPushLoading: boolean;
  isTotalContentClicksCountFromPushLoading: boolean;
  isTotalContentViewsCountFromExperienceLoading: boolean;
  isTotalContentClicksCountFromExperienceLoading: boolean;
  errorMessage: string;
}

export interface SetDataAction {
  type: string;
  payload: {
    value: number;
  };
}

export interface SetLoadingAction {
  type: string;
  payload: boolean;
}

export interface SetErrorAction {
  type: typeof AnalyticsContentEngagementBySourceActionType.SET_ERROR;
  payload: string;
}
