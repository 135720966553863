import React, { useEffect, useRef } from 'react';
import { TContentPreviewCardProps } from '../types';
import './ContentDetailPreviewCard.scss';

const MAIN_CLASS = 'content-detail-preview-card';
const CLASSES = {
  IMAGE: `${MAIN_CLASS}__image`,
  CONTENT: `${MAIN_CLASS}__content`,
  NAV: `${MAIN_CLASS}__nav`,
  HEADER: `${MAIN_CLASS}__header`,
  TITLE: `${MAIN_CLASS}__title`,
  DESCRIPTION: `${MAIN_CLASS}__description`,
};

export default function ContentDetailPreviewCard({ header, title, description, imageUrl }: TContentPreviewCardProps) {
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const descriptionEl = descriptionRef.current;
    if (descriptionEl) {
      const isDescriptionHTML = /<\/?[a-z][\s\S]*>/i.test(description || '');
      if (isDescriptionHTML) {
        descriptionEl.innerHTML = description || '';
      } else {
        descriptionEl.innerText = description || '';
      }
    }
  }, [description]);

  return (
    <div className={MAIN_CLASS} aria-label="content preview">
      <div className={CLASSES.NAV} aria-label="content back">
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.62421 12.5233L0.32375 7.26197C0.100417 7.0405 0.100417 6.68268 0.32375 6.46063L5.62421 1.1993C5.94661 0.878879 6.47116 0.878879 6.79413 1.1993C7.11653 1.51972 7.11653 2.03976 6.79413 2.36018L2.25988 6.86159L6.79413 11.3619C7.11653 11.6828 7.11653 12.2029 6.79413 12.5233C6.47116 12.8437 5.94661 12.8437 5.62421 12.5233Z"
            fill="#2371F1"
          />
        </svg>
        Back
      </div>
      <div className={CLASSES.CONTENT}>
        <div className={CLASSES.HEADER} aria-label="content header">
          {header}
        </div>
        <div className={CLASSES.TITLE} aria-label="content title">
          {title}
        </div>
        {imageUrl && (
          <div className={CLASSES.IMAGE} aria-label="content image">
            <img src={imageUrl} alt="preview" />
          </div>
        )}
        <div className={CLASSES.DESCRIPTION} aria-label="content description" ref={descriptionRef}></div>
      </div>
    </div>
  );
}
