import React from 'react';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { getStepMetadata } from 'store/journey/journey.selector';
import { ContentListItem } from 'components/ExperienceCanvas/types';
import { FlightButton, FlightTooltip } from '@flybits/design-system';
import PushNotificationIcon from 'pages/ExperienceCanvas/assets/icons/PushNotificationIcon';
import DragAndDropIcon from 'pages/ExperienceCanvas/assets/icons/DragAndDropIcon';
import { EVENT_KEYS } from 'types/events';
import './ContentList.scss';
import CircleEditIcon from 'pages/ExperienceCanvas/assets/icons/CircleEditIcon';
import CircleRemoveIcon from 'pages/ExperienceCanvas/assets/icons/CircleRemoveIcon';

type TContentListProps = {
  contents: ContentListItem[];
  onDeleteClick: (content: ContentListItem) => void;
  onEditClick: (content: ContentListItem) => void;
  onMakePrimaryClick: (oldPrimaryRefId: string, newPrimaryRefId: string) => void;
  onSelectContentClick: (content: ContentListItem) => void;
  onDisconnectClick?: (depRefId: string) => void;
  currentStepIdx: number;
};

type TItemProps = Omit<TContentListProps, 'contents'> & {
  primaryRefId: string;
  content: ContentListItem;
  externalContents: string[];
};

const CLASS_PREFIX = 'content-list';
const CLASSES = {
  MAIN: CLASS_PREFIX,
  TYPE_LIST: `${CLASS_PREFIX}__type-list`,
  TYPE: `${CLASS_PREFIX}__type`,
  TYPE_TITLE: `${CLASS_PREFIX}__type-title`,
  TYPE_PRIMARY: `${CLASS_PREFIX}__type--is-primary`,
  TYPE_SECONDARY: `${CLASS_PREFIX}__type--is-secondary`,
  CONTENT_LIST: `${CLASS_PREFIX}__list`,
  CONTENT: `${CLASS_PREFIX}__content`,
  CONTENT_TITLE: `${CLASS_PREFIX}__content-name`,
  DRAG_ICON: `${CLASS_PREFIX}__drag-icon`,
  MODIFY: `${CLASS_PREFIX}__modify`,
  TOOLTIP: `${CLASS_PREFIX}__tooltip`,
  ACTIONS: `${CLASS_PREFIX}__actions`,
  SELECT_CONTENT: `${CLASS_PREFIX}__select-content`,
  CENTER_TITLE: `${CLASS_PREFIX}__center-title`,
  SEPARATOR: `${CLASS_PREFIX}__separator`,
};

const editAndDeleteIconFill = '#82899B';

function BlankContentItem({
  onSelectContentClick,
  onDisconnectClick,
  content,
  isExternal,
}: Pick<TItemProps, 'onSelectContentClick' | 'onDisconnectClick' | 'content'> & {
  isExternal: boolean;
}) {
  const journeyState = useSelector((state) => state.te.journey);
  const isReadOnly = content.isReadOnly;
  return (
    <div
      className={`${CLASSES.CONTENT} ${CLASSES.SELECT_CONTENT}`}
      aria-label="open content selector"
      tabIndex={0}
      role="button"
      onClick={() => {
        isExternal
          ? onDisconnectClick && !isReadOnly && onDisconnectClick(content.refId)
          : onSelectContentClick(content);
      }}
      onKeyDown={(e) => {
        if (e.key === EVENT_KEYS.ENTER) {
          isExternal
            ? onDisconnectClick && !isReadOnly && onDisconnectClick(content.refId)
            : onSelectContentClick(content);
        }
      }}
      draggable
    >
      <div className={CLASSES.CENTER_TITLE}>
        {isExternal
          ? `< Choose content in ${getStepMetadata(journeyState, content.stepIdx).name}\
          ${isReadOnly ? '' : '. Click to detach from current touchpoint.'} >`
          : '< Select Content >'}
      </div>
    </div>
  );
}

function ListItem({
  primaryRefId,
  content,
  onEditClick,
  onDeleteClick,
  onDisconnectClick,
  onMakePrimaryClick,
  onSelectContentClick,
  externalContents,
}: TItemProps) {
  const hasId = !!content.id;
  const isPrimary = primaryRefId === content.refId;
  const journeyState = useSelector((state) => state.te.journey);
  const isExternal = externalContents.includes(content.refId);
  const stepHasExternal = !!externalContents.length;

  return (
    <>
      {hasId ? (
        <div className={CLASSES.CONTENT} draggable>
          <div className={CLASSES.DRAG_ICON}>
            <DragAndDropIcon />
          </div>
          <div className={CLASSES.CONTENT_TITLE}>
            {content.name}
            {isExternal ? ` (From ${getStepMetadata(journeyState, content.stepIdx).name})` : ''}
          </div>
          {!isExternal && (
            <div className={CLASSES.MODIFY}>
              <FlightTooltip
                className={CLASSES.TOOLTIP}
                direction="top"
                isEnabled={content.isReadOnly}
                description={'Content restricted by the template creator from editing.'}
              >
                <button type="button" onClick={() => onEditClick(content)} disabled={content.isReadOnly}>
                  <CircleEditIcon fill={editAndDeleteIconFill} />
                </button>
              </FlightTooltip>
            </div>
          )}
          {!isExternal && (
            <div className={CLASSES.MODIFY}>
              <FlightTooltip
                className={CLASSES.TOOLTIP}
                direction="top"
                isEnabled={content.isReadOnly}
                description={'Content restricted by the template creator from deleting.'}
              >
                <button type="button" onClick={() => onDeleteClick(content)} disabled={content.isReadOnly}>
                  <CircleRemoveIcon fill={editAndDeleteIconFill} />
                </button>
              </FlightTooltip>
            </div>
          )}
          {isExternal && !content.isReadOnly && (
            <div className={CLASSES.MODIFY}>
              <FlightTooltip
                className={CLASSES.TOOLTIP}
                direction="top"
                isEnabled={isExternal}
                description={'Detach external touchpoint content'}
              >
                <button type="button" onClick={() => onDisconnectClick && onDisconnectClick(content.refId)}>
                  <CircleRemoveIcon fill={editAndDeleteIconFill} />
                </button>
              </FlightTooltip>
            </div>
          )}
          {!isPrimary && !stepHasExternal && (
            <div className={CLASSES.ACTIONS}>
              <FlightButton
                theme="secondary"
                size="small"
                label="Make Primary"
                onClick={() => onMakePrimaryClick(primaryRefId, content.refId)}
              />
            </div>
          )}
        </div>
      ) : (
        <BlankContentItem
          onSelectContentClick={onSelectContentClick}
          onDisconnectClick={onDisconnectClick}
          content={content}
          isExternal={isExternal}
        />
      )}
    </>
  );
}

export default function ContentList({ contents, ...props }: TContentListProps) {
  const primaryContent = contents.find((c) => c.type === 'primary');
  const externalContents = contents.filter((c) => c.stepIdx !== props.currentStepIdx).map((c) => c.refId);

  if (contents.length === 0) return null;

  return (
    <div className={CLASSES.MAIN}>
      {contents.length > 1 && (
        <div className={CLASSES.TYPE_LIST}>
          <div className={CLASSES.TYPE} />
          <div className={`${CLASSES.TYPE} ${CLASSES.TYPE_PRIMARY}`}>
            <div className={CLASSES.TYPE_TITLE}>
              <PushNotificationIcon />
              Primary
            </div>
          </div>
          <div className={`${CLASSES.TYPE} ${CLASSES.TYPE_SECONDARY}`}>
            <div className={CLASSES.TYPE_TITLE}>
              <PushNotificationIcon />
              Secondary
            </div>
          </div>
        </div>
      )}
      <div className={CLASSES.CONTENT_LIST}>
        <div className={CLASSES.CONTENT}>
          <div className={CLASSES.CONTENT_TITLE}>Name</div>
        </div>
        {primaryContent && (
          <ListItem
            primaryRefId={primaryContent.refId}
            content={primaryContent}
            externalContents={externalContents}
            {...props}
          />
        )}
        {contents.length >= 2 && (
          <>
            <div className={CLASSES.SEPARATOR} />
            {contents
              .filter((c) => c.type === 'secondary')
              .map((c, i) => (
                <ListItem
                  key={i}
                  primaryRefId={primaryContent?.refId ?? ''}
                  content={c}
                  externalContents={externalContents}
                  {...props}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
}
