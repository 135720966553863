import './CreateFirstModule.scss';

import React, { useContext } from 'react';
import { FlightButton } from '@flybits/design-system';
import { ReactComponent as CreateModuleImage } from 'assets/images/create-zm-module-image.svg';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import { MODULE_LAYOUTS } from '../types';

const CLASS_MAIN = 'create-first-module';
const CLASS_CONTAINER = `${CLASS_MAIN}__container`;
const CLASS_IMAGE = `${CLASS_CONTAINER}__image`;
const CLASS_INFO = `${CLASS_CONTAINER}__info`;
const CLASS_TITLE = `${CLASS_INFO}__title`;
const CLASS_SUBTITLE = `${CLASS_INFO}__subtitle`;
const CLASS_BUTTON = `${CLASS_MAIN}__button`;

function CreateFirstModule() {
  const { setSelectedModule, setShowModuleDetailsSlideout } = useContext(PrioritizationContext);

  return (
    <div className={CLASS_MAIN}>
      <div className={CLASS_CONTAINER}>
        <div className={CLASS_IMAGE}>
          <CreateModuleImage />
        </div>
        <div className={CLASS_INFO}>
          <div className={CLASS_TITLE}>Create a module</div>
          <div className={CLASS_SUBTITLE}>There are currently no modules in this zone</div>
        </div>
      </div>
      <FlightButton
        className={CLASS_BUTTON}
        iconLeft="add"
        label={'Add a module'}
        onClick={() => {
          // Set default layout as `vertical` to pre-select inside the `ModuleInfo` tab
          setSelectedModule({ layout: MODULE_LAYOUTS.VERTICAL });
          setShowModuleDetailsSlideout(true);
        }}
      />
    </div>
  );
}

export default CreateFirstModule;
