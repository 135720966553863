import { PushPayloadType } from 'interface/push/push.interface';
import { JourneyState, StateStep, Dependency } from 'store/journey/journey.type';
import { PushStateItem } from 'store/push/push.type';
import { ContentActionTypes, LoadingStatus } from 'store/content/content.type';
import { Content } from 'interface/content/content.interface';
import { RuleActionTypes, RuleTriggerType, SchedulerStateItem, RuleStateItem } from './rule/rule.type';
import { JourneyRulePayload, Scheduler } from 'interface/rule/rule.interface';

export enum ActionTypes {
  CLEAR_JOURNEY = 'CLEAR_JOURNEY',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SET_UI_NODE_ATTR = 'SET_UI_NODE_ATTR',
}

/**
 * Toggle a UI node's attributes.  Requires an entity Id.
 * */
export type SetUINodeAttribute = {
  type: ActionTypes.SET_UI_NODE_ATTR;
  // a single attribute for now, can migrate UI only attributes for nodes later.
  payload: {
    refId: string;
    fields: {
      enableOptionalNode: boolean;
    };
  };
};

export type UpdateJourneyAction = {
  type: 'UPDATE_JOURNEY';
  payload: Partial<JourneyState>;
};

export type ClearErrorAction = {
  type: ActionTypes.CLEAR_ERROR;
  payload: {
    errorIdx: number;
  };
};

export type ClearJourneyAction = {
  type: ActionTypes.CLEAR_JOURNEY;
};

export type UpsertScheduleAction = {
  type: 'UPDATE_SCHEDULE';
  payload: Partial<SchedulerStateItem> & {
    stepIdx: number;
    type: 'start' | 'end' | 'both';
  };
};

export type ClearScheduleAction = {
  type: 'UPDATE_SCHEDULE';
  payload: {
    stepIdx: number;
    type: 'start' | 'end' | 'both';
  };
};

export type UpdateStepAction = {
  type: 'UPDATE_STEP';
  payload: {
    stepIdx: number;
    fields: Partial<StateStep>;
  };
};

export type UpdateStepContentNamesAction = {
  type: 'UPDATE_STEP_CONTENT_NAME';
  payload: {
    stepIndex: number;
    contentNodes?: {
      [refId: string]: { name: string };
    };
  };
};

export type UpdateStepWebhookNamesAction = {
  type: 'UPDATE_STEP_WEBHOOK_NAME';
  payload: {
    stepIndex: number;
    webhookNodes?: {
      [refId: string]: { name: string };
    };
  };
};

export type ChangePrimaryContentAction = {
  type: 'CHANGE_PRIMARY_CONTENT';
  payload: {
    oldPrimaryRefId: string;
    newPrimaryRefId: string;
    stepIdx: number;
    affectedEntities: { refId: string; var: string }[];
  };
};

export type UpdateContentCreationStatusAction = {
  type: 'UPDATE_CONTENT_CREATE_STATUS';
  payload: { creationStatus: LoadingStatus };
};

export type UpdatePushSimpleAction = {
  type: 'UPDATE_PUSH_SIMPLE';
  payload: {
    refId: string;
    fields: Partial<PushStateItem>;
    stepIdx?: number;
  };
};

export type UpdatePushAction = {
  type: 'UPDATE_PUSH';
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    form: any;
    pushTypeId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deliveryOptions?: any;
    type: PushPayloadType;
    refId: string;
    templateType: string;
    ruleRefId?: string;
  };
};

export type BatchInsertContentsAction = {
  type: 'INSERT_CONTENTS';
  payload: {
    refIds: string[];
    contents: Content[];
    stepIdx: number;
    dependantPushRefId?: string;
  };
};

export type InsertStepAction = {
  type: 'INSERT_STEP';
};

// no defaults yet, to be added
export type InsertRuleAction = {
  type: RuleActionTypes.INSERT_RULE;
  payload: {
    refId: string;
    type: 'restricted' | 'preferred';
    templateType: string;
    rulePayload: JourneyRulePayload | null;
    stepIdx?: number;
  };
};

export type UpdateRuleAction = {
  type: RuleActionTypes.UPDATE_RULE_SIMPLE;
  payload: {
    refId: string;
    fields: Partial<RuleStateItem>;
  };
};

export type SaveRuleAction = {
  type: RuleActionTypes.UPDATE_RULE;
  payload: {
    templateId: string;
    refId: string;
    id: string;
    type: 'restricted' | 'preferred';
    templateType: string;
    rulePayload: JourneyRulePayload | null;
    stepIdx?: number;
    shouldSyncStartScheduleWithRuleBody: boolean;
  };
};

// the component figures out if we want to update before/after vs rule
// maybe we want to make if we more explict to allow saving different form vals
export type SaveContextualSchedulerAction = {
  type: RuleActionTypes.SAVE_CONTEXTUAL_SCHEDULER;
  payload: Partial<Scheduler['contextualRule']> & {
    stepIdx: number;
    type: 'start' | 'end' | 'both';
  };
};

// isEmptyRule is workaround for reducer structuring to notify push that it doesn't have to update
export type UpdateRuleTriggerAction = {
  type: RuleActionTypes.UPDATE_RULE_TRIGGER;
  payload: {
    stepIdx: number;
    type: RuleTriggerType;
    isEmptyRule?: string;
  };
};

export type InsertPushAction = {
  type: 'INSERT_PUSH';
  payload: {
    defaults: PushStateItem;
    stepIdx?: number;
    dependencies?: Dependency[];
  };
};

export type ContentFetchedAction = {
  type: 'CONTENT_FETCHED';
  payload: {
    content: Content;
    refId: string;
  };
};

export type RemoveStepAction = {
  type: 'REMOVE_STEP';
  payload: {
    stepIdx: number;
    push: string;
    rule: {
      restricted: string;
      preferred: string;
    };
    content: string[];
  };
};

export type RemoveContentAction = {
  type: 'REMOVE_CONTENT';
  payload: {
    refIds: string[];
    stepIdx?: number;
    required?: boolean[];
  };
};

export type RemoveRuleAction = {
  type: 'REMOVE_RULE';
  payload: {
    refId: string;
    type: 'preferred' | 'restricted';
    stepIdx?: number;
    wipeDependencies?: boolean;
  };
};

export type RemovePushAction = {
  type: 'REMOVE_PUSH';
  payload: {
    refId: string;
    stepIdx?: number;
  };
};

export type RemoveDependencyAction = {
  type: 'REMOVE_DEPENDENCY';
  payload: {
    entityRefId: string;
    dependencyRefId: string;
  };
};

export type UpdatePushDependencyAction = {
  type: 'UPDATE_PUSH_DEPENDENCY';
  payload: {
    refId: string;
    type: 'content' | 'rule';
    value: string | undefined;
  };
};

export type UpdateErrorAction = {
  type: 'UPDATE_ERRORS';
  payload: {
    refIds: string[];
    values: boolean[];
  };
};

export type UpdateContentAction = {
  type: 'UPDATE_CONTENT';
  payload: {
    [refId: string]: Content;
  };
};

export type SetContentAction = {
  type: ContentActionTypes.SET_CONTENT_INSTANCES | ContentActionTypes.SET_CREATED_CONTENT_INSTANCES;
  payload: {
    contentInstances: Content[];
  };
};
