import React, { useState, useEffect } from 'react';
import { FlightSelect } from '@flybits/design-system';
import { useCustomModuleAnalyticsContext } from '../../context/CustomModuleAnalyticsContext';
import { MetricFactorType } from '../../context/CustomModuleAnalyticsContext';
import { TEntityType, ENTITY_DD_ITEMS, entityDropDownOptions } from '../../constants';
import { metricsPush, metricsContent, metricsPushContent } from './metrics';

import './CustomModuleMetrics.scss';

const DEFAULT_CLASS = 'custom-module-analytics__main-content';
const METRICS_CONTAINER = `${DEFAULT_CLASS}__metric_container`;

export const CustomModuleMetrics = () => {
  const { customModuleState, setCustomModuleState } = useCustomModuleAnalyticsContext();
  const {
    measureItems: { entityType },
    metrics,
  } = customModuleState;
  const { metricOption } = metrics;
  const { measureItems } = customModuleState;

  // Only for Audience
  const isDisableMetrics = entityType.value === ENTITY_DD_ITEMS.AUDIENCES;

  const [metricDropdownValues, setMetricDropdownValues] = useState<TEntityType[]>(metricsPush);
  const [selectedMetricDropdown, setSelectedMetricDropdown] = useState<TEntityType | null>(
    metricOption || metricsPush[0],
  );
  const [selectedDropdown, setSelectedDropdown] = useState<TEntityType>(entityType || entityDropDownOptions[0]);

  const handleSelectedEntity = (option: TEntityType) => {
    const selectedEntity = {
      key: option.key,
      name: option.name,
      value: option.value,
    };

    setSelectedDropdown(selectedEntity);
    setSelectedMetricDropdown(null);
    switch (selectedEntity.key) {
      case ENTITY_DD_ITEMS.PUSH_NOTIFICATIONS:
        setMetricDropdownValues(metricsPush);
        break;
      case ENTITY_DD_ITEMS.AUDIENCES:
        setMetricDropdownValues(metricsPushContent);
        break;
      case ENTITY_DD_ITEMS.CONTENT:
        setMetricDropdownValues(metricsContent);
        break;
    }
  };

  // TODO: figure out why we have both option and factors.
  const handleMetricDropdownChange = (option: TEntityType) => {
    setSelectedMetricDropdown(option);
    const metricFactors: MetricFactorType[] = option?.value
      ?.split('|')
      .map((interaction) => ({ name: interaction, isActive: true, id: interaction }));
    setCustomModuleState({
      ...customModuleState,
      metrics: {
        ...metrics,
        metricFactors,
        metricOption: option,
      },
    });
  };

  useEffect(() => {
    setCustomModuleState({
      ...customModuleState,
      measureItems: {
        ...measureItems,
        entityType: selectedDropdown,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropdown]);

  return (
    <div className={METRICS_CONTAINER}>
      <div>
        <FlightSelect
          label="Select items to measure"
          className={`${DEFAULT_CLASS}__header__settings__dropdown`}
          options={entityDropDownOptions}
          selected={selectedDropdown}
          handleOptionClick={handleSelectedEntity}
          hasLabelAnimation
        />
      </div>
      <div>
        <FlightSelect
          label={`Select metric option`}
          className={`${DEFAULT_CLASS}__header__settings__dropdown`}
          options={metricDropdownValues}
          selected={selectedMetricDropdown}
          disabled={isDisableMetrics}
          handleOptionClick={handleMetricDropdownChange}
          hasLabelAnimation
        />
      </div>
    </div>
  );
};
