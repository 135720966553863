import { ZoneConfigPayload } from 'pages/Zones/types';

export const initialZonePayload: ZoneConfigPayload = {
  zones: [
    {
      name: 'Zone-1',
      modules: [],
      labels: [],
    },
  ],
  scriptURLs: [],
  version: {
    major: 1,
    minor: 0,
    patch: 0,
  },
};
