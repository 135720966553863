import BaseAPI from './base.api';
import { CtxDataFile } from 'interface/file/file.interface';
import { PaginationResponse } from 'interface/shared/api.interface';
import axios from 'axios';

export default class FileAPI extends BaseAPI {
  private ROUTES = {
    allCtxDataFiles: '/context/ctxdataimport/files',
    markAsUploaded: '/kernel/file-manager/files/:id/uploaded',
    preSigned: '/kernel/file-manager/presigned',
    processCtxFile: '/context/ctxdataimport/files/import',
    uploadCtxFile: '/kernel/file-manager/files/upload',
  };

  params = {
    limit: 0,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc',
  };

  // get all files
  getAllCtxDataFiles(opts = {}): Promise<PaginationResponse<CtxDataFile>> {
    this.params = Object.assign(this.params, opts);
    return this.GET(this.ROUTES.allCtxDataFiles, { ...this.params, fileType: 'context-data' });
  }

  uploadCtxFile(file: File, uuidIdentifier: string): Promise<CtxDataFile[]> {
    const fm = new FormData();
    fm.append('file', file);
    fm.append('isPrivate', 'true');
    fm.append('extension', '.csv');
    fm.append('tags', JSON.stringify(['context-data', uuidIdentifier]));

    return this.POST(this.ROUTES.uploadCtxFile, fm, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 30 * 1000,
    });
  }

  // process file by id
  processFileById(fileId: string): Promise<CtxDataFile> {
    return this.POST(this.ROUTES.processCtxFile, { fileId });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadViaPreSignedUrl(
    file: File,
    setFileUploadedPercentage: (percentCompleted: number) => void,
    journeyGUID: string,
  ): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      (async () => {
        const preSignedMetadataRequest = await this.POST(this.ROUTES.preSigned, {
          friendlyName: file.name,
          tags: ['context-data', `${journeyGUID}`],
          size: file.size,
        }).catch((axiosError) => axiosError);

        if (!preSignedMetadataRequest.url && !preSignedMetadataRequest.fileId) {
          let message = 'The configuration required to upload the file could not be retrieved.';

          if (preSignedMetadataRequest.response.data?.error?.exceptionMessage) {
            message = preSignedMetadataRequest.response.data.error.exceptionMessage;
          }

          reject({
            message,
            axiosError: preSignedMetadataRequest,
          });
          return;
        } else {
          const s3Request = await axios
            .put(preSignedMetadataRequest.url, file, {
              headers: {
                'Content-Type': 'text/csv',
                'If-None-Match': '*',
              },
              onUploadProgress: (progressEvent) => {
                if (progressEvent.total) {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  setFileUploadedPercentage(percentCompleted);
                }
              },
            })
            .catch((axiosError) => axiosError);

          if (!s3Request.status) {
            let message = 'The file could not be uploaded to the S3 bucket.';

            if (s3Request.response?.data?.error?.exceptionMessage) {
              message = s3Request.response.data.error.exceptionMessage;
            } else if (s3Request.message) {
              message = s3Request.message;
            }

            reject({
              message,
              axiosError: s3Request,
            });
            return;
          } else {
            const uploadedRequest = await this.PUT(
              this.buildURL(this.ROUTES.markAsUploaded, { id: preSignedMetadataRequest.fileId }),
            ).catch((axiosError) => axiosError);

            if (!uploadedRequest.id) {
              let message = 'The file could not be marked as uploaded.';

              if (uploadedRequest.response?.data?.error?.exceptionMessage) {
                message = uploadedRequest.response.data.error.exceptionMessage;
              } else if (uploadedRequest.message) {
                message = uploadedRequest.message;
              }

              reject({
                message,
                axiosError: uploadedRequest,
              });
              return;
            } else {
              resolve({
                fileId: preSignedMetadataRequest.fileId,
                response: uploadedRequest,
              });
              return;
            }
          }
        }
      })();
    });

    return promise;
  }
}
