import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FlightButton, FlightRadioButton } from '@flybits/design-system';
import LabelInput from 'components/LabelInput/LabelInput';
import { SpecificEntityList } from './SpecificEntityList';
import { useCustomModuleAnalyticsContext } from '../../context/CustomModuleAnalyticsContext';
import { TEntityType, ENTITY_DD_ITEMS, ENTITY_OPTIONS } from '../../constants';
import '../CustomModuleAnalyticsContent.scss';

const DEFAULT_CLASS = 'custom-module-analytics__main-content__measure_items';
const CONTAINER_CLASS = `${DEFAULT_CLASS}_container`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const entityDropDownOptions: TEntityType[] = [
  // { key: ENTITY_DD_ITEMS.EXPERIENCE, value: ENTITY_DD_ITEMS.EXPERIENCE, name: 'Experience (as a whole)' }, // TODO: In Future experience will be part of the dropdown
  { key: ENTITY_DD_ITEMS.CONTENT, value: ENTITY_DD_ITEMS.CONTENT, name: 'Content' },
  { key: ENTITY_DD_ITEMS.AUDIENCES, value: ENTITY_DD_ITEMS.AUDIENCES, name: 'Audiences' },
  { key: ENTITY_DD_ITEMS.PUSH_NOTIFICATIONS, value: ENTITY_DD_ITEMS.PUSH_NOTIFICATIONS, name: 'Push Notifications' },
];

export const CustomModuleMeasureItems = () => {
  // TODO: After demo, fix this TS issue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { te } = useSelector((state: any) => state);
  const journeyState = te.journey;
  const { customModuleState, setCustomModuleState } = useCustomModuleAnalyticsContext();
  const { measureItems } = customModuleState;
  const { filterOption } = measureItems;

  const [selectedFilterOption, setSelectedFilterOption] = useState<ENTITY_OPTIONS>(
    filterOption || ENTITY_OPTIONS.SPECIFIC,
  );

  useEffect(() => {
    setCustomModuleState({
      ...customModuleState,
      measureItems: {
        ...measureItems,
        filterOption: selectedFilterOption,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOption]);

  // TODO: Find out if we need this right now
  useEffect(() => {
    const { labels } = journeyState;
    if (labels) {
      setCustomModuleState({
        ...customModuleState,
        measureItems: {
          ...measureItems,
          selectedLabels: labels,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journeyState?.labels]);

  const handleEntityOption = (key: ENTITY_OPTIONS) => {
    if (selectedFilterOption === key) return;
    setSelectedFilterOption(key);
  };

  const MeasureBody = useCallback(() => {
    switch (selectedFilterOption) {
      case ENTITY_OPTIONS.LABEL:
        return <LabelInput experience={journeyState} />;

      case ENTITY_OPTIONS.SPECIFIC:
        // TODO:  In Future we will have a dropdown with Items like - Experience, Content, Push Notificatio  and Audience
        // At that time, pass the selected dropdown value. Right now a hard-coded Experience is passed
        return (
          <SpecificEntityList
            selectedDropdown={{
              key: ENTITY_DD_ITEMS.EXPERIENCE,
              value: ENTITY_DD_ITEMS.EXPERIENCE,
              name: 'Experience (as a whole)',
            }}
          />
        );

      case ENTITY_OPTIONS.ALL:
        return (
          <SpecificEntityList
            selectedDropdown={{
              key: ENTITY_DD_ITEMS.EXPERIENCE,
              value: ENTITY_DD_ITEMS.EXPERIENCE,
              name: 'Experience (all)',
            }}
            selectAll
          />
        );
      // return <span>{`All ${ENTITY_DD_ITEMS.EXPERIENCE}s are selected to create the custom module`}</span>;

      default:
        return <p>No option selected</p>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOption]);

  const handleClearSelection = useCallback(() => {
    setCustomModuleState({
      ...customModuleState,
      measureItems: {
        ...measureItems,
        selectedEntities: [],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={DEFAULT_CLASS}>
      {/* TO-DO: Future enhancement to show a dropdown. Supporting comments from MeasureBody component under SPECIFIC case */}
      {/* <div className={CONTAINER_CLASS}>
        <FlightSelect
          label="Select entity to measure"
          className={`${DEFAULT_CLASS}__header__settings__dropdown`}
          options={entityDropDownOptions}
          selected={selectedDropdown}
          handleOptionClick={(option: any) => handleSelectedEntity(option)}
        />
      </div> */}

      <div className={CONTAINER_CLASS}>
        {/* TO-DO: Future enhancement to support label selection */}
        {/* <div className={`${CONTAINER_CLASS}__radio__container__option-container`}>
          <FlightRadioButton
            className={`${CONTAINER_CLASS}__radio__container__radio`}
            label="Specific labels"
            onSelect={handleEntityOption}
            value={ENTITY_OPTIONS.LABEL}
            checked={selectedFilterOption === 'label'}
          />
        </div> */}

        <div className={`${CONTAINER_CLASS}__radio__container__option-container`}>
          <FlightRadioButton
            className={`${CONTAINER_CLASS}__radio__container__radio`}
            label={`All ${ENTITY_DD_ITEMS.EXPERIENCE}s`}
            onSelect={handleEntityOption}
            value={ENTITY_OPTIONS.ALL}
            checked={selectedFilterOption === 'all'}
          />
        </div>
        <div className={`${CONTAINER_CLASS}__radio__container__option-container`}>
          <FlightRadioButton
            className={`${CONTAINER_CLASS}__radio__container__radio`}
            label={`Specific ${ENTITY_DD_ITEMS.EXPERIENCE}s`}
            onSelect={handleEntityOption}
            value={ENTITY_OPTIONS.SPECIFIC}
            checked={selectedFilterOption === 'specific'}
          />
        </div>
        <div className={`${CONTAINER_CLASS}__radio__container__option-container clear`}>
          <FlightButton
            theme="secondary"
            label={'Clear'}
            size={'small'}
            onClick={handleClearSelection}
            ariaLabel="Clear selection"
            onKeyDown={handleClearSelection}
          />
        </div>
      </div>

      <div className={CONTAINER_CLASS}>{selectedFilterOption === 'specific' && <MeasureBody />}</div>
    </div>
  );
};
