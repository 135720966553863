import React from 'react';
import { TIconProps } from '../types';

function UploadContextDataIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5303 16.5303C19.6807 15.6844 22 12.8087 22 9.3913C22 5.3092 18.6908 2 14.6087 2C11.1913 2 8.31556 4.31926 7.46966 7.46966C4.31926 8.31556 2 11.1913 2 14.6087C2 18.6908 5.3092 22 9.3913 22C12.8087 22 15.6844 19.6807 16.5303 16.5303ZM14.6087 16.7826C15.2734 16.7826 15.9176 16.6949 16.5303 16.5303C16.6949 15.9176 16.7826 15.2734 16.7826 14.6087C16.7826 10.5266 13.4734 7.21739 9.3913 7.21739C8.72662 7.21739 8.08242 7.30513 7.46966 7.46966C7.30513 8.08241 7.21739 8.72661 7.21739 9.3913C7.21739 13.4734 10.5266 16.7826 14.6087 16.7826Z"
        fill={fill}
      />
    </svg>
  );
}

export default UploadContextDataIcon;
