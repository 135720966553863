import React from 'react';

import { EVENT_KEYS } from 'types/events';

import './ToggleOptions.scss';

const DEFAULT_CLASS = 'flight-toggle-options';

type TFlightToggleProps = {
  className: string;
  optionNames: {
    left: string;
    right: string;
  };
  isToggledRight: boolean;
  onToggle: (isToggledRight: boolean) => void;
};

export default function FlightToggleOptions(props: TFlightToggleProps) {
  const { className, optionNames, isToggledRight, onToggle } = props;

  const leftOptionClass = `${DEFAULT_CLASS}__option ${isToggledRight ? '' : 'selected'}`;
  const rightOptionClass = `${DEFAULT_CLASS}__option ${isToggledRight ? 'selected' : ''}`;

  const handleClick = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (onToggle) {
      onToggle(!isToggledRight);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === EVENT_KEYS.ENTER) {
      handleClick(e);
    }
  };

  return (
    <div
      className={`${DEFAULT_CLASS} ${className}`}
      role="button"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <button className={leftOptionClass} tabIndex={-1}>
        {optionNames.left}
      </button>
      <button className={rightOptionClass} tabIndex={-1}>
        {optionNames.right}
      </button>
    </div>
  );
}
