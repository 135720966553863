import { useDispatch } from 'react-redux';
import { useAppSelector as useSelector } from './reduxHooks';

import { FeatureFlagActionType } from 'store/feature-flag/feature-flag.type';
import bulletTrain from 'bullet-train-client';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

// Example Usage
// ===================================================
// import useFeatureFlag from 'hooks/useFeatureFlag';
// const { flags } = useFeatureFlag();
// flags['enabled_audience_optimization'] ? 1 : 0

// List of Feature Flags used for current application. (flags cannot be updated via API)
// The following array of value should be created on https://app.flagsmith.com/.
const flags = [
  'tx_ao_notifications',
  'tx_ca_custom_module',
  'tx_demo',
  'tx_design_and_code_content',
  'tx_enable_experience_settings_in_actions_header',
  'tx_enable_flow',
  'tx_filters',
  'tx_folders',
  'tx_groups',
  'tx_labels',
  'tx_maker_checker',
  'tx_opendome',
  'tx_sandbox',
  'tx_show_analytics_engagement',
  'tx_show_route_content_manager',
  'tx_show_route_zones',
  'tx_theme_settings',
  'tx_upload_ctxfile_s3',
  'tx_zone_module_label_to_content',
  'tx_zones_modules_v2',
  'tx_merchant_portal',
  'tx_es1_link_visible',
] as const;

// List of default values of traits for new users. (Traits can be updated via API - user specific)
// The values will be persisted on https://app.bullet-train.io/
const traits = [
  { key: 'tx_never_show_preferred_save_modal', value: false },
  { key: 'tx_never_show_restriction_snackbar', value: false },
  { key: 'tx_never_show_preferred_snackbar', value: false },
  { key: 'tx_never_show_delete_amplification_snackbar', value: false },
];

export default function useFeatureFlag() {
  const auth = useSelector((state) => state.auth);
  const featureFlag = useSelector((state) => state.featureFlag);
  const dispatch = useDispatch();
  let isInitialized = false;

  function initBulletTrain() {
    bulletTrain.identify(`${auth.user?.email} - ${auth.user?.id}`);
    bulletTrain.init({
      environmentID: process.env.REACT_APP_BULLET_TRAIN_ID,
      onChange: (res: any) => {
        if (isInitialized) return;
        const traitsObj: any = {};
        const flagsObj: { [key in (typeof flags)[number]]?: boolean } = {};
        flags.forEach((name) => {
          flagsObj[name] = res[name]?.enabled || false;
        });
        traits.forEach((trait: any) => {
          const value = bulletTrain.getTrait(trait.key);
          if (value !== undefined) {
            traitsObj[trait.key] = value;
          } else {
            traitsObj[trait.key] = trait.value;
          }
        });
        dispatch({ type: FeatureFlagActionType.INITIALIZE_FLAGS, payload: { flags: flagsObj, traits: traitsObj } });
        bulletTrain.setTraits(traitsObj);
        isInitialized = true;
      },
      onError: () => {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            content:
              'Some features are temporarily unavailable. You can still access the page, but certain functions may be disabled. Please try again later.',
            type: 'error',
          },
        });
        dispatch({ type: FeatureFlagActionType.INITIALIZE_FLAGS, payload: { flags: {}, traits: {} } });
        isInitialized = true;
      },
    });
  }

  function setTrait(key: string, value: any) {
    bulletTrain.setTrait(key, value);
    dispatch({
      type: FeatureFlagActionType.SET_TRAITS,
      payload: {
        traits: {
          ...featureFlag.traits,
          [key]: value,
        },
      },
    });
  }

  useEffect(() => {
    if (!auth.authenticated) return;
    if (featureFlag.isInitialized) return;
    if (isInitialized) return;
    initBulletTrain();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.authenticated, isInitialized, featureFlag.isInitialized]);

  return {
    flags: featureFlag.flags,
    traits: featureFlag.traits,
    isInitialized: featureFlag.isInitialized,
    setTrait,
  };
}
