import './WebhookNode.scss';

import BaseNode, { BaseNodeProps, FlowNodeMenuItem } from '../BaseNode/BaseNode';
import React, { useCallback, useMemo } from 'react';

import { FlightTooltip } from '@flybits/design-system';
import IconWebhook from 'pages/FlowBuilder/assets/icons/IconWebhook';
import { WebhookStateItem } from 'store/webhook/webhook.type';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import useJourneyStatus from 'hooks/useJourneyStatus';
import { JourneyComponentTypes } from 'pages/FlowBuilder/types/flow-builder.types';
import { StepMetadata } from 'interface/experience/experience.interface';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';

const CLASS_MAIN = 'webhook-node';
const CLASS_LABEL = `${CLASS_MAIN}__label`;
// const CLASS_DISABLED = `${CLASS_LABEL}--disabled`;
const CLASS_TEXT = `${CLASS_LABEL}__text`;
const CLASS_NAME = `${CLASS_LABEL}__name`;
const CLASS_NAME_TOOLTIP = `${CLASS_MAIN}__name-tooltip`;

type WebhookNodeProps = {
  data: {
    webhook: WebhookStateItem;
    stepIndex: number;
  };
};

function WebhookNode(props: WebhookNodeProps) {
  const {
    data: { webhook, stepIndex },
  } = props;

  const { isJourneyLocked } = useJourneyStatus();
  const flowBuilderContext = useFlowBuilderContext();
  const webhookNodeName = useSelector((state) => {
    const stepsMetadata = JSON.parse(state.te.journey.metadata?.stepMetadata ?? '[]') as StepMetadata[];

    return stepsMetadata[stepIndex]?.webhookNodes?.[webhook.refId]?.name || '';
  });

  const webhookDisplayName = webhookNodeName || webhook.name || 'Webhook';

  const renderWebhookNode = () => (
    <div className={CLASS_LABEL}>
      <FlightTooltip description={`Webhook Name: ${webhookDisplayName}`} className={CLASS_NAME_TOOLTIP}>
        <div className={CLASS_TEXT}>{webhookDisplayName}</div>
      </FlightTooltip>
      <div className={CLASS_NAME}>{`Type: Webhook`}</div>
    </div>
  );

  const handleEditWebhook = useCallback(() => {
    const flowWebhookContext = flowBuilderContext.sidePanels.webhook;
    flowWebhookContext.setStepIndex(stepIndex);
    flowWebhookContext.setWebhookContext({ isOpen: true, isViewOnly: false, webhook });
  }, [flowBuilderContext.sidePanels.webhook, stepIndex, webhook]);

  const handleViewWebhook = useCallback(() => {
    const flowWebhookContext = flowBuilderContext.sidePanels.webhook;
    flowWebhookContext.setStepIndex(stepIndex);
    flowWebhookContext.setWebhookContext({ isOpen: true, isViewOnly: true, webhook });
  }, [flowBuilderContext.sidePanels.webhook, stepIndex, webhook]);

  const handleRenameWebhook = useCallback(() => {
    const flowRenameModalContext = flowBuilderContext.modals.rename;
    flowRenameModalContext.setData({
      entity: webhook,
      entityType: JourneyComponentTypes.WEBHOOK,
      entityNodeName: webhookDisplayName,
      stepIndex,
    });
  }, [flowBuilderContext.modals.rename, webhook, stepIndex, webhookDisplayName]);

  const baseNodeProps = useMemo<Omit<BaseNodeProps, 'children'>>(() => {
    const menuItems: FlowNodeMenuItem[] = [];

    if (isJourneyLocked) {
      menuItems.push({
        icon: 'eyeOpenOutline',
        label: 'View Webhook',
        onClick: handleViewWebhook,
      });
    } else {
      menuItems.push(
        {
          icon: 'editOutline',
          label: 'Edit Webhook',
          onClick: handleEditWebhook,
        },
        {
          icon: 'textBoxWithCorners',
          label: 'Rename',
          onClick: handleRenameWebhook,
        },
      );
    }

    return {
      className: CLASS_MAIN,
      menuItems,
      Icon: IconWebhook,
    };
  }, [isJourneyLocked, handleEditWebhook, handleViewWebhook, handleRenameWebhook]);

  return <BaseNode {...baseNodeProps}>{renderWebhookNode()}</BaseNode>;
}

export default WebhookNode;
