import React, { useState } from 'react';
import { FlightTextInput } from '@flybits/design-system';
import { ReactComponent as IconListGray } from 'pages/Zones/assets/icons/icon-list-gray-fill.svg';
import { CLASSES } from 'pages/Zones/constants/classes';
import { ZoneConfig } from 'pages/Zones/types';
import ModuleLabelsList from 'components/Zones/ModuleLabelsList/ModuleLabelsList';

type TLabelsTabProps = {
  zoneId: string;
  moduleId: string;
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
};

const LabelsTab: React.FC<TLabelsTabProps> = ({ zoneId, moduleId, zoneConfig, setZoneConfig }) => {
  const [newLabel, setNewLabel] = useState('');
  const zoneIndex = zoneConfig.zones.findIndex((zone) => zone.id === zoneId);
  const moduleIndex = zoneConfig.zones[zoneIndex].modules.findIndex((module) => module.id === moduleId);
  const moduleLabels = zoneConfig?.zones?.[zoneIndex]?.modules?.[moduleIndex]?.labelsOrder ?? [];

  const handleNewLabelChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setNewLabel(value);
  };

  const handleNewLabelKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      const _zoneConfig = { ...zoneConfig };

      _zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsOrder = [
        ...(_zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsOrder || []),
        newLabel,
      ];
      _zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits = {
        ..._zoneConfig.zones[zoneIndex].modules[moduleIndex].labelsLimits,
        [newLabel]: 10,
      };
      setZoneConfig(_zoneConfig);
      setNewLabel('');
    }
  };

  return (
    <div className={CLASSES.LABELS_CONTENT}>
      <div className={`${CLASSES.LABELS_CONTENT}__header`}>
        <div className={`${CLASSES.LABELS_CONTENT}__header__text`}>
          Add labels and manage their positions based on where you want them to exist in the module.
        </div>
        <div className={`${CLASSES.LABELS_CONTENT}__header__search`}>
          <FlightTextInput
            iconInput="addTag"
            placeholderText="Add labels"
            value={newLabel}
            onChange={handleNewLabelChange}
            onKeyPress={handleNewLabelKeyPress}
          />
        </div>
      </div>
      <div className={`${CLASSES.LABELS_CONTENT}__body`}>
        {moduleLabels.length ? (
          <ModuleLabelsList
            labels={moduleLabels}
            zoneId={zoneId}
            moduleId={moduleId}
            zoneConfig={zoneConfig}
            setZoneConfig={setZoneConfig}
          />
        ) : (
          <div className={CLASSES.PLACEHOLDER}>
            <div className={`${CLASSES.PLACEHOLDER}__icon`}>
              <IconListGray />
            </div>
            <div className={`${CLASSES.PLACEHOLDER}__text`}>Content you select will appear here</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LabelsTab;
