import React from 'react';

// Custom imports
import { FlightDropdown, getIcon } from '@flybits/design-system';
import InfoIcon from 'pages/ExperienceCanvas/assets/icons/InfoIcon';

// Custom Hooks
import useCategoryLibrary from 'hooks/useCategoryLibrary';

// Stylings
import './InfoDropdown.scss';

type InfoDropdownDetailData = {
  templateName: string;
};

const InfoDropdown = ({ templateName = 'Template name' }: InfoDropdownDetailData): JSX.Element | null => {
  const headerIconFill = '#2371f1';
  const { isLoading, category, isError } = useCategoryLibrary();

  if (isLoading || isError) return null;

  const {
    categoryData: { selectedCategoryName },
    subCategoryData: { selectedSubCategoryName },
  } = category;

  const notApplicableText = 'N/A';

  return (
    <FlightDropdown
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      className="experience-overview-view-details"
      direction="bottom"
      maxWidth="288px"
      ariaControlsId="viewTemplateDetails"
      trigger={
        <div className={`overview-header-item-container`} aria-label="View details">
          <InfoIcon fill={headerIconFill} ariaHidden={true} />
        </div>
      }
    >
      <div className="experience-overview-view-details__container">
        <div className="experience-overview-view-details__container__header">
          <h2>Experience Details</h2>
        </div>

        {/* Template Section */}
        <div className="experience-overview-view-details__container__body">
          <div className="experience-overview-view-details__container__body--section">
            <div aria-hidden="true">{getIcon('template', {})}</div>
            <div>
              <h2>Parent Template</h2>
              <span>{templateName}</span>
            </div>
          </div>

          {/* Category Section */}
          <div className="experience-overview-view-details__container__body--section">
            <div aria-hidden="true">{getIcon('categories', {})}</div>
            <div>
              <h2>Category</h2>
              <span>{selectedCategoryName || notApplicableText}</span>
            </div>
          </div>

          {/* Sub Category Section */}
          <div className="experience-overview-view-details__container__body--section">
            <div aria-hidden="true">{getIcon('subcategoryTree', {})}</div>
            <div>
              <h2>Sub-Category</h2>
              <span>{selectedSubCategoryName || notApplicableText}</span>
            </div>
          </div>
        </div>
      </div>
    </FlightDropdown>
  );
};

export default InfoDropdown;
