import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

// Custom components
import { Crumb, HeaderBreadcrumb } from '../HeaderBreadcrumb/HeaderBreadcrumb';

// Redux/ Hook
import useCategoryLibrary from 'hooks/useCategoryLibrary';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';

// Types
import { ActionTypes } from 'store/actionTypes';
import { TExperienceCanvasRouteParams } from 'pages/ExperienceCanvas/types';

const BreadcrumbContainer = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { pid: projectId } = useRouteMatch<TExperienceCanvasRouteParams>().params;
  const { isLoading, category, isError } = useCategoryLibrary();
  const {
    categoryData: { selectedCategoryName, catId },
    subCategoryData: { subCatId },
  } = category;
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const isTemplate = urlParams.get('fromTemplateLibrary')?.toLowerCase() === 'true';

  const clearJourneyInstance = () => {
    dispatch({
      type: ActionTypes.CLEAR_JOURNEY,
    });
  };

  // Create crumb list
  const crumbsList = useMemo(() => {
    // Build URL for crumb
    const generateTemplateLibCrumbURL = (isCategory = false): string => {
      const projectBaseURL = `/project/${projectId}`;
      let templateLibURL = `${projectBaseURL}/${window.location.search}`;

      // Editing existing experience
      if (isTemplate) {
        templateLibURL = `${projectBaseURL}/experiences/templates/library`;

        if (isCategory) {
          templateLibURL = `${templateLibURL}${catId ? `/${catId}${subCatId ? `/${subCatId}` : ''}` : '/unassigned'}`;
        }
      }

      return templateLibURL;
    };

    const crumbs: Crumb[] = [
      {
        name: isTemplate ? 'Template Library' : 'List of experiences',
        path: generateTemplateLibCrumbURL(),
        isClickable: true,
        cbFunc: clearJourneyInstance,
      },
      {
        name: `${isTemplate ? 'Create' : 'Edit'} an experience`,
        path: '',
        isClickable: false,
      },
    ];

    // Load category crumb if user is creating a new experience
    if (isTemplate) {
      const categoryCrumb: Crumb = {
        name: `"${selectedCategoryName || 'Templates'}"`,
        path: generateTemplateLibCrumbURL(true),
        isClickable: true,
        cbFunc: clearJourneyInstance,
      };

      crumbs.splice(1, 0, categoryCrumb);
    }

    return crumbs;
  }, [selectedCategoryName, isTemplate, projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading || isError) return null;

  return <HeaderBreadcrumb crumbs={crumbsList} />;
};

export default BreadcrumbContainer;
