import React from 'react';
import { XYPlot, VerticalBarSeries, AreaSeries, LabelSeries, Hint, makeWidthFlexible } from 'react-vis';

interface FunnelGraphData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  areaSeriesData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  barGraphData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelTitleData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelPercentData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelUserData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  differencePercentData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyFunnelLabelData: any[];
}

export default function STExperienceFunnelGraph(props: FunnelGraphData) {
  function hintData() {
    if (props.barGraphData[0].y && props.differencePercentData.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const hintDataArray: any[] = [];
      for (let i = 0; i < props.differencePercentData.length; i++) {
        //Value of x is position where difference of perecent needs to display
        const myValue = {
          x: props.differencePercentData.length === 1 ? i + 0.4 : i + 0.3,
          y: props.differencePercentData[i].y,
        };
        hintDataArray.push(
          <Hint key={i} value={myValue} align={{ horizontal: 'right', vertical: 'auto' }}>
            <div className="hint-label">
              <p className="hint-label__text">{props.differencePercentData[i].label}</p>
              <div className="hint-label__arrow"></div>
            </div>
          </Hint>,
        );
      }
      return hintDataArray;
    } else {
      return <></>;
    }
  }

  const FlexibleXYPlot = makeWidthFlexible(XYPlot);
  return (
    <FlexibleXYPlot height={400} margin={{ top: 50 }}>
      <AreaSeries
        data={props.areaSeriesData}
        style={{
          fill: '#EEF5FF',
          stroke: '#EEF5FF',
        }}
      />
      <VerticalBarSeries data={props.barGraphData} barWidth={0.25} colorType="literal" />
      <LabelSeries data={props.labelTitleData} labelAnchorX="middle" style={{ fontWeight: 'bold' }} />
      <LabelSeries data={props.labelUserData} labelAnchorX="middle" />
      <LabelSeries data={props.labelPercentData} labelAnchorX="middle" labelAnchorY="text-top" />
      <LabelSeries data={props.emptyFunnelLabelData} labelAnchorX="middle" labelAnchorY="text-top" />
      {hintData()}
    </FlexibleXYPlot>
  );
}
