import React from 'react';
import { TIconProps } from '../types';

function TemplateIcon({ ariaHidden = false }: TIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="template" aria-hidden={ariaHidden}>
      <g data-name="layout 50">
        <path fill="#dadde2" d="M3,15V5A2,2,0,0,1,5,3H19a2,2,0,0,1,2,2V15Z"></path>
        <path fill="#55abff" d="M3,19V15H21v4a2,2,0,0,1-2,2H5A2,2,0,0,1,3,19Z"></path>
        <path
          fill="#0078ee"
          d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM4,5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1v9H4ZM5,20a1,1,0,0,1-1-1V16H8v4Zm5,0V16h4v4Zm10-1a1,1,0,0,1-1,1H16V16h4Z"
        ></path>
      </g>
    </svg>
  );
}

export default TemplateIcon;
