import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useClickOutsideDetect(ref: any, action: any) {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleClickOutside(event: any) {
      if (ref?.current && !ref?.current?.contains(event?.target)) {
        action();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, action]);
}
