import { LabelFilter } from 'pages/Zones/types';
import { TLabelFilterContext } from './LabelFilterContext';
import { useEffect, useState } from 'react';

const useLabelFilterContext = (
  moduleName: string | null,
  initLabelFilter: LabelFilter[] | null,
): TLabelFilterContext => {
  const [selectedLabelFilter, setSelectedLabelFilter] = useState<LabelFilter | null>(null);
  const [labelFilters, setLabelFilters] = useState<LabelFilter[]>(initLabelFilter ?? []);

  const addLabelFilter = async (newLabels: LabelFilter[]) => {
    await setLabelFilters((prev) => {
      return [...prev, ...newLabels];
    });
  };

  const reOrder = (startIndex: number, endIndex: number) => {
    setLabelFilters((prev) => {
      const result = Array.from(prev ?? []);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    });
  };

  const remove = (label: string) => {
    setLabelFilters((prev) => {
      return prev ? prev.filter((lf) => lf.label !== label) : [];
    });
  };

  useEffect(() => {
    setLabelFilters((prev) => {
      const index = prev.findIndex((filter) => filter.label === selectedLabelFilter?.label);
      if (prev && index >= 0 && selectedLabelFilter) {
        prev[index] = selectedLabelFilter;
        return prev;
      } else {
        return prev;
      }
    });
  }, [selectedLabelFilter]);

  useEffect(() => {
    if (selectedLabelFilter && labelFilters.length == 0) {
      setSelectedLabelFilter(null);
    } else if (!selectedLabelFilter && labelFilters.length > 0) {
      setSelectedLabelFilter(labelFilters[0]);
    } else if (selectedLabelFilter) {
      const index = labelFilters.findIndex((filter) => filter.label === selectedLabelFilter?.label);
      if (index === -1) {
        setSelectedLabelFilter(labelFilters[0]);
      }
    }
  }, [labelFilters, setSelectedLabelFilter, selectedLabelFilter]);

  return {
    moduleName,
    labelFilters,
    selectedLabelFilter,
    setSelectedLabelFilter,
    addLabelFilter,
    reOrder,
    remove,
  };
};

export default useLabelFilterContext;
