/* eslint-disable @typescript-eslint/no-explicit-any */
export function calculateCircleData(lat = 0, lng = 0, radius = 0) {
  const r = 6371;
  const pi = Math.PI;
  const _lat = (lat * pi) / 180;
  const _lng = (lng * pi) / 180;
  const d = radius / 1000 / r;
  const detail = 8;
  let data = '';

  for (let i = 0; i <= 360; i += detail) {
    const brng = (i * pi) / 180;
    let pLat = Math.asin(Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng));
    const pLng =
      ((_lng +
        Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(_lat), Math.cos(d) - Math.sin(_lat) * Math.sin(pLat))) *
        180) /
      pi;
    pLat = (pLat * 180) / pi;
    data += `|${pLat},${pLng}`;
  }

  return data;
}

export function calculateCenter(points: any) {
  const longitudes = points.map((i: any) => i.lng).sort();
  const latitudes = points.map((i: any) => i.lat).sort();

  const lowX = latitudes[0];
  const highX = latitudes[latitudes.length - 1];
  const lowY = longitudes[0];
  const highY = longitudes[longitudes.length - 1];

  const longestDistance = getDistance({ lat: lowX, lng: lowY }, { lat: highX, lng: highY });
  const radius = Math.ceil(longestDistance / 2);

  const centerX = lowX + (highX - lowX) / 2;
  const centerY = lowY + (highY - lowY) / 2;

  return { lat: centerX, lng: centerY, radius };
}

export function rad(x: number) {
  return (x * Math.PI) / 180;
}

export function getDistance(p1: any, p2: any) {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
}
