export const MAIN_CLASS = 'prioritization-manager';
export const CONTAINER = `${MAIN_CLASS}__container`;

export const CLASSES = {
  CATEGORIES: `${CONTAINER}__categories`,
  CATEGORY_CONTENT: `${CONTAINER}__category-content`,
  LABELS_CONTENT: `${MAIN_CLASS}__labels-content`,
  PINNED_CONTENT: `${MAIN_CLASS}__pinned-content`,
  PLACEHOLDER: `${MAIN_CLASS}__placeholder`,
};
