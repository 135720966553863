import './PinContentLimitBanner.scss';

import React from 'react';

const DEFAULT_CLASS = 'pin-content-limit-banner';

const CLASSES = {
  NONE: `${DEFAULT_CLASS}--none`,
  ONE: `${DEFAULT_CLASS}--one`,
  TWO: `${DEFAULT_CLASS}--two`,
  THREE: `${DEFAULT_CLASS}--three`,
  TEXT: `${DEFAULT_CLASS}__text`,
};

type PinContentLimitBannerProps = {
  numPinnedContent: number;
  limit?: number;
};

function getLimitClass(numPinnedContent: number) {
  switch (numPinnedContent) {
    case 0:
      return CLASSES.NONE;
    case 1:
      return CLASSES.ONE;
    case 2:
      return CLASSES.TWO;
    case 3:
      return CLASSES.THREE;
  }
}

function PinContentLimitBanner(props: PinContentLimitBannerProps) {
  const numPinnedContent = props.numPinnedContent;
  const limit = props.limit || 3;

  const getSelectedText = () => {
    if (numPinnedContent === 0) {
      return 'None selected';
    } else {
      return `${numPinnedContent} of ${limit} selected`;
    }
  };

  return (
    <div className={`${DEFAULT_CLASS} ${getLimitClass(props.numPinnedContent)}`}>
      <div className={CLASSES.TEXT}>You can only select three contents to pin</div>
      <div className={CLASSES.TEXT}>{getSelectedText()}</div>
    </div>
  );
}

export default PinContentLimitBanner;
