import { useQuery } from '@tanstack/react-query';
import { Merchant } from 'pages/MerchantPortal/merchant-portal.types';
import merchantService from 'services/api/merchants.api';

const DEFAULT_QUERY_KEY = 'merchant';

export default function useMerchantMock(id: string) {
  const {
    data: merchantData,
    isLoading: isMerchantLoading,
    isError: isMerchantError,
  } = useQuery<Merchant>({
    queryKey: [DEFAULT_QUERY_KEY, id],
    queryFn: () => merchantService.getMerchantMock(id),
    refetchOnWindowFocus: false,
  });

  return {
    merchantData,
    isMerchantLoading,
    isMerchantError,
    queryKey: [DEFAULT_QUERY_KEY, id],
  };
}
