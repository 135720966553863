import React, { useRef, useEffect } from 'react';
import { update } from 'jdenticon';

interface Props {
  size: string;
  value: string;
  className?: string;
}

function Jdenticon(props: Props) {
  const { size, value, className } = props;
  const icon = useRef<any>(null);

  useEffect(() => {
    if (icon.current) {
      update(icon.current, value);
    }
  }, [value]);

  return (
    <div className={className}>
      <svg data-jdenticon-value={value} height={size} width={size} ref={icon} />
    </div>
  );
}

export default Jdenticon;
