import React from 'react';
import { TIconProps } from '../types';

function CalendarWithClockIcon({ fill = '#000000', className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 19.25H5C3.423 19.25 2.75 18.577 2.75 17V8.75H19.25V11C19.25 11.414 19.586 11.75 20 11.75C20.414 11.75 20.75 11.414 20.75 11V6.5C20.75 4.082 19.418 2.75 17 2.75H15.75V2C15.75 1.586 15.414 1.25 15 1.25C14.586 1.25 14.25 1.586 14.25 2V2.75H7.75V2C7.75 1.586 7.414 1.25 7 1.25C6.586 1.25 6.25 1.586 6.25 2V2.75H5C2.582 2.75 1.25 4.082 1.25 6.5V17C1.25 19.418 2.582 20.75 5 20.75H11C11.414 20.75 11.75 20.414 11.75 20C11.75 19.586 11.414 19.25 11 19.25ZM5 4.25H6.25V5C6.25 5.414 6.586 5.75 7 5.75C7.414 5.75 7.75 5.414 7.75 5V4.25H14.25V5C14.25 5.414 14.586 5.75 15 5.75C15.414 5.75 15.75 5.414 15.75 5V4.25H17C18.577 4.25 19.25 4.923 19.25 6.5V7.25H2.75V6.5C2.75 4.923 3.423 4.25 5 4.25ZM8.02002 12C8.02002 12.552 7.57302 13 7.02002 13C6.46802 13 6.01489 12.552 6.01489 12C6.01489 11.448 6.45801 11 7.01001 11H7.02002C7.57202 11 8.02002 11.448 8.02002 12ZM12.02 12C12.02 12.552 11.573 13 11.02 13C10.468 13 10.0149 12.552 10.0149 12C10.0149 11.448 10.458 11 11.01 11H11.02C11.572 11 12.02 11.448 12.02 12ZM18 13.25C15.381 13.25 13.25 15.381 13.25 18C13.25 20.619 15.381 22.75 18 22.75C20.619 22.75 22.75 20.619 22.75 18C22.75 15.381 20.619 13.25 18 13.25ZM18 21.25C16.208 21.25 14.75 19.792 14.75 18C14.75 16.208 16.208 14.75 18 14.75C19.792 14.75 21.25 16.208 21.25 18C21.25 19.792 19.792 21.25 18 21.25ZM8.02002 16C8.02002 16.552 7.57302 17 7.02002 17C6.46802 17 6.01489 16.552 6.01489 16C6.01489 15.448 6.45801 15 7.01001 15H7.02002C7.57202 15 8.02002 15.448 8.02002 16ZM19.6021 18.541C19.8951 18.833 19.8951 19.3091 19.6021 19.6021C19.4561 19.7481 19.264 19.822 19.072 19.822C18.88 19.822 18.688 19.7491 18.542 19.6021L17.4709 18.531C17.3299 18.39 17.251 18.2 17.251 18.001V16.501C17.251 16.087 17.587 15.751 18.001 15.751C18.415 15.751 18.751 16.087 18.751 16.501V17.6899L19.6021 18.541Z"
        fill={fill}
      />
    </svg>
  );
}

export default CalendarWithClockIcon;
