import React, { useState } from 'react';
import { ReactComponent as DragIcon } from 'pages/Zones/assets/icons/icon-drag.svg';
import './PinnedContentListItem.scss';
import DynamicContentPreviewIframe from 'components/ExperienceCanvas/DynamicContentIframe/DynamicContentPreviewIframe';
import { getIcon } from '@flybits/design-system';

type TPinnedContentListItemProps = (
  | {
      contentType: 'image';
      contentURI: string;
      contentInstanceId?: never;
    }
  | {
      contentType: 'content-instance';
      contentInstanceId: string;
      contentURI?: never;
    }
) & {
  id: string;
  index: number;
  listLength: number;
  title: string;
  onChangePosition: (oldIndex: number, newIndex: number) => void;
  onDragStart: (index: number) => void;
  onDragEnd: () => void;
  onUnpinClick: (id: string) => void;
  labels?: string[];
  infoTooltip?: string;
  isDisabled?: boolean;
  isPinned?: boolean;
  isSelected?: boolean;
  showDropzone?: boolean;
};

const MAIN_CLASS = 'pinned-content-list-item';
const CLASSES = {
  LIGHT_BACKGROUND: `${MAIN_CLASS} ${MAIN_CLASS}--is-light`,
  DARK_BACKGROUND: `${MAIN_CLASS} ${MAIN_CLASS}--is-dark`,
  DRAGGING: `${MAIN_CLASS}--is-dragging`,
  DRAG_ICON: `${MAIN_CLASS}__drag-icon`,
  CONTENT: `${MAIN_CLASS}__content`,
  IMAGE_PREVIEW_WRAPPER: `${MAIN_CLASS}__image-preview-wrapper`,
  IMAGE_PREVIEW: `${MAIN_CLASS}__image-preview`,
  TITLE: `${MAIN_CLASS}__title`,
  FLEX: `${MAIN_CLASS}__flex`,
  UNPIN_ICON: `${MAIN_CLASS}__unpin-icon`,
  LABEL_LIST: `${MAIN_CLASS}__label-list`,
  LABEL: `${MAIN_CLASS}__label`,
  MOVE_ACTIONS: `${MAIN_CLASS}__move-actions`,
  DRAG_DESCRIPTION: `${MAIN_CLASS}__drag-description`,
  DROPZONE: `${MAIN_CLASS}__dropzone`,
  DROPZONE_TOP: `${MAIN_CLASS}__dropzone ${MAIN_CLASS}__dropzone--is-top`,
};

const PinnedContentListItem: React.FC<TPinnedContentListItemProps> = ({
  contentType,
  id,
  index,
  listLength,
  title,
  onChangePosition,
  onDragStart,
  onDragEnd,
  onUnpinClick,
  contentInstanceId,
  contentURI,
  labels = [],
  showDropzone = false,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.dataTransfer.setData('text/plain', index.toString());
    setIsDragging(true);
    onDragStart(index);
  };

  const handleDragEnd = () => {
    onDragEnd();
    setIsDragging(false);
  };

  const handleDrop = (evt: React.DragEvent<HTMLDivElement>, position: 'top' | 'bottom') => {
    evt.preventDefault();
    const oldIndex = +evt.dataTransfer.getData('text/plain');
    let newIndex;

    if (position === 'bottom') newIndex = index;
    else newIndex = index - 1;
    if (newIndex < oldIndex) newIndex = newIndex + 1;

    onChangePosition(oldIndex, newIndex);
  };

  const handleDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
  };

  const handleUnpinClick = () => {
    onUnpinClick(id);
  };

  const handleMoveUpClick = () => {
    onChangePosition(index, index - 1);
  };

  const handleMoveDownClick = () => {
    onChangePosition(index, index + 1);
  };

  return (
    <div
      className={`${index % 2 !== 0 ? `${CLASSES.LIGHT_BACKGROUND}` : `${CLASSES.DARK_BACKGROUND}`}${
        isDragging ? ` ${CLASSES.DRAGGING}` : ''
      }`}
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {showDropzone && index === 1 && (
        <div className={CLASSES.DROPZONE_TOP} onDrop={(evt) => handleDrop(evt, 'top')} onDragOver={handleDragOver} />
      )}
      <div className={CLASSES.DRAG_ICON}>
        <DragIcon />
      </div>
      <div className={CLASSES.CONTENT}>
        {contentType === 'content-instance' ? (
          <DynamicContentPreviewIframe id={id} contentInstanceIds={[contentInstanceId]} />
        ) : (
          <div className={CLASSES.IMAGE_PREVIEW_WRAPPER}>
            <div
              className={CLASSES.IMAGE_PREVIEW}
              aria-label="content preview image"
              style={{ backgroundImage: `url(${contentURI})` }}
            />
          </div>
        )}
      </div>
      <div className={CLASSES.TITLE}>
        <div className={CLASSES.FLEX}>
          <p>{title}</p>
          <div className={CLASSES.UNPIN_ICON}>
            <button type="button" onClick={handleUnpinClick}>
              {getIcon('mathMultiply', {})}
            </button>
          </div>
        </div>
        {labels.length > 0 && (
          <div className={CLASSES.LABEL_LIST}>
            {labels.map((label) => (
              <div key={label} className={CLASSES.LABEL}>
                {label}
              </div>
            ))}
          </div>
        )}
        <div className={CLASSES.MOVE_ACTIONS}>
          <button type="button" onClick={handleMoveUpClick} disabled={index === 1}>
            {getIcon('baselineKeyboardArrowUp', {})} Move Up
          </button>
          <button type="button" onClick={handleMoveDownClick} disabled={index === listLength}>
            {getIcon('baselineKeyboardArrowDown', {})} Move Down
          </button>
        </div>
        <div className={CLASSES.DRAG_DESCRIPTION}>Drag to desired position</div>
      </div>
      {showDropzone && (
        <div className={CLASSES.DROPZONE} onDrop={(evt) => handleDrop(evt, 'bottom')} onDragOver={handleDragOver} />
      )}
    </div>
  );
};

export default PinnedContentListItem;
