/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any

// Advanced
import React, { useCallback } from 'react';
import { getDeliveryFormOptions } from 'store/push/push.selector';
import useForm from 'hooks/useSimpleForm';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { FlightNumberInput, FlightSelect } from '@flybits/design-system';
import { PERIODS, FORM_WIDTHS as WIDTH } from 'components/ExperienceCanvas/constants';

type TReminderFormProps = {
  classPrefix: string;
  stepIdx: number;
};

enum FieldKeys {
  FREQ = 'frequency',
  FREQ_P = 'frequencyPeriod',
  LIMIT = 'limit',
}

export default function ReminderForm({ classPrefix = '', stepIdx }: TReminderFormProps) {
  const dispatch = useDispatch();
  const deliveryValues = useSelector((state) => {
    const pushRefId = state.te.journey.steps[stepIdx]?.push ?? '';

    return getDeliveryFormOptions(state.te, pushRefId, stepIdx);
  });

  const { formData, updateField } = useForm({
    data: {
      [FieldKeys.FREQ]: { value: deliveryValues.frequency.value },
      [FieldKeys.FREQ_P]: { value: deliveryValues.frequency.period },
      [FieldKeys.LIMIT]: { value: parseInt(deliveryValues.limit.key) },
    },
  });

  /*
    Explanation: as per backend:
    - if the limit is set to 0 (display value 1), that means we send once.
      Frequency is irrelevant and should techincally be 0, with period once.
    - if the limit is set to -1 or > 0, (display value 0 or > 1), that means we
      REQUIRE a freqency, so it can't be 0 and period shouldn't be once.
  */
  const disableFrequency = deliveryValues.limit.key === '1';
  const periodOptions = disableFrequency ? PERIODS.slice(0, 1) : PERIODS.slice(2);

  const handleSelect = (option: any) => {
    updateField(FieldKeys.FREQ_P, option);
    dispatch({
      type: 'UPDATE_SCHEDULE',
      payload: {
        stepIdx,
        type: 'start',
        frequencyType: option.key,
      },
    });
  };

  const handleInput = (key: FieldKeys.LIMIT | FieldKeys.FREQ, value: any) => {
    // maybe we can put this guard directly into the form :/
    const val = parseInt(value);
    const isBlank = value === '';
    if (!isBlank && isNaN(val)) return;
    updateField(key, isBlank ? '' : val);
  };

  const handleBlur = useCallback(
    (key: FieldKeys.LIMIT | FieldKeys.FREQ) => {
      if (formData[key].value === '') return;

      let limit: number | undefined;
      let freq: number | undefined;
      let period: string | undefined;
      switch (key) {
        case FieldKeys.LIMIT:
          limit = formData[key].value - 1;
          if (formData[key].value === 1) {
            freq = 0;
            updateField(FieldKeys.FREQ, 0);
            period = PERIODS[0].key;
            updateField(FieldKeys.FREQ_P, PERIODS[0]);
          } else {
            if (formData[FieldKeys.FREQ_P].value.key !== 'once') break;
            freq = 1;
            updateField(FieldKeys.FREQ, 1);
            period = PERIODS[2].key;
            updateField(FieldKeys.FREQ_P, PERIODS[2]);
          }
          break;
        case FieldKeys.FREQ:
          freq = formData[key].value;
          break;
      }

      dispatch({
        type: 'UPDATE_SCHEDULE',
        payload: {
          stepIdx,
          type: 'start',
          [FieldKeys.FREQ]: freq ?? deliveryValues.frequency.value,
          [FieldKeys.FREQ_P]: period ?? deliveryValues.frequency.period.key,
          [FieldKeys.LIMIT]: limit ?? parseInt(deliveryValues.limit.key) - 1,
        },
      });
    },
    [
      deliveryValues.frequency.period.key,
      deliveryValues.frequency.value,
      deliveryValues.limit.key,
      dispatch,
      stepIdx,
      formData,
      updateField,
    ],
  );

  return (
    <>
      <div className={`${classPrefix}__advanced-desc`}>
        Decide how often each user receives a reminder/follow-up notification
      </div>
      <div className={`${classPrefix}__advanced-row`}>
        <span className={`${classPrefix}__advanced-label`}> Maximum notifications per user </span>
        <div className={`${classPrefix}__advanced-fields`}>
          <FlightNumberInput
            className={`${classPrefix}__advanced-input`}
            width={WIDTH.SMALL}
            placeholderText={'1'}
            maxValue={999}
            minValue={0}
            onChange={(e: any) => {
              handleInput(FieldKeys.LIMIT, e.target.value);
            }}
            onBlur={() => handleBlur(FieldKeys.LIMIT)}
            value={formData.limit.value.toString()}
          />
        </div>
      </div>
      <div className={`${classPrefix}__advanced-row`}>
        <span className={`${classPrefix}__advanced-label`}> Delay between each notification </span>
        <div className={`${classPrefix}__advanced-fields`}>
          <FlightNumberInput
            className={`${classPrefix}__advanced-input`}
            width={WIDTH.SMALL}
            placeholderText={'1'}
            maxValue={999}
            minValue={1}
            onChange={(e: any) => {
              handleInput(FieldKeys.FREQ, e.target.value);
            }}
            onBlur={() => handleBlur(FieldKeys.FREQ)}
            value={formData.frequency.value.toString()}
            disabled={disableFrequency}
          />
          <FlightSelect
            label=""
            options={periodOptions}
            hasLabelAnimation={true}
            handleOptionClick={(option: any) => {
              handleSelect(option);
            }}
            dropdownMaxHeight={'200px'}
            className={`${classPrefix}__advanced-dropdown`}
            width={WIDTH.MEDIUM}
            dropdownDirection={'bottom'}
            selected={formData.frequencyPeriod.value}
            disabled={disableFrequency}
          />
        </div>
      </div>
    </>
  );
}
