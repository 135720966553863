import { ApprovalSettings } from 'interface/approval/approval.interface';
import { Experience, ExperienceInstance } from 'interface/experience/experience.interface';
import { PaginationResponse } from 'interface/shared/api.interface';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import BaseAPI from 'services/api/base.api';

export default class TemplatedExperienceAPI extends BaseAPI {
  private ROUTES = {
    allInstances: '/kernel/journey/instances',
    getInstance: '/kernel/journey/instances/:id',
    createInstance: '/kernel/journey/instances',
    updateInstance: '/kernel/journey/instances/:id',
    updateInstanceSchedule: '/kernel/journey/instances/:id/schedules',
    activateInstance: '/kernel/journey/instances/:id/activate',
    deactivateInstance: '/kernel/journey/instances/:id/deactivate',
    deleteInstance: '/kernel/journey/instances/:id',
    allTemplates: '/kernel/journey/templates',
    getTemplate: '/kernel/journey/templates/:id',
    approvalSettings: '/kernel/journey/settings/approval',
    getPushTypes: '/push/push/payloadtypes',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTemplatedInstances(opts: any): Promise<PaginationResponse<Experience>> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let params: any = {
      page: 0,
      limit: 10,
      offset: 0,
      search: '',
      searchExact: false,
      sortby: '',
      sortorder: 'desc',
      startdate: 0,
      enddate: 0,
    };
    params = Object.assign(params, opts);
    params.offset = params.page ? params.limit * (params.page - 1) : params.offset ? params.offset : 0;
    params.search = params.search?.trim();
    for (const key in params) {
      if (!params[key] || (Array.isArray(params[key]) && isEmpty(params[key]))) {
        delete params[key];
      }
    }

    return this.GET(`${this.ROUTES.allInstances}/?${queryString.stringify({ ...params }, { arrayFormat: 'none' })}`);
  }

  getTemplatedExperiences(opts = {}): Promise<PaginationResponse<Experience>> {
    let params = { limit: 1000, offset: 0 };
    params = Object.assign(params, opts);
    return this.GET(this.ROUTES.allTemplates, params);
  }

  getTemplatedExperience(id: string, payload = true): Promise<Experience> {
    return this.GET(this.buildURL(this.ROUTES.getTemplate, { id: id }), { payload });
  }

  getTemplatedExperienceInstance(id: string): Promise<Experience> {
    return this.GET(this.buildURL(this.ROUTES.getInstance, { id: id }));
  }

  createTemplatedInstance(tExperience: ExperienceInstance): Promise<Experience> {
    return this.POST(this.ROUTES.createInstance, tExperience);
  }

  updateTemplatedExperienceInstance(
    tExperience: ExperienceInstance,
    queryParams?: object | undefined,
  ): Promise<Experience> {
    if (!tExperience.id) throw new Error('Cannot update without an experience ID!');
    return this.PUT(this.buildURL(this.ROUTES.updateInstance, { id: tExperience.id }, queryParams), tExperience);
  }

  updateTemplatedExperienceInstanceSchedule(id: string, schedule: Experience['schedule']): Promise<Experience> {
    return this.PUT(this.buildURL(this.ROUTES.updateInstanceSchedule, { id }), schedule);
  }

  activateTemplatedExperienceInstance(id: string): Promise<Experience> {
    return this.PUT(this.buildURL(this.ROUTES.activateInstance, { id }));
  }

  deactivateTemplatedExperienceInstance(id: string): Promise<Experience> {
    return this.PUT(this.buildURL(this.ROUTES.deactivateInstance, { id }));
  }

  deleteTemplatedExperienceInstance(id: string): Promise<string> {
    return this.DELETE(this.buildURL(this.ROUTES.deleteInstance, { id }));
  }

  getApprovalSettings(): Promise<ApprovalSettings> {
    return this.GET(this.ROUTES.approvalSettings);
  }

  getPushTypes() {
    return this.GET(this.ROUTES.getPushTypes);
  }
}
