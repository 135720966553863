/* eslint-disable @typescript-eslint/no-explicit-any */
import { getStepRuleRefIds } from 'store/rule/rule.selector';
import { RemoveRuleAction, InsertRuleAction, SaveRuleAction } from 'store/actionTypes';
import { insertRuleAction, updateRuleAction, removeRuleAction } from './rule.action';
import { RootState } from 'store/store';
import { BROADCASTABLE_TEMPLATES } from 'components/ExperienceCanvas/types';

export function insertRule(payload: InsertRuleAction['payload']) {
  return (dispatch: any, getState: any) => {
    const teState = getState().te;
    const stepRuleRefIds = getStepRuleRefIds(teState, payload.stepIdx ?? 0);
    const stepPushRefId = teState.journey.steps[payload.stepIdx ?? 0]?.push[0];
    const mainStepRuleRefId =
      payload.type === 'restricted' ? payload.refId : stepRuleRefIds.restricted ?? payload.refId;

    dispatch({ type: 'INSERT_RULE', payload });
    if (stepPushRefId) {
      dispatch({
        type: 'UPDATE_PUSH_DEPENDENCY',
        payload: {
          refId: stepPushRefId,
          type: 'rule',
          value: mainStepRuleRefId,
        },
      });
    }
  };
}

export function removeRulesThunk(payload: RemoveRuleAction['payload']) {
  return (dispatch: any, getState: any) => {
    const teState = getState().te;
    const stepRuleRefIds = getStepRuleRefIds(teState, payload.stepIdx ?? 0);
    const stepPushRefId = teState.journey.steps[payload.stepIdx ?? 0]?.push;

    if (payload.refId === stepRuleRefIds.restricted) {
      stepRuleRefIds.restricted = '';
    }

    if (payload.refId === stepRuleRefIds.preferred) {
      stepRuleRefIds.preferred = '';
    }

    const mainStepRuleRefId = stepRuleRefIds.restricted || stepRuleRefIds.preferred;

    dispatch({ type: 'REMOVE_RULE', payload });
    if (stepPushRefId) {
      dispatch({
        type: 'UPDATE_PUSH_DEPENDENCY',
        payload: {
          refId: stepPushRefId,
          type: 'rule',
          value: mainStepRuleRefId || undefined,
        },
      });
    }
  };
}

export function saveRulesThunk(payload: SaveRuleAction['payload']) {
  return (dispatch: any, getState: any) => {
    const teState: RootState['te'] = getState().te;
    const templateType = teState.journey.templateType ?? '';
    const stepRuleRefIds = getStepRuleRefIds(teState, payload.stepIdx ?? 0);
    const rule = teState.rule.byRefId[stepRuleRefIds[payload.type]];

    //  no rule present in journey && empty/no rule returned from rb => no change
    if (!rule && !payload.refId) {
      return;
    }

    // no rule present && rule returned
    if (!rule && payload.refId) {
      dispatch(
        insertRuleAction({
          refId: payload.refId,
          type: payload.type,
          templateType: payload.templateType,
          rulePayload: payload.rulePayload,
          stepIdx: payload.stepIdx,
        }),
      );
    }

    // rule present && was wiped
    if (rule && !payload.refId) {
      // wipe if rule is NOT deletable ...
      if (!rule.isOptional) {
        dispatch(
          updateRuleAction({
            ...payload,
            refId: rule.refId,
          }),
        );
        // ... remove if it is
      } else {
        let wipeDependencies = false;
        for (const [key, value] of Object.entries(stepRuleRefIds)) {
          if (key !== payload.type && !value) wipeDependencies = true;
        }
        dispatch(
          removeRuleAction({
            refId: payload.refId,
            type: payload.type,
            stepIdx: payload.stepIdx,
            wipeDependencies,
          }),
        );
        // TODO: investigate necessity of this dispatch
        // for now, set type to "omit" only if template is broadcastable
        if (BROADCASTABLE_TEMPLATES.includes(templateType)) {
          dispatch({ type: 'UPDATE_RULE_TRIGGER', payload: { stepIdx: payload.stepIdx ?? 0, type: 'omit' } });
        }
      }
    }

    // rule present && updated
    if (rule && payload.refId) {
      dispatch(updateRuleAction(payload));
    }
  };
}
