import { MERCHANT_FORM_INIT_VALS } from './merchant-portal.constants';
import { Merchant, MerchantCategory, MerchantFormValues } from './merchant-portal.types';

// Currency formatter
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
export function formatCurrency(
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: number | string) => void,
  field: string,
) {
  const inputField = e.target;
  const number = parseInt(inputField.value.replace(/[^0-9]/g, ''), 10);
  setFieldValue(field, !isNaN(number) ? currencyFormatter.format(number / 100) + '' : '');
}

export const dateToEpoch = (date: Date) => {
  if (date == null) date = new Date(0);
  return (date.getTime() - date.getMilliseconds()) / 1000;
};

export const epochToDate = (ms: EpochTimeStamp) => {
  if (!ms) {
    return new Date();
  } else {
    return new Date(ms * 1000);
  }
};

export const getFullDate = (date: Date) =>
  new Intl.DateTimeFormat(undefined, {
    dateStyle: 'full',
  }).format(date);

export function validateInput(e: React.KeyboardEvent<HTMLInputElement>) {
  // Allow only number keys, control keys, and decimal points
  if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|Tab|Enter/.test(e.key)) {
    e.preventDefault(); // Prevent non-numeric and non-control keys
  }
}

export const mapMerchantDataToFormValues = (merchantData?: Merchant): MerchantFormValues => {
  if (!merchantData) return MERCHANT_FORM_INIT_VALS;
  const {
    externalId,
    name,
    categoryId: category,
    logoURL: logo,
    phoneNumber: phone,
    addressLine: address,
    addressCity: city,
    addressState: state,
    addressZipCode: zip,
    verificationStatus,
    notes,
  } = merchantData;
  return {
    name,
    externalId,
    category,
    logo,
    phone,
    address,
    city,
    state,
    zip,
    contact: {
      name: merchantData.contactName,
      phone: merchantData.contactPhoneNumber,
      email: merchantData.contactEmail,
    },
    verification: {
      verificationStatus,
      notes,
    },
  };
};

export const mapMerchantCategoriesToOptions = (merchantCategories: MerchantCategory[]) => {
  return merchantCategories.map((category) => ({
    key: category.id,
    name: category.name,
  }));
};
