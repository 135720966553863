import { RootState } from 'store/store';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { JourneyError } from 'validators/ExperienceCanvas/types';

const refIdToStep = (refId: string, state: RootState) => {
  const steps = state.te.journey.steps;
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];

    if (
      step.rule.restricted === refId ||
      step.rule.preferred === refId ||
      step.push === refId ||
      step.content.includes(refId)
    )
      return i;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getBackendJourneyErrors(beErrors: any[], state: RootState, filter?: (err: any) => boolean) {
  const topLevelErrors: JourneyError[] = [];
  const stepErrors: { [stepIdx: string]: JourneyError[] } = {};
  const filteredErrors: JourneyError[] = [];

  beErrors.forEach((err, idx) => {
    const journeyError = {
      stateIdx: idx,
      err: err.exceptionMessage ?? err.fberror?.exceptionMessage ?? 'unknown error',
      type: ERROR_TYPES.ERROR,
    };
    // check for filters conditions component and path.
    if (filter && filter(err)) filteredErrors.push(journeyError);

    if (err.refId) {
      const stepId = refIdToStep(err.refId, state)?.toString();
      if (stepId) {
        stepErrors[stepId] ? stepErrors[stepId].push(journeyError) : (stepErrors[stepId] = [journeyError]);
        return;
      }
      // fall through to check path
    }
    if (!err.path || typeof err.path !== 'string') {
      topLevelErrors.push(journeyError);
      return;
    }
    // matches the index of the step (and only if it starts with it)
    const regEx = /(?<=^step\[)\d+?(?=\])/g;
    const index = [...err.path.matchAll(regEx)][0]?.[0];
    if (!index) {
      topLevelErrors.push(journeyError);
      return;
    }
    stepErrors[index] ? stepErrors[index].push(journeyError) : (stepErrors[index] = [journeyError]);
  });

  return {
    topLevelErrors,
    stepErrors,
    filteredErrors,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateErrorToast(errors: any[], state: RootState) {
  let toast = 'Something went wrong with experience creation!\nThere ';

  const { topLevelErrors, stepErrors } = getBackendJourneyErrors(errors, state);
  const journeyLevelErrors = topLevelErrors.length;

  let hasWasOrWere = false;

  if (journeyLevelErrors > 1) {
    toast += `were ${journeyLevelErrors} general configuration errors`;
    hasWasOrWere = true;
  } else if (journeyLevelErrors > 0) {
    toast += `was a general configuration error`;
    hasWasOrWere = true;
  }

  const stepStrings: string[] = [];

  for (const [key, value] of Object.entries(stepErrors)) {
    if (!hasWasOrWere && value.length > 1) {
      toast += `were `;
      hasWasOrWere = true;
    } else if (!hasWasOrWere) {
      toast += `was `;
      hasWasOrWere = true;
    }

    stepStrings.push(`${value.length} error(s) on touchpoint ${parseInt(key) + 1}`);
  }

  if (stepStrings.length && journeyLevelErrors > 0) toast += ', ';
  if (stepStrings.length === 1 && journeyLevelErrors > 0) toast += 'and ';

  stepStrings.forEach((str, idx) => {
    if (idx !== 0) toast += ', ';
    if (idx === stepStrings.length - 1 && idx !== 0) toast += 'and ';
    toast += str;
  });

  return (toast += '.');
}
