// common validators
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { FieldValidationType } from 'components/ExperienceCanvas/types';
import { ValidatorData } from 'validators/ExperienceCanvas/types';

// https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
// email validation is super complex (apparently) - this is a rough pass
export function isEmailValid(value: string) {
  const regExp = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
  return regExp.test(value);
}

// same as above issue with emails
export function isPhoneValid(value: string) {
  const regExp = /^(\+\d{0,3}\s?)?\(?\d{0,5}\)?[-\s.]?\d{1,4}[-\s.]?\d{1,6}[-\s.]?\d{0,2}[-\s.]?\d{0,2}$/;
  return regExp.test(value);
}

export function isUrlValid(value: string, http = true) {
  if (http) {
    const regExp =
      /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
    return regExp.test(value);
  } else {
    try {
      new URL(value);
      return true;
    } catch (e) {
      return false;
    }
  }
}

// oddly specific validators
export function validateEmail(value: string) {
  return !isEmailValid(value) ? { type: ERROR_TYPES.ERROR, err: 'A valid email is required.' } : null;
}

export function validatePhone(value: string) {
  return !isPhoneValid(value) ? { type: ERROR_TYPES.ERROR, err: 'A valid phone number is required.' } : null;
}

export function validateURL(value: string, http = true) {
  return !isUrlValid(value, http)
    ? { type: ERROR_TYPES.ERROR, err: 'A valid URL beginning with "https://" is required.' }
    : null;
}

// top level validators
export function validateRequired(value: unknown, required: boolean, isDraft: boolean) {
  if (required && !isDraft && (value === '' || value === undefined || value === null))
    return { type: ERROR_TYPES.INCOMPLETE, err: 'Required Field' };
  return null;
}
export function validateString(value: unknown) {
  if (value === undefined || value === null) return null;
  if (typeof value !== 'string') return { type: ERROR_TYPES.ERROR, err: 'Not a valid string!' };
  if (value.length > 170) return { type: ERROR_TYPES.ERROR, err: 'Must be under 170 characters' };
  return null;
}

// maybe we need the option for min max configurations.
export function validateNumber(value: unknown) {
  if (value === undefined || value === null) return null;
  if (typeof value !== 'number' && Number.isNaN(Number(value)))
    return { type: ERROR_TYPES.ERROR, err: 'Not a valid number!' };
  return null;
}

export function generateBasicValidator(type: FieldValidationType, required = true) {
  switch (type) {
    case 'number':
      return (data: ValidatorData, key: string, isDraft = false) => {
        const value = data[key]?.value;
        return validateRequired(value, required, isDraft) || validateNumber(value);
      };
    case 'string':
    case 'text':
      return (data: ValidatorData, key: string, isDraft = false) => {
        const value = data[key]?.value;
        return validateRequired(value, required, isDraft) || validateString(value);
      };
    case 'url':
      return (data: ValidatorData, key: string, isDraft = false) => {
        const value = data[key]?.value;
        return validateRequired(value, required, isDraft) || validateString(value) || validateURL(value);
      };
    case 'email':
      return (data: ValidatorData, key: string, isDraft = false) => {
        const value = data[key]?.value;
        return validateRequired(value, required, isDraft) || validateString(value) || validateEmail(value);
      };
    case 'phone':
      return (data: ValidatorData, key: string, isDraft = false) => {
        const value = data[key]?.value;
        return validateRequired(value, required, isDraft) || validateString(value) || validatePhone(value);
      };
    default:
      return () => null;
  }
}
