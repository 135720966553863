import './RenameNodeModal.scss';

import { FlightButton, FlightModal, FlightTextInput } from '@flybits/design-system';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { JourneyComponentTypes } from 'pages/FlowBuilder/types/flow-builder.types';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import React, { useEffect, useState } from 'react';
import { SaveRuleAction, UpdatePushSimpleAction } from 'store/actionTypes';
import { RuleStateItem } from 'store/rule/rule.type';
import { saveRulesThunk } from 'store/rule/rule.thunk';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { PushStateItem } from 'store/push/push.type';
import { updatePushSimple } from 'store/push/push.thunk';
import { StepMetadata } from 'interface/experience/experience.interface';
import { ContentStateItem } from 'store/content/content.type';
import { WebhookStateItem } from 'store/webhook/webhook.type';

const MAIN_CLASS = 'rename-node-flow-modal';
const CLASSES = {
  CONTENT: `${MAIN_CLASS}__content`,
  FOOTER: `${MAIN_CLASS}__footer`,
  HEADER: `${MAIN_CLASS}__header`,
};

function RenameNodeModal() {
  const [entityName, setEntityName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [initialEntityName, setInitialEntityName] = useState('');
  const flowBuilderContext = useFlowBuilderContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const languages = useSelector((state) => state.settings?.languages);
  const defaultLanguage = getDefaultLanguage(languages);
  const templateType = useSelector((state) => state.te.journey.templateType);
  const shouldSyncStartScheduleWithRuleBody = useSelector((state) => {
    const stepIdx = flowBuilderContext.modals.rename.data?.stepIndex ?? 0;
    const step = state.te.journey.steps[stepIdx];

    return step?.constraints?.audienceConstraints?.syncStartScheduleWithRuleBody ?? false;
  });
  const contentNodes = useSelector((state) => {
    const { stepIndex } = flowBuilderContext.modals.rename.data ?? {};
    const stepsMetadata = JSON.parse(state.te.journey.metadata?.stepMetadata ?? '[]') as StepMetadata[];

    return stepsMetadata[stepIndex ?? -1]?.contentNodes ?? {};
  });
  const webhookNodes = useSelector((state) => {
    const { stepIndex } = flowBuilderContext.modals.rename.data ?? {};
    const stepsMetadata = JSON.parse(state.te.journey.metadata?.stepMetadata ?? '[]') as StepMetadata[];

    return stepsMetadata[stepIndex ?? -1]?.webhookNodes ?? {};
  });

  const dispatch = useDispatch();

  const onClose = () => {
    setInitialEntityName('');
    setEntityName('');
    setErrorMessage('');
    flowBuilderContext.modals.rename.setData(null);
  };

  const updateContentNodeName = (name: string) => {
    const { stepIndex, entity } = flowBuilderContext.modals.rename.data ?? {};
    const content = entity as ContentStateItem;

    dispatch({
      type: 'UPDATE_STEP_CONTENT_NAME',
      payload: {
        stepIndex,
        contentNodes: {
          ...contentNodes,
          [content.refId]: { name },
        },
      },
    });
  };

  const updateWebhookNodeName = (name: string) => {
    const { stepIndex, entity } = flowBuilderContext.modals.rename.data ?? {};
    const webhook = entity as WebhookStateItem;

    dispatch({
      type: 'UPDATE_STEP_WEBHOOK_NAME',
      payload: {
        stepIndex,
        webhookNodes: {
          ...webhookNodes,
          [webhook.refId]: { name },
        },
      },
    });
  };

  const onSave = () => {
    if (validate()) {
      const { entity, entityType, stepIndex, pushType } = flowBuilderContext.modals.rename.data ?? {};

      if (entity) {
        switch (entityType) {
          case JourneyComponentTypes.CONTENT:
            updateContentNodeName(entityName);

            onClose();
            break;
          case JourneyComponentTypes.PUSH:
            const push = entity as PushStateItem;
            const savePushPayload: UpdatePushSimpleAction['payload'] = {
              refId: push.refId,
              fields: { ...push, name: entityName },
            };
            dispatch(updatePushSimple(savePushPayload, stepIndex ?? -1, pushType));

            onClose();
            break;
          case JourneyComponentTypes.RULE:
            const rule = entity as RuleStateItem;
            const saveRulePayload: SaveRuleAction['payload'] = {
              // ToDo: Support preferred rule
              type: 'restricted',
              templateId: rule.templateId ?? '',
              id: rule.id ?? '',
              templateType: templateType ?? '',
              refId: rule.refId,
              stepIdx: stepIndex,
              rulePayload: { ...rule, name: entityName },
              shouldSyncStartScheduleWithRuleBody,
            };
            dispatch(saveRulesThunk(saveRulePayload));

            onClose();
            break;
          case JourneyComponentTypes.WEBHOOK:
            updateWebhookNodeName(entityName);

            onClose();
            break;

          default:
            throw new Error('onSave: Invalid entity type');
        }
      }
    }
  };

  const validate = () => {
    if (!entityName) {
      setErrorMessage('You must enter a name.');
      return false;
    }

    setErrorMessage('');
    return true;
  };

  useEffect(() => {
    const { entity, entityType, entityNodeName } = flowBuilderContext.modals.rename.data || {};
    let name: string | undefined;

    if (entity) {
      switch (entityType) {
        case JourneyComponentTypes.CONTENT:
        case JourneyComponentTypes.WEBHOOK:
          name = entityNodeName;
          break;
        case JourneyComponentTypes.PUSH:
        case JourneyComponentTypes.RULE:
          name = entity.name;
          break;
        default:
          setEntityName('');
          break;
      }

      if (name) {
        setInitialEntityName(name);
        setEntityName(name);
      }
    }
  }, [defaultLanguage, flowBuilderContext.modals.rename]);

  return (
    <FlightModal
      className={MAIN_CLASS}
      isVisible={!!flowBuilderContext.modals.rename.data}
      toggleModalShown={onClose}
      header={<div className={CLASSES.HEADER}>Rename this component</div>}
      content={
        <div className={CLASSES.CONTENT}>
          <p>
            <strong>Current name:</strong> {initialEntityName}
          </p>
          <FlightTextInput
            errorMessage={errorMessage}
            hasError={!!errorMessage}
            isAriaRequired
            onBlur={validate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEntityName(e.target.value)}
            placeholderText="Rename to"
            value={entityName}
            width="100%"
          />
        </div>
      }
      footer={
        <div className={CLASSES.FOOTER}>
          <FlightButton label="Cancel" theme="secondary" onClick={onClose} />
          <FlightButton label="Save" onClick={onSave} disabled={!!errorMessage} />
        </div>
      }
    />
  );
}

export default RenameNodeModal;
