export const metricsPush = [
  { key: 'bounced', value: 'bounced', name: 'Bounced' },
  { key: 'bounced|sent', value: 'bounced|sent', name: 'Bounced x Sent' },
  { key: 'engaged', value: 'engaged', name: 'Engaged' },
  { key: 'engaged|sent', value: 'engaged|sent', name: 'Engaged x Sent' },
  { key: 'reach', value: 'reach', name: 'Reach' },
  { key: 'sent', value: 'sent', name: 'Sent' },
];
export const metricsContent = [
  { key: 'engaged', value: 'engaged', name: 'Engaged' },
  { key: 'engaged|viewed', value: 'engaged|viewed', name: 'Engaged x Viewed' },
  { key: 'fulfilled', value: 'fulfilled', name: 'Fulfilled' },
  { key: 'fulfilled|engaged', value: 'fulfilled|engaged', name: 'Fulfilled x Engaged' },
  { key: 'fulfilled|viewed', value: 'fulfilled|viewed', name: 'Fulfilled x Viewed' },
  { key: 'reach', value: 'reach', name: 'Reach' },
  { key: 'viewed', value: 'viewed', name: 'Viewed' },
];
export const metricsPushContent = [
  { key: 'bounced', value: 'bounced', name: 'Bounced' },
  { key: 'bounced|sent', value: 'bounced|sent', name: 'Bounced x Sent' },
  { key: 'engaged', value: 'engaged', name: 'Engaged' },
  { key: 'engaged|sent', value: 'engaged|sent', name: 'Engaged x Sent' },
  { key: 'engaged|viewed', value: 'engaged|viewed', name: 'Engaged x Viewed' },
  { key: 'fulfilled', value: 'fulfilled', name: 'Fulfilled' },
  { key: 'fulfilled|engaged', value: 'fulfilled|engaged', name: 'Fulfilled x Engaged' },
  { key: 'fulfilled|sent', value: 'fulfilled|sent', name: 'Fulfilled x Sent' },
  { key: 'fulfilled|viewed', value: 'fulfilled|viewed', name: 'Fulfilled x Viewed' },
  { key: 'reach', value: 'reach', name: 'Reach' },
  { key: 'viewed', value: 'viewed', name: 'Viewed' },
  { key: 'viewed|sent', value: 'viewed|sent', name: 'Viewed x Sent' },
  { key: 'sent', value: 'sent', name: 'Sent' },
];
