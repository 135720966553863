import './PinnedSection.scss';
import React, { useContext, useMemo } from 'react';
import { Module, PinnedContent } from 'pages/Zones/types';
import {
  ModuleSectionRoot,
  ModuleSectionHeader,
  ModuleSectionFooter,
  ModuleSectionItem,
  ModuleSectionItems,
  ModuleSectionLoader,
  ModuleSectionEmptyItem,
} from './ModuleSectionComponents';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';

import { ReactComponent as ThumbTack } from 'assets/icons/thumbtack-vertical.svg';

import { FlightButton } from '@flybits/design-system';
import { UseQueryResult } from '@tanstack/react-query';
import { Content } from 'interface/content/content.interface';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';

type PinnedSectionProps = {
  sectionNumber: number;
  module: Module;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  languages: any[];
  contentQueriesResults: UseQueryResult<Content, unknown>[];
};

type PinnedActionInfo = {
  pinnedContent: PinnedContent;
};

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  hour: 'numeric',
  hourCycle: 'h12',
  dayPeriod: 'narrow',
};

const MAIN_CLASS = 'pinned-section';

function PinnedActionInfo({ pinnedContent }: PinnedActionInfo) {
  const { startAt, endAt } = pinnedContent;
  const [isActive, isScheduled] = useMemo(() => {
    const now = Math.round(Date.now() / 1000);
    return [now > startAt && (endAt === -1 || now < endAt), now < startAt && (endAt === -1 || now < endAt)];
  }, [startAt, endAt]);
  return (
    <div className={`${MAIN_CLASS}`}>
      {isActive ? (
        <div className={`${MAIN_CLASS}__pinned-active`}>
          <div className={`${MAIN_CLASS}__pinned-active__pinned-indicator`} />
          Active until{' '}
          {pinnedContent.endAt > -1
            ? `${new Date(pinnedContent.endAt * 1000).toLocaleString(undefined, DateFormatOptions)}`
            : 'unpinned'}
        </div>
      ) : isScheduled ? (
        <div className={`${MAIN_CLASS}__pinned-inactive`}>
          <div className={`${MAIN_CLASS}__pinned-inactive__pinned-inactive-indicator`} />
          {'Will Activate on ' +
            `${new Date(pinnedContent.startAt * 1000).toLocaleString(undefined, DateFormatOptions)}`}
        </div>
      ) : (
        <div className={`${MAIN_CLASS}__pinned-expried`}>
          <div className={`${MAIN_CLASS}__pinned-expried__pinned-expried-indicator`} />
          <div>
            {'Deactivated on ' +
              `${new Date(pinnedContent.endAt * 1000).toLocaleDateString(undefined, DateFormatOptions)}`}
          </div>
        </div>
      )}
    </div>
  );
}

function PinnedSection({
  sectionNumber,
  module,
  isLoading: isZoneConfigLoading,
  languages,
  contentQueriesResults,
}: PinnedSectionProps) {
  const { setShowContentManagerSlideout, setPinnedContentScheduleOpen, setSelectedModule } =
    useContext(PrioritizationContext);

  const defaultLang = getDefaultLanguage(languages);
  const isEmpty = !module.pinnedContentIDsByTimeRange?.length;

  const fetchedPinnedContents = useMemo(() => {
    if (isZoneConfigLoading) return [];
    // sort pinned content
    module.pinnedContentIDsByTimeRange?.sort((a, b) => {
      return a.endAt - b.endAt;
    });

    const shouldPinnedContentBeShown = (startAt: number, endAt: number) => {
      const nowInSeconds = Math.floor(Date.now() / 1000);

      // Currently it's an engineering decision only to show the active pinned content in the module box section
      return /* active */ startAt <= nowInSeconds && (endAt === -1 || nowInSeconds < endAt);
    };

    return (
      module.pinnedContentIDsByTimeRange
        ?.map((pc) => {
          const contentId = pc.contentID;
          const content = contentQueriesResults.find((c) => c.data?.id === contentId);

          return {
            ...pc,
            iconUrl: content?.data?.iconUrl,
            name: content?.data?.localizations?.[defaultLang].name,
          };
        })
        .filter((pc) => shouldPinnedContentBeShown(pc.startAt, pc.endAt)) ?? []
    );
  }, [isZoneConfigLoading, module.pinnedContentIDsByTimeRange, contentQueriesResults, defaultLang]);

  return (
    <ModuleSectionRoot sectionNumber={sectionNumber}>
      <ModuleSectionHeader
        icon={<ThumbTack />}
        title={'Pin content'}
        subtext={
          <div>
            Pinned content will always appear at the top of the module. There is a limit of 1 active pin at a time.
            Pinned content can be changed in the
            <FlightButton
              iconRight="baselineArrowRight"
              label="content manager"
              size="small"
              theme="link"
              onClick={() => {
                setShowContentManagerSlideout(true);
              }}
            />
            .
          </div>
        }
      />
      <ModuleSectionItems>
        {isZoneConfigLoading ? (
          <ModuleSectionLoader />
        ) : isEmpty ? (
          <ModuleSectionEmptyItem>No content has been pinned to this module.</ModuleSectionEmptyItem>
        ) : (
          fetchedPinnedContents.map((pinInfo, index) => (
            <ModuleSectionItem
              key={index}
              index={index + 1}
              title={pinInfo.name ?? pinInfo.contentID}
              iconUrl={pinInfo.iconUrl}
            >
              <PinnedActionInfo pinnedContent={{ ...pinInfo }} />
            </ModuleSectionItem>
          ))
        )}
      </ModuleSectionItems>
      {!isEmpty && (
        <ModuleSectionFooter>
          <FlightButton
            iconLeft="add"
            iconRight="baselineArrowRight"
            label="View pin schedule"
            size="small"
            theme="link"
            onClick={() => {
              setSelectedModule(module);
              setPinnedContentScheduleOpen(true);
            }}
          />
        </ModuleSectionFooter>
      )}
    </ModuleSectionRoot>
  );
}

export default PinnedSection;
