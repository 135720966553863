import BaseAPI from './base.api';

import { User } from 'interface/auth/auth.interface';

export default class AuthAPI extends BaseAPI {
  private ROUTES = {
    me: '/sso/auth/me',
    users: '/sso/auth/users',
    serviceAccounts: '/sso/auth/sa',
  };

  validateToken(): Promise<User> {
    return this.GET(this.ROUTES.me);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUsers(params: URLSearchParams): Promise<any> {
    return this.GET(this.ROUTES.users, params);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getServiceAccounts(params: URLSearchParams): Promise<any> {
    return this.GET(this.ROUTES.serviceAccounts, params);
  }
}
