import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { startCase, merge, orderBy } from 'lodash';

import AOApi from 'services/api/ao.api';
import { updateJourneyAO } from 'store/journey/journey.thunk';

import { FlightButton, FlightSnackbar, FlightTable, getIcon } from '@flybits/design-system';
import { Attribute } from 'interface/attribute.interface';
import { AudienceOptimization } from 'interface/experience/experience.interface';
import { SmartTargetingMetrics } from 'interface/smart-targeting/smart-targeting.interface';
import Skeleton from 'react-loading-skeleton';
import { isEmpty } from 'lodash';
import './ContextAttributeChart.scss';

type TContextAttributeChartProps = {
  metrics: SmartTargetingMetrics;
  isTrainingNewModel: boolean;
  setIsTrainingNewModel: (val: boolean) => void;
};

const MAIN_CLASS = 'context-attributes';

export default function ContextAttributeChart({
  metrics,
  isTrainingNewModel,
  setIsTrainingNewModel,
}: TContextAttributeChartProps) {
  const AoAPI = useMemo(() => new AOApi(), []);

  const dispatch = useDispatch();
  const instance = useSelector((state) => state.te.journey);
  const disableRetrain = !!instance?.audienceOptimization?.skipEngagementCalculation;
  const [isLoading, setIsLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [attributes, setAttributes] = useState<Array<Attribute>>([]);
  const [attributesCandidate, setAttributesCandidate] = useState<Array<Attribute>>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<Set<string>>();
  const [sortTable, setSortTable] = useState<{ header: string; sortBy: 'asc' | 'desc' | boolean }>({
    header: '',
    sortBy: false,
  });

  // initial load
  useEffect(() => {
    setAttributes(
      metrics.activeModelMetrics.featureMetrics ? updateAttributes(metrics.activeModelMetrics.featureMetrics) : [],
    );
    setAttributesCandidate(
      metrics.candidateModelMetrics.featureMetrics
        ? updateAttributes(metrics.candidateModelMetrics.featureMetrics)
        : [],
    );
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics, isTrainingNewModel]);

  useEffect(() => {
    if (attributesCandidate) {
      setSelectedAttributes(
        new Set(attributesCandidate.filter((attr) => attr.isRegressor).map((attr) => attr.feature)),
      );
    } else if (attributes) {
      setSelectedAttributes(new Set(attributes.filter((attr) => attr.isRegressor).map((attr) => attr.feature)));
    }
  }, [attributes, attributesCandidate, isEditing]);

  function updateAttributes(attrs: Array<Attribute>) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return attrs?.sort((a: Attribute, b: Attribute) => b.importance! - a.importance!);
  }

  const renderBar = (val = 0, type = 'current') => {
    let status = `${MAIN_CLASS}__current-bar`;
    if (type === 'updated') {
      status = `${MAIN_CLASS}__updated-bar`;
    }
    return !!val && <div style={{ width: `${val}%` }} className={status}></div>;
  };

  const mergedAttributes = useMemo(() => {
    let candidateArr: Array<Attribute> =
      isCollapsed && attributesCandidate.length > 3 ? attributesCandidate.slice(0, 3) : attributesCandidate || [];
    const arr: Array<Attribute> = isCollapsed && attributes.length > 3 ? attributes.slice(0, 3) : attributes || [];
    if (!isEmpty(candidateArr)) {
      candidateArr =
        candidateArr?.map((attribute: Attribute) => ({
          feature: attribute?.feature,
          candidateIs_regressor: attribute?.isRegressor,
          candidateImportance: attribute?.importance,
        })) || [];
    }
    if (!sortTable.header) {
      return orderBy(
        merge(orderBy(arr, ['feature'], ['asc']), orderBy(candidateArr, ['feature'], ['asc'])),
        ['importance', 'candidateImportance'],
        ['desc', 'desc'],
      );
    } else if (sortTable.header === 'attribute') {
      return merge(
        orderBy(arr, ['feature'], [sortTable.sortBy]),
        orderBy(candidateArr, ['feature'], [sortTable.sortBy]),
      );
    } else {
      return orderBy(
        merge(arr, candidateArr),
        ['importance', 'candidateImportance'],
        [sortTable.sortBy, sortTable.sortBy],
      );
    }
  }, [sortTable, isCollapsed, attributes, attributesCandidate]);

  const importanceTableData = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableRows: any = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mergedAttributes?.forEach((attribute: any) => {
      const attrLength = attribute?.feature?.split('.');
      const categoryName = attrLength?.[2] || '';
      let attrName = attrLength?.[3] || '';
      if (attrName === 'query') {
        attrName = attrLength?.[4] || '';
      }
      tableRows.push({
        key: `${attribute.feature}`,
        attribute: (
          <span
            className={`${MAIN_CLASS}__table__attribute ${
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
              attribute?.candidateIs_regressor! || attribute?.isRegressor! ? '' : 'inactive'
            }`}
          >
            {startCase(categoryName)} / <strong>{startCase(attrName)}</strong>
          </span>
        ),
        importance: (
          <div className={`${MAIN_CLASS}__table__importance`}>
            <div className={`${MAIN_CLASS}__table__lines`}>
              {attribute?.isRegressor
                ? renderBar(Math.round(attribute.importance ? attribute.importance * 100 : 0), 'current')
                : !isEmpty(attributes) && <div className={`${MAIN_CLASS}__omitted-bar`}>Omitted</div>}
              {attribute?.candidateIs_regressor
                ? renderBar(
                    Math.round(attribute?.candidateImportance ? attribute?.candidateImportance * 100 : 0),
                    'updated',
                  )
                : !isEmpty(attributesCandidate) && <div className={`${MAIN_CLASS}__omitted-bar`}>Omitted</div>}
            </div>
          </div>
        ),
      });
    });
    return tableRows;
  }, [mergedAttributes, attributes, attributesCandidate]);

  const onGenerateNewModel = async () => {
    setIsCollapsed(true);
    setIsEditing(false);
    const biasedFeatures: Array<string> = [];

    const activeAttributes = attributesCandidate ? attributesCandidate : attributes;
    activeAttributes?.forEach((attr) => {
      if (!selectedAttributes?.has(attr.feature)) return biasedFeatures.push(attr.feature);
    });

    const updatedAOPayload: AudienceOptimization = {
      ...instance.audienceOptimization,
      audienceOptimizationEligible: true,
      biasedFeatures,
    };

    await dispatch(
      updateJourneyAO(updatedAOPayload, async () => {
        if (instance.id) {
          await AoAPI.startTraining(instance.id).then(() => {
            setIsTrainingNewModel(true);
          });
        }
      }),
    );
  };

  const getAttributesAmount = () => {
    if (!isEmpty(attributes)) {
      return attributes?.length;
    }
    return 0;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleHeaderSort = (header: any) => {
    setIsCollapsed(false);
    if (header.key === sortTable.header) {
      setSortTable({ header: header.key, sortBy: sortTable.sortBy === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortTable({ header: header.key, sortBy: 'asc' });
    }
  };

  const onCancelModelTraining = async () => {
    if (instance.id) {
      await AoAPI.cancelTraining(instance.id).then(() => {
        setIsTrainingNewModel(false);
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCheckbox = (option: any) => {
    if (option.key && selectedAttributes) {
      if (selectedAttributes.has(option.key)) {
        selectedAttributes.delete(option.key);
      } else {
        selectedAttributes.add(option.key);
      }
      setSelectedAttributes(new Set(selectedAttributes));
    }
  };

  const scrollToTop = () => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.querySelector(`.${MAIN_CLASS}`)!.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  if (isLoading) {
    return <Skeleton width={'100%'} height={500} />;
  } else if (instance.status !== 'draft') {
    return (
      <div className={MAIN_CLASS}>
        <div className="top">
          <p className="headline">Context Attributes {!isTrainingNewModel && <b>{getAttributesAmount()}</b>}</p>
          <FlightButton
            theme="minor"
            iconLeft="cog"
            label={'Manage attributes'}
            className={`graph-button${
              isTrainingNewModel || isEditing || (isEmpty(attributes) && isEmpty(attributesCandidate))
                ? `--disabled`
                : ''
            }`}
            onClick={() => setIsEditing(true)}
            disabled={isTrainingNewModel || isEditing || (isEmpty(attributes) && isEmpty(attributesCandidate))}
          />
        </div>
        {isTrainingNewModel && (
          <div className="table__loading">
            <div className={'performance-loading-state__icon'}>{getIcon('loading', {})}</div>
            <div className="table__loading__text">Updating context attributes</div>
            <FlightButton theme="link" label={'Cancel'} onClick={() => onCancelModelTraining()} />
          </div>
        )}
        {(!isEmpty(attributes) || !isEmpty(attributesCandidate)) && !isTrainingNewModel && (
          <div>
            <div className={`${MAIN_CLASS}__suggested-model`}>
              {!isEmpty(attributes) && (
                <>
                  <div className={`${MAIN_CLASS}__suggested-model__marker-current`}></div>
                  <span>Current targeting model</span>
                </>
              )}
              {!isEmpty(attributesCandidate) && (
                <>
                  <div
                    className={`${MAIN_CLASS}__suggested-model__marker-updated ${
                      !isEmpty(attributes) && 'margin-left'
                    }`}
                  ></div>
                  <span>Updated targeting model</span>
                </>
              )}
            </div>
            <div className={`${MAIN_CLASS}__table-container`}>
              <FlightTable
                className={`${MAIN_CLASS}__table`}
                tableHeaders={[
                  {
                    key: 'attribute',
                    name: 'Context attributes',
                    isVisible: true,
                    isSortable: true,
                  },
                  {
                    key: 'importance',
                    name: 'Importance factor (%)',
                    isVisible: true,
                    isSortable: true,
                  },
                ]}
                tableData={importanceTableData()}
                loadingRowNumber={2}
                hasPaginationBeforeTable={false}
                hasPaginationAfterTable={false}
                handleHeaderSort={handleHeaderSort}
                sortOrder={sortTable.sortBy.toString()}
                sortByKey={sortTable.header}
                allowRowSelect={isEditing}
                allowMultiSelect={isEditing}
                selectedDataKey={selectedAttributes}
                handleDataSelect={handleCheckbox}
                handleCheckboxMultiSelect={handleCheckbox}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                handleSelectAll={(keys: any) => setSelectedAttributes(keys)}
              />
              {(attributes.length > 3 || attributesCandidate.length > 3) && (
                <FlightButton
                  theme="link"
                  className={`${MAIN_CLASS}__table-button`}
                  label={`${isCollapsed ? 'More' : 'Less'} targeting details`}
                  iconRight={isCollapsed ? 'baselineKeyboardArrowDown' : 'baselineKeyboardArrowUp'}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                ></FlightButton>
              )}
            </div>
            {isEditing && (
              <div className={`${MAIN_CLASS}__footer`}>
                <hr />
                <FlightSnackbar
                  isVisible={true}
                  isFloating={false}
                  animation={true}
                  content="Removing context attributes as factors will require the model to be re-trained and may take several minutes to generate the new model."
                  type="warning"
                  isAutoDismiss={false}
                  actionName=""
                  className={`${MAIN_CLASS}__footer__warning`}
                />
                {!selectedAttributes?.size && (
                  <FlightSnackbar
                    isVisible={true}
                    isFloating={false}
                    animation={true}
                    content="At least one context attribute is required."
                    type="error"
                    isAutoDismiss={false}
                    actionName=""
                    className={`${MAIN_CLASS}__footer__warning`}
                  />
                )}
                <div className={`${MAIN_CLASS}__footer__buttons`}>
                  <FlightButton
                    theme="secondary"
                    onClick={() => {
                      setIsEditing(false);
                      setIsCollapsed(true);
                      scrollToTop();
                    }}
                    label="Cancel"
                    className={`${MAIN_CLASS}__footer__buttons__cancel`}
                  />
                  <FlightButton
                    onClick={onGenerateNewModel}
                    disabled={disableRetrain || !selectedAttributes?.size}
                    label="Generate new model"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
