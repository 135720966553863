// errors.ts
import TemplateErrorImage from 'assets/images/template.error.png';
import SystemErrorImage from 'assets/images/system.error.png';

export class JourneyException extends Error {
  title: string;
  desc: string;
  image: string;

  constructor(message: string) {
    super(message);
    this.title = 'System error!';
    this.desc = 'Looks like something went wrong, please try to login again or refresh the page.';
    this.image = SystemErrorImage;
    this.name = 'SystemError';
  }
}

export class TemplateError extends JourneyException {
  constructor(message: string) {
    super(message);
    this.name = 'TemplateError';
    this.title = 'Template breakdown';
    this.desc = 'Looks like the template you were trying to use has broken down!';
    this.image = TemplateErrorImage;
  }
}

export class InstanceError extends JourneyException {
  constructor(message: string) {
    super(message);
    this.name = 'InstanceError';
    this.title = 'Instance breakdown';
    this.desc = 'Looks like the instance you were trying to use has broken down!';
    this.image = TemplateErrorImage;
  }
}

export class SystemError extends JourneyException {}
