export enum WEBHOOK_ACTION_TYPES {
  WEBHOOK = 'webhook-notification',
}

export enum WebhookActionTypes {
  SET_WEBHOOK = 'SET_WEBHOOK',
}

export type WebhookStateItem = {
  actionType: WEBHOOK_ACTION_TYPES;
  refId: string;
  isOptional?: boolean;
  id: string;
  name: string;
  description: string;
  url: string;
  method: string;
  payload: { [key: string]: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payloads: any;
  headers: { [key: string]: string };
  certificateName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contextVariables: any;
};

export type WebhookState = {
  byRefId: {
    [refId: string]: WebhookStateItem;
  };
  allRefId: string[];
  stepRefIdMap: {
    [idx: number]: string;
  };
};

export type SetWebhookAction = {
  type: WebhookActionTypes.SET_WEBHOOK;
  payload: {
    refId: string;
    stepIdx: number;
    webhook: WebhookStateItem;
  };
};
