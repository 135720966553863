import { RuleActionTypes } from './rule.type';
import { RemoveRuleAction, InsertRuleAction, SaveRuleAction, SaveContextualSchedulerAction } from 'store/actionTypes';

export function updateRuleAction(payload: SaveRuleAction['payload']) {
  return {
    type: RuleActionTypes.UPDATE_RULE,
    payload,
  };
}

export function removeRuleAction(payload: RemoveRuleAction['payload']) {
  return {
    type: RuleActionTypes.REMOVE_RULE,
    payload,
  };
}

export function insertRuleAction(payload: InsertRuleAction['payload']) {
  return {
    type: RuleActionTypes.INSERT_RULE,
    payload,
  };
}

export function saveContextualSchedulerAction(payload: SaveContextualSchedulerAction['payload']) {
  return {
    type: RuleActionTypes.SAVE_CONTEXTUAL_SCHEDULER,
    payload,
  };
}
