import React, { useEffect } from 'react';
import './Zones.scss';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { TExperienceCanvasRouteParams } from 'pages/ExperienceCanvas/types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import ZonesList from 'components/Zones/v2/ZonesList/ZonesList';
import usePrioritizationContext from './PrioritizationContext/usePrioritizationContext';
import PublishChangesBanner from 'components/Shared/PublishChangesBanner/PublishChangesBanner';

const MAIN_CLASS = 'zm';
const CLASSES = {
  CONTAINER: `${MAIN_CLASS}__container`,
  HEADER: `${MAIN_CLASS}__header`,
  BODY: `${MAIN_CLASS}__content`,
};
const LOADING_WRAPPER = 'zm-loading-wrapper';

const ZonesV2 = function () {
  const { flags } = useFeatureFlag();
  const history = useHistory();
  const { pid: projectId } = useRouteMatch<TExperienceCanvasRouteParams>().params;

  const prioritizationContext = usePrioritizationContext();
  const { zones, isZoneConfigLoading } = prioritizationContext;
  useEffect(() => {
    document.title = 'Zones and Modules | Experience Studio @ Flybits';
  }, []);
  useEffect(() => {
    if (!zones?.length && !isZoneConfigLoading) {
      // redirect to onboarding screen and remove the history entry to avoid loops
      history.replace(`/project/${projectId}/zones/v2/onboarding`);
    }
  }, [history, isZoneConfigLoading, projectId, zones]);

  if (!flags['tx_zones_modules_v2']) {
    return false;
  }

  if (isZoneConfigLoading) {
    return (
      <div className={LOADING_WRAPPER}>
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
      <PublishChangesBanner />
      <div className={CLASSES.CONTAINER}>
        <main className={CLASSES.BODY}>
          <ZonesList />
        </main>
      </div>
    </>
  );
};

export default ZonesV2;
