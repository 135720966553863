import './ModuleBox.scss';

import React, { useContext } from 'react';
import { Module } from 'pages/Zones/types';
import { FlightButton } from '@flybits/design-system';
import PinnedSection from './ModuleBoxComponents/PinnedSection';
import LabeledAndOrderContent from './ModuleBoxComponents/LabelsSection';
import EnableDeprioritized from './ModuleBoxComponents/DeprioritizeSection';
import PredefinedLayoutIcon from './PredefinedLayoutIcons';
import { Content } from 'interface/content/content.interface';
import { UseQueryResult } from '@tanstack/react-query';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import CustomLayoutIcon from './CustomLayoutIcon';

const MAIN_CLASS = 'modulev2-box';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  BODY: `${MAIN_CLASS}__body`,
};

type ModuleBoxProps = {
  module: Module;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  languages: any[];
  contentQueriesResults: UseQueryResult<Content, unknown>[];
};

function ModuleBox({ module, isLoading, languages, contentQueriesResults }: ModuleBoxProps) {
  const { setSelectedModule, setShowModuleDetailsSlideout } = useContext(PrioritizationContext);

  const ModuleBoxHeader = () => {
    return (
      <div className={`${CLASSES.HEADER}`}>
        <div className={`${CLASSES.HEADER}__module-info`}>
          <div className={`${CLASSES.HEADER}__module-info__module-image`}>
            {!module.layout ? <CustomLayoutIcon /> : <PredefinedLayoutIcon predefinedIconTitle={module.layout} />}
          </div>
          <div className={`${CLASSES.HEADER}__module-info__module-text`}>{module.name}</div>
        </div>
        <div className={`${CLASSES.HEADER}__overflow-container`}>
          {/* This will be looked once we revamp the file upload. */}
          {/* <FlightButton
            className={`${CLASSES.HEADER}__overflow-container__screenshot`}
            iconLeft="eyeOpen"
            label="View screenshot"
            size="small"
            theme="link"
            onClick={() => null}
          /> */}
          <FlightButton
            className={`${CLASSES.HEADER}__overflow-container__edit`}
            iconLeft="pencil"
            label="Edit"
            size="small"
            theme="link"
            onClick={() => {
              setSelectedModule(module);
              setShowModuleDetailsSlideout(true);
            }}
          />
        </div>
      </div>
    );
  };

  const ModuleBody = () => {
    return (
      <div className={CLASSES.BODY}>
        <PinnedSection
          module={module}
          sectionNumber={1}
          isLoading={isLoading}
          languages={languages}
          contentQueriesResults={contentQueriesResults}
        />
        <hr />
        <LabeledAndOrderContent sectionNumber={2} module={module} />
        <hr />
        <EnableDeprioritized sectionNumber={3} module={module} />
      </div>
    );
  };

  // ModuleBox return
  return (
    <div className={MAIN_CLASS}>
      <ModuleBoxHeader />
      <ModuleBody />
    </div>
  );
}

export default ModuleBox;
