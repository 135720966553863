import React from 'react';
import { TIconProps } from '../types';

function UploadingIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6667 1.35327C11.82 1.6666 14.3334 4.1666 14.6334 7.33327C14.7212 8.20371 14.6365 9.08292 14.384 9.92056C14.1315 10.7582 13.7162 11.5378 13.162 12.2147C12.6077 12.8915 11.9253 13.4524 11.154 13.8652C10.3826 14.2779 9.53736 14.5344 8.6667 14.6199V13.2866C11.0934 12.9999 13 11.0733 13.3067 8.64661C13.3931 7.95139 13.3415 7.24597 13.155 6.5707C12.9685 5.89542 12.6506 5.26355 12.2197 4.71123C11.7887 4.15891 11.2531 3.69698 10.6434 3.35186C10.0338 3.00674 9.36205 2.78521 8.6667 2.69994V1.35327ZM7.33337 1.37327V2.7066C6.38003 2.83994 5.48003 3.2266 4.73337 3.8266L3.78003 2.83994C4.79117 2.00673 6.02891 1.49585 7.33337 1.37327ZM2.84003 3.77994L3.79337 4.73327C3.20269 5.48641 2.8251 6.38434 2.70003 7.33327H1.3667C1.50003 6.02661 2.00003 4.79327 2.84003 3.77994ZM1.37337 8.6666H2.7067C2.8267 9.61327 3.2067 10.5133 3.79337 11.2666L2.8467 12.2199C2.01848 11.2052 1.50621 9.9697 1.37337 8.6666ZM4.73337 12.2466C5.4867 12.8333 6.3867 13.2133 7.33337 13.3333V14.6666C6.0267 14.5266 4.7867 13.9999 3.78003 13.1599L4.73337 12.2466ZM8.00003 4.99994L5.00003 7.99994H7.33337V10.6666H8.6667V7.99994H11L8.00003 4.99994Z"
        fill={fill}
      />
    </svg>
  );
}

export default UploadingIcon;
