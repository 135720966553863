import React from 'react';
import ContentPreviewCard from '../ContentPreviewCard/ContentPreviewCard';
import PushPreviewCard from '../PushPreviewCard/PushPreviewCard';
import { ContentPreviewProps } from '../types';
import headerImg from 'assets/images/button-preview-ios.svg';

import './SmartphonePreview.scss';
import { TemplateType } from 'interface/content/content.interface';
import DynamicContentPreview from 'components/ExperienceCanvas/DynamicContentIframe/DynamicContentPreviewIframe';

type TSmartphonePreviewProps = {
  isTemplate?: boolean;
  push?: {
    title: string;
    body: string;
  };
  contents?: ContentPreviewProps[];
};

const MAIN_CLASS = 'smartphone-preview';
const CLASSES = {
  PUSH_PREVIEW: `${MAIN_CLASS}__push-preview`,
  CONTENT_PREVIEW: `${MAIN_CLASS}__content-preview`,
};

function SmartphonePreview({ isTemplate = false, contents = [], push }: TSmartphonePreviewProps) {
  const isDynamicContentDemo = !isTemplate && contents.find((content) => content.renderType);
  const contentPreviewClass =
    CLASSES.CONTENT_PREVIEW + (isDynamicContentDemo ? ` ${CLASSES.CONTENT_PREVIEW}__dynamic` : '');
  const getActions = (content: ContentPreviewProps) => {
    const type = content.templateType;
    switch (type) {
      case TemplateType.CONCIERGE_CARD_BUTTONS:
        return isTemplate ? ['Activate', 'Learn More'] : content?.buttons || [];
      case TemplateType.CONCIERGE_CARD_LINK:
        return isTemplate ? ['Learn More'] : [content?.link];
      default:
        return [];
    }
  };

  return (
    <div className={MAIN_CLASS}>
      {push && (
        <div className={CLASSES.PUSH_PREVIEW}>
          <PushPreviewCard title={push.title} body={push.body} />
        </div>
      )}
      {contents.length > 0 && (
        <div className={contentPreviewClass}>
          {isDynamicContentDemo ? (
            <DynamicContentPreview contentInstanceIds={contents.map((c) => c.id).filter((id) => !!id)} />
          ) : (
            contents.map((c, index) => (
              <ContentPreviewCard
                key={index}
                imageUrl={isTemplate ? headerImg : c.imageUrl}
                header={isTemplate ? 'Inform Customers' : c.header}
                title={c.title}
                description={c.description}
                type={c.templateType}
                actions={getActions(c)}
                isSupported={c.isSupported}
                isLoading={!c.id && !c.refId}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default SmartphonePreview;
