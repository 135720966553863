import React from 'react';
import { IconProps } from './icon.types';

function IconTargetAudience(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <g clipPath="url(#clip0_1539_55259)">
        <path
          d="M5.58768 10.3153C4.81597 10.3262 4.06468 10.5649 3.42828 11.0016C2.79187 11.4382 2.29875 12.0532 2.01092 12.7693C1.72308 13.4854 1.65337 14.2707 1.81055 15.0263C1.96773 15.7819 2.34479 16.4742 2.89433 17.0161C3.44386 17.558 4.14134 17.9254 4.89907 18.072C5.65681 18.2186 6.44098 18.138 7.153 17.8402C7.86502 17.5424 8.47311 17.0407 8.90082 16.3983C9.32853 15.7558 9.55677 15.0013 9.55685 14.2295C9.5569 13.7108 9.45387 13.1973 9.25373 12.7188C9.05359 12.2403 8.76035 11.8063 8.39104 11.4421C8.02173 11.0779 7.58372 10.7908 7.10247 10.5973C6.62121 10.4039 6.10631 10.308 5.58768 10.3153ZM5.58768 16.732C4.92056 16.7321 4.28021 16.4696 3.80506 16.0013C3.32992 15.533 3.05815 14.8965 3.04852 14.2295C3.04902 13.7936 3.15936 13.3648 3.36935 12.9828C4.46183 13.6902 5.73453 14.0688 7.03602 14.0737C7.40159 14.0746 7.76657 14.044 8.12685 13.982C8.1314 14.0644 8.1314 14.1471 8.12685 14.2295C8.12444 14.9022 7.85614 15.5466 7.38048 16.0223C6.90481 16.498 6.26037 16.7662 5.58768 16.7687V16.732Z"
          fill="#4992FD"
        />
        <path
          d="M4.18563 19.2437C3.33124 19.5031 2.54634 19.9519 1.8893 20.5565C1.23227 21.1612 0.720011 21.9062 0.390625 22.7362H10.804C10.329 21.4379 9.38926 20.362 8.16662 19.7168C6.94398 19.0716 5.52543 18.903 4.18563 19.2437ZM6.01896 21.297C6.01896 21.4185 5.97067 21.5351 5.88472 21.6211C5.79876 21.707 5.68218 21.7553 5.56063 21.7553C5.43907 21.7553 5.32249 21.707 5.23653 21.6211C5.15058 21.5351 5.10229 21.4185 5.10229 21.297V20.1145C5.10229 19.9929 5.15058 19.8763 5.23653 19.7904C5.32249 19.7044 5.43907 19.6562 5.56063 19.6562C5.68218 19.6562 5.79876 19.7044 5.88472 19.7904C5.97067 19.8763 6.01896 19.9929 6.01896 20.1145V21.297Z"
          fill="#4992FD"
        />
        <path
          d="M19.7048 7.71197L18.4031 7.5928C18.6872 8.25296 18.8338 8.9641 18.834 9.6828C18.8346 10.6022 18.5913 11.5053 18.1289 12.3C17.6665 13.0947 17.0017 13.7525 16.2021 14.2063C15.4025 14.6602 14.4968 14.8938 13.5775 14.8834C12.6582 14.8729 11.758 14.6188 10.969 14.147C10.9734 14.1743 10.9734 14.2022 10.969 14.2295C10.9701 14.7932 10.8804 15.3535 10.7031 15.8886C11.9087 16.4581 13.254 16.6642 14.5747 16.4818C15.8955 16.2994 17.1345 15.7363 18.1406 14.8615C19.1468 13.9866 19.8764 12.8377 20.2404 11.5551C20.6044 10.2725 20.5871 8.9116 20.1906 7.63864C20.0337 7.68908 19.8696 7.71384 19.7048 7.71197Z"
          fill="#4992FD"
        />
        <path
          d="M8.43928 9.66452C8.44365 8.81922 8.65376 7.9877 9.05146 7.24179C9.44916 6.49588 10.0225 5.85801 10.7219 5.3833C11.4214 4.9086 12.2258 4.61132 13.0659 4.51716C13.9059 4.42299 14.7563 4.53478 15.5435 4.84285L15.4151 3.42202C15.4014 3.30324 15.4014 3.18329 15.4151 3.06452C14.8327 2.90522 14.2314 2.82506 13.6276 2.82618C11.9305 2.82852 10.2944 3.45932 9.035 4.59686C7.77558 5.7344 6.98208 7.29808 6.80762 8.98618C7.38564 9.11936 7.9372 9.34866 8.43928 9.66452Z"
          fill="#4992FD"
        />
        <path
          d="M15.5245 10.0313C15.4632 10.3725 15.3105 10.6906 15.0826 10.9518C14.8547 11.2131 14.5602 11.4076 14.2305 11.5146C13.9008 11.6217 13.5483 11.6373 13.2104 11.5597C12.8725 11.4822 12.562 11.3145 12.312 11.0744C12.062 10.8343 11.8817 10.5309 11.7905 10.1964C11.6993 9.862 11.7006 9.50909 11.7942 9.17531C11.8877 8.84153 12.0701 8.53939 12.3219 8.30109C12.5736 8.06279 12.8853 7.89726 13.2237 7.82213L14.5895 6.38296C13.8981 6.18905 13.1633 6.21712 12.4887 6.46322C11.8141 6.70932 11.2338 7.16103 10.8297 7.7546C10.4256 8.34818 10.2181 9.05366 10.2365 9.7715C10.2548 10.4893 10.4981 11.1833 10.932 11.7555C11.3659 12.3276 11.9685 12.7491 12.6547 12.9604C13.341 13.1718 14.0763 13.1623 14.7569 12.9333C15.4375 12.7043 16.029 12.2674 16.448 11.6842C16.8669 11.101 17.0922 10.401 17.092 9.68296C17.0827 9.311 17.0147 8.94284 16.8904 8.59213L15.5245 10.0313Z"
          fill="#4992FD"
        />
        <path
          d="M22.0698 2.94537L20.6582 2.46871L20.1815 1.05704C20.156 0.98217 20.112 0.914977 20.0536 0.861746C19.9951 0.808516 19.9241 0.770988 19.8471 0.752673C19.7702 0.734357 19.6899 0.735853 19.6137 0.757021C19.5375 0.778188 19.4679 0.818335 19.4115 0.873706L17.3123 2.97287C17.2168 3.06803 17.1435 3.1831 17.0975 3.30985C17.0516 3.43661 17.0341 3.57194 17.0465 3.70621L17.1657 4.97121L13.1415 9.21537C13.0127 9.34428 12.9404 9.51902 12.9404 9.70121C12.9404 9.88339 13.0127 10.0581 13.1415 10.187C13.2704 10.3158 13.4451 10.3881 13.6273 10.3881C13.8095 10.3881 13.9843 10.3158 14.1132 10.187L18.119 5.96121L19.4207 6.08037C19.5549 6.09272 19.6903 6.0753 19.817 6.02935C19.9438 5.9834 20.0588 5.91005 20.154 5.81454L22.2532 3.71537C22.3085 3.65892 22.3487 3.58935 22.3698 3.51316C22.391 3.43697 22.3925 3.35665 22.3742 3.27973C22.3559 3.20281 22.3183 3.13178 22.2651 3.07331C22.2119 3.01483 22.1447 2.97082 22.0698 2.94537Z"
          fill="#4992FD"
        />
      </g>
      <defs>
        <clipPath id="clip0_1539_55259">
          <rect width="22" height="22" fill="white" transform="translate(0.390625 0.736328)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconTargetAudience;
