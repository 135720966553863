import React, { ChangeEvent, useState } from 'react';
import { FlightButton, FlightSelect, FlightTextInput, getIcon } from '@flybits/design-system';
import { genUUID } from 'helpers/common.helper';
import { SelectOption } from 'types/common';
import { Zone, ZoneConfig } from 'pages/Zones/types';
import ImageAddModule from 'pages/Zones/assets/images/image-add-module.svg';
import './AddModuleBox.scss';
import ModuleConfigurationSidePanel from 'pages/Zones/ModuleConfigurationSidePanel/ModuleConfigurationSidePanel';

type TAddModuleBoxProps = {
  selectedZoneId: string;
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
};

type Form = {
  moduleName: string;
  uiTheme: SelectOption;
  moduleHeight: string;
  layoutHTML: string;
  styleCSS: string;
};

const MAIN_CLASS = 'add-module-box';
const CLASSES = {
  FORM_BOX: `${MAIN_CLASS}__form-box`,
  FORM_HEADER: `${MAIN_CLASS}__form-header`,
  FORM_BODY: `${MAIN_CLASS}__form-body`,
  FORM_FOOTER: `${MAIN_CLASS}__form-footer`,
  FIELD: `${MAIN_CLASS}__field`,
  BUTTON_LINK: `${MAIN_CLASS}__button-link`,
  VIEW_BOX: `${MAIN_CLASS}__view-box`,
  CONTAINER: `${MAIN_CLASS}__container`,
  TEXT: `${MAIN_CLASS}__text`,
  BUTTON_WRAPPER: `${MAIN_CLASS}__button-wrapper`,
};

const uiThemeOptions = [
  { key: '', name: 'Default' },
  { key: 'expose', name: 'Exposé' },
  { key: 'horizontal', name: 'Horizontal' },
  { key: 'vertical', name: 'Vertical' },
];

const initialFormData: Form = {
  moduleName: '',
  uiTheme: uiThemeOptions[0],
  moduleHeight: '',
  layoutHTML: '',
  styleCSS: '',
};

const AddModuleBox: React.FC<TAddModuleBoxProps> = ({ selectedZoneId, zoneConfig, setZoneConfig }) => {
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);
  const [isModuleConfigurationSidePanelOpen, setIsModuleConfigurationSidePanelOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const toggleFormVisibility = () => {
    setIsAddFormVisible((prevState) => !prevState);
  };

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUIThemeChange = (theme: SelectOption) => {
    setFormData({ ...formData, uiTheme: theme });
  };

  const handleSetupModuleClick = () => {
    const map = Object.fromEntries(zoneConfig.zones.map((zone) => [zone.id, zone]));
    const modules = !!map[selectedZoneId].modules ? [...map[selectedZoneId].modules] : [];
    modules.push({
      id: genUUID(),
      name: formData.moduleName,
      layout: formData.uiTheme.key,
      height: formData.moduleHeight ? +formData.moduleHeight : undefined,
      layoutHTML: formData.layoutHTML,
      styleCSS: formData.styleCSS,
    });
    map[selectedZoneId].modules = modules;
    const zones: Zone[] = Object.values(map);
    setIsAddFormVisible(false);
    setZoneConfig({
      ...zoneConfig,
      zones,
    });
    setFormData(initialFormData);
  };

  return (
    <div className={MAIN_CLASS}>
      {isAddFormVisible && (
        <div className={CLASSES.FORM_BOX}>
          <div className={CLASSES.FORM_HEADER}>
            <input
              type="text"
              name="moduleName"
              placeholder="Name this module"
              value={formData.moduleName}
              onChange={handleInputChange}
            />
            <button type="button" onClick={toggleFormVisibility}>
              {getIcon('clear', {})}
            </button>
          </div>
          <hr />
          <div className={CLASSES.FORM_BODY}>
            <div className={CLASSES.FIELD}>
              <label htmlFor="ui-theme">UI Theme</label>
              <p>Select a format in which you want the content within this module to be presented in.</p>
              <FlightSelect
                ariaControlsId="ui-theme"
                options={uiThemeOptions}
                selected={formData.uiTheme}
                handleOptionClick={handleUIThemeChange}
              />
            </div>
            <div className={CLASSES.FIELD}>
              <label htmlFor="module-height">Module Height</label>
              <FlightTextInput name="moduleHeight" value={formData.moduleHeight} onChange={handleInputChange} />
            </div>
            <div className={CLASSES.FIELD}>
              <button
                className={CLASSES.BUTTON_LINK}
                type="button"
                onClick={() => setIsModuleConfigurationSidePanelOpen(true)}
              >
                Configure HTML or CSS {getIcon('cogOutline', {})}
              </button>
            </div>
          </div>
          <div className={CLASSES.FORM_FOOTER}>
            <FlightButton label="Setup Module" onClick={handleSetupModuleClick} />
          </div>
        </div>
      )}
      <div className={CLASSES.VIEW_BOX}>
        <img src={ImageAddModule} alt="add module" />
        <div className={CLASSES.CONTAINER}>
          <div className={CLASSES.TEXT}>Setup your module to start prioritizing content and labels</div>
          <div className={CLASSES.BUTTON_WRAPPER}>
            <FlightButton iconLeft="add" label="Add module" onClick={toggleFormVisibility} />
          </div>
        </div>
      </div>
      <ModuleConfigurationSidePanel
        isOpen={isModuleConfigurationSidePanelOpen}
        zoneId={selectedZoneId}
        zoneConfig={zoneConfig}
        setZoneConfig={setZoneConfig}
        onChange={handleInputChange}
        form={{ layoutHTML: formData.layoutHTML, styleCSS: formData.styleCSS }}
        onClose={() => setIsModuleConfigurationSidePanelOpen(false)}
      />
    </div>
  );
};

export default AddModuleBox;
