import { Pagination } from 'interface/shared/api.interface';
import BaseAPI from './base.api';

type LabelsAPIResponse = {
  data: string[];
  pagination: Pagination;
};

export default class LabelsAPI extends BaseAPI {
  private ROUTES = {
    labels: '/kernel/labels',
  };

  get(): Promise<LabelsAPIResponse> {
    return this.GET(`${this.ROUTES.labels}?limit=999`);
  }

  getLabels(searchTerm = '', page = 0, limit = 10000): Promise<{ data: string[] }> {
    return this.GET(this.ROUTES.labels + `?limit=${limit}&offset=${page * limit}&sortorder=asc&search=${searchTerm}`);
  }

  putLabels(labels: string[]): Promise<{ data: string[] }> {
    return this.PUT(this.ROUTES.labels, { data: labels });
  }
}
