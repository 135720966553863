import React from 'react';
import { TIconProps } from '../types';

function TargetAudienceIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8998 18.3346C34.1332 11.3846 28.6165 5.86797 21.6665 5.1013V1.66797H18.3332V5.1013C11.3832 5.86797 5.8665 11.3846 5.09984 18.3346H1.6665V21.668H5.09984C5.8665 28.618 11.3832 34.1346 18.3332 34.9013V38.3346H21.6665V34.9013C28.6165 34.1346 34.1332 28.618 34.8998 21.668H38.3332V18.3346H34.8998ZM19.9998 31.668C13.5498 31.668 8.33317 26.4513 8.33317 20.0013C8.33317 13.5513 13.5498 8.33463 19.9998 8.33463C26.4498 8.33463 31.6665 13.5513 31.6665 20.0013C31.6665 26.4513 26.4498 31.668 19.9998 31.668Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8916 20.9414C25.0333 21.7164 25.8333 22.7664 25.8333 24.1664V26.6664H28.1666C28.7189 26.6664 29.1666 26.2187 29.1666 25.6664V24.1664C29.1666 22.3497 26.1916 21.2747 23.8916 20.9414Z"
        fill={fill}
      />
      <path
        d="M17.5003 19.9987C19.3413 19.9987 20.8337 18.5063 20.8337 16.6654C20.8337 14.8244 19.3413 13.332 17.5003 13.332C15.6594 13.332 14.167 14.8244 14.167 16.6654C14.167 18.5063 15.6594 19.9987 17.5003 19.9987Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4999 19.9987C24.3416 19.9987 25.8333 18.507 25.8333 16.6654C25.8333 14.8237 24.3416 13.332 22.4999 13.332C22.1083 13.332 21.7416 13.4154 21.3916 13.532C22.0833 14.3904 22.4999 15.482 22.4999 16.6654C22.4999 17.8487 22.0833 18.9404 21.3916 19.7987C21.7416 19.9154 22.1083 19.9987 22.4999 19.9987Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5002 20.832C15.2752 20.832 10.8335 21.9487 10.8335 24.1654V25.6654C10.8335 26.2176 11.2812 26.6654 11.8335 26.6654H23.1668C23.7191 26.6654 24.1668 26.2176 24.1668 25.6654V24.1654C24.1668 21.9487 19.7252 20.832 17.5002 20.832Z"
        fill={fill}
      />
    </svg>
  );
}

export default TargetAudienceIcon;
