import React from 'react';
import { TIconProps } from '../types';

function SaveIcon({ fill, className = '', ariaHidden = false }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden={ariaHidden}
    >
      <g clipPath="url(#clip0_33_1937)">
        <path
          d="M19 2H14.82C14.4 0.84 13.3 0 12 0C10.7 0 9.6 0.84 9.18 2H5C3.9 2 3 2.9 3 4V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V4C21 2.9 20.1 2 19 2ZM12 2C12.55 2 13 2.45 13 3C13 3.55 12.55 4 12 4C11.45 4 11 3.55 11 3C11 2.45 11.45 2 12 2ZM19 20H5V4H7V7H17V4H19V20Z"
          fill={fill}
        />
        <path
          d="M8 15.3336V17H9.66644L14.5813 12.0851L12.9149 10.4187L8 15.3336ZM15.87 10.7964C16.0433 10.6231 16.0433 10.3431 15.87 10.1698L14.8302 9.12998C14.6569 8.95667 14.3769 8.95667 14.2036 9.12998L13.3904 9.9432L15.0568 11.6096L15.87 10.7964Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_33_1937">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SaveIcon;
