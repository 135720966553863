import React from 'react';

function ContentManagerSlideoutIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#2371F1" />
      <path
        d="M27 14.1667C27 13.8572 26.8771 13.5605 26.6583 13.3417C26.4395 13.1229 26.1428 13 25.8333 13H14.1667C13.8572 13 13.5605 13.1229 13.3417 13.3417C13.1229 13.5605 13 13.8572 13 14.1667V25.8333C13 26.1428 13.1229 26.4395 13.3417 26.6583C13.5605 26.8771 13.8572 27 14.1667 27H25.8333C26.1428 27 26.4395 26.8771 26.6583 26.6583C26.8771 26.4395 27 26.1428 27 25.8333V14.1667ZM25.8333 25.5417C25.8333 25.619 25.8026 25.6932 25.7479 25.7479C25.6932 25.8026 25.619 25.8333 25.5417 25.8333H14.4583C14.381 25.8333 14.3068 25.8026 14.2521 25.7479C14.1974 25.6932 14.1667 25.619 14.1667 25.5417V14.4583C14.1667 14.381 14.1974 14.3068 14.2521 14.2521C14.3068 14.1974 14.381 14.1667 14.4583 14.1667H25.5417C25.619 14.1667 25.6932 14.1974 25.7479 14.2521C25.8026 14.3068 25.8333 14.381 25.8333 14.4583V25.5417Z"
        fill="white"
      />
      <path
        d="M15.6253 22.6248H22.042C22.1967 22.6248 22.3451 22.5634 22.4545 22.454C22.5639 22.3446 22.6253 22.1962 22.6253 22.0415V15.6248C22.6253 15.4701 22.5639 15.3218 22.4545 15.2124C22.3451 15.103 22.1967 15.0415 22.042 15.0415H15.6253C15.4706 15.0415 15.3222 15.103 15.2128 15.2124C15.1035 15.3218 15.042 15.4701 15.042 15.6248V22.0415C15.042 22.1962 15.1035 22.3446 15.2128 22.454C15.3222 22.5634 15.4706 22.6248 15.6253 22.6248ZM16.1095 20.6765L17.037 18.874C17.0669 18.8155 17.1098 18.7646 17.1623 18.7252C17.2149 18.6857 17.2758 18.6588 17.3403 18.6465C17.406 18.6342 17.4736 18.6367 17.5382 18.6539C17.6028 18.6711 17.6627 18.7024 17.7137 18.7457L18.4253 19.3815C18.4418 19.396 18.4616 19.4063 18.4829 19.4114C18.5043 19.4164 18.5266 19.4162 18.5478 19.4107C18.5693 19.4072 18.5898 19.3992 18.608 19.3871C18.6261 19.375 18.6414 19.3592 18.6528 19.3407L19.662 17.4565C19.7034 17.3815 19.7657 17.3201 19.8412 17.2797C19.9168 17.2393 20.0025 17.2217 20.0878 17.229C20.1734 17.2379 20.2547 17.2712 20.3219 17.325C20.3892 17.3788 20.4395 17.4508 20.467 17.5323L21.4878 20.7407C21.5082 20.8063 21.513 20.8758 21.5018 20.9437C21.4907 21.0115 21.4639 21.0758 21.4237 21.1315C21.3833 21.1881 21.3298 21.234 21.2679 21.2655C21.2059 21.297 21.1373 21.3131 21.0678 21.3123H16.5003C16.4254 21.3117 16.3519 21.2921 16.2866 21.2554C16.2213 21.2187 16.1664 21.166 16.127 21.1023C16.0869 21.0392 16.0642 20.9665 16.0611 20.8918C16.0581 20.817 16.0747 20.7428 16.1095 20.6765Z"
        fill="white"
      />
      <path
        d="M21.0672 23.5H21.8488C21.8488 23.5 22.333 23.5 22.333 23.9842V24.4742C22.333 24.4742 22.333 24.9583 21.8488 24.9583H21.0672C21.0672 24.9583 20.583 24.9583 20.583 24.4742V23.9842C20.583 23.9842 20.583 23.5 21.0672 23.5Z"
        fill="white"
      />
      <path
        d="M23.6922 23.5H24.4738C24.4738 23.5 24.958 23.5 24.958 23.9842V24.4742C24.958 24.4742 24.958 24.9583 24.4738 24.9583H23.6922C23.6922 24.9583 23.208 24.9583 23.208 24.4742V23.9842C23.208 23.9842 23.208 23.5 23.6922 23.5Z"
        fill="white"
      />
      <path
        d="M15.4795 24.6665H18.1045C18.2205 24.6665 18.3318 24.6204 18.4139 24.5384C18.4959 24.4563 18.542 24.345 18.542 24.229C18.542 24.113 18.4959 24.0017 18.4139 23.9196C18.3318 23.8376 18.2205 23.7915 18.1045 23.7915H15.4795C15.3635 23.7915 15.2522 23.8376 15.1701 23.9196C15.0881 24.0017 15.042 24.113 15.042 24.229C15.042 24.345 15.0881 24.4563 15.1701 24.5384C15.2522 24.6204 15.3635 24.6665 15.4795 24.6665Z"
        fill="white"
      />
      <path
        d="M23.9375 15.9165H24.5208C24.6369 15.9165 24.7481 15.8704 24.8302 15.7884C24.9122 15.7063 24.9583 15.595 24.9583 15.479C24.9583 15.363 24.9122 15.2517 24.8302 15.1696C24.7481 15.0876 24.6369 15.0415 24.5208 15.0415H23.9375C23.8215 15.0415 23.7102 15.0876 23.6281 15.1696C23.5461 15.2517 23.5 15.363 23.5 15.479C23.5 15.595 23.5461 15.7063 23.6281 15.7884C23.7102 15.8704 23.8215 15.9165 23.9375 15.9165Z"
        fill="white"
      />
      <path
        d="M23.9375 17.9585H24.5208C24.6369 17.9585 24.7481 17.9124 24.8302 17.8304C24.9122 17.7483 24.9583 17.637 24.9583 17.521C24.9583 17.405 24.9122 17.2937 24.8302 17.2116C24.7481 17.1296 24.6369 17.0835 24.5208 17.0835H23.9375C23.8215 17.0835 23.7102 17.1296 23.6281 17.2116C23.5461 17.2937 23.5 17.405 23.5 17.521C23.5 17.637 23.5461 17.7483 23.6281 17.8304C23.7102 17.9124 23.8215 17.9585 23.9375 17.9585Z"
        fill="white"
      />
      <path
        d="M23.9375 20H24.5208C24.6369 20 24.7481 19.9539 24.8302 19.8719C24.9122 19.7898 24.9583 19.6785 24.9583 19.5625C24.9583 19.4465 24.9122 19.3352 24.8302 19.2531C24.7481 19.1711 24.6369 19.125 24.5208 19.125H23.9375C23.8215 19.125 23.7102 19.1711 23.6281 19.2531C23.5461 19.3352 23.5 19.4465 23.5 19.5625C23.5 19.6785 23.5461 19.7898 23.6281 19.8719C23.7102 19.9539 23.8215 20 23.9375 20Z"
        fill="white"
      />
    </svg>
  );
}

export default ContentManagerSlideoutIcon;
