import './Zone.scss';

import PublishChangesBanner from 'components/Shared/PublishChangesBanner/PublishChangesBanner';
import ZoneHeader from 'components/Zones/v2/ZoneHeader/ZoneHeader';

import React, { useContext, useEffect, useMemo } from 'react';

import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import PrioritizationContext from './PrioritizationContext/PrioritizationContext';
import LabelFilterSlideout from 'components/Zones/v2/LabelFilterSlideout/LabelFilterSlideout';

import ModuleBox from 'components/Zones/v2/ModuleInfo/ModuleBox';
import { LabelFilter, ZoneSelectorInfo } from 'pages/Zones/types';
import { useDispatch } from 'react-redux';
import { FlightButton } from '@flybits/design-system';

import useSettings from 'hooks/useSetting';
import LabelFilterProvider from 'components/Zones/v2/LabelFilterSlideout/LabelFilterContext/LabelFilterProvider';
import ModuleDetailsSlideout from 'components/Zones/v2/ModuleDetailsSlideout/ModuleDetailsSlideout';
import { useQueries } from '@tanstack/react-query';
import { fetchContentInstance } from 'store/content/content.thunk';
import { MODULE_LAYOUTS } from 'components/Zones/v2/types';
import ContentManagerSlideout from 'components/Zones/v2/ContentManagerSlideout/ContentManagerSlideout';
import CreateFirstModule from 'components/Zones/v2/CreateFirstModule/CreateFirstModule';
import PinnedContentScheduleSlideout from 'components/Zones/v2/PinnedContentScheduleSlideout/PinnedContentScheduleSlideout';

const MAIN_CLASS = 'zone';

type TRouteParams = {
  zoneId: string;
};

type ZonesListPageParams = {
  pid: string;
};

export type ModuleDetailsTab = { key: string; name: string; title: string; isDisabled?: boolean };

const Zone = function () {
  const history = useHistory();
  const { zoneId: routeZoneId } = useRouteMatch<TRouteParams>().params;
  const { pid } = useParams<ZonesListPageParams>();

  const { languages, isInitialized: isLanguageInitialized } = useSettings();
  const {
    zones,
    isLabelManagementOpen,
    setLabelManagementOpen,
    labelAndOrderedContentSelectedModuleId,
    setLabelAndOrderedContentSelectedModuleId,
    updateModule,
    isZoneConfigLoading,
    setSelectedModule,
    setShowModuleDetailsSlideout,
    setSelectedZone,
  } = useContext(PrioritizationContext);

  const dispatch = useDispatch();

  const [selectedZone, selectedZoneModules, pinnedContentIds] = useMemo(() => {
    const zone = zones?.find((zone) => zone.id === routeZoneId) ?? {
      id: '',
      name: '',
      referenceID: '',
      labels: [],
      modules: [],
    };
    // null coalesce may be unnecessary here depending on if zones can have null/undefined modules on a zone
    const modules = zone.modules ?? [];
    const contentIds: string[] = [];
    modules.forEach((module) => {
      module.pinnedContentIDsByTimeRange?.forEach((pc) => {
        if (!contentIds.find((cId) => cId === pc.contentID)) contentIds.push(pc.contentID);
      });
    });
    return [zone, modules, contentIds];
  }, [routeZoneId, zones]);

  const contentQueriesResults = useQueries({
    queries: pinnedContentIds.map((id) => ({
      queryKey: ['content-instance', id],
      queryFn: () => fetchContentInstance(id),
      staleTime: Infinity,
    })),
  });

  const isContentFetching = useMemo(() => {
    return contentQueriesResults.some((queriedContent) => queriedContent.isFetching);
  }, [contentQueriesResults]);

  const labelAndOrderedContentSelectedModule = useMemo(
    () => selectedZoneModules.find((module) => module.id === labelAndOrderedContentSelectedModuleId),
    [labelAndOrderedContentSelectedModuleId, selectedZoneModules],
  );

  const handleZoneSelected = (zoneInfo: ZoneSelectorInfo) => {
    if (zoneInfo.id) {
      history.replace(`/project/${pid}/zones/v2/${zoneInfo.id}`);
    }
  };

  // Update selected zone on zone details page load/refresh/route-param-changes
  useEffect(() => {
    if (!selectedZone.id) history.replace(`/project/${pid}/zones/v2/`);
    else setSelectedZone(selectedZone);
  }, [history, pid, selectedZone, setSelectedZone]);

  return (
    <div className={MAIN_CLASS}>
      <PublishChangesBanner />
      <ZoneHeader zones={zones ?? []} selectedZone={selectedZone} onZoneSelected={handleZoneSelected} />
      {/* Module Boxes */}
      {routeZoneId && (
        <div className={`${MAIN_CLASS}__modules`}>
          <div className={`${MAIN_CLASS}__modules__items`}>
            {selectedZoneModules.length ? (
              <>
                {selectedZoneModules.map((module) => {
                  return (
                    <ModuleBox
                      key={module.id}
                      module={module}
                      isLoading={isZoneConfigLoading || !isLanguageInitialized || isContentFetching}
                      languages={languages}
                      contentQueriesResults={contentQueriesResults}
                    />
                  );
                })}
                <FlightButton
                  theme="link"
                  onClick={() => {
                    // Set default layout as `vertical` to pre-select inside the `ModuleInfo` tab
                    setSelectedModule({ layout: MODULE_LAYOUTS.VERTICAL });
                    setShowModuleDetailsSlideout(true);
                  }}
                  label={'Create new module'}
                  iconLeft="add"
                  className={`${MAIN_CLASS}__module-add-button`}
                />
              </>
            ) : (
              <CreateFirstModule />
            )}
          </div>
        </div>
      )}
      {/* Label Filters Slideout */}
      {labelAndOrderedContentSelectedModuleId && (
        <LabelFilterProvider
          labelFilters={labelAndOrderedContentSelectedModule?.labelFilters ?? null}
          moduleName={labelAndOrderedContentSelectedModule?.name ?? null}
        >
          <LabelFilterSlideout
            onClickClose={() => {
              setLabelManagementOpen(false);
            }}
            onClickApply={(labelFilters: LabelFilter[]) => {
              const module = labelAndOrderedContentSelectedModule;
              module ? updateModule(routeZoneId, { ...module, labelFilters: labelFilters }) : null;
              setLabelManagementOpen(false);
              dispatch({
                type: 'SHOW_SNACKBAR',
                payload: { content: `Label filters have been changed on module  ${module?.name} `, type: 'success' },
              });
            }}
            onSlideOutComplete={() => !isLabelManagementOpen && setLabelAndOrderedContentSelectedModuleId(null)}
          />
        </LabelFilterProvider>
      )}
      {/* Module Details Slideout */}
      <ModuleDetailsSlideout />

      {/* Content Manager Slideout */}
      <ContentManagerSlideout />
      <PinnedContentScheduleSlideout contentQueriesResults={contentQueriesResults} languages={languages} />
    </div>
  );
};

export default Zone;
