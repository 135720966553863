import { useState, useEffect } from 'react';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

// Hook
export default function useExitPrompt(bool: any) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = () => {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    if (showExitPrompt) {
      initBeforeUnLoad(showExitPrompt);
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}
