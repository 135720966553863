import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { genUUID } from 'helpers/common.helper';
import { ZoneConfig } from 'pages/Zones/types';
import './NewZone.scss';

type TNewZoneProps = {
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
};

const MAIN_CLASS = 'new-zone';
const CLASSES = {
  ADD_ZONE_BUTTON: `${MAIN_CLASS}__add-zone-button`,
  FORM: `${MAIN_CLASS}__form`,
  BUSY_OVERLAY: `${MAIN_CLASS}__busy-overlay`,
};

const NewZone: React.FC<TNewZoneProps> = ({ zoneConfig, setZoneConfig }) => {
  const [isNewZoneInputVisible, setIsNewZoneInputVisible] = useState(false);
  const [newZoneName, setNewZoneName] = useState('');
  const newZoneNameRef = useRef<HTMLInputElement>(null);

  const toggleFormVisibility = () => {
    setIsNewZoneInputVisible((prevState) => !prevState);
  };

  const handleZoneNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setNewZoneName(value);
  };

  const handleApplyClick = () => {
    if (newZoneName.trim() === '') return;

    const newZoneConfig: ZoneConfig = {
      ...zoneConfig,
      zones: [
        ...zoneConfig.zones,
        {
          id: genUUID(),
          name: newZoneName,
          modules: [],
          labels: [],
        },
      ],
    };
    setZoneConfig(newZoneConfig);
    setIsNewZoneInputVisible(false);
  };

  const handleZoneNameKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      handleApplyClick();
    }
    if (evt.key === 'Escape') {
      toggleFormVisibility();
    }
  };

  useEffect(() => {
    if (isNewZoneInputVisible) newZoneNameRef.current?.focus();
  }, [isNewZoneInputVisible]);

  return (
    <div className={MAIN_CLASS}>
      {!isNewZoneInputVisible && (
        <button className={CLASSES.ADD_ZONE_BUTTON} onClick={toggleFormVisibility}>
          + Add Zone
        </button>
      )}
      {isNewZoneInputVisible && (
        <div className={CLASSES.FORM}>
          <input
            type="text"
            placeholder="Name this zone"
            onChange={handleZoneNameChange}
            onKeyUp={handleZoneNameKeyUp}
            ref={newZoneNameRef}
          />
          <button type="button" onClick={handleApplyClick}>
            Apply
          </button>
          <button type="button" onClick={toggleFormVisibility}>
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default NewZone;
