type ApprovalSettings = {
  approvalRequired: boolean;
};

export type ApprovalState = {
  settings: ApprovalSettings | null;
  isInitialized: boolean;
  isLoading: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
};

export enum ApprovalActionTypes {
  SET_APPROVAL_SETTINGS = 'SET_APPROVAL_SETTINGS',
  SET_APPROVAL_SETTINGS_LOADING = 'SET_APPROVAL_SETTINGS_LOADING',
  SET_APPROVAL_SETTINGS_ERROR = 'SET_APPROVAL_SETTINGS_ERROR',
}

export type SetApprovalSettingsAction = {
  type: ApprovalActionTypes.SET_APPROVAL_SETTINGS;
  payload: {
    settings: ApprovalState['settings'];
    isInitialized: boolean;
  };
};

export type SetApprovalSettingsLoadingAction = {
  type: ApprovalActionTypes.SET_APPROVAL_SETTINGS_LOADING;
  payload: {
    isLoading: boolean;
  };
};

export type SetApprovalSettingsErrorAction = {
  type: ApprovalActionTypes.SET_APPROVAL_SETTINGS_ERROR;
  payload: {
    error: ApprovalState['error'];
    settings: null;
    isInitialized: boolean;
  };
};
