import React from 'react';
import { TIconProps } from 'pages/ExperienceCanvas/assets/types';
import { PushPayloadType } from 'interface/push/push.interface';

export const BROADCASTABLE_TEMPLATES = ['broadcast', 'app-content'];

export enum TOUCHPOINT_STATUSES {
  COMPLETE = 'Complete',
  INCOMPLETE = 'Incomplete',
  ERROR = 'Error',
}

export enum TOUCHPOINT_ACTIONS {
  SCHEDULE = 'Schedule',
  PUSH = 'Push',
  PRIMARY_CONTENT = 'Primary Content',
  SECONDARY_CONTENT = 'Secondary Content',
}

export type TFormProps = {
  refId: string;
  activeLang: string;
  defaultLang: string;
  stepIdx: number;
};

export type PushPayloadMetadata = {
  id: string;
  key: PushPayloadType;
  title: string;
  icon: React.FC<TIconProps>;
};

export type PushUniversalAction = {
  name: string;
  minVersion: number;
  prefix: string;
  fields: PushUniversalField[];
  basicValidation: FieldValidationType;
};

type PushUniversalField = {
  key: string;
  name: string;
  isBooleanInput?: boolean;
};

export type FieldValidationType = 'text' | 'string' | 'url' | 'email' | 'phone' | 'number' | '';

export type PushFieldMetadata = {
  [key: string]: {
    path: string;
    isLocalized: boolean;
    basicValidation: FieldValidationType;
    displayName?: string;
  };
};

export type FormData = {
  [key: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    label?: string;
    // used as the true key
    path?: string;
    isDirty?: boolean;
    showError: boolean;
    error: string;
  };
};

// implemented this way to support custom or generic forms in the future
export type FormDataParam = {
  [key: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    path?: string;
    label?: string;
  };
};

export type TContentPreviewCardProps = {
  id?: string;
  title?: string;
  header?: string;
  description?: string;
  type?: string;
  actions?: string[];
  imageUrl?: string;
  buttons?: ContentButtonLink[];
  texts?: ContentTextLink[];
  isSupported?: boolean;
  isLoading?: boolean;
};

export type ContentButtonLink = {
  actionType: string;
  actionValue: string;
  label: string;
};

export type ContentTextLink = {
  actionType: string;
  actionValue: string;
  label: string;
};

// should merge this with ContentPreview in content.interface and TContentPreviewCardProps sometime
export type ContentPreviewProps = {
  id: string;
  imageUrl: string;
  header: string;
  title: string;
  description: string;
  templateType: string;
  buttons: string[];
  link: string;
  details: string;
  isSupported: boolean;
  refId?: string;
  renderType: string;
};

export type ContentListItem = ContentPreviewProps & {
  refId: string;
  prototypeId: string;
  stepIdx: number;
  name: string;
  metadataDescription: string;
  type: 'primary' | 'secondary';
  isRequired: boolean;
  templateIdConstraint: string;
  isReadOnly: boolean;
};

export enum CONTENT_OPTIONS {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export enum CONTENT_MANAGER_STEPS {
  CONTENT_OPTION_SELECTOR = 'CONTENT_OPTION_SELECTOR',
  CONTENT_SELECTOR = 'CONTENT_SELECTOR',
  TEMPLATE_SELECTOR = 'TEMPLATE_SELECTOR',
  CONTENT_EDITOR = 'CONTENT_EDITOR',
}

export interface TSlidingSidePanelProps extends React.ComponentProps<'div'> {
  show: boolean;
  headerInfo: {
    mainTitle: string;
    showCloseButton?: boolean;
    goBackTitle?: string;
    goBackSubTitle?: string;
    goBackIcon?: JSX.Element;
    goBackActionHandler?: () => void;
    statusComponent?: JSX.Element;
    headerAction?: {
      label: string;
      handler: () => void;
    };
  };
  footerInfo?: {
    primaryActionText?: string;
    primaryActionHandler?: () => void;
    primaryActionLoading?: boolean;
    primaryActionDisabled?: boolean;
    secondaryActionText?: string;
    secondaryActionHandler?: () => void;
    secondaryActionDisabled?: boolean;
    footerText?: string;
    showProvideFeedbackButton?: boolean;
  };
  onSlideOutComplete?: () => void;
  showFooter?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}
