import React from 'react';

import './STLoadingState.scss';
import { getIcon } from '@flybits/design-system';

const SIZES: Record<string, string> = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};
const COMPONENT_CLASS = 'performance-loading-state';

const STLoadingState = (props: { size?: string }) => (
  <div className={`${COMPONENT_CLASS}${props.size ? ` ${COMPONENT_CLASS}--${SIZES[props.size]}` : ''}`}>
    <div className={`${COMPONENT_CLASS}__title`}>Loading</div>
    <div className={`${COMPONENT_CLASS}__icon`}>{getIcon('loading', {})}</div>
  </div>
);

export default STLoadingState;
