import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlightDateRangePicker } from '@flybits/design-system';
import { ReactComponent as AndroidGray } from 'assets/icons/android_gray.svg';
import { ReactComponent as IosGray } from 'assets/icons/ios_gray.svg';

import { ReactComponent as IosBlue } from 'assets/icons/ios_blue.svg';
import { ReactComponent as AndroidGreen } from 'assets/icons/android_green.svg';
import { ReactComponent as WebBlue } from 'assets/icons/web_blue.svg';

import { AnalyticsCardsGroup } from 'components/Analytics/AnalyticsOverviewCardsGroup/AnalyticsCardsGroup';
import { AnalyticsGraphs } from 'components/Analytics/AnalyticsOverviewGraphs/AnalyticsGraphs';
import { GraphData, AnalyticsOverview as TAnalyticsOverview } from 'components/Analytics/types';
import LoadingState from 'components/Performance/LoadingState/LoadingState';
import { ErrorPage } from 'components/Shared/ErrorPage/ErrorPage';
import useFeatureFlag from 'hooks/useFeatureFlag';
import AnalyticsExperiencesAPI from 'services/api/analytics-experiences.api';
import Engagement from './Engagement/Engagement';
import './AnalyticsOverview.scss';
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import { ModuleConfig } from 'interface/templated-experience/analytics.interface';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import { FlightTooltip } from '@flybits/design-system';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import PieGraphDevice from 'components/Shared/Graphs/PieGraphDevice';
import { Info } from '@material-ui/icons';
import { AdvancedDateRange } from './types';
import useDelayedLoadingState from 'hooks/useDelayedLoading';
import { intlNumberFormat } from 'helpers/common.helper';

const CLASS_ANALYTICS_OVERVIEW_DEFAULT = 'analytics-overview';
const ANALYTICS_OVERVIEW = {
  BOTTOM_SIDE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__bottom-side`,
  TOP_SIDE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side`,
  TOP_SIDE_HEADER: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header`,
  TOP_SIDE_HEADER_DATE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header__date`,
  TOP_SIDE_HEADER_DATE_TITLE: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header__date__title`,
  TOP_SIDE_HEADER_DATE_DROPDOWN: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__top-side__header__date__dropdown`,
  SPINNER: `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__spinner`,
};

const CLASS_ANALYTICS_OVERVIEW_TOTAL = `${CLASS_ANALYTICS_OVERVIEW_DEFAULT}__total`;
const ANALYTICS_OVERVIEW_GENERAL = {
  TOTAL_CARD: `${CLASS_ANALYTICS_OVERVIEW_TOTAL}__card`,
  TOTAL_VALUE: `${CLASS_ANALYTICS_OVERVIEW_TOTAL}__value`,
  TOTAL_TEXT: `${CLASS_ANALYTICS_OVERVIEW_TOTAL}__text`,
  TOTAL_USERS: `${CLASS_ANALYTICS_OVERVIEW_TOTAL}__total-users`,
};

const CLASS_ANALYTICS_OVERVIEW_TOTAL_DEVICES = `${ANALYTICS_OVERVIEW_GENERAL.TOTAL_CARD}__total-devices`;
const ANALYTICS_OVERVIEW_LOCATION_TOTAL_DEVICES_CLASSES = {
  TOTAL_GRAPHS: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_DEVICES}__total-graphs`,
};

const CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD = `${CLASS_ANALYTICS_OVERVIEW_TOTAL}--location-card`;
const ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES = {
  LOCATION_OPTIN: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin`,
  HEADER: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__header`,
  TEXT: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__header__text`,
  INFO_ICON: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__header__info-icon`,
  DEVICE_LABELS: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels`,
  DEVICE: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device`,
  DEVICE_TYPE: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type`,
  DEVICE_TYPE_LABELS: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type__labels`,
  DEVICE_TYPE_LABELS_TEXT: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type__labels__label__text`,
  DEVICE_TYPE_LABELS_ICON: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type__labels__label__icon`,
  DEVICE_TYPE_LABELS_INFO_ICON: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type__labels__label__info-icon`,
  DEVICE_VALUES: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type__values`,
  DEVICE_VALUES_VALUE: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type__values__value`,
  DEVICE_VALUES_PERCENT: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}__location-optin__device-labels__device__type__values__percent`,
};

const CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS = `${ANALYTICS_OVERVIEW_GENERAL.TOTAL_CARD}__total-push-optins`;
const ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS = {
  TOTAL_GRAPHS: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__total-graphs`,
  LABELS: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels`,
  LABELS_DEVICE: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device`,
  LABELS_DEVICE_WRAPPER: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device__wrapper`,
  LABELS_DEVICES_TYPE: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device__type`,
  LABELS_DEVICES_TYPE_VALUE: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device__type__value`,
  LABELS_DEVICES_TYPE_LABELS: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device__type__labels`,
  LABELS_DEVICES_TYPE_LABEL: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device__type__labels__label`,
  LABELS_DEVICES_TYPE_ICON: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device__type__labels__label__icon`,
  LABELS_DEVICES_TYPE_TEXT: `${CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}__push-optins-labels__device__type__labels__label__text`,
};

const defaultGraphData = [
  {
    id: 'ios',
    label: '50.0%',
    value: 50,
    color: '#165Dff',
  },
  {
    id: 'android',
    label: '50.0%',
    value: 50,
    color: '#D919D9',
  },
];

export default function AnalyticsOverview() {
  const analyticsExperiencesApi = useMemo(() => new AnalyticsExperiencesAPI(), []);
  const templatedExperienceAnalyticsApi = useMemo(() => new TemplatedExperienceAnalyticsAPI(), []);

  const { flags } = useFeatureFlag();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState<AdvancedDateRange>(() => {
    const startOfToday = new Date();
    const endOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    endOfToday.setHours(23, 59, 59, 999);
    return [startOfToday, endOfToday, 0, 'day'];
  });

  const handleDateRangeChange = async (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
  };

  const [totalDevices, setTotalDevices] = useState<GraphData[]>([]);
  const [totalPushOptin, setTotalPushOptIn] = useState<GraphData[]>([]);

  const [customModuleGraphsToRender, setCustomModuleGraphsToRender] = useState<ModuleConfig[]>();
  const {
    data: customModuleGraphs,
    isLoading: areCustomModuleGraphsLoading,
    refetch,
  } = useQuery({
    queryKey: ['customModules'],
    queryFn: async () => {
      const { data } = await templatedExperienceAnalyticsApi.getModuleConfigs({});
      return data;
    },
    retry: false,
  });
  const {
    isLoading: isAOLoading,
    isFetching: isAOFetching,
    isError: isOverviewExperiencesError,
  } = useQuery({
    queryKey: ['analytics-overview', dateRange],
    queryFn: async () => {
      const endOfDay = new Date(dateRange[1] ?? new Date());
      if (dateRange.length < 3 || (dateRange.length >= 3 && dateRange[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59, 999);
      }

      const fetchedOverview = await analyticsExperiencesApi.getOverviewExperiences({
        startTime: Math.trunc((dateRange[0]?.getTime() ?? new Date().setHours(0, 0, 0, 0)) / 1000),
        endTime: Math.trunc((endOfDay?.getTime() ?? new Date().setHours(23, 59, 59, 999)) / 1000),
      });

      setOverviewExperiencesToRender(fetchedOverview);
      return fetchedOverview;
    },
    staleTime: 0,
    enabled: dateRange[0] !== null && dateRange[1] !== null,
    retry: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const [overviewExperiencesToRender, setOverviewExperiencesToRender] = useState<TAnalyticsOverview>();
  const isOverviewFetching = useDelayedLoadingState(isAOFetching);
  const isOverviewLoading = useDelayedLoadingState((isAOLoading || isAOFetching) && !overviewExperiencesToRender);
  const locationOptIn = overviewExperiencesToRender?.locationOptIn;
  const locationOptInTotal = locationOptIn
    ? locationOptIn.web + locationOptIn.other + locationOptIn.android + locationOptIn.ios
    : 0;
  const locationOptInValues = [
    {
      icon: <IosBlue />,
      label: 'iOS',
      value: locationOptIn ? locationOptIn.ios : 0,
      percent: locationOptInTotal ? ((locationOptIn.ios / locationOptInTotal) * 100).toFixed(2) : 0,
    },
    {
      icon: <AndroidGreen />,
      label: 'Android',
      value: locationOptIn ? locationOptIn.android : 0,
      percent: locationOptInTotal ? ((locationOptIn.android / locationOptInTotal) * 100).toFixed(2) : 0,
    },
    {
      icon: <WebBlue />,
      label: 'Other',
      value: locationOptIn ? locationOptIn.web + locationOptIn.other : 0,
      percent: locationOptInTotal
        ? (((locationOptIn.web + locationOptIn.other) / locationOptInTotal) * 100).toFixed(2)
        : 0,
      tooltip: 'Other can be any platform that is not IOS or Android (for example, web, HarmonyOS).',
    },
  ];
  const removeCustomModule = useCallback(
    async (id: string) => {
      try {
        await templatedExperienceAnalyticsApi.removeModuleConfig(id);
        dispatch(
          showSnackbar({
            content: 'Module removed successfuly!',
            type: 'success',
          }),
        );
        refetch();
      } catch (error) {
        dispatch(
          showSnackbar({
            content: 'An error occurred while trying to remove the module',
            type: 'error',
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => {
    document.title = 'Analytics | Experience Studio @ Flybits';
  }, []);
  useEffect(() => {
    if (window.location.hash === '#updated') {
      refetch();
      window.location.hash = '';
    }
    // eslint-disable-next-line
  }, [window.location.hash]);

  // Create total device graphdata
  useEffect(() => {
    const graphData: [GraphData] = Object.entries(overviewExperiencesToRender?.totalDevices?.platformSplit ?? {})
      .map(([name, decimal]) => {
        switch (name) {
          case 'ios':
            return {
              id: 'ios',
              label: (decimal * 100).toFixed(1).concat('%'),
              value: (decimal * 100).toFixed(1),
              color: '#165Dff',
            };
          case 'android':
            return {
              id: 'android',
              label: (decimal * 100).toFixed(1).concat('%'),
              value: (decimal * 100).toFixed(1),
              color: '#D919D9',
            };
          case 'other':
            return { id: 'other', label: (decimal * 100).toFixed(1).concat('%'), value: (decimal * 100).toFixed(2) };
          default:
            return {};
        }
      })
      .filter((e) => Object.entries(e).length > 0);

    setTotalDevices(graphData.length > 0 ? graphData : defaultGraphData);
  }, [overviewExperiencesToRender]);

  // Create push optin
  useEffect(() => {
    const graphData: [GraphData] = Object.entries(overviewExperiencesToRender?.pushOptIn?.platformSplit ?? {})
      .map(([name, decimal]) => {
        if (!decimal) return {};
        switch (name) {
          case 'ios':
            return {
              id: 'ios',
              label: (decimal * 100).toFixed(1).concat('%'),
              value: (decimal * 100).toFixed(2),
              color: '#165Dff',
            };
          case 'android':
            return {
              id: 'android',
              label: (decimal * 100).toFixed(1).concat('%'),
              value: (decimal * 100).toFixed(2),
              color: '#D919D9',
            };
          default:
            return {};
        }
      })
      .filter((e) => Object.entries(e).length > 0);

    setTotalPushOptIn(graphData.length > 0 ? graphData : defaultGraphData);
  }, [overviewExperiencesToRender]);

  // why do we need this effect
  useEffect(() => {
    if (!customModuleGraphs) return;
    if (!isEqual(customModuleGraphsToRender, customModuleGraphs)) {
      setCustomModuleGraphsToRender(customModuleGraphs);
    }
    // eslint-disable-next-line
  }, [customModuleGraphs]);

  const showBottomPanel = flags['tx_ca_custom_module'];

  return (
    <>
      {!isOverviewExperiencesError ? (
        <div className={CLASS_ANALYTICS_OVERVIEW_DEFAULT}>
          <div className={CLASS_ANALYTICS_OVERVIEW_TOTAL}>
            <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_CARD}>
              <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_USERS}>
                <div>
                  Total users
                  <FlightTooltip
                    description="Total number of users that have opted-in or registered with Flybits."
                    direction="right"
                  >
                    <Info />
                  </FlightTooltip>
                </div>
                <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_VALUE}>
                  {isOverviewLoading ? (
                    <Skeleton width={40} height={30} />
                  ) : (
                    intlNumberFormat(overviewExperiencesToRender?.totalUsers?.value || 0)
                  )}
                </div>
              </div>
            </div>
            <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_CARD}>
              <div className={CLASS_ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS}>
                <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICE}>
                  <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_TEXT}>
                    User push opt ins based on platform{' '}
                    <FlightTooltip
                      description="Percent of users that have opted in to push notifications from each platform."
                      direction="right"
                    >
                      <Info />
                    </FlightTooltip>
                  </div>
                  <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICE_WRAPPER}>
                    <div
                      className={`${ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE} ${
                        isOverviewLoading ? `${ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE}--loading` : ''
                      }`}
                    >
                      {isOverviewLoading ? (
                        <Skeleton width={40} height={30} />
                      ) : (
                        <>
                          <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_VALUE}>
                            {intlNumberFormat(overviewExperiencesToRender?.pushOptIn?.ios ?? 0)}
                          </div>
                          <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_LABELS}>
                            <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_ICON}>
                              <IosGray />
                            </div>
                            <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_LABEL}>iOS</div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE}>
                      {isOverviewLoading ? (
                        <Skeleton width={40} height={30} />
                      ) : (
                        <>
                          <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_VALUE}>
                            {intlNumberFormat(overviewExperiencesToRender?.pushOptIn?.android ?? 0)}
                          </div>
                          <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_LABELS}>
                            <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_ICON}>
                              <AndroidGray />
                            </div>
                            <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.LABELS_DEVICES_TYPE_LABEL}>
                              Android
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className={ANALYTICS_OVERVIEW_TOTAL_PUSH_OPTINS.TOTAL_GRAPHS}>
                  {isOverviewLoading ? <Skeleton height={50} /> : <PieGraphDevice data={totalPushOptin} />}
                </div>
              </div>
            </div>
          </div>

          <div className={CLASS_ANALYTICS_OVERVIEW_TOTAL}>
            <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_CARD}>
              <div>
                <div className={CLASS_ANALYTICS_OVERVIEW_TOTAL_DEVICES}>
                  <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_TEXT}>
                    Total devices{' '}
                    <FlightTooltip
                      description="Total number of devices that have opted-in or registered with Flybits."
                      direction="right"
                    >
                      <Info />
                    </FlightTooltip>
                  </div>
                  <div className={ANALYTICS_OVERVIEW_GENERAL.TOTAL_VALUE}>
                    {isOverviewLoading ? (
                      <Skeleton width={40} height={30} />
                    ) : (
                      intlNumberFormat(overviewExperiencesToRender?.totalDevices?.value ?? 0)
                    )}
                  </div>
                </div>
                <div className={ANALYTICS_OVERVIEW_LOCATION_TOTAL_DEVICES_CLASSES.TOTAL_GRAPHS}>
                  {isOverviewLoading ? <Skeleton width={220} height={100} /> : <PieGraphDevice data={totalDevices} />}
                </div>
              </div>
            </div>

            <div className={`${ANALYTICS_OVERVIEW_GENERAL.TOTAL_CARD} ${CLASS_ANALYTICS_OVERVIEW_TOTAL_LOCATION_CARD}`}>
              <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.LOCATION_OPTIN}>
                <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.HEADER}>
                  <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.TEXT}>
                    Location opt ins based on platform
                  </div>
                  <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.INFO_ICON}>
                    <FlightTooltip
                      description="The number of users who choose to share their location data with the application."
                      direction="right"
                    >
                      <Info />
                    </FlightTooltip>
                  </div>
                </div>
                <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE}>
                  {isOverviewLoading ? (
                    <Skeleton count={3} height={35} />
                  ) : (
                    locationOptInValues.map((rowData, i) => {
                      const showDivider = i !== locationOptInValues.length - 1;
                      return (
                        <React.Fragment key={`location-opt-in-analytics-row-${i}`}>
                          <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_TYPE}>
                            <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_TYPE_LABELS}>
                              <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_TYPE_LABELS_ICON}>
                                {rowData.icon}
                              </div>
                              <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_TYPE_LABELS_TEXT}>
                                {rowData.label}
                              </div>
                              {rowData.tooltip && (
                                <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_TYPE_LABELS_INFO_ICON}>
                                  <FlightTooltip description={rowData.tooltip} direction="right">
                                    <Info />
                                  </FlightTooltip>
                                </div>
                              )}
                            </div>
                            <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_VALUES}>
                              <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_VALUES_VALUE}>
                                {intlNumberFormat(rowData.value)}
                              </div>
                              <div className={ANALYTICS_OVERVIEW_LOCATION_OPTIN_CLASSES.DEVICE_VALUES_PERCENT}>
                                <span>({rowData.percent}%)</span>
                              </div>
                            </div>
                          </div>
                          {showDivider && <hr />}
                        </React.Fragment>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={ANALYTICS_OVERVIEW.TOP_SIDE}>
            <div className={ANALYTICS_OVERVIEW.TOP_SIDE_HEADER}>
              <h2>Overview (all experiences)</h2>
              {isOverviewFetching ? (
                <div className={ANALYTICS_OVERVIEW.SPINNER}>
                  <LoadingIcon />
                </div>
              ) : (
                <div className={ANALYTICS_OVERVIEW.TOP_SIDE_HEADER_DATE}>
                  <div className={ANALYTICS_OVERVIEW.TOP_SIDE_HEADER_DATE_TITLE}>
                    {dateRange[2] === 0
                      ? `${moment().format('MMM DD, YYYY')}`
                      : `${moment(dateRange[0]).format('MMM DD, YYYY')} - ${moment(dateRange[1]).format(
                          'MMM DD, YYYY',
                        )}`}
                  </div>
                  <FlightDateRangePicker
                    className={ANALYTICS_OVERVIEW.TOP_SIDE_HEADER_DATE_DROPDOWN}
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    maxDate={new Date()}
                    includeAllTime={false}
                    includeToday
                    isIncludeCustom
                    replaceCustomRangeLabel={false}
                  />
                </div>
              )}
            </div>
            <AnalyticsCardsGroup overviewExperiences={overviewExperiencesToRender} isLoading={isOverviewFetching} />
          </div>
          <Engagement />
          {showBottomPanel && (
            <div className={ANALYTICS_OVERVIEW.BOTTOM_SIDE}>
              {areCustomModuleGraphsLoading ? (
                <LoadingState />
              ) : (
                <AnalyticsGraphs
                  setCustomModuleGraphs={setCustomModuleGraphsToRender}
                  customModuleGraphs={customModuleGraphsToRender}
                  handleRemoveCustomModule={removeCustomModule}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <ErrorPage />
      )}
    </>
  );
}
