import React, { useState, useRef } from 'react';
import { Attachment } from 'interface/approval/approval.interface';
import GalleryModal from 'components/Modal/GalleryModal/GalleryModal';
import { EVENT_KEYS } from 'types/events';

import './AttachmentManager.scss';

type TAttachmentManagerProps = {
  setAttachments: React.Dispatch<React.SetStateAction<Attachment[]>>;
  addAttachments: React.Dispatch<React.SetStateAction<FileList | null>>;
  attachments: Attachment[];
  uploads: FileList | null;
  className?: string;
  isAuthor?: boolean;
};

type TGalleryProps = {
  isVisible: boolean;
  attachment: Attachment | null;
};

const MAIN_CLASS = 'attachment-manager';

const CLASSES = {
  TILE: `${MAIN_CLASS}__tile`,
  ADD: `${MAIN_CLASS}__add`,
};

const ImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

// TODO: handle pdfs and other files
const AttachmentManager: React.FC<TAttachmentManagerProps> = ({
  setAttachments,
  addAttachments,
  attachments,
  uploads,
  className,
  isAuthor = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [galleryProps, setGalleryProps] = useState<TGalleryProps>({
    isVisible: false,
    attachment: null,
  });

  const uploadedAttachments = (() => {
    const uploadedAttachments: Attachment[] = [];
    if (!uploads || !uploads.length) return uploadedAttachments;

    for (const file of uploads) {
      if (ImageTypes.includes(file.type))
        uploadedAttachments.push({
          fileId: '',
          url: URL.createObjectURL(file),
          name: '',
          friendlyName: file.name,
          createdAt: 0,
        });
    }

    return uploadedAttachments;
  })();

  // TODO: condensed view
  const renderPreviews = () => {
    const attachmentRenderList = attachments;
    const toRender = [...attachmentRenderList, ...uploadedAttachments];
    if (!toRender.length) return null;
    return toRender.map((att) => (
      <div
        key={`am-${att.url}`}
        className={CLASSES.TILE}
        aria-label="Open Details View"
        tabIndex={0}
        role="button"
        onClick={() => setGalleryProps({ isVisible: true, attachment: att })}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === EVENT_KEYS.ENTER) {
            setGalleryProps({ isVisible: true, attachment: att });
          }
        }}
      >
        <img src={att.url} alt="Attachment Preview" />
      </div>
    ));
  };

  const handleRemove = (id?: string) => {
    if (!id) return;
    setAttachments((prev) => prev.filter((att) => att.fileId !== id) || null);
    setGalleryProps({ isVisible: false, attachment: null });
  };

  return (
    <div className={`${MAIN_CLASS} ${className ?? ''}`}>
      {renderPreviews()}
      {isAuthor && (
        <div
          className={`${CLASSES.TILE} ${CLASSES.ADD}`}
          aria-label="Add attachment"
          tabIndex={0}
          role="button"
          onClick={() => fileInputRef.current?.click()}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === EVENT_KEYS.ENTER) {
              fileInputRef.current?.click();
            }
          }}
        >
          + Add More
          <input
            type="file"
            accept=".jpeg,.jpg,.png"
            multiple
            ref={fileInputRef}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => addAttachments(evt.target.files)}
          />
        </div>
      )}
      <GalleryModal
        isAuthor={isAuthor}
        attachment={galleryProps.attachment}
        isVisible={galleryProps.isVisible}
        onCancel={() => setGalleryProps({ isVisible: false, attachment: null })}
        onRemove={handleRemove}
      />
    </div>
  );
};

export default AttachmentManager;
