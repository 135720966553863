import React, { useEffect, useMemo, useState, useRef } from 'react';
import { FlightButton, getIcon } from '@flybits/design-system';

import { SMART_TARGET_STATUS, SmartTargetStatusType } from 'interface/smart-targeting/smart-targeting.interface';
import { EVENT_KEYS } from 'types/events';

// Custom imports
import { JourneyState } from 'store/journey/journey.type';
import SmartTargetingIcon from 'pages/ExperienceCanvas/assets/icons/SmartTargetingIcon';
import { ReactComponent as NetworkImage } from 'assets/images/network-map.svg';
import { ReactComponent as BrainImage } from 'assets/images/brain.svg';
import { ReactComponent as TargetImage } from 'assets/images/targets.svg';
import StatusPill from '../StatusPill/StatusPill';

// Stylings
import './SmartTargetingData.scss';

const STATUS_CARD_CLASS = 'status-info-card';

type TSmartTargetingPanelTypeProps = {
  toggleIsSmartTargetingPanelOpen: () => void;
  journeyInstance: JourneyState;
  status: SmartTargetStatusType;
};

const defaultMsgBox: {
  [key in SmartTargetStatusType]: string;
} = {
  [SMART_TARGET_STATUS.INACTIVE]: 'Inactive',
  [SMART_TARGET_STATUS.IN_TRAINING]: 'In training',
  [SMART_TARGET_STATUS.READY_ACTIVATE]: 'Ready to activate',
  [SMART_TARGET_STATUS.ACTIVE]: 'Active',
  ['']: '',
};

type StatusContent = {
  header: string;
  description: JSX.Element;
  image: JSX.Element;
};

const STATUS_CONTENT_MAP: Partial<Record<SmartTargetStatusType, StatusContent>> = {
  inactive: {
    header: 'Optimize your audience',
    description: (
      <p className={`${STATUS_CARD_CLASS}__body__description`}>
        Smart Targeting amplifies your target impact with AI and human-in-control tools. Simply launch your experience,
        and we&apos;ll let you know when Smart Targeting is ready to optimize your audience reach and engagement.
      </p>
    ),
    image: <NetworkImage className="image" />,
  },
  'in-training': {
    header: 'Training in progress...',
    description: (
      <p className={`${STATUS_CARD_CLASS}__body__description`}>
        Our model is in <strong>training mode</strong> to learn how your experience performs in-market. You&apos;ll be
        notified when Smart Targeting is ready to optimize your experience.
      </p>
    ),
    image: <BrainImage className="image" />,
  },
  'ready-activate': {
    header: 'Ready to activate',
    description: (
      <p className={`${STATUS_CARD_CLASS}__body__description`}>
        Our model has completed training and is ready to provide recommendations to optimize your experience.
      </p>
    ),
    image: <TargetImage className="image" />,
  },
};

export const SmartTargetingData: React.FC<TSmartTargetingPanelTypeProps> = ({
  toggleIsSmartTargetingPanelOpen,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  journeyInstance,
  status,
}) => {
  const handleSmartTargetBtnClick = () => {
    if (status === SMART_TARGET_STATUS.ACTIVE) {
      toggleIsSmartTargetingPanelOpen();
    } else {
      setIsShowCard(!isShowCard);
    }
  };

  const handleActivate = () => {
    toggleIsSmartTargetingPanelOpen();
    setIsShowCard(false);
  };

  const renderStatusCard = () => {
    return (
      <div ref={smartTargetingPanelRef} className={STATUS_CARD_CLASS}>
        <div className={`${STATUS_CARD_CLASS}__header`}>
          <div className={`${STATUS_CARD_CLASS}__header__img`}>{STATUS_CONTENT_MAP[status]?.image}</div>
          <FlightButton
            theme="minor"
            onClick={() => setIsShowCard(false)}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === EVENT_KEYS.ENTER) {
                setIsShowCard(false);
              }
            }}
            iconLeft="clear"
            className={`${STATUS_CARD_CLASS}__header__close`}
            ariaLabel="Close"
          />
        </div>
        <div
          className={`${STATUS_CARD_CLASS}__divider ${status === SMART_TARGET_STATUS.IN_TRAINING ? 'training' : ''}`}
        ></div>
        <div className={`${STATUS_CARD_CLASS}__body`}>
          <p className={`${STATUS_CARD_CLASS}__body__subheader`}>Smart targeting</p>
          <p className={`${STATUS_CARD_CLASS}__body__header`}>{STATUS_CONTENT_MAP[status]?.header}</p>
          {STATUS_CONTENT_MAP[status]?.description}
          {status === SMART_TARGET_STATUS.INACTIVE && (
            <div className={`${STATUS_CARD_CLASS}__body__note ${STATUS_CARD_CLASS}__body__description`}>
              <p>Note: Smart Targeting will automatically begin training when there is enough data</p>
            </div>
          )}
        </div>
        <div className={`${STATUS_CARD_CLASS}__footer`}>
          {status === SMART_TARGET_STATUS.READY_ACTIVATE && (
            <FlightButton
              className={`${STATUS_CARD_CLASS}__footer__activate`}
              theme="primary"
              onClick={handleActivate}
              label="Turn on Smart Targeting"
            />
          )}
          {/* <FlightButton
          className={`${STATUS_CARD_CLASS}__footer__learn-more`}
          theme="secondary"
            onClick={() => {}}
            label='Learn more'
          /> */}
        </div>
      </div>
    );
  };

  const defaultIcon = getIcon('error', { className: `status__icon ${status}` });
  const iconMap = useMemo(() => {
    return {
      inactive: null,
      'in-training': getIcon('loading', { className: `status__icon ${status}` }),
      'ready-activate': getIcon('checkCircle', { className: `status__icon ${status}` }),
      active: getIcon('checkCircle', { className: `status__icon ${status}` }),
      '': null,
    };
  }, [status]);

  const statusMsg = defaultMsgBox[status] || 'Invalid status';
  const isShowSlideOut = ['active'].includes(status);
  const titleBaseClass = 'secondary__header--box__smart-targeting-data__title';
  const titleClass = `${titleBaseClass} ${status === SMART_TARGET_STATUS.INACTIVE ? 'inactive' : ''}`;
  const isHideHeadIcon = ['inactive'].includes(status);
  const headIcon = isHideHeadIcon ? null : iconMap[status] || defaultIcon;
  const [isShowCard, setIsShowCard] = useState(false);

  const smartTargetingPanelRef = useRef(null);

  useEffect(() => {
    const checkMouseClickOutside = (e: React.MouseEvent<HTMLElement>) => {
      if (!smartTargetingPanelRef.current?.contains(e.target)) {
        setIsShowCard(false);
      }
    };

    const escapeKeyListener = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === EVENT_KEYS.ESCAPE) {
        setIsShowCard(false);
      }
    };

    window.addEventListener('click', checkMouseClickOutside, true);
    window.addEventListener('keydown', escapeKeyListener, true);

    return () => {
      window.removeEventListener('click', checkMouseClickOutside);
      window.removeEventListener('keydown', escapeKeyListener);
    };
  }, [smartTargetingPanelRef]);

  return (
    <div className="smart-targeting-data-container">
      <button className="secondary__header--box__smart-targeting-data" onClick={handleSmartTargetBtnClick}>
        <div className={titleClass}>
          <SmartTargetingIcon className="smart-icon" />
          Smart targeting
        </div>

        <StatusPill
          style={`secondary__header--box__smart-targeting-data__pill ${status}`}
          headIcon={headIcon}
          statusMsg={statusMsg}
          isShowSlideOut={isShowSlideOut}
          ariaLabel={`Current status is ${status}`}
        />
      </button>
      {isShowCard && renderStatusCard()}
    </div>
  );
};

export default SmartTargetingData;
