import { Zone, Module } from 'pages/Zones/types';

export const ZM_DETAILS = 'zone-module-details';

export const ZM_DETAILS_CLASSES = {
  FORM: `${ZM_DETAILS}__form`,
  SIDEBAR: `${ZM_DETAILS}__sidebar`,
  DIAGRAM_CONTAINER: `${ZM_DETAILS}__diagram__container`,
  HEADER: `${ZM_DETAILS}__header`,
  CONTAINER: `${ZM_DETAILS}__container`,
};

export const ZoneDefaults: Zone = {
  id: '',
  name: '',
  labels: [],
  modules: [],
};

export const ModuleDefaults: Module = {
  id: '',
  name: '',
  layout: '',
  layoutHTML: '',
  styleCSS: '',
  styleCSSURL: '',
  viewAll: '',
  query: '',
  options: [],
  height: 0,
  labelFilters: [],
  pinnedContentIDsByTimeRange: [],
};

export const DEFAULT_LABEL_LIMIT = 10;
