// Push Editor Advanced Form
import React from 'react';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { getIcon } from '@flybits/design-system';
import { EVENT_KEYS } from 'types/events';

import AdvancedForm from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/PushEditorBox/AdvancedForm';
import ReminderForm from 'components/ExperienceCanvas/TouchpointEditor/TouchpointTimelineBox/PushEditorBox/ReminderForm';

type TAdvancedPushProps = {
  classPrefix: string;
  onToggle: () => void;
  stepIdx: number;
  isExpanded: boolean;
};

export default function PushEditorAdvancedForm({
  classPrefix = '',
  onToggle,
  stepIdx,
  isExpanded = false,
}: TAdvancedPushProps) {
  const templateType = useSelector((state) => state.te.journey.templateType);
  const ruleRepeatSchedule = useSelector((state) => {
    const frequency = state.te.rule.startSchedulerMap[0]?.frequency;
    return frequency && frequency > 0;
  });
  const pushActionType = useSelector((state) => {
    const pushRefId = state.te.journey.steps[stepIdx]?.push;
    return pushRefId && state.te.push.byRefId[pushRefId]?.actionType;
  });
  const templateName = useSelector((state) => state.te.journey.templateName);
  let shouldRender =
    pushActionType !== 'broadcast-push' &&
    !ruleRepeatSchedule &&
    templateType !== 'broadcast' &&
    templateName !== 'Broadcast Push Notification';
  if (templateType === 'reminder' && stepIdx > 0) shouldRender = false;
  const formTitle = templateType === 'reminder' ? 'Reminder Settings' : 'Advanced Settings';
  const RenderForm = () => {
    return templateType === 'reminder' ? (
      <ReminderForm classPrefix={classPrefix} stepIdx={stepIdx} />
    ) : (
      <AdvancedForm classPrefix={classPrefix} stepIdx={stepIdx} />
    );
  };

  return (
    <>
      {shouldRender && (
        <div className={`${classPrefix}__advanced`}>
          <div
            className={`${classPrefix}__advanced-header`}
            onClick={onToggle}
            onKeyDown={(e) => {
              if (e.key === EVENT_KEYS.ENTER) {
                onToggle();
              }
            }}
            aria-label={'toggle advanced push settings'}
            role="button"
            tabIndex={0}
          >
            {formTitle}
            {getIcon(isExpanded ? 'baselineKeyboardArrowUp' : 'baselineKeyboardArrowDown', {})}
          </div>
          {isExpanded ? RenderForm() : null}
        </div>
      )}
    </>
  );
}
