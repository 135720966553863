import React from 'react';
import { TIconProps } from '../types';

function ScheduleIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2495_153782)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3627 2.81818H18.2718C19.2718 2.81818 20.09 3.63636 20.09 4.63636V12.374C19.5199 12.1622 18.9088 12.0344 18.2718 12.006V9.18182H3.72638V19.1818H12.1164C12.2462 19.8319 12.4812 20.4442 12.8027 21H3.72638C2.72638 21 1.9082 20.1818 1.9082 19.1818V4.63636C1.9082 3.63636 2.72638 2.81818 3.72638 2.81818H4.63548V1H6.45366V2.81818H15.5446V1H17.3627V2.81818ZM3.72638 7.36364H18.2718V4.63636H3.72638V7.36364Z"
          fill={fill}
        />
        <path
          d="M17.995 13C15.235 13 13 15.24 13 18C13 20.76 15.235 23 17.995 23C20.76 23 23 20.76 23 18C23 15.24 20.76 13 17.995 13ZM18 22C15.79 22 14 20.21 14 18C14 15.79 15.79 14 18 14C20.21 14 22 15.79 22 18C22 20.21 20.21 22 18 22ZM18.25 15.5H17.5V18.5L20.125 20.075L20.5 19.46L18.25 18.125V15.5Z"
          fill={fill}
          stroke={fill}
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2495_153782">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ScheduleIcon;
