import React, { useMemo } from 'react';
import SlidingSidePanel from '../SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import TouchpointCardCarousel from 'components/ExperienceCanvas/TouchpointCardCarousel/TouchpointCardCarousel';

import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';

import ApprovalAPI from 'services/api/approval.api';

import './ApprovalRequestSlideOut.scss';

type TApprovalRequestSlideOutProps = {
  isOpen: boolean;
  onClickClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: (showLoading?: any) => Promise<void>;
};

const ApprovalRequestSlideOut: React.FC<TApprovalRequestSlideOutProps> = ({ isOpen, onClickClose, refetch }) => {
  const approvalAPI = useMemo(() => new ApprovalAPI(), []);

  const dispatch = useDispatch();

  const journeyId = useSelector((state) => state.te.journey.id);

  const submitApprovalStatus = async () => {
    if (!journeyId) return;

    try {
      const { teams, reviewers } = await approvalAPI.getDefaultApprovalSettings();

      const approvalPayload = {
        entityId: journeyId,
        attachments: [],
        notes: '',
        dueDate: 0,
        readyForReview: true,
        approvalRequirements: {
          teams: teams ?? undefined,
          reviewers: reviewers ?? undefined,
        },
      };

      await approvalAPI.postApprovalRequest(approvalPayload);
      refetch(false);

      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: `Successfully Submited Approval Request!`, type: 'success' },
      });

      onClickClose();
    } catch (e) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: `Something went wrong D:`, type: 'error' },
      });
    }
  };

  const slidingSidePanelProps: TSlidingSidePanelProps = {
    headerInfo: {
      mainTitle: 'Experience Overview',
      showCloseButton: true,
      goBackActionHandler: onClickClose,
    },
    footerInfo: {
      primaryActionText: 'Send for Approval',
      primaryActionHandler: submitApprovalStatus,
      secondaryActionText: 'Cancel',
      secondaryActionHandler: onClickClose,
    },
    showFooter: true,
    show: isOpen,
    size: 'small',
  };

  /*
    TODO:
    - Grab default reviewer settings (API Call)
    - ?Check if elligible to submit approval request
    - ?Pull list of users (sorted by teams)
    - ?Check if user has saved the journey in a launchable state
    - Submit approval request (trigger reload for now)
  */

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <TouchpointCardCarousel />
    </SlidingSidePanel>
  );
};

export default ApprovalRequestSlideOut;
