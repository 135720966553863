import { JourneyState, StateStep } from 'store/journey/journey.type';
import { RootState } from 'store/store';
import { getPushErrorList } from 'validators/ExperienceCanvas/push.validator';
import { getContentErrorList } from 'validators/ExperienceCanvas/content.validator';
import {
  getRuleErrorList,
  getAbsoluteScheduleErrorList,
  getContextualScheduleErrorList,
} from 'validators/ExperienceCanvas/rule.validator';
import { getBackendJourneyErrors } from 'helpers/error.helper';
import { JourneyError } from 'validators/ExperienceCanvas/types';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { FlowNodeTypes } from 'components/FlowBuilder/FlowNodes/node.types';

export function validateJourney(state: RootState) {
  const journeyState: JourneyState = state.te.journey;

  const stepErrors: string[] = [];

  for (let i = 0; i < journeyState.steps.length; i++) {
    const { errorStatus } = validateStep(state, i);
    stepErrors.push(errorStatus);
  }

  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  getBackendJourneyErrors(beErrors, state).topLevelErrors.forEach(() => stepErrors.push('error'));
  // for now, the validate will only return a bool indicating validity
  return stepErrors.reduce((prev, curr) => {
    return prev && curr === '';
  }, true);
}

export function validateStep(state: RootState, stepIdx: number) {
  const journeyState: JourneyState = state.te.journey;
  const step = journeyState.steps[stepIdx];
  if (!step)
    return {
      errorStatus: ERROR_TYPES.ERROR,
      errors: [{ type: ERROR_TYPES.ERROR, err: 'An unknown error has occured.' }],
    };

  const errors: JourneyError[] = [];

  validateTrigger(state, step, stepIdx, false).forEach((err) => errors.push(err));
  validateActions(state, step, false).forEach((err) => errors.push(err));

  // merge state errors
  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  getBackendJourneyErrors(beErrors, state).stepErrors[stepIdx.toString()]?.forEach((err) => errors.push(err));

  const errorStatus = errors.reduce((prev, curr) => {
    if (prev === ERROR_TYPES.ERROR || curr.type === ERROR_TYPES.ERROR) return ERROR_TYPES.ERROR;
    else return ERROR_TYPES.INCOMPLETE;
  }, '');
  return { errorStatus, errors };
}

export function validateStepTrigger(state: RootState, stepIdx: number) {
  const journeyState: JourneyState = state.te.journey;
  const step = journeyState.steps[stepIdx];
  if (!step)
    return {
      errorStatus: ERROR_TYPES.ERROR,
      errors: [{ type: ERROR_TYPES.ERROR, err: 'An unknown error has occured.' }],
    };

  const errors: JourneyError[] = [];
  validateTrigger(state, step, stepIdx, false).forEach((err) => errors.push(err));

  const errorStatus = errors.reduce((prev, curr) => {
    if (prev === ERROR_TYPES.ERROR || curr.type === ERROR_TYPES.ERROR) return ERROR_TYPES.ERROR;
    else return ERROR_TYPES.INCOMPLETE;
  }, '');
  return { errorStatus, errors };
}

export function validateStepAction(
  state: RootState,
  stepIdx: number,
  nodeType: FlowNodeTypes,
  refId?: string | undefined,
) {
  const journeyState: JourneyState = state.te.journey;
  const errors: JourneyError[] = [];

  switch (nodeType) {
    case FlowNodeTypes.CONTENT:
      validateContentAction(state, journeyState.steps[stepIdx], false, refId ?? '').forEach((err) => errors.push(err));
      break;
    case FlowNodeTypes.PUSH:
      validatePushAction(state, journeyState.steps[stepIdx], false).forEach((err) => errors.push(err));
      break;
  }

  const errorStatus = errors.reduce((prev, curr) => {
    if (prev === ERROR_TYPES.ERROR || curr.type === ERROR_TYPES.ERROR) return ERROR_TYPES.ERROR;
    else return ERROR_TYPES.INCOMPLETE;
  }, '');
  return { errorStatus, errors };
}

export function validateTrigger(state: RootState, step: StateStep, stepIdx: number, checkJourneyErrors = true) {
  const errors: JourneyError[] = [];
  // TODO: Unify rule validations between selector and validators
  const resRefId = step.rule.restricted;
  const prefRefId = step.rule.preferred;
  const restrictedRule = state.te.rule.byRefId[resRefId];
  const preferredRule = state.te.rule.byRefId[prefRefId];
  const checkRestricted = restrictedRule && (!restrictedRule.isOptional || !!restrictedRule.enableOptionalNode);
  const checkPreferred = preferredRule && (!preferredRule.isOptional || !!preferredRule.enableOptionalNode);

  if (checkPreferred || checkRestricted) {
    // edge case check if both rules are optional, but the user has set rule to be enabled. Workaround for current UI.
    const isLooselyEnabled =
      restrictedRule?.isOptional &&
      preferredRule?.isOptional &&
      restrictedRule.enableOptionalNode &&
      preferredRule.enableOptionalNode;
    const restrictedErrors = getRuleErrorList(state, resRefId, checkRestricted, checkJourneyErrors);
    const preferredErrors = getRuleErrorList(state, prefRefId, checkPreferred, checkJourneyErrors);

    if (!isLooselyEnabled || (restrictedErrors.length && preferredErrors.length)) {
      restrictedErrors.forEach((err) => errors.push(err));
      preferredErrors.forEach((err) => errors.push(err));
    }
    getAbsoluteScheduleErrorList(state, stepIdx, checkJourneyErrors).forEach((err) => errors.push(err));
    getContextualScheduleErrorList(state, checkJourneyErrors).forEach((err) => errors.push(err));
  }

  return errors;
}

export function validateActions(state: RootState, step: StateStep, checkJourneyErrors = true) {
  const errors: JourneyError[] = [];
  // check push
  if (step.push) {
    const push = state.te.push.byRefId[step.push];
    if (!push?.isOptional || push?.enableOptionalNode)
      getPushErrorList(state, step.push, checkJourneyErrors).forEach((err) => errors.push(err));
  }

  // check content
  if (step.content && step.content.length) {
    step.content.forEach((refId) => {
      const content = state.te.content.byRefId[refId];
      if (!content?.isOptional || content?.enableOptionalNode)
        getContentErrorList(state, refId, checkJourneyErrors).forEach((err) => errors.push(err));
    });
  }
  return errors;
}

function validatePushAction(state: RootState, step: StateStep, checkJourneyErrors: boolean) {
  const errors: JourneyError[] = [];
  // check push
  if (step.push) {
    const push = state.te.push.byRefId[step.push];
    if (!push?.isOptional || push?.enableOptionalNode)
      getPushErrorList(state, step.push, checkJourneyErrors).forEach((err) => errors.push(err));
  }

  return errors;
}

function validateContentAction(state: RootState, step: StateStep, checkJourneyErrors: boolean, refId: string) {
  const errors: JourneyError[] = [];

  const content = state.te.content.byRefId[refId];
  if (content && (!content?.isOptional || content?.enableOptionalNode)) {
    getContentErrorList(state, refId, checkJourneyErrors).forEach((err) => errors.push(err));
  }

  return errors;
}
