import React from 'react';
import { FlightSnackbar } from '@flybits/design-system';
import { useSelector, useDispatch } from 'react-redux';
import { dismissSnackbar } from 'store/snackbar/snackbar.action';
import './SnackBar.scss';

export default function SnackBar() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state = useSelector((state: any) => state.snackbar);
  const dispatch = useDispatch();

  return (
    <div className="snackbar">
      <FlightSnackbar
        isVisible={state.isVisible}
        isFloating={state.isFloating}
        animation={state.animation}
        icon={state.icon}
        title={state.title}
        content={state.content}
        type={state.type}
        isAutoDismiss={state.isAutoDismiss}
        dismissAfter={state.dismissAfter}
        action={state.action ? state.action : () => dispatch(dismissSnackbar())}
        actionName={state.actionName || ''}
        handleClose={state.toggleVisible ? state.toggleVisible : () => dispatch(dismissSnackbar())}
      />
    </div>
  );
}
