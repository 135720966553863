import React from 'react';
import './TemplatesLibrary.scss';
import DefaultSubCatIcon from 'assets/icons/default-subcategories.svg';
import DefaultCatIcon from 'assets/icons/default-categories.svg';
import { Category } from 'interface/templated-experience/templated-experience.interface';

type TProps = {
  isAllTemplateSelected: boolean;
  onSidebarClick: (catId: string, subCatId: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categoriesList: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  projectData: any;
  isError?: boolean;
};

export const TemplatesLibrarySidebar = ({
  isAllTemplateSelected,
  onSidebarClick,
  categoriesList,
  projectData,
  isError = false,
}: TProps) => {
  const DEFAULT_CLASS = 'templates-library__sidebar';
  const getCategoryButton = (category: Category, subcategory: Category, index: number) => {
    if (subcategory.key && subcategory.key !== 'unassigned') {
      const subCategoryClass =
        projectData.cid === category.key && projectData.sid === subcategory.key ? ' selected' : '';
      const subCategoryImage = subcategory.iconUrl ? subcategory.iconUrl : DefaultSubCatIcon;
      return (
        <li key={index.toString()}>
          <button
            type="button"
            tabIndex={0}
            className={`${DEFAULT_CLASS}__button inner` + subCategoryClass}
            aria-label={subcategory.name ? subcategory.name : subcategory.key}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (event?.currentTarget?.className?.includes('selected')) {
                return;
              }
              onSidebarClick(category?.key || '', subcategory?.key || '');
            }}
          >
            <img className={`${DEFAULT_CLASS}__button__img`} src={`${subCategoryImage}`} alt={subcategory.key} />
            <span className={`${DEFAULT_CLASS}__button__label`}>
              {subcategory.name ? subcategory.name : subcategory.key}
            </span>
          </button>
        </li>
      );
    }
  };
  const isOnlyUnassignedSubCat = (subcategory: Category[]) => {
    let isUnassignedSubCat = true;
    if (subcategory.length === 1 && subcategory[0].key === 'unassigned') {
      isUnassignedSubCat = false;
    }
    return isUnassignedSubCat;
  };
  return (
    <div className={`${DEFAULT_CLASS}`}>
      <button
        type="button"
        tabIndex={0}
        className={`${DEFAULT_CLASS}__button` + (isAllTemplateSelected || isError ? ' selected' : '')}
        aria-label="All Templates"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          if (event?.currentTarget?.className?.includes('selected')) {
            return;
          }
          onSidebarClick('', '');
        }}
      >
        <span className={`${DEFAULT_CLASS}__button__no-img`}></span>
        <span className={`${DEFAULT_CLASS}__button__label`}>All Templates</span>
      </button>
      {categoriesList?.length > 0 ? (
        <nav aria-label="side panel">
          {categoriesList
            ?.sort((firstCategory: Category, secondCategory: Category) =>
              firstCategory.name > secondCategory.name ? 1 : -1,
            )
            ?.map((_category: Category, olIndex: number) => {
              if (_category.key && _category.key !== 'all') {
                const categoryClass = projectData.cid === _category.key && !projectData.sid ? ' selected' : '';
                const categoryImage = _category.iconUrl || DefaultCatIcon;
                return (
                  <ol key={olIndex.toString()}>
                    <li>
                      <button
                        type="button"
                        tabIndex={0}
                        className={`${DEFAULT_CLASS}__button` + categoryClass}
                        aria-label={_category.name ? _category.name : _category.key}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          if (event?.currentTarget?.className?.includes('selected')) {
                            return;
                          }
                          onSidebarClick(_category?.key || '', '');
                        }}
                      >
                        <img className={`${DEFAULT_CLASS}__button__img`} src={`${categoryImage}`} alt={_category.key} />
                        <span className={`${DEFAULT_CLASS}__button__label`}>
                          {_category.name ? _category.name : _category.key}
                        </span>
                      </button>
                    </li>
                    {_category?.subcategories?.length && isOnlyUnassignedSubCat(_category.subcategories) ? (
                      <ol>
                        {_category?.subcategories
                          ?.sort((firstCategory: Category, secondCategory: Category) =>
                            firstCategory.name > secondCategory.name ? 1 : -1,
                          )
                          .map((subcategory: Category, index: number) => {
                            return getCategoryButton(_category, subcategory, index);
                          })}
                      </ol>
                    ) : (
                      ''
                    )}
                  </ol>
                );
              }
              return undefined;
            })}
        </nav>
      ) : (
        ''
      )}
    </div>
  );
};
