import { AuthActionType, AuthorizeUserAction, UnAuthorizeUserAction } from 'store/auth/auth.type';

import AuthAPI from 'services/api/auth.api';
import Session from 'services/session';
import { User } from 'interface/auth/auth.interface';
import { getTenantId } from 'helpers/templated-experience.helper';

export function authorizeUser(payload: { user: User; token: string }): AuthorizeUserAction {
  return {
    type: AuthActionType.AUTHORIZE_USER,
    payload,
  };
}

export function unauthorizeUser(): UnAuthorizeUserAction {
  return {
    type: AuthActionType.UNAUTHORIZE_USER,
  };
}

export function authenticateUser(token = '', tenantId = '') {
  const authAPI = new AuthAPI();
  const session = new Session();

  const _tenantId = tenantId || getTenantId();
  let _token = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    if (token) {
      // Executes when the token is passed by RedirectIndex.tsx by parsing
      // the query param from the URL
      await session.setToken(_tenantId, token);
      _token = token;
    }

    if (!token) {
      _token = (await session.getToken(_tenantId)) as string;
    }

    return new Promise<void>((resolve, reject) => {
      if (!_token) {
        reject({
          message: 'The token is missing.',
        });

        return;
      }

      authAPI
        .validateToken()
        .then((user) => {
          dispatch(authorizeUser({ token: _token, user }));
          resolve();
        })
        .catch((axiosError) => {
          let message = 'There was an error retrieving the signed-in user.';

          if (axiosError?.message) {
            message += ` (${axiosError.message})`;
          }
          reject({
            message,
            error: axiosError,
          });
        });
    });
  };
}
