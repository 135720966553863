import React, { useCallback, useState, useEffect } from 'react';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { saveContextualSchedulerAction } from 'store/rule/rule.action';
import { convertSecondsToPeriod } from 'helpers/templated-experience.helper';
import { TimeContextAttribute, genBeforeAfterPayload } from 'helpers/rule.helper';
import { FlightNumberInput, FlightTextInput, FlightSelect } from '@flybits/design-system';
import { EVENT_PERIODS } from 'components/ExperienceCanvas/constants';

type TTimeContextFormProps = {
  stepIdx: number;
  defaultClass: string;
  timeAttributes: TimeContextAttribute[];
  preconditionKey?: string;
  memoizedModifier?: string[];
  startOrEnd?: 'start' | 'end';
};

// maybe we should even render this form if the project has no time plugin attributes.
export default function TimeContextForm({
  stepIdx,
  defaultClass,
  timeAttributes,
  preconditionKey,
  memoizedModifier,
  startOrEnd = 'start',
}: TTimeContextFormProps) {
  const dispatch = useDispatch();

  const getAttributesAndParams = (timeAttributes: TimeContextAttribute[], splitModifier?: string[]) => {
    const attribute = splitModifier?.[0]?.includes('query')
      ? splitModifier?.[0]?.split('.')?.slice(0, 5).join('.')
      : splitModifier?.[0]?.split('.')?.slice(0, 4).join('.');
    const params = splitModifier?.[0]?.includes('query') ? splitModifier?.[0]?.split('.')?.slice(5) : [];
    let returnAttr = '';
    let returnParams: string[] = [];

    if (timeAttributes?.length) {
      if (attribute && splitModifier && splitModifier.length > 1 && params) {
        const matchingTimeCtxAttr = timeAttributes.find((attr) => attr.key === attribute);

        if (matchingTimeCtxAttr) {
          returnAttr = matchingTimeCtxAttr.key || '';
          returnParams = matchingTimeCtxAttr.ctxParams.map((_, idx) =>
            // remove wildcards
            params[idx] === '*' ? '' : params[idx] ?? '',
          );
        }
      } else {
        returnAttr = timeAttributes[0]?.key || '';
        returnParams = timeAttributes[0]?.ctxParams.map(() => '') ?? [];
      }
    }

    return {
      attribute: returnAttr,
      params: returnParams,
    };
  };

  const getPeriod = (storeValue?: string) => {
    const ctxModifierValue = Number(storeValue || '1');
    const { value, period: periodString } = convertSecondsToPeriod(ctxModifierValue);
    return {
      value,
      period: EVENT_PERIODS.find((period) => period.key === periodString) || EVENT_PERIODS[1],
    };
  };

  const [eventPeriod, setPeriod] = useState<{ value: number | string; period: (typeof EVENT_PERIODS)[0] }>(
    getPeriod(memoizedModifier?.[2]),
  );
  const [eventAttrParams, setAttrParams] = useState(getAttributesAndParams(timeAttributes, memoizedModifier));
  const activeAttribute = timeAttributes.find((attr) => attr.key === eventAttrParams.attribute);

  const serializeAndDispatch = useCallback(
    (value: number | string, period: string, attribute: string, params: string[]) => {
      const numericValue = Number(value);
      if (Number.isNaN(numericValue) || numericValue <= 0 || !period || !attribute) {
        return;
      }

      const saveSchedulerRulePayload = genBeforeAfterPayload(
        stepIdx,
        startOrEnd,
        numericValue,
        period,
        attribute,
        params,
        preconditionKey,
      );

      if (saveSchedulerRulePayload.modifier === memoizedModifier?.join(' ')) return;

      dispatch(saveContextualSchedulerAction(saveSchedulerRulePayload));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepIdx, dispatch, preconditionKey, memoizedModifier],
  );

  useEffect(() => {
    setAttrParams(getAttributesAndParams(timeAttributes, memoizedModifier));
    setPeriod(getPeriod(memoizedModifier?.[2]));
  }, [timeAttributes, memoizedModifier]);

  return (
    <>
      <div className={`${defaultClass}__before-after-unique-event`}>
        <div className={`${defaultClass}__before-after-unique-event__element`}>
          {eventPeriod.period.key !== 'now' && (
            <FlightNumberInput
              placeholderText="1"
              width="50px"
              minValue={1}
              maxValue={999}
              value={String(eventPeriod.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                setPeriod((prev) => {
                  return { ...prev, value };
                });
              }}
              onBlur={() =>
                serializeAndDispatch(
                  eventPeriod.value,
                  eventPeriod.period.key,
                  eventAttrParams.attribute,
                  eventAttrParams.params,
                )
              }
            />
          )}
        </div>
        <div className={`${defaultClass}__before-after-unique-event__element`}>
          <FlightSelect
            options={EVENT_PERIODS}
            selected={eventPeriod.period}
            hasLabelAnimation={true}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleOptionClick={(option: any) => {
              setPeriod((prev) => {
                const value = prev.period.key === 'now' ? 1 : prev.value;
                serializeAndDispatch(value, option.key, eventAttrParams.attribute, eventAttrParams.params);
                return {
                  ...prev,
                  period: option,
                  value,
                };
              });
            }}
            dropdownMaxHeight="200px"
            dropdownDirection="auto"
            width="128px"
            label=""
          />
        </div>
        <div className={`${defaultClass}__before-after-unique-event__element`}>
          {preconditionKey === 'before-unique-event' ? 'before' : 'after'} each user&apos;s
        </div>
        <div className={`${defaultClass}__before-after-unique-event__element`}>
          <FlightSelect
            options={timeAttributes}
            selected={activeAttribute}
            hasLabelAnimation={true}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleOptionClick={(option: any) => {
              const newParams = option.ctxParams.map(() => '');
              setAttrParams(() => {
                return { attribute: option.key, params: newParams };
              });
              serializeAndDispatch(eventPeriod.value, eventPeriod.period.key, option.key, newParams);
            }}
            dropdownMaxHeight="200px"
            dropdownDirection="auto"
            label=""
          />
        </div>
      </div>
      {!!activeAttribute?.ctxParams.length && (
        <div className={`${defaultClass}__before-after-unique-event`}>
          <div className={`${defaultClass}__before-after-unique-event__element`}>With parameters</div>
          <div className={`${defaultClass}__before-after-unique-event__element`}>
            {activeAttribute?.ctxParams.map((param, paramIdx) => (
              <div
                key={`before-after-param-form-${paramIdx}`}
                className={`${defaultClass}__before-after-unique-event__param__container`}
              >
                <label className={`${defaultClass}__before-after-unique-event__param__element`}>{param.name}</label>
                {param.valueOptions && param.valueOptions.length ? (
                  <FlightSelect
                    className={`${defaultClass}__before-after-unique-event__param__element`}
                    options={param.valueOptions}
                    selected={param.valueOptions.find((opt) => opt.key === eventAttrParams.params[paramIdx])}
                    hasLabelAnimation={true}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    handleOptionClick={(option: any) => {
                      setAttrParams((prev) => {
                        const newParams = [...prev.params];
                        newParams.splice(paramIdx, 1, option.key);
                        serializeAndDispatch(eventPeriod.value, eventPeriod.period.key, prev.attribute, newParams);
                        return { ...prev, params: newParams };
                      });
                    }}
                    dropdownMaxHeight="200px"
                    dropdownDirection="auto"
                    aria-label={param.name}
                  />
                ) : (
                  <div className={`${defaultClass}__before-after-unique-event__param__element`}>
                    <FlightTextInput
                      placeholderText="Any"
                      width="232px"
                      value={eventAttrParams.params[paramIdx]}
                      aria-label={param.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setAttrParams((prev) => {
                          const newParams = [...prev.params];
                          newParams.splice(paramIdx, 1, value);
                          return { ...prev, params: newParams };
                        });
                      }}
                      onBlur={() =>
                        serializeAndDispatch(
                          eventPeriod.value,
                          eventPeriod.period.key,
                          eventAttrParams.attribute,
                          eventAttrParams.params,
                        )
                      }
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
