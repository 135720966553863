import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FlightCheckbox, FlightRadioButton, FlightSelect, FlightTimeZone, FlightTooltip } from '@flybits/design-system';
import { getISODate } from 'helpers/common.helper';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { FullTime, Option, SelectOption } from 'types/common';
import CalendarIcon from 'pages/ExperienceCanvas/assets/icons/CalendarIcon';
import { getTimeRangeInMinutes, getTimezoneList } from 'helpers/templated-experience.helper';
import { SchedulerStateItem } from 'store/rule/rule.type';
import { AbsoluteErrorReturnValue } from 'validators/ExperienceCanvas/rule.validator';
import { CLASSES } from '../classes';
import '../ScheduleEditorBox.scss';
import { START_TIME } from 'pages/ExperienceCanvas/types';
import CronExpressionSection from '../CronExpressionSection/CronExpressionSection';
import { selectStepRule } from 'store/rule/rule.selector';

type TTimeScheduleProps = {
  dateTime: { start: FullTime; end: FullTime; timezone: Option | undefined };
  errors: AbsoluteErrorReturnValue;
  startScheduler: SchedulerStateItem;
  endScheduler: SchedulerStateItem;
  stepIdx: number;
  storeDateTime: {
    start: FullTime;
    end: FullTime;
    timezone: Option | undefined;
  };
  operationType?: string;
  setDateTime: React.Dispatch<
    React.SetStateAction<{
      start: FullTime;
      end: FullTime;
      timezone: Option | undefined;
    }>
  >;
  clearDateTime: () => void;
};

const touchpointOperationModeOptions: SelectOption[] = [
  { key: 'only-start', name: 'at a specific moment' },
  { key: 'start-and-end', name: 'within a specific period' },
  { key: 'recurring', name: 'on a recurring basis' },
];

const tzList: Option[] = getTimezoneList().map((tz) => ({
  key: tz.key,
  name: tz.name,
  value: tz.offset,
}));

const MAIN_CLASS = 'time-schedule';

const TimeSchedule: React.FC<TTimeScheduleProps> = ({
  stepIdx,
  storeDateTime,
  dateTime,
  startScheduler,
  endScheduler,
  errors,
  operationType,
  setDateTime,
  clearDateTime,
}) => {
  const { schedule: journeySchedule } = useSelector((state) => state.te.journey);
  const minStart = moment
    .unix(journeySchedule?.start || Date.now() / 1000)
    .utc()
    .add(5, 'm');
  const [startAt, setStartAt] = useState<'specific-time' | 'now'>(
    startScheduler?.start === START_TIME.NOW ? 'now' : 'specific-time',
  );
  const [timeRange, setTimeRange] = useState({
    start: getTimeRangeInMinutes(storeDateTime.start.date, minStart.unix(), journeySchedule?.end),
    end: getTimeRangeInMinutes(
      storeDateTime.end.date,
      storeDateTime.start.date ? storeDateTime.start.date?.getTime() / 1000 : undefined,
      journeySchedule?.end,
    ),
  });
  const [touchpointOperationMode, setTouchpointOperationMode] = useState<SelectOption | undefined>(() => {
    const optionIndex = touchpointOperationModeOptions.findIndex((option) => option.key === operationType);
    if (operationType) {
      return optionIndex !== -1 ? touchpointOperationModeOptions[optionIndex] : undefined;
    } else {
      if (dateTime.end.date) return touchpointOperationModeOptions[1];
      if (dateTime.start.date) return touchpointOperationModeOptions[0];
      if (!!startScheduler?.cronExpression) return touchpointOperationModeOptions[2];
      return undefined;
    }
  });
  const hasEndDate = touchpointOperationMode?.key === 'start-and-end';

  const dispatch = useDispatch();

  const handleTouchpointOperationModeChange = (option: SelectOption) => {
    // if we submit an end scheduler without a start scheduler, back end will punish us severely
    const omitStart = false;
    const omitEnd = option.key === 'only-start';

    dispatch({
      type: 'UPDATE_STEP',
      payload: {
        stepIdx,
        fields: {
          omitStart,
          omitEnd,
          operationType: option.key,
        },
      },
    });

    if (omitEnd) {
      dispatch({
        type: 'CLEAR_SCHEDULE',
        payload: {
          stepIdx,
          type: 'end',
        },
      });
    }

    setTouchpointOperationMode(option);
  };

  // this function was originally altered to support the updating of timerange
  // whenever date/time/timezone changed.  With the use of browser default input
  // range calculations for time no longer matter, but this code will be left
  // in place until it can be confirmed whether or not custom time pickers
  // that support limiting time choice should be supported.
  const handleDateTimeChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (type: 'start' | 'end', dataType: 'date' | 'time' | 'tz', arg: any) => {
      const fullTime = type === 'start' ? dateTime.start : dateTime.end;
      const newValues = {
        date: dataType === 'date' ? arg : fullTime.date,
        time: dataType === 'time' ? arg : fullTime.time,
        tz: dataType === 'tz' ? arg : dateTime.timezone,
      };

      const newDateTime = {
        ...dateTime,
        [type]: {
          ...dateTime[type],
          date: newValues.date,
          time: newValues.time,
        },
        timezone: newValues.tz,
      };

      const newTimeRange = {
        start: getTimeRangeInMinutes(newDateTime.start.date, minStart.unix(), journeySchedule?.end),
        end: getTimeRangeInMinutes(
          newDateTime.end.date,
          newDateTime.start.date ? newDateTime.start.date?.getTime() / 1000 : undefined,
          journeySchedule?.end,
        ),
      };

      // check if time is within new bounds (defunct pending comment above)
      // const newStartTime = newDateTime.start.time?.value;
      // const newEndTime = newDateTime.end.time?.value;

      // if (!checkInTimeRange(newTimeRange.start, newStartTime)) newDateTime.start.time = undefined;
      // if (!checkInTimeRange(newTimeRange.end, newEndTime)) newDateTime.end.time = undefined;

      let updateStore = true;
      if (!newValues.date || newValues.time?.value === undefined || !newValues.tz?.name) updateStore = false;

      setTimeRange(newTimeRange);
      setDateTime(newDateTime);

      if (updateStore) {
        dispatch({
          type: 'UPDATE_SCHEDULE',
          payload: {
            stepIdx,
            type,
            start: moment(newValues.date).add(newValues.time.value, 'seconds').unix(),
            timezone: {
              name: newValues.tz.name,
              offset: newValues.tz.offset,
            },
          },
        });
      }
    },
    [dateTime, dispatch, journeySchedule, minStart, stepIdx, setDateTime],
  );

  const getSelectTimeOption = (time: string) => {
    const [hour, minute] = time.split(':');
    const seconds = parseInt(hour) * 3600 + parseInt(minute) * 60;
    return {
      key: `${seconds}`,
      value: seconds,
      name: moment(time, 'HH:mm').format('hh:mm A'),
    };
  };

  const handleStartAtChange = (value: 'specific-time' | 'now') => {
    if (value === 'now') {
      dispatch({
        type: 'UPDATE_SCHEDULE',
        payload: {
          stepIdx,
          type: 'start',
          start: START_TIME.NOW,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_SCHEDULE',
        payload: {
          stepIdx,
          type: 'start',
          start: 0,
        },
      });
    }
    clearDateTime();
    setStartAt(value);
  };

  const { ruleRefId, evaluateAtAbsoluteIntervalStart } = useSelector((state) => {
    const rule = selectStepRule(state.te, stepIdx);
    return {
      ruleRefId: rule?.refId ?? '',
      evaluateAtAbsoluteIntervalStart: rule?.evaluateAtAbsoluteIntervalStart ?? false,
    };
  });

  const handleEvaluateAtAbsoluteIntervalStart = () => {
    dispatch({
      type: 'UPDATE_RULE_SIMPLE',
      payload: {
        refId: ruleRefId,
        fields: { evaluateAtAbsoluteIntervalStart: !evaluateAtAbsoluteIntervalStart },
      },
    });
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.SECTION}>
        <div className={CLASSES.FIELD}>
          <label htmlFor="touchpoint-operation-mode">The touchpoint will operate</label>
          <FlightSelect
            ariaControlsId="touchpoint-operation-mode"
            options={touchpointOperationModeOptions}
            selected={
              touchpointOperationMode || {
                key: '',
                name: (
                  <span className={CLASSES.SELECT_PLACEHOLDER}>
                    Select the schedule work <span className={CLASSES.REQUIRED_SYMBOL}>*</span>
                  </span>
                ),
              }
            }
            handleOptionClick={handleTouchpointOperationModeChange}
            width="100%"
          />
        </div>
        <div className={CLASSES.FIELD}>
          <label htmlFor="evaluate-at-interval-start">Evaluate rule immediately when schedule is activated?</label>
          <span id="evaluate-at-interval-start">
            <FlightCheckbox
              checkState={evaluateAtAbsoluteIntervalStart ? 'SELECTED' : 'UNSELECTED'}
              onSelect={handleEvaluateAtAbsoluteIntervalStart}
            />
          </span>
        </div>
      </div>
      {['only-start', 'start-and-end'].includes(touchpointOperationMode?.key || '') && (
        <>
          <div className={CLASSES.SECTION}>
            <h4>
              <CalendarIcon />
              Select the evaluating time
            </h4>
            {journeySchedule && (
              <p>
                The touchpoint schedule needs to be{' '}
                <strong>
                  within the journey schedule
                  {journeySchedule.start && journeySchedule.end
                    ? ` from ${moment(journeySchedule.start * 1000).format('MMM D, YYYY, [at] hh[:]mma')} to ${moment(
                        journeySchedule.end * 1000,
                      ).format('MMM D, YYYY, [at] hh[:]mma')}`
                    : ''}
                  {journeySchedule.start && !journeySchedule.end
                    ? ` starting ${moment(journeySchedule.start * 1000).format('MMM D, YYYY, [at] hh[:]mma')}`
                    : ''}
                  {journeySchedule.end && !journeySchedule.start
                    ? ` ending ${moment(journeySchedule.end * 1000).format('MMM D, YYYY, [at] hh[:]mma')}`
                    : ''}
                </strong>
              </p>
            )}
            {touchpointOperationMode?.key === 'only-start' && (
              <div className={CLASSES.FIELD}>
                <label htmlFor="start-evaluating">Start evaluating</label>
                <div className={CLASSES.RADIO_WRAPPER}>
                  <FlightRadioButton
                    label="At a specific time"
                    checked={startAt === 'specific-time'}
                    value="specific-time"
                    onSelect={handleStartAtChange}
                  />
                  <FlightTooltip
                    isEnabled={stepIdx !== 0 || !!journeySchedule?.start}
                    description={
                      stepIdx !== 0
                        ? 'Available only in the first step of the journey.'
                        : 'The "Now" option is currently unavailable due to Experience\'s schedule start date is specified.'
                    }
                    direction="top"
                  >
                    <FlightRadioButton
                      label="Now"
                      value="now"
                      checked={startAt === 'now'}
                      onSelect={handleStartAtChange}
                      disabled={stepIdx !== 0 || !!journeySchedule?.start}
                    />
                  </FlightTooltip>
                </div>
              </div>
            )}
            {(hasEndDate || startAt === 'specific-time') && (
              <>
                <div className={CLASSES.FIELD}>
                  {hasEndDate && <label htmlFor="start-and-end-dates">Start evaluating on</label>}
                  <div className={hasEndDate ? CLASSES.DATETIME : CLASSES.DATETIME_LEFT_ALIGNED}>
                    <input
                      type="date"
                      className={!!errors.start?.[0]?.err ? CLASSES.HAS_ERROR : ''}
                      id="start-date"
                      name="start-date"
                      value={getISODate(dateTime.start.date)}
                      min={minStart.format('YYYY-MM-DD')}
                      onChange={(evt) =>
                        handleDateTimeChange(
                          'start',
                          'date',
                          moment(evt.target.value).isValid() ? moment(evt.target.value).toDate() : '',
                        )
                      }
                      aria-label="start date"
                      width={200}
                    />
                    <input
                      type="time"
                      className={!!errors.start?.[0]?.err ? CLASSES.HAS_ERROR : ''}
                      id="start-time"
                      name="start-time"
                      value={
                        dateTime?.start?.time?.value !== undefined
                          ? moment.unix(dateTime.start.time.value).utc().format('HH:mm')
                          : ''
                      }
                      min={timeRange.start.min}
                      max={timeRange.start.max}
                      onChange={(evt) => handleDateTimeChange('start', 'time', getSelectTimeOption(evt.target.value))}
                      aria-label="start time"
                      width={90}
                    />
                    <FlightTimeZone
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      handleTimeZoneChange={(arg: any) => handleDateTimeChange('start', 'tz', arg)}
                      selectedTimeZone={dateTime?.timezone}
                      hasError={!!errors.start?.[0]?.err}
                      timeZoneList={tzList}
                      timeZoneRegex=""
                    />
                  </div>
                </div>
                {hasEndDate && (
                  <div className={CLASSES.FIELD}>
                    <label htmlFor="start-and-end-dates">End on</label>
                    <div className={CLASSES.DATETIME}>
                      <input
                        type="date"
                        className={!!errors.end?.[0]?.err ? CLASSES.HAS_ERROR : ''}
                        id="end-date"
                        name="end-date"
                        value={getISODate(dateTime.end.date)}
                        min={minStart.format('YYYY-MM-DD')}
                        onChange={(evt) =>
                          handleDateTimeChange(
                            'end',
                            'date',
                            moment(evt.target.value).isValid() ? moment(evt.target.value).toDate() : '',
                          )
                        }
                        aria-label="end date"
                        width={200}
                      />
                      <input
                        type="time"
                        className={!!errors.end?.[0]?.err ? CLASSES.HAS_ERROR : ''}
                        id="end-time"
                        name="end-time"
                        value={
                          dateTime?.end?.time?.value !== undefined
                            ? moment.unix(dateTime.end.time.value).utc().format('HH:mm')
                            : ''
                        }
                        min={timeRange.end.min}
                        max={timeRange.end.max}
                        onChange={(evt) => handleDateTimeChange('end', 'time', getSelectTimeOption(evt.target.value))}
                        aria-label="end time"
                        width={90}
                      />
                      <FlightTimeZone
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        handleTimeZoneChange={(arg: any) => handleDateTimeChange('end', 'tz', arg)}
                        selectedTimeZone={dateTime?.timezone}
                        hasError={!!errors.end?.[0]?.err}
                        timeZoneList={tzList}
                        timeZoneRegex=""
                        disabled
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      {touchpointOperationMode?.key === 'recurring' && (
        <CronExpressionSection stepIdx={stepIdx} startScheduler={startScheduler} endScheduler={endScheduler} />
      )}
    </div>
  );
};

export default TimeSchedule;
