import { TIconProps } from 'pages/ExperienceCanvas/assets/types';
import React from 'react';
import TouchpointEditorCardBody from './TouchpointEditorCardBody';
import TouchpointEditorCardHeader from './TouchpointEditorCardHeader';
import './TouchpointEditorCard.scss';

type TCardProps = {
  title: string;
  icon: (arg0: TIconProps) => JSX.Element;
  children: JSX.Element | string;
  info?: string;
  subtitle?: string;
  className?: string;
  timelineIndex?: string;
  toggleExpanded?: (isExpanded: boolean) => void;
  toggleTooltip?: string;
  isExpanded?: boolean;
  showExpandToggle?: boolean;
  isToggleExpandedDisabled?: boolean;
  actions?: JSX.Element[];
  isLocked?: boolean;
  lockedMessage?: string;
  hasError?: boolean;
};

const CLASS_PREFIX = 'card';
const TIMELINE_PREFIX = 'touchpoint__timeline';
const CLASSES = {
  CARD: CLASS_PREFIX,
  TIMELINE_CONTAINER: `${TIMELINE_PREFIX}-item`,
  TIMELINE_TOP: `${TIMELINE_PREFIX}-top`,
  TIMELINE_CIRCLE: `${TIMELINE_PREFIX}-circle`,
  TIMELINE_BOTTOM: `${TIMELINE_PREFIX}-bottom`,
  TIMELINE_BODY: `${TIMELINE_PREFIX}-container`,
};

export default function TouchpointEditorCard({
  title,
  children,
  icon,
  info,
  subtitle,
  className,
  toggleExpanded,
  toggleTooltip,
  lockedMessage,
  timelineIndex = '',
  isExpanded = true,
  showExpandToggle = false,
  isToggleExpandedDisabled = false,
  actions = [],
  isLocked = false,
  hasError = false,
}: TCardProps) {
  const handleToggle = (value: boolean) => {
    if (toggleExpanded) toggleExpanded(value);
  };

  return (
    <div className={`${CLASSES.TIMELINE_CONTAINER} ${hasError ? `${CLASSES.TIMELINE_CONTAINER}--error` : ''}`}>
      <div className={`${CLASSES.TIMELINE_TOP}`} />
      <div className={`${CLASSES.TIMELINE_CIRCLE}`}>{timelineIndex}</div>
      <div className={`${CLASSES.TIMELINE_BOTTOM}`} />
      <div className={`${CLASSES.TIMELINE_BODY}`}>
        <div className={`${CLASSES.CARD}${className ? ` ${className}` : ''}`}>
          <TouchpointEditorCardHeader
            title={title}
            subtitle={subtitle}
            info={info}
            icon={icon}
            isExpanded={isExpanded}
            setIsExpanded={handleToggle}
            showExpandToggle={showExpandToggle}
            toggleTooltip={toggleTooltip}
            isToggleExpandedDisabled={isToggleExpandedDisabled || isLocked}
            actions={actions}
            isLocked={isLocked}
            lockedMessage={lockedMessage}
          />
          {isExpanded && <TouchpointEditorCardBody isLocked={isLocked}>{children}</TouchpointEditorCardBody>}
        </div>
      </div>
    </div>
  );
}
