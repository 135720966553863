import React from 'react';
import './PinContentCard.scss';
import { FlightTooltip, getIcon } from '@flybits/design-system';
import DynamicContentPreviewIframe from 'components/ExperienceCanvas/DynamicContentIframe/DynamicContentPreviewIframe';
import { ReactComponent as PinIcon } from 'pages/Zones/assets/icons/icon-pinned.svg';

type TPinContentCardProps = (
  | {
      contentType: 'image';
      contentURI: string;
      contentInstanceId?: never;
    }
  | {
      contentType: 'content-instance';
      contentInstanceId: string;
      contentURI?: never;
    }
) & {
  id: string;
  title: string;
  onSelect?: (id: string) => void;
  onRemoveSelection?: (id: string) => void;
  infoTooltip?: string;
  isDisabled?: boolean;
  isPinned?: boolean;
  isSelected?: boolean;
};

const MAIN_CLASS = 'pin-content-card';
const CLASSES = {
  IS_DISABLED: `${MAIN_CLASS}--is-disabled`,
  CONTENT: `${MAIN_CLASS}__content`,
  ACTIONS: `${MAIN_CLASS}__actions`,
  TITLE: `${MAIN_CLASS}__title`,
  IMAGE_PREVIEW_WRAPPER: `${MAIN_CLASS}__image-preview-wrapper`,
  IMAGE_PREVIEW_LOADING_WRAPPER: `${MAIN_CLASS}__image-preview-loading-wrapper`,
  IMAGE_PREVIEW: `${MAIN_CLASS}__image-preview`,
  SELECTED_ICON: `${MAIN_CLASS}__selected-icon`,
  PINNED_ICON_WRAPPER: `${MAIN_CLASS}__pinned-icon-wrapper`,
};

const PinContentCard: React.FC<TPinContentCardProps> = ({
  contentType,
  id,
  title,
  onSelect,
  onRemoveSelection,
  contentInstanceId,
  contentURI,
  infoTooltip,
  isDisabled = false,
  isPinned = false,
  isSelected = false,
}) => {
  const handleSelectClick = () => {
    if (!onSelect) return;
    onSelect(id);
  };

  const handleRemoveSelectionClick = () => {
    if (!onRemoveSelection) return;
    onRemoveSelection(id);
  };

  return (
    <div className={`${MAIN_CLASS}${isDisabled ? ` ${CLASSES.IS_DISABLED}` : ''}`}>
      <div className={CLASSES.CONTENT}>
        {contentType === 'content-instance' ? (
          <DynamicContentPreviewIframe id={id} contentInstanceIds={[contentInstanceId]} />
        ) : (
          <div className={CLASSES.IMAGE_PREVIEW_WRAPPER}>
            <div
              className={CLASSES.IMAGE_PREVIEW}
              aria-label="content preview image"
              style={{ backgroundImage: `url(${contentURI})` }}
            />
          </div>
        )}
        {onSelect && onRemoveSelection && (
          <div className={CLASSES.ACTIONS}>
            {!isSelected && (
              <button type="button" onClick={handleSelectClick}>
                Select
              </button>
            )}
            {isSelected && (
              <button type="button" onClick={handleRemoveSelectionClick}>
                Remove selection
              </button>
            )}
          </div>
        )}
        {isSelected && <div className={CLASSES.SELECTED_ICON}>{getIcon('checkCircle', {})}</div>}
      </div>
      <div className={CLASSES.TITLE}>
        <p title={title}>{title}</p>
        {infoTooltip && <FlightTooltip description={infoTooltip}>{getIcon('infoFilled', {})}</FlightTooltip>}
      </div>
      {isPinned && (
        <div className={CLASSES.PINNED_ICON_WRAPPER}>
          <PinIcon />
          Pinned
        </div>
      )}
    </div>
  );
};

export default PinContentCard;
