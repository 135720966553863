import { TEASingleValue } from 'interface/templated-experience/analytics.interface';

export const AnalyticsFunnelActionType = {
  SET_FUNNEL_TA_LOADING: 'SET_FUNNEL_TA_LOADING',
  SET_FUNNEL_SP_LOADING: 'SET_FUNNEL_SP_LOADING',
  SET_FUNNEL_OP_LOADING: 'SET_FUNNEL_OP_LOADING',
  SET_FUNNEL_PD_LOADING: 'SET_FUNNEL_PD_LOADING',
  SET_FUNNEL_COCP_LOADING: 'SET_FUNNEL_COCP_LOADING',
  SET_FUNNEL_VC_LOADING: 'SET_FUNNEL_VC_LOADING',
  SET_FUNNEL_COCA_LOADING: 'SET_FUNNEL_COCA_LOADING',
  SET_FLOW_PND_LOADING: 'SET_FLOW_PND_LOADING',
  SET_FLOW_PNOV_LOADING: 'SET_FLOW_PNOV_LOADING',
  SET_FLOW_PNOE_LOADING: 'SET_FLOW_PNOE_LOADING',
  SET_FUNNEL_ERROR: 'SET_FUNNEL_ERROR',
  SET_FUNNEL_TA_DATA: 'SET_FUNNEL_TA_DATA',
  SET_FUNNEL_SP_DATA: 'SET_FUNNEL_SP_DATA',
  SET_FUNNEL_OP_DATA: 'SET_FUNNEL_OP_DATA',
  SET_FUNNEL_PD_DATA: 'SET_FUNNEL_PD_DATA',
  SET_FUNNEL_COCP_DATA: 'SET_FUNNEL_COCP_DATA',
  SET_FUNNEL_VC_DATA: 'SET_FUNNEL_VC_DATA',
  SET_FUNNEL_COCA_DATA: 'SET_FUNNEL_COCA_DATA',
  SET_FLOW_PND_DATA: 'SET_FLOW_PND_DATA',
  SET_FLOW_PNOV_DATA: 'SET_FLOW_PNOV_DATA',
  SET_FLOW_PNOE_DATA: 'SET_FLOW_PNOE_DATA',
};

export interface AnalyticsFunnelState {
  targetAudience: TEASingleValue;
  pushSent: TEASingleValue;
  pushDelivered: TEASingleValue;
  openedPush: TEASingleValue;
  clickedOnContentFromPush: TEASingleValue;
  viewedContentInApp: TEASingleValue;
  clickedOnContentInApp: TEASingleValue;
  viewedContentWithoutPushDelivered: TEASingleValue;
  viewedContentWithoutPushOpened: TEASingleValue;
  engagedContentWithoutPushOpened: TEASingleValue;
  isTargetAudienceLoading: boolean;
  isPushSentLoading: boolean;
  isOpenedPushLoading: boolean;
  isPushDeliveredLoading: boolean;
  isClickedOnContentFromPushLoading: boolean;
  isViewedContentInAppLoading: boolean;
  isClickedOnContentInAppLoading: boolean;
  isViewedContentWithoutPushDeliveredLoading: boolean;
  isEngagedContentWithoutPushOpenedLoading: boolean;
  isViewedContentWithoutPushOpenedLoading: boolean;
  errorMessage: string;
}

export interface SetFunnelDataAction {
  type: string;
  payload: {
    value: number;
  };
}

export interface SetFunnelLoadingAction {
  type: string;
  payload: boolean;
}

export interface SetFunnelErrorAction {
  type: typeof AnalyticsFunnelActionType.SET_FUNNEL_ERROR;
  payload: string;
}
