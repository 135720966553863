import React, { useCallback, useEffect, useState } from 'react';
import './EditMerchant.scss';
import MerchantForm from 'components/MerchantPortal/MerchantForm/MerchantForm';
import { sleep } from 'helpers/common.helper';
import { MerchantFormValues, MerchantsRouteParams } from '../merchant-portal.types';
import useConfirm, { ConfirmationDialogProps } from 'hooks/useConfirm';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import IconPublishChanges from 'components/Shared/Icons/IconPublishChanges';
import { ConfirmationModalTypes } from 'components/Shared/shared.types';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import { FlightButton } from '@flybits/design-system';
import { useHistory, useParams } from 'react-router-dom';
import useMerchantMock from 'hooks/useMerchantMock';
import { MERCHANT_FORM_INIT_VALS } from '../merchant-portal.constants';
import { mapMerchantDataToFormValues } from '../merchant-portal.helpers';
import useMerchantCategoriesMock from 'hooks/useMerchantCategoriesMock';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';

const MAIN_CLASS = 'edit-merchant';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  TITLE: `${MAIN_CLASS}__header__title`,
  GUTTER: `${MAIN_CLASS}__header__gutter`,
  BODY: `${MAIN_CLASS}__body`,
  BREADCRUMBS: `${MAIN_CLASS}__body__breadcrumbs`,
  CONTAINER: `${MAIN_CLASS}__body__container`,
  LOADING: `${MAIN_CLASS}__loading`,
};

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.PUBLISH,
  icon: <IconPublishChanges />,
  title: 'Publish Changes',
  description: `Are you sure you want to commit these changes to the merchant profile?`,
  primaryAction: {
    value: 'Publish changes',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};
const EditMerchant = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pid: projectId, merchantid } = useParams<MerchantsRouteParams>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { merchantData: data, isMerchantLoading, isMerchantError } = useMerchantMock(merchantid ?? '');
  const { merchantCategories, isMerchantCategoriesLoading } = useMerchantCategoriesMock();
  const [merchantData, setMerchantData] = useState<MerchantFormValues>(
    data ? mapMerchantDataToFormValues(data) : MERCHANT_FORM_INIT_VALS,
  );
  const [isMerchantFormDirty, setMerchantFormDirty] = useState(false);
  const [ConfirmationDialog, confirmSubmit] = useConfirm(confirmationDialogProps);
  const handleOnFormSubmit = useCallback(async () => {
    if (await (confirmSubmit() as Promise<boolean>)) {
      setIsSubmitted(false);
      setIsSubmitting(true);
      await sleep(2000);
      setIsSubmitting(false);
      setIsSubmitted(true);
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: `Merchant details successfully saved.`, type: 'success' },
      });
      history.push(`/project/${projectId}/merchants`);
    }
  }, [confirmSubmit, dispatch, history, projectId]);
  useEffect(() => {
    if (data && !isMerchantError && !isMerchantLoading) {
      setMerchantData(mapMerchantDataToFormValues(data));
    }
  }, [data, isMerchantError, isMerchantLoading]);

  return (
    <>
      <div className={MAIN_CLASS}>
        <header className={CLASSES.HEADER}>
          <FlightButton
            iconLeft="clear"
            theme="link"
            ariaLabel="navigate back to merchant list"
            onClick={() => history.push(`/project/${projectId}/merchants`)}
          />
          <div className={CLASSES.TITLE}>Edit Merchant Information</div>
          <div className={CLASSES.GUTTER}></div>
        </header>
        {isMerchantLoading || isMerchantCategoriesLoading ? (
          <div className={CLASSES.LOADING} aria-label="merchant is loading">
            <LoadingIcon />
          </div>
        ) : (
          <div className={CLASSES.BODY}>
            <section className={CLASSES.CONTAINER}>
              <MerchantForm
                data={merchantData}
                categories={merchantCategories ?? []}
                onSubmit={handleOnFormSubmit}
                setDirty={setMerchantFormDirty}
                isEditMode
              />
            </section>
          </div>
        )}
      </div>
      {ConfirmationDialog()}
      <UnsavedPrompt
        when={isMerchantFormDirty && !isSubmitting && !isSubmitted}
        unblockPaths={['/merchants/:merchantid/edit']}
      />
    </>
  );
};

export default EditMerchant;
