import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FlightButton, FlightDropdown, getIcon } from '@flybits/design-system';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { EVENT_KEYS } from 'types/events';
import { CtxDataFile } from 'interface/file/file.interface';
import { PaginationResponse } from 'interface/shared/api.interface';
import FileAPI from 'services/api/file.api';
import { CtxFileStatus, FILE_STATUS } from 'pages/ExperienceCanvas/types';
import FileIcon from 'pages/ExperienceCanvas/assets/icons/FileIcon';
import UploadContextDataIcon from 'pages/ExperienceCanvas/assets/icons/UploadContextDataIcon';
import { FILE_STATUSES } from '../constants';
import './FileUploadDropdown.scss';
import ProcessContextFileButton from '../ProcessContextFileButton/ProcessContextFileButton';

type TFileUploadDropdown = {
  title: string;
  onViewAllClick: () => void;
};

const MAIN_CLASS = 'file-upload-dropdown';
const CLASSES = {
  COLLAPSED_TRIGGER: `${MAIN_CLASS}__collapsed-trigger`,
  ITEM_STATUS_LABEL: `${MAIN_CLASS}__item-status-label`,
  ITEM_STATUS_LABEL_ROUNDED: `${MAIN_CLASS}__item-status-label ${MAIN_CLASS}__item-status-label--is-rounded`,
  DROPDOWN: `${MAIN_CLASS}__dropdown`,
  DROPDOWN_CONTENT: `${MAIN_CLASS}__dropdown-content`,
  FILE_LIST_WRAPPER: `${MAIN_CLASS}__file-list-wrapper`,
  FILE_GROUP: `${MAIN_CLASS}__file-group`,
  FILE: `${MAIN_CLASS}__file`,
  EMPTY_STATE: `${MAIN_CLASS}__empty-state`,
  VIEW_ALL: `${MAIN_CLASS}__view-all`,
};

const perPage = 10;

const FileUploadDropdown: React.FC<TFileUploadDropdown> = ({ title, onViewAllClick }) => {
  const fileAPI = useMemo(() => new FileAPI(), []);

  const [isOpen, setIsOpen] = useState(false);
  const [isFileProcessing, setIsFileProcessing] = useState(false);
  const filesUUID = useSelector((state) => state.te.journey.metadata?.filesUUID || '');
  const triggerRef = useRef<HTMLButtonElement>(null);

  const fetchCtxDataFiles = async () =>
    await fileAPI.getAllCtxDataFiles({
      offset: 0,
      limit: perPage,
      tag: filesUUID,
    });

  const { data: res } = useQuery<PaginationResponse<CtxDataFile>>({
    queryKey: ['ctx-data-file', perPage, filesUUID],
    queryFn: () => fetchCtxDataFiles(),
    keepPreviousData: true,
    enabled: !!filesUUID,
  });

  const {
    bgColor: mainStatusBGColor,
    fgColor: mainStatusFGColor,
    text: mainStatusText,
    Icon: MainStatusIcon,
  } = res?.data.sort((a, b) => {
    return FILE_STATUSES[a.status].priority - FILE_STATUSES[b.status].priority;
  })[0]?.status
    ? FILE_STATUSES?.[res?.data[0]?.status]
    : {
        bgColor: '',
        fgColor: '',
        text: 'No files uploaded',
        Icon: () => null,
      };

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = (focusAfterClose = false) => {
    setIsOpen(false);
    if (focusAfterClose) triggerRef.current?.focus();
  };

  const handleViewAllClick = () => {
    closeDropdown();
    onViewAllClick();
  };

  useEffect(() => {
    if (res?.data?.length) {
      const isProcessing = res.data.some((file) => file.status === FILE_STATUS.PROCESSING);
      setIsFileProcessing(isProcessing);
    }
  }, [res]);

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === EVENT_KEYS.ESCAPE) {
        closeDropdown(true);
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, []);

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.COLLAPSED_TRIGGER}>
        <FlightDropdown
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          className={CLASSES.DROPDOWN}
          maxWidth="388px"
          isActive={isOpen}
          trigger={
            <button
              type="button"
              ref={triggerRef}
              onClick={openDropdown}
              onKeyUp={(evt: React.KeyboardEvent<HTMLButtonElement>) => {
                if (evt.key === EVENT_KEYS.ENTER) openDropdown();
              }}
            >
              <UploadContextDataIcon />
              {title}
              <div
                className={CLASSES.ITEM_STATUS_LABEL}
                style={{ backgroundColor: mainStatusBGColor, color: mainStatusFGColor }}
              >
                <MainStatusIcon />
                {mainStatusText}
                {getIcon(isOpen ? 'infoFilled' : 'baselineKeyboardArrowRight', {})}
              </div>
            </button>
          }
          isControlledByIsActive
          handleClickOutside={closeDropdown}
          maxHeight="420px"
        >
          <div className={CLASSES.DROPDOWN_CONTENT}>
            <h4>{title}</h4>
            <div className={CLASSES.FILE_LIST_WRAPPER}>
              {Object.keys(FILE_STATUSES).map((statusName) => {
                if (res?.data.findIndex((file) => file.status === statusName) === -1) return null;

                const { oneWordText, title, fgColor, bgColor, Icon } = FILE_STATUSES[statusName as CtxFileStatus];
                return (
                  <div className={CLASSES.FILE_GROUP} key={statusName}>
                    <h5 style={{ color: fgColor }}>{title}</h5>
                    {res?.data
                      .filter((file) => file.status === statusName)
                      .map((file) => (
                        <div key={file.id} className={CLASSES.FILE}>
                          <FileIcon />
                          {file.name}
                          {file.status === FILE_STATUS.UPLOADED && !isFileProcessing ? (
                            <ProcessContextFileButton fileId={file.id} />
                          ) : (
                            <div
                              className={CLASSES.ITEM_STATUS_LABEL_ROUNDED}
                              style={{ backgroundColor: bgColor, color: fgColor }}
                            >
                              <Icon />
                              {oneWordText}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                );
              })}
              {res?.data.length === 0 && <div className={CLASSES.EMPTY_STATE}>No context data file yet</div>}
            </div>
            <div className={CLASSES.VIEW_ALL}>
              <FlightButton
                theme="minor"
                label="View All"
                onClick={handleViewAllClick}
                onKeyDown={(evt: React.KeyboardEvent<HTMLButtonElement>) => {
                  if (evt.key === EVENT_KEYS.ENTER) handleViewAllClick();
                }}
              />
            </div>
          </div>
        </FlightDropdown>
      </div>
    </div>
  );
};

export default FileUploadDropdown;
