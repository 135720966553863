import BaseAPI from './base.api';
import { Location, LocationPayload } from 'interface/location/location.interface';
import { PaginationResponse } from 'interface/shared/api.interface';

type LocationError = {
  error: {
    exceptionMessage: string;
    exceptionType: string;
    requestId: string;
  };
};
export default class LocationAPI extends BaseAPI {
  private ROUTES = {
    locations: '/context/location',
    location: '/context/location/:id',
    files: '/context/location/files',
  };

  getLocation(id: string): Promise<Location> {
    return this.GET(this.buildURL(this.ROUTES.location, { id }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLocations(opts: any = {}): Promise<PaginationResponse<Location>> {
    let params = { limit: 1000, offset: 0 };
    params = Object.assign(params, opts);
    return this.GET(this.ROUTES.locations, params);
  }

  createLocation(locationPayload: LocationPayload): Promise<Location & LocationError> {
    return this.POST(this.ROUTES.locations, locationPayload);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLocationFiles(opts: any = {}): Promise<PaginationResponse<Location>> {
    let params = { limit: 10 };
    params = Object.assign(params, opts);
    return this.GET(this.ROUTES.files, params);
  }

  updateLocation(locationPayload: LocationPayload, id: string): Promise<Location & LocationError> {
    return this.PUT(this.buildURL(this.ROUTES.location, { id }), locationPayload);
  }
}
