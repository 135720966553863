import React from 'react';
import { FlightButton } from '@flybits/design-system';
import { useHistory, useRouteMatch } from 'react-router-dom';

import CustomModuleAnalyticsProvider from './context/CustomModuleAnalyticsContextProvider';
import { CustomModuleAnalyticsContent } from './CustomModuleAnalyticsContent/CustomModuleAnalyticsContent';
import './CustomModuleAnalytics.scss';

interface RouteMatch {
  pid?: string;
  moduleID?: string;
}

export default function CustomModuleAnalytics() {
  const DEFAULT_CLASS = 'custom-module-analytics';
  const history = useHistory();
  const { pid, moduleID }: RouteMatch = useRouteMatch().params;
  const pageTitle = moduleID ? 'Edit widget' : 'New widget';

  const handleCancel = () => {
    history.push(`/project/${pid}/analytics`);
  };

  return (
    <div className={DEFAULT_CLASS}>
      <div className={`${DEFAULT_CLASS}__header`}>
        <FlightButton
          theme="minor"
          iconLeft="baselineKeyboardArrowLeft"
          onClick={handleCancel}
          ariaLabel="back button"
          className={`${DEFAULT_CLASS}__header__back`}
        />
        <h2 className={`${DEFAULT_CLASS}__header__title`}>{pageTitle}</h2>
      </div>
      <CustomModuleAnalyticsProvider>
        <CustomModuleAnalyticsContent moduleID={moduleID} />
      </CustomModuleAnalyticsProvider>
    </div>
  );
}
