import React from 'react';

// Custom components
import BreadcrumbContainer from './BreadcrumbContainer/BreadcrumbContainer';
import PrimaryHeader from './PrimaryHeader/PrimaryHeader';
import ActionsHeader from './ActionsHeader/ActionsHeader';

// Types
import { JourneyState } from 'store/journey/journey.type';

// Stylings
import './HeaderLayout.scss';

type HeaderLayoutType = {
  journeyInstance: JourneyState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: (showLoading?: any) => Promise<void>;
};

const HeaderLayout = ({ journeyInstance, refetch }: HeaderLayoutType): JSX.Element | null => {
  const primaryHeaderProps = {
    journeyInstance,
    refetch,
  };

  return (
    <>
      <BreadcrumbContainer />
      <PrimaryHeader {...primaryHeaderProps} />
      <ActionsHeader journeyInstance={journeyInstance} />
    </>
  );
};

export default HeaderLayout;
