import { IconProps } from './icon.types';
import React from 'react';

function IconPush(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1531 14.1403C21.4729 14.1403 22.5426 13.0705 22.5426 11.7508C22.5426 10.4311 21.4729 9.36133 20.1531 9.36133C18.8335 9.36133 17.7637 10.4311 17.7637 11.7508C17.7637 13.0705 18.8335 14.1403 20.1531 14.1403Z"
        stroke="black"
        strokeWidth="2.34755"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.32013 32.4596C10.6398 32.4596 11.7096 31.3899 11.7096 30.0701C11.7096 28.7505 10.6398 27.6807 9.32013 27.6807C8.00046 27.6807 6.93066 28.7505 6.93066 30.0701C6.93066 31.3899 8.00046 32.4596 9.32013 32.4596Z"
        stroke="black"
        strokeWidth="2.34755"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.826 32.4596C32.1456 32.4596 33.2155 31.3899 33.2155 30.0701C33.2155 28.7505 32.1456 27.6807 30.826 27.6807C29.5064 27.6807 28.4365 28.7505 28.4365 30.0701C28.4365 31.3899 29.5064 32.4596 30.826 32.4596Z"
        stroke="black"
        strokeWidth="2.34755"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7693 35.327C28.6367 38.1944 33.097 38.1944 35.9644 35.327C38.8318 32.4596 38.8318 27.9993 35.9644 25.1319C33.5749 22.7425 29.9111 22.4239 27.203 24.0168L21.1924 13.8936"
        stroke="black"
        strokeWidth="2.34755"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.37 23.2205C4.50263 24.0169 2.27246 26.8843 2.27246 30.0703C2.27246 34.0527 5.45842 37.2387 9.44087 37.2387C13.4233 37.2387 16.6093 34.0527 16.6093 30.0703H28.4464"
        stroke="black"
        strokeWidth="2.34755"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.162 13.6623C27.6399 11.2729 27.0027 8.72409 25.2504 6.97181C22.3831 4.10445 17.9227 4.10445 15.0554 6.97181C11.8694 10.3171 12.3473 15.7332 16.489 18.282L10.7773 28.1616"
        stroke="black"
        strokeWidth="2.34755"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconPush;
