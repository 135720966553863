import './WebhookSidePanel.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WebhookForm, { WebhookFormValues } from 'components/FlowBuilder/FlowSidePanels/WebhookSidePanel/WebhookForm';

import IconWebhook from 'pages/FlowBuilder/assets/icons/IconWebhook';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import { WEBHOOK_INIT } from 'constants/webhook.constants';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { WebhookActionTypes } from 'store/webhook/webhook.type';

const MAIN_CLASS = 'webhook-slide-panel';

const INIT_FORM_VALUES = {
  name: '',
  description: '',
  method: '',
  url: '',
  headers: [{ key: '', value: '' }],
  payload: '',
  certificateName: '',
};

function WebhookSidePanel() {
  const dispatch = useDispatch();
  const flowBuilderContext = useFlowBuilderContext();
  const flowWebhookContext = flowBuilderContext.sidePanels.webhook;
  const isViewOnly = flowWebhookContext.isViewOnly || false;
  const webhook = flowWebhookContext.webhook || WEBHOOK_INIT;
  const stepIdx = flowWebhookContext.stepIndex;

  const [webhookFormValues, setWebhookFormValues] = useState<WebhookFormValues>(INIT_FORM_VALUES);

  const onClose = useCallback(() => {
    flowWebhookContext.setWebhookContext({ isOpen: false, webhook });
  }, [flowWebhookContext, webhook]);

  const onSave = useCallback(() => {
    const { headers, payload, ...webhookPayload } = webhookFormValues;

    const headersMap: { [key: string]: string } = {};
    for (const header of headers) {
      headersMap[header.key] = header.value;
    }

    const jsonPayload = JSON.parse(payload);

    const updateWebhookPayload = {
      ...webhookPayload,
      headers: headersMap,
      payload: jsonPayload,
    };

    dispatch({
      type: WebhookActionTypes.SET_WEBHOOK,
      payload: {
        refId: webhook.refId,
        stepIdx,
        webhook: updateWebhookPayload,
      },
    });

    onClose();
  }, [webhookFormValues, dispatch, stepIdx, webhook.refId, onClose]);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(() => {
    const sidePanelProps: TSlidingSidePanelProps = {
      show: flowWebhookContext.isOpen,
      headerInfo: {
        mainTitle: '',
        goBackIcon: <IconWebhook />,
        goBackTitle: 'Webhook',
        goBackActionHandler: onClose,
      },
      footerInfo: {
        secondaryActionHandler: onClose,
        secondaryActionText: 'Cancel',
      },
      size: 'large',
    };

    if (!isViewOnly) {
      sidePanelProps.footerInfo = {
        ...sidePanelProps.footerInfo,
        primaryActionHandler: onSave,
        primaryActionText: 'Save Changes',
      };
    }
    return sidePanelProps;
  }, [onClose, onSave, flowWebhookContext.isOpen, isViewOnly]);

  useEffect(() => {
    if (!webhook.id) {
      return;
    }

    const { name, description, method, url, headers, payload, certificateName } = webhook;

    const headersArray: { key: string; value: string }[] = [];
    for (const key in headers) {
      headersArray.push({ key, value: headers[key] });
    }

    setWebhookFormValues({
      name,
      description,
      method,
      url,
      headers: headersArray,
      payload: JSON.stringify(payload, null, 2),
      certificateName,
    });
  }, [webhook]);

  return (
    <div className={MAIN_CLASS}>
      <SlidingSidePanel {...slidingSidePanelProps}>
        <WebhookForm isViewOnly={isViewOnly} formValues={webhookFormValues} setFormValues={setWebhookFormValues} />
      </SlidingSidePanel>
    </div>
  );
}

export default WebhookSidePanel;
