import React from 'react';
import { FlightButton, FlightModal } from '@flybits/design-system';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import IncompleteIcon from 'pages/ExperienceCanvas/assets/icons/IncompleteIcon';
import ErrorIcon from 'pages/ExperienceCanvas/assets/icons/ErrorIcon';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { ActionTypes } from 'store/actionTypes';
import { validateStep } from 'validators/ExperienceCanvas/journey.validator';
import { getImageUrl } from 'helpers/common.helper';
import { getBackendJourneyErrors } from 'helpers/error.helper';

import './ErrorListModal.scss';

export type TErrorListModal = {
  stepIdx?: number;
  isVisible: boolean;
  onClose: () => void;
};

export default function ErrorListModal(props: TErrorListModal) {
  const { stepIdx, isVisible, onClose } = props;

  const dispatch = useDispatch();
  const { errors } = useSelector((state) => {
    if (stepIdx !== undefined) return validateStep(state, stepIdx);
    const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
    const { topLevelErrors } = getBackendJourneyErrors(beErrors, state);
    return { errors: topLevelErrors };
  });
  const getIcon = (status: ERROR_TYPES) => {
    switch (status) {
      case ERROR_TYPES.INCOMPLETE:
        return <IncompleteIcon className="error-list-modal__list-row-icon" fill="#FFB005" />;
      default:
        return <ErrorIcon className="error-list-modal__list-row-icon" fill="#FF6E46" />;
    }
  };

  return (
    <FlightModal
      className="error-list-modal"
      size="medium"
      isVisible={isVisible}
      scrollable={true}
      toggleModalShown={onClose}
      header={
        <>
          <img src={getImageUrl('warning.svg')} className="error-list-modal__header-icon" alt="header icon" />
          <h3>{stepIdx === undefined ? 'General' : `Touchpoint ${stepIdx + 1}`} Errors</h3>
        </>
      }
      content={
        <div className="error-list-modal__list-wrapper">
          {errors.map((err, idx) => {
            return (
              <div className="error-list-modal__list-row" key={`error-modal.err.${idx}`}>
                {getIcon(err.type)}
                <div className="error-list-modal__list-row-text">{err.err}</div>
                {(!!err.stateIdx || err.stateIdx === 0) && (
                  <FlightButton
                    className="error-list-modal__list-row-clear"
                    theme="minor"
                    iconLeft="clear"
                    ariaLabel="dismiss error"
                    onClick={() => {
                      dispatch({
                        type: ActionTypes.CLEAR_ERROR,
                        payload: {
                          errorIdx: err.stateIdx,
                        },
                      });
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      }
      footer={
        <div className="error-list-modal__footer">
          <FlightButton onClick={onClose} label="Close" />
        </div>
      }
    />
  );
}
