import React from 'react';
import { IconProps } from './icon.types';

function IconContentFlow(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1528_108745)">
        <path
          d="M22.6666 0H9.33325C8.27239 0 7.25497 0.421427 6.50482 1.17157C5.75468 1.92172 5.33325 2.93913 5.33325 4V28C5.33325 29.0609 5.75468 30.0783 6.50482 30.8284C7.25497 31.5786 8.27239 32 9.33325 32H22.6666C23.7275 32 24.7449 31.5786 25.495 30.8284C26.2452 30.0783 26.6666 29.0609 26.6666 28V4C26.6666 2.93913 26.2452 1.92172 25.495 1.17157C24.7449 0.421427 23.7275 0 22.6666 0ZM23.9999 25.3333C23.9999 25.5101 23.9297 25.6797 23.8047 25.8047C23.6796 25.9298 23.5101 26 23.3333 26H8.66659C8.57904 26 8.49235 25.9828 8.41146 25.9493C8.33058 25.9157 8.25709 25.8666 8.19518 25.8047C8.13327 25.7428 8.08417 25.6693 8.05067 25.5885C8.01716 25.5076 7.99992 25.4209 7.99992 25.3333V4.66667C7.99992 4.48986 8.07016 4.32029 8.19518 4.19526C8.3202 4.07024 8.48977 4 8.66659 4H23.3333C23.5101 4 23.6796 4.07024 23.8047 4.19526C23.9297 4.32029 23.9999 4.48986 23.9999 4.66667V25.3333Z"
          fill="#1194DA"
        />
        <path
          d="M20.68 14.5734C20.5628 14.4039 20.4078 14.264 20.2274 14.1645C20.0469 14.065 19.8459 14.0087 19.64 14C19.4352 13.9916 19.2313 14.0306 19.0439 14.1138C18.8566 14.1971 18.691 14.3224 18.56 14.48L16.3467 17.3334L13.9734 15.52C13.7033 15.3598 13.3854 15.3001 13.0757 15.3513C12.7659 15.4026 12.4841 15.5614 12.28 15.8L10.3067 18.9867C10.245 19.088 10.2115 19.204 10.2096 19.3227C10.2077 19.4414 10.2375 19.5584 10.2959 19.6617C10.3543 19.7649 10.4393 19.8508 10.542 19.9102C10.6447 19.9697 10.7614 20.0007 10.88 20H22C22.1109 20.0004 22.2202 19.9731 22.3179 19.9206C22.4155 19.8682 22.4986 19.7921 22.5595 19.6995C22.6204 19.6068 22.6572 19.5004 22.6666 19.3899C22.676 19.2794 22.6577 19.1683 22.6134 19.0667L20.68 14.5734Z"
          fill="#1194DA"
        />
        <path
          d="M10.3467 10C10.3467 10.5304 10.5574 11.0391 10.9325 11.4142C11.3075 11.7893 11.8162 12 12.3467 12C12.8771 12 13.3858 11.7893 13.7609 11.4142C14.136 11.0391 14.3467 10.5304 14.3467 10C14.3467 9.46957 14.136 8.96086 13.7609 8.58579C13.3858 8.21071 12.8771 8 12.3467 8C11.8162 8 11.3075 8.21071 10.9325 8.58579C10.5574 8.96086 10.3467 9.46957 10.3467 10Z"
          fill="#1194DA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1528_108745">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconContentFlow;
