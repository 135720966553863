import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import './MapContainer.scss';
import { FlightButton } from '@flybits/design-system';
import { LatLng } from 'interface/location/location.interface';

const mapBaseZoom = 11;
const tristanDaCunha = { lat: -37.111112, lng: -12.28834 };
const locationDetectionErrorMsg =
  'Failed to detect location using Google Maps. Please check if your browser has location permission and reload the page.';
type MapContainerProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locationCoordinates: any[];
};
export const MapContainer = ({ locationCoordinates }: MapContainerProps) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<google.maps.Map>();
  const [isDetectingLocation, setIsDetectingLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<LatLng>();

  const mapRef = useRef<HTMLDivElement>(null);
  const centreMapControl = useRef<HTMLDivElement>(null);

  const initMap = useCallback(() => {
    const map = new google.maps.Map(mapRef.current as HTMLDivElement, {
      center: tristanDaCunha,
      zoom: mapBaseZoom,
      mapTypeId: 'roadmap',
      gestureHandling: 'greedy',
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: true /* To be replaced with custom zoom controls */,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    });
    centreMapControl.current && map.controls[google.maps.ControlPosition.RIGHT_TOP].push(centreMapControl.current);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const markers = locationCoordinates.map((position: any) => {
      const marker = new google.maps.Marker({
        position,
      });
      return marker;
    });

    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({ markers, map });
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < locationCoordinates.length; i++) {
      bounds.extend(locationCoordinates[i]);
    }
    map.fitBounds(bounds);
    setMap(map);
  }, [locationCoordinates]);

  const detectCurrentLocation = useCallback(() => {
    if (window.navigator && window.navigator.geolocation) {
      setIsDetectingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (geoposition) => {
          setCurrentLocation({
            lat: geoposition.coords.latitude,
            lng: geoposition.coords.longitude,
          });
          setIsDetectingLocation(false);
        },
        () => {
          setCurrentLocation(tristanDaCunha);
          setIsDetectingLocation(false);

          dispatch({
            type: 'SHOW_SNACKBAR',
            payload: { content: locationDetectionErrorMsg, type: 'error' },
          });
        },
      );
    } else {
      setIsDetectingLocation(false);
      setCurrentLocation(tristanDaCunha);
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: locationDetectionErrorMsg, type: 'error' },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useEffect(() => {
    if (map) {
      detectCurrentLocation();
    }
  }, [map, detectCurrentLocation]);

  useEffect(() => {
    if (map && currentLocation) {
      map.setCenter(currentLocation);
      map.setZoom(mapBaseZoom);
    }
  }, [map, currentLocation]);

  return (
    <div className="map-container" ref={mapRef}>
      <div className="map-container__map-control" ref={centreMapControl}>
        <FlightButton
          className={`
            map-container__map-control__btn${
              !isDetectingLocation ? ' map-container__map-control__btn--not-loading' : ''
            }
          `}
          ariaLabel="Go to current location"
          onClick={() => {
            detectCurrentLocation();
          }}
          loading={isDetectingLocation}
          theme="minor"
          iconLeft="mapCrosshairs"
        />
      </div>
    </div>
  );
};
