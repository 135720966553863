import React from 'react';
import { IconProps } from './icon.types';

function IconContentMultiPage(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.751221" y="0.8125" width="20.3735" height="14.1047" rx="7.05236" fill="#0851DF" />
      <g clipPath="url(#clip0_1584_84672)">
        <path
          d="M9.79545 10.1507H14.5297C14.6163 10.1507 14.6993 10.1851 14.7605 10.2463C14.8218 10.3076 14.8562 10.3906 14.8562 10.4772C14.8562 10.5638 14.8218 10.6469 14.7605 10.7081C14.6993 10.7693 14.6163 10.8037 14.5297 10.8037H9.79545C9.70886 10.8037 9.62582 10.7693 9.56459 10.7081C9.50335 10.6469 9.46896 10.5638 9.46896 10.4772C9.46896 10.3906 9.50335 10.3076 9.56459 10.2463C9.62582 10.1851 9.70886 10.1507 9.79545 10.1507Z"
          fill="white"
        />
        <path
          d="M14.5297 8.19141H9.79548C9.70889 8.19141 9.62585 8.15701 9.56462 8.09578C9.50339 8.03455 9.46899 7.9515 9.46899 7.86491C9.46899 7.77832 9.50339 7.69527 9.56462 7.63404C9.62585 7.57281 9.70889 7.53841 9.79548 7.53841H14.5297C14.6163 7.53841 14.6993 7.57281 14.7606 7.63404C14.8218 7.69527 14.8562 7.77832 14.8562 7.86491C14.8562 7.9515 14.8218 8.03455 14.7606 8.09578C14.6993 8.15701 14.6163 8.19141 14.5297 8.19141Z"
          fill="white"
        />
        <path
          d="M14.5297 5.58008H9.79548C9.70889 5.58008 9.62585 5.54568 9.56462 5.48445C9.50339 5.42322 9.46899 5.34017 9.46899 5.25358C9.46899 5.16699 9.50339 5.08394 9.56462 5.02271C9.62585 4.96148 9.70889 4.92708 9.79548 4.92708H14.5297C14.6163 4.92708 14.6993 4.96148 14.7606 5.02271C14.8218 5.08394 14.8562 5.16699 14.8562 5.25358C14.8562 5.34017 14.8218 5.42322 14.7606 5.48445C14.6993 5.54568 14.6163 5.58008 14.5297 5.58008Z"
          fill="white"
        />
        <path
          d="M7.50959 10.9658H8.16259C8.16259 10.9658 8.32584 10.9658 8.32584 10.8026V10.1496C8.32584 10.1496 8.32584 9.98633 8.16259 9.98633H7.50959C7.50959 9.98633 7.34634 9.98633 7.34634 10.1496V10.8026C7.34634 10.8026 7.34634 10.9658 7.50959 10.9658Z"
          fill="white"
        />
        <path
          d="M8.16276 11.293H7.50976C7.37987 11.293 7.2553 11.2414 7.16346 11.1495C7.07161 11.0577 7.02001 10.9331 7.02001 10.8032V10.1502C7.02001 10.0203 7.07161 9.89577 7.16346 9.80392C7.2553 9.71208 7.37987 9.66048 7.50976 9.66048H8.16276C8.22707 9.66048 8.29076 9.67315 8.35017 9.69776C8.40959 9.72237 8.46358 9.75844 8.50906 9.80392C8.55454 9.8494 8.59061 9.90339 8.61522 9.96281C8.63984 10.0222 8.6525 10.0859 8.6525 10.1502V10.8032C8.6525 10.9331 8.60091 11.0577 8.50906 11.1495C8.41721 11.2414 8.29264 11.293 8.16276 11.293ZM8.326 10.1502C8.326 10.1069 8.30881 10.0654 8.27819 10.0348C8.24758 10.0042 8.20605 9.98698 8.16276 9.98698H7.50976C7.46646 9.98698 7.42494 10.0042 7.39432 10.0348C7.36371 10.0654 7.34651 10.1069 7.34651 10.1502V10.8032C7.34651 10.8465 7.36371 10.888 7.39432 10.9187C7.42494 10.9493 7.46646 10.9665 7.50976 10.9665H8.16276C8.20605 10.9665 8.24758 10.9493 8.27819 10.9187C8.30881 10.888 8.326 10.8465 8.326 10.8032V10.1502Z"
          fill="white"
        />
        <path
          d="M7.50959 8.35547H8.16259C8.16259 8.35547 8.32584 8.35547 8.32584 8.19222V7.53922C8.32584 7.53922 8.32584 7.37597 8.16259 7.37597H7.50959C7.50959 7.37597 7.34634 7.37597 7.34634 7.53922V8.19222C7.34634 8.19222 7.34634 8.35547 7.50959 8.35547Z"
          fill="white"
        />
        <path
          d="M8.16276 8.68066H7.50976C7.37987 8.68066 7.2553 8.62907 7.16346 8.53722C7.07161 8.44537 7.02001 8.32081 7.02001 8.19092V7.53792C7.02001 7.40803 7.07161 7.28346 7.16346 7.19162C7.2553 7.09977 7.37987 7.04817 7.50976 7.04817H8.16276C8.22707 7.04817 8.29076 7.06084 8.35017 7.08545C8.40959 7.11006 8.46358 7.14614 8.50906 7.19162C8.55454 7.23709 8.59061 7.29108 8.61522 7.3505C8.63984 7.40992 8.6525 7.47361 8.6525 7.53792V8.19092C8.6525 8.32081 8.60091 8.44537 8.50906 8.53722C8.41721 8.62907 8.29264 8.68066 8.16276 8.68066ZM8.326 7.53792C8.326 7.49462 8.30881 7.4531 8.27819 7.42249C8.24758 7.39187 8.20605 7.37467 8.16276 7.37467H7.50976C7.46646 7.37467 7.42494 7.39187 7.39432 7.42249C7.36371 7.4531 7.34651 7.49462 7.34651 7.53792V8.19092C7.34651 8.23421 7.36371 8.27574 7.39432 8.30635C7.42494 8.33697 7.46646 8.35417 7.50976 8.35417H8.16276C8.20605 8.35417 8.24758 8.33697 8.27819 8.30635C8.30881 8.27574 8.326 8.23421 8.326 8.19092V7.53792Z"
          fill="white"
        />
        <path
          d="M7.50959 5.74316H8.16259C8.16259 5.74316 8.32584 5.74316 8.32584 5.57992V4.92692C8.32584 4.92692 8.32584 4.76367 8.16259 4.76367H7.50959C7.50959 4.76367 7.34634 4.76367 7.34634 4.92692V5.57992C7.34634 5.57992 7.34634 5.74316 7.50959 5.74316Z"
          fill="white"
        />
        <path
          d="M8.16276 6.06836H7.50976C7.37987 6.06836 7.2553 6.01676 7.16346 5.92492C7.07161 5.83307 7.02001 5.7085 7.02001 5.57861V4.92562C7.02001 4.79573 7.07161 4.67116 7.16346 4.57931C7.2553 4.48747 7.37987 4.43587 7.50976 4.43587H8.16276C8.22707 4.43587 8.29076 4.44854 8.35017 4.47315C8.40959 4.49776 8.46358 4.53383 8.50906 4.57931C8.55454 4.62479 8.59061 4.67878 8.61522 4.7382C8.63984 4.79762 8.6525 4.8613 8.6525 4.92562V5.57861C8.6525 5.7085 8.60091 5.83307 8.50906 5.92492C8.41721 6.01676 8.29264 6.06836 8.16276 6.06836ZM8.326 4.92562C8.326 4.88232 8.30881 4.8408 8.27819 4.81018C8.24758 4.77957 8.20605 4.76237 8.16276 4.76237H7.50976C7.46646 4.76237 7.42494 4.77957 7.39432 4.81018C7.36371 4.8408 7.34651 4.88232 7.34651 4.92562V5.57861C7.34651 5.62191 7.36371 5.66343 7.39432 5.69405C7.42494 5.72466 7.46646 5.74186 7.50976 5.74186H8.16276C8.20605 5.74186 8.24758 5.72466 8.27819 5.69405C8.30881 5.66343 8.326 5.62191 8.326 5.57861V4.92562Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1584_84672">
          <rect width="7.83596" height="7.83596" fill="white" transform="matrix(1 0 0 -1 7.02018 11.7822)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconContentMultiPage;
