import React from 'react';
import { FlightButton } from '@flybits/design-system';
import { ReactComponent as MerchantEmptyImage } from 'assets/images/merchant-empty.svg';
import './MerchantEmpty.scss';
import {
  MERCHANT_EMPTY_PAGE_BUTTON_LABELS,
  MERCHANT_EMPTY_PAGE_TEXTS,
} from 'pages/MerchantPortal/merchant-portal.constants';
import { useHistory, useParams } from 'react-router-dom';
import { MerchantsRouteParams } from 'pages/MerchantPortal/merchant-portal.types';

const CLASS_MAIN = 'merchant-portal';
const CLASSES = {
  CONTAINER: `${CLASS_MAIN}__container`,
  BODY: `${CLASS_MAIN}__content-empty`,
  MERCHANT_ADD_BUTTON: `${CLASS_MAIN}__merchant-add-button`,
};
function MerchantEmpty() {
  const history = useHistory();
  const { pid } = useParams<MerchantsRouteParams>();
  const goToAddMerchant = () => {
    history.push(`/project/${pid}/register-merchant`);
  };
  return (
    <div className={CLASSES.CONTAINER}>
      <main className={CLASSES.BODY}>
        <MerchantEmptyImage />
        <h3>{MERCHANT_EMPTY_PAGE_TEXTS.HEADING}</h3>
        <span>{MERCHANT_EMPTY_PAGE_TEXTS.TITLE}</span>
        <p>{MERCHANT_EMPTY_PAGE_TEXTS.DESCRIPTION}</p>
        <FlightButton
          theme="primary"
          onClick={goToAddMerchant}
          label={MERCHANT_EMPTY_PAGE_BUTTON_LABELS.ADD_MERCHANT}
          iconLeft="add"
          className={CLASSES.MERCHANT_ADD_BUTTON}
        />
      </main>
    </div>
  );
}

export default MerchantEmpty;
