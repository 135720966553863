import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlightNumberInput, FlightSelect } from '@flybits/design-system';
import { RuleBuilderContentAnalyticsProps, RuleContainer, RulePreview } from '@flybits/webapp-react-rule-builder';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { EVENT_PERIODS } from 'components/ExperienceCanvas/constants';
import { genBeforeAfterPayload, getTimeCtxPluginAttributes } from 'helpers/rule.helper';
import { convertPeriodToSeconds, convertSecondsToPeriod } from 'helpers/templated-experience.helper';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import useContentAnalytics, { useContentAnalyticsComponents } from 'hooks/useContentAnalytics';
import useContentInstances from 'hooks/useContentInstances';
import usePlugins from 'hooks/useContextPlugins';
import useLocations from 'hooks/useLocations';
import { LocationPayload } from 'interface/location/location.interface';
import { RuleBuilderAudience } from 'interface/rule/rule.interface';
import TriggerIcon from 'pages/ExperienceCanvas/assets/icons/TriggerIcon';
import { SaveContextualSchedulerAction } from 'store/actionTypes';
import { getStepRuleSyntheticId } from 'store/helper';
import { fetchMatchingLocationsWithLabelsThunk, saveLocationThunk } from 'store/location/location.thunk';
import { saveContextualSchedulerAction } from 'store/rule/rule.action';
import { getIsAudienceEmpty } from 'store/rule/rule.selector';
import { RuleStateItem } from 'store/rule/rule.type';
import { serializeRule } from 'store/serializers';
import LabelsAPI from 'services/api/labels.api';
import PluginAPI from 'services/api/plugin.api';
import { SelectOption } from 'types/common';
import TimeContextForm from '../TimeContextForm';
import { CLASSES } from '../classes';
import { PUSH_ACTION_TYPES } from 'store/push/push.type';

type TEventSelectorSectionProps = {
  stepIdx: number;
  startOrEnd: 'start' | 'end';
};

const preconditionRules: SelectOption = {
  key: 'precondition-rules',
  name: 'After a set of preconditions to each target audience member',
};
const beforeUniqueEvent: SelectOption = {
  key: 'before-unique-event',
  name: 'Before a unique event to each target audience member',
};
const afterUniqueEvent: SelectOption = {
  key: 'after-unique-event',
  name: 'After a unique event to each target audience member',
};
const eventConditionOptions: SelectOption[] = [preconditionRules, beforeUniqueEvent, afterUniqueEvent];

const MAIN_CLASS = 'event-selector-section';

const EventSelectorSection: React.FC<TEventSelectorSectionProps> = ({ stepIdx, startOrEnd }) => {
  const labelsAPI = useMemo(() => new LabelsAPI(), []);
  const pluginAPI = useMemo(() => new PluginAPI(), []);

  const dispatch = useDispatch();
  const [contentInstanceSearchVal, setContentInstanceSearchVal] = useState('');
  const {
    locations,
    areLocationsLoading: areLocationsWaiting,
    numMatchingLocations,
    areMatchingLocationsLoading,
    isLocationSaving,
  } = useLocations();
  const { plugins } = usePlugins();
  const { contentInstances } = useContentInstances();
  const {
    isLoading,
    isFetchingNextPage,
    contentInstances: contentAnalyticsSuggestionList,
    fetchNextContentPage,
    isError,
    serverErrorMessage,
  } = useContentAnalytics(contentInstanceSearchVal);
  const [componentHookProps, setComponentHookProps] = useState({
    contentId: '',
    uid: '',
    nodeId: '',
  });
  const { isLoading: areComponentsLoading, ...componentsObject } = useContentAnalyticsComponents(
    componentHookProps.contentId,
    componentHookProps.uid,
  );

  const isAudienceEmpty = useSelector((state) => getIsAudienceEmpty(state.te, stepIdx));
  const stateScheduler = useSelector((state) => {
    const synthRuleId = getStepRuleSyntheticId(stepIdx);
    return startOrEnd === 'start'
      ? state.te.rule.startSchedulerMap[synthRuleId]
      : state.te.rule.endSchedulerMap[synthRuleId];
  });
  const templateType = useSelector((state) => state.te.journey.templateType);

  // ToDo: Maybe move the main rule and scheduler rule/context extraction logic
  // to a generic function / hook
  const { memoizedModifier, memoizedDelay, memoizedTarget, memoizedSchedulerRule } = useMemo(() => {
    const serializedSchedulerRule = serializeRule(stateScheduler as RuleStateItem);
    const ruleDelay = Number(stateScheduler?.modifier);
    const convertedRuleDelay =
      Number.isNaN(ruleDelay) || ruleDelay < 60 ? undefined : convertSecondsToPeriod(ruleDelay);
    return {
      memoizedModifier: stateScheduler?.modifier?.split(' '),
      memoizedDelay: convertedRuleDelay,
      memoizedTarget: stateScheduler?.target,
      memoizedSchedulerRule: serializedSchedulerRule,
    };
  }, [stateScheduler]);

  // temporary workaround for the td use case, should be moved to rule builder project and handled there
  const shouldUseTemporaryRuleBuilder = Object.keys(memoizedSchedulerRule?.payload?.predicates || {}).some((key) => {
    if (memoizedSchedulerRule?.payload?.predicates?.[key]?.parameters?.[0].attribute?.includes('$')) {
      return true;
    }
  });

  // state
  const [delayValue, setDelayValue] = useState(memoizedDelay?.value ?? '');
  const [delayPeriod, setDelayPeriod] = useState(
    memoizedDelay?.period
      ? EVENT_PERIODS.find((period) => period.key === memoizedDelay.period) || EVENT_PERIODS[1]
      : EVENT_PERIODS[0],
  );
  const [filteredEventConditionOptions, setFilteredEventConditionOptions] = useState<SelectOption[]>();
  const [isPreconditionSelectDisabled, setPreconditionSelectDisabled] = useState(true);
  const [eventCondition, setEventCondition] = useState<SelectOption>();
  const [waitingNodeId, setWaitingNodeId] = useState('');
  const [areLocationsLabelsWaiting, setAreLocationsLabelsWaiting] = useState({
    waitingNodeId: '',
    isWaiting: false,
  });
  const [contextLabels, setContextLabels] = useState<string[]>([]);
  const [historicalQueries, setHistoricalQueries] = useState([]);
  const [isRuleBuilderOpen, setRuleBuilderOpen] = useState(false);
  const [isSerializeUiModel, setIsSerializeUiModel] = useState(false);
  const [matchingLocations, setMatchingLocations] = useState({
    matchingLocationsNodeId: '',
    numMatchingLocations: 0,
  });
  const [timeAttributes, setTimeAttributes] = useState(getTimeCtxPluginAttributes(plugins));

  const saveAndDispatchRuleModifier = (value?: string | number, period?: string) => {
    if (!period) return;

    const modifier = convertPeriodToSeconds(value || 0, period).toString() || '';

    const saveSchedulerRulePayload = {
      stepIdx,
      type: startOrEnd,
      modifier,
      target: 'now',
    };

    dispatch(saveContextualSchedulerAction(saveSchedulerRulePayload));
  };

  const handleDelayPeriodChange = (option: (typeof EVENT_PERIODS)[0]) => {
    // if (!option.key) return;
    if (option.key === delayPeriod.key) return;
    let newDelayValue = delayValue;
    if (delayPeriod.key === EVENT_PERIODS[0].key) {
      newDelayValue = 1;
      setDelayValue(1);
    }
    if (option.key === EVENT_PERIODS[0].key) {
      newDelayValue = 0;
      setDelayValue(0);
    }
    setDelayPeriod(option);
    saveAndDispatchRuleModifier(newDelayValue, option.key);
  };

  const handleContentSearch = useCallback((searchVal: string) => {
    setContentInstanceSearchVal(searchVal);
  }, []);

  const handleComponentsDropdownFocus = useCallback((nodeId: string, contentId: string, uid: string) => {
    setComponentHookProps({ contentId, uid, nodeId });
  }, []);

  const memoizedContentAnalyticsProps: RuleBuilderContentAnalyticsProps = useMemo(
    () => ({
      contentData: {
        data: {
          options: contentAnalyticsSuggestionList,
          width: '600px',
          ariaControlsId: 'contentAnalyticsSuggestionBox',
          noDataAvailable: false,
          loading: isLoading || isFetchingNextPage,
          disabled: false,
          isServerError: isError,
          clearOnClickOutside: true,
          content: {
            errorMessage: 'Select content instance from dropdown',
            serverErrorMsg: {
              text: serverErrorMessage || 'Could not load content',
            },
          },
        },
        methods: {
          handleContentSearch,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleContentOptionClick: () => {},
          fetchNextContentPage,
        },
      },
      componentData: {
        nodeId: componentHookProps.nodeId,
        data: {
          options: componentsObject.data,
          width: '600px',
          ariaControlsId: 'contentAnalyticsComponentsSuggestionBox',
          noDataAvailable: false,
          loading: areComponentsLoading,
          disabled: false,
          isServerError: componentsObject.isError,
          clearOnClickOutside: true,
          content: {
            errorMessage: 'Select component from dropdown',
            serverErrorMsg: {
              text: 'Could not load content',
            },
          },
        },
        methods: {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleComponentSearch: () => {},
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handleComponentOptionClick: () => {},
        },
      },
      handleComponentsDropdownFocus,
    }),
    [
      contentAnalyticsSuggestionList,
      handleContentSearch,
      fetchNextContentPage,
      isError,
      serverErrorMessage,
      isLoading,
      isFetchingNextPage,
      componentHookProps,
      componentsObject,
      areComponentsLoading,
      handleComponentsDropdownFocus,
    ],
  );

  const handleEditPreconditionRule = useCallback(() => {
    if (!isPreconditionSelectDisabled) setRuleBuilderOpen(true);
  }, [isPreconditionSelectDisabled, setRuleBuilderOpen]);

  const handleEventConditionChange = (option: SelectOption) => {
    // for now, switching precondition types will wipe.  If we want to preserve,
    // we can split out the fields for each and just reserialize on POST/PUT
    if (option?.key === eventCondition?.key) return;
    if (option?.key === 'precondition-rules') {
      const modifier = convertPeriodToSeconds(delayValue, delayPeriod.key).toString() || '';

      const saveSchedulerRulePayload = {
        stepIdx,
        type: startOrEnd,
        modifier,
        logic: '',
        predicates: null,
        target: 'now',
      };
      dispatch(saveContextualSchedulerAction(saveSchedulerRulePayload));
      setRuleBuilderOpen(true);
    }
    if (option?.key === 'before-unique-event' || option?.key === 'after-unique-event') {
      const saveSchedulerRulePayload = genBeforeAfterPayload(
        stepIdx,
        startOrEnd,
        1,
        EVENT_PERIODS[1].key,
        timeAttributes[0].key,
        timeAttributes[0].ctxParams.map(() => ''),
        option.key,
      );
      dispatch(saveContextualSchedulerAction(saveSchedulerRulePayload));
    }
    setEventCondition(option);
  };

  const handleLocationLabelSelection = async (nodeId: string, labelsFormula: string) => {
    setWaitingNodeId(nodeId);

    dispatch(fetchMatchingLocationsWithLabelsThunk(labelsFormula));
  };

  const handleSaveLocation = async (locationPayload: LocationPayload, callback: () => void, locationId?: string) => {
    dispatch(saveLocationThunk(locationPayload, callback, locationId));
  };

  const handleCloseRuleBuilder = useCallback(() => {
    setRuleBuilderOpen(false);
    setIsSerializeUiModel(false);
  }, []);

  const handleSaveRuleBuilder = useCallback(() => {
    setIsSerializeUiModel(true);
  }, []);

  const saveRuleBuilderPredicates = useCallback(
    (currentRule: RuleBuilderAudience) => {
      const saveSchedulerRulePayload: SaveContextualSchedulerAction['payload'] = {
        stepIdx,
        type: startOrEnd,
        target: 'now',
        logic: currentRule?.payload?.logic || '',
        predicates: currentRule?.payload?.predicates || null,
      };

      dispatch(saveContextualSchedulerAction(saveSchedulerRulePayload));

      setRuleBuilderOpen(false);
      setIsSerializeUiModel(false);
    },
    [stepIdx, dispatch, startOrEnd],
  );

  const slidingSidePanelProps: TSlidingSidePanelProps = {
    show: isRuleBuilderOpen,
    headerInfo: {
      mainTitle: 'Rule Builder',
      goBackIcon: <TriggerIcon fill="#ffffff" />,
      goBackTitle: 'Triggers',
      goBackSubTitle: 'Set up preconditions',
      goBackActionHandler: handleCloseRuleBuilder,
    },
    footerInfo: {
      primaryActionText: 'Save',
      secondaryActionText: 'Cancel',
      primaryActionHandler: handleSaveRuleBuilder,
      secondaryActionHandler: handleCloseRuleBuilder,
    },
  };

  const isAppContentTemplate = useSelector((state) => {
    const contentRefIds = state.te.journey.steps[stepIdx]?.content;
    if (!contentRefIds || !contentRefIds?.length) {
      return false;
    }

    return contentRefIds.some((contentRefId) => {
      const contentStateItem = state.te.content.byRefId[contentRefId];
      return !contentStateItem.isOptional;
    });
  });

  const isTriggeredPushTemplate = useSelector((state) => {
    const pushRefId = state.te.journey.steps[stepIdx]?.push;
    if (!pushRefId) {
      return false;
    }

    const pushStateItem = state.te.push.byRefId[pushRefId];

    return (
      !pushStateItem?.isOptional &&
      (pushStateItem?.actionType === PUSH_ACTION_TYPES.TRIGERRED_PUSH ||
        pushStateItem?.actionType === PUSH_ACTION_TYPES.PUSH_NOTIFICATION)
    );
  });

  const isReminderTemplate = useSelector((state) => {
    const step = state.te.journey.steps[stepIdx];

    return step?.constraints?.audienceConstraints?.syncStartScheduleWithRuleBody ?? false;
  });

  useEffect(() => {
    // ToDo: Cleanup backwards compatibility with `templateType` after migration
    if (templateType) {
      switch (templateType) {
        case 'reminder':
          setPreconditionSelectDisabled(true);
          setEventCondition(preconditionRules);
          break;
        case 'triggered':
        case 'app-content':
          if (isAudienceEmpty) {
            setPreconditionSelectDisabled(true);
            setEventCondition(undefined);
          } else {
            setPreconditionSelectDisabled(false);

            let _selectedPrecondition = undefined;

            if (memoizedTarget === 'context') {
              if (memoizedModifier?.[1] === '-') {
                _selectedPrecondition = beforeUniqueEvent;
              } else if (memoizedModifier?.[1] === '+') {
                _selectedPrecondition = afterUniqueEvent;
              }
            } else if (memoizedSchedulerRule?.payload?.logic) {
              _selectedPrecondition = preconditionRules;
              setDelayValue(memoizedDelay?.value ?? '');
              setDelayPeriod(
                memoizedDelay?.period
                  ? EVENT_PERIODS.find((period) => period.key === memoizedDelay.period) || EVENT_PERIODS[1]
                  : EVENT_PERIODS[0],
              );
            }

            setEventCondition(_selectedPrecondition);
          }
          break;
        default:
          setPreconditionSelectDisabled(true);
          setEventCondition(undefined);
      }
    } else {
      if (isReminderTemplate) {
        setPreconditionSelectDisabled(true);
        setEventCondition(preconditionRules);
      } else if (isAppContentTemplate || isTriggeredPushTemplate) {
        if (isAudienceEmpty) {
          setPreconditionSelectDisabled(true);
          setEventCondition(undefined);
        } else {
          setPreconditionSelectDisabled(false);

          let _selectedPrecondition = undefined;

          if (memoizedTarget === 'context') {
            if (memoizedModifier?.[1] === '-') {
              _selectedPrecondition = beforeUniqueEvent;
            } else if (memoizedModifier?.[1] === '+') {
              _selectedPrecondition = afterUniqueEvent;
            }
          } else if (memoizedSchedulerRule?.payload?.logic) {
            _selectedPrecondition = preconditionRules;
            setDelayValue(memoizedDelay?.value ?? '');
            setDelayPeriod(
              memoizedDelay?.period
                ? EVENT_PERIODS.find((period) => period.key === memoizedDelay.period) || EVENT_PERIODS[1]
                : EVENT_PERIODS[0],
            );
          }

          setEventCondition(_selectedPrecondition);
        }
      } else {
        setPreconditionSelectDisabled(true);
        setEventCondition(undefined);
      }
    }
  }, [
    templateType,
    isAudienceEmpty,
    memoizedSchedulerRule,
    memoizedModifier,
    memoizedDelay,
    memoizedTarget,
    isReminderTemplate,
    isAppContentTemplate,
    isTriggeredPushTemplate,
  ]);

  useEffect(() => {
    setAreLocationsLabelsWaiting({
      waitingNodeId,
      isWaiting: areMatchingLocationsLoading,
    });

    setMatchingLocations({
      matchingLocationsNodeId: waitingNodeId,
      numMatchingLocations,
    });
  }, [waitingNodeId, numMatchingLocations, areMatchingLocationsLoading]);

  useEffect(() => {
    setTimeAttributes(getTimeCtxPluginAttributes(plugins));
  }, [plugins]);

  // remove options from dropdown if timecontexts do not exist
  useEffect(() => {
    if (timeAttributes.length) setFilteredEventConditionOptions(eventConditionOptions);
    else setFilteredEventConditionOptions([eventConditionOptions[0]]);
  }, [timeAttributes]);

  useEffect(() => {
    // ToDo
    // Move this to a custom hook so that it can used across components
    // Also add error handling for working with context labels api
    (async () => {
      const { data: labels = [] } = await labelsAPI.getLabels();
      setContextLabels(labels);
      const { data } = await pluginAPI.getAllHistoricalPlugins();
      setHistoricalQueries(data);
    })();
  }, [labelsAPI, pluginAPI]);

  return (
    <>
      <div className={CLASSES.FIELD}>
        <label htmlFor="event">{startOrEnd === 'start' ? 'The message will be sent' : 'The touchpoint will end'}</label>
        <FlightSelect
          options={filteredEventConditionOptions}
          selected={
            eventCondition || {
              key: '',
              name: (
                <span className={CLASSES.SELECT_PLACEHOLDER}>
                  Select the condition <span className={CLASSES.REQUIRED_SYMBOL}>*</span>
                </span>
              ),
            }
          }
          handleOptionClick={handleEventConditionChange}
          width="100%"
        />
      </div>
      {eventCondition?.key === 'precondition-rules' && (
        <>
          <div className={CLASSES.FIELD_RIGHT_ALIGNED}>
            {memoizedDelay?.value && (
              <FlightNumberInput
                placeholderText="1"
                value={String(delayValue)}
                width="68px"
                minValue={0}
                maxValue={99}
                allowDecimal={false}
                allowNegative={false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue = e.target.value;
                  setDelayValue(newValue);
                }}
                onBlur={() => {
                  saveAndDispatchRuleModifier(delayValue, delayPeriod.key);
                }}
              />
            )}
            <FlightSelect
              options={EVENT_PERIODS}
              width="132px"
              selected={delayPeriod}
              label=""
              hasLabelAnimation={true}
              dropdownMaxHeight="200px"
              dropdownDirection="auto"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              handleOptionClick={(option: any) => handleDelayPeriodChange(option)}
            />
            <label htmlFor="after-preconditions">after a user satisfies the following preconditions</label>
          </div>
          <div className={CLASSES.FIELD}>
            {shouldUseTemporaryRuleBuilder ? (
              <div className={CLASSES.TEMP_RULE_PREVIEW_LIST}>
                <div className={CLASSES.TEMP_RULE_PREVIEW_ITEM}>
                  <div className={CLASSES.TEMP_RULE_PREVIEW_NUMBER}>1</div>
                  <p>
                    Previous step is{' '}
                    <strong>{memoizedSchedulerRule?.payload?.predicates?.[1]?.parameters?.[1].value}</strong>
                  </p>
                </div>
              </div>
            ) : (
              <button
                className={CLASSES.RULE_PREVIEW_WRAPPER}
                aria-label={`showing preconditon rules, click to edit`}
                onClick={() => handleEditPreconditionRule()}
              >
                <RulePreview
                  responseJSON={memoizedSchedulerRule}
                  locations={locations}
                  contextPlugins={plugins}
                  historicalQueries={historicalQueries}
                />
              </button>
            )}
          </div>
        </>
      )}
      {(eventCondition?.key === 'before-unique-event' || eventCondition?.key === 'after-unique-event') && (
        <div className={CLASSES.FIELD_MULTI_LINE}>
          <TimeContextForm
            stepIdx={stepIdx}
            timeAttributes={timeAttributes}
            defaultClass={MAIN_CLASS}
            preconditionKey={eventCondition?.key}
            memoizedModifier={memoizedModifier}
            startOrEnd={startOrEnd}
          />
        </div>
      )}
      <SlidingSidePanel {...slidingSidePanelProps}>
        <RuleContainer
          isSerializeUiModel={isSerializeUiModel}
          serializeUiModel={saveRuleBuilderPredicates}
          responseJSON={memoizedSchedulerRule}
          contextPlugins={plugins}
          contentInstances={contentInstances}
          contextLabels={contextLabels}
          locationProps={{
            locations,
            googleMapAPIKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
            areLocationsWaiting,
            areLocationsLabelsWaiting,
            matchingLocations,
            onLocationLabelSelection: handleLocationLabelSelection,
            isLocationSaving,
            onSaveLocation: handleSaveLocation,
            hideAddNewLocationCTA: false,
          }}
          historicalQueries={historicalQueries}
          contentAnalyticsProps={memoizedContentAnalyticsProps}
          expandAllConditions
        />
      </SlidingSidePanel>
    </>
  );
};

export default EventSelectorSection;
