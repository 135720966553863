/* eslint-disable @typescript-eslint/no-explicit-any */
/*
  content.thunk.ts
  a set of functions that take perform more complex synchronous or moderately
  complex async logic
*/

import { checkPushRefIds } from 'store/push/push.selector';
import { Content, ContentCreationPayload, ContentTemplate } from 'interface/content/content.interface';
import ContentAPI from 'services/api/content.api';
import { setContentInstances } from './content.action';
import { BatchInsertContentsAction } from 'store/actionTypes';
import type { RootState, AppThunkDispatch } from 'store/store';

/**
 * Create contents passed in thru the content instance API.
 * */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createContentInstances(contents: ContentCreationPayload[], refIds: string[]) {
  const contentAPI = new ContentAPI();

  return async (dispatch: any) => {
    const createdContents: Content[] = [];
    dispatch({ type: 'UPDATE_CONTENT_CREATE_STATUS', payload: { creationStatus: 'pending' } });

    for (const content of contents) {
      try {
        const createRes = await contentAPI.createInstance(content);
        const fetchRes = await contentAPI.getInstance(createRes.id ?? '');
        createdContents.push(fetchRes);
      } catch {
        // maybe one day this will have real error handling lmao
        dispatch({ type: 'UPDATE_CONTENT_CREATE_STATUS', payload: { creationStatus: 'failure' } });
        return undefined;
      }
    }
    // this can be batched together in a future update
    dispatch({ type: 'SET_CREATED_CONTENT_INSTANCES', payload: { contentInstances: createdContents } });
    dispatch({ type: 'UPDATE_CONTENT_CREATE_STATUS', payload: { creationStatus: 'complete' } });

    return createdContents;
  };
}

// not quite sure this function belongs here yet, but can be reevaluated later
export async function fetchContentInstance(contentId: string, queryParams?: object) {
  const contentAPI = new ContentAPI();

  let response: Content;
  try {
    response = await contentAPI.getInstance(contentId, queryParams);
    return response;
  } catch {
    // maybe one day this will have real error handling lmao
    return {} as Content;
  }
}

export function fetchContentInstancesThunk() {
  const contentAPI = new ContentAPI();

  return async (dispatch: any, getState: any) => {
    const { contentInstances } = getState().te;

    if (contentInstances) {
      return contentInstances;
    }

    try {
      const { data: contentInstances } = await contentAPI.getContentInstances({
        data: true,
        limit: 100,
        managementMode: true,
        sortby: 'createdAt',
        sortorder: 'desc',
      });

      dispatch(setContentInstances({ contentInstances }));

      return contentInstances;
    } catch (e) {
      return;
    }
  };
}

/**
 * Exactly what its name suggests. The fetch contentInstance async call itself
 * has been abstracted out (since it seems highly reusable)
 */
export function fetchAndSetContentInstance(contentId: string, contentActionRefId?: string, pushActionRefId?: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();

    // check to see if this was called from a push, and if that push has a
    // dependency that points to an existing content; skips the dispatch if not.
    const contentRefId = pushActionRefId ? checkPushRefIds(state, pushActionRefId).content : contentActionRefId;

    if (!contentRefId) return;

    if (state.te.content.byRefId[contentRefId].id === contentId) return;

    const content = await fetchContentInstance(contentId);

    if (!content) return;

    dispatch({
      type: 'CONTENT_FETCHED',
      payload: {
        content,
        refId: contentRefId,
      },
    });
  };
}

export function insertContents(payload: BatchInsertContentsAction['payload']) {
  return (dispatch: AppThunkDispatch, getState: () => RootState) => {
    const teState = getState().te;
    const journeyStep = teState.journey.steps[payload.stepIdx];
    const pushRefId = journeyStep?.push;
    const noContent = !journeyStep?.content?.length;
    const push = teState.push.byRefId[pushRefId ?? ''];
    const isContentPush = push?.pushPayloadType && push.pushPayloadType === 'content';

    dispatch({
      type: 'INSERT_CONTENTS',
      payload: {
        ...payload,
        dependantPushRefId: isContentPush && noContent ? pushRefId : undefined,
      },
    });
  };
}

export async function fetchContentTemplate(contentTemplateId: string): Promise<ContentTemplate | void> {
  const contentAPI = new ContentAPI();

  let response: ContentTemplate;
  try {
    response = await contentAPI.getTemplate(contentTemplateId);
    return response;
  } catch {
    // maybe one day this will have real error handling lmao
  }
}
