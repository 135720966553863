import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlightCheckbox, FlightNumberInput, FlightSelect } from '@flybits/design-system';
import { SchedulerStateItem } from 'store/rule/rule.type';
import { SelectOption } from 'types/common';
import { CLASSES as PARENT_CLASSES } from '../classes';
import './RepeatSection.scss';
import moment from 'moment';
import CalendarImage from 'pages/ExperienceCanvas/assets/images/Calendar';
import { START_TIME } from 'pages/ExperienceCanvas/types';

type TRepeatSectionProps = {
  stepIdx: number;
  hasEndDate: boolean;
  startScheduler: SchedulerStateItem;
  endScheduler: SchedulerStateItem;
  isEnabled: boolean;
};

const repeatFrequencyTypeOptions: SelectOption[] = [
  { key: 'once', name: '' },
  { key: 'minute', name: 'Minutes' },
  { key: 'hour', name: 'Hours' },
  { key: 'day', name: 'Days' },
  { key: 'week', name: 'Weeks' },
  { key: 'month', name: 'Months' },
  { key: 'year', name: 'Years' },
];

const MAIN_CLASS = 'repeat-section';
const CLASSES = {
  SUMMARY: `${MAIN_CLASS}__summary`,
  DATE_LIST: `${MAIN_CLASS}__date-list`,
  DATE: `${MAIN_CLASS}__date`,
  DATE_ELLIPSIS: `${MAIN_CLASS}__date ${MAIN_CLASS}__date--is-ellipsis`,
  FLEX_HACK: `${MAIN_CLASS}__flex-hack`,
  CALENDAR_IMAGE: `${MAIN_CLASS}__calendar-image`,
};

const RepeatSection: React.FC<TRepeatSectionProps> = ({
  stepIdx,
  hasEndDate,
  startScheduler,
  endScheduler,
  isEnabled,
}) => {
  const dispatch = useDispatch();
  const [repeatFrequencyType, setRepeatFrequencyType] = useState<SelectOption>(
    !!startScheduler?.frequencyType
      ? (repeatFrequencyTypeOptions.find((option) => option.key === startScheduler.frequencyType) as SelectOption)
      : repeatFrequencyTypeOptions[3],
  );
  const [repeatLimit, setRepeatLimit] = useState(startScheduler?.limit ? startScheduler.limit.toString() : '-1');
  const [repeatLimitEnabled, setRepeatLimitEnabled] = useState(!!startScheduler?.limit && startScheduler?.limit !== -1);
  const [repeatFrequency, setRepeatFrequency] = useState(
    startScheduler?.frequency ? startScheduler.frequency.toString() : '',
  );
  const isStartContextual = Object.keys(startScheduler?.predicates || {}).length > 0;

  const handleRepeatFieldChange = (field: string, value: SelectOption | string) => {
    if (field === 'frequency') setRepeatFrequency(value as string);
    else if (field === 'limit') setRepeatLimit(value as string);
    else if (field === 'frequencyType') setRepeatFrequencyType(value as SelectOption);
  };

  const handleToggleRepeatLimit = () => {
    if (repeatLimitEnabled) {
      setRepeatLimit('-1');
    } else {
      setRepeatLimit('');
    }
    setRepeatLimitEnabled((prevState) => !prevState);
  };

  const getStartScheduleString = () => {
    if (startScheduler?.start) {
      const startDate = startScheduler.start === START_TIME.NOW ? moment() : moment(startScheduler.start * 1000);
      return `starting from ${startDate.format('MMMM, D, [at] h:mm A (Z)')}`;
    }
    if (isStartContextual) return 'from the contextual schedule';

    return '';
  };

  const getAllDatesLoop = () => {
    if (!startScheduler?.start || !repeatFrequency || !repeatFrequencyType) return [];
    const max = repeatLimit !== '-1' && repeatLimit !== '' ? +repeatLimit : 26;
    const startDate = startScheduler?.start === START_TIME.NOW ? moment() : moment(startScheduler.start * 1000);
    const endDate = endScheduler?.start ? startDate.toDate() : null;
    let loop = startDate.toDate();
    let count = 0;
    const dates: Date[] = [];

    while ((!!endDate && loop < endDate) || count < max) {
      dates.push(loop);
      loop = moment(loop)
        .add(+repeatFrequency, repeatFrequencyType.key as moment.unitOfTime.DurationConstructor)
        .toDate();
      count++;
    }

    return dates;
  };

  useEffect(() => {
    if (!isEnabled) {
      setRepeatLimit('');
      setRepeatFrequency('');
      setRepeatFrequencyType(repeatFrequencyTypeOptions[0]); // index 0 = once
    } else if (repeatFrequencyType.key === 'once') {
      setRepeatFrequencyType(repeatFrequencyTypeOptions[3]);
      setRepeatLimit('-1');
    }
  }, [isEnabled, repeatFrequencyType]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_SCHEDULE',
      payload: {
        stepIdx,
        type: hasEndDate ? 'both' : 'start',
        frequency: +repeatFrequency,
        frequencyType: repeatFrequencyType?.key,
        limit: +repeatLimit,
      },
    });
  }, [dispatch, hasEndDate, repeatFrequency, repeatFrequencyType, repeatLimit, stepIdx]);

  if (!isEnabled) return null;

  return (
    <div className={PARENT_CLASSES.SECTION}>
      <div className={PARENT_CLASSES.FIELD}>
        <label htmlFor="repeat-number">Repeat every</label>
        <FlightNumberInput
          value={repeatFrequency}
          width="68px"
          minValue={0}
          maxValue={99}
          allowDecimal={false}
          allowNegative={false}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => handleRepeatFieldChange('frequency', evt.target.value)}
        />
        <FlightSelect
          options={repeatFrequencyTypeOptions.slice(1)}
          selected={repeatFrequencyType}
          width="120px"
          handleOptionClick={(arg: SelectOption) => handleRepeatFieldChange('frequencyType', arg)}
        />
      </div>
      <div className={PARENT_CLASSES.FIELD}>
        <FlightCheckbox
          label="End the repeat after"
          checkState={repeatLimitEnabled ? 'SELECTED' : 'UNSELECTED'}
          onSelect={handleToggleRepeatLimit}
        />
        <FlightNumberInput
          value={repeatLimitEnabled ? repeatLimit : ''}
          disabled={!repeatLimitEnabled}
          minValue={1}
          label="Enter the occurrences number"
          onChange={(evt: ChangeEvent<HTMLInputElement>) => handleRepeatFieldChange('limit', evt.target.value)}
        />
        occurrences
      </div>
      <div className={PARENT_CLASSES.FIELD}>
        <div className={CLASSES.SUMMARY}>
          <p>
            The schedule will{' '}
            <strong>
              repeat every {repeatFrequency} {repeatFrequencyType.name.toLocaleLowerCase()} {getStartScheduleString()}.
            </strong>
          </p>
          {repeatLimit && repeatLimit !== '-1' ? (
            <p>
              It will{' '}
              <strong>
                stop after {+repeatLimit > 1 ? ` ${repeatLimit} occurrences` : `${repeatLimit} occurrence`} or until the
                journey ends, whichever occurs first
              </strong>
              .
            </p>
          ) : (
            <p>It will not stop until the journey end.</p>
          )}
          {!isStartContextual && (
            <>
              <p style={{ marginTop: '24px' }}>
                <strong>Here are the starting schedule dates:</strong>
              </p>
              <ul className={CLASSES.DATE_LIST}>
                {getAllDatesLoop().map((date) => (
                  <li className={CLASSES.DATE} key={date.getTime()}>
                    {moment(date).format('DD/MM/YYYY hh:mm A')}
                  </li>
                ))}
                {(!repeatLimitEnabled || !repeatLimit) && <li className={CLASSES.DATE_ELLIPSIS}>…</li>}
                <li className={CLASSES.FLEX_HACK}></li>
                <li className={CLASSES.FLEX_HACK}></li>
                <li className={CLASSES.FLEX_HACK}></li>
                <li className={CLASSES.FLEX_HACK}></li>
              </ul>
            </>
          )}
          <CalendarImage className={CLASSES.CALENDAR_IMAGE} />
        </div>
      </div>
    </div>
  );
};

export default RepeatSection;
