import { PushUniversalAction } from 'components/ExperienceCanvas/types';
import { PushBodyData } from 'interface/push/push.interface';
import { PushPayloadMetadata, FormData, FormDataParam, PushFieldMetadata } from 'components/ExperienceCanvas/types';
import { PushUniversalActionTypes, PushFormActionlinkFields } from 'components/ExperienceCanvas/constants';

import BasicIcon from 'pages/ExperienceCanvas/assets/icons/BasicIcon';
import IACIcon from 'pages/ExperienceCanvas/assets/icons/IACIcon';
import WeblinkIcon from 'pages/ExperienceCanvas/assets/icons/WeblinkIcon';
import UniverseIcon from 'pages/ExperienceCanvas/assets/icons/UniverseIcon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapPushTypes(pushTypes: any) {
  const payloadTabs: PushPayloadMetadata[] = [
    {
      id: '',
      key: 'basic',
      title: 'Basic',
      icon: BasicIcon,
    },
  ];

  const supportedPushTypes = {
    content: '',
    weblink: '',
    actionlink: '',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pushTypes?.forEach((pushType: any) => {
    if (pushType.name === 'Content') {
      supportedPushTypes.content = pushType.id;
    }
    if (pushType.name === 'Web Link') {
      supportedPushTypes.weblink = pushType.id;
    }
    if (pushType.name === 'Action Link') {
      supportedPushTypes.actionlink = pushType.id;
    }
  });

  if (supportedPushTypes.content)
    payloadTabs.push({
      id: supportedPushTypes.content,
      key: 'content',
      title: 'In-App Content',
      icon: IACIcon,
    });

  if (supportedPushTypes.weblink)
    payloadTabs.push({
      id: supportedPushTypes.weblink,
      key: 'weblink',
      title: 'Web Link',
      icon: WeblinkIcon,
    });

  if (supportedPushTypes.actionlink)
    payloadTabs.push({
      id: supportedPushTypes.actionlink,
      key: 'actionlink',
      title: 'Action Link',
      icon: UniverseIcon,
    });

  return payloadTabs;
}

// placeholder functions
/**
 * Merges field metadata with values to return form dataparams.
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateFormData(values: any, fieldMetadata: PushFieldMetadata): FormDataParam | undefined {
  const retVal: FormDataParam = {};
  for (const [key, metadata] of Object.entries(fieldMetadata)) {
    if (!metadata.basicValidation) continue;

    retVal[key] = {
      value: values?.[key],
      path: metadata.path,
    };
  }
  return retVal;
}

export function generateActionlinkFieldKey(action: PushUniversalAction, idx: number) {
  return `${action.prefix}.${action.fields[idx].key}`;
}

/**
 * Generates the full actionlink url string from form fields.
 * */
export function serializeActionlink(pushAction: PushUniversalAction, form: FormData): string {
  const params = pushAction.fields
    .map((field, idx) => {
      const inputField = form[generateActionlinkFieldKey(pushAction, idx)]?.value?.trim();
      return inputField?.length > 0 && field.key ? `${field.key}=` + inputField : null;
    })
    .filter((str) => str?.length)
    .join('&');

  return `${pushAction.prefix}//?${params}`;
}

/**
 * Decomposes an actionscheme into its component fields and actionType.
 * */
export function deserializeActionlink(actionScheme: PushBodyData['actionScheme'] | undefined, lang: string) {
  const linkString = actionScheme?.[lang];
  const formFields: FormDataParam = {};

  if (!linkString) return { formFields, actionType: PushUniversalActionTypes[0] };

  const [prefix, paramString] = linkString?.split('//?');

  const actionType = PushUniversalActionTypes.find((at) => at.prefix === prefix) ?? PushUniversalActionTypes[0];

  const params = new URLSearchParams(paramString);

  actionType.fields.forEach((atf, idx) => {
    const value = atf.key ? params.get(atf.key) : paramString;

    formFields[generateActionlinkFieldKey(actionType, idx)] = {
      value,
      path: PushFormActionlinkFields.actionType.path,
      label: atf.name,
    };
  });

  return {
    formFields,
    actionType,
  };
}
