import React, { useMemo, useState } from 'react';
import PinnedContentListItem from '../PinnedContentListItem/PinnedContentListItem';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { useQuery } from '@tanstack/react-query';
import ContentAPI from 'services/api/content.api';
import { Content } from 'interface/content/content.interface';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { ZoneConfig } from 'pages/Zones/types';
import { cloneDeep } from 'lodash';

type TPinnedContentListProps = {
  contentIDs: string[];
  moduleId: string;
  zoneConfig: ZoneConfig;
  zoneId: string;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
};

const fetchContentInstances = async (contentAPI: ContentAPI, contentIDs: string[]) => {
  const contentList: Promise<Content>[] = [];
  for (let i = 0; i < contentIDs.length; i++) {
    const res = contentAPI.getInstance(contentIDs[i]);
    contentList.push(res);
  }
  return await Promise.all(contentList);
};

const PinnedContentList: React.FC<TPinnedContentListProps> = ({
  contentIDs,
  moduleId,
  zoneConfig,
  zoneId,
  setZoneConfig,
}) => {
  const contentAPI = useMemo(() => new ContentAPI(), []);

  const [currentDraggingIndex, setCurrentDraggingIndex] = useState<number | undefined>();
  const { languages } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const { data: contentInstances, status } = useQuery({
    queryKey: ['content-instances', [...contentIDs].sort()],
    queryFn: async () => await fetchContentInstances(contentAPI, contentIDs),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const zoneIndex = zoneConfig.zones.findIndex((zone) => zone.id === zoneId);
  const moduleIndex = zoneConfig.zones[zoneIndex].modules.findIndex((module) => module.id === moduleId);

  const hasHTMLContent = (content: Content) => {
    return (
      !!content?.defaultUIConfig?.layoutHTML ||
      !!content?.defaultUIConfig?.styleCSSURL ||
      !!content?.defaultUIConfig?.styleCSS
    );
  };

  const handleChangePosition = (oldIndex: number, newIndex: number) => {
    const _contentIDs = [...contentIDs];
    [_contentIDs[oldIndex], _contentIDs[newIndex]] = [_contentIDs[newIndex], _contentIDs[oldIndex]];
    const _zoneConfig = cloneDeep(zoneConfig);
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].pinnedContentIDs = _contentIDs;
    setZoneConfig(_zoneConfig);
  };

  const handleUnpinClick = (contentInstanceId: string) => {
    const _zoneConfig = cloneDeep(zoneConfig);
    const _pinnedContentIDs = _zoneConfig.zones[zoneIndex].modules[moduleIndex].pinnedContentIDs.filter(
      (id) => id !== contentInstanceId,
    );
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].pinnedContentIDs = _pinnedContentIDs;
    setZoneConfig(_zoneConfig);
  };

  if (status === 'loading') return <LoadingIcon />;

  return status === 'success' ? (
    <div
      style={{ marginBottom: '3rem', border: '1px solid #e6e7eb', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px' }}
    >
      {contentIDs.map((contentId, index) => {
        const contentInstance = contentInstances?.find(
          (contentInstance) => contentInstance.id === contentId,
        ) as Content;
        if (!contentInstance) return null;
        const contentType = hasHTMLContent(contentInstance) ? 'content-instance' : 'image';
        const props:
          | {
              contentType: 'image';
              contentURI: string;
              contentInstanceId?: never;
            }
          | {
              contentType: 'content-instance';
              contentInstanceId: string;
              contentURI?: never;
            } =
          contentType === 'content-instance'
            ? {
                contentType: 'content-instance',
                contentInstanceId: contentInstance.id || '',
              }
            : {
                contentType: 'image',
                contentURI:
                  contentInstance?.content?.data?.[0]?.media?.localizations?.[defaultLang]?.previewImgURL ||
                  contentInstance.iconUrl ||
                  '',
              };
        return (
          <PinnedContentListItem
            key={contentId}
            index={index + 1}
            id={contentId}
            listLength={contentInstances.length}
            title={contentInstance.localizations?.[defaultLang].name || ''}
            labels={contentInstance.labels || []}
            onChangePosition={(oldIndex, newIndex) => handleChangePosition(oldIndex - 1, newIndex - 1)}
            onUnpinClick={handleUnpinClick}
            onDragStart={setCurrentDraggingIndex}
            onDragEnd={() => setCurrentDraggingIndex(undefined)}
            showDropzone={
              currentDraggingIndex !== undefined &&
              currentDraggingIndex !== index + 1 &&
              currentDraggingIndex !== index + 2
            }
            {...props}
          />
        );
      })}
    </div>
  ) : null;
};

export default PinnedContentList;
