import { Dispatch, SetStateAction, useContext, createContext } from 'react';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import {
  metricsPush,
  metricsContent,
  metricsPushContent,
} from '../CustomModuleAnalyticsContent/CustomModuleMetrics/metrics';
import {
  TEntityType,
  ENTITY_DD_ITEMS,
  ENTITY_OPTIONS,
  entityDropDownOptions,
  ruleDDOption,
  VISUALIZER_OPTIONS,
  TIMEFRAME_INTERVAL_OPTIONS,
  CALC_METHOD_OPTIONS,
} from '../constants';
import type { ModuleConfig } from 'interface/templated-experience/analytics.interface';

import moment from 'moment';

const templatedExperienceAPI = new TemplatedExperienceAPI();

export type TSpecificEntity = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: Record<string, any>;
  name: string;
  isActive: boolean;
};

export type MetricFactorType = Omit<TSpecificEntity, 'context'> & {
  id: string;
};

export type TCustomModuleAnalyticsParams = {
  visualization: {
    selectedGraph: VISUALIZER_OPTIONS;
  };
  measureItems: {
    entityType: TEntityType;
    filterOption: ENTITY_OPTIONS;
    selectedLabels: string[];
    selectedEntities: TSpecificEntity[];
  };
  metrics: {
    metricOption: TEntityType;
    metricFactors: MetricFactorType[];
    rule: TEntityType;
    logicString: string;
    ruleTracker: {
      [key: string]: string;
    };
  };
  timeFrame: {
    customDateRange: Date[];
    relativeDateRange?: [number, string];
    interval: TIMEFRAME_INTERVAL_OPTIONS;
  };
  calculationMethod: {
    populationBase: TEntityType;
    method: CALC_METHOD_OPTIONS;
  };
};

type TCustomModuleAnalyticsContextParams = {
  customModuleState: TCustomModuleAnalyticsParams;
  setCustomModuleState: Dispatch<SetStateAction<TCustomModuleAnalyticsParams>>;
};

export const initialContextValue: TCustomModuleAnalyticsParams = {
  visualization: {
    selectedGraph: VISUALIZER_OPTIONS.BAR,
  },
  measureItems: {
    entityType: entityDropDownOptions[0],
    filterOption: ENTITY_OPTIONS.SPECIFIC,
    selectedLabels: [],
    selectedEntities: [],
  },
  metrics: {
    metricOption: metricsPush[0],
    metricFactors: [],
    rule: ruleDDOption[0],
    logicString: '',
    ruleTracker: {},
  },
  timeFrame: {
    customDateRange: [],
    relativeDateRange: [7, 'day'],
    interval: TIMEFRAME_INTERVAL_OPTIONS.DAILY,
  },
  calculationMethod: {
    populationBase: {} as TEntityType,
    method: CALC_METHOD_OPTIONS.TOTAL,
  },
};

export const CustomModuleAnalyticsContext = createContext<TCustomModuleAnalyticsContextParams>({
  customModuleState: initialContextValue,
  setCustomModuleState: () => null,
});

export const serializeGraphData = (
  customModuleState: TCustomModuleAnalyticsParams,
  moduleName: string,
): Partial<ModuleConfig> => {
  const { metrics, visualization, timeFrame, measureItems } = customModuleState;
  const { selectedGraph } = visualization;
  const { entityType, filterOption, selectedEntities, selectedLabels } = measureItems;

  const interactions = metrics.metricFactors?.map((factor) => factor.name.toLocaleLowerCase());

  const journeyIds = selectedEntities?.length > 0 ? selectedEntities?.map((entity) => entity?.context?.id) : [];

  const yAxis = selectedGraph === VISUALIZER_OPTIONS.BAR ? 'Experiences' : '';

  const xAxis =
    selectedGraph === VISUALIZER_OPTIONS.BAR
      ? `${entityType.name}: ${metrics.metricOption.name} ${metrics.metricFactors.length > 1 ? '(%)' : ''}`
      : '';

  const timeFramePayload = timeFrame.relativeDateRange
    ? {
        type: 'relative' as const,
        absoluteStart: 0,
        absoluteEnd: 0,
        relativeTimeOffset: timeFrame.relativeDateRange?.[0] ?? 7,
        relativeTimeUnit: timeFrame.relativeDateRange?.[1] ?? 'day',
      }
    : {
        type: 'absolute' as const,
        absoluteStart: moment(timeFrame.customDateRange[0] ?? 0).unix(),
        absoluteEnd: moment(timeFrame.customDateRange[1]).unix(),
      };

  // Generate Payload
  return {
    name: moduleName,
    description: moduleName,
    labels: selectedLabels?.length > 0 ? selectedLabels : null,
    timeFrame: {
      ...timeFramePayload,
      relativeTimeIsCurrentIncluded: false,
      timeZone: 'America/Toronto',
      ...(selectedGraph === VISUALIZER_OPTIONS.LINE && { timeInterval: timeFrame.interval }),
    },
    scope: {
      journeyIds: filterOption === ENTITY_OPTIONS.ALL ? [] : journeyIds, //When ALL, send empty array
      components: entityType?.key,
      interactions,
      aggContext: {
        dataTypeId: '',
        valueKey: '',
        columnConditions: null,
        andOrColConds: '',
        isAggCol: false,
      },
    },
    chartConfig: {
      type: selectedGraph,
      title: moduleName,
      xAxis,
      yAxis,
    },
    resultsConfig: {
      aggregateFunction: 'COUNT',
      maxResults: 5,
      sortOrder: 'DESC',
      countDistinct: false,
      cumulativeOverTime: false,
    },
  };
};

function getOption<T extends object>(val: string, opts: T) {
  for (const opt of Object.values(opts)) {
    if (val === opt) return opt;
  }
  throw new Error('Unsupported configuration');
}

function getDDOption<T extends { key: string }>(key: string, opts: T[]) {
  const ddOpt = opts.find((opt) => opt.key === key);
  if (ddOpt) return ddOpt;
  throw new Error('Unsupported configuration');
}

/**
 * Heap's algo, this implementation only works on primitives.
 * */
function permuteArr<T>(arr: T[]) {
  if (!arr.length) return [];
  const retVal: T[][] = [];
  const inputArr = [...arr];

  const swap = (arr: T[], idx1: number, idx2: number) => {
    const buffer = arr[idx1];
    arr[idx1] = arr[idx2];
    arr[idx2] = buffer;
  };

  const permute = (arr: T[], k = arr.length) => {
    if (k === 1) return retVal.push([...arr]);

    permute(arr, k - 1);

    for (let i = 0; i < k - 1; i++) {
      const isOdd = k % 2;
      if (isOdd) swap(arr, 0, k - 1);
      else swap(arr, i, k - 1);
      permute(arr, k - 1);
    }
  };

  permute(inputArr);

  return retVal;
}

/**
 *  Deserializes incoming custom module analytics configs.
 *  As per https://flybits.atlassian.net/browse/ERTW-596?focusedCommentId=72924
 *  the scope, UI, and data structures may change, so for now, to deserialize the
 *  legend labels properly, we will simply be fetching the journeys.
 *  For `All`, as the journey will be empty, the xample graph will show at first.
 * */
export const deserializeGraphData = async (moduleConfig: ModuleConfig): Promise<TCustomModuleAnalyticsParams> => {
  const { chartConfig, scope, timeFrame } = moduleConfig;
  const selectedEntities = await Promise.all(
    scope.journeyIds?.map(async (id) => {
      try {
        const journey = await templatedExperienceAPI.getTemplatedExperienceInstance(id);
        return {
          context: journey,
          name: journey.name ?? '',
          isActive: true,
        };
      } catch (e) {
        return {
          context: { id },
          name: 'Unknown Instance',
          isActive: true,
        };
      }
    }) ?? [],
  );
  // these will likely need refactor in the future to support more cases
  const filterOption = scope.journeyIds?.length ? ENTITY_OPTIONS.SPECIFIC : ENTITY_OPTIONS.ALL;

  const metricFactors = [];
  for (let i = 0; i < scope.interactions?.length; i++) {
    metricFactors.push({
      name: scope.interactions[i],
      isActive: true,
      id: scope.interactions[i],
    });
  }

  // as array order may not be guaranteed - generate potential keys to match
  const metricOptionKeys = permuteArr(metricFactors.map((factor) => factor.name)).map((permutedKeys) =>
    permutedKeys.join('|'),
  );

  let metricOption: TEntityType | undefined;

  metricOptionKeys.forEach((key) => {
    try {
      const metricOptions =
        scope.components === ENTITY_DD_ITEMS.PUSH_NOTIFICATIONS
          ? metricsPush
          : scope.components === ENTITY_DD_ITEMS.CONTENT
          ? metricsContent
          : scope.components === ENTITY_DD_ITEMS.AUDIENCES
          ? metricsPushContent
          : undefined;
      if (!metricOptions) throw new Error('Unsupported configuration');
      metricOption = getDDOption(key, metricOptions);
    } catch {}
  });

  if (!metricOption) throw new Error('Unsupported configuration');

  const customDateRange: Date[] = [];
  let relativeDateRange: [number, string] | undefined;
  if (timeFrame.type === 'absolute') {
    customDateRange.push(new Date(timeFrame.absoluteStart * 1000), new Date(timeFrame.absoluteEnd * 1000));
  } else {
    relativeDateRange = [timeFrame.relativeTimeOffset, timeFrame.relativeTimeUnit];
  }

  return {
    visualization: {
      selectedGraph: getOption(chartConfig.type, VISUALIZER_OPTIONS),
    },
    measureItems: {
      entityType: getDDOption(scope.components, entityDropDownOptions),
      filterOption,
      selectedLabels: moduleConfig.labels ?? [],
      selectedEntities,
    },
    metrics: {
      metricOption,
      metricFactors,
      // TODO: MetricsRuleContainer is not being used right now
      rule: ruleDDOption[0],
      logicString: '',
      ruleTracker: {},
    },
    timeFrame: {
      customDateRange,
      relativeDateRange,
      interval: getOption(timeFrame.timeInterval ?? TIMEFRAME_INTERVAL_OPTIONS.DAILY, TIMEFRAME_INTERVAL_OPTIONS),
    },
    calculationMethod: {
      // this may not be accurate right now, as the feature is incomplete
      // and also does not account for compound metrics like sent x viewed
      populationBase: {} as TEntityType,
      method: CALC_METHOD_OPTIONS.TOTAL,
    },
  };
};

export const useCustomModuleAnalyticsContext = () => {
  return useContext<TCustomModuleAnalyticsContextParams>(CustomModuleAnalyticsContext);
};
