import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FlightRadioButton, FlightTimeZone } from '@flybits/design-system';
import { getISODate } from 'helpers/common.helper';
import { getTimezoneList } from 'helpers/templated-experience.helper';
import { useAppSelector } from 'hooks/reduxHooks';
import { FullTime, Option } from 'types/common';
import { SchedulerStateItem } from 'store/rule/rule.type';
import { saveContextualSchedulerAction } from 'store/rule/rule.action';
import { AbsoluteErrorReturnValue } from 'validators/ExperienceCanvas/rule.validator';
import EventSelectorSection from '../EventSelectorSection/EventSelectorSection';
import { CLASSES } from '../classes';

type TEndScheduleSectionProps = {
  stepIdx: number;
  isEnabled: boolean;
  endScheduler: SchedulerStateItem;
  dateTime: { start: FullTime; end: FullTime; timezone: Option | undefined };
  errors: AbsoluteErrorReturnValue;
  setDateTime: React.Dispatch<
    React.SetStateAction<{
      start: FullTime;
      end: FullTime;
      timezone: Option | undefined;
    }>
  >;
};

const tzList: Option[] = getTimezoneList().map((tz) => ({
  key: tz.key,
  name: tz.name,
  value: tz.offset,
}));

const EndScheduleSection: React.FC<TEndScheduleSectionProps> = ({
  stepIdx,
  dateTime,
  endScheduler,
  errors,
  isEnabled,
  setDateTime,
}) => {
  const { schedule: journeySchedule } = useAppSelector((state) => state.te.journey);

  const getHowToEndTouchpointInitialValue = () => {
    if (!!dateTime?.end?.date) return 'specific-time';
    if (Object.keys(endScheduler?.predicates || {}).length > 0) return 'with-event';
    return undefined;
  };

  const [howToEndTouchpoint, setHowToEndTouchpoint] = useState<'specific-time' | 'with-event' | undefined>(
    getHowToEndTouchpointInitialValue,
  );
  const dispatch = useDispatch();
  const minStart = moment
    .unix(journeySchedule?.start || Date.now() / 1000)
    .utc()
    .add(5, 'm');

  const handleHowToEndTouchpoint = (value: 'specific-time' | 'with-event') => {
    dispatch({
      type: 'UPDATE_STEP',
      payload: {
        stepIdx,
        fields: {
          omitEnd: value !== 'specific-time',
        },
      },
    });
    dispatch({
      type: 'CLEAR_SCHEDULE',
      payload: {
        stepIdx,
        type: 'end',
      },
    });

    if (value === 'specific-time') {
      dispatch(
        saveContextualSchedulerAction({
          stepIdx,
          type: 'end',
          modifier: '',
          predicates: null,
          target: 'now',
          logic: '',
        }),
      );
    }

    setHowToEndTouchpoint(value);
  };

  const getSelectTimeOption = (time: string) => {
    const [hour, minute] = time.split(':');
    const seconds = parseInt(hour) * 3600 + parseInt(minute) * 60;
    return {
      key: `${seconds}`,
      value: seconds,
      name: moment(time, 'HH:mm').format('hh:mm A'),
    };
  };

  // this function was originally altered to support the updating of timerange
  // whenever date/time/timezone changed.  With the use of browser default input
  // range calculations for time no longer matter, but this code will be left
  // in place until it can be confirmed whether or not custom time pickers
  // that support limiting time choice should be supported.
  const handleDropdownSelect = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (dropType: 'date' | 'time' | 'tz', arg: any) => {
      const fullTime = dateTime.end;
      const newValues = {
        date: dropType === 'date' ? arg : fullTime.date,
        time: dropType === 'time' ? arg : fullTime.time,
        tz: dropType === 'tz' ? arg : dateTime.timezone,
      };

      const newDateTime = {
        ...dateTime,
        end: {
          ...dateTime.end,
          date: newValues.date,
          time: newValues.time,
        },
        timezone: newValues.tz,
      };

      let updateStore = true;
      if (!newValues.date || newValues.time?.value === undefined || !newValues.tz?.name) updateStore = false;

      setDateTime(newDateTime);

      if (updateStore) {
        dispatch({
          type: 'UPDATE_SCHEDULE',
          payload: {
            stepIdx,
            type: 'end',
            start: moment(newValues.date).add(newValues.time.value, 'seconds').unix(),
            timezone: {
              name: newValues.tz.name,
              offset: newValues.tz.offset,
            },
          },
        });
      }
    },
    [dateTime, dispatch, stepIdx, setDateTime],
  );

  if (!isEnabled) return null;

  return (
    <div className={CLASSES.SECTION}>
      <div className={CLASSES.FIELD}>
        <label htmlFor="how-to-end-touchpoint">End this touchpoint</label>
        <div className={CLASSES.RADIO_WRAPPER}>
          <FlightRadioButton
            label="At a specific time"
            checked={howToEndTouchpoint === 'specific-time'}
            value="specific-time"
            onSelect={handleHowToEndTouchpoint}
          />
          <FlightRadioButton
            label="With an event"
            value="with-event"
            checked={howToEndTouchpoint === 'with-event'}
            onSelect={handleHowToEndTouchpoint}
          />
        </div>
      </div>
      {howToEndTouchpoint === 'with-event' && <EventSelectorSection stepIdx={stepIdx} startOrEnd="end" />}
      {howToEndTouchpoint === 'specific-time' && (
        <>
          {journeySchedule?.start || journeySchedule?.end ? (
            <p>
              The end time needs to be{' '}
              <strong>
                within the journey schedule
                {journeySchedule.start && journeySchedule.end
                  ? ` from ${moment(journeySchedule.start * 1000).format('MMM D, YYYY, [at] hh[:]mma')} to ${moment(
                      journeySchedule.end * 1000,
                    ).format('MMM D, YYYY, [at] hh[:]mma')}`
                  : ''}
                {journeySchedule.start && !journeySchedule.end
                  ? ` starting ${moment(journeySchedule.start * 1000).format('MMM D, YYYY, [at] hh[:]mma')}`
                  : ''}
                {journeySchedule.end && !journeySchedule.start
                  ? ` ending ${moment(journeySchedule.end * 1000).format('MMM D, YYYY, [at] hh[:]mma')}`
                  : ''}
              </strong>
            </p>
          ) : null}
          <div className={CLASSES.FIELD}>
            <label htmlFor="and-end-date">Ends on</label>
            <div className={CLASSES.DATETIME}>
              <input
                type="date"
                className={!!errors.end?.[0]?.err ? CLASSES.HAS_ERROR : ''}
                id="end-date"
                name="end-date"
                value={getISODate(dateTime.end.date)}
                min={minStart.format('YYYY-MM-DD')}
                onChange={(evt) =>
                  handleDropdownSelect(
                    'date',
                    moment(evt.target.value).isValid() ? moment(evt.target.value).toDate() : '',
                  )
                }
                aria-label="end date"
                width={200}
              />
              <input
                type="time"
                className={!!errors.end?.[0]?.err ? CLASSES.HAS_ERROR : ''}
                id="end-time"
                name="end-time"
                value={
                  dateTime?.end?.time?.value !== undefined
                    ? moment.unix(dateTime.end.time.value).utc().format('HH:mm')
                    : ''
                }
                onChange={(evt) => handleDropdownSelect('time', getSelectTimeOption(evt.target.value))}
                aria-label="end time"
                width={90}
              />
              <FlightTimeZone
                handleTimeZoneChange={(arg) => handleDropdownSelect('tz', arg)}
                selectedTimeZone={dateTime?.timezone}
                hasError={!!errors.end?.[0]?.err}
                timeZoneList={tzList}
                timeZoneRegex=""
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EndScheduleSection;
