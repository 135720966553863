/* eslint-disable @typescript-eslint/no-explicit-any */
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import {
  SetPerformanceErrorAction,
  AnalyticsPerformanceActionType,
  SetPerformanceLoadingAction,
  SetPerformanceDataAction,
} from 'store/analytics/experience-performance/experience-performance.type';
import { TEATimeline } from 'interface/templated-experience/analytics.interface';

const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();

// fetch Target Audience for Experience Performance Graph
export function fetchPerformanceTAData(opts: any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setPerformanceLoading(true, AnalyticsPerformanceActionType.SET_PERFORMANCE_TA_LOADING));
      dispatch(setPerformanceError(''));
      const getTargetAudienceTimeline = opts.ruleID
        ? templatedExperienceAnalyticsAPI.getTargetAudienceTimeline(opts.ruleID, opts.params)
        : templatedExperienceAnalyticsAPI.getTotalUsersTimeline({
            startTime: opts.params.startdate,
            endTime: opts.params.enddate,
            interval: opts.params.aggregate,
          });
      getTargetAudienceTimeline
        .then((res: any) => {
          dispatch(setPerformanceData(res, AnalyticsPerformanceActionType.SET_PERFORMANCE_TA_DATA));
        })
        .catch((err: any) => {
          dispatch(
            setPerformanceError(
              err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.',
            ),
          );
          reject();
        })
        .finally(() => {
          dispatch(setPerformanceLoading(false, AnalyticsPerformanceActionType.SET_PERFORMANCE_TA_LOADING));
        });
    });
  };
}

// fetch Opened Push for Experience Performance Graph
export function fetchPerformanceOPData(opts: any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setPerformanceLoading(true, AnalyticsPerformanceActionType.SET_PERFORMANCE_OP_LOADING));
      dispatch(setPerformanceError(''));
      templatedExperienceAnalyticsAPI
        .getOpenedPushTimeline(opts.pushID, opts.params)
        .then((res: any) => {
          dispatch(setPerformanceData(res, AnalyticsPerformanceActionType.SET_PERFORMANCE_OP_DATA));
        })
        .catch((err: any) => {
          dispatch(
            setPerformanceError(
              err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.',
            ),
          );
          reject();
        })
        .finally(() => {
          dispatch(setPerformanceLoading(false, AnalyticsPerformanceActionType.SET_PERFORMANCE_OP_LOADING));
        });
    });
  };
}

// fetch Content Views for Experience Performance Graph
export function fetchPerformanceCVData(opts: {
  contentID: string;
  parentIDs: string[];
  experienceID: string;
  params: any;
}) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setPerformanceLoading(true, AnalyticsPerformanceActionType.SET_PERFORMANCE_CV_LOADING));
      dispatch(setPerformanceError(''));
      templatedExperienceAnalyticsAPI
        .getContentViewsTimeline(opts.contentID, {
          ...opts.params,
          parentIDs: opts.parentIDs.join(),
          experienceid: opts.experienceID,
        })
        .then((res: any) => {
          dispatch(setPerformanceData(res, AnalyticsPerformanceActionType.SET_PERFORMANCE_CV_DATA));
          resolve(res);
        })
        .catch((err: any) => {
          dispatch(
            setPerformanceError(
              err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.',
            ),
          );
          reject(err);
        })
        .finally(() => {
          dispatch(setPerformanceLoading(false, AnalyticsPerformanceActionType.SET_PERFORMANCE_CV_LOADING));
        });
    });
  };
}

// fetch Push Delievered for Experience Performance Graph
export function fetchPerformancePDData(opts: { pushID: string; params: any }) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setPerformanceLoading(true, AnalyticsPerformanceActionType.SET_PERFORMANCE_PD_LOADING));
      dispatch(setPerformanceError(''));
      templatedExperienceAnalyticsAPI
        .getDeliveredPushTimeline(opts.pushID, opts.params)
        .then((res: any) => {
          dispatch(setPerformanceData(res, AnalyticsPerformanceActionType.SET_PERFORMANCE_PD_DATA));
        })
        .catch((err: any) => {
          dispatch(
            setPerformanceError(
              err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.',
            ),
          );
          reject();
        })
        .finally(() => {
          dispatch(setPerformanceLoading(false, AnalyticsPerformanceActionType.SET_PERFORMANCE_PD_LOADING));
        });
    });
  };
}

// fetch Conetent Clicks for Experience Performance Graph
export function fetchPerformanceCCData(opts: {
  contentID: string;
  parentIDs: string[];
  experienceID: string;
  pushID: string;
  params: any;
}) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setPerformanceLoading(true, AnalyticsPerformanceActionType.SET_PERFORMANCE_CC_LOADING));
      dispatch(setPerformanceError(''));
      templatedExperienceAnalyticsAPI
        .getContentClicksTimeline(opts.contentID, {
          ...opts.params,
          parentIDs: opts.parentIDs.join(),
          experienceid: opts.experienceID,
          pushid: opts.pushID,
        })
        .then((res: any) => {
          dispatch(setPerformanceData(res, AnalyticsPerformanceActionType.SET_PERFORMANCE_CC_DATA));
        })
        .catch((err: any) => {
          dispatch(
            setPerformanceError(
              err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.',
            ),
          );
          reject();
        })
        .finally(() => {
          dispatch(setPerformanceLoading(false, AnalyticsPerformanceActionType.SET_PERFORMANCE_CC_LOADING));
        });
    });
  };
}

function setPerformanceLoading(payload: boolean, performanceType: string): SetPerformanceLoadingAction {
  return {
    type: performanceType,
    payload,
  };
}

function setPerformanceData(payload: TEATimeline, performanceType: string): SetPerformanceDataAction {
  return {
    type: performanceType,
    payload,
  };
}

// set Error for Experience Performance Graph
function setPerformanceError(payload: string): SetPerformanceErrorAction {
  return {
    type: AnalyticsPerformanceActionType.SET_PERFORMANCE_ERROR,
    payload,
  };
}
