import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FlightTextInput } from '@flybits/design-system';
import PinContentLimitBanner from 'components/Zones/PinContentLimitBanner/PinContentLimitBanner';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import ContentAPI from 'services/api/content.api';
import PinContentCard from 'components/Zones/PinContentCard/PinContentCard';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { Content } from 'interface/content/content.interface';
import { CLASSES } from 'pages/Zones/constants/classes';
import useDebounce from 'hooks/useDebounce';
import { PaginationResponse } from 'interface/shared/api.interface';
import LabelsAPI from 'services/api/labels.api';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { ZoneConfig } from 'pages/Zones/types';

type TManagePinnedContentProps = {
  zoneId: string;
  moduleId: string;
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
  onBack: () => void;
};

const PER_PAGE = 8;
const fetchContent = async (contentAPI: ContentAPI, pageParam: number, searchTerm: string, selectedLabel: string) => {
  const response = await contentAPI.getContentInstances({
    limit: PER_PAGE,
    offset: pageParam,
    labelsFormula: !selectedLabel ? '(!fb-auto-gen)' : `(!fb-auto-gen,${selectedLabel})`,
    search: searchTerm || undefined,
    managementMode: true,
    data: true,
  });
  return response;
};
const fetchLabels = async (labelsAPI: LabelsAPI) => {
  const response = await labelsAPI.getLabels();
  return response.data;
};

const ManagePinnedContent: React.FC<TManagePinnedContentProps> = ({
  zoneId,
  moduleId,
  zoneConfig,
  setZoneConfig,
  onBack,
}) => {
  const contentAPI = useMemo(() => new ContentAPI(), []);
  const labelsAPI = useMemo(() => new LabelsAPI(), []);

  const zoneIndex = zoneConfig.zones.findIndex((zone) => zone.id === zoneId);
  const moduleIndex = zoneConfig.zones[zoneIndex].modules.findIndex((module) => module.id === moduleId);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [selectedContents, setSelectedContents] = useState<string[]>(
    zoneConfig?.zones?.[zoneIndex]?.modules?.[moduleIndex]?.pinnedContentIDs || [],
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [lastElement, setLastElement] = useState<HTMLElement | null>(null);
  const scrollRef = useHorizontalScroll();
  const { languages } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data: labels, status: labelsAPIStatus } = useQuery({
    queryKey: ['labels'],
    queryFn: () => fetchLabels(labelsAPI),
    refetchOnWindowFocus: false,
  });

  const {
    data: contentList,
    status: contentAPIStatus,
    fetchNextPage,
  } = useInfiniteQuery<PaginationResponse<Content>>({
    queryKey: ['contentList', debouncedSearchTerm, selectedLabel],
    queryFn: ({ pageParam = 0 }) => fetchContent(contentAPI, pageParam, debouncedSearchTerm, selectedLabel),
    getNextPageParam: (response) =>
      response.pagination.offset + PER_PAGE >= response.pagination.totalRecords
        ? undefined
        : response.pagination.offset + PER_PAGE,
    refetchOnWindowFocus: false,
  });

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      },
      {
        threshold: 0.25,
      },
    ),
  );

  const hasHTMLContent = (content: Content) => {
    return (
      !!content?.defaultUIConfig?.layoutHTML ||
      !!content?.defaultUIConfig?.styleCSSURL ||
      !!content?.defaultUIConfig?.styleCSS
    );
  };

  const handleLabelClick = (label: string) => {
    if (selectedLabel === label) setSelectedLabel('');
    else setSelectedLabel(label);
  };

  const handleSearchTermChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setSearchTerm(value);
  };

  const handleContentSelect = (contentId: string) => {
    if (selectedContents.length >= 3) return;
    setSelectedContents((prev) => [...prev, contentId]);
    const _zoneConfig = { ...zoneConfig };
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].pinnedContentIDs = [
      ...(_zoneConfig.zones[zoneIndex].modules[moduleIndex].pinnedContentIDs || []),
      contentId,
    ];
    setZoneConfig(_zoneConfig);
  };

  const handleContentRemoveSelection = (contentId: string) => {
    setSelectedContents((prev) => prev.filter((item) => item !== contentId));
    const _zoneConfig = { ...zoneConfig };
    _zoneConfig.zones[zoneIndex].modules[moduleIndex].pinnedContentIDs = [
      ...(_zoneConfig.zones[zoneIndex].modules[moduleIndex].pinnedContentIDs || []).filter(
        (item) => item !== contentId,
      ),
    ];
    setZoneConfig(_zoneConfig);
  };

  useEffect(() => {
    const currentObserver = observer.current;
    if (lastElement) {
      currentObserver.observe(lastElement);
    }
    return () => {
      if (lastElement) {
        currentObserver.unobserve(lastElement);
      }
    };
  }, [lastElement]);

  return (
    <div>
      <div className={`${CLASSES.PINNED_CONTENT}__header__select-content`}>
        <button className={`${CLASSES.PINNED_CONTENT}__header__select-content__back-button`} onClick={onBack}>
          {'< Back'}
        </button>
        <div className={`${CLASSES.PINNED_CONTENT}__header__select-content__header-text`}>Select content</div>
        <div className={`${CLASSES.PINNED_CONTENT}__header__select-content__labels`} ref={scrollRef}>
          {labelsAPIStatus === 'success' &&
            labels
              .filter((label) => !label.includes('proto-'))
              .map((label) => (
                <button
                  type="button"
                  className={selectedLabel === label ? 'is-selected' : ''}
                  key={label}
                  onClick={() => handleLabelClick(label)}
                >
                  {label}
                </button>
              ))}
        </div>
        <div className={`${CLASSES.PINNED_CONTENT}__header__select-content__search`}>
          <FlightTextInput
            iconInput="search"
            placeholderText="Search content by label or title"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
        </div>
      </div>
      <div className={`${CLASSES.PINNED_CONTENT}__header__pin-content-limit-banners`}>
        <PinContentLimitBanner numPinnedContent={selectedContents.length} />
      </div>
      {contentAPIStatus === 'loading' && <p>loading...</p>}
      {contentAPIStatus === 'success' && (
        <div className={`${CLASSES.PINNED_CONTENT}__content-list`}>
          {contentList.pages
            .flatMap((page) => page.data)
            .map((content) => {
              const contentType = hasHTMLContent(content) ? 'content-instance' : 'image';
              const props:
                | {
                    contentType: 'image';
                    contentURI: string;
                    contentInstanceId?: never;
                  }
                | {
                    contentType: 'content-instance';
                    contentInstanceId: string;
                    contentURI?: never;
                  } =
                contentType === 'content-instance'
                  ? {
                      contentType: 'content-instance',
                      contentInstanceId: content.id || '',
                    }
                  : {
                      contentType: 'image',
                      contentURI:
                        content?.content?.data?.[0]?.media?.localizations?.[defaultLang]?.previewImgURL ||
                        content.iconUrl ||
                        '',
                    };
              return (
                <div key={content.id || ''} ref={setLastElement}>
                  <PinContentCard
                    id={content.id || ''}
                    title={content.localizations?.[defaultLang].name || ''}
                    {...props}
                    onSelect={() => handleContentSelect(content.id as string)}
                    onRemoveSelection={() => handleContentRemoveSelection(content.id as string)}
                    isSelected={selectedContents.includes(content.id as string)}
                    isDisabled={!selectedContents.includes(content.id as string) && selectedContents.length >= 3}
                  />
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ManagePinnedContent;
