import React from 'react';
import './AnalyticsCardsGroup.scss';
import PieGraphDevice from 'components/Shared/Graphs/PieGraphDevice';
import { GraphData } from 'components/Analytics/types';
import { FlightTooltip } from '@flybits/design-system';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overviewExperiences: any;
  frequency?: string;
  isLoading: boolean;
}

export const AnalyticsCardsGroup = (props: Props) => {
  const DEFAULT_CLASS = 'analytics-overview__top-side__cards';
  const pushOpenRateValue = props?.overviewExperiences?.pushOpenRate?.value.toFixed(2);
  const pushOpenIOS: number = props?.overviewExperiences?.pushOpenRate?.platformSplit?.ios || 0;
  const pushOpenAndroid: number = props?.overviewExperiences?.pushOpenRate?.platformSplit?.android || 0;
  const pushOpenPlatformTotal: number = pushOpenIOS + pushOpenAndroid;
  const pushOpenDataIos: number = pushOpenPlatformTotal > 0 ? (100 * pushOpenIOS) / pushOpenPlatformTotal : 50;
  const pushOpenDataAndroid: number = pushOpenPlatformTotal > 0 ? (100 * pushOpenAndroid) / pushOpenPlatformTotal : 50;
  const pushOpenData: GraphData[] = [
    {
      id: 'ios',
      label: pushOpenDataIos.toFixed(2).concat('%'),
      value: pushOpenDataIos,
      color: '#165Dff',
    },
    {
      id: 'android',
      label: pushOpenDataAndroid.toFixed(2).concat('%'),
      value: pushOpenDataAndroid,
      color: '#D919D9',
    },
  ];
  const clickThroughRateValue = props?.overviewExperiences?.clickThroughRate?.value.toFixed(2);
  const clickThroughIOS: number = props?.overviewExperiences?.clickThroughRate?.platformSplit?.ios || 0;
  const clickThroughAndroid: number = props?.overviewExperiences?.clickThroughRate?.platformSplit?.android || 0;
  const clickThroughPlatformTotal: number = clickThroughIOS + clickThroughAndroid;
  const clickThroughDataIos: number =
    clickThroughPlatformTotal > 0 ? (100 * clickThroughIOS) / clickThroughPlatformTotal : 50;
  const clickThroughDataAndroid: number =
    clickThroughPlatformTotal > 0 ? (100 * clickThroughAndroid) / clickThroughPlatformTotal : 50;
  const clickThroughData: GraphData[] = [
    {
      id: 'ios',
      label: clickThroughDataIos.toFixed(2).concat('%'),
      value: clickThroughDataIos,
      color: '#165Dff',
    },
    {
      id: 'android',
      label: clickThroughDataAndroid.toFixed(2).concat('%'),
      value: clickThroughDataAndroid,
      color: '#D919D9',
    },
  ];

  // function cardMessageElement(value: number, isHalfCard: boolean) {
  //   const colorClassName = value < 0 ? `${DEFAULT_CLASS}__card__message-red` : `${DEFAULT_CLASS}__card__message-green`;
  //   const halfCardName = isHalfCard ? `${DEFAULT_CLASS}__card__message-half` : '';
  //   return (
  //     <div className={`${DEFAULT_CLASS}__card__message ${halfCardName} ${colorClassName}`}>
  //       {value ? Number(value).toFixed(2) : 0}% from last {formatFrequency(props?.frequency || 'daily')}
  //     </div>
  //   );
  // }
  return (
    <div className={DEFAULT_CLASS}>
      {props.isLoading ? (
        <div className={`${DEFAULT_CLASS}__placeholder`}>
          <Skeleton height={225} />
        </div>
      ) : (
        <div className={`${DEFAULT_CLASS}__card`}>
          <FlightTooltip
            description="Number of experiences that were activated in the selected time frame."
            direction="top"
          >
            <div className={`${DEFAULT_CLASS}__card__title`}>
              <div className={`${DEFAULT_CLASS}__card__title__text`}>Total experiences launched</div>
              <div className={`${DEFAULT_CLASS}__card__title__icon`}></div>
            </div>
            <div className={`${DEFAULT_CLASS}__card__value`}>
              {props?.overviewExperiences?.experiencesLaunched?.value || 0}
            </div>
            {/* {cardMessageElement(props?.overviewExperiences?.experiencesLaunched?.change, true)} */}
          </FlightTooltip>
        </div>
      )}
      {props.isLoading ? (
        <div className={`${DEFAULT_CLASS}__placeholder`}>
          <Skeleton height={225} />
        </div>
      ) : (
        <div className={`${DEFAULT_CLASS}__card`}>
          <FlightTooltip
            description="Number of unique push or content engagements over the number of unique push or content views in the selected time frame."
            direction="top"
          >
            <div className={`${DEFAULT_CLASS}__card__title`}>Average engagement rate</div>
            <div className={`${DEFAULT_CLASS}__card__value`}>
              {props?.overviewExperiences?.avgEngagementRate?.value.toFixed(2) || 0}%
            </div>
            {/* {cardMessageElement(props?.overviewExperiences?.avgEngagementRate?.change.toFixed(2), true)} */}
          </FlightTooltip>
        </div>
      )}
      {props.isLoading ? (
        <div className={`${DEFAULT_CLASS}__placeholder`}>
          <Skeleton height={225} />
        </div>
      ) : (
        <div className={`${DEFAULT_CLASS}__card`}>
          <div className={`${DEFAULT_CLASS}__card__left`}>
            <FlightTooltip
              description="Number of unique push engagements over the number of unique push sends in the selected time frame."
              direction="top"
            >
              <div className={`${DEFAULT_CLASS}__card__title`}>
                <div className={`${DEFAULT_CLASS}__card__title__text`}>Push open rate</div>
                <div className={`${DEFAULT_CLASS}__card__title__icon`}></div>
              </div>
              <div className={`${DEFAULT_CLASS}__card__value`}>{pushOpenRateValue || 0}%</div>
              {/* {cardMessageElement(props?.overviewExperiences?.pushOpenRate?.change.toFixed(2), false)} */}
            </FlightTooltip>
          </div>
          <div className={`${DEFAULT_CLASS}__card__right`}>
            <PieGraphDevice data={pushOpenData} />
          </div>
        </div>
      )}
      {props.isLoading ? (
        <div className={`${DEFAULT_CLASS}__placeholder`}>
          <Skeleton height={225} />
        </div>
      ) : (
        <div className={`${DEFAULT_CLASS}__card`}>
          <div className={`${DEFAULT_CLASS}__card__left`}>
            <FlightTooltip
              description="Number of unique content engagements over number of unique content views in the selected time frame."
              direction="top"
            >
              <div className={`${DEFAULT_CLASS}__card__title`}>
                <div className={`${DEFAULT_CLASS}__card__title__text`}>Click-through rate</div>
                <div className={`${DEFAULT_CLASS}__card__title__icon`}></div>
              </div>
              <div className={`${DEFAULT_CLASS}__card__value`}>{clickThroughRateValue || 0}%</div>
              {/* {cardMessageElement(props?.overviewExperiences?.clickThroughRate?.change.toFixed(2), false)} */}
            </FlightTooltip>
          </div>
          <div className={`${DEFAULT_CLASS}__card__right`}>
            <PieGraphDevice data={clickThroughData} />
          </div>
        </div>
      )}
    </div>
  );
};
