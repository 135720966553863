import React from 'react';

function LocationHeaderIcon() {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ddd_2529_53159)">
        <rect x="5" y="4" width="31" height="31" rx="15.5" fill="#2371F1" />
        <path
          d="M20.5008 12C16.9162 12 14 15.0103 14 18.7105C14 22.6445 17.5402 25.0587 19.8828 26.6558L20.2882 26.9337C20.3524 26.9779 20.4266 27 20.5 27C20.5734 27 20.6476 26.9779 20.7118 26.9337L21.1172 26.6558C23.4598 25.0587 27 22.6445 27 18.7105C27.0015 15.0103 24.0854 12 20.5008 12ZM20.5008 20.6842C19.4446 20.6842 18.5888 19.8008 18.5888 18.7105C18.5888 17.6203 19.4446 16.7368 20.5008 16.7368C21.5569 16.7368 22.4128 17.6203 22.4128 18.7105C22.4128 19.8008 21.5569 20.6842 20.5008 20.6842Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_2529_53159"
          x="0"
          y="0"
          width="41"
          height="41"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2529_53159" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2529_53159" result="effect2_dropShadow_2529_53159" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_2529_53159" result="effect3_dropShadow_2529_53159" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2529_53159" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default LocationHeaderIcon;
