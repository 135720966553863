import React from 'react';

function ModuleEditDetailsIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#2371F1" />
      <path
        d="M23 27.3125H15.5C13.6865 27.3125 12.6875 26.3135 12.6875 24.5V17C12.6875 15.1865 13.6865 14.1875 15.5 14.1875H17.75C18.0605 14.1875 18.3125 14.4395 18.3125 14.75C18.3125 15.0605 18.0605 15.3125 17.75 15.3125H15.5C14.3173 15.3125 13.8125 15.8173 13.8125 17V24.5C13.8125 25.6828 14.3173 26.1875 15.5 26.1875H23C24.1827 26.1875 24.6875 25.6828 24.6875 24.5V22.25C24.6875 21.9395 24.9395 21.6875 25.25 21.6875C25.5605 21.6875 25.8125 21.9395 25.8125 22.25V24.5C25.8125 26.3135 24.8135 27.3125 23 27.3125ZM26.435 14.7725L25.2275 13.5651C24.8 13.1451 24.1175 13.145 23.69 13.5725L22.6775 14.5925L25.4075 17.3225L26.4275 16.31C26.855 15.8825 26.855 15.2 26.435 14.7725ZM21.8825 15.3875L17 20.2926V23H19.7075L24.6125 18.1175L21.8825 15.3875Z"
        fill="white"
      />
    </svg>
  );
}

export default ModuleEditDetailsIcon;
