import { useState, useEffect, useRef } from 'react';
import { disableLoading } from 'helpers/common.helper';
/**
 * Given a loading state variable (boolean), returns another loading variable
 * that will not flip from true to false before the minimum loading time (1.2s) has been reached
 * Can be used with the loading/fetching statuses from react-query.
 * @param isLoading
 * @returns delayedIsLoading
 */
const useDelayedLoadingState = (isLoading: boolean, minimumLoadingWait = 1200) => {
  const [delayedLoading, setDelayedLoading] = useState(isLoading);
  const startTime = useRef<number>();

  useEffect(() => {
    if (isLoading) {
      setDelayedLoading(true);
      startTime.current = Date.now();
    } else {
      if (startTime.current)
        disableLoading(
          startTime.current,
          () => {
            setDelayedLoading(false);
            startTime.current = undefined;
          },
          minimumLoadingWait,
        );
      else setDelayedLoading(false);
    }
  }, [isLoading, minimumLoadingWait]);

  return delayedLoading || isLoading;
};

export default useDelayedLoadingState;
