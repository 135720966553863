import { DependencyGraph, JOURNEY_STATUSES, JourneyState, StateStep } from 'store/journey/journey.type';
import { Edge, Node, XYPosition } from 'reactflow';
import { PushState, PushStateItem } from 'store/push/push.type';
import { ContentState, ContentStateItem } from 'store/content/content.type';
import { cloneDeep, isEmpty } from 'lodash';
import { dashedEdgeProps, startEdgeProps } from '../constants/flow-builder.constants';
import { FlowNodeTypes } from 'components/FlowBuilder/FlowNodes/node.types';
import { JourneyComponentTypes } from 'pages/FlowBuilder/types/flow-builder.types';
import { PUSH_PAYLOAD_TYPES } from 'pages/ExperienceCanvas/types';
import { RuleState } from 'store/rule/rule.type';
import { WebhookState } from 'store/webhook/webhook.type';
import ELK, { ElkNode, ElkExtendedEdge } from 'elkjs/lib/elk.bundled';

const elk = new ELK();

/**
 * Start: Internal Helpers
 */
const createStepsMap = (steps: StateStep[]) => {
  const stepsMap = new Map<string, StateStep>();

  steps.forEach((step, index) => {
    step.id && stepsMap.set(step.id, { ...step, index });
  });

  return stepsMap;
};

const addParentsToStep = (steps: StateStep[], stepsMap: Map<string, StateStep>) => {
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];

    if (step.children && step.children.length > 0) {
      step.children.forEach((childId) => {
        const childStep = stepsMap.get(childId);
        if (childStep) {
          if (!childStep.parents) {
            childStep.parents = [];
          }
          step.id && childStep.parents.push(step.id);
        }
      });
    }
  }

  return steps;
};

const getDependencySourceIds = (
  stepIndex: number,
  steps: StateStep[],
  dependencyGraph: DependencyGraph | undefined,
  type: JourneyComponentTypes,
  targetNodeRefId: string,
  defaultSourceNodeIds: string[],
) => {
  if (stepIndex === -1 || !dependencyGraph) {
    return defaultSourceNodeIds;
  }

  const sourceIds: string[] = [];

  let depGraphNode = null;
  switch (type) {
    case JourneyComponentTypes.RULE:
      depGraphNode = dependencyGraph[`SYNTH_STEP_RULE_ID_${stepIndex}`];
      if (depGraphNode.requires.length === 0) {
        return defaultSourceNodeIds;
      }

      for (const requiredNode of depGraphNode.requires) {
        const requiredNodeId = requiredNode.refId;
        const requiredStepIdx = dependencyGraph[requiredNodeId].stepIdx;
        /* If `rule` node requires another `rule` node from a different step */
        if (requiredNodeId.includes('SYNTH_STEP_RULE_ID')) {
          // ToDo: Support for `preferred` rule
          const requiredStepRuleRefId = steps[requiredStepIdx].rule.restricted;
          if (requiredStepRuleRefId) {
            sourceIds.push(`node-${requiredStepRuleRefId}`);
          }
        } /* If `rule` node requires another `action` node */ else {
          // bypass webhook "parents" for now
          if (steps[stepIndex].webhook !== requiredNodeId) {
            sourceIds.push(`node-${requiredNodeId}`);
          }
        }
      }

      break;
    case JourneyComponentTypes.CONTENT:
      depGraphNode = dependencyGraph[targetNodeRefId];
      if (depGraphNode.isRefBy.length === 0) {
        return defaultSourceNodeIds;
      }

      for (const referredNodeId of depGraphNode.isRefBy) {
        if (!referredNodeId.includes('SYNTH_STEP_RULE_ID')) {
          const referredNodeIdStepIdx = dependencyGraph[referredNodeId].stepIdx;
          // First connect the `content` node to its parent `rule` or `action` node in the same step
          if (depGraphNode.stepIdx !== referredNodeIdStepIdx) {
            defaultSourceNodeIds.forEach((defaultSourceNodeId) => sourceIds.push(defaultSourceNodeId));
          }
          sourceIds.push(`node-${referredNodeId}`);
        }
      }

      break;
  }

  return isEmpty(sourceIds) ? defaultSourceNodeIds : sourceIds;
};

const createParentChildRelationship = (nodesMap: Map<string, Node>, childNodeId: string, parentNodeId: string) => {
  const parentNode = nodesMap.get(parentNodeId);
  const childNode = nodesMap.get(childNodeId);

  if (parentNode) {
    if (!parentNode.data.children) {
      parentNode.data.children = new Set<string>();
    }

    parentNode.data.children.add(childNodeId);
  }

  if (childNode) {
    if (!childNode.data.parents) {
      childNode.data.parents = new Set<string>();
    }

    childNode.data.parents.add(parentNodeId);
  }

  /**
   * If push is optional and content exists
   * create an additional parent child relationship
   * between the target audience <-> content node
   */
  if (
    childNode?.type === FlowNodeTypes.CONTENT &&
    parentNode?.type === FlowNodeTypes.PUSH &&
    parentNode.data.push.isOptional
  ) {
    const grandParentNodeIds: Set<string> = parentNode.data.parents;

    grandParentNodeIds.forEach((grandParentNodeId) => {
      const grandParentNode = nodesMap.get(grandParentNodeId);
      if (grandParentNode?.type === FlowNodeTypes.TARGET_AUDIENCE) {
        const grandParentChildren: Set<string> = grandParentNode.data.children;
        grandParentChildren.add(childNodeId);

        const childNodeParents: Set<string> = childNode.data.parents;
        childNodeParents.add(grandParentNodeId);
      }
    });
  }
};

// ToDo: Optimize the `getDependencySourceIds` and `createParentChildRelationship` functions
// to iterate once through the `nodesMap` and create the connections
// Right now, the `createParentChildRelationship` fails to create children in a node which doesn't exist at the time of invocation
// Till then `addMissingChildrenNodeIds` would be a temporary solution
const addMissingChildrenNodeIds = (nodesMap: Map<string, Node>) => {
  nodesMap.forEach((node) => {
    const parentNodeIds: Set<string> = node.data.parents;

    parentNodeIds?.forEach((parentNodeId) => {
      const parentNode = nodesMap.get(parentNodeId);

      if (parentNode) {
        if (!parentNode.data.children) {
          parentNode.data.children = new Set<string>();
        }

        parentNode.data.children.add(node.id);
      }
    });
  });
};

const createEdges = (nodesMap: Map<string, Node>) => {
  const edges: Edge[] = [];

  nodesMap.forEach((node) => {
    const childNodeIds: Set<string> = node.data.children;

    let edgeProps = dashedEdgeProps;

    if (node.id === 'node-start') {
      edgeProps = startEdgeProps;
    }

    childNodeIds?.forEach((childNodeId) => {
      const edgeId = `edge-${node.id}--${childNodeId}`;
      edges.push({
        id: edgeId,
        source: node.id,
        target: childNodeId,
        ...edgeProps,
      });
    });
  });

  return edges;
};

const getNodeDimensions = (node: Node, nodesMap: Map<string, Node>) => {
  if (node.type === FlowNodeTypes.START) {
    if (node.data.children) {
      const [firstNodeId] = node.data.children;

      if (firstNodeId) {
        const node = nodesMap.get(firstNodeId);

        // Dimensions for start node
        // If the first rule node has an optional node wrapper
        if (node && node.type === FlowNodeTypes.TARGET_AUDIENCE && node.data.rule?.isOptional) {
          return {
            width: 205,
            height: 70,
          };
        }
      }
    }

    // Dimension for start node
    return {
      width: 232,
      height: 70,
    };
  }

  // Dimensions for all nodes
  return {
    width: 245,
    height: 243,
  };
};

const positionNodesWithElk = async (nodes: Node[], edges: Edge[], nodesMap: Map<string, Node>) => {
  const elkOptions = {
    'elk.algorithm': 'elk.mrtree',
    'elk.spacing.nodeNode': '150',
  };

  const elkGraph = {
    id: 'root',
    layoutOptions: elkOptions,
    children: nodes.map((node) => ({
      ...node,

      // Adjust the target and source handle positions based on the layout direction.
      targetPosition: 'top',
      sourcePosition: 'bottom',

      // Hardcode a width and height for elk to use when layouting.
      ...getNodeDimensions(node, nodesMap),
    })),
    edges: edges as unknown as ElkExtendedEdge,
  } as unknown as ElkNode;

  try {
    const layoutedGraph = await elk.layout(elkGraph);
    const nodes = layoutedGraph.children?.map((node) => ({
      ...node,
      // React Flow expects a position property on the node instead of `x` and `y` fields.
      position: { x: node.x, y: node.y },
    }));
    const edges = layoutedGraph.edges;

    // Update nodes map
    nodes?.forEach((node) => {
      nodesMap.set(node.id, node as Node);
    });

    return { nodes, edges };
  } catch {
    // eslint-disable-next-line no-console
    console.error('> elk layouting failed');

    return { nodes, edges };
  }
};

const repositionOptionalPushNodeChildren = (nodesMap: Map<string, Node>) => {
  const repositionChildren = (nodeId: string) => {
    const node = nodesMap.get(nodeId);
    if (node) {
      node.position.y += 400; // Approximate vertical distance between two levels
      node.position.x += 15; // Optional node wrapper is 30px wider than base node

      // This flag will be used to skip content node repositioning in `repositionContentNodes()`
      node.data.repositionedFromOptionalPush = true;

      if (isEmpty(node.data.children)) {
        return;
      } else {
        const childNodeIds: Set<string> = node.data.children;
        childNodeIds?.forEach((nodeId) => {
          repositionChildren(nodeId);
        });
      }
    }
  };

  nodesMap.forEach((node) => {
    if (node.type === FlowNodeTypes.PUSH && node.data.push.isOptional) {
      // If the parent node doesn't have children other than the child of the optional push node
      // then don't reposition the children of the optional push node
      const childNodeIds: Set<string> = node.data.children;
      const parentNodeIds: Set<string> = node.data.parents;

      if (childNodeIds && childNodeIds.size > 0) {
        let numSiblings = 0;

        parentNodeIds?.forEach((parentNodeId) => {
          const parentNode = nodesMap.get(parentNodeId);
          if (parentNode) {
            const childNodeIdsOfParent: Set<string> = parentNode.data.children;

            childNodeIdsOfParent?.forEach((childId) => {
              if (childId !== node.id && !childNodeIds.has(childId)) {
                numSiblings++;
              }
            });

            if (numSiblings === 0) {
              return;
            }
          }
        });

        // If there are no siblings then move the optional push node towards the left
        // to make space for the connection from its parent rule node to its child content node
        if (numSiblings === 0) {
          node.position.x -= 150;
          return;
        }

        // Reposition the children tree of the optional push node
        childNodeIds.forEach((nodeId) => {
          repositionChildren(nodeId);
        });
      }
    }
  });
};

const repositionContentNodes = (nodesMap: Map<string, Node>, dependencyGraph?: DependencyGraph) => {
  nodesMap.forEach((node) => {
    if (node.type === FlowNodeTypes.CONTENT) {
      const content = node.data.content as ContentStateItem;
      const contentStepIdx = node.data.stepIndex;
      const contentDGNode = dependencyGraph?.[content.refId] || null;

      if (contentDGNode) {
        const contentIsRefBy = contentDGNode.isRefBy || [];

        if (contentStepIdx === -1 || contentIsRefBy.length === 0) {
          return;
        } else {
          contentIsRefBy?.forEach((refId) => {
            const refByNode = nodesMap.get(`node-${refId}`);

            if (refByNode?.type === FlowNodeTypes.PUSH) {
              const refByPushStepIdx = refByNode.data.stepIndex;
              const refByPushYPos = refByNode.position.y;

              // Reposition only if the push node is from another step and its yPos is higher than the push node's yPos
              if (refByPushStepIdx !== contentStepIdx && refByPushYPos > node.position.y) {
                // Move content node's yPos one level down from the refByPushNode
                node.position.y = refByPushYPos + 400;

                // Align content node's xPos with parent only if it's not repositioned already due to an optional push node earlier ...
                const contentNodeParentIds: Set<string> = node.data.parents;

                if (!node.data.repositionedFromOptionalPush && contentNodeParentIds && contentNodeParentIds.size > 0) {
                  const parentRuleNodeIds: string[] = [];

                  contentNodeParentIds.forEach((parentNodeId) => {
                    const parentNode = nodesMap.get(parentNodeId);
                    if (parentNode) {
                      const type = parentNode.type;

                      if (type === FlowNodeTypes.TARGET_AUDIENCE) {
                        parentRuleNodeIds.push(parentNodeId);
                      }
                    }
                  });

                  // ... and it has only one rule node among its parents
                  if (parentRuleNodeIds.length === 1) {
                    let isParentNodeOptional = false;
                    const parentNode = nodesMap.get(parentRuleNodeIds[0]);

                    if (parentNode) {
                      const parentNodeType = parentNode?.type || FlowNodeTypes.DEFAULT;
                      if (parentNodeType === FlowNodeTypes.TARGET_AUDIENCE) {
                        isParentNodeOptional = parentNode.data.rule?.isOptional || false;
                      } else if (parentNodeType === FlowNodeTypes.CONTENT || parentNodeType === FlowNodeTypes.PUSH) {
                        isParentNodeOptional = parentNode.data[parentNodeType]?.isOptional || false;
                      }

                      if (isParentNodeOptional) {
                        node.position.x = parentNode.position.x + 15;
                      } else {
                        node.position.x = parentNode.position.x;
                      }
                    }
                  }
                }
              }
            }
          });
        }
      }
    }
  });
};

/**
 * End: Internal Helpers
 */

/**
 * Start: Exported Helpers
 */
export const getIsContentNeeded = (push: PushStateItem, dependencyGraph?: DependencyGraph) => {
  if (push.pushPayloadType !== PUSH_PAYLOAD_TYPES.CONTENT) {
    return;
  }

  const pushDGNode = dependencyGraph?.[push.refId] ?? null;

  if (!pushDGNode) {
    return;
  }

  let isContentDepFound = false;
  for (let i = 0; i < pushDGNode.requires.length; i++) {
    const requiredDep = pushDGNode.requires[i];
    if (requiredDep.var.includes('dep.content-id')) {
      isContentDepFound = true;
      break;
    }
  }

  return !isContentDepFound;
};

/**
 * End: Exported Helpers
 */

/**
 * Start: Flow generator functions that transform journey -> nodes and edges
 */

/**
 * Works with the current step payload in production
 * Creates a linear flow between journey steps
 */
export const mapLinearJourney = async (
  journeyState: JourneyState,
  ruleState: RuleState,
  pushState: PushState,
  contentState: ContentState,
  webhookState: WebhookState,
  defaultLanguage: string,
  dependencyGraph?: DependencyGraph,
) => {
  const journeyStatus = journeyState.status;
  const isJourneyActive = journeyStatus !== JOURNEY_STATUSES.INITIAL && journeyStatus !== JOURNEY_STATUSES.DRAFT;

  const START_POS: XYPosition = {
    x: 275,
    y: 0,
  };

  const defaultNodePos: XYPosition = {
    x: START_POS.x - 7.5,
    y: START_POS.y + 150,
  };

  const nodesMap = new Map<string, Node>();

  const startNode: Node = {
    id: 'node-start',
    type: FlowNodeTypes.START,
    data: {
      label: 'Journey Start',
    },
    position: START_POS,
  };
  nodesMap.set('node-start', startNode);

  let previousNodeId = 'node-start';
  let previousTargetAudienceNodeId = '';

  const { steps } = journeyState;

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i];

    if (step) {
      // ToDo: Support for `preferred` rule
      const ruleRefId = step.rule.restricted || '';
      const pushRefId = step.push || '';
      const contentRefIds = step.content || [];
      const webhookRefId = step.webhook || '';

      const rule = ruleRefId ? ruleState.byRefId[ruleRefId] : null;
      const nodeId = `node-${ruleRefId}`;

      const node = {
        id: nodeId,
        type: FlowNodeTypes.TARGET_AUDIENCE,
        data: { rule, stepIndex: i },
        position: defaultNodePos,
      };
      nodesMap.set(nodeId, node);

      createParentChildRelationship(nodesMap, nodeId, previousNodeId);

      // Subsequent steps target audience node
      if (i > 0) {
        previousNodeId = previousTargetAudienceNodeId;
      }

      previousTargetAudienceNodeId = previousNodeId = nodeId;

      /* If push exists */
      checkIfPushExists: if (pushRefId) {
        const push = pushState.byRefId[pushRefId];

        // Skip rendering optional push node if not enabled
        if (isJourneyActive && push.isOptional && !push.enableOptionalNode) {
          break checkIfPushExists;
        }

        // ToDo: Unable to derive from `pushPayloadType` as it's getting set only after navigating to the `Actions` tab in the Touchpoint editor page
        let pushType = PUSH_PAYLOAD_TYPES.BASIC;

        if (!!push.customPayloadData?.contentId || push.pushPayloadType === PUSH_PAYLOAD_TYPES.CONTENT) {
          pushType = PUSH_PAYLOAD_TYPES.CONTENT;
        } else if (!!push.customPayloadData?.url || push.pushPayloadType === PUSH_PAYLOAD_TYPES.WEB_LINK) {
          pushType = PUSH_PAYLOAD_TYPES.WEB_LINK;
        } else if (!!push.customPayloadData?.actionScheme || push.pushPayloadType === PUSH_PAYLOAD_TYPES.ACTION_LINK) {
          pushType = PUSH_PAYLOAD_TYPES.ACTION_LINK;
        }

        const nodeId = `node-${pushRefId}`;
        const node = {
          id: nodeId,
          type: FlowNodeTypes.PUSH,
          data: { push, pushType, stepIndex: i },
          position: defaultNodePos,
        };
        nodesMap.set(nodeId, node);

        createParentChildRelationship(nodesMap, nodeId, previousNodeId);

        /* Push (leads to primary content) */
        if (pushType === PUSH_PAYLOAD_TYPES.CONTENT) {
          previousNodeId = nodeId;
        }
      }

      /* If content exists */
      if (contentRefIds?.length > 0) {
        createContentNode(i, contentRefIds);
      }

      /* If webhook exists */
      if (webhookRefId) {
        const webhook = webhookState.byRefId[webhookRefId];
        const nodeId = `node-${webhookRefId}`;
        const node: Node = {
          id: nodeId,
          type: FlowNodeTypes.WEBHOOK,
          data: { webhook, stepIndex: step.index },
          position: defaultNodePos,
        };

        nodesMap.set(nodeId, node);

        createParentChildRelationship(nodesMap, nodeId, previousNodeId);
      }
    }
  }

  function createContentNode(stepIndex: number, contentRefIds: string[]) {
    for (let i = 0; i < contentRefIds.length; i++) {
      const contentRefId = contentRefIds[i];

      if (contentRefId) {
        const content = contentState.byRefId[contentRefId];

        // Skip rendering optional content node if not enabled
        if (isJourneyActive && content.isOptional && !content.enableOptionalNode) {
          continue;
        }

        const contentName = content.localizations?.[defaultLanguage]?.name;

        const nodeId = `node-${contentRefId}`;
        const node = {
          id: nodeId,
          type: FlowNodeTypes.CONTENT,
          data: { content, contentName, stepIndex },
          position: defaultNodePos,
        };
        nodesMap.set(nodeId, node);

        createParentChildRelationship(nodesMap, nodeId, previousNodeId);
      }
    }
  }

  const initialNodes = [...nodesMap.values()];
  const initialEdges = createEdges(nodesMap);

  // Here we are not extracting the `nodes` in order to the apply
  // a set of manual repositioning logic for the journey use cases
  const { edges } = await positionNodesWithElk(initialNodes, initialEdges, nodesMap);

  // Manual repositioning logic for journey use cases
  repositionOptionalPushNodeChildren(nodesMap);
  repositionContentNodes(nodesMap, dependencyGraph);

  // Extract the repositioned nodes
  const nodes = [...nodesMap.values()];

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('nodesMap: ', nodesMap.entries());

    // eslint-disable-next-line no-console
    console.log('nodes: ', nodes);

    // eslint-disable-next-line no-console
    console.log('edges: ', edges);
  }

  return {
    nodes,
    edges,
  };
};

/**
 * Works with the new step payload that contains id, type, and children
 * Creates a branched flow between journey steps
 */
export const mapBranchedJourney = async (
  journeyState: JourneyState,
  ruleState: RuleState,
  pushState: PushState,
  contentState: ContentState,
  webhookState: WebhookState,
  defaultLanguage: string,
  dependencyGraph?: DependencyGraph,
) => {
  const journeyStatus = journeyState.status;
  const isJourneyActive = journeyStatus !== JOURNEY_STATUSES.INITIAL && journeyStatus !== JOURNEY_STATUSES.DRAFT;

  const START_POS: XYPosition = {
    x: 275,
    y: 0,
  };

  const defaultNodePos: XYPosition = {
    x: START_POS.x - 7.5,
    y: START_POS.y + 150,
  };

  let previousNodeId = 'node-start';

  const visitedStepsMap = new Map<string, boolean>();
  const nodesMap = new Map<string, Node>();

  const startNode: Node = {
    id: 'node-start',
    type: FlowNodeTypes.START,
    data: {
      label: 'Journey Start',
    },
    position: START_POS,
  };
  nodesMap.set('node-start', startNode);

  const parseSteps = (stepId: string, steps: StateStep[], stepsMap: Map<string, StateStep>) => {
    const step = stepsMap.get(stepId);

    if (!step || visitedStepsMap.get(stepId)) {
      return;
    }

    visitedStepsMap.set(stepId, true);

    // ToDo: Support for `preferred` rule
    const ruleRefId = step.rule.restricted || '';
    const pushRefId = step.push || '';
    const contentRefIds = step.content || [];
    const webhookRefId = step.webhook || '';

    const rule = ruleRefId ? ruleState.byRefId[ruleRefId] : null;
    const nodeId = `node-${ruleRefId}`;

    const node: Node = {
      id: nodeId,
      type: FlowNodeTypes.TARGET_AUDIENCE,
      data: { rule, stepIndex: step.index },
      position: defaultNodePos,
    };
    nodesMap.set(nodeId, node);

    /* First step target audience node */
    if (!step.parents || step.parents.length === 0) {
      createParentChildRelationship(nodesMap, nodeId, previousNodeId);
    } /* Subsequent steps target audience node */ else {
      const defaultSourceNodeIds: string[] = [];
      for (const parentStepId of step.parents) {
        const parentStep = stepsMap.get(parentStepId);
        // ToDo: Support for `preferred` rule
        const parentStepRuleRefId = parentStep?.rule.restricted;
        parentStepRuleRefId && defaultSourceNodeIds.push(`node-${parentStepRuleRefId}`);
      }

      const dependencySourceIds = getDependencySourceIds(
        step.index ?? -1,
        steps,
        dependencyGraph,
        JourneyComponentTypes.RULE,
        ruleRefId,
        defaultSourceNodeIds,
      );

      for (const dependencySourceId of dependencySourceIds) {
        createParentChildRelationship(nodesMap, nodeId, dependencySourceId);
      }
    }

    previousNodeId = nodeId;

    /* If push exists */
    checkIfPushExists: if (pushRefId) {
      const push = pushState.byRefId[pushRefId];

      // Skip rendering optional push node if not enabled
      if (isJourneyActive && push.isOptional && !push.enableOptionalNode) {
        break checkIfPushExists;
      }

      // ToDo: Unable to derive from `pushPayloadType` as it's getting set only after navigating to the `Actions` tab in the Touchpoint editor page
      let pushType = PUSH_PAYLOAD_TYPES.BASIC;

      if (!!push.customPayloadData?.contentId || push.pushPayloadType === PUSH_PAYLOAD_TYPES.CONTENT) {
        pushType = PUSH_PAYLOAD_TYPES.CONTENT;
      } else if (!!push.customPayloadData?.url || push.pushPayloadType === PUSH_PAYLOAD_TYPES.WEB_LINK) {
        pushType = PUSH_PAYLOAD_TYPES.WEB_LINK;
      } else if (!!push.customPayloadData?.actionScheme || push.pushPayloadType === PUSH_PAYLOAD_TYPES.ACTION_LINK) {
        pushType = PUSH_PAYLOAD_TYPES.ACTION_LINK;
      }

      const nodeId = `node-${pushRefId}`;
      const node: Node = {
        id: nodeId,
        type: FlowNodeTypes.PUSH,
        data: { push, pushType, stepIndex: step.index },
        position: defaultNodePos,
      };
      nodesMap.set(nodeId, node);

      createParentChildRelationship(nodesMap, nodeId, previousNodeId);
    }

    /* If content exists */
    if (contentRefIds?.length > 0) {
      createContentNode(step.index ?? -1, contentRefIds);
    }

    /* If webhook exists */
    if (webhookRefId) {
      const webhook = webhookState.byRefId[webhookRefId];
      const nodeId = `node-${webhookRefId}`;
      const node: Node = {
        id: nodeId,
        type: FlowNodeTypes.WEBHOOK,
        data: { webhook, stepIndex: step.index },
        position: defaultNodePos,
      };

      nodesMap.set(nodeId, node);

      createParentChildRelationship(nodesMap, nodeId, previousNodeId);
    }

    /* Parse children steps */
    step.children?.forEach((childId) => {
      if (childId) {
        parseSteps(childId, steps, stepsMap);
      }
    });
  };

  const createContentNode = (stepIndex: number, contentRefIds: string[]) => {
    for (let i = 0; i < contentRefIds.length; i++) {
      const contentRefId = contentRefIds[i];

      if (contentRefId) {
        const content = contentState.byRefId[contentRefId];

        // Skip rendering optional content node if not enabled
        if (isJourneyActive && content.isOptional && !content.enableOptionalNode) {
          continue;
        }

        const contentName = content.localizations?.[defaultLanguage]?.name;

        const nodeId = `node-${contentRefId}`;

        const node: Node = {
          id: nodeId,
          type: FlowNodeTypes.CONTENT,
          data: { content, contentName, stepIndex },
          position: defaultNodePos,
        };
        nodesMap.set(nodeId, node);

        const dependencySourceIds = getDependencySourceIds(
          stepIndex,
          steps,
          dependencyGraph,
          JourneyComponentTypes.CONTENT,
          contentRefId,
          [previousNodeId],
        );

        for (const dependencySourceId of dependencySourceIds) {
          createParentChildRelationship(nodesMap, nodeId, dependencySourceId);
        }
      }
    }
  };

  const _steps = cloneDeep(journeyState.steps);
  const stepsMap = createStepsMap(_steps);
  const steps = addParentsToStep(_steps, stepsMap);

  if (steps[0]?.id) {
    parseSteps(steps[0].id, steps, stepsMap);
    addMissingChildrenNodeIds(nodesMap);
  }

  const initialNodes = [...nodesMap.values()];
  const initialEdges = createEdges(nodesMap);

  // Here we are not extracting the `nodes` in order to the apply
  // a set of manual repositioning logic for the journey use cases
  const { edges } = await positionNodesWithElk(initialNodes, initialEdges, nodesMap);

  // Manual repositioning logic for journey use cases
  repositionOptionalPushNodeChildren(nodesMap);
  repositionContentNodes(nodesMap, dependencyGraph);

  // Extract the repositioned nodes
  const nodes = [...nodesMap.values()];

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('nodesMap: ', nodesMap.entries());

    // eslint-disable-next-line no-console
    console.log('nodes: ', nodes);

    // eslint-disable-next-line no-console
    console.log('edges: ', edges);
  }

  return {
    nodes,
    edges,
  };
};

/**
 * End: Flow generator functions
 */
