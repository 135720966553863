import moment from 'moment';
import { ERROR_TYPES, START_TIME } from 'pages/ExperienceCanvas/types';
import { RuleState } from 'store/rule/rule.type';
import { ExperienceSchedule } from 'interface/experience/experience.interface';
import { RootState } from 'store/store';
import { ScheduleFormAbsoluteFields, ScheduleFormContextFields } from 'components/ExperienceCanvas/constants';
import { JourneyError } from 'validators/ExperienceCanvas/types';
import { getStepRuleSyntheticId } from 'store/helper';
import { getBackendJourneyErrors } from 'helpers/error.helper';

export function getRuleErrorList(
  state: RootState,
  ruleRefId: string | undefined,
  isRequired: boolean,
  checkJourneyErrors = true,
): JourneyError[] {
  const ruleState: RuleState = state.te.rule;

  const rule = ruleState.byRefId[ruleRefId ?? ''];
  const retVal: JourneyError[] = [];
  // rulebuilder doesn't currently spit back any errors, so we only check for empty
  // also maybe we need a better check for empty rules.
  if (isRequired && (!ruleRefId || !rule || !rule.logic))
    retVal.push({ type: ERROR_TYPES.INCOMPLETE, err: 'Unconfigured audience constraint' });

  if (!checkJourneyErrors) return retVal;

  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { filteredErrors } = getBackendJourneyErrors(beErrors, state, (err: any) => {
    const excludePaths = Object.values({ ...ScheduleFormAbsoluteFields, ...ScheduleFormContextFields }).map(
      (field) => field.path,
    );
    return err.component && err.component === 'rules' && !excludePaths.find((path) => err.path?.includes(path));
  });

  retVal.push(...filteredErrors);

  return retVal;
}

export function getAbsoluteScheduleErrorList(
  state: RootState,
  stepIdx: number,
  checkJourneyErrors = true,
): JourneyError[] {
  const { status, schedule: journeySchedule } = state.te.journey;

  if (status === 'active' || status === 'scheduled') return [];

  const ruleState = state.te.rule;
  const step = state.te.journey.steps[stepIdx];
  const synthId = getStepRuleSyntheticId(stepIdx);
  const start = ruleState.startSchedulerMap[synthId];
  const end = ruleState.endSchedulerMap[synthId];
  const checkStart = !step?.omitSched && !step?.omitStart;
  const checkEnd = !step?.omitSched && !step?.omitEnd;
  const errors: JourneyError[] = [];

  // need validation for contexualRule after full precondition support implement
  const {
    start: se,
    end: ee,
    journey,
  } = getAbsoluteScheduleErrors(
    state,
    { isActive: checkStart, value: start?.start, hasCronExpression: !!start?.cronExpression },
    { isActive: checkEnd, value: end?.start, hasCronExpression: !!end?.cronExpression },
    journeySchedule,
    checkJourneyErrors,
  );

  errors.push(...se, ...ee, ...journey);

  return errors;
}

export function getContextualScheduleErrorList(state: RootState, checkJourneyErrors = true) {
  // for now this function can only really check rich errs (and maybe constraints)
  if (!checkJourneyErrors) return [];
  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { filteredErrors } = getBackendJourneyErrors(beErrors, state, (err: any) => {
    const paths = Object.values(ScheduleFormContextFields).map((field) => field.path);
    return err.component && err.component === 'rules' && err.path && !!paths.find((path) => err.path.includes(path));
  });

  return filteredErrors;
}

// if we want to make use of forms later, we'll need to wrap it a bit, like the
// push validators
type AbsoluteSchedule = {
  isActive: boolean;
  value?: number | START_TIME;
  hasCronExpression?: boolean;
};

export type AbsoluteErrorReturnValue = {
  start: JourneyError[];
  end: JourneyError[];
  journey: JourneyError[];
};

export function getAbsoluteScheduleErrors(
  state: RootState,
  start: AbsoluteSchedule,
  end: AbsoluteSchedule,
  journeySchedule?: ExperienceSchedule,
  checkJourneyErrors = true,
): AbsoluteErrorReturnValue {
  const retVal: AbsoluteErrorReturnValue = { start: [], end: [], journey: [] };
  const startHasCronExpression = !!start.hasCronExpression;
  const startDateTime = start.value === START_TIME.NOW ? moment().add(5, 'm').add(1, 's').unix() : start.value;
  const endDateTime = end.value === START_TIME.NOW ? moment().add(5, 'm').add(1, 's').unix() : end.value; // just for safety, end.value should never be "NOW"
  if (start.isActive && !startDateTime && !startHasCronExpression) {
    retVal.start.push({ type: ERROR_TYPES.INCOMPLETE, err: 'Start date is unconfigured.' });
  } else if (start.isActive && startDateTime && !startHasCronExpression) {
    if (startDateTime < Date.now() / 1000 + 300)
      retVal.start.push({ type: ERROR_TYPES.ERROR, err: 'Start time must be 5 minutes after the experience launch.' });
    if (
      (journeySchedule?.start && journeySchedule.start > startDateTime) ||
      (journeySchedule?.end && journeySchedule.end < startDateTime)
    )
      retVal.start.push({ type: ERROR_TYPES.ERROR, err: 'Start date falls outside bounds of experience schedule.' });
  }
  if (end.isActive && !endDateTime && !startHasCronExpression) {
    retVal.end.push({ type: ERROR_TYPES.INCOMPLETE, err: 'End date is unconfigured.' });
  } else if (end.isActive && endDateTime) {
    if (endDateTime < Date.now() / 1000 + 300)
      retVal.end.push({ type: ERROR_TYPES.ERROR, err: 'End time must be 5 minutes after the experience launch.' });
    if (start.isActive && startDateTime && endDateTime <= startDateTime)
      retVal.end.push({ type: ERROR_TYPES.ERROR, err: 'End date cannot be at or before the start date.' });
    // first statment is slightly redundant as absolute end cannot exist without
    // absolute start; absolute start must be inside journey schedule.
    if (
      !startHasCronExpression &&
      ((journeySchedule?.start && journeySchedule.start > endDateTime) ||
        (journeySchedule?.end && journeySchedule.end < endDateTime))
    )
      retVal.end.push({ type: ERROR_TYPES.ERROR, err: 'End date falls outside bounds of experience schedule.' });
  }

  if (!checkJourneyErrors) return retVal;
  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { filteredErrors } = getBackendJourneyErrors(beErrors, state, (err: any) => {
    const paths = Object.values(ScheduleFormAbsoluteFields).map((field) => field.path);
    return err.component && err.component === 'rules' && err.path && !!paths.find((path) => err.path.includes(path));
  });
  retVal.journey.push(...filteredErrors);

  return retVal;
}
