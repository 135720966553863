import React from 'react';
import { TIconProps } from '../types';

function EndIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="15"
      height="17"
      viewBox="0 0 15 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.36 2L7.76 4H13V10H9.64L9.24 8H2V2H7.36ZM9 0H0V17H2V10H7.6L8 12H15V2H9.4L9 0Z" fill={fill} />
    </svg>
  );
}

export default EndIcon;
