import React from 'react';
import './ErrorPage.scss';
import SystemErrorImage from 'assets/images/illustration-system-error.svg';

export const ErrorPage = () => {
  const DEFAULT_CLASS = 'main-content-error';
  return (
    <div className={`${DEFAULT_CLASS} main-content`}>
      <div className={`${DEFAULT_CLASS}__text`}>
        <h3 className={`${DEFAULT_CLASS}__text__title`}>System error!</h3>
        <div className={`${DEFAULT_CLASS}__text__desc `}>
          Looks like something went wrong, please try to login again or refresh the page.
        </div>
      </div>
      <div className={`${DEFAULT_CLASS}__image`}>
        <div
          className={`${DEFAULT_CLASS}__image__container`}
          style={{ backgroundImage: `url(${SystemErrorImage})` }}
        ></div>
      </div>
    </div>
  );
};
