import React from 'react';
import { TIconProps } from '../types';

function NavigateTouchpointIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2230_147804)">
        <path d="M10.35 10L8 12.35L15.6333 20L8 27.65L10.35 30L20.35 20L10.35 10Z" fill={fill} />
        <path d="M22.7001 10L20.3501 12.35L27.9834 20L20.3501 27.65L22.7001 30L32.7001 20L22.7001 10Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_2230_147804">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NavigateTouchpointIcon;
