import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Libraries } from '@googlemaps/js-api-loader';

type GoogleMapWrapperProps = {
  apiKey: string;
  version: string;
  libraries: Libraries;
};

const GoogleMapWrapper: React.FC<GoogleMapWrapperProps> = ({ apiKey, version, libraries, children }) => {
  return (
    <Wrapper apiKey={apiKey} version={version} libraries={libraries}>
      {children}
    </Wrapper>
  );
};

export default GoogleMapWrapper;
