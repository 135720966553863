import { JourneyRulePayload, Scheduler, Predicate } from 'interface/rule/rule.interface';
import { Dependencies } from 'interface/common.interface';

// trigger off of the SET_INSTANCE dispatch call
// but to avoid mutating the state too much at this point, we are accessing
// thesese new reducers through an all new branch of the root reducer
// currently this uses preexisting interfaces in the interest of time
// in the future, this should be its own type
export interface RuleState {
  byRefId: {
    [refId: string]: RuleStateItem;
  };
  allRefId: string[];
  startSchedulerMap: {
    [refId: string]: SchedulerStateItem;
  };
  endSchedulerMap: {
    [refId: string]: SchedulerStateItem;
  };
  bySyntheticId: {
    [synthId: string]: string[];
  };
}

export interface RuleStateItem extends JourneyRulePayload {
  refId: string;
  isOptional?: boolean;
  ruleType?: 'restricted' | 'preferred';
  templateId?: string;
  id?: string;
  dependencies?: Dependencies;
  hasError?: boolean;
  // keeping this option on RuleState for now, as theoretically both preferred and restricted can be different in optionality
  enableOptionalNode?: boolean; //UI
}

export interface SchedulerStateItem extends Omit<Scheduler, 'contextualRule' | 'repeat'> {
  // contexualRule
  logic?: string;
  modifier?: string;
  predicates?: Predicate | null;
  reevaluateOnReschedule?: boolean;
  target?: string;
  // repeat
  frequencyType?: string;
  frequency?: number;
  limit?: number;
  cronExpression?: string;
}

export type UpdateRulesActionPayload = {
  refId: string;
  type: string;
  templateType: string;
  stepIdx?: number;
};

export type RuleTriggerType = 'omit' | 'default' | 'context' | 'rule';

export enum RuleActionTypes {
  UPDATE_RULE = 'UPDATE_RULE',
  UPDATE_RULE_SIMPLE = 'UPDATE_RULE_SIMPLE',
  UPDATE_RULE_TRIGGER = 'UPDATE_RULE_TRIGGER',
  REMOVE_RULE = 'REMOVE_RULE',
  INSERT_RULE = 'INSERT_RULE',
  SAVE_CONTEXTUAL_SCHEDULER = 'SAVE_CONTEXTUAL_SCHEDULER',
}
