import {
  LocationActionTypes,
  SetLocationPayload,
  SetMatchingLocationsWithLabelsPayload,
  SetSaveLocationPayload,
} from 'store/location/location.type';

export type LocationAction = {
  type: LocationActionTypes;
  payload: SetLocationPayload & SetMatchingLocationsWithLabelsPayload & SetSaveLocationPayload;
};

export function setLocations(payload: SetLocationPayload) {
  return {
    type: LocationActionTypes.SET_LOCATIONS,
    payload,
  };
}

export function setMatchingLocationsWithLabels(payload: SetMatchingLocationsWithLabelsPayload) {
  return {
    type: LocationActionTypes.SET_MATCHING_LOCATIONS_WITH_LABELS,
    payload,
  };
}

export function setSaveLocation(payload: SetSaveLocationPayload) {
  return {
    type: LocationActionTypes.SET_SAVE_LOCATION,
    payload,
  };
}
