import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import uniqueId from 'lodash/uniqueId';
import Storage from 'services/storage';
import { getTenantId } from 'helpers/templated-experience.helper';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import PencilEditIcon from 'pages/ExperienceCanvas/assets/icons/PencilEditIcon';
import DeleteIcon from 'pages/ExperienceCanvas/assets/icons/DeleteIcon';

import './IconUploadPreview.scss';

type IconUploadPreviewProps = {
  iconUrl: string;
  inputElementId?: string;
  maxWidth?: number;
  aspectRatio?: number[];
  containerDimensions?: { width: number; height: number };
  disableEdit?: boolean;
  enableDelete?: boolean;
  onError?: (err: AxiosError) => void;
  onSuccess?: (imageUrl?: string) => void;
};

const MAIN_CLASS = 'icon-upload-preview';
const ACTIONS_CLASS = `${MAIN_CLASS}__actions`;
const IMAGE_CLASS = `${MAIN_CLASS}__image`;
const DEFAULT_ICON_MODIFIER = `${IMAGE_CLASS}--default-icon`;
const INPUT_CONTAINER_CLASS = `${MAIN_CLASS}__input-container`;
const LOADING_CLASS = `${MAIN_CLASS}__loading`;

const IconUploadPreview: React.FC<IconUploadPreviewProps> = ({
  iconUrl,
  inputElementId,
  containerDimensions = { width: 48, height: 48 },
  disableEdit = false,
  enableDelete = false,
  onError,
  onSuccess,
}) => {
  const image = useMemo(() => new Image(), []);
  const storage = useMemo(() => new Storage(), []);
  const fm = useMemo(() => new FormData(), []);

  const [isBusy, setIsBusy] = useState(false);
  const [currentIconUrl, setCurrentIconUrl] = useState<string | undefined>(iconUrl);
  const { current: elementId } = useRef(inputElementId || uniqueId('icon-upload-'));
  const iconInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;
    if (!files || files.length < 1) return;
    const url = URL.createObjectURL(files?.[0] as File);

    setIsBusy(true);

    image.src = url;
    image.addEventListener('load', async () => {
      const tenantId = getTenantId();
      const token = await storage.getItem(`${tenantId}+token`);

      fm.append('image', files[0]);
      fm.append('proxyTarget', `${process.env.REACT_APP_API_URL}/kernel/file-manager/files/upload`);
      fm.append(
        'proxyHeaders',
        JSON.stringify({
          'x-authorization': token,
        }),
      );
      fm.append('proxyFileParam', 'image');

      try {
        const { data } = await axios.post('https://pixelpusher.solutions-us.flybits.com/upload', fm);
        onSuccess?.(data[0]?.url);
      } catch (err) {
        if (onError) onError(err as AxiosError);
      } finally {
        setIsBusy(false);
      }
      URL.revokeObjectURL(url);
    });
  };

  const handleEditIcon = () => {
    iconInputRef.current?.click();
  };

  const handleRemoveIcon = () => {
    setCurrentIconUrl(undefined);
    onSuccess?.();
  };

  const getIconUrl = () => currentIconUrl || '/assets/default-icons/default-experience-icon.png';

  useEffect(() => {
    setCurrentIconUrl(iconUrl);
  }, [iconUrl]);

  return (
    <div
      className={MAIN_CLASS}
      style={{ width: `${containerDimensions.width}px`, height: `${containerDimensions.height}px` }}
    >
      <div
        className={`${IMAGE_CLASS} ${!currentIconUrl ? DEFAULT_ICON_MODIFIER : ''}`}
        style={{ backgroundImage: `url(${getIconUrl()})` }}
      />
      {!disableEdit && (
        <div className={ACTIONS_CLASS}>
          <button className={`${ACTIONS_CLASS}__change`} onClick={handleEditIcon}>
            <PencilEditIcon fill="#2371f1" />
          </button>
          {enableDelete && (
            <button className={`${ACTIONS_CLASS}__remove`} onClick={handleRemoveIcon}>
              <DeleteIcon fill="#b32013" />
            </button>
          )}
        </div>
      )}
      <div className={INPUT_CONTAINER_CLASS}>
        <label className={`${INPUT_CONTAINER_CLASS}__input-label`} htmlFor={elementId}>
          <input
            type="file"
            id={elementId}
            accept=".jpeg,.jpg,.png,.svg"
            multiple={false}
            ref={iconInputRef}
            onChange={handleFileChange}
          />
        </label>
      </div>
      {isBusy && (
        <div className={LOADING_CLASS}>
          <LoadingIcon />
        </div>
      )}
    </div>
  );
};

export default IconUploadPreview;
