import React, { useState, useMemo } from 'react';

import {
  TCustomModuleAnalyticsParams,
  initialContextValue,
  CustomModuleAnalyticsContext,
} from './CustomModuleAnalyticsContext';

type TCustomModuleAnalyticsProviderProps = {
  children: React.ReactNode;
};

const CustomModuleAnalyticsProvider = ({ children }: TCustomModuleAnalyticsProviderProps) => {
  const [customModuleState, setCustomModuleState] = useState<TCustomModuleAnalyticsParams>(initialContextValue);

  const memoizedContextValue = useMemo(
    () => ({
      customModuleState,
      setCustomModuleState,
    }),
    [customModuleState, setCustomModuleState],
  );

  return (
    <CustomModuleAnalyticsContext.Provider value={memoizedContextValue}>
      {children}
    </CustomModuleAnalyticsContext.Provider>
  );
};

export default CustomModuleAnalyticsProvider;
