import { useEffect, useMemo } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import SettingAPI from 'services/api/setting.api';

export default function useSettings() {
  //  TODO: migrate to react query and set up proper typing
  const settingAPI = useMemo(() => new SettingAPI(), []);

  const state = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.isInitialized) return;
    settingAPI
      .getLanguages()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((r: any) => {
        const languages = [
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          r.settings.data.find((language: any) => language.isDefault === true),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...r.settings.data.filter((language: any) => language.isDefault === false),
        ];
        dispatch({
          type: 'SET_LANGUAGES',
          payload: {
            languages,
            previewTextDirection: languages[0]?.direction || 'ltr',
            previewTextLanguage: languages[0]?.id || 'en',
          },
        });
      })
      .catch(() => {
        dispatch({ type: 'SET_LANGUAGES', payload: { languages: [{ id: 'en', isDefault: true, name: 'English' }] } });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    languages: state.languages,
    isInitialized: state.isInitialized,
    previewTextDirection: state.previewTextDirection,
    previewTextLanguage: state.previewTextLanguage,
  };
}
