import React from 'react';
import { TIconProps } from '../types';

function PencilEditIcon({ fill = 'currentColor', className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 14.2525V18.002H3.74948L14.8079 6.94349L11.0585 3.19401L0 14.2525ZM17.7075 4.04389C18.0975 3.65395 18.0975 3.02403 17.7075 2.63409L15.3679 0.294412C14.9779 -0.0955333 14.348 -0.0955333 13.9581 0.294412L12.1283 2.12416L15.8778 5.87364L17.7075 4.04389Z"
        fill={fill}
      />
    </svg>
  );
}

export default PencilEditIcon;
