import { MerchantFormValues, VERIFICATION_STATUSES } from 'pages/MerchantPortal/merchant-portal.types';
import * as Yup from 'yup';

export const MERCHANT_EMPTY_PAGE_TEXTS = {
  HEADING: 'Get Started with Local Merchants',
  TITLE: 'Currently, there are no local merchants added yet.',
  DESCRIPTION: 'Add a local merchant now to provide your customers with special offers and cashback.',
};

export const MERCHANT_EMPTY_PAGE_BUTTON_LABELS = {
  ADD_MERCHANT: 'Add a local merchant',
};

export const MERCHANT_FORM_INIT_VALS: MerchantFormValues = {
  name: '',
  externalId: '',
  category: null,
  logo: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  contact: {
    name: '',
    phone: '',
    email: '',
  },
  verification: null,
};

export const US_STATES = [
  // {
  //   name: 'Alabama',
  //   code: 'AL',
  // },
  // {
  //   name: 'Alaska',
  //   code: 'AK',
  // },
  // {
  //   name: 'Arizona',
  //   code: 'AZ',
  // },
  // {
  //   name: 'Arkansas',
  //   code: 'AR',
  // },
  // {
  //   name: 'California',
  //   code: 'CA',
  // },
  // {
  //   name: 'Colorado',
  //   code: 'CO',
  // },
  // {
  //   name: 'Connecticut',
  //   code: 'CT',
  // },
  // {
  //   name: 'Delaware',
  //   code: 'DE',
  // },
  // {
  //   name: 'Florida',
  //   code: 'FL',
  // },
  // {
  //   name: 'Georgia',
  //   code: 'GA',
  // },
  // {
  //   name: 'Hawaii',
  //   code: 'HI',
  // },
  // {
  //   name: 'Idaho',
  //   code: 'ID',
  // },
  // {
  //   name: 'Illinois',
  //   code: 'IL',
  // },
  // {
  //   name: 'Indiana',
  //   code: 'IN',
  // },
  // {
  //   name: 'Iowa',
  //   code: 'IA',
  // },
  // {
  //   name: 'Kansas',
  //   code: 'KS',
  // },
  // {
  //   name: 'Kentucky',
  //   code: 'KY',
  // },
  // {
  //   name: 'Louisiana',
  //   code: 'LA',
  // },
  // {
  //   name: 'Maine',
  //   code: 'ME',
  // },
  // {
  //   name: 'Maryland',
  //   code: 'MD',
  // },
  // {
  //   name: 'Massachusetts',
  //   code: 'MA',
  // },
  // {
  //   name: 'Michigan',
  //   code: 'MI',
  // },
  // {
  //   name: 'Minnesota',
  //   code: 'MN',
  // },
  // {
  //   name: 'Mississippi',
  //   code: 'MS',
  // },
  // {
  //   name: 'Missouri',
  //   code: 'MO',
  // },
  // {
  //   name: 'Montana',
  //   code: 'MT',
  // },
  // {
  //   name: 'Nebraska',
  //   code: 'NE',
  // },
  // {
  //   name: 'Nevada',
  //   code: 'NV',
  // },
  // {
  //   name: 'New Hampshire',
  //   code: 'NH',
  // },
  // {
  //   name: 'New Jersey',
  //   code: 'NJ',
  // },
  // {
  //   name: 'New Mexico',
  //   code: 'NM',
  // },
  // {
  //   name: 'New York',
  //   code: 'NY',
  // },
  // {
  //   name: 'North Carolina',
  //   code: 'NC',
  // },
  // {
  //   name: 'North Dakota',
  //   code: 'ND',
  // },
  // {
  //   name: 'Ohio',
  //   code: 'OH',
  // },
  // {
  //   name: 'Oklahoma',
  //   code: 'OK',
  // },
  // {
  //   name: 'Oregon',
  //   code: 'OR',
  // },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  // {
  //   name: 'Rhode Island',
  //   code: 'RI',
  // },
  // {
  //   name: 'South Carolina',
  //   code: 'SC',
  // },
  // {
  //   name: 'South Dakota',
  //   code: 'SD',
  // },
  // {
  //   name: 'Tennessee',
  //   code: 'TN',
  // },
  // {
  //   name: 'Texas',
  //   code: 'TX',
  // },
  // {
  //   name: 'Utah',
  //   code: 'UT',
  // },
  // {
  //   name: 'Vermont',
  //   code: 'VT',
  // },
  // {
  //   name: 'Virginia',
  //   code: 'VA',
  // },
  // {
  //   name: 'Washington',
  //   code: 'WA',
  // },
  // {
  //   name: 'West Virginia',
  //   code: 'WV',
  // },
  // {
  //   name: 'Wisconsin',
  //   code: 'WI',
  // },
  // {
  //   name: 'Wyoming',
  //   code: 'WY',
  // },
];

export const VerificationStatusOptions = [
  { key: VERIFICATION_STATUSES.PENDING, name: 'Pending Verification' },
  { key: VERIFICATION_STATUSES.VERIFIED, name: 'Verified' },
  { key: VERIFICATION_STATUSES.FAILED, name: 'Verification Failed' },
  { key: VERIFICATION_STATUSES.PENDING_DELETION, name: 'Pending Deletion' },
  { key: VERIFICATION_STATUSES.DELETED, name: 'Deleted' },
];

export const phoneRegex = /^\([0-9]{3}\)\s[0-9]{3}\s-\s[0-9]{4}$/;
export const addressRegex = /^[a-zA-Z0-9\s,.'-]{3,}$/;
export const zipRegex = /^\d{5}(-\d{4})?$/;
// https://emailregex.com/
export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const MerchantValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name cannot be empty').max(43, 'Name cannot be longer than 43 characters'),
  id: Yup.string(),
  externalId: Yup.string().required('Merchant ID cannot be empty'),
  category: Yup.string().required('Category cannot be empty').nullable(),
  logo: Yup.string(),
  phone: Yup.string().required('Phone number cannot be empty').matches(phoneRegex, 'Invalid phone number'),
  address: Yup.string().required('Address cannot be empty').matches(addressRegex, 'Invalid address'),
  city: Yup.string().required('City cannot be empty'),
  state: Yup.string()
    .required('State cannot be empty')
    .oneOf(
      US_STATES.map((s) => s.code),
      'Invalid state',
    ),
  zip: Yup.string().required('Zip code cannot be empty').matches(zipRegex, 'Invalid zip code'),
  contact: Yup.object().shape({
    name: Yup.string(),
    phone: Yup.string().matches(phoneRegex, 'Invalid phone number'),
    email: Yup.string().matches(emailRegex, 'Invalid email address'),
  }),
  verification: Yup.object()
    .shape({
      verificationStatus: Yup.string()
        .oneOf(Object.values(VERIFICATION_STATUSES))
        .required('Verification status cannot be empty'),
      notes: Yup.string(),
    })
    .nullable()
    .default(null),
});
