import React from 'react';
import './Graphs.scss';
import { ResponsivePie } from '@nivo/pie';
import { GraphData } from 'components/Analytics/types';
import { ReactComponent as IosBlue } from 'assets/icons/ios_blue.svg';
import { ReactComponent as AndroidPink } from 'assets/icons/android_pink.svg';
import { ReactComponent as Device } from 'assets/icons/device.svg';

type TGraphProps = {
  data: GraphData[];
  innerRadius?: number;
};

const Icons = (id: string) => {
  switch (id) {
    case 'android':
      return <AndroidPink />;
    case 'ios':
      return <IosBlue />;
    default:
      return <Device />;
  }
};

export default function PieGraphDevice(props: TGraphProps) {
  const renderResponsivePie = () => (
    <ResponsivePie
      data={props.data}
      margin={{ top: 10, right: 40, bottom: 10, left: 0 }}
      innerRadius={props.innerRadius}
      padAngle={0.7}
      colors={{ datum: 'data.color' }}
      cornerRadius={0}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      theme={{
        legends: {
          text: {
            fontSize: 14,
          },
        },
      }}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          translateX: 30,
          translateY: 0,
          itemsSpacing: 10,
          itemWidth: 60,
          itemHeight: 14,
          itemDirection: 'left-to-right',
          itemTextColor: '#999',
          symbolSize: 15,
          // eslint-disable-next-line react/prop-types
          symbolShape: (props) => Icons(`${props.id}`),
        },
      ]}
    />
  );

  //(['android', 'ios', 'other'].includes(`${props.id}`) ? <AndroidPink /> : <IosBlue />),
  return <div className="pie-chart-device">{renderResponsivePie()}</div>;
}
