import { JOURNEY_STATUSES } from 'store/journey/journey.type';
import { useAppSelector as useSelector } from './reduxHooks';

// useJourneyStatus hook
export default function useJourneyStatus() {
  const status = useSelector((state) => state.te.journey.status);
  const hasActivationDate = useSelector((state) => !!state.te.journey.activatedAt);
  const lockedStatuses = [JOURNEY_STATUSES.ACTIVE, JOURNEY_STATUSES.SCHEDULED];

  return {
    journeyStatus: status,
    isJourneyLocked: lockedStatuses.includes(status),
    hasActivationDate,
  };
}
