import React from 'react';
import { IconProps } from './icon.types';

function ModuleLabelIcon(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.367 7.90225L14.2442 6.77798C14.2405 6.77423 14.2397 6.76901 14.236 6.76526C14.2322 6.76151 14.227 6.76072 14.2232 6.75697L9.96777 2.49698C9.45477 1.98323 8.74372 1.68848 8.01697 1.68848H3.71426C2.59751 1.68848 1.68848 2.59823 1.68848 3.71573V8.02301C1.68848 8.76026 1.97575 9.45325 2.49625 9.9745L6.75326 14.236C6.75552 14.2383 6.75623 14.2413 6.75848 14.2435C6.76073 14.2458 6.76374 14.2465 6.76599 14.2487L7.89703 15.3805C8.51503 15.9993 9.17125 16.3135 9.84775 16.3135C10.5235 16.3135 11.1797 15.9993 11.7977 15.3805L15.3692 11.806C16.6247 10.5468 16.6248 9.16075 15.367 7.90225ZM2.81274 8.02223V3.715C2.81274 3.217 3.21703 2.81274 3.71353 2.81274H8.01623C8.44598 2.81274 8.86751 2.98749 9.17126 3.29124L13.0405 7.16425L7.15674 13.048L3.2912 9.17799C2.9867 8.87424 2.81274 8.45273 2.81274 8.02223ZM14.572 11.0102L11.0005 14.5847C10.201 15.385 9.49075 15.385 8.69125 14.5847L7.95178 13.8445L13.8355 7.96075L14.5712 8.69725C15.3917 9.5185 15.3917 10.1882 14.572 11.0102ZM6.00024 5.25024C6.00024 5.66424 5.66571 6.00024 5.25171 6.00024C4.83921 6.00024 4.50024 5.66424 4.50024 5.25024C4.50024 4.83624 4.83178 4.50024 5.24503 4.50024H5.25253C5.66578 4.50024 6.00024 4.83624 6.00024 5.25024Z"
        fill="#051336"
      />
    </svg>
  );
}

export default ModuleLabelIcon;
