import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';

import { ContentListItem, TOUCHPOINT_ACTIONS, TOUCHPOINT_STATUSES } from '../types';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { EVENT_KEYS } from 'types/events';

import PencilEditIcon from 'pages/ExperienceCanvas/assets/icons/PencilEditIcon';
import CompleteIcon from 'pages/ExperienceCanvas/assets/icons/CompleteIcon';
import IncompleteIcon from 'pages/ExperienceCanvas/assets/icons/IncompleteIcon';
import ErrorIcon from 'pages/ExperienceCanvas/assets/icons/ErrorIcon';
import DeleteIcon from 'pages/ExperienceCanvas/assets/icons/DeleteIcon';
import PushNotificationIcon from 'pages/ExperienceCanvas/assets/icons/PushNotificationIcon';
import ConnectionLineIcon from 'pages/ExperienceCanvas/assets/shapes/ConnectionLine';
import PrimaryContentIcon from 'pages/ExperienceCanvas/assets/icons/PrimaryContentIcon';
import ScheduleIcon from 'pages/ExperienceCanvas/assets/icons/ScheduleIcon';
import Triangle from 'pages/ExperienceCanvas/assets/shapes/Triangle';

import SmartphonePreview from '../SmartphonePreview/SmartphonePreview';

import { TErrorListModal } from 'components/Modal/ErrorListModal/ErrorListModal';

import useSettings from 'hooks/useSetting';

import { validateStep } from 'validators/ExperienceCanvas/journey.validator';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';

import ContentAPI from 'services/api/content.api';
import { RootState } from 'store/store';
import { getContentListItems } from 'store/content/content.selector';
import { extractContentPreviewFields } from 'helpers/content.helper';
import { showContentBox as showContentPreview, getContentGrouping } from 'store/journey/journey.selector';
import { useQuery } from '@tanstack/react-query';

import './TouchpointCard.scss';
import { getStepRuleSyntheticId } from 'store/helper';
import IconUploadPreview from '../IconUploadPreview/IconUploadPreview';

type TTouchpointCardProps = {
  stepIdx: number;
  title: string;
  subtitle?: string;
  showFooter: boolean;
  iconUrl?: string;
  onEdit: () => void;
  onDelete: () => void;
  onClickFooter: (modalProps: Omit<TErrorListModal, 'onClose'>) => void;
};

function TouchpointCard({
  stepIdx,
  title,
  subtitle,
  showFooter,
  iconUrl,
  onEdit,
  onDelete,
  onClickFooter,
}: TTouchpointCardProps) {
  const contentAPI = useMemo(() => new ContentAPI(), []);

  const pencilEditIconFillColor = '#2371F1';
  const deleteIconFillColor = '#FF541E';

  const statusIconFillColors = {
    [TOUCHPOINT_STATUSES.COMPLETE]: '#00AB50',
    [TOUCHPOINT_STATUSES.INCOMPLETE]: '#FFB005',
    [TOUCHPOINT_STATUSES.ERROR]: '#FF6E46',
  };

  const statusIcons = {
    [TOUCHPOINT_STATUSES.COMPLETE]: CompleteIcon,
    [TOUCHPOINT_STATUSES.INCOMPLETE]: IncompleteIcon,
    [TOUCHPOINT_STATUSES.ERROR]: ErrorIcon,
  };

  const scheduleIcon = {
    fill: '#0851DF',
    activeFill: '#1237b5',
  };
  const pushNotificationIcon = {
    fill: '#FFB005',
    activeFill: '#FF8800',
  };
  const primaryContentIcon = {
    fill: '#8B6DFF',
    activeFill: '#6434DC',
  };

  const [activeAction, setActiveAction] = useState<
    | TOUCHPOINT_ACTIONS.SCHEDULE
    | TOUCHPOINT_ACTIONS.PUSH
    | TOUCHPOINT_ACTIONS.PRIMARY_CONTENT
    | TOUCHPOINT_ACTIONS.SECONDARY_CONTENT
    | null
  >(null);
  const scheduleConnectionLineStrokeDasharray = activeAction === TOUCHPOINT_ACTIONS.SCHEDULE ? '0' : '4';
  const pushConnectionLineStrokeDasharray = activeAction === TOUCHPOINT_ACTIONS.PUSH ? '0' : '4';
  const stepRuleSyntheticId = getStepRuleSyntheticId(stepIdx);

  // i'm a little uncertain of what will happen when 400s start returning
  // we can consider moving error handling into state, or writing a more complex
  // function that'll merge front end errors with back end
  const errorStatus = useSelector((state) => {
    const { errorStatus } = validateStep(state, stepIdx);
    if (errorStatus === '') {
      return TOUCHPOINT_STATUSES.COMPLETE;
    } else if (errorStatus === ERROR_TYPES.INCOMPLETE) {
      return TOUCHPOINT_STATUSES.INCOMPLETE;
    } else {
      return TOUCHPOINT_STATUSES.ERROR;
    }
  });

  const StatusIcon = statusIcons[errorStatus];

  const handleClickPushPreview = () => {
    if (activeAction !== TOUCHPOINT_ACTIONS.PUSH) {
      setActiveAction(TOUCHPOINT_ACTIONS.PUSH);
    }
  };
  const handleClickPrimaryContentPreview = () => {
    if (activeAction !== TOUCHPOINT_ACTIONS.PRIMARY_CONTENT) {
      setActiveAction(TOUCHPOINT_ACTIONS.PRIMARY_CONTENT);
    }
  };
  const handleClickSecondaryContentPreview = () => {
    if (activeAction !== TOUCHPOINT_ACTIONS.SECONDARY_CONTENT) {
      setActiveAction(TOUCHPOINT_ACTIONS.SECONDARY_CONTENT);
    }
  };

  const handleClickTouchpointFooter = () => {
    if (errorStatus !== TOUCHPOINT_STATUSES.COMPLETE) {
      onClickFooter({
        isVisible: true,
        stepIdx,
      });
    }
  };

  const { omitStart, isRequired } = useSelector((state) => state.te.journey.steps[stepIdx]);
  const { startSchedulerMap } = useSelector((state) => state.te.rule);

  const push = useSelector((state: RootState) => {
    const refId = state.te.journey.steps[stepIdx]?.push;
    const pushStateItem = state.te.push.byRefId[refId];
    const showPush = !pushStateItem?.isOptional || pushStateItem?.enableOptionalNode;
    return showPush ? pushStateItem : undefined;
  });

  const showPrimaryContent = useSelector((state) => showContentPreview(state, stepIdx));
  const isStepEmpty = useSelector(
    (state) => !state.te.journey.steps[stepIdx]?.push && !state.te.journey.steps[stepIdx]?.content.length,
  );
  const contentsFromState: ContentListItem[] = useSelector((state) => getContentListItems(state, stepIdx));

  const contentGroupingProtoId = useSelector((state) => {
    const groupingRefId = getContentGrouping(state, stepIdx);
    const groupingAction = groupingRefId ? state.te.content.byRefId[groupingRefId] : undefined;

    return groupingAction?.prototypeId;
  });

  const { languages, isInitialized: isLangInit } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const selectedLang = isLangInit ? defaultLang : '';

  useEffect(() => {
    if (push) {
      setActiveAction(TOUCHPOINT_ACTIONS.PUSH);
    } else if (showPrimaryContent) {
      setActiveAction(TOUCHPOINT_ACTIONS.PRIMARY_CONTENT);
    }
  }, [push, showPrimaryContent]);

  const { data: groupingFilteredContents } = useQuery<ContentListItem[]>({
    queryKey: ['cgf', stepIdx, contentGroupingProtoId, defaultLang],
    queryFn: async () => {
      if (contentGroupingProtoId) {
        const { data: contentInstances } = await contentAPI.getContentInstances({
          data: true,
          templatePrototypeID: contentGroupingProtoId,
        });

        if (contentInstances)
          return contentInstances.map((ci) => {
            const extractedContent = extractContentPreviewFields(ci, defaultLang);
            return {
              refId: '',
              stepIdx,
              name: '',
              metadataDescription: '',
              type: 'secondary',
              isRequired: true,
              templateIdConstraint: '',
              isReadOnly: true,
              ...extractedContent,
            };
          });
      }

      return [];
    },
    enabled: !!contentGroupingProtoId,
    placeholderData: [],
  });

  const showSecondaryContent =
    Array.isArray(contentsFromState) && contentsFromState.length + groupingFilteredContents.length > 1;

  return (
    <div className="touchpoint-card">
      <div className="touchpoint-card__header">
        <div className="touchpoint-card__header__title-container">
          {iconUrl ? (
            <IconUploadPreview iconUrl={iconUrl} disableEdit />
          ) : (
            <div
              className="touchpoint-card__header__title-container__touchpoint-number"
              aria-label={`touchpoint ${stepIdx + 1}`}
            >
              {stepIdx + 1}
            </div>
          )}
          <div className="touchpoint-card__header__title-container__text-container">
            <div
              className="touchpoint-card__header__title-container__text-container__title"
              aria-label={`touchpoint title ${title}`}
            >
              {title}
            </div>
            {subtitle && (
              <div
                className="touchpoint-card__header__title-container__text-container__sub-title"
                aria-label={`touchpoint sub title ${subtitle}`}
              >
                {subtitle}
              </div>
            )}
          </div>
        </div>
        <div className="touchpoint-card__header__actions">
          <div
            className="touchpoint-card__header__icon-container"
            aria-label={`edit touchpoint ${stepIdx + 1}`}
            tabIndex={0}
            role="button"
            onClick={onEdit}
            onKeyDown={(e) => {
              if (e.key === EVENT_KEYS.ENTER) {
                onEdit();
              }
            }}
          >
            <PencilEditIcon fill={pencilEditIconFillColor} />
          </div>
          {!isRequired && (
            <div
              className="touchpoint-card__header__icon-container"
              aria-label={`delete touchpoint ${stepIdx + 1}`}
              tabIndex={0}
              role="button"
              onClick={onDelete}
              onKeyDown={(e) => {
                if (e.key === EVENT_KEYS.ENTER) {
                  onDelete();
                }
              }}
            >
              <DeleteIcon fill={deleteIconFillColor} />
            </div>
          )}
        </div>
      </div>
      <div className="touchpoint-card__body">
        <div
          className={`touchpoint-card__body__actions-header ${
            showSecondaryContent ? 'touchpoint-card__body__actions-header--with-secondary-content' : ''
          }`}
        >
          <div className="touchpoint-card__body__actions-header__push-and-content">
            {omitStart === false && startSchedulerMap?.[stepRuleSyntheticId]?.start !== 0 && (
              <>
                <div
                  className="touchpoint-card-schedule-icon-container"
                  aria-label={`touchpoint ${stepIdx} is scheduled`}
                >
                  <ScheduleIcon fill={scheduleIcon.fill} />
                </div>
                <div
                  className={`touchpoint-card-connection-line-container${
                    activeAction === TOUCHPOINT_ACTIONS.PUSH
                      ? ' touchpoint-card-connection-line-container--is-push-selected'
                      : ' touchpoint-card-connection-line-container--is-content-selected'
                  }`}
                >
                  <div className="touchpoint-card-connection-line-container__arrow touchpoint-card-connection-line-container__arrow--after-schedule">
                    <Triangle />
                  </div>
                  <ConnectionLineIcon
                    width={showSecondaryContent ? 25 : 30}
                    strokeDasharray={scheduleConnectionLineStrokeDasharray}
                  />
                </div>
              </>
            )}
            {push && (
              <div
                className={`touchpoint-card-push-icon-container ${
                  activeAction === TOUCHPOINT_ACTIONS.PUSH ? 'touchpoint-card-push-icon-container--active' : ''
                }`}
                aria-label={`show push preview for touchpoint ${stepIdx + 1}`}
                tabIndex={0}
                role="button"
                onClick={handleClickPushPreview}
                onKeyDown={(e) => {
                  if (e.key === EVENT_KEYS.ENTER) {
                    handleClickPushPreview();
                  }
                }}
              >
                <PushNotificationIcon
                  fill={
                    activeAction === TOUCHPOINT_ACTIONS.PUSH
                      ? pushNotificationIcon.activeFill
                      : pushNotificationIcon.fill
                  }
                />
              </div>
            )}
            {showPrimaryContent && (
              <>
                <div
                  className={`touchpoint-card-connection-line-container${
                    activeAction === TOUCHPOINT_ACTIONS.PUSH
                      ? ' touchpoint-card-connection-line-container--is-push-selected'
                      : ' touchpoint-card-connection-line-container--is-content-selected'
                  }`}
                >
                  {push && (
                    <>
                      <div className="touchpoint-card-connection-line-container__arrow">
                        <Triangle />
                      </div>
                      <ConnectionLineIcon
                        width={showSecondaryContent ? 30 : 60}
                        strokeDasharray={pushConnectionLineStrokeDasharray}
                      />
                    </>
                  )}
                </div>
                <div
                  className={`touchpoint-card-content-icon-container ${
                    activeAction === TOUCHPOINT_ACTIONS.PRIMARY_CONTENT
                      ? 'touchpoint-card-content-icon-container--active'
                      : ''
                  }`}
                  aria-label={`show primary content preview for touchpoint ${stepIdx + 1}`}
                  tabIndex={0}
                  role="button"
                  onClick={handleClickPrimaryContentPreview}
                  onKeyDown={(e) => {
                    if (e.key === EVENT_KEYS.ENTER) {
                      handleClickPrimaryContentPreview();
                    }
                  }}
                >
                  <PrimaryContentIcon
                    fill={
                      activeAction === TOUCHPOINT_ACTIONS.PRIMARY_CONTENT
                        ? primaryContentIcon.activeFill
                        : primaryContentIcon.fill
                    }
                  />
                </div>
              </>
            )}
          </div>
          {showSecondaryContent && (
            <div className="touchpoint-card__body__actions-header__secondary-content-num">
              <div
                className={`touchpoint-card-content-icon-container touchpoint-card-content-icon-container--secondary ${
                  activeAction === TOUCHPOINT_ACTIONS.SECONDARY_CONTENT
                    ? 'touchpoint-card-content-icon-container--secondary-active'
                    : ''
                }`}
                aria-label={`secondary content preview for touchpoint ${stepIdx + 1}`}
                tabIndex={0}
                role="button"
                onClick={handleClickSecondaryContentPreview}
                onKeyDown={(e) => {
                  if (e.key === EVENT_KEYS.ENTER) {
                    handleClickSecondaryContentPreview();
                  }
                }}
              >
                +{contentsFromState.length - 1 + groupingFilteredContents.length}
              </div>
            </div>
          )}
        </div>
        <div
          className="touchpoint-card__body__actions-preview"
          aria-label={`smartphone preview for touchpoint ${stepIdx + 1}`}
        >
          {isStepEmpty ? (
            <SmartphonePreview />
          ) : (
            <>
              {activeAction === TOUCHPOINT_ACTIONS.PUSH && (
                <SmartphonePreview
                  push={{
                    title: push?.title?.[selectedLang] ? push.title[selectedLang] : '<Push Notification Title>',
                    body: push?.alert?.[selectedLang] ? push.alert[selectedLang] : '<Push Notification Body>',
                  }}
                />
              )}
              {activeAction === TOUCHPOINT_ACTIONS.PRIMARY_CONTENT && (
                <SmartphonePreview
                  contents={
                    contentsFromState[0]
                      ? [contentsFromState[0]]
                      : groupingFilteredContents[0]
                      ? [groupingFilteredContents[0]]
                      : []
                  }
                />
              )}
              {activeAction === TOUCHPOINT_ACTIONS.SECONDARY_CONTENT && (
                <SmartphonePreview contents={[...contentsFromState, ...groupingFilteredContents]} />
              )}
            </>
          )}
        </div>
      </div>
      {showFooter && (
        <div
          className={`touchpoint-card__footer touchpoint-card__footer--${errorStatus.toLowerCase()}`}
          aria-label={`show touchpoint ${stepIdx + 1} errors`}
          tabIndex={0}
          role="button"
          onClick={handleClickTouchpointFooter}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === EVENT_KEYS.ENTER) {
              handleClickTouchpointFooter();
            }
          }}
        >
          <div className="touchpoint-card__footer__status-title">Status:</div>
          <div className="touchpoint-card__footer__status-container">
            <div className="touchpoint-card__footer__status-container__icon-container">
              <StatusIcon fill={statusIconFillColors[errorStatus]} />
            </div>
            <div
              className="touchpoint-card__footer__status-container__text"
              aria-label={`touchpoint ${stepIdx + 1} status is ${errorStatus}`}
            >
              {errorStatus}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TouchpointCard;
