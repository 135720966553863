import './FileUpload.scss';

import React, { useMemo, useRef, useState } from 'react';

import { CtxDataFile } from 'interface/file/file.interface';
import FileAPI from 'services/api/file.api';
import { FlightCheckbox } from '@flybits/design-system';
import { ReactComponent as Image } from './images/file-upload.svg';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import { useAppSelector } from 'hooks/reduxHooks';
import { useDispatch } from 'react-redux';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useQueryClient } from '@tanstack/react-query';

type TFileUploadProps = {
  allowedTypes?: string[];
  showDownloadSampleFile?: boolean;
};

const CSV_UPLOAD_FILE_LIMIT = 200099999;
const MAIN_CLASS = 'file-upload';
const CLASSES = {
  FILE_WRAPPER: `${MAIN_CLASS}__file-wrapper`,
  FILE_LABEL: `${MAIN_CLASS}__file-label`,
  IMAGE: `${MAIN_CLASS}__image`,
  TEXT: `${MAIN_CLASS}__text`,
  TEXT_HIGHLIGHT: `${MAIN_CLASS}__text-highlight`,
  TEXT_SMALL: `${MAIN_CLASS}__text-small`,
  SPINNER_WRAPPER: `${MAIN_CLASS}__spinner-wrapper`,
  IS_DRAGGING: `${MAIN_CLASS}__file-label--is-dragging`,
  FOOTER: `${MAIN_CLASS}__footer`,
  SAMPLE_FILE: `${MAIN_CLASS}__sample-file`,
};

const FileUpload: React.FC<TFileUploadProps> = ({ allowedTypes, showDownloadSampleFile = false }) => {
  const { flags } = useFeatureFlag();
  const fileApi = useMemo(() => new FileAPI(), []);

  const [isBusy, setIsBusy] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [fileUploadedPercentage, setFileUploadedPercentage] = useState(0);
  const [isProcessImmediatelyChecked, setIsProcessImmediatelyChecked] = useState(false);
  const labelRef = useRef<HTMLLabelElement>(null);
  const filesUUID = useAppSelector((state) => state.te.journey.metadata?.filesUUID || '');
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const uploadFiles = async (files: FileList) => {
    setFilesUploaded(1);
    setTotalFiles(files.length);
    setIsBusy(true);

    if (flags && flags['tx_upload_ctxfile_s3']) {
      const filePromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > CSV_UPLOAD_FILE_LIMIT) {
          dispatch(
            showSnackbar({
              content: `The file "${files[i].name}" is too large. The maximum file size is 200MB.`,
              type: 'error',
            }),
          );
        } else if (file.type !== 'text/csv') {
          dispatch(
            showSnackbar({
              content: `The file "${files[i].name}" is not a valid CSV file.`,
              type: 'error',
            }),
          );
        } else {
          const promise = fileApi.uploadViaPreSignedUrl(files[i], setFileUploadedPercentage, filesUUID);

          promise.then(() => {
            if (filesUploaded < totalFiles) {
              setFilesUploaded((oldState) => oldState + 1);
            }
          });

          filePromises.push(promise);
        }
      }

      if (filePromises.length) {
        const rejected = (await Promise.allSettled(filePromises)).filter((r) => r.status === 'rejected');

        if (rejected.length) {
          dispatch(
            showSnackbar({
              content: `${
                totalFiles > 1 ? 'Some of your files did not upload successfully.' : 'The file failed to upload.'
              } ${rejected[0].reason.message}`,
              type: 'error',
            }),
          );
        } else {
          const message = `${
            totalFiles > 1 ? 'All files have been successfully uploaded.' : 'The file has been uploaded successfully.'
          }`;

          dispatch(
            showSnackbar({
              content: message,
              type: 'success',
            }),
          );
        }
      }
    } else {
      const uploadedFiles: Promise<CtxDataFile[]>[] = [];

      for (let i = 0; i < files.length; i++) {
        const uploadedFilePromise = fileApi.uploadCtxFile(files[i], filesUUID);
        uploadedFilePromise.then(() => setFilesUploaded((oldState) => oldState + 1));
        uploadedFiles.push(uploadedFilePromise);
      }

      const responses = await Promise.allSettled(uploadedFiles);
      if (responses.map((r) => r.status).includes('rejected')) {
        dispatch(
          showSnackbar({
            content: `${totalFiles > 1 ? 'Some of your files' : 'Your file'} failed to upload`,
            type: 'error',
          }),
        );
      } else {
        let message = `Your ${totalFiles > 1 ? 'files' : 'file'} have been uploaded`;
        if (isProcessImmediatelyChecked) {
          const res = responses[0] as PromiseFulfilledResult<CtxDataFile[]>;
          await fileApi.processFileById(res.value[0].id);
          message = `Your ${
            totalFiles > 1 ? 'files have been uploaded and the first one' : 'file has been uploaded and'
          } will be processed shortly`;
        }

        dispatch(
          showSnackbar({
            content: message,
            type: 'success',
          }),
        );
      }
    }
    await queryClient.invalidateQueries(['ctx-data-file']);
    setIsBusy(false);
  };

  const resetLabelClasses = () => {
    labelRef.current?.classList.remove(CLASSES.IS_DRAGGING);
  };

  const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;
    if (!files?.length) return;

    uploadFiles(files);
    evt.target.value = '';
  };

  const handleDrop = (evt: React.DragEvent<HTMLLabelElement>) => {
    evt.preventDefault();
    resetLabelClasses();
    const { files } = evt.dataTransfer;
    if (!files.length) return;

    uploadFiles(files);
  };

  const handleDragLeave = (evt: React.DragEvent<HTMLLabelElement>) => {
    evt.preventDefault();
    resetLabelClasses();
  };

  const handleDragOver = (evt: React.DragEvent<HTMLLabelElement>) => {
    evt.preventDefault();
    labelRef.current?.classList.add(CLASSES.IS_DRAGGING);
  };

  const handleCheckboxChange = () => {
    setIsProcessImmediatelyChecked((oldState) => !oldState);
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.FILE_WRAPPER}>
        <label
          className={CLASSES.FILE_LABEL}
          aria-label="select a .csv file to upload"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnd={handleDragLeave}
          onDrop={handleDrop}
          ref={labelRef}
        >
          <input type="file" accept={allowedTypes?.join(',')} disabled={isBusy} onChange={handleFileChange} multiple />
          <div className={`${MAIN_CLASS}__image`}>
            <Image />
          </div>
          <div className={CLASSES.TEXT}>
            <p>
              Drag and drop your files here or <span className={CLASSES.TEXT_HIGHLIGHT}>Browse</span>
            </p>
            <p className={CLASSES.TEXT_SMALL}>Importing requires .csv format only</p>
          </div>
        </label>
        {isBusy && (
          <div className={CLASSES.SPINNER_WRAPPER}>
            <LoadingIcon />
            Your {totalFiles > 1 ? 'files are' : 'file is'} being uploaded ({fileUploadedPercentage}%)... (
            {filesUploaded}/{totalFiles})
          </div>
        )}
      </div>
      <div className={CLASSES.FOOTER}>
        <FlightCheckbox
          label="By checking this box, the first context file will begin processing immediately."
          checkState={isProcessImmediatelyChecked ? 'SELECTED' : 'UNSELECTED'}
          disabled={isBusy}
          onSelect={handleCheckboxChange}
        />
        {showDownloadSampleFile && (
          <div className={CLASSES.SAMPLE_FILE}>
            <a href="/assets/Context_Data_Sample_Files.zip" download>
              Download Sample File
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
