import React from 'react';
import { FlightButton, FlightCheckbox, FlightModal } from '@flybits/design-system';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import TargetAudienceIcon from 'pages/ExperienceCanvas/assets/icons/TargetAudienceIcon';
import { getImageUrl } from 'helpers/common.helper';

import './ConfirmModal.scss';

export type TConfirmModalProps = {
  title: string;
  isVisible: boolean;
  content: string | JSX.Element;
  confirmEmptyAudience?: string;
  errorMessage?: string;
  icon?: string;
  isAudienceEmpty?: boolean;
  isLaunching?: boolean;
  isLoading?: boolean;
  contentIcon?: JSX.Element;
  primaryButton?: {
    onClick: () => void;
    name: string;
    disabled?: boolean;
  };
  secondaryButton?: {
    onClick: () => void;
    name: string;
  };
  cancelButton?: {
    onClick: () => void;
    name: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setModalProps?: (args: any) => void;
};

export default function ConfirmModal(props: TConfirmModalProps) {
  const {
    title,
    isVisible,
    content,
    confirmEmptyAudience = 'UNSELECTED',
    errorMessage = null,
    icon = null,
    isAudienceEmpty = false,
    isLaunching = false,
    isLoading = false,
    contentIcon: ContentIcon = null,
    cancelButton = null,
    primaryButton = null,
    secondaryButton = null,
    setModalProps = null,
  } = props;
  return (
    <FlightModal
      className="confirm-modal"
      size="medium"
      isVisible={isVisible}
      scrollable={true}
      toggleModalShown={cancelButton?.onClick || primaryButton?.onClick}
      header={
        <>
          {icon && <img src={getImageUrl(icon)} className="confirm-modal__header-icon" alt="header icon" />}
          <h3>{title}</h3>
        </>
      }
      content={
        <>
          <div className="confirm-modal__content-wrapper">
            {ContentIcon && <div className="confirm-modal__content-icon">{ContentIcon}</div>}
            <div>{content}</div>
          </div>
          {isAudienceEmpty && isLaunching && (
            <div className="confirm-modal__warn-section">
              <div className="confirm-modal__warn-section__icon-wrapper">
                <TargetAudienceIcon />
              </div>
              <div>
                <p>
                  Without any audience constraints, this experience will reach all audiences. Would you like to proceed?
                </p>
              </div>
            </div>
          )}
          {isAudienceEmpty && confirmEmptyAudience && isLaunching && (
            <div className="confirm-modal__warn-section__checkbox-wrapper">
              <FlightCheckbox
                label="Yes, I am sure."
                checkState={confirmEmptyAudience}
                onSelect={() =>
                  setModalProps
                    ? setModalProps({
                        ...props,
                        confirmEmptyAudience: confirmEmptyAudience === 'SELECTED' ? 'UNSELECTED' : 'SELECTED',
                      })
                    : undefined
                }
              />
            </div>
          )}
          {errorMessage && <div className="confirm-modal__error">Error: {errorMessage}</div>}
          {isLoading && (
            <div className="confirm-modal__spinner-overlay">
              <div className="confirm-modal__spinner">
                <LoadingIcon />
              </div>
            </div>
          )}
        </>
      }
      footer={
        <div className="confirm-modal__footer">
          {cancelButton && <FlightButton theme="secondary" onClick={cancelButton.onClick} label={cancelButton.name} />}
          {secondaryButton?.name && (
            <FlightButton type="secondary" onClick={secondaryButton.onClick} label={secondaryButton.name} />
          )}
          {primaryButton && (
            <FlightButton
              onClick={primaryButton.onClick}
              disabled={
                primaryButton.disabled ||
                isLoading ||
                (isAudienceEmpty && confirmEmptyAudience === 'UNSELECTED' && isLaunching)
              }
              loading={isLoading}
              label={primaryButton.name}
            />
          )}
        </div>
      }
    />
  );
}
