import {
  Action,
  ContentAction,
  PushAction,
  Step,
  WebhookAction,
  isContentAction,
} from 'interface/experience/experience.interface';
import { AnyAction, Reducer } from 'redux';

import { PUSH_ACTION_TYPES } from './push/push.type';
import { Scheduler } from 'interface/rule/rule.interface';
import { WEBHOOK_ACTION_TYPES } from './webhook/webhook.type';

export interface Handler<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [actionName: string]: (state: T, action: any) => T;
}

// create new object with updated fields
export function updateObject<T>(oldObject: T, newValues: Partial<T>): T {
  return {
    ...oldObject,
    ...newValues,
  };
}

// create new array with updated fields
export function updateItemInArray<T>(arr: T[], idFn: (item: T) => boolean, updateFn: (item: T) => T): T[] {
  const updatedItems = arr.map((item) => {
    if (!idFn(item)) {
      return item;
    }

    // Use the provided callback to create an updated item
    const updatedItem = updateFn(item);
    return updatedItem;
  });

  return updatedItems;
}

// generate a reducer
export function createReducer<T>(initialState: T, handlers: Handler<T>): Reducer<T, AnyAction> {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type as string](state, action);
    } else {
      return state;
    }
  };
}

// pull all push actions from a step into an array
export function getPushActionFromStep(step: Step): PushAction | undefined {
  return step.actions?.find((action: Action) => {
    return (
      action.actionType === PUSH_ACTION_TYPES.PUSH_NOTIFICATION ||
      action.actionType === PUSH_ACTION_TYPES.TRIGERRED_PUSH ||
      action.actionType === PUSH_ACTION_TYPES.BROADCAST_PUSH
    );
  }) as PushAction;
}

// pull all content actions from a step into an array
export function getContentActionsFromStep(step: Step): ContentAction[] {
  const retVal: ContentAction[] = [];

  step.actions?.forEach((action: Action) => {
    if (isContentAction(action)) {
      retVal.push(action);
    }
  });

  return retVal;
}

// get initial values of all relevant schedule toggles
export function getScheduleOmissionsFromStep(step: Step) {
  const restricted = step.audience.restricted;
  const preferred = step.audience.preferred;
  let omitSched = true;
  let omitStart = true;
  let omitEnd = true;
  let startScheduler: Scheduler | undefined;
  let endScheduler: Scheduler | undefined;

  if (restricted?.refId) {
    startScheduler = restricted.payload?.schedule?.startScheduler;
    endScheduler = restricted.payload?.schedule?.endScheduler;
  } else if (preferred?.refId) {
    startScheduler = preferred.payload?.schedule?.startScheduler;
    endScheduler = preferred.payload?.schedule?.endScheduler;
  }
  if (startScheduler?.start) omitStart = false;
  if (endScheduler?.start) omitEnd = false;
  if (!omitStart || !omitEnd) omitSched = false;

  return {
    omitSched,
    omitStart,
    omitEnd,
  };
}

// pull all webhook actions from a step into an array
export function getWebhookActionFromStep(step: Step): WebhookAction | undefined {
  return step.actions?.find((action: Action) => {
    return action.actionType === WEBHOOK_ACTION_TYPES.WEBHOOK;
  }) as WebhookAction;
}

// used to simplify rule operations
export function getStepRuleSyntheticId(stepIdx = 0) {
  return `SYNTH_STEP_RULE_ID_${stepIdx}`;
}

export function getStepOfRuleSyntheticId(synthId: string) {
  let retVal: number | undefined;
  if (!synthId.includes('SYNTH_STEP_RULE_ID_')) return retVal;

  try {
    retVal = parseInt(synthId.replace('SYNTH_STEP_RULE_ID_', ''));
  } catch {}

  return retVal;
}

export function mergeUserFilters(searchIds: string[], roleIds: string[]): string[] {
  // see src/test/redux/settings/audit/audit.action.test.ts for expected behaviour
  if (!searchIds.length || !roleIds.length) {
    return searchIds.length ? searchIds : roleIds;
  }
  const userIds: string[] = searchIds.filter((userId) => roleIds.includes(userId));
  return userIds.length ? userIds : ['']; // to display no results found
}
