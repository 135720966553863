import React, { useEffect, useState, useRef } from 'react';
import { FlightButton } from '@flybits/design-system';
import { EVENT_KEYS } from 'types/events';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import ChevronLeftIcon from 'pages/ExperienceCanvas/assets/icons/ChevronLeftIcon';
import './SlidingSidePanel.scss';

const chevronLeftIconFillColor = '#2371f1';

const SlidingSidePanel: React.FC<TSlidingSidePanelProps> = ({
  show,
  headerInfo,
  footerInfo,
  children,
  onSlideOutComplete,
  showFooter = true,
  size = 'large',
  className,
}) => {
  const [shouldRender, setShouldRender] = useState(show);

  const sidePanelRef = useRef<HTMLDivElement>(null);
  const closeBtnRef = useRef<HTMLDivElement>(null);
  const backBtnRef = useRef<HTMLButtonElement>(null);

  // TODO: we should really reconsider how we're implementing this focus trap
  const sidePanelFocusOutEventListener = (e: FocusEvent) => {
    const relatedTargetElement = e.relatedTarget as HTMLElement | null;
    const sidePanel = sidePanelRef.current;

    if (sidePanel && relatedTargetElement) {
      if (!sidePanel.contains(relatedTargetElement) && !relatedTargetElement?.closest('[tabIndex="-1"]')) {
        // Not so robust because it won't work for any sliding side panel
        // that doesn't have a close button or a back button
        const firstFocusableElement = closeBtnRef.current || backBtnRef.current;
        firstFocusableElement?.focus();
      }
    }
  };

  useEffect(() => {
    const sidePanel = sidePanelRef.current;

    if (show) {
      setShouldRender(true);
    }

    return () => {
      if (sidePanel) {
        sidePanel.removeEventListener('focusout', sidePanelFocusOutEventListener);
      }
    };
  }, [show]);

  const onAnimationEnd = (e: React.AnimationEvent<HTMLDivElement>) => {
    if (!show) {
      setShouldRender(false);
      if (onSlideOutComplete) onSlideOutComplete();
    } else {
      const sidePanel = sidePanelRef.current;
      if (!sidePanel || sidePanel !== e.target) return;

      const firstFocusableElement = closeBtnRef.current || backBtnRef.current;

      firstFocusableElement?.focus();

      // Move focus back to sliding side panel when it goes out
      sidePanel.addEventListener('focusout', sidePanelFocusOutEventListener);
    }
  };

  const {
    mainTitle,
    showCloseButton = false,
    goBackTitle = '',
    goBackSubTitle = '',
    goBackIcon = null,
    goBackActionHandler = () => null,
    statusComponent = null,
    headerAction = null,
  } = headerInfo;
  const {
    primaryActionText = '',
    secondaryActionText = '',
    primaryActionHandler,
    primaryActionLoading = false,
    primaryActionDisabled = false,
    secondaryActionHandler = () => null,
    secondaryActionDisabled = false,
    footerText = '',
    showProvideFeedbackButton = false,
  } = footerInfo || {};

  return shouldRender ? (
    <div
      className={`sliding-side-panel-container ${
        show ? 'sliding-side-panel-container--fade-in' : 'sliding-side-panel-container--fade-out'
      } ${className ? className : ''}`}
    >
      <div
        className={`sliding-side-panel sliding-side-panel--is-${size} ${
          show ? 'sliding-side-panel--slide-in' : 'sliding-side-panel--slide-out'
        }`}
        aria-labelledby={mainTitle}
        aria-modal={true}
        role="dialog"
        ref={sidePanelRef}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="sliding-side-panel__header">
          {goBackTitle && (
            <div className="sliding-side-panel__header__go-back">
              <button
                className="sliding-side-panel__header__go-back__btn"
                aria-label="go back"
                tabIndex={0}
                onClick={goBackActionHandler}
                onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                  if (e.key === EVENT_KEYS.ENTER) {
                    goBackActionHandler();
                  }
                }}
                ref={backBtnRef}
              >
                <ChevronLeftIcon fill={chevronLeftIconFillColor} />
              </button>
              <div className="sliding-side-panel__header__go-back__info-label">
                {goBackIcon && (
                  <div
                    className="sliding-side-panel__header__go-back__info-label__icon-container"
                    aria-label={`${goBackTitle} icon`}
                  >
                    {goBackIcon}
                  </div>
                )}
                <div className="sliding-side-panel__header__go-back__info-label__text-container">
                  <div
                    className="sliding-side-panel__header__go-back__info-label__text-container__title"
                    aria-label={goBackTitle}
                  >
                    {goBackTitle}
                  </div>
                  <div
                    className="sliding-side-panel__header__go-back__info-label__text-container__sub-title"
                    aria-label={goBackTitle}
                  >
                    {goBackSubTitle}
                  </div>
                </div>
              </div>
            </div>
          )}
          {showCloseButton && (
            <div className="sliding-side-panel__header__close-button" ref={closeBtnRef}>
              <FlightButton
                theme="minor"
                onClick={secondaryActionHandler}
                iconLeft="clear"
                label=""
                ariaLabel="close side panel"
              />
            </div>
          )}
          <div className="sliding-side-panel__header__title" aria-label={mainTitle}>
            {mainTitle}
          </div>
          {statusComponent ? (
            <div className="sliding-side-panel__header__status-container">{statusComponent}</div>
          ) : headerAction ? (
            <div className="sliding-side-panel__header__action">
              <FlightButton
                label={headerAction.label}
                ariaLabel={headerAction.label}
                theme="primary"
                onClick={headerAction.handler}
              />
            </div>
          ) : (
            <div className="sliding-side-panel__header__gutter"></div>
          )}
        </div>
        <div className="sliding-side-panel__body" aria-label={`${mainTitle} content`}>
          {children}
        </div>
        {showFooter && footerInfo && (
          <div className={`sliding-side-panel__footer ${!!footerText ? 'sliding-side-panel__footer--with-text' : ''}`}>
            {!!footerText && (
              <div className="sliding-side-panel__footer__text" aria-label={footerText}>
                {footerText}
              </div>
            )}
            <div className="sliding-side-panel__footer__actions">
              {!showProvideFeedbackButton ? (
                <div
                  aria-label={secondaryActionText}
                  tabIndex={-1}
                  role="button"
                  onClick={secondaryActionHandler}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === EVENT_KEYS.ENTER) {
                      secondaryActionHandler();
                    }
                  }}
                >
                  <FlightButton
                    label={secondaryActionText}
                    ariaLabel={secondaryActionText}
                    theme="secondary"
                    onClick={secondaryActionHandler}
                    disabled={secondaryActionDisabled}
                  />
                </div>
              ) : (
                <div
                  aria-label="provide feedback - help us improve"
                  className="sliding-side-panel__footer__actions__provide-feedback"
                >
                  <FlightButton
                    theme="secondary"
                    size="small"
                    label="Provide Feedback - Help us improve"
                    ariaLabel="Provide Feedback - Help us improve"
                    onClick={() => true}
                  />
                </div>
              )}
              {primaryActionHandler && !showProvideFeedbackButton && (
                <div
                  aria-label={primaryActionText}
                  tabIndex={-1}
                  role="button"
                  onClick={primaryActionHandler}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === EVENT_KEYS.ENTER) {
                      primaryActionHandler();
                    }
                  }}
                >
                  <FlightButton
                    label={primaryActionText}
                    ariaLabel={primaryActionText}
                    theme="primary"
                    onClick={primaryActionHandler}
                    loading={primaryActionLoading}
                    disabled={primaryActionDisabled}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
};

const MemoizedSlidingSidePanel = React.memo(SlidingSidePanel);

export default MemoizedSlidingSidePanel;
