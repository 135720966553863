import './TargetAudienceSidePanel.scss';

import { RuleBuilderAudience } from 'interface/rule/rule.interface';
import { RuleContainer, RulePreview } from '@flybits/webapp-react-rule-builder';
import { SaveRuleAction } from 'store/actionTypes';
import { saveRulesThunk } from 'store/rule/rule.thunk';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import { useRuleBuilderProps } from 'hooks/useRuleBuilderProps';
import React, { useCallback, useMemo } from 'react';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import IconTargetAudience from 'pages/FlowBuilder/assets/icons/IconTargetAudience';

const MAIN_CLASS = 'target-audience-side-panel';

function TargetAudienceSidePanel() {
  const flowBuilderContext = useFlowBuilderContext();
  const ruleBuilderProps = useRuleBuilderProps();
  const shouldSyncStartScheduleWithRuleBody = useSelector((state) => {
    const stepIdx = flowBuilderContext.sidePanels.targetAudience.stepIndex;
    const step = state.te.journey.steps[stepIdx];

    return step?.constraints?.audienceConstraints?.syncStartScheduleWithRuleBody ?? false;
  });
  const dispatch = useDispatch();

  const onCancel = useCallback(() => {
    ruleBuilderProps.setIsSerializeUiModel(false);
    flowBuilderContext.sidePanels.targetAudience.setAudienceContext((prev) => ({ ...prev, isOpen: false }));
  }, [flowBuilderContext.sidePanels.targetAudience, ruleBuilderProps]);

  const onSave = useCallback(() => {
    ruleBuilderProps.setIsSerializeUiModel(true);
    flowBuilderContext.sidePanels.targetAudience.setAudienceContext((prev) => ({ ...prev, isOpen: false }));
  }, [flowBuilderContext.sidePanels.targetAudience, ruleBuilderProps]);

  const saveRuleBuilderPredicates = useCallback(
    (currentRule: RuleBuilderAudience) => {
      if (!currentRule) {
        return;
      }

      const saveRulePayload: SaveRuleAction['payload'] = {
        id: currentRule.id,
        refId: currentRule.refId,
        rulePayload: currentRule.payload,
        stepIdx: flowBuilderContext.sidePanels.targetAudience.stepIndex,
        templateId: currentRule.templateId,
        templateType: '',
        type: 'restricted',
        shouldSyncStartScheduleWithRuleBody,
      };

      dispatch(saveRulesThunk(saveRulePayload));

      flowBuilderContext.sidePanels.targetAudience.setAudienceContext((prev) => ({ ...prev, isOpen: false }));
      ruleBuilderProps.setIsSerializeUiModel(false);
    },
    [dispatch, flowBuilderContext.sidePanels.targetAudience, ruleBuilderProps, shouldSyncStartScheduleWithRuleBody],
  );

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: flowBuilderContext.sidePanels.targetAudience.isOpen,
      headerInfo: {
        mainTitle: 'Rule Builder',
        goBackIcon: <IconTargetAudience />,
        goBackTitle: 'Target Audience',
        goBackSubTitle: `Currently ${
          flowBuilderContext.sidePanels.targetAudience.isViewOnlyMode ? 'viewing' : 'editing'
        } from Flow Builder`,
        goBackActionHandler: onCancel,
      },
      footerInfo: {
        ...(flowBuilderContext.sidePanels.targetAudience.isViewOnlyMode
          ? {
              primaryActionText: 'Close',
              primaryActionHandler: onCancel,
            }
          : {
              primaryActionText: 'Save',
              primaryActionHandler: onSave,
            }),
        secondaryActionText: 'Cancel',
        secondaryActionHandler: onCancel,
      },
    }),
    [flowBuilderContext.sidePanels.targetAudience, onSave, onCancel],
  );

  return (
    <div className={MAIN_CLASS}>
      <SlidingSidePanel {...slidingSidePanelProps}>
        {flowBuilderContext.sidePanels.targetAudience.isViewOnlyMode ? (
          <RulePreview {...ruleBuilderProps} locations={ruleBuilderProps.locationProps.locations} />
        ) : (
          <RuleContainer {...ruleBuilderProps} serializeUiModel={saveRuleBuilderPredicates} />
        )}
      </SlidingSidePanel>
    </div>
  );
}

export default TargetAudienceSidePanel;
