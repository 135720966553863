import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FlightTextInput } from '@flybits/design-system';
import InfiniteScroll from 'react-infinite-scroller';
import { useAppSelector } from 'hooks/reduxHooks';
import useDebounce from 'hooks/useDebounce';
import { Content } from 'interface/content/content.interface';
import Skeleton from 'react-loading-skeleton';
import ContentAPI from 'services/api/content.api';
import './ContentTemplateSelector.scss';

type TContentTemplateSelectorProps = {
  setSelectedTemplate: React.Dispatch<React.SetStateAction<Content | undefined>>;
  selectedTemplate?: Content;
  isSingleSelectMode?: boolean;
  singleSelectTemplateIdConstraint?: string;
};

const MAIN_CLASS = 'content-template-selector';
const CLASSES = {
  SEARCH_WRAPPER: `${MAIN_CLASS}__search-wrapper`,
  TEMPLATE_LIST: `${MAIN_CLASS}__template-list`,
  TEMPLATE_ITEM: `${MAIN_CLASS}__template-item`,
  TEMPLATE_ICON: `${MAIN_CLASS}__template-icon`,
  TEMPLATE_NAME: `${MAIN_CLASS}__template-name`,
  TEMPLATE_DESCRIPTION: `${MAIN_CLASS}__template-description`,
  LOADER_WRAPPER: `${MAIN_CLASS}__loader-wrapper`,
  SKELETON_WRAPPER: `${MAIN_CLASS}__skeleton-wrapper`,
  NO_TEMPLATES: `${MAIN_CLASS}__no-templates`,
};

const ContentTemplateSelector: React.FC<TContentTemplateSelectorProps> = ({
  setSelectedTemplate,
  selectedTemplate,
  isSingleSelectMode = false,
  singleSelectTemplateIdConstraint = '',
}) => {
  const contentAPI = useMemo(() => new ContentAPI(), []);

  const template = useAppSelector((state) => state.templatedExperience.template);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [templates, setTemplates] = useState<Content[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const isNearByTemplate = template?.steps?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (step: any) => step.type === 'audience' && step?.data?.trigger?.type === 'nearby-location',
  );

  const handleTemplateChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const template = templates.find((t) => t.id === value);
    if (!!template) setSelectedTemplate(template);
  };

  const fetchTemplates = async (searchTerm: string, resetPageCount = false) => {
    if ((!hasMore || isLoading) && !resetPageCount) return;
    setIsLoading(true);

    // constrainted workflow
    if (isSingleSelectMode && singleSelectTemplateIdConstraint) {
      const template = await contentAPI.getTemplate(singleSelectTemplateIdConstraint);
      setIsLoading(false);
      setHasMore(false);
      setTemplates([template]);
      return;
    }
    // unconstrained workflow
    const currentPage = resetPageCount ? 0 : page;
    const location = !!isNearByTemplate;
    const params = { offset: currentPage, limit: 10, search: searchTerm, location };
    const { data, pagination } = await contentAPI.getContentTemplates(params);
    setIsLoading(false);
    const mergedTemplates = resetPageCount ? data : [...templates, ...data];
    setHasMore(mergedTemplates.length < pagination.totalRecords);
    setTemplates(mergedTemplates);
    setPage(currentPage + 1);
  };

  const loadMore = () => {
    fetchTemplates(debouncedSearchTerm);
  };

  useEffect(() => {
    fetchTemplates(searchTerm, true);
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const TemplateSkeleton = () => (
    <li className={CLASSES.TEMPLATE_ITEM}>
      <div className={CLASSES.LOADER_WRAPPER}>
        <div className={CLASSES.TEMPLATE_ICON}>
          <Skeleton width="24px" height="24px" circle />
        </div>
        <div className={CLASSES.TEMPLATE_NAME}>
          <Skeleton width="28%" />
        </div>
        <div className={CLASSES.TEMPLATE_DESCRIPTION}>
          <Skeleton width="100%" />
        </div>
      </div>
    </li>
  );

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.SEARCH_WRAPPER}>
        <FlightTextInput
          trailingIcon="search"
          width="100%"
          value={searchTerm}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(evt: any) => setSearchTerm(evt.target.value)}
          placeholderText="Search here for Templates"
        />
      </div>
      <ul className={CLASSES.TEMPLATE_LIST}>
        <InfiniteScroll initialLoad={false} loadMore={loadMore} hasMore={hasMore} useWindow={false} threshold={20}>
          {isLoading
            ? [...Array(3)].map((item, index) => <TemplateSkeleton key={index} />)
            : templates.map((template) => (
                <li className={CLASSES.TEMPLATE_ITEM} key={template.id}>
                  <input
                    type="radio"
                    value={template.id}
                    name="template"
                    id={`template-${template.id}`}
                    onChange={handleTemplateChange}
                    checked={selectedTemplate?.id === template.id}
                  />
                  <label htmlFor={`template-${template.id}`}>
                    <div className={CLASSES.TEMPLATE_ICON}>
                      {template.iconUrl && <img src={template.iconUrl} alt="template icon" />}
                    </div>
                    <div className={CLASSES.TEMPLATE_NAME}>{template.localizations?.en?.name}</div>
                    <div className={CLASSES.TEMPLATE_DESCRIPTION}>{template.localizations?.en?.description}</div>
                  </label>
                </li>
              ))}
        </InfiniteScroll>
        {!isLoading && templates.length < 1 && (
          <li className={CLASSES.NO_TEMPLATES}>
            <p>No templates to show</p>
          </li>
        )}
      </ul>
    </div>
  );
};

export default ContentTemplateSelector;
