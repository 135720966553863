import React, { useEffect, useMemo, useState } from 'react';
import { FlightCollapsible } from '@flybits/design-system';
import { useCustomModuleAnalyticsContext, deserializeGraphData } from '../context/CustomModuleAnalyticsContext';
import { CustomModuleVisualizer } from './CustomModuleVisualizer/CustomModuleVisualizer';
import { CustomModuleMeasureItems } from './CustomModuleMeasureItems/CustomModuleMeasureItems';
import { CustomModuleMetrics } from './CustomModuleMetrics/CustomModuleMetrics';
import { CustomModuleTimeFrame } from './CustomModuleTimeFrame/CustomModuleTimeFrame';
import { CustomModuleAnalyticsPreview } from './CustomModuleAnalyticsPreview/CustomModuleAnalyticsPreview';
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import { ReactComponent as ArrowTurnDownRight } from 'assets/icons/arrow-turn-down-right.svg';
import './CustomModuleAnalyticsContent.scss';

const DEFAULT_CLASS = 'custom-module-analytics__main-content';

export const CustomModuleAnalyticsContent = ({ moduleID }: { moduleID?: string }) => {
  const templatedExperienceAnalyticsApi = useMemo(() => new TemplatedExperienceAnalyticsAPI(), []);

  const { customModuleState, setCustomModuleState } = useCustomModuleAnalyticsContext();
  const {
    visualization: { selectedGraph },
  } = customModuleState;

  const [moduleName, setModuleName] = useState(
    `Untitled ${new Date().toLocaleDateString('pt-br').split('/').reverse().join('-')}`,
  );

  useEffect(() => {
    const fetchModuleConfigs = async (moduleId?: string) => {
      if (!moduleId) return;
      try {
        const { moduleConfig } = await templatedExperienceAnalyticsApi.getModuleConfigData(moduleId);
        const deserializedData = await deserializeGraphData(moduleConfig);
        setCustomModuleState((prev) => ({ ...prev, ...deserializedData }));
        setModuleName(moduleConfig.name);
      } catch (e) {
        // TODO: Handle properly
      }
    };
    fetchModuleConfigs(moduleID);
  }, [moduleID, setCustomModuleState, templatedExperienceAnalyticsApi]);

  const CustomHeader = ({
    stepCount,
    headerTitle,
    selectedItem,
  }: {
    stepCount: string;
    headerTitle: string;
    selectedItem?: string;
  }): React.ReactElement => {
    return (
      <div className="custom-module-analytics-header">
        <div>{stepCount}</div>
        <div>
          <div>{`| ${headerTitle}`}</div>
          {selectedItem && (
            <div className="custom-module-analytics-header__sub">
              <ArrowTurnDownRight />
              {selectedItem}
            </div>
          )}
        </div>
      </div>
    );
  };

  const collapsibleItems = [
    {
      name: <CustomHeader stepCount="1" headerTitle="Select visualization" selectedItem={selectedGraph} />,
      key: 0,
      disabled: false,
      content: <CustomModuleVisualizer />,
    },
    {
      name: <CustomHeader stepCount="2" headerTitle="Select items to measure" />,
      key: 1,
      disabled: false,
      content: <CustomModuleMeasureItems />,
    },
    {
      name: <CustomHeader stepCount="3" headerTitle="Select metrics to display" />,
      key: 2,
      disabled: false,
      content: <CustomModuleMetrics />,
    },
    {
      name: <CustomHeader stepCount="4" headerTitle="Select a timeframe" />,
      key: 3,
      disabled: false,
      content: <CustomModuleTimeFrame />,
    },
    // This is hiddem for now by request but will come back later
    // {
    //   name: <CustomHeader stepCount="5" headerTitle="Select calculation method" />,
    //   key: 4,
    //   disabled: false,
    //   content: <CustomModuleCalcMethod />,
    // },
  ];

  return (
    <div className={DEFAULT_CLASS}>
      <div className={`${DEFAULT_CLASS}__radio`}>
        <FlightCollapsible
          className={`${DEFAULT_CLASS}__radio__container`}
          maxHeight="unset"
          items={collapsibleItems}
        />
      </div>
      <CustomModuleAnalyticsPreview moduleName={moduleName} setModuleName={setModuleName} />
    </div>
  );
};
