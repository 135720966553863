import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FlightCheckbox, FlightTimeZone, FlightTooltip } from '@flybits/design-system';
import { convertEpochToTime, getImageUrl } from 'helpers/common.helper';
import {
  getTimezoneList,
  getTimeRangeInMinutes,
  // checkInTimeRange,
  guessUserTimezone,
} from 'helpers/templated-experience.helper';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { getStepRuleSyntheticId } from 'store/helper';
import { FullTime } from 'types/common';
import { ExperienceSchedule } from 'interface/experience/experience.interface';
import { JOURNEY_STATUSES, JOURNEY_TEMPLATE_TYPES, START_TIME } from 'pages/ExperienceCanvas/types';
import SlidingSidePanel from '../SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import './Schedule.scss';
import { getISODate } from 'helpers/common.helper';

type TScheduleProps = {
  isOpen: boolean;
  onClickClose: () => void;
};

type TimeOption = {
  key: string | number;
  name: string;
  value: number;
};

type DateTime = {
  start: FullTime;
  end: FullTime;
  timezone?: TimeOption;
};

const MAIN_CLASS = 'schedule-panel';
const CLASSES = {
  SECTION: `${MAIN_CLASS}__section`,
  SECTION_LEFT: `${MAIN_CLASS}__section-left`,
  SECTION_RIGHT: `${MAIN_CLASS}__section-right`,
  SECTION_WITH_BORDER: `${MAIN_CLASS}__section ${MAIN_CLASS}__section--has-border`,
  SECTION_TITLE: `${MAIN_CLASS}__section-title`,
  INSTRUCTIONS: `${MAIN_CLASS}__instructions`,
  IMAGE: `${MAIN_CLASS}__image`,
  IMAGE_TITLE: `${MAIN_CLASS}__image-title`,
  IMAGE_DESC: `${MAIN_CLASS}__image-desc`,
  DATETIME: `${MAIN_CLASS}__datetime`,
  TOGGLE_SWITCH_WRAPPER: `${MAIN_CLASS}__toggle-switch-wrapper`,
  EXPERIENCE_DURATION: `${MAIN_CLASS}__experience-duration`,
  VIEW_TIMELINE: `${MAIN_CLASS}__view-timeline`,
  ERROR_MESSAGE: `${MAIN_CLASS}__error-message`,
  HAS_ERROR: 'has-error',
};

function Schedule({ isOpen, onClickClose }: TScheduleProps) {
  const dispatch = useDispatch();
  const tzList = getTimezoneList().map((tz) => ({ key: tz.key, name: tz.name, value: tz.offset }));
  const firstStepstartScheduler = useSelector((state) => {
    const synthId = getStepRuleSyntheticId(0); // only step 0 can have "Now" as start schedule
    return state.te.rule.startSchedulerMap[synthId];
  });
  const { status, isBroadcast } = useSelector((state) => {
    return {
      status: state.te.journey.status,
      isBroadcast:
        state.te.journey.templateType === JOURNEY_TEMPLATE_TYPES.BROADCAST ||
        state.te.journey.templateType === JOURNEY_TEMPLATE_TYPES.LIST_BROADCAST,
    };
  });

  const { storeDateTime, storeSchedule } = useSelector(
    (
      state,
    ): {
      storeDateTime: DateTime;
      storeSchedule?: ExperienceSchedule;
    } => {
      const schedule = state.te.journey.schedule;
      const timezone = schedule?.timezone || guessUserTimezone();
      const stateStart = schedule?.start;
      const stateEnd = schedule?.end;

      const start = convertEpochToTime(stateStart, timezone, !stateStart);
      const end = convertEpochToTime(stateEnd, timezone, !stateEnd);

      return {
        storeDateTime: {
          start,
          end,
          timezone: tzList?.find((tz) => tz.name === timezone),
        },
        storeSchedule: schedule,
      };
    },
  );

  const [startTimeError, setStartTimeError] = useState<string>();
  const [endTimeError, setEndTimeError] = useState<string>();
  const [dateTime, setDateTime] = useState(storeDateTime);
  const minStart = moment
    .unix(Date.now() / 1000)
    .utc()
    .add(5, 'm');
  const [timeRange, setTimeRange] = useState({
    start: getTimeRangeInMinutes(storeDateTime.start.date, minStart.unix()),
    end: getTimeRangeInMinutes(storeDateTime.end.date, storeSchedule?.start),
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const experienceDuration = useMemo(() => {
    if (
      (!dateTime.start.isActive && !dateTime.end.isActive) ||
      (dateTime.start.isActive && !dateTime.end.isActive) ||
      (dateTime.start.isActive && !dateTime.start.date) ||
      (dateTime.start.isActive && !dateTime.start.time) ||
      !dateTime.end.date ||
      !dateTime.end.time ||
      !dateTime.timezone
    )
      return 'None';

    const startDate = !dateTime.start.isActive
      ? moment()
      : moment(dateTime.start.date).add(dateTime.start.time?.value, 'seconds');
    const endDate = moment(dateTime.end.date).add(dateTime.end.time?.value, 'seconds');
    const diffDuration = moment.duration(endDate.diff(startDate));
    const days = Math.floor(diffDuration.asDays());

    if (days < 0) return 'None';

    const formattedDays = `${days > 1 ? `${days} days` : `${days} day`}`;
    const hours = diffDuration.hours();
    const formattedHours = `${hours > 1 ? `${hours} hours` : `${hours} hour`}`;
    const minutes = diffDuration.minutes();
    const formattedMinutes = `${minutes > 1 ? `${minutes} minutes` : `${minutes} minute`}`;
    const finalDate: string[] = [];

    if (days) finalDate.push(formattedDays);
    if (hours) finalDate.push(formattedHours);
    if (!days && minutes) finalDate.push(formattedMinutes);

    return finalDate.length > 0 ? finalDate.join(' and ') : 'None';
  }, [dateTime]);

  const isPayloadValid = useCallback(() => {
    setStartTimeError(undefined);
    setEndTimeError(undefined);
    const startDate = moment(dateTime.start.date).add(dateTime.start.time?.value, 'seconds');
    const endDate = moment(dateTime.end.date).add(dateTime.end.time?.value, 'seconds');
    const now = moment();

    // All start fields are required if checkbox is checked and
    // minimum start time should be 5 minutes in the future
    if (dateTime.start.isActive) {
      if (!dateTime.start.date || !dateTime.start.time || !dateTime.timezone) {
        setStartTimeError('All fields are required.');
        return false;
      }

      const diffFromNow = startDate.diff(now, 'minutes');
      if (diffFromNow < 5) {
        setStartTimeError('Start time should be at least 5 minutes in the future.');
        return false;
      }
    }

    // All end fields are required if checkbox is checked
    if (dateTime.end.isActive && (!dateTime.end.date || !dateTime.end.time || !dateTime.end.time)) {
      setEndTimeError('All fields are required.');
      return false;
    }

    // Validate minimum difference between end and start time
    if (dateTime.start.isActive && dateTime.end.isActive) {
      const diffBetweenDates = endDate.diff(startDate, 'minutes');

      if (diffBetweenDates < 15) {
        setEndTimeError('Difference between end time and start time should be at least 15 minutes.');
        return false;
      }
    }

    return true;
  }, [dateTime]);

  const handleSave = useCallback(async () => {
    setIsSubmitted(true);

    const payload = {
      start: dateTime.start.isActive
        ? moment(dateTime.start.date).add(dateTime.start.time?.value, 'seconds').unix()
        : 0,
      end: dateTime.end.isActive ? moment(dateTime.end.date).add(dateTime.end.time?.value, 'seconds').unix() : 0,
      timezone: dateTime.end.isActive || dateTime.start.isActive ? dateTime?.timezone?.name : '',
    };

    if (isPayloadValid()) {
      dispatch({
        type: 'UPDATE_JOURNEY',
        payload: {
          schedule: payload,
        },
      });

      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          content: 'Successfully saved your scheduling preferences.',
          type: 'success',
        },
      });
      onClickClose();
    }
  }, [dateTime, dispatch, isPayloadValid, onClickClose]);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: 'Experience Schedule',
        showCloseButton: true,
      },
      footerInfo: {
        primaryActionText: 'Save',
        primaryActionHandler: handleSave,
        secondaryActionText: 'Close',
        secondaryActionHandler: onClickClose,
        showProvideFeedbackButton: false,
      },
      size: 'large',
      showFooter: true,
    }),
    [isOpen, onClickClose, handleSave],
  );

  const handleOnSelect = (type: 'start' | 'end') => {
    setDateTime((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        isActive: !prevState[type].isActive,
      },
    }));
  };

  // see comment in ScheduleEditorBox.tsx for context about time range calc
  const handleDropdownSelect = useCallback(
    (type: 'start' | 'end', dropType: 'date' | 'time' | 'tz', val: Date | TimeOption | string) => {
      const fullTime = type === 'start' ? dateTime.start : dateTime.end;
      const newValues = {
        date: dropType === 'date' ? (val as Date) : fullTime.date,
        time: dropType === 'time' ? (val as TimeOption) : fullTime.time,
        tz: dropType === 'tz' ? (val as TimeOption) : dateTime.timezone,
      };

      const newDateTime = {
        ...dateTime,
        [type]: {
          ...dateTime[type],
          date: newValues.date,
          time: newValues.time,
        },
        timezone: newValues.tz,
      };

      const newTimeRange = {
        start: getTimeRangeInMinutes(newDateTime.start.date, minStart.unix()),
        end: getTimeRangeInMinutes(newDateTime.end.date, storeSchedule?.start),
      };

      // // check if time is within new bounds
      // const newStartTime = newDateTime.start.time?.value;
      // const newEndTime = newDateTime.end.time?.value;

      // if (!checkInTimeRange(newTimeRange.start, newStartTime)) newDateTime.start.time = undefined;
      // if (!checkInTimeRange(newTimeRange.end, newEndTime)) newDateTime.end.time = undefined;

      setTimeRange(newTimeRange);
      setDateTime(newDateTime);
    },
    [dateTime, minStart, storeSchedule],
  );

  useEffect(() => {
    if (isSubmitted) isPayloadValid();
  }, [isSubmitted, isPayloadValid]);

  const getSelectTimeOption = (time: string) => {
    const [hour, minute] = time.split(':');
    const seconds = parseInt(hour) * 3600 + parseInt(minute) * 60;
    return {
      key: `${seconds}`,
      value: seconds,
      name: moment(time, 'HH:mm').format('hh:mm A'),
    };
  };

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={MAIN_CLASS} aria-label="experience schedule">
        <div className={CLASSES.SECTION_LEFT}>
          <div className={CLASSES.SECTION_TITLE} aria-label="experience schedule title">
            Set the duration of your experience
          </div>
          {status !== JOURNEY_STATUSES.ACTIVE && (
            <div className={CLASSES.SECTION} aria-label="start date">
              <h2 aria-label="start date and time">
                {isBroadcast ? 'Send at' : 'Start'}
                <FlightTooltip
                  description={
                    'You cannot set the start date unless you turn off the touchpoint schedule\'s "Now" option.'
                  }
                  direction="right"
                  isEnabled={firstStepstartScheduler?.start === START_TIME.NOW}
                >
                  <span>
                    <FlightCheckbox
                      checkState={dateTime.start.isActive ? 'SELECTED' : 'UNSELECTED'}
                      onSelect={() => handleOnSelect('start')}
                      disabled={firstStepstartScheduler?.start === START_TIME.NOW}
                    />
                  </span>
                </FlightTooltip>
              </h2>
              <p className={CLASSES.INSTRUCTIONS}>Start date must be at least 5 minutes after the experience launch.</p>
              <div className={CLASSES.DATETIME}>
                <input
                  type="date"
                  className={!!startTimeError ? CLASSES.HAS_ERROR : ''}
                  id="start-date"
                  name="start-date"
                  value={getISODate(dateTime.start.date)}
                  min={minStart.format('YYYY-MM-DD')}
                  onChange={(evt) => handleDropdownSelect('start', 'date', moment(evt.target.value).toDate())}
                  disabled={!dateTime.start.isActive}
                  aria-label="start date"
                />
                <input
                  type="time"
                  className={!!startTimeError ? CLASSES.HAS_ERROR : ''}
                  id="start-time"
                  name="start-time"
                  value={
                    dateTime?.start?.time?.value ? moment.unix(dateTime.start.time.value).utc().format('HH:mm') : ''
                  }
                  min={timeRange.start.min}
                  max={timeRange.start.max}
                  step="900"
                  onChange={(evt) => handleDropdownSelect('start', 'time', getSelectTimeOption(evt.target.value))}
                  disabled={!dateTime.start.isActive}
                  aria-label="start time"
                />
                <FlightTimeZone
                  handleTimeZoneChange={(arg) => handleDropdownSelect('start', 'tz', arg)}
                  selectedTimeZone={dateTime?.timezone}
                  disabled={!dateTime.start.isActive}
                  hasError={!!startTimeError}
                  timeZoneList={tzList}
                  timeZoneRegex=""
                />
              </div>
              {!!startTimeError && <p className={CLASSES.ERROR_MESSAGE}>{startTimeError}</p>}
            </div>
          )}
          {!isBroadcast && (
            <div className={CLASSES.SECTION} aria-label="end date">
              <h2 aria-label="end date and time">
                End
                <FlightCheckbox
                  checkState={dateTime.end.isActive ? 'SELECTED' : 'UNSELECTED'}
                  onSelect={() => handleOnSelect('end')}
                />
              </h2>
              <div className={CLASSES.DATETIME}>
                <input
                  type="date"
                  className={!!endTimeError ? CLASSES.HAS_ERROR : ''}
                  id="end-date"
                  name="end-date"
                  value={getISODate(dateTime.end.date)}
                  min={minStart.format('YYYY-MM-DD')}
                  onChange={(evt) => handleDropdownSelect('end', 'date', moment(evt.target.value).toDate())}
                  disabled={!dateTime.end.isActive}
                  aria-label="end date"
                />
                <input
                  type="time"
                  className={!!endTimeError ? CLASSES.HAS_ERROR : ''}
                  id="end-time"
                  name="end-time"
                  value={dateTime?.end?.time?.value ? moment.unix(dateTime.end.time.value).utc().format('HH:mm') : ''}
                  min={timeRange.end.min}
                  max={timeRange.end.max}
                  step="900"
                  onChange={(evt) => handleDropdownSelect('end', 'time', getSelectTimeOption(evt.target.value))}
                  disabled={!dateTime.end.isActive}
                  aria-label="end time"
                />
                <FlightTimeZone
                  handleTimeZoneChange={(arg) => handleDropdownSelect('end', 'tz', arg)}
                  selectedTimeZone={dateTime?.timezone}
                  disabled={!dateTime.end.isActive || dateTime.start.isActive}
                  hasError={!!endTimeError}
                  timeZoneList={tzList}
                />
              </div>
              {!!endTimeError && <p className={CLASSES.ERROR_MESSAGE}>{endTimeError}</p>}
            </div>
          )}
          {status !== JOURNEY_STATUSES.ACTIVE && !isBroadcast && (
            <div className={CLASSES.SECTION_WITH_BORDER} aria-label="experience duration">
              <p className={CLASSES.EXPERIENCE_DURATION}>
                Experience Duration: <span>{experienceDuration}</span>
              </p>
            </div>
          )}
          {/*<div className={CLASSES.SECTION}>
            <button
              className={CLASSES.VIEW_TIMELINE}
              onClick={() => console.log('hehe xd')}
            >
              View Experience Timeline
            </button>
          </div>*/}
        </div>
        <div className={CLASSES.SECTION_RIGHT}>
          <img
            className={CLASSES.IMAGE}
            src={getImageUrl('schedule.svg', 'assets/images')}
            alt="pick experience schedule"
          />
          <h3 className={CLASSES.IMAGE_TITLE}>Start scheduling your experience now!</h3>
          <p className={CLASSES.IMAGE_DESC}>
            Get started by choosing a start day for your experience delivery. You can set an end date to define the
            experience duration/cease the experience.
          </p>
        </div>
      </div>
    </SlidingSidePanel>
  );
}

export default Schedule;
