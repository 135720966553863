import './RedirectIndex.scss';
import DOMPurify from 'dompurify';
import * as AuthActions from 'store/auth/auth.action';

import React, { useEffect, useMemo, useState } from 'react';

import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import Storage from 'services/storage';
import useActions from 'hooks/useActions';
import { useHistory } from 'react-router-dom';

export default function RedirectIndex() {
  const authActions = useActions(AuthActions);
  const history = useHistory();
  const storage = useMemo(() => new Storage(), []);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const origin = params.get('origin');
    const originHost = origin?.split('/#')[0];
    const path = params.get('path') ?? '';
    const tenantID = path?.split('/')[1];
    const token = params.get('token');

    const missingParametersErrorMessage = 'The redirect URL is invalid due to missing/incorrect parameters.';

    // Note:
    // The value of "origin" varies based on which web app has redirected to
    // Experience Studio 2.x. The value may consist of only the domain or it
    // may also include the path. For instance, the value of "origin" could be:
    //   - https://development-me-qa.development.flybits.com
    //   - https://development-me-qa.development.flybits.com/#/experiences (when ES 1.x redirects to this route)

    // If there is no token, we must redirect back to the origin

    if (path && tenantID && token) {
      if (originHost) {
        storage.setItem(`${tenantID}+origin`, originHost);
      }

      authActions
        .authenticateUser(token, tenantID)
        .then(() => {
          history.replace(path);
        })
        .catch((response: { message: string }) => {
          setErrorMessage(response.message);
        });
    } else if (origin && window.location.host.includes('.flybits.com')) {
      setTimeout(() => {
        window.location.href = DOMPurify.sanitize(origin);
      }, 10000);

      setErrorMessage(`${missingParametersErrorMessage} You will be redirected to the ${origin} shortly.`);
    } else {
      setErrorMessage(missingParametersErrorMessage);
    }
  }, [authActions, history, storage]);

  return (
    <div className="redirect-container">
      <div className="content">
        {errorMessage ? (
          <div className="error-message">{errorMessage}</div>
        ) : (
          <>
            <LoadingIcon />
            <div>Please wait a few seconds while we authenticate you...</div>
          </>
        )}
      </div>
    </div>
  );
}
