import React from 'react';
import { JourneyException } from 'types/errors';
import './ErrorSplash.scss';

interface TErrorSplashProps {
  error: unknown;
}

export default function ErrorSplash({ error }: TErrorSplashProps) {
  const isJourneyError = error instanceof JourneyException;
  const { title, desc, image } = isJourneyError ? error : new JourneyException('an unknown error has occurred');

  return error ? (
    <div className="error-splash">
      <div className="error-splash-text">
        <h3 className="error-splash-text--name">{title}</h3>
        <div className="error-splash-text--desc">{desc}</div>
      </div>
      <img className="error-splash-img" src={image} alt="Error flavor" />
    </div>
  ) : null;
}
