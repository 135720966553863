import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PhonePreviewWallpaper from 'assets/images/PhonePreviewWallpaper.png';
import { ReactComponent as PhoneSignalIcon } from 'assets/icons/phone-signal.svg';
import { ReactComponent as PhoneWifiIcon } from 'assets/icons/phone-wifi.svg';
import { ReactComponent as PhoneBatteryIcon } from 'assets/icons/phone-battery.svg';
import { ReactComponent as PhoneLockIcon } from 'assets/icons/phone-lock.svg';
import { ReactComponent as PhoneFlashlightIcon } from 'assets/icons/phone-flashlight.svg';
import { ReactComponent as PhoneCameraIcon } from 'assets/icons/phone-camera.svg';
import { ContentListItem } from '../types';
import { Language } from 'interface/settings/settings.interface';
import PushPreviewCard from '../PushPreviewCard/PushPreviewCard';
import ContentPreviewCard from '../ContentPreviewCard/ContentPreviewCard';
import ContentDetailPreviewCard from '../ContentDetailPreviewCard/ContentDetailPreviewCard';
import DynamicContentPreview from 'components/ExperienceCanvas/DynamicContentIframe/DynamicContentPreviewIframe';
import { FlightButton } from '@flybits/design-system';
import './MultiActionPreview.scss';

// steps can be: 'push', 'card' and 'detail'
type TMultiPreviewProps = {
  className: string;
  isFullPreview: boolean;
  steps?: string[];
  push?: {
    title: string;
    body: string;
  };
  contents?: ContentListItem[];
  showPreview?: 'push' | 'content';
  language?: Language;
};

enum AnimationSteps {
  'EMPTY',
  'PUSH',
  'CONTENT',
  'DETAILS',
}

// Using while we dont have the redux schema yet
export default function MultiActionPreview({
  className,
  isFullPreview,
  push,
  contents = [],
  showPreview,
  language,
}: TMultiPreviewProps) {
  const isDynamicContentDemo = contents.find((content) => content.renderType);
  const [dynamicContentDemoIds, setDemoIds] = useState(contents.map((c) => c.id).filter((id) => !!id));
  const [currentTime, setCurrentTime] = useState<{ time: string; date: string }>({ time: '', date: '' });
  const [currentStep, setCurrentStep] = useState(AnimationSteps.EMPTY);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [showMousePointer, setShowMousePointer] = useState(true);
  const [isDemoIframeDetailView, setIsDemoIframeDetailView] = useState(false);
  const resetSteps = () => {
    setIsDemoIframeDetailView(false);
    setCurrentStep(AnimationSteps.EMPTY);
    setShowReplayButton(false);
  };

  const finishAnimation = () => {
    setShowMousePointer(false);
    setShowReplayButton(true);
  };

  // update date and time every second
  useEffect(() => {
    const timeInterval = setInterval(() => {
      const currentDate = moment();
      setCurrentTime({
        time: currentDate.format('hh:mm'),
        date: currentDate.format('dddd, MMMM D'),
      });
    }, 1000);

    return () => clearInterval(timeInterval);
  }, []);

  // had to pull the filter fn out of jsx to prevent it from sending a fresh array every render.
  useEffect(() => {
    setDemoIds(contents.map((c) => c.id).filter((id) => !!id));
  }, [contents]);

  const moveMousePointerToElement = (element: Element) => {
    if (!element) return;
    const mousePointerEl = document.getElementById('mouse-pointer') as HTMLElement;
    const mousePointerRadius = (mousePointerEl?.offsetWidth + 6) / 2; // 6 is the box-shadow size from css
    const { top: phoneTop, left: phoneLeft } = document.getElementsByClassName('phone')[0].getBoundingClientRect();
    const { top: elTop, left: elLeft, width: elWidth, height: elHeight } = element.getBoundingClientRect();
    const relTop = elTop - phoneTop;
    const relLeft = elLeft - phoneLeft;

    if (!mousePointerEl) return;

    mousePointerEl.style.top = `${relTop - mousePointerRadius + elHeight / 2}px`;
    mousePointerEl.style.left = `${relLeft - mousePointerRadius + elWidth / 2}px`;
  };

  const simulateClick = () => {
    const mousePointerEl = document.getElementById('mouse-pointer') as HTMLElement;
    mousePointerEl.style.transform = 'scale(.8)';
    setTimeout(() => (mousePointerEl.style.transform = 'scale(1)'), 50);
  };

  const safeDecodeURIComponent = (component: string) => {
    try {
      return decodeURIComponent(component);
    } catch {
      return component;
    }
  };

  // full preview animations
  useEffect(() => {
    if (!isFullPreview) {
      resetSteps();
      return;
    }
    let animationTimeout: NodeJS.Timeout;
    let pointerMoveTimeout: NodeJS.Timeout;
    let pointerClickTimeout: NodeJS.Timeout;

    const animate = () => {
      setIsDemoIframeDetailView(true);
      switch (currentStep) {
        case AnimationSteps.EMPTY:
          setShowMousePointer(true);
          if (push?.title || push?.body) animationTimeout = setTimeout(() => setCurrentStep(AnimationSteps.PUSH), 500);
          else if (contents?.length >= 1) {
            animationTimeout = setTimeout(() => setCurrentStep(AnimationSteps.CONTENT), 500);
          } else animationTimeout = setTimeout(finishAnimation, 500);
          break;
        // may need to be tweaked as push -> details, but push is never empty in the current implementation
        case AnimationSteps.PUSH:
          if (contents?.length >= 1) {
            const element = document.getElementsByClassName('push-preview-card')[0];
            pointerMoveTimeout = setTimeout(() => moveMousePointerToElement(element), 1000);
            pointerClickTimeout = setTimeout(simulateClick, 2400);
            animationTimeout = setTimeout(() => setCurrentStep(AnimationSteps.CONTENT), 2500);
          } else animationTimeout = setTimeout(finishAnimation, 500);
          break;
        case AnimationSteps.CONTENT:
          if (contents?.length >= 1 && !isDynamicContentDemo) {
            const element = document.querySelectorAll(
              '.content-preview-card__button-link, .content-preview-card__text-link',
            )[0];
            // we dont want to click the button if its a link
            if (element?.className === 'content-preview-card__text-link') {
              animationTimeout = setTimeout(finishAnimation, 500);
              break;
            }
            pointerMoveTimeout = setTimeout(() => moveMousePointerToElement(element), 1000);
            pointerClickTimeout = setTimeout(simulateClick, 2400);
            animationTimeout = setTimeout(() => setCurrentStep(AnimationSteps.DETAILS), 2500);
          } else animationTimeout = setTimeout(finishAnimation, 500);
          break;
        case AnimationSteps.DETAILS:
          animationTimeout = setTimeout(finishAnimation, 500);
          break;
      }
    };
    animate();

    return () => {
      clearTimeout(animationTimeout);
      clearTimeout(pointerClickTimeout);
      clearTimeout(pointerMoveTimeout);
    };
  }, [push, contents, currentStep, isFullPreview, isDynamicContentDemo]);

  return (
    <div className={`${className} multi-action-preview`}>
      <div className="phone" style={{ backgroundImage: `url(${PhonePreviewWallpaper})` }}>
        <div className="black"></div>
        {((showPreview === 'push' && !isFullPreview) || currentStep === AnimationSteps.PUSH) && push && (
          <div className="push-notification" aria-label="push notification">
            <PushPreviewCard {...push} direction={language?.direction} />
          </div>
        )}

        {((showPreview === 'content' && !isFullPreview) || currentStep === AnimationSteps.CONTENT) &&
          contents?.length >= 1 && (
            <div
              className={`card-view${isDynamicContentDemo ? ' card-view__dynamic' : ''}`}
              aria-label="card view"
              style={{ paddingTop: contents.length > 1 ? '12px' : undefined }}
            >
              {isDynamicContentDemo ? (
                <DynamicContentPreview
                  contentInstanceIds={dynamicContentDemoIds}
                  isDetails={isDemoIframeDetailView}
                  language={language}
                />
              ) : (
                contents.map((c) => (
                  <ContentPreviewCard
                    key={c.id}
                    imageUrl={c.imageUrl}
                    header={c.header || '<header>'}
                    title={c.title || '<title>'}
                    description={c.description || '<description>'}
                    type={c.templateType}
                    actions={c?.buttons?.length ? c.buttons : [c.link]}
                  />
                ))
              )}
            </div>
          )}
        {currentStep === AnimationSteps.DETAILS && (
          <div className="card-detail-view" aria-label="card detail">
            <ContentDetailPreviewCard
              imageUrl={contents[0]?.imageUrl}
              header={contents[0]?.header || '<header>'}
              title={contents[0]?.title || '<title>'}
              description={safeDecodeURIComponent(contents[0]?.description) || '<description>'}
            />
          </div>
        )}
        {isFullPreview && showMousePointer && <div className="mouse-pointer" id="mouse-pointer" />}
        {showReplayButton && (
          <div className="replay-button-wrapper">
            <FlightButton onClick={resetSteps} label={'Replay'} iconLeft={'replay'} />
          </div>
        )}
        <div className="home-indicator"></div>
        <div className="statusbar">
          <div className="left-side">Rogers</div>
          <div className="notch"></div>
          <div className="right-side">
            <div className="mobile-signal">
              <PhoneSignalIcon />{' '}
            </div>
            <div className="wifi">
              <PhoneWifiIcon />
            </div>
            <div className="battery">
              <PhoneBatteryIcon />
            </div>
          </div>
        </div>
        <div className="time-date">
          <div className="lock">
            <PhoneLockIcon />
            <div className="time">{currentTime.time}</div>
            <div className="date">{currentTime.date}</div>
          </div>
        </div>
        <div className="bottom-actions">
          <div className="action-btn">
            <PhoneFlashlightIcon />
          </div>
          <div className="action-btn">
            <PhoneCameraIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
