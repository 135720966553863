import React, { useState } from 'react';
import { TTimelineBoxProps } from 'pages/ExperienceCanvas/types';
import { FlightButton } from '@flybits/design-system';
import TouchpointEditorCard from 'components/Shared/TouchpointEditorCard/TouchpointEditorCard';
import FilesHistoryIcon from 'pages/ExperienceCanvas/assets/icons/FilesHistoryIcon';
import UploadContextDataIcon from 'pages/ExperienceCanvas/assets/icons/UploadContextDataIcon';
import FileUpload from './FileUpload/FileUpload';
import './UploadContextDataBox.scss';
import ContextDataSlideOut from 'components/ExperienceCanvas/ContextDataSlideOut/ContextDataSlideOut';

const MAIN_CLASS = 'upload-context-data-box';
const CLASSES = {
  BUTTON_LABEL: `${MAIN_CLASS}__button-label`,
  UPLOAD_CONTEXT_DATA_PANEL: `${MAIN_CLASS}__upload-context-data-panel`,
  FILE_UPLOAD_WRAPPER: `${MAIN_CLASS}__file-upload-wrapper`,
};

const UploadContextDataBox = ({ timelineIndex }: TTimelineBoxProps) => {
  const [isContextDataSlideOutIOpen, setIsContextDataSlideOutOpen] = useState(false);

  const toggleIsContextDataSlideOutOpen = () => {
    setIsContextDataSlideOutOpen((prevState) => !prevState);
  };

  const buttonLabel = (
    <div className={CLASSES.BUTTON_LABEL}>
      <FilesHistoryIcon />
      See upload history
    </div>
  );

  return (
    <TouchpointEditorCard
      className={MAIN_CLASS}
      title="Upload Context Data"
      icon={UploadContextDataIcon}
      timelineIndex={timelineIndex}
      subtitle="Providing your own data files enables personalized marketing experiences and precise audience targeting."
    >
      <div>
        <div className={CLASSES.FILE_UPLOAD_WRAPPER}>
          <FileUpload allowedTypes={['text/csv']} />
        </div>
        <FlightButton label={buttonLabel} onClick={toggleIsContextDataSlideOutOpen} theme="secondary" />
        <ContextDataSlideOut isOpen={isContextDataSlideOutIOpen} onClickClose={toggleIsContextDataSlideOutOpen} />
      </div>
    </TouchpointEditorCard>
  );
};

export default UploadContextDataBox;
