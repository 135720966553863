import React from 'react';
import './Graphs.scss';
import { ResponsivePie } from '@nivo/pie';
import { GraphData } from 'components/Analytics/types';
type TGraphProps = {
  data: GraphData[];
  legendIcon: 'circle' | 'diamond' | 'square' | 'triangle';
  innerRadius?: number;
  isLegendVisible?: boolean;
};

export default function PieGraph(props: TGraphProps) {
  const maxChars = 12;
  let maxWidth = maxChars;
  props.data.map((item) => {
    const newWidth = item.id.length * 6;
    if (newWidth > maxChars && newWidth > maxWidth) {
      maxWidth = newWidth;
    }
  });
  const renderResponsivePie = () => (
    <ResponsivePie
      data={props.data.map(({ label, ...d }) => ({
        ...d,
        label: String(label)?.length >= maxChars ? String(label).substring(0, maxChars) + '...' : String(label),
      }))}
      margin={{ top: 15, right: 10, bottom: 15, left: 10 }}
      innerRadius={props.innerRadius}
      padAngle={0}
      colors={{ datum: 'data.color' }}
      cornerRadius={3}
      enableArcLabels={props.data.some(({ value }) => String(value).length >= 6) ? false : true}
      arcLinkLabel={(d) => `${d.label}`}
      transitionMode="centerRadius"
      enableArcLinkLabels={true}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextOffset={6}
      arcLinkLabelsDiagonalLength={6}
      arcLinkLabelsStraightLength={6}
      arcLinkLabelsThickness={1}
      arcLabelsSkipAngle={20}
      activeOuterRadiusOffset={5}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      isInteractive
      animate
      theme={{
        legends: {
          text: {
            fontSize: 11,
            blockOverflow: 'ellipsis',
          },
        },
        labels: {
          text: {
            blockOverflow: 'ellipsis',
          },
        },
      }}
      legends={
        props.isLegendVisible
          ? [
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 1,
                itemWidth: 50,
                itemHeight: 18,
                itemDirection: 'top-to-bottom',
                itemTextColor: '#999',
                symbolSize: 14,
                symbolShape: props.legendIcon,
              },
            ]
          : []
      }
    />
  );

  return <div className="pie-chart">{renderResponsivePie()}</div>;
}
