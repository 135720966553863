import React from 'react';
import ImageLaunchZone from 'pages/Zones/assets/images/image-launch-zone.svg';

import './SetupZones.scss';
import { FlightButton } from '@flybits/design-system';

const MAIN_CLASS = 'setup-zones';
const CLASSES = {
  SETUP_IMAGE: `${MAIN_CLASS}__setup-image`,
  SETUP_TEXT_CONTAINER: `${MAIN_CLASS}__setup-text-container`,
  SETUP_BUTTON: `${MAIN_CLASS}__setup-button`,
};

type SetupZonesProps = {
  isLaunchingZone: boolean;
  onLaunchZone: () => void;
};

function SetupZones(props: SetupZonesProps) {
  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.SETUP_IMAGE}>
        <img src={ImageLaunchZone} alt="getting started" />
      </div>
      <div className={CLASSES.SETUP_TEXT_CONTAINER}>
        <div className={`${CLASSES.SETUP_TEXT_CONTAINER}__title`}>
          {props.isLaunchingZone ? 'Getting started' : 'Let’s launch your project'}
        </div>
        <div className={`${CLASSES.SETUP_TEXT_CONTAINER}__sub-title`}>
          {props.isLaunchingZone
            ? 'One moment as we initialize your project ...'
            : 'Start setting up zones and modules for your project!'}
        </div>
      </div>
      <FlightButton
        className={CLASSES.SETUP_BUTTON}
        ariaLabel="Launch Zones and Modules"
        label="Launch"
        loading={props.isLaunchingZone}
        onClick={() => props.onLaunchZone()}
      />
    </div>
  );
}

export default SetupZones;
