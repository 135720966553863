import './FlowStartNode.scss';

import React from 'react';
import IconStartNode from 'pages/FlowBuilder/assets/icons/IconStartNode';
import { Handle, Position } from 'reactflow';

const MAIN_CLASS = 'flow-start-node';
const CLASSES = {
  ICON: `${MAIN_CLASS}__icon`,
  LABEL: `${MAIN_CLASS}__label`,
};

type FlowStartNodeProps = {
  data: {
    label: string;
  };
};

const purple100 = '#D4CEFF';

const sourceHandleStyle = {
  bottom: '-7px',
  width: '30px',
  height: '8px',
  border: 0,
  borderRadius: '0 0 30px 30px',
  backgroundColor: purple100,
};

function FlowStartNode(props: FlowStartNodeProps) {
  const {
    data: { label },
  } = props;

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.ICON}>
        <IconStartNode />
      </div>
      <div className={CLASSES.LABEL}>{label}</div>
      <Handle type="source" position={Position.Bottom} style={sourceHandleStyle} />
    </div>
  );
}

export default FlowStartNode;
