import { Localizations, SurveyMetaData } from 'interface/shared';

export enum TemplateType {
  CONCIERGE_CARD_BUTTONS = 'concierge-card-buttons',
  CONCIERGE_CARD_LINK = 'concierge-card-link',
}

export const streamlinedContentTypes = [TemplateType.CONCIERGE_CARD_BUTTONS, TemplateType.CONCIERGE_CARD_LINK];

interface ContentData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>;
  pagination?: {
    totalRecords: number;
    limit: number;
    offset: number;
    sortBy: string;
    sortOrder: 'asc' | 'desc';
  };
}

export type ContentMetadata = {
  description: string;
  iconUrl: string;
  id: string;
  isContextLocked: boolean;
  labels: string[];
  title: string;
};

export type ContentCreationPayload = Omit<Content, 'content'> & {
  // oof
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  templatePrototypeId?: string;
};

export type ContentPayload = Content & ContentPrototype;

export function isContentInstance(content: Content | ContentTemplate): content is Content {
  return (content as Content).templateId !== undefined;
}

export function isContentTemplate(content: Content | ContentTemplate): content is ContentTemplate {
  return (content as ContentTemplate).type !== undefined;
}

export type Content = DynamicDefaults & {
  accessType?: string;
  contentModeration?: boolean;
  content?: ContentData;
  createdAt?: number;
  iconUrl?: string;
  id?: string;
  internal?: boolean;
  labels?: string[];
  links?: string[];
  localizations?: Localizations;
  metadata?: object;
  modifiedAt?: number;
  surveyMetadata?: SurveyMetaData;
  templateId?: string;
  templateType?: string;
  tenantId?: string;
};

export type ContentTemplate = {
  createdAt?: number;
  iconUrl?: string;
  id?: string;
  internal?: boolean;
  labels?: Array<string>;
  localizations?: Localizations;
  modifiedAt?: number;
  schema?: {
    fields?: ContentPrototypeSchemaField[];
  };
  tenantId?: string;
  type?: string;
  defaultValues?: DynamicDefaults;
};

export interface ContentPrototypePayload {
  constraints?: {
    actionType?: {
      defaultValue?: string;
      isReadOnly?: boolean;
    };
    contentTemplate?: {
      defaultValue?: string;
      isReadOnly?: boolean;
    };
    contentInstance?: {
      defaultValue?: string;
      isReadOnly?: boolean;
    };
    schemaConstraints?: {
      [key: string]: {
        fields?: ContentPrototypeSchemaConstraintsField[];
      };
    };
    groupingFilter?: {
      filter: { key: string; value: string }[];
      operator: string;
    };
  };
  contentTemplate?: ContentTemplate;
  description?: string;
  name?: string;
}

export interface ContentPrototype {
  id?: string;
  iconUrl: string;
  payload?: ContentPrototypePayload;
  createdAt?: number;
  modifiedAt?: number;
  tenantId?: string;
  userId?: string;
  content?: ContentData;
}

export interface ContentPrototypeSchemaConstraintsField {
  defaultValue?: string;
  isReadOnly?: boolean;
  name: string;
  type: string;
}

export interface ContentPrototypeSchemaField {
  displayName?: {
    [key: string]: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any;
  name: string;
  properties?: {
    isRequired?: boolean;
    isUniqueIdentifier?: boolean;
  };
  type?: string;
  fields?: ContentPrototypeSchemaField[];
}

export interface ExperienceContent {
  createdAt: number;
  evaluatedAt: number;
  experienceId: string;
  iconUrl: string;
  id: string;
  localizations: Localizations;
  modifiedAt: number;
  tenantId: string;
}

export interface ContentPreview {
  headerImg?: string;
  header?: string;
  title?: string;
  body?: string;
  type?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  details?: string;
  linkLabel?: string;
  isContentInstance: boolean;
  isStarter: boolean;
  isVisibleOnBox?: boolean;
  unSupportedTitle?: string;
}

// TODO: tighten optional once typing is confirmed and all typing bugs resolved
type DynamicDefaults = {
  defaultUIConfig?: ContentUiConfig;
  detailsUIConfig?: ContentUiConfig;
  renderType?: string;
};

type ContentUiConfig = {
  layout: string;
  layoutHTML: string;
  styleCSS: string;
  styleCSSURL: string;
};

export interface Component {
  contentID: string;
  componentID: string;
  componentName?: string;
  componentType?: string;
}
