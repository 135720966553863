import React, { useState } from 'react';
import ModulePositionIcon from 'pages/Zones/assets/icons/icon-module-position.svg';
import ModulePriortizationIcon from 'pages/Zones/assets/icons/icon-module-priortization.svg';
import SetupModuleBox from '../SetupModuleBox/SetupModuleBox';
import { Module, ZoneConfig } from 'pages/Zones/types';
import './ModuleBox.scss';
import PrioritizationManager from '../PrioritizationManager/PrioritizationManager';
import ModulePrioritizationContainer from '../ModulePrioritizationContainer/ModulePrioritizationContainer';
import { FlightOverflowMenu, getIcon } from '@flybits/design-system';
import { cloneDeep } from 'lodash';
import ModuleConfigurationSidePanel from 'pages/Zones/ModuleConfigurationSidePanel/ModuleConfigurationSidePanel';

type TModuleBoxProps = {
  module: Module;
  selectedZoneId: string;
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
  toggleModulePositionManager: () => void;
};

const MAIN_CLASS = 'module-box';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  BODY: `${MAIN_CLASS}__body`,
};

const ModuleBox: React.FC<TModuleBoxProps> = ({
  module,
  selectedZoneId,
  zoneConfig,
  setZoneConfig,
  toggleModulePositionManager,
}) => {
  const [isPrioritizationManagerOpen, setIsPrioritizationManagerOpen] = useState(false);
  const [isModuleConfigurationSidePanelOpen, setIsModuleConfigurationSidePanelOpen] = useState(false);

  const deleteModule = () => {
    const _zoneConfig = cloneDeep(zoneConfig);
    const zoneIndex = _zoneConfig.zones.findIndex((zone) => zone.id === selectedZoneId);
    const _modules = _zoneConfig.zones[zoneIndex].modules.filter((m) => m.id !== module.id);
    _zoneConfig.zones[zoneIndex].modules = _modules;
    setZoneConfig(_zoneConfig);
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.HEADER}>
        <div>
          <h3>{module.name}</h3>
          <span>
            • Positions <strong>{(module?.labelsOrder?.length || 0) + (module?.pinnedContentIDs?.length || 0)}</strong>
          </span>
        </div>
        <ul>
          <li>
            <button type="button" onClick={toggleModulePositionManager}>
              <img src={ModulePositionIcon} alt="" />
              Module Position
            </button>
          </li>
          <li>
            <button type="button" onClick={() => setIsPrioritizationManagerOpen(true)}>
              <img src={ModulePriortizationIcon} alt="" />
              Module Prioritization
            </button>
          </li>
          <li>
            <button type="button" onClick={() => setIsModuleConfigurationSidePanelOpen(true)}>
              {getIcon('cogOutline', {})}
              Module Configuration
            </button>
          </li>
          <li>
            <button type="button" onClick={deleteModule}>
              {getIcon('trashCan', {})}
            </button>
          </li>
        </ul>
        <FlightOverflowMenu
          className="module-box__overflow-menu"
          direction="left"
          optionGroups={[
            [
              {
                key: 1,
                name: 'Module Position',
                onClick: toggleModulePositionManager,
              },
              {
                key: 2,
                name: 'Module Prioritization',
                onClick: () => setIsPrioritizationManagerOpen(true),
              },
              {
                key: 3,
                name: 'Module Configuration',
                onClick: () => setIsModuleConfigurationSidePanelOpen(true),
              },
              {
                key: 4,
                name: 'Delete Module',
                onClick: deleteModule,
              },
            ],
          ]}
        />
      </div>
      <div className={CLASSES.BODY}>
        {module?.labelsOrder?.length > 0 || module?.pinnedContentIDs?.length > 0 ? (
          <ModulePrioritizationContainer module={module} />
        ) : (
          <SetupModuleBox openPrioritizationManager={() => setIsPrioritizationManagerOpen(true)} />
        )}
      </div>
      <PrioritizationManager
        isOpen={isPrioritizationManagerOpen}
        onClose={() => setIsPrioritizationManagerOpen(false)}
        zoneId={selectedZoneId}
        moduleId={module.id}
        zoneConfig={zoneConfig}
        setZoneConfig={setZoneConfig}
      />
      <ModuleConfigurationSidePanel
        isOpen={isModuleConfigurationSidePanelOpen}
        onClose={() => setIsModuleConfigurationSidePanelOpen(false)}
        zoneId={selectedZoneId}
        moduleId={module.id}
        zoneConfig={zoneConfig}
        setZoneConfig={setZoneConfig}
      />
    </div>
  );
};

export default ModuleBox;
