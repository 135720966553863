import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FlightToggleSwitch } from '@flybits/design-system';
import { fetchInstance } from 'store/journey/journey.thunk';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import SlidingSidePanel from '../SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import './Settings.scss';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';

interface TSettingsProps {
  isOpen: boolean;
  onClickClose: () => void;
}

const MAIN_CLASS = 'settings-sliding-panel';
const CLASSES = {
  SECTION: `${MAIN_CLASS}__section`,
  SECTION_TITLE: `${MAIN_CLASS}__section-title`,
  TOGGLE_SWITCH_WRAPPER: `${MAIN_CLASS}__toggle-switch-wrapper`,
};

function Settings({ isOpen, onClickClose }: TSettingsProps) {
  const templatedExperienceAPI = useMemo(() => new TemplatedExperienceAPI(), []);

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { te } = useSelector((state: any) => state);
  const journeyState = te.journey;
  const audienceOptimizationState = journeyState.audienceOptimization;
  const audienceOptimizationEligibleState = audienceOptimizationState?.audienceOptimizationEligible || false;
  const skipEngagementCalculationState = audienceOptimizationState?.skipEngagementCalculation || false;

  const [skipEngagementCalculation, setSkipEngagementCalculation] = useState<boolean>(skipEngagementCalculationState);
  const [isConfirmStopEngagementModalVisible, setIsConfirmStopEngagementModalVisible] = useState(false);
  const [stopEngagementInProgress, setStopEngagementInProgress] = useState(false);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: 'Experience Settings',
        showCloseButton: true,
      },
      footerInfo: {
        primaryActionText: '',
        secondaryActionText: 'Close',
        secondaryActionHandler: onClickClose,
        showProvideFeedbackButton: true,
      },
      size: 'small',
      showFooter: false,
    }),
    [isOpen, onClickClose],
  );

  const handleStopEngagementToggled = (value: boolean) => {
    setSkipEngagementCalculation(value);
    setIsConfirmStopEngagementModalVisible(true);
  };

  const handleStopEngagementCanceled = () => {
    setSkipEngagementCalculation((skipped) => !skipped);
    setIsConfirmStopEngagementModalVisible(false);
  };

  const handleStopEngagementConfirmed = async () => {
    setStopEngagementInProgress(true);

    const journeyPayload = {
      ...journeyState,
      audienceOptimization: {
        ...audienceOptimizationState,
        skipEngagementCalculation,
      },
    };

    try {
      const updateResponse = await templatedExperienceAPI.updateTemplatedExperienceInstance(journeyPayload);

      if (!updateResponse || updateResponse?.errors) {
        throw new Error('There was a problem updating the experience.');
      }

      const fetchResponse = await dispatch(fetchInstance(journeyState.id));

      if (!fetchResponse) {
        throw new Error('There was a problem fetching the experience.');
      }

      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          content: `Engagement calculation ${skipEngagementCalculation ? 'skipped' : 'resumed'}`,
          type: 'success',
        },
      });
    } catch (error) {
      setSkipEngagementCalculation((skipped) => !skipped);
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: `Something went wrong! ${error}`, type: ERROR_TYPES.ERROR },
      });
    } finally {
      setStopEngagementInProgress(false);
      setIsConfirmStopEngagementModalVisible(false);
    }
  };

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={MAIN_CLASS} aria-label="experience settings">
        <div className={CLASSES.SECTION} aria-label="enable or disable engagement calculation">
          <div className={CLASSES.SECTION_TITLE}>Mock Engagement</div>
          {!audienceOptimizationEligibleState && (
            <div>
              This experience is not eligible for audience optimization. Therefore, this setting is not available.
            </div>
          )}
          <div
            className={
              audienceOptimizationEligibleState
                ? `${CLASSES.TOGGLE_SWITCH_WRAPPER}`
                : `${CLASSES.TOGGLE_SWITCH_WRAPPER} disabled`
            }
          >
            <p className="description">
              Activate this toggle to manually send engagement data to simulate model predictions for the Smart
              Targeting feature.
            </p>
            <FlightToggleSwitch
              className="silent-push-settings__btn"
              checked={skipEngagementCalculation}
              onChange={handleStopEngagementToggled}
              label={
                skipEngagementCalculationState ? 'Disable engagement calculation' : 'Enable engagement calculation'
              }
            />
          </div>
        </div>
        <ConfirmModal
          isVisible={isConfirmStopEngagementModalVisible}
          title={`${skipEngagementCalculation ? 'Stop' : 'Resume'} engagement calculation`}
          content={
            <>
              <p>
                The engagement calculation for this experience will be{' '}
                {skipEngagementCalculation ? 'stopped' : 'resumed'}.
              </p>
              <p>Do you wish to continue?</p>
            </>
          }
          isLoading={stopEngagementInProgress}
          primaryButton={{ name: 'Confirm', onClick: handleStopEngagementConfirmed }}
          cancelButton={{ name: 'Cancel', onClick: handleStopEngagementCanceled }}
        />
      </div>
    </SlidingSidePanel>
  );
}

export default Settings;
