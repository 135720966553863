import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FlightButton, FlightSnackbar } from '@flybits/design-system';
import SearchBarPage from 'components/Shared/SearchBarPage/SearchBarPage';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { TemplatesLibrarySidebar } from './TemplatesLibrarySidebar';
import { TemplatesLibraryContent } from './TemplatesLibraryContent';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useAsyncState from 'hooks/useAsyncState';
import ContentLoader from 'react-content-loader';
import { Category } from 'interface/templated-experience/templated-experience.interface';
import { ActionTypes } from 'store/actionTypes';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useCategoryLibrary from 'hooks/useCategoryLibrary';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import './TemplatesLibrary.scss';

interface RouteMatch {
  pid?: string;
  cid?: string;
  sid?: string;
}

export default function TemplatesLibrary() {
  const journeyApi = useMemo(() => new TemplatedExperienceAPI(), []);

  const dispatch = useDispatch();
  const { isInitialized } = useFeatureFlag();
  const history = useHistory();
  const DEFAULT_CLASS = 'templates-library';
  const projectData: RouteMatch = useRouteMatch().params;
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState('');
  const [closeSnackbar, setCloseSnackBar] = useState(false);
  const [isAllTemplateSelected, setIsAllTemplateSelected] = useState(true);
  const tempIdRef = useRef('');

  const CustomContentLoader = () => (
    <ContentLoader viewBox="0 0 380 180">
      <rect x="5" y="5" rx="5" ry="5" width="70" height="15" />
      <rect x="5" y="25" rx="5" ry="5" width="70" height="15" />
      <rect x="5" y="45" rx="5" ry="5" width="70" height="15" />
      <rect x="5" y="65" rx="5" ry="5" width="70" height="15" />
      <rect x="5" y="85" rx="5" ry="5" width="70" height="15" />
      <rect x="5" y="105" rx="5" ry="5" width="70" height="15" />
      <rect x="5" y="125" rx="5" ry="5" width="70" height="15" />
      <rect x="5" y="145" rx="5" ry="5" width="70" height="15" />
      <rect x="95" y="5" rx="5" ry="5" width="270" height="100" />
      <rect x="115" y="110" rx="5" ry="5" width="70" height="55" />
      <rect x="195" y="110" rx="5" ry="5" width="70" height="55" />
      <rect x="275" y="110" rx="5" ry="5" width="70" height="55" />
    </ContentLoader>
  );

  const handleCancel = () => {
    history.push(`/project/${projectData?.pid}`);
  };

  const handleCreate = (id: string) => {
    tempIdRef.current = id;
  };

  const handleBreadcrumb = (id: string) => {
    const cardsList = document.getElementsByClassName(
      'templates-library__main-content__content__container__cards__card',
    );
    for (const card of cardsList) {
      card.classList.remove('selected');
    }
    tempIdRef.current = '';
    if (id) {
      history.push(`/project/${projectData?.pid}/experiences/templates/library/${id}`);
    } else {
      setIsAllTemplateSelected(true);
      history.push(`/project/${projectData?.pid}/experiences/templates/library`);
    }
  };

  const handleSidebar = (catId: string, subCatId: string) => {
    const cardsList = document.getElementsByClassName(
      'templates-library__main-content__content__container__cards__card',
    );
    for (const card of cardsList) {
      card.classList.remove('selected');
    }
    tempIdRef.current = '';
    setIsAllTemplateSelected(catId === '');
    if (subCatId) {
      history.push(`/project/${projectData?.pid}/experiences/templates/library/${catId}/${subCatId}`);
    } else if (catId) {
      history.push(`/project/${projectData?.pid}/experiences/templates/library/${catId}`);
    } else {
      history.push(`/project/${projectData?.pid}/experiences/templates/library`);
    }
  };

  const handleViewAll = (id: string) => {
    setIsAllTemplateSelected(false);
    if (projectData.cid) {
      history.push(`/project/${projectData?.pid}/experiences/templates/library/${projectData.cid}/${id}`);
    } else {
      history.push(`/project/${projectData?.pid}/experiences/templates/library/${id}`);
    }
  };

  /**
   * TODO: Once flow is released, a decision needs to be made on what we want createTemplate and handleUseFlowButton to do.
   * If we still want to allow certain users to view the flow version of non-branching templates, we need to keep both buttons,
   * or allow the user to navigate back to (and stay on) the flow page from overview, since the default behaviour on the flow-builder page (without the viewFlow query param)
   * will be to check if the template is branching and then redirect to the overview page if it is not.
   */
  const createTemplate = () => {
    if (tempIdRef.current) {
      return history.push(
        `/project/${projectData?.pid}/experiences/${tempIdRef.current}/overview?fromTemplateLibrary=true`,
      );
    } else {
      setCloseSnackBar(true);
      setTimeout(() => {
        setCloseSnackBar(false);
      }, 3000);
    }
  };

  const handleUseFlowButton = () => {
    if (tempIdRef.current) {
      return history.push(
        `/project/${projectData?.pid}/experiences/${tempIdRef.current}/flow-builder?viewFlow=true&fromTemplateLibrary=true`,
      );
    } else {
      setCloseSnackBar(true);
      setTimeout(() => {
        setCloseSnackBar(false);
      }, 3000);
    }
  };

  const { categories, isLoading, isError } = useCategoryLibrary();

  const [templatedExperiences, areTemplatedExperiencesLoading, isTemplatedExperiencesError] = useAsyncState(
    () =>
      journeyApi.getTemplatedExperiences().then((r) => {
        return r.data;
      }),
    [''],
  );

  const RenderErrorBanner = () => {
    const message = `Please select a Template`;
    return (
      <FlightSnackbar
        isVisible={closeSnackbar}
        isFloating={true}
        type="info"
        className="library-selection__error-banner"
        content={message}
        isAutoDismiss={true}
        action={() => {
          setCloseSnackBar(false);
        }}
        actionName="x"
      />
    );
  };

  useEffect(() => {
    document.title = 'Templates Library | Experience Studio @ Flybits';
  }, []);

  useEffect(() => {
    if (isLoading || areTemplatedExperiencesLoading || isError || isTemplatedExperiencesError || !isInitialized) {
      return;
    }

    const libList = categories || [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    templatedExperiences?.forEach((template: any) => {
      const categoryName = template.categories?.[0]?.split('category-')[1];
      const subCategoryName = template.categories?.[1]?.split('subcategory-')[1];
      const checkCatExist = libList?.find((cat: Category) => cat.key === categoryName);

      if (categoryName && checkCatExist) {
        const checkSubCatExist = checkCatExist?.subcategories?.find(
          (subCat: Category) => subCat.key === subCategoryName,
        );
        if (subCategoryName && checkSubCatExist) {
          return;
        }
        const checkCatUnassigned = checkCatExist?.subcategories?.find(
          (subCat: Category) => subCat.key === 'unassigned',
        );
        if (!checkCatUnassigned) {
          libList?.forEach(function (catValue: Category) {
            if (
              catValue.key === categoryName &&
              !(
                catValue.key === 'byo' ||
                catValue.key === 'flybits-starters' ||
                catValue.key === 'recently-added' ||
                catValue.key === 'recently-used'
              )
            ) {
              if (!catValue.subcategories) {
                catValue.subcategories = [];
              }
              catValue.subcategories?.push({
                color: '',
                description: '',
                key: 'unassigned',
                name: 'Templates',
              });
            }
          });
        }
      } else {
        if (!libList?.find((cat: Category) => cat.key === 'unassigned')) {
          libList?.push({
            color: '',
            description: '',
            iconUrl: '',
            key: 'unassigned',
            name: 'Templates',
            subcategories: [],
          });
        }
      }
    });
    setCategoriesList(libList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areTemplatedExperiencesLoading,
    isLoading,
    isTemplatedExperiencesError,
    isError,
    categories,
    templatedExperiences,
  ]);
  useEffect(() => {
    if ((projectData.cid || projectData.sid) && categories?.length) {
      let isCategoryValid = false;
      categories?.forEach((category: Category) => {
        if (!category.name) {
          category.name = category.key;
        }
        if (projectData.sid) {
          category.subcategories?.forEach((subCat: Category) => {
            if (!subCat.name) {
              subCat.name = subCat.key;
            }
            if (category.key === projectData.cid && subCat.key === projectData.sid) {
              isCategoryValid = true;
            }
          });
        } else {
          if (category.key === projectData.cid) {
            isCategoryValid = true;
          }
        }
      });
      setIsAllTemplateSelected(!isCategoryValid);
      if (!isCategoryValid) {
        history.push(`/project/${projectData?.pid}/experiences/templates/library`);
      }
    } else {
      setIsAllTemplateSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.CLEAR_JOURNEY,
    });
  }, [dispatch]);
  return (
    <div className="templates-library__container">
      <div className={DEFAULT_CLASS}>
        {isLoading || areTemplatedExperiencesLoading ? (
          <CustomContentLoader />
        ) : (
          <>
            <RenderErrorBanner />
            <div className={`${DEFAULT_CLASS}__header`}>
              <FlightButton
                theme="minor"
                iconLeft="baselineKeyboardArrowLeft"
                onClick={handleCancel}
                ariaLabel="back button"
                className={`${DEFAULT_CLASS}__header__back`}
              />
              <h2 className={`${DEFAULT_CLASS}__header__title`}>Template Library</h2>
              <SearchBarPage
                className={`${DEFAULT_CLASS}__header__search`}
                handleSearchTermChange={(text: string) => {
                  setSearchText(text);
                  history.push(`/project/${projectData?.pid}/experiences/templates/library`);
                  setIsAllTemplateSelected(true);
                }}
                placeholder="Search templates"
                width="500px"
              />
            </div>
            <TemplatesLibrarySidebar
              isAllTemplateSelected={isAllTemplateSelected}
              onSidebarClick={handleSidebar}
              categoriesList={categoriesList}
              projectData={projectData}
              isError={isError || isTemplatedExperiencesError}
            />
            <TemplatesLibraryContent
              categories={categoriesList}
              templates={templatedExperiences}
              categoryId={projectData.cid}
              subCategoryId={projectData.sid}
              onSelect={handleCreate}
              onViewAll={handleViewAll}
              onBreadcrumbClick={handleBreadcrumb}
              searchText={searchText}
              isError={isError || isTemplatedExperiencesError}
              onCreate={createTemplate}
              onUseFlow={handleUseFlowButton}
            />
          </>
        )}
      </div>
    </div>
  );
}
