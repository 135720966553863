import { applyMiddleware, createStore, AnyAction } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';

import thunk, { ThunkDispatch } from 'redux-thunk';
import rootReducer from './reducer';
import { createBrowserHistory } from 'history';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logger = (createLogger as any)();
export const history = createBrowserHistory();

export const routerMiddleware = createRouterMiddleware(history);
const middlewares = [thunk, routerMiddleware];

const dev = process.env.NODE_ENV === 'development';

let middleware = dev ? applyMiddleware(...middlewares, logger) : applyMiddleware(...middlewares);

if (dev) {
  middleware = composeWithDevTools(middleware);
}

export const store = createStore(rootReducer, middleware);

export type RootState = ReturnType<typeof store.getState>;
// a little bit of a hack to get around incomplete type inferrals sans RTK
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
