import React, { useState } from 'react';
import STExperiencePerformanceGraph from './STExperiencePerformanceGraph';
import { LineSeries } from 'react-vis';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { FlightOverflowMenu } from '@flybits/design-system';
import {
  GraphData,
  LegendGraphData,
  LineGraph,
  LineGraphData,
} from 'interface/templated-experience/graph-data.interface';
import STErrorState from '../STErrorState/STErrorState';
import STLoadingState from '../STLoadingState/STLoadingState';

const JOURNEY_TYPES_METRICS: Record<string, LineGraph[]> = {
  IN_APP_CONTENT: [
    {
      id: 'targetAudience',
      data: [],
      label: 'Audience Targeted',
      color: '#7C4DFF',
      className: 'left-color left-color-ta',
    },
    {
      id: 'contentViews',
      data: [],
      label: 'Viewed Content',
      color: '#16D892',
      className: 'left-color left-color-op',
    },
    {
      id: 'contentClicks',
      data: [],
      label: 'Clicked On Content',
      color: '#EC2758',
      className: 'left-color left-color-coc',
    },
  ],
  IN_APP_MULTI_CONTENT: [
    {
      id: 'targetAudience',
      data: [],
      label: 'Audience Targeted',
      color: '#7C4DFF',
      className: 'left-color left-color-ta',
    },
  ],
  TRIGGERED_PUSH: [
    {
      id: 'targetAudience',
      data: [],
      label: 'Audience Targeted',
      color: '#7C4DFF',
      className: 'left-color left-color-ta',
    },
    {
      id: 'pushDelivered',
      data: [],
      label: 'Push Delivered',
      color: '#3AC6FF',
      className: 'left-color left-color-pd',
    },
    {
      id: 'openedPush',
      data: [],
      label: 'Opened Push',
      color: '#16D892',
      className: 'left-color left-color-op',
    },
  ],
  TRIGGERED_PUSH_CONTENT: [
    {
      id: 'targetAudience',
      data: [],
      label: 'Audience Targeted',
      color: '#7C4DFF',
      className: 'left-color left-color-ta',
    },
    {
      id: 'pushDelivered',
      data: [],
      label: 'Push Delivered',
      color: '#3AC6FF',
      className: 'left-color left-color-pd',
    },
    {
      id: 'openedPush',
      data: [],
      label: 'Opened Push',
      color: '#16D892',
      className: 'left-color left-color-op',
    },
    {
      id: 'contentClicks',
      data: [],
      label: 'Clicked On Content',
      color: '#EC2758',
      className: 'left-color left-color-coc',
    },
  ],
  IN_APP_AMPLIFIED_CONTENT: [
    {
      id: 'targetAudience',
      data: [],
      label: 'Audience Targeted',
      color: '#7C4DFF',
      className: 'left-color left-color-ta',
    },
    {
      id: 'pushDelivered',
      data: [],
      label: 'Push Delivered',
      color: '#3AC6FF',
      className: 'left-color left-color-pd',
    },
    {
      id: 'contentViews',
      data: [],
      label: 'Viewed Content',
      color: '#16D892',
      className: 'left-color left-color-op',
    },
    {
      id: 'contentClicks',
      data: [],
      label: 'Clicked On Content',
      color: '#EC2758',
      className: 'left-color left-color-coc',
    },
  ],
  IN_APP_AMPLIFIED_MULTI_CONTENT: [
    {
      id: 'targetAudience',
      data: [],
      label: 'Audience Targeted',
      color: '#7C4DFF',
      className: 'left-color left-color-ta',
    },
    {
      id: 'pushDelivered',
      data: [],
      label: 'Push Delivered',
      color: '#3AC6FF',
      className: 'left-color left-color-pd',
    },
    {
      id: 'openedPush',
      data: [],
      label: 'Opened Push',
      color: '#16D892',
      className: 'left-color left-color-op',
    },
  ],
};

export default function STExperiencePerformance(props: {
  journeyType: string;
  dropdownValue: string;
  setDropdownValue: (val: string) => void;
}) {
  const [hoveredNode, setHoveredNode] = useState<GraphData>({ x: 0, y: 0 });
  const reduxAnalyticsState = useSelector((state) => state.analytics.analyticsPerformance);
  type PerformanceDataKey = keyof typeof reduxAnalyticsState;

  let experiencePerformanceData = JOURNEY_TYPES_METRICS[props.journeyType];
  // populate experiencePerformanceData data with redux values
  experiencePerformanceData = experiencePerformanceData.map((metric) => {
    const dataKey = metric.id as PerformanceDataKey;
    return {
      ...metric,
      data: reduxAnalyticsState[dataKey]?.data || [],
    };
  });

  const legendGraphData: LegendGraphData[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lineGraphData: any[] = [];
  let xAxisLabelData: number[] = [];
  let emptyGraphValue = true;
  experiencePerformanceData.forEach((experiencePerformanceItem: LineGraph, index: number) => {
    const lineGraphDataArray = experiencePerformanceItem.data;
    let lineGraphPlotValue: GraphData[] = [];
    if (lineGraphDataArray) {
      lineGraphPlotValue = lineGraphDataArray.map((lineDataValue: LineGraphData, innerIndex: number) => {
        return { x: innerIndex, y: lineDataValue.value };
      });
      const emptyGraphData = lineGraphDataArray.find((d: LineGraphData) => d.value);
      if (emptyGraphData) {
        emptyGraphValue = false;
      }
    } else {
      emptyGraphValue = false;
    }

    legendGraphData.push({ title: experiencePerformanceItem.label, color: experiencePerformanceItem.color });
    lineGraphData.push(
      <LineSeries
        key={index}
        onNearestXY={(value: GraphData) => setHoveredNode(value)}
        style={{ fill: 'none', strokeWidth: '2px' }}
        data={lineGraphPlotValue}
        color={experiencePerformanceItem.color}
        className={experiencePerformanceItem.className}
      />,
    );
  });

  if (experiencePerformanceData[0] && experiencePerformanceData[0].data) {
    xAxisLabelData = experiencePerformanceData[0].data.map((lineDataValue: LineGraphData) => {
      return lineDataValue.timestamp;
    });
  }

  return (
    <div className="st-experience-performance-container">
      <div className="top">
        <p className="headline">
          {props.journeyType === 'IN_APP_MULTI_CONTENT' ? 'Audience Targeted' : 'Experience Performance'}
        </p>
        <FlightOverflowMenu
          label={props.dropdownValue}
          iconRight="baselineKeyboardArrowDown"
          optionGroups={[
            [
              {
                key: '0',
                name: 'Daily',
                disabled: false,
                onClick: () => {
                  props.setDropdownValue('Daily');
                },
              },
              {
                key: '1',
                name: 'Weekly',
                disabled: false,
                onClick: () => {
                  props.setDropdownValue('Weekly');
                },
              },
              {
                key: '2',
                name: 'Monthly',
                disabled: false,
                onClick: () => {
                  props.setDropdownValue('Monthly');
                },
              },
            ],
          ]}
        />
      </div>
      {!!reduxAnalyticsState.errorMessage ? (
        <STErrorState errorMessage={reduxAnalyticsState.errorMessage} />
      ) : reduxAnalyticsState.isContentClicksLoading ||
        reduxAnalyticsState.isContentViewsLoading ||
        reduxAnalyticsState.isOpenedPushLoading ||
        reduxAnalyticsState.isPushDeliveredLoading ||
        reduxAnalyticsState.isTargetAudienceLoading ? (
        <STLoadingState />
      ) : (
        <STExperiencePerformanceGraph
          legendGraphData={legendGraphData}
          lineGraphData={lineGraphData}
          hoveredNode={hoveredNode}
          xAxisLabelData={xAxisLabelData}
          emptyGraph={emptyGraphValue}
        />
      )}
    </div>
  );
}
