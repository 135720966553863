import { FeatureFlagActionType } from 'store/feature-flag/feature-flag.type';

const initialState = {
  isInitialized: false,
  flags: {},
  traits: {},
};
// TODO: Strongly type this reducer, or get rid of this reducer and migrate to react-query
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state = initialState, action: any) => {
  switch (action.type) {
    case FeatureFlagActionType.INITIALIZE_FLAGS:
      return {
        isInitialized: true,
        flags: action.payload.flags,
        traits: action.payload.traits,
      };
    case FeatureFlagActionType.SET_FLAGS:
      return {
        ...state,
        flags: action.payload.flags,
      };
    case FeatureFlagActionType.SET_TRAITS:
      return {
        ...state,
        traits: action.payload.traits,
      };
    default:
      return {
        ...state,
      };
  }
};
