// eslint-disable-next-line @typescript-eslint/no-explicit-any

import momentTZ from 'moment-timezone';
import moment from 'moment';
import pluralize from 'pluralize';
import { Experience } from 'interface/experience/experience.interface';
import { Delivery } from 'store/templated-experience/templated-experience.type';

export function getTenantId(): string {
  const tenantIdFromPathname = window.location.pathname.split('/')[2];
  const urlParams = new URLSearchParams(window.location.search);
  const tenantIdFromParams = urlParams.get('path')?.split('/')[1] || '';

  return tenantIdFromParams || tenantIdFromPathname;
}

/**
 * Returns the style for the experience label, based on the status property
 * @returns the style name
 * @param status
 */
export function defineStatusStyle(status: string) {
  if (status === 'active') return 'success';
  if (status === 'inactive') return 'error';
  if (status === 'draft') return 'default';
  if (status === 'scheduled') return 'info';
  return 'default';
}

/**
 * Returns updated instance with a new status
 * @returns updated instance object
 * @param instances
 * @param id
 * @param status
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateTemplatedInstanceStatus(instances: any, id: string, status: string) {
  return instances.data
    ? instances.data.map((instance: Experience) => {
        if (instance.id === id) {
          instance.status = status;
        }
        return instance;
      })
    : [];
}

/**
 * Returns the list of timezones from the 'moment' library
 * @returns array
 */
export function getTimezoneList(searchZone = '') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timezones = momentTZ.tz.names().map((timezone: any, idx: number) => {
    return {
      key: idx + 1,
      name: timezone.replace(/_/g, ' ') + ' (UTC ' + momentTZ.tz(timezone).format('Z') + ')',
      offset: parseInt(momentTZ.tz(timezone).format('Z')),
    };
  });

  if (searchZone) {
    timezones = timezones.filter((timezone) => {
      return timezone.name.toLowerCase().search(searchZone.toLowerCase().replace(/[_)(+]/g, ' ')) >= 0;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return timezones.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
}

/**
 * Returns an estimate of the user's timezone from the 'moment' library
 * @returns string
 */
export function guessUserTimezone() {
  const timezone = moment.tz.guess();
  return timezone.replace(/_/g, ' ') + ' (UTC ' + momentTZ.tz(timezone).format('Z') + ')';
}

/**
 * Calculate time ranges give a min/max in epoch, a selected date, and a
 * timezone-based offset in hours.
 * Returns min max in minutes past midnight.
 * @returns { min: number, max: number }
 */
export function getTimeRangeInMinutes(date?: Date, min?: number, max?: number, interval = 15) {
  const retVal: { min?: number; max?: number } = { min: undefined, max: undefined };

  if (!date) return retVal;

  const secondsInDay = 60 * 60 * 24;
  const dateEpoch = date.getTime() / 1000;

  if (min && dateEpoch < min) {
    retVal.min = Math.ceil((min - dateEpoch) / 60.0 / interval) * interval;
  }

  if (max && dateEpoch + secondsInDay > max) {
    retVal.max = Math.ceil((max - dateEpoch) / 60.0 / interval) * interval;
  }

  return retVal;
}

/**
 * Returns timezone name without UTC data
 * @returns string
 */
export function cleanupTimezone(timezone: string) {
  const start = timezone?.indexOf(' (UTC');
  if (start > -1) {
    timezone = timezone?.slice(0, start);
  }
  timezone = timezone?.replace(/ /, '_');
  return timezone;
}

/**
 * Returns date and time, converted to timezone + user's local time
 * @returns object
 */
export function convertDateToTimezone(date: number, timezone: string) {
  const dateFormatted = moment(date * 1000).format('YYYY-MM-DD HH:mm');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selZoneTime = momentTZ.tz(dateFormatted, cleanupTimezone(timezone!));
  const localZoneTime = selZoneTime.clone().tz(moment.tz.guess());
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dateTZFormatted = momentTZ.tz(date * 1000, cleanupTimezone(timezone!)).format('YYYY-MM-DD HH:mm');
  const localZoneUnixTime = moment(dateTZFormatted).unix();
  return {
    tz_date: selZoneTime.format('MMM D, YYYY'),
    tz_time: selZoneTime.format('hh:mmA'),
    tz_unix: localZoneUnixTime,
    local_date: localZoneTime.format('MMM D, YYYY'),
    local_time: localZoneTime.format('hh:mmA'),
    local_unix: localZoneTime.unix(),
  };
}

/**
 * Returns date and time, converted to timezone + user's local time
 * @returns object
 */
export function convertTimezoneToDate(date: number, timezone: string) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dateFormatted = moment(date! * 1000).format('YYYY-MM-DD HH:mm:ss');
  const localZoneTime = momentTZ.tz(dateFormatted, moment.tz.guess());
  const selZoneTime = timezone ? localZoneTime.clone().tz(cleanupTimezone(timezone)) : null;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const dateTZFormatted = momentTZ.tz(date! * 1000, cleanupTimezone(timezone)).format('YYYY-MM-DD HH:mm:ss');
  const localZoneUnixTime = moment(dateTZFormatted).unix();

  return {
    tz_date: selZoneTime ? selZoneTime.format('MMM D, YYYY') : '',
    tz_time: selZoneTime ? selZoneTime.format('hh:mmA') : '',
    tz_unix: localZoneUnixTime,
    local_date: localZoneTime.format('MMM D, YYYY'),
    local_time: localZoneTime.format('hh:mmA'),
    local_unix: localZoneTime.unix(),
  };
}

/**
 * Returns the string representation of the difference between two epochs.
 * Can specify how many periods are shown via the `shownPeriodCount` arg.
 * @returns string
 */
const PERIODS = ['months', 'weeks', 'days', 'hours', 'minutes', 'seconds'] as const;
const PeriodValues = {
  months: 2592000, // 30 * days
  weeks: 604800,
  days: 86400,
  hours: 3600,
  minutes: 60,
  seconds: 1,
};
type Period = (typeof PERIODS)[number];

export function getDuration(
  start: number,
  end: number,
  maxPeriod: Period = 'months',
  minPeriod: Period = 'minutes',
  shownPeriodCount = 2,
): string {
  if (end === -1) return 'No End';
  const duration = end - start;
  if (duration < 0) return 'Invalid Duration';

  const maxIdx = PERIODS.indexOf(maxPeriod);
  const minIdx = PERIODS.indexOf(minPeriod);
  const shownPeriods: { value: number; period: Period; text: string }[] = [];

  for (let i = maxIdx; i <= minIdx; i++) {
    if (shownPeriods.length === shownPeriodCount) break;
    const remainingTime = shownPeriods.reduce((acc, curr) => acc - curr.value * PeriodValues[curr.period], duration);
    const value = Math.floor(remainingTime / PeriodValues[PERIODS[i]]);

    if (value > 0 || shownPeriods.length || (shownPeriods.length === 0 && i === minIdx))
      shownPeriods.push({
        value,
        period: PERIODS[i],
        text: `${value} ${value === 1 ? PERIODS[i].slice(0, -1) : PERIODS[i]}`,
      });
    else continue;
  }

  // trim trailing 0s
  for (let i = shownPeriods.length - 1; i > 0; i--) {
    if (shownPeriods[i].value) break;
    shownPeriods.pop();
  }

  return shownPeriods.map((p) => p.text).join(', ');
}

/**
 * Returns the object with warning variables on launching scheduled experience
 * @returns object
 */
export function getWarningOnLaunchScheduled(schedule?: Experience['schedule']) {
  const startDate = moment((schedule?.start || 0) * 1000)?.format('MMM DD, YYYY [at] h:mmA');
  const endDate = schedule?.end ? moment((schedule.end || 0) * 1000)?.format('MMM DD, YYYY [at] h:mmA') : 'No end date';
  let newTitle = '';
  let newMessage = '';
  if (schedule?.start) {
    newTitle = 'Launch on date';
    newMessage =
      'This experience has a schedule associated with it. As a result, it will launch with the following dates:';
  }
  return { startDate, endDate, newTitle, newMessage };
}

/**
 * Returns serialized rule schedule data
 * @returns object
 */
export function serializeRuleSchedule(delivery: Delivery) {
  return {
    repeat: {
      limit: parseInt(delivery.frequency?.total) - 1 || 0,
      frequency: parseInt(delivery.frequency?.value) || 0,
      frequencyType: delivery.frequency?.period?.key || 'once',
    },
    modifier: convertPeriodToSeconds(
      delivery.delay?.selected === 'SELECTED' && delivery.delay?.value ? delivery.delay.value : '',
      delivery.delay?.selected === 'SELECTED' && delivery.delay?.period?.key ? delivery.delay.period.key : '',
    ),
  };
}

/**
 * Returns serialized push delivery data, that match the backend requirements
 * @returns object
 */
export function serializeDelivery(delivery: Delivery) {
  const retVal = {
    max: parseInt(delivery.frequency?.total) || 0,
    cooldown: convertPeriodToSeconds(delivery.frequency?.value || '', delivery.frequency?.period?.key || ''),
    limit: parseInt(delivery.limit?.key) || 0,
    remain: delivery.remain === 'SELECTED',
    delay: convertPeriodToSeconds(
      delivery.delay?.selected === 'SELECTED' && delivery.delay?.value ? delivery.delay.value : '',
      delivery.delay?.selected === 'SELECTED' && delivery.delay?.period?.key ? delivery.delay.period.key : '',
    ),
  };

  return retVal;
}

/**
 * Returns number of seconds in period
 * @returns number
 */

export function convertPeriodToSeconds(value: string | number, period: string) {
  const periodValue = typeof value === 'string' ? parseInt(value) : value;
  let time = periodValue || 0;

  if (!period) return time;
  period = period === 'now' ? period : pluralize(period);

  const secondsInMinute = moment.duration(1, 'minutes').asSeconds();
  const secondsInHour = moment.duration(1, 'hours').asSeconds();
  const secondsInDay = moment.duration(1, 'days').asSeconds();
  const secondsInWeek = moment.duration(1, 'weeks').asSeconds();
  const secondsInMonth = moment.duration(1, 'months').asSeconds();
  const secondsInYear = moment.duration(1, 'years').asSeconds();

  if (period === 'now') {
    time = 0;
  }
  if (period === 'minutes') {
    time = periodValue * secondsInMinute;
  }
  if (period === 'hours') {
    time = periodValue * secondsInHour;
  }
  if (period === 'days') {
    time = periodValue * secondsInDay;
  }
  if (period === 'weeks') {
    time = periodValue * secondsInWeek;
  }
  if (period === 'months') {
    time = periodValue * secondsInMonth;
  }
  if (period === 'years') {
    time = periodValue * secondsInYear;
  }

  return time;
}

/**
 * Returns period from seconds
 * @returns object
 */

export function convertSecondsToPeriod(seconds: number) {
  const secondsInMinute = moment.duration(1, 'minutes').asSeconds();
  const secondsInHour = moment.duration(1, 'hours').asSeconds();
  const secondsInDay = moment.duration(1, 'days').asSeconds();
  const secondsInWeek = moment.duration(1, 'weeks').asSeconds();
  const secondsInMonth = moment.duration(1, 'months').asSeconds();
  const secondsInYear = moment.duration(1, 'years').asSeconds();

  let period = null;
  let value = 0;

  // In years
  if (!period && seconds >= secondsInYear) {
    value = parseFloat((seconds / secondsInYear).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'years';
    }
  }

  // In months
  if (!period && seconds >= secondsInMonth) {
    value = parseFloat((seconds / secondsInMonth).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'months';
    }
  }

  // In weeks
  if (!period && seconds >= secondsInWeek) {
    value = parseFloat((seconds / secondsInWeek).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'weeks';
    }
  }

  // In days
  if (!period && seconds >= secondsInDay) {
    value = parseFloat((seconds / secondsInDay).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'days';
    }
  }

  // In hours
  if (!period && seconds >= secondsInHour) {
    value = parseFloat((seconds / secondsInHour).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'hours';
    }
  }

  // In minutes
  if (!period && seconds >= secondsInMinute) {
    value = parseFloat((seconds / secondsInMinute).toFixed(2));

    if (value % 1 === 0 || (value - 0.1) % 1 === 0) {
      period = 'minutes';
    }
  }

  if (value % 1 !== 0 && (value - 0.1) % 1 === 0) {
    value = Math.round(value);
  }

  if (!period && seconds === 0) {
    period = 'now';
    value = seconds;
  }

  if (!period && seconds < secondsInMinute) {
    period = 'seconds';
    value = seconds;
  }

  if (!period) {
    period = 'seconds';
    value = seconds;
  }

  return { value, period };
}

/**
 * Returns user's timezone data
 * @returns object
 */
export function getMyTimezone() {
  let myZone = moment.tz.guess().replace(/_/g, ' ');
  const myOffset = moment.tz(myZone).format('Z');
  myZone = myZone + ' (UTC ' + myOffset + ')';
  return {
    key: -1,
    name: myZone,
    offset: myOffset,
  };
}

/**
 * Returns the number with commas as thousands separators
 * @returns string
 */
export function numberWithCommas(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Returns default language
 * @returns string
 */
export function getDefaultLanguage(languages: { id: string; isDefault: boolean; name: string; direction?: string }[]) {
  return (
    languages?.find((lang) => {
      return lang?.isDefault;
    })?.id ?? 'en'
  );
}
