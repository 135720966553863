import React from 'react';
import ImageLaunchZone from 'assets/images/image-launch-zone.svg';

import './OnboardingLoader.scss';

const MAIN_CLASS = 'setup-zones-launch';
const CLASSES = {
  SETUP_IMAGE: `${MAIN_CLASS}__setup-image`,
  SETUP_TEXT_CONTAINER: `${MAIN_CLASS}__setup-text-container`,
  SETUP_BUTTON: `${MAIN_CLASS}__setup-button`,
  BAR: `${MAIN_CLASS}__bar`,
  BAR_WRAPPER: `${MAIN_CLASS}__bar__wrapper`,
};

type OnboardingLoaderProps = {
  isLaunchingZone: boolean;
};

function OnboardingLoader(props: OnboardingLoaderProps) {
  const { isLaunchingZone } = props;
  return (
    <div className={`${MAIN_CLASS} ${isLaunchingZone ? 'show' : ''}`}>
      <div className={CLASSES.SETUP_IMAGE}>
        <img src={ImageLaunchZone} alt="getting started" />
      </div>
      <div className={CLASSES.SETUP_TEXT_CONTAINER}>
        <div className={`${CLASSES.SETUP_TEXT_CONTAINER}__title`}>Getting started</div>
        <div className={`${CLASSES.SETUP_TEXT_CONTAINER}__sub-title`}>One moment as we initialize your project...</div>
      </div>
      <div className={CLASSES.BAR_WRAPPER}>
        <div className={CLASSES.BAR}>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingLoader;
