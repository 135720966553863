import React, { Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react';
import './AnalyticsGraphs.scss';
import { VISUALIZER_OPTIONS } from 'pages/Analytics/CustomModuleAnalytics/constants';

// react-grid-layout
import { WidthProvider, Responsive, Layout, Layouts } from 'react-grid-layout';
const originalLayouts = getFromLS('layouts') || [];

// Local storage saving for now
function getFromLS(key: string) {
  let ls: Layouts = {};
  if (window.localStorage) {
    try {
      const data = window.localStorage.getItem('analytics-modules');
      ls = (data && JSON.parse(data)) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key: string, value: Layout[]) {
  if (window.localStorage) {
    window.localStorage.setItem(
      'analytics-modules',
      JSON.stringify({
        [key]: value,
      }),
    );
  }
}
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { useHistory } from 'react-router-dom';
import { AnalyticsGraph } from './AnalyticsGraph';
import { FlightButton } from '@flybits/design-system';
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import {
  EngagementModuleData,
  EngagementModuleOverTimeData,
  ModuleConfig,
} from 'interface/templated-experience/analytics.interface';
import { GraphData } from '../types';

type TProps = {
  customModuleGraphs: ModuleConfig[] | undefined;
  setCustomModuleGraphs: Dispatch<SetStateAction<ModuleConfig[] | undefined>>;
  handleRemoveCustomModule: (id: string) => void;
};
type TGraphProps = {
  title: string;
  id: string;
  data: EngagementModuleData[] | EngagementModuleOverTimeData[] | GraphData[];
  keys: string[];
  graph: string;
  indexBy?: string;
  layout?: 'horizontal' | 'vertical' | undefined;
  reverse?: boolean;
  xAxis?: string;
  yAxis?: string;
  timeInterval?: 'day' | 'millisecond' | 'second' | 'minute' | 'hour' | 'month' | 'year' | undefined;
};
export const AnalyticsGraphs = (props: TProps) => {
  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const { customModuleGraphs, handleRemoveCustomModule } = props;
  const templatedExperienceAnalyticsApi = useMemo(() => new TemplatedExperienceAnalyticsAPI(), []);
  const [editMode, setEditMode] = useState(false);
  const [modules, setModules] = useState<TGraphProps[] | null>();
  // TODO: Save/Get layout to/from backend
  const [customLayouts, setCustomLayouts] = useState<Layout[]>(originalLayouts);
  const [modulesWithErrors, setModulesWithErrors] = useState<string[]>([]);
  const DEFAULT_CLASS = 'analytics-overview__bottom-side__graphs';
  const history = useHistory();
  const pathName = history.location.pathname.replace(/\/$/, '');

  // TODO: Save/Get layout to/from backend
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onLayoutChange = (layout: Layout[], layouts: Layouts) => {
    // setCustomLayouts({ layouts });
  };
  // TODO: Save/Get layout to/from backend
  const onDragStop = (items: Layout[]) => {
    setCustomLayouts(items);
  };

  const handleSaveLayout = () => {
    saveToLS('layouts', customLayouts);
    setEditMode(false);
  };

  const getGraphData = async (id: string) => {
    try {
      const { data, moduleConfig } = await templatedExperienceAnalyticsApi.getModuleConfigData(id);
      const { chartConfig } = moduleConfig;
      return {
        data: data,
        id: id,
        keys: ['line'].includes(chartConfig.type) ? ['x', 'y'] : ['value'],
        title: chartConfig.title,
        graph: ['pie', 'doughnut'].includes(chartConfig.type) ? 'pie' : chartConfig.type,
        indexBy: 'name',
        reverse: false,
        layout: 'horizontal',
        xAxis: chartConfig.xAxis,
        yAxis: chartConfig.yAxis,
        timeInterval: moduleConfig.timeFrame.timeInterval,
      };
    } catch (error) {
      if (![...modulesWithErrors].includes(id)) {
        setModulesWithErrors((prev) => [...prev, id]);
      }
    }
  };

  // get all data for the graphs on load
  useEffect(() => {
    setModulesWithErrors([]);
    const promises = customModuleGraphs?.map(async (module) => {
      return getGraphData(module.id).catch((e) => e);
    });
    if (!promises?.length) return;
    Promise.all(promises).then((response) => {
      setModules(response);
    });

    return () => {
      // reset graph data
      setModulesWithErrors([]);
      setModules(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customModuleGraphs]);
  useEffect(() => {
    const layouts = getFromLS('layouts') || [];
    setCustomLayouts(layouts);
  }, []);
  function getAllGraph(customModuleGraphs: TGraphProps[]) {
    return customModuleGraphs
      .filter((item) => item?.graph !== VISUALIZER_OPTIONS.LINE)
      .map((item: TGraphProps, num: number) => {
        if (!item) return null;
        const availableHandles = ['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne'];
        const grid: Layout | undefined = customLayouts.find((layout) => layout.i === item.id);
        const defaultX = (num * 2) % 3;
        const defaultY = Math.ceil(Math.random() * 2) + 1;
        if (item.graph === 'bar') {
          return (
            <div
              key={item.id}
              data-grid={{
                x: grid?.x ?? defaultX,
                y: grid?.y ?? Math.floor(num / 3) * defaultY,
                w: grid?.w ?? 1,
                h: grid?.h ?? 2,
                minH: 1,
                resizeHandles: availableHandles,
              }}
            >
              <AnalyticsGraph
                title={item.title}
                id={item.id}
                graph={item.graph}
                data={item.data}
                keys={item.keys}
                indexBy={item.indexBy}
                reverse={item.reverse}
                layout={item.layout}
                xAxis={item.xAxis}
                yAxis={item.yAxis}
                key={item.id}
                onRemove={handleRemoveCustomModule}
              />
            </div>
          );
        } else {
          return (
            <div
              key={item.id}
              data-grid={{
                x: grid?.x ?? defaultX,
                y: grid?.y ?? Math.floor(num / 6) * defaultY,
                w: grid?.w ?? 1,
                h: grid?.h ?? 2,
                minH: 1,
                resizeHandles: availableHandles,
              }}
            >
              <AnalyticsGraph
                id={item.id}
                title={item.title}
                graph={item.graph}
                data={item.data}
                keys={item.keys}
                key={item.id}
                xAxis={item.xAxis}
                yAxis={item.yAxis}
                onRemove={handleRemoveCustomModule}
                timeInterval={item.timeInterval}
              />
            </div>
          );
        }
      });
  }

  function getErrorModules() {
    return modulesWithErrors.map((id) => {
      // get module data
      const module = customModuleGraphs?.find((mod) => mod.id === id);
      return (
        <div key={module?.id || id} data-grid={{ x: Infinity, y: Infinity, w: 1, h: 1 }}>
          <AnalyticsGraph
            id={id}
            title={module?.name || ''}
            graph={'bar'}
            data={[]}
            keys={['error']}
            key={id}
            hasError
            onRemove={handleRemoveCustomModule}
          />
        </div>
      );
    });
  }

  function addCustomGraph() {
    history.push(`${pathName}/module/create`);
  }
  return (
    <div className={`${DEFAULT_CLASS}`}>
      <section className={`${DEFAULT_CLASS}__module-layout`}>
        <div>Custom widgets</div>
        {editMode ? (
          <>
            <FlightButton
              theme="secondary"
              label={'Cancel'}
              onClick={() => setEditMode(false)}
              ariaLabel="Cancel layout edit"
              onKeyDown={() => setEditMode(false)}
            />
            <FlightButton
              theme="primary"
              label={'Save layout'}
              onClick={handleSaveLayout}
              onKeyDown={handleSaveLayout}
            />
          </>
        ) : (
          <FlightButton
            theme="primary"
            label={'Edit layout'}
            onClick={() => setEditMode(true)}
            onKeyDown={() => setEditMode(true)}
          />
        )}
      </section>
      <ResponsiveReactGridLayout
        className="layout"
        cols={{ lg: 3, md: 3, sm: 3, xs: 3, xxs: 2 }}
        rowHeight={180}
        containerPadding={[0, 0]}
        margin={[20, 20]}
        draggableCancel={'.flight-button'}
        isDraggable={editMode}
        isResizable={editMode}
        onDragStop={onDragStop}
        onResizeStop={onDragStop}
        onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
      >
        <div
          className={`${DEFAULT_CLASS}__add-module`}
          key={'static'}
          data-grid={{ x: 0, y: 0, w: 1, h: 2, static: true }}
        >
          <div className={`${DEFAULT_CLASS}__add-module__text`}>Add metrics and statistics to gain further insight</div>
          <FlightButton
            theme="minor"
            label={'+ Add widget'}
            className={`${DEFAULT_CLASS}__add-module__button`}
            onClick={() => addCustomGraph()}
            ariaLabel="Add custom widget"
            onKeyDown={() => addCustomGraph()}
          />
        </div>
        {modules && modules.length > 0 && getAllGraph(modules)}
        {modulesWithErrors.length > 0 && getErrorModules()}
      </ResponsiveReactGridLayout>
    </div>
  );
};
