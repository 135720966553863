import { SnackbarType, actionType } from './snackbar.type';
import { DISMISS_SNACKBAR, SHOW_SNACKBAR } from './snackbar.action';

const initialState: SnackbarType = {
  isVisible: false,
  isFloating: true,
  animation: true,
  icon: '',
  title: '',
  content: '',
  type: 'default',
  isAutoDismiss: true,
  dismissAfter: 5000,
  action: null,
  actionName: 'Dismiss',
  toggleVisible: null,
};

export default (state: SnackbarType = initialState, action: actionType) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        isVisible: true,
        ...action.payload,
      };
    case DISMISS_SNACKBAR:
      return {
        ...initialState,
        isVisible: false,
      };
    default:
      return {
        ...state,
      };
  }
};
