import './PushNode.scss';

import { JourneyComponentTypes } from 'pages/FlowBuilder/types/flow-builder.types';
import { PUSH_LABELS } from 'components/ExperienceCanvas/constants';
import { PushStateItem } from 'store/push/push.type';
import { useFlowBuilderContext } from 'pages/FlowBuilder/context/flow-builder.context';
import BaseNode, { BaseNodeProps } from 'components/FlowBuilder/FlowNodes/BaseNode/BaseNode';
import React, { useCallback, useMemo } from 'react';
import { PushPayloadType } from 'interface/push/push.interface';
import IconPush from 'pages/FlowBuilder/assets/icons/IconPush';
import { FlightTooltip } from '@flybits/design-system';
import OptionalNodeWrapper from '../OptionalNodeWrapper/OptionalNodeWrapper';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useJourneyStatus from 'hooks/useJourneyStatus';
import { isEmpty } from 'lodash';
import { getIsContentNeeded } from 'pages/FlowBuilder/helpers/flow-builder.helpers';
import { validateStepAction } from 'validators/ExperienceCanvas/journey.validator';
import { TOUCHPOINT_STATUSES } from 'components/ExperienceCanvas/types';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';

import { FlowNodeTypes } from '../node.types';

const MAIN_CLASS = 'push-node';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  LABEL_DISABLED: `${MAIN_CLASS}__label--disabled`,
  LABEL_TEXT: `${MAIN_CLASS}__label__text`,
  LABEL_NAME: `${MAIN_CLASS}__label__name`,
  NAME_TOOLTIP: `${MAIN_CLASS}__name-tooltip`,
};

type PushNodeProps = {
  data: {
    push: PushStateItem;
    pushType: PushPayloadType;
    stepIndex: number;
  };
};

function PushNode(props: PushNodeProps) {
  const {
    data: { push, pushType, stepIndex },
  } = props;

  const errorStatus = useSelector((state) => {
    const { errorStatus } = validateStepAction(state, stepIndex, FlowNodeTypes.PUSH);

    if (errorStatus === '') {
      return TOUCHPOINT_STATUSES.COMPLETE;
    } else if (errorStatus === ERROR_TYPES.INCOMPLETE) {
      return TOUCHPOINT_STATUSES.INCOMPLETE;
    } else {
      return TOUCHPOINT_STATUSES.ERROR;
    }
  });

  const dispatch = useDispatch();
  const { isJourneyLocked } = useJourneyStatus();
  const flowBuilderContext = useFlowBuilderContext();
  const dependencyGraph = useSelector((state) => state.te.journey.dependencyGraph);

  const handleEditPush = useCallback(() => {
    flowBuilderContext.sidePanels.push.setStepIndex(stepIndex);
    flowBuilderContext.sidePanels.push.setPushContext({ isOpen: true });
  }, [flowBuilderContext.sidePanels.push, stepIndex]);

  const handleRenamePush = useCallback(() => {
    flowBuilderContext.modals.rename.setData({
      entity: push,
      entityType: JourneyComponentTypes.PUSH,
      stepIndex,
      pushType,
    });
  }, [flowBuilderContext.modals.rename, push, pushType, stepIndex]);

  const pushName = push.name || 'Push Notification';
  const pushLabel = pushType ? PUSH_LABELS[pushType] : '';
  const isOptional = push.isOptional;
  const isOptionalPushEnabled = !!push.enableOptionalNode;

  const handleToggleOptionalPush = () => {
    dispatch({
      type: 'UPDATE_PUSH_SIMPLE',
      payload: {
        refId: push.refId,
        fields: { enableOptionalNode: !isOptionalPushEnabled },
      },
    });
  };

  const handleAddContent = useCallback(() => {
    flowBuilderContext.sidePanels.content.setStepIndex(stepIndex);
    flowBuilderContext.sidePanels.content.setContentContext((prev) => {
      return {
        ...prev,
        headerInfo: {
          ...prev.headerInfo,
          goBackTitle: 'Select/Create a content',
          goBackSubTitle: 'From Flow Builder',
        },
        isOpen: true,
      };
    });
  }, [flowBuilderContext.sidePanels.content, stepIndex]);

  const baseNodeProps = useMemo<Omit<BaseNodeProps, 'children'>>(() => {
    const isPendingActionsMenuOpen = getIsContentNeeded(push, dependencyGraph ?? {});
    const pendingActionsMenuItems = isPendingActionsMenuOpen
      ? [
          {
            icon: 'add',
            label: 'Add Content',
            onClick: handleAddContent,
          },
        ]
      : [];
    const menuItems = isJourneyLocked
      ? [
          {
            icon: 'eyeOpenOutline',
            label: 'View Push Notification',
            // Touchpoint editor currently locks push anyway
            onClick: handleEditPush,
          },
        ]
      : [
          {
            icon: 'editOutline',
            label: 'Edit Push Notification',
            onClick: handleEditPush,
          },
          {
            icon: 'textBox',
            label: 'Rename',
            onClick: handleRenamePush,
          },
        ];

    return {
      className: MAIN_CLASS,
      menuItems,
      Icon: IconPush,
      isPendingActionsMenuOpen,
      pendingActionsMenuItems,
    };
  }, [handleEditPush, handleRenamePush, isJourneyLocked, handleAddContent, push, dependencyGraph]);

  const renderPushNode = () => (
    <div className={`${CLASSES.LABEL} ${isOptional && !isOptionalPushEnabled ? CLASSES.LABEL_DISABLED : ''}`}>
      <FlightTooltip description={`Push Name: ${pushName}`} className={CLASSES.NAME_TOOLTIP}>
        <div className={CLASSES.LABEL_TEXT}>{pushName}</div>
      </FlightTooltip>
      {pushLabel && <div className={CLASSES.LABEL_NAME}>{`Type: ${pushLabel}`}</div>}
    </div>
  );

  const shouldShowOptionalNodeWrapper = () => {
    if (isJourneyLocked) {
      return false;
    }

    let showWrapper = false;

    if (isEmpty(dependencyGraph?.[push.refId]?.isRefBy) && isOptional) {
      showWrapper = true;
    }

    return showWrapper;
  };

  return shouldShowOptionalNodeWrapper() ? (
    <OptionalNodeWrapper isEnabled={isOptionalPushEnabled} onToggle={handleToggleOptionalPush}>
      <BaseNode {...baseNodeProps} isOptional isDisabled={!isOptionalPushEnabled} status={errorStatus}>
        {renderPushNode()}
      </BaseNode>
    </OptionalNodeWrapper>
  ) : (
    <BaseNode {...baseNodeProps} status={errorStatus}>
      {renderPushNode()}
    </BaseNode>
  );
}

export default PushNode;
