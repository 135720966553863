import React from 'react';
import { ReactComponent as CloudError } from 'assets/images/cloud-error.svg';

import './STErrorState.scss';
import { FlightButton } from '@flybits/design-system';

const STErrorState = (props: { errorMessage: string }) => {
  return (
    <div className="performance-error-state">
      <CloudError className="performance-error-state__image" />
      <div className="performance-error-state__title">Something went wrong</div>
      <div className="performance-error-state__description">
        {props.errorMessage || 'We’re sorry, there was a problem loading the data.'}
      </div>
      <FlightButton
        className="performance-error-state__reload"
        iconLeft="replay"
        label="Refresh"
        onClick={() => window.location.reload()}
        size="small"
      />
    </div>
  );
};

export default STErrorState;
