import React from 'react';
import { TIconProps } from '../types';

function ContentIcon({ ariaHidden = false }: TIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" id="content" aria-hidden={ariaHidden}>
      <g fill="#fff" transform="translate(5 -1031.863)">
        <circle cx="8" cy="1044.863" r="13" fill="#239fdb" fillRule="evenodd"></circle>
        <path
          fill="#0b5ecd"
          d="M5.799 1057.662a13 13 0 0 0 .904.137 13 13 0 0 0 1.297.064 13 13 0 0 0 .92-.033 13 13 0 0 0 1.289-.156 13 13 0 0 0 1.268-.286 13 13 0 0 0 1.234-.408 13 13 0 0 0 1.185-.531 13 13 0 0 0 1.127-.647 13 13 0 0 0 1.057-.755 13 13 0 0 0 .977-.858 13 13 0 0 0 .886-.951 13 13 0 0 0 .786-1.035 13 13 0 0 0 .68-1.108 13 13 0 0 0 .566-1.17 13 13 0 0 0 .445-1.22 13 13 0 0 0 .32-1.26 13 13 0 0 0 .196-1.285 13 13 0 0 0 .064-1.297 13 13 0 0 0-.033-.92 13 13 0 0 0-.154-1.268L15 1036.863H1v16l4.799 4.799z"
          color="#000"
          fontFamily="sans-serif"
          fontWeight="400"
        ></path>
        <path
          d="M1 0v16h14V0H1Zm1 1h12v14H2V1Zm1 1v4h4V2H3Zm5 0v1h5V2H8ZM4 3h2v2H4V3Zm4 1v1h5V4H8ZM3 7v1h10V7H3Zm0 2v1h5V9H3zm8 1c-1.099 0-2 .901-2 2s.901 2 2 2 2-.901 2-2-.901-2-2-2zm-8 1v1h5v-1H3zm8 0c.558 0 1 .442 1 1s-.442 1-1 1-1-.442-1-1 .442-1 1-1zm-8 2v1h5v-1H3z"
          color="#000"
          fontFamily="sans-serif"
          fontWeight="400"
          overflow="visible"
          transform="translate(0 1036.863)"
        ></path>
      </g>
    </svg>
  );
}

export default ContentIcon;
