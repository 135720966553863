import { ComponentEngagement } from 'interface/templated-experience/analytics.interface';
import { AnalyticsContentEngagmentState, AnalyticsContentEngagementActionType } from './content-engagement.type';

const initialState: AnalyticsContentEngagmentState = {
  records: { data: [] },
  isLoading: false,
  errorMessage: '',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: AnalyticsContentEngagmentState = initialState, action: any) => {
  switch (action.type) {
    case AnalyticsContentEngagementActionType.RESET_CONTENT_ENGAGEMENT_DATA:
      return {
        ...state,
        records: { data: [] },
      };
    case AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_DATA:
      let contentKey = 1;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action.payload.data.forEach((content: any) => {
        content.key = contentKey++;
        const componentsMap = new Map<string, ComponentEngagement>();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content.components.forEach((component: any) => {
          if (componentsMap.get(component.componentId) !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            componentsMap.get(component.componentId)!.elementName += ` / ${component.elementName}`;
          } else {
            componentsMap.set(component.componentId, component);
          }
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        content.components = Array.from(componentsMap, ([_, value]) => value);
      });
      return {
        ...state,
        records: { data: state.records.data.concat(action.payload.data || []) },
      };
    case AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
