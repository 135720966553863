import { Merchant, MerchantCategory, Offer } from 'pages/MerchantPortal/merchant-portal.types';
import BaseAPI from './base.api';
import { sleep } from 'helpers/common.helper';
import { AxiosError, AxiosHeaders } from 'axios';

class MerchantAPI extends BaseAPI {
  private ROUTES = {
    categories: `kernel/journey/merchants/categories`,
    merchants: `kernel/journey/merchants`,
    offers: `kernel/journey/merchants/:merchantId/offers`,
  };

  private queryParams = {
    limit: 0,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc',
  };

  getMerchants(params = {}): Promise<Merchant[]> {
    const queryParams = { ...this.queryParams, ...params };
    return this.GET(this.ROUTES.merchants, queryParams);
  }

  getOffers(merchantId: string): Promise<Offer[]> {
    return this.GET(this.buildURL(this.ROUTES.offers, { merchantId }), this.queryParams);
  }

  async getCategoriesMock(): Promise<MerchantCategory[]> {
    await sleep(1200);
    const { default: categories } = await import('../../pages/MerchantPortal/mocks/mock-categories');
    return categories;
  }

  async getMerchantsMock(): Promise<Merchant[]> {
    await sleep(1200);
    const { default: merchants } = await import('../../pages/MerchantPortal/mocks/mock-merchants-and-offers');
    return merchants as Merchant[];
  }

  async getMerchantMock(id: string): Promise<Merchant> {
    await sleep(1200);
    const { default: merchants } = await import('../../pages/MerchantPortal/mocks/mock-merchants-and-offers');
    const merchant = (merchants as Merchant[]).find((merchant) => id === merchant.id);
    if (!merchant) {
      const headers = new AxiosHeaders();
      const config = { url: 'https://floobotz.com', headers: headers };
      throw new AxiosError(
        'Error',
        'Error',
        config,
        { path: '/foo' },
        {
          status: 500,
          data: { error: 'error' },
          statusText: 'error',
          config,
          headers,
        },
      );
    }
    return merchant;
  }

  async getOffersMock(): Promise<Offer[]> {
    await sleep(1200);
    const { default: offers } = await import('../../pages/MerchantPortal/mocks/mock-offers');
    return offers as Offer[];
  }
}

const merchantService = new MerchantAPI();

export default merchantService;
