import { JOURNEY_STATUSES } from './journey.type';

export const journeyStatusNames: { [key: string]: string } = {
  [JOURNEY_STATUSES.ACTIVE]: 'Active',
  [JOURNEY_STATUSES.DRAFT]: 'Draft',
  [JOURNEY_STATUSES.ERROR]: 'Error',
  [JOURNEY_STATUSES.INACTIVE]: 'Inactive',
  [JOURNEY_STATUSES.INITIAL]: 'Initial',
  [JOURNEY_STATUSES.SCHEDULED]: 'Scheduled',
};

export const journeyStatusTagTypes: { [key: string]: string } = {
  [JOURNEY_STATUSES.ACTIVE]: 'success',
  [JOURNEY_STATUSES.DRAFT]: 'default',
  [JOURNEY_STATUSES.ERROR]: 'error',
  [JOURNEY_STATUSES.INACTIVE]: 'error',
  [JOURNEY_STATUSES.INITIAL]: 'info',
  [JOURNEY_STATUSES.SCHEDULED]: 'info',
};
