/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './ContentEditorBox.scss';
import { Content } from 'interface/content/content.interface';
import { ContentListItem, CONTENT_MANAGER_STEPS } from 'components/ExperienceCanvas/types';
import { FlightButton, FlightSelect } from '@flybits/design-system';
import { getContentErrorList } from 'validators/ExperienceCanvas/content.validator';
import { getContentListItems } from 'store/content/content.selector';
import { SelectOption } from 'types/common';
import { TExperienceCanvasRouteParams, TTimelineBoxProps } from 'pages/ExperienceCanvas/types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { ZoneConfig } from 'pages/Zones/types';
import ContentAPI from 'services/api/content.api';
import ContentList from './ContentList/ContentList';
import ContentManager from 'components/ExperienceCanvas/ContentManager/ContentManager';
import InAppContentIcon from 'pages/ExperienceCanvas/assets/icons/InAppContentIcon';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import TouchpointEditorCard from 'components/Shared/TouchpointEditorCard/TouchpointEditorCard';
import useContentInstances from 'hooks/useContentInstances';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useJourneyStatus from 'hooks/useJourneyStatus';
import ZoneAPI from 'services/api/zone.api';

const CLASS_PREFIX = 'content-editor-box';
const CLASSES = {
  MAIN: CLASS_PREFIX,
  INSTRUCTIONS: `${CLASS_PREFIX}__instructions`,
  TIP: `${CLASS_PREFIX}__tip`,
  BUTTON_LABEL: `${CLASS_PREFIX}__button-label`, // temporary before adding the icon to DS
  SELECT_PLACEHOLDER: `${CLASS_PREFIX}__select-placeholder`,
  SELECT_REQUIRED_SYMBOL: `${CLASS_PREFIX}__required-symbol`,
};

export default function ContentEditorBox({ stepIdx, timelineIndex, onBlurTimelineBox }: TTimelineBoxProps) {
  const contentAPI = useMemo(() => new ContentAPI(), []);
  const zoneAPI = useMemo(() => new ZoneAPI(), []);

  const [isContentManagerVisible, setIsContentManagerVisible] = useState(false);
  const [specificContentRefId, setSpecificContentRefId] = useState('');
  const [contentManagerInitialStep, setContentManagerInitialStep] = useState<CONTENT_MANAGER_STEPS | undefined>();
  const dispatch = useDispatch();
  const contentsFromState = useSelector((state) => state.te.content.byRefId);
  const contentListItems: ContentListItem[] = useSelector((state) => getContentListItems(state, stepIdx));
  const stepPushRefId = useSelector((state) => state.te.journey.steps[stepIdx]?.push);
  const { isJourneyLocked } = useJourneyStatus();
  const { flags } = useFeatureFlag();
  const history = useHistory();
  const { pid: projectId } = useRouteMatch<TExperienceCanvasRouteParams>().params;

  const hasError = useSelector((state) => {
    const step = state.te.journey.steps[stepIdx];
    const errors = [];
    if (step?.content && step.content.length) {
      step.content.forEach((refId) => getContentErrorList(state, refId).forEach((err) => errors.push(err)));
    }
    return !!errors.length && step.isDirty;
  });
  const handleAddContent = () => {
    setIsContentManagerVisible(true);
  };

  const handleMakePrimary = (oldPrimaryRefId: string, newPrimaryRefId: string) => {
    const oldPrimaryContent = contentsFromState[oldPrimaryRefId]?.payload;
    const newPrimaryContent = contentsFromState[newPrimaryRefId]?.payload;
    dispatch({
      type: 'REMOVE_CONTENT',
      payload: {
        refIds: [oldPrimaryRefId, newPrimaryRefId],
        stepIdx,
        required: [true, true],
      },
    });
    dispatch({
      type: 'UPDATE_CONTENT',
      payload: {
        [oldPrimaryRefId]: { ...newPrimaryContent, refId: oldPrimaryRefId },
        [newPrimaryRefId]: { ...oldPrimaryContent, refId: newPrimaryRefId },
      },
    });

    if (onBlurTimelineBox) onBlurTimelineBox('content');
  };

  const handleDelete = (content: ContentListItem) => {
    dispatch({
      type: 'REMOVE_CONTENT',
      payload: {
        refIds: [content.refId],
        stepIdx,
        required: [content.isRequired],
      },
    });
    if (onBlurTimelineBox) onBlurTimelineBox('content');
  };

  const handleDisconnect = useCallback(
    (depRefId: string) => {
      if (!stepPushRefId) return;
      // will only matter if push is connected.
      if (contentListItems.length < 2) {
        dispatch({
          type: 'REMOVE_DEPENDENCY',
          payload: {
            entityRefId: stepPushRefId,
            dependencyRefId: depRefId,
          },
        });
      } else {
        dispatch({
          type: 'CHANGE_PRIMARY_CONTENT',
          payload: {
            oldPrimaryRefId: depRefId,
            newPrimaryRefId: contentListItems.find((c) => c.refId !== depRefId)?.refId,
            affectedEntities: [{ refId: stepPushRefId, var: '{{dep.content-id.0}}' }],
            stepIdx,
          },
        });
      }
    },
    [stepPushRefId, contentListItems, dispatch, stepIdx],
  );

  // temporary before adding the icon to DS
  const ButtonLabel = ({ children }: { children: string }) => (
    <div className={CLASSES.BUTTON_LABEL}>
      <InAppContentIcon />
      {children}
    </div>
  );

  const handleEdit = async (content: ContentListItem) => {
    setContentManagerInitialStep(CONTENT_MANAGER_STEPS.CONTENT_EDITOR);
    setSpecificContentRefId(content.refId);
    setIsContentManagerVisible(true);

    if (onBlurTimelineBox) onBlurTimelineBox('content');
  };

  const handleOpenTargetedSelection = (content: ContentListItem) => {
    setSpecificContentRefId(content.refId);
    setIsContentManagerVisible(true);
  };

  const [showAddMoreBtn, setShowAddMoreBtn] = useState(false);

  const [moduleDropdownOptions, setModuleDropdownOptions] = useState<SelectOption[]>([]);
  const [moduleLabelDropdownOptions, setModuleLabelDropdownOptions] = useState<SelectOption[]>([]);
  const [zoneDropdownOptions, setZoneDropdownOptions] = useState<SelectOption[]>([]);

  // -- Zones & Modules section

  const [isZMButtonDisabled, setIsZMButtonDisabled] = useState(false);
  const [selectedModuleLabel, setSelectedModuleLabel] = useState<SelectOption | undefined>();
  const [selectedModule, setSelectedModule] = useState<SelectOption | undefined>();
  const [selectedZone, setSelectedZone] = useState<SelectOption | undefined>();
  const [zmErrorMessage, setZMErrorMessage] = useState<string>('');
  const [zoneConfig, setZoneConfig] = useState<ZoneConfig>();
  const { contentInstances } = useContentInstances();

  const handleZoneSelection = (option: SelectOption) => {
    setSelectedZone(option);
    setSelectedModule(undefined);
    setSelectedModuleLabel(undefined);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedZoneModules = zoneConfig?.zones.filter((zone: any) => {
      return zone.id === option.key;
    });

    if (selectedZoneModules?.length) {
      const options: SelectOption[] = [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedZoneModules[0].modules.forEach((module: any) => {
        options.push({
          key: module.id,
          name: module.name,
        });
      });

      setModuleDropdownOptions(options);
    }
  };

  const handleModuleSelection = (option: SelectOption) => {
    setSelectedModule(option);
    setSelectedModuleLabel(undefined);

    const zones = zoneConfig?.zones ?? [];
    let module = null;

    for (let i = 0; i < zones.length; i++) {
      const modules = zones[i]?.modules ?? [];

      if (modules) {
        for (let j = 0; j < modules.length; j++) {
          if (modules[j].id === option.key) {
            module = modules[j];
            break;
          }
        }
      }
    }

    const options: SelectOption[] = [];

    module?.labelsOrder.forEach((label: string, index: number) => {
      options.push({
        key: index.toString(),
        name: label,
      });
    });

    setModuleLabelDropdownOptions(options);
  };

  const handleModuleLabelSelection = (option: SelectOption) => {
    setSelectedModuleLabel(option);
  };

  const applyZMLabel = async () => {
    const label = selectedModuleLabel?.name;
    const updatedContentPromises: Promise<Content>[] = [];
    const contentList = contentListItems.filter((c) => c.id != '');

    if (!label) {
      setZMErrorMessage('Select a label to apply');
      return;
    }

    if (contentInstances.length <= 0) {
      setZMErrorMessage('Failed to retrieve all content');
      return;
    } else if (contentList.length <= 0) {
      setZMErrorMessage('Add content before applying the label');
      return;
    } else {
      setIsZMButtonDisabled(true);
      setZMErrorMessage('');

      contentList.forEach((content) => {
        const filteredContents = contentInstances.filter((c: { id: string }) => c.id === content.id);

        if (filteredContents.length > 0 && !filteredContents[0].labels.includes(label)) {
          filteredContents[0].labels.push(label);
          updatedContentPromises.push(contentAPI.updateInstance(filteredContents[0], content.id));
        }
      });

      if (updatedContentPromises.length <= 0) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            title: 'No changes made',
            content: `The label ${label} is already exists for all selected content.`,
            type: 'info',
          },
        });
        setIsZMButtonDisabled(false);
        return;
      }
      try {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            title: 'Success!',
            content: `The label ${label} was added to all selected content.`,
            type: 'success',
          },
        });
        await Promise.all(updatedContentPromises);
        setIsZMButtonDisabled(false);
      } catch (error) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            title: 'Error',
            content: `The label ${label} could not be added to all selected content. ${error}`,
            type: 'error',
          },
        });
        setIsZMButtonDisabled(false);
      }
    }
  };

  useEffect(() => {
    const options: SelectOption[] = [];

    const getZoneConfig = async () => {
      try {
        const config = await zoneAPI.getZoneConfig();
        setZoneConfig(config);
        config.zones?.forEach((zone) => {
          if (zone.id) {
            options.push({
              key: zone.id,
              name: zone.name,
            });
          }
        });

        setZoneDropdownOptions(options);
      } catch (exception) {
        //TODO: Handle exception
      }
    };

    getZoneConfig();
  }, [zoneAPI]);

  // -- End of Zones & Modules section

  useEffect(() => {
    if (contentListItems?.length > 0) {
      for (const content of contentListItems) {
        if (!content.id) {
          setShowAddMoreBtn(false);
          return;
        }
      }

      setShowAddMoreBtn(true);
    } else setShowAddMoreBtn(false);
  }, [contentListItems]);

  const redirectToZM = () => {
    history.push(`/project/${projectId}/zones`);
  };

  return (
    <>
      <TouchpointEditorCard
        title="Content"
        icon={InAppContentIcon}
        timelineIndex={timelineIndex}
        className={CLASSES.MAIN}
        isLocked={isJourneyLocked}
        hasError={hasError}
      >
        <>
          {contentListItems.length < 1 && (
            <FlightButton
              theme="secondary"
              label={<ButtonLabel>Add Content +</ButtonLabel>}
              size="large"
              onClick={handleAddContent}
            />
          )}
          <p className={CLASSES.INSTRUCTIONS}>Content added here will appear in the user&apos;s feed.</p>
          <ContentList
            contents={contentListItems}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            onMakePrimaryClick={handleMakePrimary}
            onSelectContentClick={handleOpenTargetedSelection}
            onDisconnectClick={handleDisconnect}
            currentStepIdx={stepIdx}
          />
          {showAddMoreBtn && (
            <FlightButton
              theme="secondary"
              label={<ButtonLabel>Add More +</ButtonLabel>}
              size="large"
              onClick={handleAddContent}
            />
          )}
          <p className={CLASSES.TIP}>
            <strong>Recommended:</strong> 6 max.
          </p>

          {flags['tx_zone_module_label_to_content'] && (
            <div className="zone-module-selection">
              <div className={contentListItems.length < 1 ? 'is-no-content' : ''}>
                <h3>Zones & Modules</h3>
                <p>
                  This is an <strong>optional</strong> configuration to place above content(s) to a specific zone.
                </p>

                {zoneDropdownOptions.length < 1 && (
                  <div className="no-zone-message flight-snackbar flight-snackbar--info">
                    <div className="flight-snackbar__content">
                      This project does not have any zones. Navigate to &nbsp;
                      <a href="#" onClick={redirectToZM}>
                        Zones & Modules
                      </a>
                      &nbsp; page to define a zone.
                    </div>
                  </div>
                )}

                <div className={zoneDropdownOptions.length < 1 ? 'is-no-zone' : ''}>
                  <label className="flight-select-label">Select a zone</label>
                  <FlightSelect
                    options={zoneDropdownOptions}
                    selected={
                      selectedZone || {
                        key: '',
                        name: <span className={CLASSES.SELECT_PLACEHOLDER}>No zone selected</span>,
                      }
                    }
                    disabled={isZMButtonDisabled || zoneDropdownOptions.length < 1}
                    handleOptionClick={handleZoneSelection}
                    width="100%"
                  />

                  <div className="module-dropdown">
                    <label className="flight-select-label">Select a module</label>
                    <FlightSelect
                      options={moduleDropdownOptions}
                      selected={
                        selectedModule || {
                          key: '',
                          name: <span className={CLASSES.SELECT_PLACEHOLDER}>No module selected</span>,
                        }
                      }
                      disabled={isZMButtonDisabled || !selectedZone}
                      handleOptionClick={handleModuleSelection}
                      width="100%"
                    />
                  </div>

                  <div className="module-label-dropdown">
                    <label className="flight-select-label">Select a label</label>
                    <FlightSelect
                      options={moduleLabelDropdownOptions}
                      selected={
                        selectedModuleLabel || {
                          key: '',
                          name: <span className={CLASSES.SELECT_PLACEHOLDER}>No label selected</span>,
                        }
                      }
                      disabled={isZMButtonDisabled || !selectedModule}
                      handleOptionClick={handleModuleLabelSelection}
                      width="100%"
                    />{' '}
                  </div>
                </div>
              </div>
              <div className="button-container">
                <FlightButton
                  className={'apply-zone-module-label-button'}
                  theme="primary"
                  label={'Apply Label'}
                  loading={isZMButtonDisabled}
                  disabled={!selectedModuleLabel || isZMButtonDisabled || contentListItems.length < 1}
                  onClick={applyZMLabel}
                  onKeyDown={applyZMLabel}
                />
                {zmErrorMessage && <span className="error-message">{zmErrorMessage}</span>}
              </div>
            </div>
          )}
        </>
      </TouchpointEditorCard>
      <ContentManager
        isOpen={isContentManagerVisible}
        stepIdx={stepIdx}
        contentRefId={specificContentRefId}
        startingStep={contentManagerInitialStep}
        onClickClose={() => {
          setIsContentManagerVisible((prevState) => !prevState);
          setSpecificContentRefId('');
          setContentManagerInitialStep(undefined);
        }}
        onChangeContent={() => {
          if (onBlurTimelineBox) onBlurTimelineBox('content');
        }}
      />
    </>
  );
}
