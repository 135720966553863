import React from 'react';
import { TIconProps } from '../types';

function ChevronLeftIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill={fill}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_359_24413)">
        <path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_359_24413">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChevronLeftIcon;
