import './ZoneHeader.scss';
import React, { useEffect, useMemo, useRef, useState, useContext, useCallback } from 'react';
import { FlightButton, FlightDropdown, getIcon } from '@flybits/design-system';
import { EVENT_KEYS } from 'types/events';
import { ZoneSelectorInfo } from 'pages/Zones/types';

import MemoizedSlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import ZoneAttributes from '../ZoneAttributes/ZoneAttributes';

import { Zone } from 'pages/Zones/types';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import { useHistory, useParams } from 'react-router-dom';
import { validateZoneAttributes } from '../zone.validators';

const CLASS_MAIN = 'zone-header';
const CLASS_HEADER_CONTAINER = `${CLASS_MAIN}__container`;
const CLASS_HEADER_LEFT = `${CLASS_HEADER_CONTAINER}--left`;
const CLASS_HEADER_RIGHT = `${CLASS_HEADER_CONTAINER}--right`;
const CLASS_BACK_BUTTON = `${CLASS_MAIN}__back-button`;
const CLASS_ZONE_SELECTOR_DROPDOWN = `${CLASS_MAIN}__zone-selector-dropdown`;
const CLASS_ZONE_SELECTOR_BUTTON = `${CLASS_MAIN}__zone-selector-button`;
const CLASS_ZONE_SELECTOR_BUTTON_TEXT = `${CLASS_ZONE_SELECTOR_BUTTON}__text`;
const CLASS_ZONE_SELECTOR_BUTTON_ICON = `${CLASS_ZONE_SELECTOR_BUTTON}__icon`;
const CLASS_ZONE_SELECTOR_OPTION = `${CLASS_MAIN}__zone-selector-option`;
const CLASS_ZONE_SELECTOR_OPTION_TEXT = `${CLASS_ZONE_SELECTOR_OPTION}__text`;
const CLASS_ZONE_SELECTOR_OPTION_TEXT_NAME = `${CLASS_ZONE_SELECTOR_OPTION_TEXT}__name`;
const CLASS_ZONE_SELECTOR_OPTION_TEXT_REFID = `${CLASS_ZONE_SELECTOR_OPTION_TEXT}__ref-id`;
const CLASS_ZONE_SELECTOR_OPTION_ICON = `${CLASS_ZONE_SELECTOR_OPTION}__icon`;
const CLASS_ZONE_EDIT_BUTTON = `${CLASS_MAIN}__zone-edit-button`;
const CLASS_ZONE_ADD_BUTTON = `${CLASS_MAIN}__zone-add-button`;
const CLASS_MAIN_SLIDINGSLIDEOUT_BODY = `${CLASS_MAIN}__slidingSidePanel`;

type ZoneHeaderProps = {
  zones: Zone[];
  selectedZone: Zone;
  onZoneSelected: (zoneInfo: ZoneSelectorInfo) => void;
};

function ZoneHeader({ zones, selectedZone, onZoneSelected }: ZoneHeaderProps) {
  const { pid } = useParams<{ pid: string }>();
  const history = useHistory();

  const [showSlideOut, setShowSlideOut] = useState(false);
  const [editZoneSlideOut, setEditZoneSlideOut] = useState(false);
  const [slideoutApplyDisabled, setSlideoutApplyDisabled] = useState(true);

  const getZoneSelectorInfo = useCallback(() => {
    return zones.map((zone) => {
      return { ...zone, isSelected: zone.id === selectedZone.id ?? '' ? true : false };
    });
  }, [selectedZone, zones]);

  const getSelectedZoneInfo = useCallback(() => {
    return getZoneSelectorInfo().find((zone) => zone.isSelected);
  }, [getZoneSelectorInfo]);

  const prioritizationContext = useContext(PrioritizationContext);

  const [selectedZoneInfo, setSelectedZoneInfo] = useState<ZoneSelectorInfo>(
    getZoneSelectorInfo().find((zone) => zone.isSelected) || {
      id: '',
      name: '',
      referenceID: '',
      labels: [],
      isSelected: false,
    },
  );

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: showSlideOut,
      headerInfo: {
        mainTitle: editZoneSlideOut ? `${selectedZoneInfo.name}` : 'Creating New Zone',
        showCloseButton: true,
      },
      footerInfo: {
        primaryActionText: 'Apply',
        primaryActionHandler: () => {
          if (editZoneSlideOut) {
            prioritizationContext.updateZone(prioritizationContext.selectedZone as Zone);
            setEditZoneSlideOut((editZone) => !editZone);
          } else {
            const addedZone = prioritizationContext.addZone(prioritizationContext.selectedZone as Zone);
            onZoneSelected({ ...(prioritizationContext.selectedZone as Zone), isSelected: true, id: addedZone.id });
          }

          setShowSlideOut(false);
        },
        primaryActionDisabled: slideoutApplyDisabled,
        secondaryActionText: 'Cancel',
        secondaryActionHandler: () => {
          setShowSlideOut(false);
        },
      },
    }),
    [showSlideOut, editZoneSlideOut, selectedZoneInfo, prioritizationContext, onZoneSelected, slideoutApplyDisabled],
  );

  const triggerRef = useRef<HTMLButtonElement>(null);
  const openDropdown = () => {
    setDropdownOpen(true);
  };
  const closeDropdown = (focusAfterClose = false) => {
    setDropdownOpen(false);
    if (focusAfterClose) {
      triggerRef.current?.focus();
    }
  };

  const handleRemoveZone = useCallback(async () => {
    if (!editZoneSlideOut) {
      return;
    }

    await prioritizationContext.removeZone(prioritizationContext.selectedZone as Zone);
    const zoneInfos = getZoneSelectorInfo();
    if (zoneInfos.length > 0) {
      setSelectedZoneInfo(zoneInfos[0]);
      onZoneSelected(zoneInfos[0]);
      setEditZoneSlideOut(false);
      setShowSlideOut(false);
      onZoneSelected(zoneInfos[0]);
    } else {
      history.goBack(); // No more zones go back.
    }
  }, [editZoneSlideOut, getZoneSelectorInfo, onZoneSelected, prioritizationContext, history]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === EVENT_KEYS.ESCAPE) {
        closeDropdown(true);
      }
    };

    document.addEventListener('keyup', handleKeyDown);

    return () => document.removeEventListener('keyup', handleKeyDown);
  }, []);

  // Check if the primary action button in slideout footer should be disabled
  useEffect(() => {
    const checkDisabled = async () => {
      const { name, refId, labels } = validateZoneAttributes(
        prioritizationContext.selectedZone?.name,
        prioritizationContext.selectedZone?.referenceID ?? '',
        prioritizationContext.selectedZone?.labels,
        prioritizationContext.selectedZone?.id,
        prioritizationContext.zones,
      );

      setSlideoutApplyDisabled(!!(name || refId || labels));
    };

    setSlideoutApplyDisabled(true);
    checkDisabled();
  }, [prioritizationContext.selectedZone, prioritizationContext.zones]);

  return (
    <div className={CLASS_MAIN}>
      <div className={`${CLASS_HEADER_CONTAINER} ${CLASS_HEADER_LEFT}`}>
        <FlightButton
          theme="minor"
          ariaLabel="Back to zones"
          className={CLASS_BACK_BUTTON}
          iconLeft="baselineKeyboardArrowLeft"
          onClick={() => history.push(`/project/${pid}/zones/v2`)}
        />
        <FlightDropdown
          className={CLASS_ZONE_SELECTOR_DROPDOWN}
          direction="bottom"
          maxWidth="462px"
          ariaControlsId="zone-selector"
          isActive={isDropdownOpen}
          handleClickOutside={closeDropdown}
          trigger={
            <button
              className={CLASS_ZONE_SELECTOR_BUTTON}
              value={'Home Page'}
              ref={triggerRef}
              onClick={openDropdown}
              onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                if (e.key === EVENT_KEYS.ENTER) {
                  openDropdown();
                }
              }}
            >
              <div className={CLASS_ZONE_SELECTOR_BUTTON_TEXT}>{getSelectedZoneInfo()?.name}</div>
              <div className={CLASS_ZONE_SELECTOR_BUTTON_ICON}>
                {getIcon(`${isDropdownOpen ? 'upCarrot' : 'downCarrot'}`, {})}
              </div>
            </button>
          }
        >
          <>
            {getZoneSelectorInfo().map((zoneInfo) => (
              <button
                key={zoneInfo.id}
                className={CLASS_ZONE_SELECTOR_OPTION}
                onClick={() => {
                  setDropdownOpen(false);
                  setSelectedZoneInfo(zoneInfo);
                  onZoneSelected(zoneInfo);
                }}
              >
                <div className={CLASS_ZONE_SELECTOR_OPTION_TEXT}>
                  <div className={CLASS_ZONE_SELECTOR_OPTION_TEXT_NAME}>{zoneInfo.name}</div>
                  <div className={CLASS_ZONE_SELECTOR_OPTION_TEXT_REFID}>Ref Id: {zoneInfo.referenceID}</div>
                </div>
                {zoneInfo.isSelected && (
                  <div className={CLASS_ZONE_SELECTOR_OPTION_ICON}>{getIcon('baselineCheck', { fill: '#0851DF' })}</div>
                )}
              </button>
            ))}
          </>
        </FlightDropdown>
        <FlightButton
          className={CLASS_ZONE_EDIT_BUTTON}
          label="Edit zone details"
          theme="secondary"
          iconLeft="editFullOutline"
          onClick={() => {
            prioritizationContext.setSelectedZone(getSelectedZoneInfo() as Zone);
            setEditZoneSlideOut(true);
            setShowSlideOut(true);
          }}
        />
      </div>
      <div className={`${CLASS_HEADER_CONTAINER} ${CLASS_HEADER_RIGHT}`}>
        <FlightButton
          className={CLASS_ZONE_ADD_BUTTON}
          label="Add new zone"
          iconLeft="add"
          onClick={() => {
            prioritizationContext.resetSelectedZone();
            setEditZoneSlideOut(false);
            setShowSlideOut(true);
          }}
        />
      </div>
      <MemoizedSlidingSidePanel {...slidingSidePanelProps}>
        <div className={CLASS_MAIN_SLIDINGSLIDEOUT_BODY}>
          <ZoneAttributes editMode={editZoneSlideOut} onRemove={handleRemoveZone} />
        </div>
      </MemoizedSlidingSidePanel>
    </div>
  );
}

export default ZoneHeader;
