import React, { useState } from 'react';
import { FlightRadioButton, FlightDateRangePicker } from '@flybits/design-system';
import { useCustomModuleAnalyticsContext } from '../../context/CustomModuleAnalyticsContext';
import { VISUALIZER_OPTIONS, TIMEFRAME_INTERVAL_OPTIONS, timeFrameIntervalOption } from '../../constants';
import './CustomModuleTimeFrame.scss';

const DEFAULT_CLASS = 'custom-module-analytics__main-content';
const TIMEFRAME_CONTAINER = `${DEFAULT_CLASS}__timeframe`;

type DateRange<T> = [T | null, T | null];
type RelativeDateRange = [number, string];
type AdvancedDateRange = DateRange<Date> | [...DateRange<Date>, ...RelativeDateRange];

const transformPayloadToRange = (absolute: Date[], relative?: [number, string]): AdvancedDateRange =>
  relative ? [null, null, ...relative] : [absolute[0] ?? null, absolute[1] ?? null];

// will fail if the range is over 100 years
const getValidDateRange = (start: number, end: number) => {
  const diff = end - start;

  // skip hourly
  for (let i = 1; i < timeFrameIntervalOption.length; i++) {
    if (diff / timeFrameIntervalOption[i].duration < 100) return timeFrameIntervalOption[i].value;
  }

  return TIMEFRAME_INTERVAL_OPTIONS.DAILY;
};

export const CustomModuleTimeFrame = () => {
  const { customModuleState, setCustomModuleState } = useCustomModuleAnalyticsContext();
  const {
    visualization: { selectedGraph },
    timeFrame,
  } = customModuleState;
  const { customDateRange, relativeDateRange, interval } = timeFrame;
  const [dateRange, setDateRange] = useState(transformPayloadToRange(customDateRange, relativeDateRange));
  const [timeInterval, setTimeInterval] = useState<TIMEFRAME_INTERVAL_OPTIONS>(
    interval || TIMEFRAME_INTERVAL_OPTIONS.DAILY,
  );

  const handleRangeChange = (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
    // the only reason i can't simplify the logic here is because typescript is too dumb.
    const isRelative = newDate[2] && newDate[3];
    const hasFullRange = newDate[0] !== null && newDate[1] !== null;
    const isAllTime = newDate[0] === null && newDate[1] === null;
    let interval = timeFrame.interval;
    if (!isRelative && (hasFullRange || isAllTime))
      interval = isAllTime
        ? getValidDateRange(0, Date.now() / 1000)
        : getValidDateRange((newDate[0] as Date).getTime() / 1000, (newDate[1] as Date).getTime() / 1000);

    setCustomModuleState({
      ...customModuleState,
      timeFrame: {
        ...timeFrame,
        interval,
        customDateRange:
          isRelative || isAllTime ? [] : hasFullRange ? [newDate[0] as Date, newDate[1] as Date] : customDateRange,
        relativeDateRange: isRelative ? [newDate[2] as number, newDate[3] as string] : undefined,
      },
    });
  };

  const handleIntervalChange = (value: TIMEFRAME_INTERVAL_OPTIONS) => {
    setTimeInterval(value);
    setCustomModuleState({
      ...customModuleState,
      timeFrame: {
        ...timeFrame,
        interval: value,
      },
    });
  };

  /* TODO: Add rules for all intervals types for LINE
   * like disable interval for all graphs except line graph;
   * enable interval if: (end-start)/interval < 100)
   */
  const isIntervalSectionEnabled = false; // disable while rules are not created
  const isShowIntervalSection = selectedGraph === VISUALIZER_OPTIONS.LINE;

  return (
    <div className={TIMEFRAME_CONTAINER}>
      {/* Date range section */}
      <p>Select range</p>
      <div className={`${TIMEFRAME_CONTAINER}__interval_container`}>
        <FlightDateRangePicker isIncludeCustom value={dateRange} maxDate={new Date()} onChange={handleRangeChange} />
      </div>

      {/* Interval Section */}
      {isShowIntervalSection && isIntervalSectionEnabled && (
        <>
          <p>Select an interval (x-axis)</p>
          <div className={`${TIMEFRAME_CONTAINER}__interval_container`}>
            {timeFrameIntervalOption.map((interval, idx) => {
              const { label, value } = interval;
              return (
                <FlightRadioButton
                  key={`ca_timeframe_${idx}`}
                  className={`${TIMEFRAME_CONTAINER}__interval_container_option`}
                  label={label}
                  checked={timeInterval === value}
                  value={value}
                  onSelect={handleIntervalChange}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
