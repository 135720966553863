import { Content, ContentPrototypePayload, ContentPrototype } from 'interface/content/content.interface';
import { Dependencies } from 'interface/common.interface';
import { Localizations } from 'interface/shared';

// currently this uses preexisting interfaces in the interest of time
// in the future, this should be its own type
export enum LoadingStatus {
  NA = 'na',
  INITIAL = 'in',
  PENDING = 'pd',
  COMPLETE = 'cp',
  FAILURE = 'fa',
}

export interface ContentState {
  creationStatus: LoadingStatus;
  byRefId: {
    [refId: string]: ContentStateItem;
  };
  allRefId: string[];
  // contentInstances: Content[];
  // contentInstanceById: {
  //   [id: string]: Content;
  // };
  // contentTemplates: any[];
  // contentTemplateById: {
  //   [id: string]: any;
  // };
}

export enum CONTENT_ACTION_TYPES {
  INSTANCE = 'content-instance',
  BLUEPRINT = 'content-blueprint',
  GROUPING = 'content-grouping',
}
export interface ContentStateItem {
  refId: string;
  stepIdx: number;
  actionType: CONTENT_ACTION_TYPES;
  isOptional?: boolean;
  id?: string;
  name?: string;
  templateType?: string;
  localizations?: Localizations;
  payload?: Content | ContentPrototype;
  constraints?: ContentPrototypePayload['constraints'];
  dependencies?: Dependencies;
  templateId?: string;
  // the back-end content action's templateId is mapped to prototypeId in state
  prototypeId?: string;
  hasError?: boolean; //UI
  enableOptionalNode?: boolean; //UI
  iconUrl?: string;
}

export enum ContentActionTypes {
  SET_CONTENT_INSTANCES = 'SET_CONTENT_INSTANCES',
  SET_CREATED_CONTENT_INSTANCES = 'SET_CREATED_CONTENT_INSTANCES',
}

export type ContentInstancesState = {
  contentInstances: Content[];
  createdInstances: Content[];
};
