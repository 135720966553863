/* eslint-disable @typescript-eslint/no-unused-vars */
import { PushState, PushStateItem, PushDependencies } from 'store/push/push.type';
import {
  UpdatePushAction,
  UpdatePushSimpleAction,
  RemovePushAction,
  UpdatePushDependencyAction,
  UpdateErrorAction,
  ContentFetchedAction,
  InsertPushAction,
  UpdateRuleTriggerAction,
  InsertRuleAction,
  RemoveRuleAction,
  BatchInsertContentsAction,
  RemoveStepAction,
} from 'store/actionTypes';
import { createReducer, getPushActionFromStep, updateObject } from 'store/helper';
import { serializeDelivery } from 'helpers/templated-experience.helper';
import { Step, PushAction } from 'interface/experience/experience.interface';
import { Dependencies } from 'interface/common.interface';
import { SetTemplateAction, SetInstanceAction } from 'store/templated-experience/templated-experience.type';

const initialState: PushState = {
  byRefId: {},
  allRefId: [],
  stepRefIdMap: {},
};

export default createReducer(initialState, {
  SET_TEMPLATE: setTemplate,
  SET_INSTANCE: setInstance,
  INSERT_PUSH: createPush,
  UPDATE_PUSH: updatePush,
  UPDATE_PUSH_SIMPLE: updatePushSimple,
  REMOVE_PUSH: removePush,
  UPDATE_PUSH_DEPENDENCY: updatePushDependency,
  CONTENT_FETCHED: contentFetched,
  UPDATE_ERRORS: updatePushError,
  UPDATE_RULE_TRIGGER: updateRuleTrigger,
  INSERT_RULE: insertRule,
  REMOVE_RULE: removeRule,
  INSERT_CONTENTS: insertContents,
  CLEAR_JOURNEY: clearJourney,
  REMOVE_STEP: removeStep,
});

function clearJourney() {
  return initialState;
}
// instance currently using a soft replace (for integration purposes)
// will swap to hard replace when push components are migrated
function setInstance(state: PushState, action: SetInstanceAction): PushState {
  if (!action.payload.updateNewReducer) return state;

  const byRefId: PushState['byRefId'] = { ...state.byRefId };
  const allRefId: PushState['allRefId'] = [...state.allRefId];
  const stepRefIdMap: PushState['stepRefIdMap'] = { ...state.stepRefIdMap };

  action.payload?.instance?.steps.forEach((step: Step, idx: number) => {
    const push = getPushActionFromStep(step);

    if (push) {
      const flattenedPushAction = flattenPushAction(push);
      byRefId[push.refId] = {
        ...byRefId[push.refId],
        ...flattenedPushAction,
        // TODO: check if this is still used
        dependencies: {
          ...byRefId[push.refId]?.dependencies,
          ...flattenedPushAction?.dependencies,
        },
      };
      if (!allRefId.includes(push.refId)) allRefId.push(push.refId);
      if (!stepRefIdMap[idx]) stepRefIdMap[idx] = push.refId;
    }
  });

  return {
    byRefId,
    allRefId,
    stepRefIdMap,
  };
}

function setTemplate(state: PushState, action: SetTemplateAction): PushState {
  const preserveInstance = action.payload.preserveInstance;
  const byRefId: PushState['byRefId'] = preserveInstance ? state.byRefId : {};
  const allRefId: PushState['allRefId'] = preserveInstance ? state.allRefId : [];
  const stepRefIdMap: PushState['stepRefIdMap'] = { ...state.stepRefIdMap };

  action.payload?.template?.steps.forEach((step: Step, idx: number) => {
    const push = getPushActionFromStep(step);

    if (push) {
      if (preserveInstance) {
        if (byRefId[push.refId]) {
          byRefId[push.refId] = {
            ...state.byRefId[push.refId],
            constraints: push.payload?.constraints,
            isOptional: push.isOptional,
          };
        } else {
          byRefId[push.refId] = flattenPushAction(push, true);
          allRefId.push(push.refId);
          stepRefIdMap[idx] = push.refId;
        }
      } else {
        byRefId[push.refId] = flattenPushAction(push, true);
        allRefId.push(push.refId);
        stepRefIdMap[idx] = push.refId;
      }
    }
  });

  return {
    byRefId,
    allRefId,
    stepRefIdMap,
  };
}

function createPush(state: PushState, action: InsertPushAction): PushState {
  const newRefId = action.payload.defaults.refId;
  return {
    byRefId: {
      ...state.byRefId,
      [newRefId]: {
        ...action.payload.defaults,
        isOptional: true,
        enableOptionalNode: true,
      },
    },
    allRefId: [...state.allRefId, newRefId],
    stepRefIdMap: { ...state.stepRefIdMap, [action.payload.stepIdx ?? 0]: newRefId },
  };
}

function updatePushSimple(state: PushState, action: UpdatePushSimpleAction): PushState {
  const refId = action.payload.refId;
  const updatedFields = action.payload.fields;
  const newPushStateItem = { ...state.byRefId[refId] };

  for (const [key, value] of Object.entries(updatedFields)) {
    const pushKey = key as keyof PushStateItem;
    if (typeof value === 'object') {
      newPushStateItem[pushKey] = updateObject(newPushStateItem[pushKey], value);
    } else {
      newPushStateItem[pushKey] = value;
    }
  }

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [refId]: newPushStateItem,
    },
  };
}

function updatePush(state: PushState, action: UpdatePushAction): PushState {
  if (!state.allRefId.includes(action.payload.refId)) return state;
  switch (action.payload.type) {
    case 'basic':
      return {
        ...state,
        byRefId: {
          ...state.byRefId,
          [action.payload.refId]: updateBasicPush(state.byRefId[action.payload.refId], action),
        },
      };
    case 'weblink':
      return {
        ...state,
        byRefId: {
          ...state.byRefId,
          [action.payload.refId]: updateWeblinkPush(state.byRefId[action.payload.refId], action),
        },
      };
    case 'content':
      return {
        ...state,
        byRefId: {
          ...state.byRefId,
          [action.payload.refId]: updateContentPush(state.byRefId[action.payload.refId], action),
        },
      };
    case 'custom':
      return {
        ...state,
        byRefId: {
          ...state.byRefId,
          [action.payload.refId]: updateCustomPush(state.byRefId[action.payload.refId], action),
        },
      };
  }
  return state;
}

function removePush(state: PushState, action: RemovePushAction) {
  if (!state.allRefId.includes(action.payload.refId)) return state;
  const { [action.payload.refId]: _, ...newByRefId } = state.byRefId;
  const { [action.payload.stepIdx ?? 0]: __, ...newRefIdMap } = state.stepRefIdMap;
  return {
    byRefId: newByRefId,
    allRefId: state.allRefId.filter((item) => item !== action.payload.refId),
    stepRefIdMap: newRefIdMap,
  };
}

function updatePushDependency(state: PushState, action: UpdatePushDependencyAction) {
  if (!state.allRefId.includes(action.payload.refId)) return state;

  const push = state.byRefId[action.payload.refId];
  const newDeps: Dependencies = {};
  const oldDeps = extractDependencies(push.dependencies);

  if (action.payload.type === 'rule') {
    if (action.payload.value) {
      newDeps[action.payload.value] = '{{dep.rule-id.0}}';
    }
    if (oldDeps.content) {
      newDeps[oldDeps.content] = '{{dep.content-id.0}}';
    }
  } else {
    if (action.payload.value) {
      newDeps[action.payload.value] = '{{dep.content-id.0}}';
    }
    if (oldDeps.rule) {
      newDeps[oldDeps.rule] = '{{dep.rule-id.0}}';
    }
  }

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [push.refId]: {
        ...push,
        dependencies: newDeps,
      },
    },
  };
}

function updatePushError(state: PushState, action: UpdateErrorAction): PushState {
  const toUpdate: PushState['byRefId'] = {};

  action.payload.refIds.forEach((refId, idx) => {
    const pushItem = state.byRefId[refId];

    if (pushItem) {
      toUpdate[refId] = {
        ...pushItem,
        hasError: action.payload.values[idx],
      };
    }
  });

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      ...toUpdate,
    },
  };
}

// update the contentId field of push with the fetched content.
function contentFetched(state: PushState, action: ContentFetchedAction): PushState {
  const newByRefIdElements: PushState['byRefId'] = {};
  for (const refId of state.allRefId) {
    const push = state.byRefId[refId];

    if (push.dependencies && action.payload.refId in push.dependencies) {
      newByRefIdElements[refId] = {
        ...push,
        contentId: action.payload.content.id,
      };
    }
  }

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      ...newByRefIdElements,
    },
  };
}

function updateRuleTrigger(state: PushState, action: UpdateRuleTriggerAction) {
  const omitRule = action.payload.type === 'omit';
  const isEmptyRule = action.payload.isEmptyRule;
  const refId = state.stepRefIdMap[action.payload.stepIdx];
  if (!refId) return state;

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [refId]: {
        ...state.byRefId[refId],
        ruleId: omitRule || isEmptyRule ? '' : '{{dep.rule-id.0}}',
      },
    },
  };
}

function insertRule(state: PushState, action: InsertRuleAction) {
  const stepIdx = action.payload.stepIdx ?? 0;
  const refId = state.stepRefIdMap[stepIdx];
  if (!refId) return state;

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [refId]: {
        ...state.byRefId[refId],
        ruleId: '{{dep.rule-id.0}}',
      },
    },
  };
}

function insertContents(state: PushState, action: BatchInsertContentsAction) {
  const stepIdx = action.payload.stepIdx ?? 0;
  if (!action.payload.refIds.length) return state;
  const refId = state.stepRefIdMap[stepIdx];
  if (!refId) return state;
  if (state.byRefId[refId]?.pushPayloadType !== 'content') return state;

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [refId]: {
        ...state.byRefId[refId],
        contentId: '{{dep.content-id.0}}',
      },
    },
  };
}

function removeRule(state: PushState, action: RemoveRuleAction) {
  const stepIdx = action.payload.stepIdx ?? 0;
  const refId = state.stepRefIdMap[stepIdx];

  return {
    ...state,
    byRefId: {
      ...state.byRefId,
      [refId]: {
        ...state.byRefId[refId],
        ruleId: action.payload.wipeDependencies ? '' : state.byRefId[refId].ruleId,
      },
    },
  };
}

function removeStep(state: PushState, action: RemoveStepAction) {
  if (!action.payload.push) return state;
  if (!state.allRefId.includes(action.payload.push)) return state;
  const { [action.payload.push]: _, ...newByRefId } = state.byRefId;
  const { [action.payload.stepIdx ?? 0]: __, ...newRefIdMap } = state.stepRefIdMap;
  return {
    byRefId: newByRefId,
    allRefId: state.allRefId.filter((item) => item !== action.payload.push),
    stepRefIdMap: newRefIdMap,
  };
}

// wip, not all must-have attributes have been confirmed yet
// function generateDefaultPushStateItem(): PushStateItem {
//   return {
//     refId: '',
//     actionType: 'push-notification',
//     // default to triggered
//     pushType: 3,
//     // for now these will be left as just 'en'; more localization changes coming
//     alert: { en: '' },
//     title: { en: '' },
//     weight: 100,
//   }
// }

function flattenPushAction(push: PushAction, isTemplate = false): PushStateItem {
  const retVal = {
    ...push.payload,
    ...push.payload?.options,
    ...push.payload?.body?.[0]?.payload,
    ...push.payload?.instancePayload,
    ...push.payload?.instancePayload?.options,
    ...push.payload?.instancePayload?.body?.[0]?.payload,
    refId: push.refId,
    id: push.id,
    isOptional: isTemplate ? push.isOptional : true,
    name: isTemplate ? '' : push.payload?.name,
    actionType: push.actionType,
    dependencies: push.dependencies,
    templateId: push.templateId,
    hasError: push.hasError,
    ruleId: push.payload?.rules?.[0]?.ruleID ?? push.payload?.instancePayload?.rules?.[0]?.ruleID,
    ruleTriggerType: push.payload?.rules?.[0]?.result ?? push.payload?.instancePayload?.rules?.[0]?.result,
    contentId:
      push.payload?.body?.[0]?.payload.data?.contentId?.value ??
      push.payload?.instancePayload?.body?.[0]?.payload.data?.contentId?.value,
    pushPayloadTypeId:
      push.payload?.body?.[0]?.payload.data?.pushPayloadTypeId ??
      push.payload?.instancePayload?.body?.[0]?.payload.data?.pushPayloadTypeId,
    url: push.payload?.body?.[0]?.payload.data?.url ?? push.payload?.instancePayload?.body?.[0]?.payload.data?.url,
    actionLinkScheme:
      push.payload?.body?.[0]?.payload.data?.actionScheme ??
      push.payload?.instancePayload?.body?.[0]?.payload.data?.actionScheme,
    actionLinkType:
      push.payload?.body?.[0]?.payload.data?.actionType ??
      push.payload?.instancePayload?.body?.[0]?.payload.data?.actionType,
    contentBlueprintId:
      push.payload?.body?.[0]?.payload.data?.contentBlueprintId ??
      push.payload?.instancePayload?.body?.[0]?.payload.data?.contentBlueprintId,
    weight: push.payload?.body?.[0].weight ?? push.payload?.instancePayload?.body?.[0].weight,
    customPayloadData: {
      ...(push.payload?.body?.[0]?.payload.data ?? push.payload?.instancePayload?.body?.[0]?.payload.data),
    },
    enableOptionalNode: isTemplate ? !push.isOptional : true,
  };

  delete retVal.body;
  delete retVal.rules;
  delete retVal.data;
  delete retVal.options;
  delete retVal.instancePayload;
  delete retVal.description;

  return retVal;
}

// these updateXPush functions can be further optimized to be less repetitive
// but that may be overengineering for now
function updateBasicPush(push: PushStateItem, action: UpdatePushAction): PushStateItem {
  return {
    ...updateCommonPushValues(push, action),
    pushPayloadTypeId: undefined,
    url: undefined,
    customPayloadData: undefined,
    contentId: undefined,
  };
}

function updateContentPush(push: PushStateItem, action: UpdatePushAction): PushStateItem {
  return {
    ...updateCommonPushValues(push, action),
    pushPayloadTypeId: action.payload.pushTypeId ?? push.pushPayloadTypeId,
    url: undefined,
    customPayloadData: undefined,
    contentId: action.payload.form.contentId ?? push.contentId,
  };
}

function updateWeblinkPush(push: PushStateItem, action: UpdatePushAction): PushStateItem {
  return {
    ...updateCommonPushValues(push, action),
    pushPayloadTypeId: action.payload.pushTypeId,
    url: {
      ...action.payload.form.url,
    },
    customPayloadData: undefined,
    contentId: undefined,
  };
}

function updateCustomPush(push: PushStateItem, action: UpdatePushAction): PushStateItem {
  return {
    ...updateCommonPushValues(push, action),
    pushPayloadTypeId: action.payload.pushTypeId,
    url: undefined,
    customPayloadData: { ...action.payload.form.data },
    contentId: undefined,
  };
}

function updateCommonPushValues(push: PushStateItem, action: UpdatePushAction): PushStateItem {
  // reminder does not use push delivery options, it uses rule scheduler
  const delivery =
    action.payload.deliveryOptions && action.payload.templateType !== 'reminder'
      ? serializeDelivery(action.payload.deliveryOptions)
      : undefined;

  const oldDeps = extractDependencies(push.dependencies);
  const newDeps = extractDependencies(action.payload.form.dependencies);
  const dependencies: Dependencies = {};

  // remove the content from the dependencies ledger if its payload type changes
  if (action.payload.type === 'content') {
    const contentDepRefId = newDeps.content ?? oldDeps.content;
    if (contentDepRefId) dependencies[contentDepRefId] = '{{dep.content-id.0}}';
  }

  // update rule dependency
  const ruleDepRefId = newDeps.rule ?? oldDeps.rule;
  if (ruleDepRefId) dependencies[ruleDepRefId] = '{{dep.rule-id.0}}';

  return {
    ...push,
    title: { ...(action.payload.form.title ?? push.title) },
    alert: { ...(action.payload.form.alert ?? push.alert) },
    ...delivery,
    dependencies,
    // uh dw about it
    hasError: false,
  };
}

function extractDependencies(dependencies?: Dependencies): PushDependencies {
  const retVal: PushDependencies = { content: undefined, rule: undefined };

  if (dependencies) {
    for (const refId in dependencies) {
      if (dependencies[refId]?.includes('dep.content-id')) retVal.content = refId;
      if (dependencies[refId]?.includes('dep.rule-id')) retVal.rule = refId;
    }
  }

  return retVal;
}
