import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Session from 'services/session';
import { getTenantId } from 'helpers/templated-experience.helper';
import Iframe from 'react-iframe';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';

import './DynamicContentManager.scss';

const MAIN_CLASS = 'dynamic-content-iframe-preview';
const CLASSES = {
  IFRAME_WRAPPER: `${MAIN_CLASS}__iframe-wrapper`,
  IFRAME: `${MAIN_CLASS}__iframe`,
  LOADING_WRAPPER: `${MAIN_CLASS}__loading-wrapper`,
};

function DynamicContentManager() {
  const tenantId = getTenantId();
  const session = useMemo(() => new Session(tenantId), [tenantId]);
  // While testing locally with vue-content-manager
  // use http://localhost:3300 (by default)
  const contentManagerUrl = process.env.VUE_CONTENT_MANAGER_URL;
  const iframeUrl = `${contentManagerUrl}/content/manage`;
  const iframeTargetOrigin = useMemo(() => new URL(iframeUrl).origin, [iframeUrl]);

  const iframeWrapperRef = useRef<HTMLDivElement>(null);
  const [isIframeLoading, setIframeLoading] = useState(true);

  const iframeMessageListener = useCallback(
    async (event: MessageEvent) => {
      const { source, origin, data: { type } = { type: '' } } = event;

      const iframe = iframeWrapperRef.current?.querySelector('#dynamicContentManager') as HTMLIFrameElement;
      const iframeWindow = iframe?.contentWindow;
      const env = process.env.REACT_API_ENV;
      const host = process.env.REACT_APP_API_URL;
      const token = await session.getToken();

      if (
        !iframe ||
        !iframeWindow ||
        source !== iframeWindow ||
        origin !== iframeTargetOrigin ||
        type !== 'flb.contentmanager.ready'
      ) {
        return;
      }

      if (!env || !host || !token) {
        return;
      }

      setIframeLoading(false);

      const messagePayload = {
        token,
        host,
        env,
      };

      iframeWindow.postMessage(messagePayload, iframeTargetOrigin);
    },
    [iframeTargetOrigin, session],
  );

  useEffect(() => {
    window.addEventListener('message', iframeMessageListener);

    return () => {
      window.removeEventListener('message', iframeMessageListener);
    };
  }, [iframeMessageListener]);

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.IFRAME_WRAPPER} ref={iframeWrapperRef}>
        {isIframeLoading && (
          <div className={CLASSES.LOADING_WRAPPER}>
            <LoadingIcon />
          </div>
        )}
        <Iframe
          className={CLASSES.IFRAME}
          title={`content-manager-${tenantId}`}
          url={iframeUrl}
          width="100%"
          height="100%"
          id="dynamicContentManager"
          position="relative"
          sandbox={['allow-scripts', 'allow-same-origin']}
        />
      </div>
    </div>
  );
}

export default DynamicContentManager;
