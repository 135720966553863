import {
  ApprovalActionTypes,
  SetApprovalSettingsAction,
  SetApprovalSettingsLoadingAction,
  SetApprovalSettingsErrorAction,
} from './approval.type';

export function getApprovalSettingsAction(payload: SetApprovalSettingsAction['payload']): SetApprovalSettingsAction {
  return {
    type: ApprovalActionTypes.SET_APPROVAL_SETTINGS,
    payload,
  };
}

export function getApprovalSettingsLoadingAction(
  payload: SetApprovalSettingsLoadingAction['payload'],
): SetApprovalSettingsLoadingAction {
  return {
    type: ApprovalActionTypes.SET_APPROVAL_SETTINGS_LOADING,
    payload,
  };
}

export function getApprovalSettingsErrorAction(
  payload: SetApprovalSettingsErrorAction['payload'],
): SetApprovalSettingsErrorAction {
  return {
    type: ApprovalActionTypes.SET_APPROVAL_SETTINGS_ERROR,
    payload,
  };
}
