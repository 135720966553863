import React from 'react';
import { TIconProps } from '../types';

function FileIcon({ fill = '#000000', className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 3V0.75L14.25 5.25H12C10.42 5.25 9.75 4.58 9.75 3ZM12 6.75C9.58 6.75 8.25 5.42 8.25 3V0H3C1 0 0 1 0 3V15C0 17 1 18 3 18H12C14 18 15 17 15 15V6.75H12Z"
        fill={fill}
      />
    </svg>
  );
}

export default FileIcon;
