import React from 'react';
import { IconProps } from './icon.types';

function LabelFilterSlideoutIcon(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#2371F1" />
      <path
        d="M25.97 22.4075L22.4 25.985C21.3725 27.005 20.315 27.005 19.295 25.985L18.692 25.3752C18.6042 25.2875 18.6042 25.145 18.692 25.0572L25.049 18.7003C25.1368 18.6125 25.2793 18.6125 25.367 18.7003L25.9692 19.3025C27.0275 20.36 26.99 21.38 25.97 22.4075ZM24.2735 17.924L17.9165 24.281C17.8288 24.3688 17.6862 24.3688 17.5985 24.281L13.895 20.5775C13.4817 20.1643 13.25 19.6047 13.25 19.0205V14.7125C13.25 13.9048 13.9047 13.25 14.7125 13.25H19.0153C19.5988 13.25 20.1583 13.4817 20.57 13.895L24.2735 17.606C24.3612 17.6937 24.3612 17.8362 24.2735 17.924ZM17 16.25C17 15.836 16.6655 15.5 16.2515 15.5H16.244C15.8315 15.5 15.4993 15.836 15.4993 16.25C15.4993 16.664 15.8382 17 16.2507 17C16.6655 17 17 16.664 17 16.25Z"
        fill="white"
      />
    </svg>
  );
}

export default LabelFilterSlideoutIcon;
