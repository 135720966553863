/*
  journey.selector.ts
  a set of functions that take in the current state related to the currently
  displayed journey and derives useful data.
*/

import { Experience } from 'interface/experience/experience.interface';
import { validateJourney } from 'validators/ExperienceCanvas/journey.validator';
import { getPushPayloadType } from 'store/push/push.selector';
import { RootState } from 'store/store';
import { JourneyState, JourneyStatusLabels, JOURNEY_STATUSES, JOURNEY_STATUS_LABELS } from './journey.type';

/**
 * Returns the metadata of a step.
 * */
export function getStepMetadata(journeyState: JourneyState, stepIdx: number) {
  return {
    name: journeyState.steps[stepIdx]?.name ?? `TouchPoint ${stepIdx + 1}`,
    desc: journeyState.steps[stepIdx]?.desc ?? '',
  };
}

/**
 * Checks if the touchpoint should allow the user to edit content
 * */
export function showContentBox(state: RootState, stepIdx: number): boolean {
  const isAppContentType = state.te.journey.templateType === 'app-content';
  const pushRefId = state.te.journey.steps[stepIdx]?.push ?? '';
  const pushPayloadType = getPushPayloadType(state, pushRefId);
  const hasContent = !!state.te.journey.steps[stepIdx]?.content.length;

  return isAppContentType || pushPayloadType?.type === 'content' || hasContent;
}

/**
 * Checks if the touchpoint has content groupings on its prototypes.  Exits on first found.
 * */
export function getContentGrouping(state: RootState, stepIdx: number): string | undefined {
  const contentRefIds = state.te.journey.steps[stepIdx]?.content;
  for (let i = 0; i < contentRefIds?.length || 0; i++) {
    const contentAction = state.te.content.byRefId[contentRefIds[i]];
    if (contentAction && contentAction.actionType === 'content-grouping') return contentRefIds[i];
  }
}

/**
 * Checks if audience can be edited. Once a rule has entered into production, it
 * can no longer be edited.
 * @returns boolean
 */
export function isAudienceLocked(state: RootState): boolean {
  const templatedInstance: Experience = state.templatedExperience.instance;
  const status = templatedInstance.status ?? '';
  const activatedAt = templatedInstance.activatedAt;

  return status.toLowerCase() === 'active' || status.toLowerCase() === 'scheduled' || !!activatedAt;
}

/**
 * Check if journey is incomplete
 * @returns boolean
 */
export function getIsJourneyIncomplete(state: RootState): boolean {
  const journeyInstance = state.te.journey;

  return journeyInstance.incompleteCount ? journeyInstance.incompleteCount > 0 : false;
}

/**
 * Calculates what the current journey status label should say
 * and, also if it is disabled
 * @returns
 *   {
 *     save: {
 *        statusLabel: JOURNEY_STATUS_LABELS,
 *        isDisabled: boolean,
 *     },
 *     launch: {
 *        statusLabel: JOURNEY_STATUS_LABELS,
 *        isDisabled: boolean,
 *     }
 *   }
 */
export function getJourneyStatusLabels(state: RootState): JourneyStatusLabels {
  const journeyInstance: JourneyState = state.te.journey;

  const journeyStatusLabels: JourneyStatusLabels = {
    save: {
      statusLabel: JOURNEY_STATUS_LABELS.SAVE,
      isDisabled: false,
    },
    launch: {
      statusLabel: JOURNEY_STATUS_LABELS.LAUNCH,
      isDisabled:
        !validateJourney(state) ||
        journeyInstance.status === JOURNEY_STATUSES.ERROR ||
        journeyInstance.isApproved === false,
    },
  };

  const journeyScheduleStart = journeyInstance.schedule?.start ?? 0;

  if (journeyInstance.status !== JOURNEY_STATUSES.ACTIVE) {
    if (journeyScheduleStart !== 0) {
      journeyStatusLabels.launch.statusLabel = JOURNEY_STATUS_LABELS.SCHEDULE;
    }
  }

  if (journeyInstance.status === JOURNEY_STATUSES.INACTIVE) {
    if (journeyScheduleStart === 0) {
      journeyStatusLabels.launch.statusLabel = JOURNEY_STATUS_LABELS.ACTIVATE;
    }
  }

  if (journeyInstance.status === JOURNEY_STATUSES.ERROR) {
    journeyStatusLabels.launch.statusLabel = JOURNEY_STATUS_LABELS.ERROR;
  }

  return journeyStatusLabels;
}

/**
 * Checks if the journey status is active or scheduled
 * @returns boolean
 */
export function getIsJourneyActiveOrScheduled(teState: RootState['te']): boolean {
  const journeyInstance: JourneyState = teState.journey;

  return journeyInstance.status === JOURNEY_STATUSES.ACTIVE || journeyInstance.status === JOURNEY_STATUSES.SCHEDULED;
}
