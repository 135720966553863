import { LabelFilter } from 'pages/Zones/types';
import React, { createContext } from 'react';

export type TLabelFilterContext = {
  moduleName: string | null;
  labelFilters: LabelFilter[] | null;
  selectedLabelFilter: LabelFilter | null;
  setSelectedLabelFilter: React.Dispatch<React.SetStateAction<LabelFilter | null>>;

  addLabelFilter: (newLabels: LabelFilter[]) => void;
  reOrder: (startIndex: number, endIndex: number) => void;
  remove: (label: string) => void;
};

const LabelFilterContextDefaults: TLabelFilterContext = {
  moduleName: null,
  labelFilters: [],
  setSelectedLabelFilter: () => null,
  selectedLabelFilter: null,
  addLabelFilter: () => null,
  reOrder: () => null,
  remove: () => null,
};

export default createContext<TLabelFilterContext>(LabelFilterContextDefaults);
