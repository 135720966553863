import './FlowCanvas.scss';

import ReactFlow, { Background, Edge, Node } from 'reactflow';

import ContentNode from '../FlowNodes/ContentNode/ContentNode';
// import ControlPanel from './ControlPanel/ControlPanel';
// import ZoomPanel from './ZoomPanel/ZoomPanel';
import { FlowNodeTypes } from '../FlowNodes/node.types';
import FlowStartNode from '../FlowNodes/FlowStartNode/FlowStartNode';
import PushNode from '../FlowNodes/PushNode/PushNode';
import React from 'react';
import TargetAudienceNode from '../FlowNodes/TargetAudienceNode/TargetAudienceNode';
import WebhookNode from '../FlowNodes/WebhookNode/WebhookNode';

const MAIN_CLASS = 'flow-canvas';
const CLASSES = {
  REACT_FLOW_CUSTOM: `${MAIN_CLASS}__react-flow-custom`,
};

const nodeTypes = {
  [FlowNodeTypes.START]: FlowStartNode,
  [FlowNodeTypes.TARGET_AUDIENCE]: TargetAudienceNode,
  [FlowNodeTypes.CONTENT]: ContentNode,
  [FlowNodeTypes.PUSH]: PushNode,
  [FlowNodeTypes.WEBHOOK]: WebhookNode,
};

type FlowCanvasProps = {
  nodes: Node[];
  edges: Edge[];
};

function FlowCanvas(props: FlowCanvasProps) {
  const { nodes = [], edges = [] } = props;

  return (
    <div className={MAIN_CLASS}>
      <ReactFlow
        className={CLASSES.REACT_FLOW_CUSTOM}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        minZoom={0.1}
      >
        {/*
          Functionality not implemented yet
          (out of scope for the Dec 2023 Product Summit)
          <ControlPanel />
        */}
        {/*
          Has some glitches now
          (out of scope for the Dec 2023 Product Summit)
          <ZoomPanel />
        */}
        <Background />
      </ReactFlow>
    </div>
  );
}

export default FlowCanvas;
