import React from 'react';

import SmartTargetingIcon from 'pages/ExperienceCanvas/assets/icons/SmartTargetingIcon';
import { ReactComponent as TargetImage } from 'assets/images/targets.svg';
import { ReactComponent as BrainImage } from 'assets/images/brain.svg';
import { ReactComponent as NetworkImage } from 'assets/images/network-map.svg';
import { SMART_TARGET_STATUS, SmartTargetStatusType } from 'interface/smart-targeting/smart-targeting.interface';

import './CurrentlyOptimizing.scss';

const DEFAULT_CLASS = 'currently-optimizing';

type TCurrentlyOptimizingProps = {
  engagement: number;
  status: SmartTargetStatusType;
};

type CurrentlyOptimizingContent = {
  header: string;
  description: JSX.Element;
  image: JSX.Element;
};

const STATUS_CONTENT_MAP: Partial<Record<SmartTargetStatusType, CurrentlyOptimizingContent>> = {
  inactive: {
    header: 'Optimize your audience',
    description: (
      <p>
        Smart Targeting amplifies your target impact with AI and human-in-control tools. Simply launch your experience,
        and we&apos;ll let you know when Smart Targeting is ready to optimize your audience reach and engagement.
      </p>
    ),
    image: <NetworkImage className="image" />,
  },
  'in-training': {
    header: 'Almost there!',
    description: (
      <p>
        Our model is in <strong>training mode</strong> to learn how your experience performs in-market. You&apos;ll be
        notified when Smart Targeting is ready to optimize your experience.
      </p>
    ),
    image: <BrainImage className="image" />,
  },
  'ready-activate': {
    header: 'Ready to activate',
    description: (
      <p>
        Our model has completed training and is ready to provide recommendations to optimize your experience. You can
        modify your targets in the <strong>Smart Targeting Performance</strong> section below.
      </p>
    ),
    image: <TargetImage className="image" />,
  },
  active: {
    header: 'Currently optimizing your experience for',
    description: (
      <p>
        You can modify your targets in the <strong>Smart Targeting Performance</strong> section below.
      </p>
    ),
    image: <TargetImage className="image" />,
  },
};

const CurrentlyOptimizing = ({ engagement, status }: TCurrentlyOptimizingProps) => {
  return (
    <div className={`${DEFAULT_CLASS} ${status === SMART_TARGET_STATUS.ACTIVE ? 'active-border' : ''}`}>
      <div className={`${DEFAULT_CLASS}__content`}>
        <div className="top">
          <SmartTargetingIcon />
          <p className="headline">{STATUS_CONTENT_MAP[status]?.header || 'Optimize your audience'}</p>
        </div>
        <div className="body">
          {status === SMART_TARGET_STATUS.ACTIVE && (
            <div className="engagement">
              <p className="engagement__number">{engagement}%</p>
              <p className="engagement__label">engagement rate</p>
            </div>
          )}
          {STATUS_CONTENT_MAP[status]?.description || (
            <p>
              Smart Targeting amplifies your target impact with AI and human-in-control tools. Simply launch your
              experience, and we&apos;ll let you know when Smart Targeting is ready to optimize your audience reach and
              engagement.
            </p>
          )}
          {status === SMART_TARGET_STATUS.INACTIVE && (
            <div className="body-note">
              <p>Note: Smart Targeting will automatically begin training when there is enough data</p>
            </div>
          )}
        </div>
        {/* {(status === SMART_TARGET_STATUS.INACTIVE || status === SMART_TARGET_STATUS.IN_TRAINING) &&
          <div className="bottom">
            <FlightButton
              theme="secondary"
              className="learn-more-btn"
              label="Learn More"
              onClick={() => {}}
            />
          </div>
        } */}
      </div>
      {STATUS_CONTENT_MAP[status]?.image || <NetworkImage className="image" />}
    </div>
  );
};

export default CurrentlyOptimizing;
