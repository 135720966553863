import PluginAPI from 'services/api/plugin.api';
import { setPlugins } from './plugin.action';

// Constants
import { ENV_TO_SHOW_ALL_PLUGINS, HIDDEN_PLUGIN_ATTRIBS } from '../../constants/contextPlugins';

function filterPluginsByEnv<T>(plugins: T[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return plugins?.filter((ctxPlugin: any) => {
    const isHiddenPlugin = HIDDEN_PLUGIN_ATTRIBS.includes(ctxPlugin.uid);
    if (isHiddenPlugin) {
      return false;
    } else {
      ctxPlugin.values = Object.fromEntries(
        Object.entries(ctxPlugin.values).filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([, value]: [string, any]) => !HIDDEN_PLUGIN_ATTRIBS.includes(value.uid),
        ),
      );
    }
    return true;
  });
}

export function fetchPluginsThunk() {
  const pluginAPI = new PluginAPI();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any, getState: any) => {
    const { plugin } = getState();

    if (plugin.plugins) {
      return plugin.plugins;
    }

    try {
      const plugins = await pluginAPI.getPlugins();
      const filteredPlugins =
        plugins && process.env.REACT_APP_ENV && ENV_TO_SHOW_ALL_PLUGINS.includes(process.env.REACT_APP_ENV)
          ? plugins
          : filterPluginsByEnv(plugins);

      dispatch(setPlugins({ plugins: filteredPlugins }));
      return plugins;
    } catch (e) {
      return;
    }
  };
}
