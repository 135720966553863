import React from 'react';
import { TIconProps } from '../types';

function IACIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1994_106371)">
        <path
          d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z"
          fill={fill}
        />
        <path d="M19 13H5L9.5 7L13 11.51L15.5 8.5L19 13Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_1994_106371">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IACIcon;
