export const ENV_TO_SHOW_ALL_PLUGINS: string[] = ['development', 'staging', 'production-demo'];

export const HIDDEN_PLUGIN_ATTRIBS: string[] = [
  'ctx.flybits.approximateArea.query.inApproximateArea',
  'ctx.sdk.activity',
  'ctx.sdk.battery',
  'ctx.sdk.device.alarmVolume',
  'ctx.sdk.device.isBluetoothA2dpOn',
  'ctx.sdk.device.isBluetoothHeadsetOn',
  'ctx.sdk.device.isBluetoothScoOn',
  'ctx.sdk.device.isHDMIPluggedIn',
  'ctx.sdk.device.isHeadsetPluggedIn',
  'ctx.sdk.device.mediaVolume',
  'ctx.sdk.device.ringerVolume',
  'ctx.sdk.fitness',
  'ctx.sdk.goalTrack',
  'ctx.sdk.location',
];
