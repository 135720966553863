import React from 'react';
import { FlightButton } from '@flybits/design-system';
import ImageSetupModule from 'pages/Zones/assets/images/images-setup-module.svg';
import './SetupModuleBox.scss';

type TSetupModuleBoxProps = {
  openPrioritizationManager: () => void;
};

const MAIN_CLASS = 'setup-module';
const CLASSES = {
  SETUP_MODULE_CONTAINER: `${MAIN_CLASS}__container`,
};

const SetupModuleBox: React.FC<TSetupModuleBoxProps> = ({ openPrioritizationManager }) => (
  <div className={MAIN_CLASS}>
    <img src={ImageSetupModule} alt="add module" />
    <div className={CLASSES.SETUP_MODULE_CONTAINER}>
      <div className={`${CLASSES.SETUP_MODULE_CONTAINER}__text`}>
        Pin content and apply labels to this module with complete customizability
      </div>
      <div className={`${CLASSES.SETUP_MODULE_CONTAINER}__button`}>
        <FlightButton iconLeft="itemsList" label="Setup prioritization" onClick={openPrioritizationManager} />
      </div>
    </div>
  </div>
);

export default SetupModuleBox;
