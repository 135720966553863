import React, { useContext, useMemo } from 'react';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';

import ModuleLabelIcon from 'pages/ZonesV2/Icons/LabelFilterSlideoutIcon';
import LabelList from './LabelList/LabelList';
import LabelPreview from './LabelPreview/LabelPreview';
import LabelConfiguration from './LabelConfiguration';

import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import './LabelFilterSlideout.scss';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import LabelFilterContext from './LabelFilterContext/LabelFilterContext';
import { LabelFilter } from 'pages/Zones/types';

type TLabelFilterSlideoutProps = {
  onClickClose: () => void;
  onClickApply: (labelFilters: LabelFilter[]) => void;
  onSlideOutComplete: () => void;
};

const MAIN_CLASS = 'module-label-filters-slide-out';
const CLASSES = {
  GRID: `${MAIN_CLASS}__grid-container`,
  PANEL: `${MAIN_CLASS}__panel`,
  LABEL_LIST: `${MAIN_CLASS}__label-list-container`,
  LABEL_INFO: `${MAIN_CLASS}__label-info-container`,
  LABEL_PREVIEW: `${MAIN_CLASS}__label-preview-container`,
};

const LabelFilterSlideout: React.FC<TLabelFilterSlideoutProps> = ({
  onClickClose,
  onClickApply,
  onSlideOutComplete,
}) => {
  const { labelFilters } = useContext(LabelFilterContext);
  const { isLabelManagementOpen } = useContext(PrioritizationContext);

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      headerInfo: {
        mainTitle: '',
        goBackTitle: 'Label Manager',
        goBackSubTitle: 'Manage and prioritize your labels',
        goBackIcon: <ModuleLabelIcon />,
        goBackActionHandler: onClickClose,
      },
      footerInfo: {
        primaryActionText: 'Apply',
        primaryActionHandler: () => {
          onClickApply(labelFilters ?? []);
        },
        secondaryActionText: 'Cancel',
        secondaryActionHandler: onClickClose,
      },
      showFooter: true,
      show: isLabelManagementOpen,
      size: 'large',
      className: MAIN_CLASS,
      onSlideOutComplete,
    }),
    [labelFilters, isLabelManagementOpen, onClickApply, onClickClose, onSlideOutComplete],
  );

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={CLASSES.GRID}>
        <div className={`${CLASSES.LABEL_LIST} ${CLASSES.PANEL}`}>
          <LabelList />
        </div>
        <div className={`${CLASSES.LABEL_INFO} ${CLASSES.PANEL}`}>
          <div className={CLASSES.LABEL_INFO}>
            <LabelConfiguration />
          </div>
        </div>
        <div className={`${CLASSES.LABEL_PREVIEW} ${CLASSES.PANEL}`}>
          <LabelPreview />
        </div>
      </div>
    </SlidingSidePanel>
  );
};

export default LabelFilterSlideout;
