import {
  SetErrorAction,
  SetErrorsAction,
  SetInstanceAction,
  SetInstancesAction,
  SetLoadingAction,
  SetTemplateAction,
  TExperienceActionType,
  UpdateInstancesAction,
} from 'store/templated-experience/templated-experience.type';

import { Experience } from 'interface/experience/experience.interface';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PaginationResponse } from 'interface/shared/api.interface';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { showSnackbar } from 'store/snackbar/snackbar.action';

function setLoading(payload: { flag: boolean }): SetLoadingAction {
  return {
    type: TExperienceActionType.SET_LOADING,
    payload,
  };
}

function setError(payload: { errorMessage: any }): SetErrorAction {
  return {
    type: TExperienceActionType.SET_ERROR,
    payload,
  };
}

export function setErrors(payload: { errors: Array<string> }): SetErrorsAction {
  return {
    type: TExperienceActionType.SET_ERRORS,
    payload,
  };
}

export function setPushTypes(payload: any) {
  return {
    type: TExperienceActionType.SET_PUSH_TYPES,
    payload,
  };
}

export function setTemplate(payload: SetTemplateAction['payload']): SetTemplateAction {
  return {
    type: TExperienceActionType.SET_TEMPLATE,
    payload,
  };
}

export function setInstance(payload: { instance: Experience | any; updateNewReducer?: boolean }): SetInstanceAction {
  return {
    type: TExperienceActionType.SET_INSTANCE,
    payload,
  };
}

export function setInstances(payload: { instances: PaginationResponse<Experience> }): SetInstancesAction {
  return {
    type: TExperienceActionType.SET_INSTANCES,
    payload,
  };
}

export function updateInstances(payload: { id: string; status: string }): UpdateInstancesAction {
  return {
    type: TExperienceActionType.UPDATE_INSTANCES,
    payload,
  };
}

export function fetchInstances(opts: any) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .getTemplatedInstances(opts)
        .then((data: PaginationResponse<Experience>) => {
          dispatch(setInstances({ instances: data }));
          resolve(data);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function deleteTemplatedExperienceInstance(instance: Experience, filters: any) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .deleteTemplatedExperienceInstance(instance.id)
        .then((res: any) => {
          dispatch(fetchInstances(filters));
          dispatch(
            showSnackbar({
              content: `Successfully deleted experience: "${instance.name || 'Untitled'}"`,
              type: 'default',
            }),
          );
          resolve(res);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

export function bulkDeleteInstances(idsToDelete: string[], filters: any) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      Promise.allSettled(
        idsToDelete.map(async (instanceId) => templatedExperienceAPI.deleteTemplatedExperienceInstance(instanceId)),
      )
        .then((res: any) => {
          dispatch(fetchInstances(filters));
          dispatch(
            showSnackbar({
              content: 'Successfully deleted selected experiences.',
              type: 'default',
            }),
          );
          resolve(res);
        })
        .catch(() => {
          dispatch(setError({ errorMessage: 'An error occured while deleting experiences.' }));
          reject();
        });
    });
  };
}
