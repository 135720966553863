/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import useJourneyInstance from 'hooks/useJourneyInstance';
import { getUserApprovalPermissions, offsetISOToUnix, unixToOffsetISO } from 'helpers/common.helper';

import axios from 'axios';
import { SystemError } from 'types/errors';
import { ApprovalRequest, Attachment } from 'interface/approval/approval.interface';

import ApprovalAPI from 'services/api/approval.api';

import TouchpointCardCarousel from 'components/ExperienceCanvas/TouchpointCardCarousel/TouchpointCardCarousel';
import CommentList from 'components/ExperienceCanvas/Comments/CommentList/CommentList';
import AttachmentManager from 'components/ExperienceCanvas/AttachmentManager/AttachmentManager';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import ErrorSplash from 'components/ExperienceCanvas/ErrorSplash/ErrorSplash';
import ConfirmApproveRequestModal from 'components/Modal/ConfirmModal/ConfirmApproveRequestModal/ConfirmApproveRequestModal';
import { FlightButton, FlightTextArea, getIcon } from '@flybits/design-system';

import './ApprovalRequest.scss';

type ApprovalRequestPageParams = {
  pid: string;
  id: string;
};

const getDueDateLabels = (dueDate: number, isAuthor: boolean) => {
  return {
    label: dueDate ? new Date(dueDate * 1000).toLocaleString() : 'No due date set.',
    actionLabel: isAuthor ? 'Edit the due date' : dueDate ? 'View the Schedule' : '',
  };
};

const MAIN_CLASS = 'approval-request';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  HEADER_TITLE: `${MAIN_CLASS}__header__title`,
  HEADER_ACTIONS: `${MAIN_CLASS}__header__actions`,
  HEADER_BUTTON: `${MAIN_CLASS}__header__button`,
  BODY: `${MAIN_CLASS}__body`,
  BODY_HEADER: `${MAIN_CLASS}__body__header`,
  BODY_HEADER_TITLE: `${MAIN_CLASS}__body__header__title`,
  BODY_HEADER_ACTIONS: `${MAIN_CLASS}__body__header__actions`,
  BODY_HEADER_BUTTON: `${MAIN_CLASS}__body__header__button`,
  SECTION_TITLE: `${MAIN_CLASS}__body__section-title`,
  TITLE: `${MAIN_CLASS}__body__title`,
  DUE_DATE: `${MAIN_CLASS}__body__due-date`,
  PREVIEW: `${MAIN_CLASS}__body__preview`,
  NOTES: `${MAIN_CLASS}__body__notes`,
  ATTACHMENTS: `${MAIN_CLASS}__body__attachments`,
  COMMENTS: `${MAIN_CLASS}__comments`,
  FOOTER: `${MAIN_CLASS}__footer`,
  FOOTER_BUTTON: `${MAIN_CLASS}__footer__button`,
};

function ApprovalRequestPage() {
  const approvalAPI = useMemo(() => new ApprovalAPI(), []);
  const tzOffset = useMemo(() => new Date().getTimezoneOffset() * 60000, []);

  const { pid, id } = useParams<ApprovalRequestPageParams>();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['approval-request', id],
    queryFn: async () => {
      const { data } = await approvalAPI.getApprovalRequests({ entityId: id });
      return data[0];
    },
    retry: false,
  });

  const { journeyInstance, isLoading: isJourneyLoading, error: journeyError } = useJourneyInstance(id, false);

  const [viewState, setViewState] = useState({ isAuthor: false, isReviewer: false, hasApproved: false });
  const [dueDate, setDueDate] = useState({
    ...getDueDateLabels(0, viewState.isAuthor),
    date: 0,
  });
  const [notes, setNotes] = useState('');
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [uploads, setUploads] = useState<FileList | null>(null);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  // TODO: Verify if this should be inline or modal
  const [isDueDateExpanded, setIsDueDateExpanded] = useState(false);

  const hasChanges =
    dueDate.date !== data?.data.dueDate ||
    notes !== data?.data.notes ||
    uploads?.length ||
    attachments.length !== data?.data.attachments?.length;

  const resetChanges = () => {
    setNotes(data?.data.notes ?? '');
    const oldDueDate = data?.data.dueDate ?? 0;
    setDueDate({
      ...getDueDateLabels(oldDueDate, viewState.isAuthor),
      date: oldDueDate,
    });
    setUploads(null);
    setAttachments(data?.data.attachments ?? []);
  };

  const saveChanges = async () => {
    if (data?.data.requestId) {
      try {
        await approvalAPI.updateApprovalRequest(data.data.requestId, {
          notes,
          dueDate: dueDate.date,
        });
        if (uploads?.length) {
          for (const file of uploads) {
            await approvalAPI.postAttachment(data.data.requestId, file);
          }
        }
        if (attachments.length !== data?.data.attachments?.length) {
          const fileIdsToDelete: string[] = [];
          data?.data.attachments?.forEach((oldAtt) => {
            if (!attachments.find((att) => att.fileId === oldAtt.fileId)) {
              fileIdsToDelete.push(oldAtt.fileId);
            }
          });

          for (const fileId of fileIdsToDelete) {
            await approvalAPI.deleteAttachment(data.data.requestId, fileId);
          }
        }
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Changes saved!`, type: 'success' },
        });
        setUploads(null);
        refetch();
      } catch (e) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Something went wrong D:`, type: 'error' },
        });
      }
    }
  };

  const submitApproval = async (status: boolean) => {
    if (data?.data.requestId) {
      try {
        await approvalAPI.updateApprovalStatus(data.data.requestId, status);
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: status ? 'Approved!' : 'Sent back to review', type: 'success' },
        });
        refetch();
      } catch (e) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Something went wrong D:`, type: 'error' },
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      const { creators, approvalRequirements, dueDate, notes } = data.data;
      const { isAuthor, isReviewer, hasApproved } = getUserApprovalPermissions(
        user?.id ?? '',
        creators,
        approvalRequirements,
      );

      setViewState({ isAuthor, isReviewer, hasApproved });
      setDueDate({
        ...getDueDateLabels(data.data.dueDate, isAuthor),
        date: data.data.dueDate,
      });
      setNotes(data.data.notes);
      setAttachments(data.data.attachments ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return isLoading || isJourneyLoading ? (
    <div className="loading-icon-container" aria-label="approval request is loading">
      <LoadingIcon className="loading-icon-container__icon" />
    </div>
  ) : error || journeyError ? (
    <ErrorSplash error={error || journeyError} />
  ) : (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.HEADER}>
        <div className={CLASSES.HEADER_TITLE}>Report for approval</div>
        <div className={CLASSES.HEADER_ACTIONS}>
          <FlightButton
            className={CLASSES.HEADER_BUTTON}
            iconLeft="gantt"
            onClick={() => null}
            theme="link"
            label="Approval Timeline"
          />
          {viewState.isAuthor && (
            <FlightButton
              className={CLASSES.HEADER_BUTTON}
              iconLeft="personCog"
              onClick={() => null}
              theme="link"
              label="Manage Users"
            />
          )}
        </div>
      </div>
      <div className={CLASSES.BODY}>
        {viewState.isAuthor && hasChanges && (
          <div className={CLASSES.BODY_HEADER}>
            <div className={CLASSES.BODY_HEADER_TITLE}>
              {getIcon('pencil', {})}
              <span>Editing Request</span>
            </div>
            <div className={CLASSES.BODY_HEADER_ACTIONS}>
              <FlightButton
                className={CLASSES.BODY_HEADER_BUTTON}
                onClick={resetChanges}
                theme="secondary"
                label="Cancel"
                size="small"
              />
              <FlightButton
                className={CLASSES.BODY_HEADER_BUTTON}
                onClick={saveChanges}
                theme="primary"
                label="Save Changes"
                size="small"
              />
            </div>
          </div>
        )}
        <h3 className={CLASSES.TITLE}>{journeyInstance.name}</h3>
        <div className={CLASSES.SECTION_TITLE}>
          <h3>Due Date</h3>
        </div>
        <div className={CLASSES.DUE_DATE}>
          <span>{dueDate.label}</span>
          <a onClick={(evt) => setIsDueDateExpanded((prev) => !prev)}>{dueDate.actionLabel}</a>
        </div>
        {isDueDateExpanded && (
          <div className={CLASSES.DUE_DATE}>
            <input
              type="datetime-local"
              id="due-date"
              name="due-date"
              aria-label="approval due date"
              value={unixToOffsetISO(dueDate.date, tzOffset)}
              min={unixToOffsetISO(Date.now() / 1000, tzOffset)}
              onChange={(evt) => {
                const date = offsetISOToUnix(evt.target.value, tzOffset);
                setDueDate({
                  ...getDueDateLabels(date, viewState.isAuthor),
                  date: date,
                });
              }}
              disabled={!viewState.isAuthor}
            />
            <a
              onClick={(evt) => {
                setIsDueDateExpanded(false);
                setDueDate({
                  ...getDueDateLabels(0, viewState.isAuthor),
                  date: 0,
                });
              }}
            >
              Remove
            </a>
          </div>
        )}
        <div className={CLASSES.SECTION_TITLE}>
          <h3>Experience Overview</h3>
          <FlightButton
            className={`${CLASSES.FOOTER_BUTTON} tertiary`}
            onClick={() => {
              history.push(`/project/${pid}/experiences/${id}/overview`);
            }}
            theme="primary"
            iconRight="openInNew"
            label="View in Experience Studio"
          />
        </div>
        {journeyInstance.id ? <TouchpointCardCarousel /> : null}
        <div className={CLASSES.SECTION_TITLE}>
          <h3>{'Notes & Attachments'}</h3>
        </div>
        {viewState.isAuthor ? (
          <FlightTextArea
            className={CLASSES.NOTES}
            width="100%"
            value={notes}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
            label=""
            ariaLabel="approval request notes"
          />
        ) : (
          <p className={CLASSES.NOTES}>{notes}</p>
        )}
        <AttachmentManager
          isAuthor={viewState.isAuthor}
          addAttachments={setUploads}
          setAttachments={setAttachments}
          attachments={attachments}
          uploads={uploads}
        />
      </div>
      {/* Hydrates with comments passed in - then manages state on its own */}
      <CommentList
        requestId={data?.data.requestId}
        className={CLASSES.COMMENTS}
        creators={data?.data.creators}
        approvalRequirements={data?.data.approvalRequirements}
        commentPagination={data?.commentPagination}
        comments={data?.data.comments}
        user={user}
        isReviewer={viewState.isReviewer}
      />
      <div className={CLASSES.FOOTER}>
        <FlightButton
          className={CLASSES.FOOTER_BUTTON}
          onClick={() => {
            history.push(`/project/${pid}/${window.location.search}`);
          }}
          theme="secondary"
          label="Back"
        />
        {viewState.isReviewer &&
          (viewState.hasApproved ? (
            <FlightButton
              className={`${CLASSES.FOOTER_BUTTON} tertiary`}
              onClick={() => submitApproval(false)}
              theme="primary"
              label="Send back to in review"
            />
          ) : (
            <FlightButton
              className={CLASSES.FOOTER_BUTTON}
              onClick={() => setIsApprovalModalOpen(true)}
              theme="primary"
              label="Approve"
            />
          ))}
      </div>
      <ConfirmApproveRequestModal
        isVisible={isApprovalModalOpen}
        onApprove={() => {
          submitApproval(true);
          setIsApprovalModalOpen(false);
        }}
        onCancel={() => setIsApprovalModalOpen(false)}
      />
    </div>
  );
}

export default ApprovalRequestPage;
