import React from 'react';
import usePrioritizationContext from './usePrioritizationContext';
import PrioritizationContext from './PrioritizationContext';

const PrioritizationProvider = ({ children }: React.PropsWithChildren) => {
  const value = usePrioritizationContext();
  return <PrioritizationContext.Provider value={value}>{children}</PrioritizationContext.Provider>;
};

export default PrioritizationProvider;
