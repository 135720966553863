import React, { useState } from 'react';
import { FlightButton } from '@flybits/design-system';
import { ReactComponent as IconListGray } from 'pages/Zones/assets/icons/icon-list-gray-fill.svg';
import { ReactComponent as IconPinBlue } from 'pages/Zones/assets/icons/icon-pin-blue-fill.svg';
import { CLASSES } from 'pages/Zones/constants/classes';
import { ZoneConfig } from 'pages/Zones/types';
import ManagePinnedContent from './ManagePinnedContent/ManagePinnedContent';
import PinnedContentList from 'components/Zones/PinnedContentList/PinnedContentList';

type TPinnedContentTabProps = {
  zoneId: string;
  moduleId: string;
  zoneConfig: ZoneConfig;
  setZoneConfig: React.Dispatch<React.SetStateAction<ZoneConfig | undefined>>;
};

enum CURRENT_VIEW {
  MANAGE_PINNED_CONTENT = 'MANAGE_PINNED_CONTENT',
  LIST_PINNED_CONTENT = 'LIST_PINNED_CONTENT',
}

const PinnedContentTab: React.FC<TPinnedContentTabProps> = ({ zoneId, moduleId, zoneConfig, setZoneConfig }) => {
  const [currentView, setCurrentView] = useState(CURRENT_VIEW.LIST_PINNED_CONTENT);
  const zoneIndex = zoneConfig.zones.findIndex((zone) => zone.id === zoneId);
  const moduleIndex = zoneConfig.zones[zoneIndex].modules.findIndex((module) => module.id === moduleId);
  const modulePinnedContentIDs = zoneConfig?.zones?.[zoneIndex]?.modules?.[moduleIndex]?.pinnedContentIDs ?? [];

  return (
    <div className={CLASSES.PINNED_CONTENT}>
      {currentView === CURRENT_VIEW.LIST_PINNED_CONTENT && (
        <>
          <div className={`${CLASSES.PINNED_CONTENT}__header`}>
            <div className={`${CLASSES.PINNED_CONTENT}__header__add-content`}>
              <div className={`${CLASSES.PINNED_CONTENT}__header__add-content__icon`}>
                <IconPinBlue />
              </div>
              <div className={`${CLASSES.PINNED_CONTENT}__header__add-content__text`}>
                We recommend pinning{' '}
                <span className={`${CLASSES.PINNED_CONTENT}__header__add-content__text__bold`}>
                  1-3 content instances
                </span>{' '}
                for optimal campaign performance.
              </div>
              <div className={`${CLASSES.PINNED_CONTENT}__header__add-content__button`}>
                <FlightButton
                  iconLeft="add"
                  label="Add Content"
                  onClick={() => setCurrentView(CURRENT_VIEW.MANAGE_PINNED_CONTENT)}
                />
              </div>
            </div>
          </div>
          <div className={`${CLASSES.PINNED_CONTENT}__body`}>
            {modulePinnedContentIDs.length ? (
              <PinnedContentList
                contentIDs={modulePinnedContentIDs}
                zoneId={zoneId}
                moduleId={moduleId}
                zoneConfig={zoneConfig}
                setZoneConfig={setZoneConfig}
              />
            ) : (
              <div className={CLASSES.PLACEHOLDER}>
                <div className={`${CLASSES.PLACEHOLDER}__icon`}>
                  <IconListGray />
                </div>
                <div className={`${CLASSES.PLACEHOLDER}__text`}>Content you select will appear here</div>
              </div>
            )}
          </div>
        </>
      )}
      {currentView === CURRENT_VIEW.MANAGE_PINNED_CONTENT && (
        <ManagePinnedContent
          onBack={() => setCurrentView(CURRENT_VIEW.LIST_PINNED_CONTENT)}
          zoneId={zoneId}
          moduleId={moduleId}
          zoneConfig={zoneConfig}
          setZoneConfig={setZoneConfig}
        />
      )}
    </div>
  );
};

export default PinnedContentTab;
