import React from 'react';
import { TIconProps } from '../types';

function DeleteIcon({ fill = 'currentColor', className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33_2002)">
        <path
          d="M15 16H19V18H15V16ZM15 8H22V10H15V8ZM15 12H21V14H15V12ZM3 18C3 19.1 3.9 20 5 20H11C12.1 20 13 19.1 13 18V8H3V18ZM5 10H11V18H5V10ZM10 4H6L5 5H2V7H14V5H11L10 4Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_33_2002">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DeleteIcon;
