/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as NotificationItemIcon } from 'assets/icons/light-on-icon.svg';
import { ReactComponent as MarkAsRead } from 'assets/icons/mark_as-read-icon.svg';
import { ReactComponent as MarkAsUnRead } from 'assets/icons/mark_unread-icon.svg';

import moment from 'moment';
import PreferencesApi from 'services/api/preferences.api';
// TODO: Upgrade Skeleton to 3.1 for typescript 4 support
import Skeleton from 'react-loading-skeleton';
import { isEmpty, uniqBy } from 'lodash';
import useAsyncState from 'hooks/useAsyncState';

import './NotificationDropDown.scss';
import { FlightTooltip } from '@flybits/design-system';
import { useHistory } from 'react-router-dom';

interface StagingModelStatus {
  status: string;
  updatedAt: number;
}

interface NotificationDropDownProps {
  notificationData: any;
  displayCount: (number: number) => any;
  count: number;
}

interface PreferenceData {
  journeyId: string;
  read: boolean;
}

interface UserPrefApi {
  notifications: PreferenceData[];
}

interface NotificationContentProps {
  journeyId: string;
  journeyName: string;
  preferencesData: any;
  stagingModelStatus: StagingModelStatus;
  displayCount: (number: number) => any;
  count: number;
}

const NotificationContent = (props: NotificationContentProps) => {
  const preferencesApi = useMemo(() => new PreferencesApi(), []);

  const { journeyId, journeyName, stagingModelStatus, preferencesData, displayCount, count } = props;
  const [markIndicator, setMarkIndicator] = useState(true);
  const [showOnHover, setShowOnHover] = useState(false);
  const timeElapsed = moment.unix(stagingModelStatus.updatedAt).fromNow();
  const history = useHistory();
  const _prefData = preferencesData?.data?.notifications?.find((item: PreferenceData) => item?.journeyId === journeyId);

  useEffect(() => {
    if (_prefData) {
      setMarkIndicator(!_prefData?.read);
    }
    // eslint-disable-next-line
  }, [_prefData]);

  const handleUpdateReadStatus = (e: any, journeyId: string) => {
    e.stopPropagation();
    setMarkIndicator((markIndicator) => !markIndicator);
    if (_prefData) {
      preferencesData?.data?.notifications?.map((data: PreferenceData) => {
        if (data?.journeyId === journeyId) {
          data.read = markIndicator;
        }
        return preferencesData;
      });
      markIndicator ? displayCount(count - 1) : displayCount(count + 1);
    } else {
      if (preferencesData?.data?.notifications) {
        preferencesData?.data?.notifications.push({ journeyId, read: markIndicator });
        markIndicator ? displayCount(count - 1) : displayCount(count + 1);
      } else {
        preferencesData.data.notifications = [{ journeyId, read: markIndicator }];
        markIndicator ? displayCount(count - 1) : displayCount(count + 1);
      }
    }
    preferencesApi?.updateUserPreference(preferencesData.data);
  };

  const redirectToJourney = () => {
    // need to use window method to trigger a reload to enable refetching of requried data
    window.location.replace(
      `/project/${history?.location?.pathname?.split('/')?.[2]}/view/${journeyId}/?page=1&tab=performance#`,
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="notification-dropdown__container__content"
      onMouseEnter={() => setShowOnHover(true)}
      onMouseLeave={() => setShowOnHover(false)}
      onClick={redirectToJourney}
    >
      <NotificationItemIcon className="notification-dropdown__container__content__icon" />
      {markIndicator ? (
        <FlightTooltip
          className="notification-dropdown__container__content__tool-tip"
          direction="left"
          isEnabled={true}
          description={'Mark as read'}
        >
          <MarkAsRead
            className="notification-dropdown__container__content__read-indicator"
            onClick={(e) => handleUpdateReadStatus(e, journeyId)}
          />
        </FlightTooltip>
      ) : (
        <FlightTooltip
          className="notification-dropdown__container__content__tool-tip"
          direction="left"
          isEnabled={true}
          description={'Mark as unread'}
        >
          {showOnHover && (
            <MarkAsUnRead
              className="notification-dropdown__container__content__read-indicator"
              onClick={(e) => handleUpdateReadStatus(e, journeyId)}
            />
          )}
        </FlightTooltip>
      )}

      <span
        className={
          !markIndicator
            ? 'notification-dropdown__container__content__name'
            : 'notification-dropdown__container__content__name notification-dropdown__container__content__name--bold'
        }
      >
        Smart Targetting available for &apos;{journeyName}&apos;
      </span>
      <div className="notification-dropdown__container__content__timestamp">{timeElapsed}</div>
    </div>
  );
};

export default function NotificationDropDown(props: NotificationDropDownProps) {
  const preferencesApi = useMemo(() => new PreferencesApi(), []);

  const { notificationData, displayCount, count } = props;
  const today = moment().unix();
  const yesterday = moment().subtract(1, 'days').unix();
  const todaysNotifications: NotificationContentProps[] = [];
  const yesterdaysNotifications: NotificationContentProps[] = [];
  const olderNotifications: NotificationContentProps[] = [];
  const [preferencesData, isLoading] = useAsyncState(() => preferencesApi.getUserPreferences().then((res: any) => res));
  const [preferencesDataToRender, setPreferencesDataToRender] = useState<any>([]);
  const areAllEmpty = () => {
    if (isEmpty(todaysNotifications) && isEmpty(yesterdaysNotifications) && isEmpty(olderNotifications)) {
      return true;
    }
    return false;
  };

  notificationData?.map((trainingData: NotificationContentProps) => {
    if (trainingData?.stagingModelStatus?.status === 'Completed') {
      //we are only concerned with models that are ready
      if (trainingData?.stagingModelStatus?.updatedAt === today) {
        todaysNotifications?.push(trainingData);
      } else if (trainingData?.stagingModelStatus?.updatedAt === yesterday) {
        yesterdaysNotifications?.push(trainingData);
      } else {
        olderNotifications?.push(trainingData);
      }
    }
    return null;
  });

  const createEmptyRecordUserPref = async () => {
    const _createData: UserPrefApi = { notifications: [] };
    notificationData?.map((_data: NotificationContentProps) => {
      _createData.notifications.push({ journeyId: _data.journeyId, read: false });
      return _createData;
    });
    const resp = await preferencesApi?.createUserPreference(_createData);
    return resp;
  };

  useEffect(() => {
    if (preferencesDataToRender !== preferencesData) {
      setPreferencesDataToRender(preferencesData);
    }
    // eslint-disable-next-line
  }, [preferencesData]);

  useEffect(() => {
    // if user settings don't exist for the user, the api returns 404, we have to
    // create an empty record in this case
    if (!preferencesData) {
      createEmptyRecordUserPref();
    }
    // eslint-disable-next-line
  }, [!isLoading, preferencesData]);

  const handleMarkAllAsRead = async () => {
    displayCount(0);
    const _markAllAsRead: { journeyId: string; read: boolean }[] = [];
    if (
      isEmpty(preferencesData?.data.notifications) ||
      preferencesData?.data?.notifications?.length < notificationData?.length
    ) {
      notificationData?.map((_data: NotificationContentProps) => {
        _markAllAsRead.push({ journeyId: _data.journeyId, read: true });
        uniqBy(_markAllAsRead, 'journeyId');
        return _markAllAsRead;
      });
      preferencesData.data.notifications = _markAllAsRead;
    } else {
      preferencesData?.data?.notifications?.map((data: PreferenceData) => {
        data.read = true;
        return preferencesData;
      });
    }
    await preferencesApi?.updateUserPreference(preferencesData.data);
    const resp = await preferencesApi?.getUserPreferences();
    setPreferencesDataToRender(resp);
  };

  return (
    <span className="notification-dropdown">
      <div className="notification-dropdown__container">
        <div className="notification-dropdown__container__heading">Notifications</div>
        <div className="notification-dropdown__container__section__mark-all-read" onClick={handleMarkAllAsRead}>
          {!areAllEmpty() && 'Mark all as read'}
        </div>
        {/* {Render today's notifications} */}
        {!isEmpty(todaysNotifications) && <div className="notification-dropdown__container__section">Today</div>}
        {todaysNotifications
          ?.sort(function (arg1, arg2) {
            return arg2.stagingModelStatus.updatedAt - arg1.stagingModelStatus.updatedAt;
          })
          .map((item: NotificationContentProps) => {
            return (
              <div key={item.journeyId}>
                {!isLoading && preferencesData ? (
                  <NotificationContent
                    {...item}
                    preferencesData={preferencesData}
                    displayCount={displayCount}
                    count={count}
                  />
                ) : (
                  <Skeleton className="notification-dropdown__container__section__loading" width={'100%'} height={50} />
                )}{' '}
              </div>
            );
          })}
        {!isEmpty(yesterdaysNotifications) && (
          <div className="notification-dropdown__container__section">Yesterday</div>
        )}
        {/* {Render yesterdays notifications} */}
        {yesterdaysNotifications
          ?.sort(function (arg1, arg2) {
            return arg2.stagingModelStatus.updatedAt - arg1.stagingModelStatus.updatedAt;
          })
          .map((item: NotificationContentProps) => {
            return (
              <div key={item.journeyId}>
                {!isLoading && preferencesDataToRender ? (
                  <NotificationContent
                    {...item}
                    preferencesData={preferencesDataToRender}
                    displayCount={displayCount}
                    count={count}
                  />
                ) : (
                  <Skeleton className="notification-dropdown__container__section__loading" width={'100%'} height={50} />
                )}{' '}
              </div>
            );
          })}
        {/* {Render older notifications} */}
        {!isEmpty(olderNotifications) && (
          <>
            {' '}
            <div className="notification-dropdown__container__section">Older</div>{' '}
          </>
        )}
        {olderNotifications
          ?.sort(function (arg1, arg2) {
            return arg2.stagingModelStatus.updatedAt - arg1.stagingModelStatus.updatedAt;
          })
          ?.map((item: NotificationContentProps) => {
            return (
              <div key={item.journeyId}>
                {!isLoading && preferencesDataToRender ? (
                  <NotificationContent
                    {...item}
                    preferencesData={preferencesDataToRender}
                    displayCount={displayCount}
                    count={count}
                  />
                ) : (
                  <Skeleton className="notification-dropdown__container__section__loading" width={'100%'} height={50} />
                )}
              </div>
            );
          })}
        {areAllEmpty() ? (
          <span className="notification-dropdown__container__no-notifications">No Notifications to display</span>
        ) : null}
      </div>
    </span>
  );
}
