import React from 'react';
import { TIconProps } from '../types';

function DeactivateIcon({ fill, className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.68317 5L25.4109 15.1738C22.6818 15.6329 20.2529 16.9764 18.4289 18.8994L1.6665 16.6667L1.68317 5ZM17.049 20.6397C16.8986 20.8659 16.7546 21.0972 16.6165 21.3333L1.6665 23.3333L1.68317 35L15.1198 29.239C15.0407 28.6707 14.9998 28.0901 14.9998 27.5C14.9998 26.8577 15.0483 26.2268 15.1417 25.6106L4.99984 29.95V26.25L15.2739 24.8846C15.6015 23.3458 16.2122 21.9118 17.049 20.6397ZM5.0165 10.05L4.99984 13.75L17.5332 15.4167L5.0165 10.05Z"
        fill={fill}
      />
      <path
        d="M28.5187 18.332H26.4816V28.5172H28.5187V18.332ZM33.4381 20.5422L31.9918 21.9885C33.6011 23.282 34.6298 25.2681 34.6298 27.4987C34.6298 31.4404 31.4418 34.6283 27.5002 34.6283C23.5585 34.6283 20.3705 31.4404 20.3705 27.4987C20.3705 25.2681 21.3992 23.282 22.9983 21.9783L21.5622 20.5422C19.5863 22.2228 18.3335 24.708 18.3335 27.4987C18.3335 32.5607 22.4381 36.6654 27.5002 36.6654C32.5622 36.6654 36.6668 32.5607 36.6668 27.4987C36.6668 24.708 35.4141 22.2228 33.4381 20.5422Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default DeactivateIcon;
