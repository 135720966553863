import { Action, Experience, Step, StepMetadata } from 'interface/experience/experience.interface';
import {
  BatchInsertContentsAction,
  ChangePrimaryContentAction,
  ClearErrorAction,
  InsertPushAction,
  InsertRuleAction,
  RemoveContentAction,
  RemoveDependencyAction,
  RemovePushAction,
  RemoveRuleAction,
  RemoveStepAction,
  SaveRuleAction,
  UpdateJourneyAction,
  UpdatePushSimpleAction,
  UpdateStepAction,
  UpdateStepContentNamesAction,
  UpdateStepWebhookNamesAction,
} from 'store/actionTypes';
import {
  DEPENDENCY_NODE_TYPES,
  Dependency,
  DependencyGraph,
  DependencyNodeType,
  JOURNEY_STATUSES,
  JourneyState,
  RuleLookUpTable,
  StateStep,
} from 'store/journey/journey.type';
import { SetInstanceAction, SetTemplateAction } from 'store/templated-experience/templated-experience.type';
import {
  createReducer,
  getContentActionsFromStep,
  getPushActionFromStep,
  getScheduleOmissionsFromStep,
  getStepRuleSyntheticId,
  getWebhookActionFromStep,
  updateObject,
} from 'store/helper';

import { Dependencies } from 'interface/common.interface';
import { isEmpty } from 'lodash';
import { v4 } from 'uuid';
// TODO: for some reason importing helper here completely breaks HMR even though no import cycles are detected ...
// import { genUUID } from 'helpers/common.helper';

function _isJourneyStatus(val: string): val is JOURNEY_STATUSES {
  return Object.values<string>(JOURNEY_STATUSES).includes(val);
}

const initialState: JourneyState = {
  steps: [],
  status: JOURNEY_STATUSES.INITIAL,
};

function _generateJourneyStep(step: Step, idx: number, stepMetadata?: StepMetadata[]) {
  const rule = {
    restricted: step.audience.restricted?.refId ?? '',
    preferred: step.audience.preferred?.refId ?? '',
  };

  const push = getPushActionFromStep(step)?.refId ?? '';
  const content = getContentActionsFromStep(step).map((contentAction) => contentAction.refId);
  const webhook = getWebhookActionFromStep(step)?.refId ?? '';
  const scheduleOmissionDefaults = getScheduleOmissionsFromStep(step);

  return {
    name: step.name ?? stepMetadata?.[idx]?.name,
    desc: step.desc ?? stepMetadata?.[idx]?.desc,
    iconUrl: step.iconUrl ?? stepMetadata?.[idx]?.iconUrl,
    isLocked: step.isLocked ?? stepMetadata?.[idx]?.isLocked,
    type: step.type,
    constraints: step.constraints,
    rule,
    push,
    content,
    webhook,
    isDirty: true,
    experience: step.experience?.experienceId,
    ...scheduleOmissionDefaults,
    // Conditionally add the following properties (until proposed branch flow structure is prod-ready)
    ...(step.id ? { id: step.id } : {}),
    ...(step.type ? { type: step.type } : {}),
    ...(step.children ? { children: step.children } : {}),
  };
}

function setInstance(state: JourneyState, action: SetInstanceAction) {
  if (!action.payload.updateNewReducer) return state;
  if (!action.payload?.instance) return state;
  const templateType = action.payload?.instance?.metadata?.templateType;

  let stepMetadata: StepMetadata[] = [];
  try {
    stepMetadata = JSON.parse(action.payload?.instance?.metadata?.stepMetadata ?? '[]');
  } catch (e) {}

  return updateObject(state, {
    ...action.payload?.instance,
    status: _isJourneyStatus(action.payload?.instance?.status ?? '')
      ? (action.payload.instance.status as JOURNEY_STATUSES) // this version of ts sucks
      : JOURNEY_STATUSES.DRAFT,
    templateType,
    steps:
      action.payload?.instance?.steps.map((step: Step, idx: number): StateStep => {
        return _generateJourneyStep(step, idx, stepMetadata);
      }) || [],
    // i guess this function ends up doing two crawls of the journey instance,
    // but its probably fine since this function doesn't run all that often
    dependencyGraph: _generateDependencyGraph(action.payload?.instance),
  });
}

function setTemplate(state: JourneyState, action: SetTemplateAction) {
  if (!action.payload?.template) return state;
  const template = action.payload.template;
  const templateName = template.name;
  let stepMetadata: StepMetadata[] = [];
  try {
    stepMetadata = JSON.parse(template.metadata?.stepMetadata ?? '[]');
  } catch (e) {}

  if (action.payload.preserveInstance) {
    return {
      ...state,
      templateName,
      // we should implement real constraints if we want this.
      steps: state.steps.map((step, idx) => {
        const { rule, push, content, webhook } = _generateJourneyStep(template.steps[idx], idx, stepMetadata);
        return {
          ...step,
          type: step.type,
          constraints: step.constraints,
          rule: {
            preferred: step.rule.preferred || rule.preferred,
            restricted: step.rule.restricted || rule.restricted,
          },
          push: step.push || push,
          content: [...step.content].concat(content.filter((c) => !step.content.includes(c))),
          webhook: step.webhook || webhook,
          isRequired: !!template?.steps?.[idx],
        };
      }),
      dependencyGraph: _generateDependencyGraph(template, true, state.dependencyGraph),
    };
  }
  const templateType = template.metadata?.templateType;

  return updateObject(state, {
    ...template,
    name: '',
    id: undefined,
    status: JOURNEY_STATUSES.DRAFT,
    activatedAt: 0,
    createdAt: 0,
    deletedAt: 0,
    updatedAt: 0,
    templateId: template.id,
    templateType,
    templateName,
    steps:
      template.steps.map((step, idx): StateStep => {
        return {
          ..._generateJourneyStep(step, idx, stepMetadata),
          isRequired: true,
          isDirty: false,
        };
      }) ?? [],
    // i guess this function ends up doing two crawls of the journey instance,
    // but its probably fine since this function doesn't run all that often
    dependencyGraph: _generateDependencyGraph(template, true),
    metadata: {
      ...template.metadata,
      filesUUID: v4().toUpperCase(),
      templateName: templateName ?? '',
    },
  });
}

function insertStep(state: JourneyState) {
  return {
    ...state,
    steps: [
      ...state.steps,
      {
        push: '',
        rule: { restricted: '', preferred: '' },
        content: [],
        isRequired: false,
        omitSched: false,
        omitStart: true,
        omitEnd: true,
      },
    ],
  };
}

function insertRule(state: JourneyState, action: InsertRuleAction) {
  const stepIdx = action.payload.stepIdx;
  const newRefId = action.payload.refId;
  const synthId = getStepRuleSyntheticId(stepIdx);

  if (stepIdx === undefined) return state;

  const depGraph = state.dependencyGraph ?? {};
  const stepPush = state.steps[stepIdx].push;
  if (stepPush) {
    depGraph[stepPush].requires.push({ var: '{{dep.rule-id.0}}', refId: synthId });
    depGraph[synthId].isRefBy.push(stepPush);
  }

  return {
    ...state,
    dependencyGraph: depGraph,
    steps: state.steps.map((step: StateStep, idx: number) => {
      if (idx === stepIdx) {
        return {
          ...step,
          rule: {
            ...step.rule,
            [action.payload.type]: newRefId,
          },
        };
      }
      return step;
    }),
  };
}

function updateRule(state: JourneyState, action: SaveRuleAction) {
  if (action.payload.stepIdx === undefined || !action.payload.refId) {
    return state;
  }

  return {
    ...state,
    steps: state.steps.map((item: StateStep, idx: number) => {
      if (idx === action.payload.stepIdx) {
        return {
          ...item,
          rule: {
            ...state.steps[idx].rule,
            [action.payload.type]: action.payload.refId,
          },
        };
      }
      return item;
    }),
  };
}

function updatePush(state: JourneyState, action: UpdatePushSimpleAction) {
  const pushPayloadType = action.payload.fields.pushPayloadType;
  const stepIdx = action.payload.stepIdx;

  if (stepIdx === undefined || !pushPayloadType) return state;

  const pushRefId = action.payload.refId;
  const contentId = action.payload.fields.contentId;

  const dg = state.dependencyGraph ?? {};
  const contentRefId =
    dg[pushRefId].requires.find((dep) => dep.var.includes('content'))?.refId ?? state.steps[stepIdx].content[0];

  if (pushPayloadType === 'content' && contentId && contentRefId) {
    _upsertDGNode(stepIdx, dg, pushRefId, DEPENDENCY_NODE_TYPES.ACTION, {
      [contentRefId]: contentId,
    });
  }
  if (pushPayloadType !== 'content') {
    const oldContentRefId = dg[pushRefId].requires.find((dep) => dep.var.includes('content'))?.refId;
    if (oldContentRefId && dg[oldContentRefId]) _deleteDGNode(dg, oldContentRefId, true);
  }

  return {
    ...state,
    dependencyGraph: dg,
  };
}

function insertPush(state: JourneyState, action: InsertPushAction) {
  const stepIdx = action.payload.stepIdx;
  const refId = action.payload.defaults.refId;
  const dependencies = action.payload.dependencies;
  if (stepIdx === undefined || !refId) return state;

  const dg = state.dependencyGraph ?? {};

  _insertDGNode(stepIdx, dg, refId, DEPENDENCY_NODE_TYPES.ACTION, dependencies);

  return {
    ...state,
    steps: state.steps.map((item: StateStep, idx: number) => {
      if (idx === stepIdx) {
        return {
          ...item,
          push: refId,
        };
      }
      return item;
    }),
    dependencyGraph: dg,
  };
}

function insertContents(state: JourneyState, action: BatchInsertContentsAction) {
  const stepIdx = action.payload.stepIdx;
  const refIds = action.payload.refIds;
  const dependantPushRefId = action.payload.dependantPushRefId;
  if (stepIdx === undefined || !refIds.length) return state;

  const dg = state.dependencyGraph ?? {};

  refIds.forEach((refId) => _insertDGNode(stepIdx, dg, refId, DEPENDENCY_NODE_TYPES.ACTION));
  if (dependantPushRefId) {
    _upsertDGNode(stepIdx, dg, dependantPushRefId, DEPENDENCY_NODE_TYPES.ACTION, {
      [refIds[0]]: '{{dep.content-id.0}}',
    });
  }

  return {
    ...state,
    steps: state.steps.map((item: StateStep, idx: number) => {
      if (idx === stepIdx) {
        return {
          ...item,
          content: [...item.content, ...refIds],
        };
      }
      return item;
    }),
    dependencyGraph: dg,
  };
}

function removeRule(state: JourneyState, action: RemoveRuleAction) {
  const stepIdx = action.payload.stepIdx || 0;
  const newDG = state.dependencyGraph;
  if (action.payload.wipeDependencies && newDG) {
    const synthId = getStepRuleSyntheticId(stepIdx);
    _deleteDGNode(newDG, synthId);
  }

  return {
    ...state,
    steps: state.steps.map((step: StateStep, idx) => {
      const isActionableStep = idx === stepIdx;
      return {
        ...step,
        rule: {
          ...step.rule,
          [action.payload.type]: isActionableStep ? '' : step.rule.restricted,
        },
      };
    }),
    dependencyGraph: newDG,
  };
}

function removeContent(state: JourneyState, action: RemoveContentAction) {
  const stepIdx = action.payload.stepIdx || 0;
  const refIdsToDelete: string[] = [];

  action.payload.refIds.forEach((refId, idx) => {
    if (!action.payload.required?.[idx]) refIdsToDelete.push(refId);
  });

  const dg = state.dependencyGraph ?? {};
  refIdsToDelete.forEach((refId) => _deleteDGNode(dg, refId));

  return {
    ...state,
    steps: state.steps.map((step: StateStep, idx) => {
      return {
        ...step,
        content:
          idx === stepIdx
            ? state.steps[stepIdx]?.content.filter((refId) => !refIdsToDelete.includes(refId))
            : step.content,
      };
    }),
  };
}

function removePush(state: JourneyState, action: RemovePushAction) {
  const stepIdx = action.payload.stepIdx || 0;

  return {
    ...state,
    steps: state.steps.map((step: StateStep, idx) => {
      return {
        ...step,
        push: idx === stepIdx ? '' : step.push,
      };
    }),
  };
}

function removeStep(state: JourneyState, action: RemoveStepAction) {
  const removedIndex = action.payload.stepIdx;
  const refIdsToDelete: string[] = [];

  if (action.payload.push) refIdsToDelete.push(action.payload.push);
  if (action.payload.rule.preferred || action.payload.rule.restricted)
    refIdsToDelete.push(getStepRuleSyntheticId(removedIndex));
  action.payload.content.forEach((refId) => refId && refIdsToDelete.push(refId));

  const dg = state.dependencyGraph ?? {};
  refIdsToDelete.forEach((refId) => _deleteDGNode(dg, refId));

  return {
    ...state,
    steps: state.steps.filter((_, idx) => idx !== removedIndex),
    dg,
  };
}

function removeDependency(state: JourneyState, action: RemoveDependencyAction) {
  const dg = state.dependencyGraph ?? {};
  const entityRefId = action.payload.entityRefId;
  const dependencyRefId = action.payload.dependencyRefId;

  if (dg[entityRefId]) {
    dg[entityRefId].requires = dg[entityRefId].requires.filter((dep) => dep.refId !== dependencyRefId);
  }
  if (dg[dependencyRefId]) {
    dg[dependencyRefId].isRefBy = dg[dependencyRefId].isRefBy.filter((refId) => refId !== entityRefId);
  }

  return {
    ...state,
    dependencyGraph: dg,
  };
}

function updateJourney(state: JourneyState, action: UpdateJourneyAction) {
  return {
    ...state,
    ...action.payload,
  };
}

function clearJourney() {
  return initialState;
}

function clearError(state: JourneyState, action: ClearErrorAction) {
  return {
    ...state,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: state.errors?.filter((_: any, idx: number) => idx !== action.payload.errorIdx),
  };
}

function changePrimaryContent(state: JourneyState, action: ChangePrimaryContentAction) {
  const { newPrimaryRefId, oldPrimaryRefId, affectedEntities, stepIdx } = action.payload;

  if (newPrimaryRefId === oldPrimaryRefId) return state;

  if (affectedEntities.length < 1)
    return {
      ...state,
      steps: state.steps.map((step: StateStep, idx) => {
        return {
          ...step,
          content:
            idx === stepIdx
              ? [newPrimaryRefId, ...step.content.filter((refId) => refId !== newPrimaryRefId)]
              : step.content,
        };
      }),
    };

  const dg = state.dependencyGraph ?? {};

  affectedEntities.forEach((affected) => _swapDependency(dg, oldPrimaryRefId, newPrimaryRefId, affected));

  return {
    ...state,
    dependencyGraph: dg,
  };
}

function updateStep(state: JourneyState, action: UpdateStepAction) {
  const stepIdx = action.payload.stepIdx;
  const stepFields = action.payload.fields;
  const stepMetadata: StepMetadata[] = JSON.parse(state.metadata?.stepMetadata || '[]');
  const stepMetadataFields = ['name', 'desc', 'iconUrl', 'isLocked'];
  const stepFieldKeys = Object.keys(stepFields);

  let updatedStepMetadata: StepMetadata[] = [...stepMetadata];

  stepFieldKeys.forEach((key) => {
    if (stepMetadataFields.includes(key)) {
      updatedStepMetadata = [
        ...stepMetadata?.slice(0, stepIdx),
        { ...stepMetadata[stepIdx], [key]: stepFields[key] },
        ...stepMetadata?.slice(stepIdx + 1),
      ];
    }
  });

  return {
    ...state,
    steps: state.steps.map((step: StateStep, idx) => {
      if (idx !== stepIdx) return step;
      return {
        ...step,
        ...stepFields,
      };
    }),
    metadata: {
      ...state.metadata,
      stepMetadata: JSON.stringify(updatedStepMetadata),
    },
  };
}

function updateStepContentName(state: JourneyState, action: UpdateStepContentNamesAction) {
  const steps = state.steps;
  const { stepIndex, contentNodes } = action.payload;
  let stepsMetadata: StepMetadata[] = JSON.parse(state.metadata?.stepMetadata || '[]');

  if (isEmpty(stepsMetadata)) {
    stepsMetadata = [...Array(steps.length).fill({})];
  }

  if (isEmpty(stepsMetadata[stepIndex])) {
    stepsMetadata[stepIndex] = {
      name: '',
      desc: '',
      contentNodes,
    };
  }

  stepsMetadata[stepIndex].contentNodes = contentNodes;

  return {
    ...state,
    metadata: {
      ...state.metadata,
      stepMetadata: JSON.stringify(stepsMetadata),
    },
  };
}

function updateStepWebhookName(state: JourneyState, action: UpdateStepWebhookNamesAction) {
  const steps = state.steps;
  const { stepIndex, webhookNodes } = action.payload;
  let stepsMetadata: StepMetadata[] = JSON.parse(state.metadata?.stepMetadata || '[]');

  if (isEmpty(stepsMetadata)) {
    stepsMetadata = [...Array(steps.length).fill({})];
  }

  if (isEmpty(stepsMetadata[stepIndex])) {
    stepsMetadata[stepIndex] = {
      name: '',
      desc: '',
      webhookNodes,
    };
  }

  stepsMetadata[stepIndex].webhookNodes = webhookNodes;

  return {
    ...state,
    metadata: {
      ...state.metadata,
      stepMetadata: JSON.stringify(stepsMetadata),
    },
  };
}

// TODO: Support recalculating dependencies from other actions
function _generateDependencyGraph(journey: Experience, isTemplate = false, dg: DependencyGraph = {}) {
  const ruleLUT: RuleLookUpTable = {};
  // generate refId -> syntheticId map
  journey?.steps?.forEach((step, idx) => {
    const restrictedRefId = step.audience.restricted?.refId;
    const preferredRefId = step.audience.preferred?.refId;
    if (restrictedRefId) ruleLUT[restrictedRefId] = getStepRuleSyntheticId(idx);
    if (preferredRefId) ruleLUT[preferredRefId] = getStepRuleSyntheticId(idx);
  });
  journey?.steps?.forEach((step: Step, idx) => {
    _upsertDGNode(
      idx,
      dg,
      getStepRuleSyntheticId(idx),
      DEPENDENCY_NODE_TYPES.RULE,
      step.audience.restricted?.dependencies,
      ruleLUT,
    );
    _upsertDGNode(
      idx,
      dg,
      getStepRuleSyntheticId(idx),
      DEPENDENCY_NODE_TYPES.RULE,
      step.audience.preferred?.dependencies,
      ruleLUT,
    );
    step.actions?.forEach((action: Action) => {
      _upsertDGNode(idx, dg, action.refId, DEPENDENCY_NODE_TYPES.ACTION, action.dependencies, ruleLUT, isTemplate);
    });
  });

  return dg;
}

const _generateDGNode = (
  stepIdx: number,
  dg: DependencyGraph,
  refId: string,
  type: DependencyNodeType,
  requires?: Dependency[],
) => {
  if (!dg[refId]) dg[refId] = { stepIdx, requires: requires ?? [], isRefBy: [], type };
};

const _insertDGNode = (
  stepIdx: number,
  dg: DependencyGraph,
  refId: string,
  type: DependencyNodeType,
  requires?: Dependency[],
) => {
  if (dg[refId]) return;

  _generateDGNode(stepIdx, dg, refId, DEPENDENCY_NODE_TYPES.ACTION, requires);

  if (!requires) return;

  requires.forEach((dependency) => {
    if (dg[dependency.refId]) {
      dg[dependency.refId].isRefBy.push(refId);
    }
  });
};

const _upsertDGNode = (
  stepIdx: number,
  dg: DependencyGraph,
  refId?: string,
  type: DependencyNodeType = DEPENDENCY_NODE_TYPES.ACTION,
  dependencies?: Dependencies,
  ruleLUT?: RuleLookUpTable,
  isTemplate = false,
) => {
  if (!refId) return;
  _generateDGNode(stepIdx, dg, refId, type);

  if (dependencies) {
    for (const [key, value] of Object.entries(dependencies)) {
      const syntheticKey = (ruleLUT && ruleLUT[key]) || key;
      // prevent redundant inserts
      if (!dg[refId].requires.find((dep) => dep.var === value && dep.refId === syntheticKey))
        dg[refId].requires.push({ var: value, refId: syntheticKey, isTemplate });
      _upsertDGNode(
        stepIdx,
        dg,
        syntheticKey,
        syntheticKey === key ? DEPENDENCY_NODE_TYPES.RULE : DEPENDENCY_NODE_TYPES.ACTION,
      );
      if (!dg[syntheticKey].isRefBy.find((ref) => ref === refId)) dg[syntheticKey].isRefBy.push(refId);
    }
  }
};

const _deleteDGNode = (dg: DependencyGraph, refId?: string, softDelete = false) => {
  if (!refId) return;
  const nodeToBeDeleted = dg[refId];
  if (!nodeToBeDeleted) return;

  nodeToBeDeleted.isRefBy.forEach((refOfRef) => {
    if (dg[refOfRef])
      dg[refOfRef].requires = dg[refOfRef].requires.filter((dep) => {
        return dep.refId !== refId;
      });
  });
  nodeToBeDeleted.requires.forEach((dependency) => {
    if (dg[dependency.refId])
      dg[dependency.refId].isRefBy = dg[dependency.refId].isRefBy.filter((depRef) => {
        return depRef !== refId;
      });
  });

  if (softDelete) {
    nodeToBeDeleted.isRefBy = [];
    nodeToBeDeleted.requires = [];
  } else delete dg[refId];
};

const _swapDependency = (
  dg: DependencyGraph,
  oldRefId: string,
  newRefId: string,
  affected: { refId: string; var: string },
) => {
  if (!oldRefId) {
    dg[affected.refId].requires.push({ var: affected.var, refId: newRefId });
    dg[newRefId].isRefBy.push(affected.refId);

    return;
  }

  const oldDGNode = dg[oldRefId];
  const affectedNode = dg[affected.refId];
  if (!oldDGNode || !affectedNode) return; //bogus refId

  const dependencyIndex = affectedNode.requires.findIndex((dep) => dep.refId === oldRefId);
  if (dependencyIndex >= 0) {
    dg[affected.refId].requires[dependencyIndex].refId = newRefId;
    dg[newRefId].isRefBy.push(affected.refId);
  }

  dg[oldRefId].isRefBy = dg[oldRefId].isRefBy.filter((refId) => refId !== affected.refId);
};

export default createReducer(initialState, {
  SET_INSTANCE: setInstance,
  SET_TEMPLATE: setTemplate,
  INSERT_RULE: insertRule,
  INSERT_STEP: insertStep,
  UPDATE_RULE: updateRule,
  UPDATE_PUSH_SIMPLE: updatePush,
  INSERT_PUSH: insertPush,
  INSERT_CONTENTS: insertContents,
  REMOVE_CONTENT: removeContent,
  REMOVE_PUSH: removePush,
  REMOVE_RULE: removeRule,
  REMOVE_STEP: removeStep,
  REMOVE_DEPENDENCY: removeDependency,
  UPDATE_JOURNEY: updateJourney,
  UPDATE_STEP: updateStep,
  UPDATE_STEP_CONTENT_NAME: updateStepContentName,
  UPDATE_STEP_WEBHOOK_NAME: updateStepWebhookName,
  CLEAR_JOURNEY: clearJourney,
  CLEAR_ERROR: clearError,
  CHANGE_PRIMARY_CONTENT: changePrimaryContent,
});
