import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { FlightTable, FlightTextInput, getIcon, FlightDateRangePicker } from '@flybits/design-system';
import { useQuery } from '@tanstack/react-query';
import useDebounce from 'hooks/useDebounce';
import AnalyticsExperiencesAPI from 'services/api/analytics-experiences.api';
import { CLASSES } from '../constants';
import { AdvancedDateRange, DateRange } from '../types';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import './Engagement.scss';
import FilterByLabel from 'components/Analytics/FilterByLabel/FilterByLabel';
import { LABEL_RULE } from 'components/Analytics/types';

const tableHeaders = [
  {
    name: 'key',
    key: 'key',
    isVisible: false,
    isSortable: false,
  },
  {
    name: 'Name',
    key: 'name',
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Status',
    key: 'status',
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Total reach',
    key: 'total_reach',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The total number of times this particular rule was fulfilled, within the time range and labels you have selected.',
  },
  {
    name: 'Unique reach',
    key: 'unique_reach',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The number of unique users who have fulfilled this particular rule, within the time range and labels you have selected.',
  },
];

const fetchContextRules = async (
  analyticsExperiencesApi: AnalyticsExperiencesAPI,
  page: number,
  perPage: number,
  dateRange: DateRange<Date>,
  search: string,
  labels: string,
  sortBy: string,
  sortOrder: string,
) => {
  const res = await analyticsExperiencesApi.getContextRulesEngagement({
    lastFrom: Math.trunc((dateRange[0]?.getTime() ?? new Date().setHours(0, 0, 0, 0)) / 1000),
    lastTo: Math.trunc((dateRange[1]?.getTime() ?? new Date().setHours(23, 59, 59, 999)) / 1000),
    offset: (page - 1) * perPage,
    limit: perPage,
    search,
    labelsFormula: labels ? `(${labels})` : undefined,
    sortBy,
    sortOrder,
  });

  return {
    contextRules: res.data || [],
    pagination: res.pagination,
  };
};

const ContextRules = () => {
  const analyticsExperiencesApi = useMemo(() => new AnalyticsExperiencesAPI(), []);

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [dateRange, setDateRange] = useState<AdvancedDateRange>([startOfToday, new Date(), 0, 'day']);
  const [queryDateRange, setQueryDateRange] = useState<AdvancedDateRange>([startOfToday, endOfDay]);
  const [search, setSearch] = useState('');
  const [labels, setLabels] = useState<string[]>([]);
  const [labelRule, setLabelRule] = useState<LABEL_RULE>();
  const labelString = labelRule === LABEL_RULE.CONTAINS_ALL_OF ? labels.join(',') : labels.join(';');
  const [sortByColumn, setSortByColumn] = useState('unique_reach');
  const [sortOrder, setSortOrder] = useState('desc');

  const debouncedSearch = useDebounce(search, 300);
  const { data, status, isFetching, isLoading } = useQuery({
    queryKey: ['context-rules-engagement', currentPage, perPage, queryDateRange, debouncedSearch, labelString],
    queryFn: () =>
      fetchContextRules(
        analyticsExperiencesApi,
        currentPage,
        perPage,
        [queryDateRange[0], queryDateRange[1]],
        debouncedSearch,
        labelString,
        sortByColumn,
        sortOrder,
      ),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setCurrentPage(1);
    setSearch(value);
  };

  const handleDateRangeChange = (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
    const hasFullRange = newDate[0] !== null && newDate[1] !== null;

    if (hasFullRange) {
      const endOfDay = new Date(newDate[1] ?? new Date());
      if (newDate.length < 3 || (newDate.length >= 3 && newDate[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59);
      }

      setQueryDateRange([newDate[0], endOfDay]);
    }
  };

  const handleLabelSelect = (selectedLabels: string[], labelRule: LABEL_RULE) => {
    setLabels(selectedLabels);
    setLabelRule(labelRule);
  };

  const handleHeaderSort = (e: { key: string }) => {
    if (e.key !== sortByColumn) {
      setSortByColumn(e.key);
      setSortOrder('desc');
    } else {
      setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    }
  };

  return (
    <div className={CLASSES.CARD}>
      <div className={CLASSES.CARD_HEADER}>
        <h2>Context rules</h2>
        <div className={CLASSES.DATE_RANGE}>
          {dateRange[2] === 0
            ? `${moment().format('MMM DD, YYYY')}`
            : `${moment(dateRange[0]).format('MMM DD, YYYY')} - ${moment(dateRange[1]).format('MMM DD, YYYY')}`}
        </div>
        <div>
          <FlightDateRangePicker
            className={CLASSES.DATE_RANGE_DD}
            value={dateRange}
            onChange={handleDateRangeChange}
            maxDate={new Date()}
            includeAllTime={false}
            includeToday
            isIncludeCustom
            replaceCustomRangeLabel={false}
          />
        </div>
        <FilterByLabel onLabelSelect={handleLabelSelect} />
      </div>
      {isLoading && (
        <div className={CLASSES.SPINNER}>
          <LoadingIcon />
        </div>
      )}
      <div className={CLASSES.SEARCH_WRAPPER}>
        <FlightTextInput
          onChange={handleSearchChange}
          value={search}
          placeholderText="Search"
          iconInput="search"
          width="280px"
          hasClearIcon
        />
      </div>
      {status === 'success' && (
        <>
          <div className={CLASSES.TABLE_WRAPPER}>
            {labels.length > 0 && (
              <div className={CLASSES.LABEL_LIST}>
                {'Labels: '}
                {labelRule === LABEL_RULE.CONTAINS_ALL_OF ? 'contains all of ' : 'contains any of '}
                {labels.map((label) => (
                  <span className={CLASSES.LABEL} key={label}>
                    {label}
                  </span>
                ))}
              </div>
            )}
            <FlightTable
              tableHeaders={tableHeaders}
              tableData={data.contextRules.map((contextRule) => ({
                key: contextRule.ruleId,
                name: contextRule.ruleName,
                status: contextRule.status,
                total_reach: contextRule.totalReach.toLocaleString(),
                unique_reach: contextRule.uniqueReach.toLocaleString(),
              }))}
              sortByKey={sortByColumn}
              sortOrder={sortOrder}
              handleHeaderSort={handleHeaderSort}
              hasPaginationBeforeTable={false}
              hasPaginationAfterTable={!!data.contextRules.length}
              paginationProps={{
                totalPageNumber: Math.ceil(data.pagination.totalRecords / perPage) || 1,
                currentPageNumber: currentPage,
                rowsPerPageOptions: [5, 10, 15, 20],
                currentRowsPerPage: perPage,
                handlePageChange: (page: number) => setCurrentPage(page),
                handleRowsPerPageChange: (num: number) => setPerPage(num),
              }}
              isLoading={isFetching}
            />
            {!!!data?.contextRules?.length && !isFetching && (
              <div className={CLASSES.EMPTY_DATA}>
                {getIcon('search', {})}
                No results found!
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ContextRules;
