import React from 'react';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import useJourneyStatus from 'hooks/useJourneyStatus';
import { TTimelineBoxProps } from 'pages/ExperienceCanvas/types';
import { getContentGrouping } from 'store/journey/journey.selector';
import TouchpointEditorCard from 'components/Shared/TouchpointEditorCard/TouchpointEditorCard';
import InAppContentIcon from 'pages/ExperienceCanvas/assets/icons/InAppContentIcon';

import './ContentGroupingBox.scss';

const CLASS_PREFIX = 'content-editor-box';
const LIST_PREFIX = 'content-list';
const CLASSES = {
  MAIN: CLASS_PREFIX,
  INSTRUCTIONS: `${CLASS_PREFIX}__instructions`,
  LIST: LIST_PREFIX,
  CONTENT_LIST: `${LIST_PREFIX}__list`,
  CONTENT: `${LIST_PREFIX}__content`,
  CONTENT_TITLE: `${LIST_PREFIX}__content-name`,
};

export default function ContentGroupingBox({ stepIdx, timelineIndex }: TTimelineBoxProps) {
  const { isJourneyLocked } = useJourneyStatus();
  const [operator, filters] = useSelector((state) => {
    const groupingRefId = getContentGrouping(state, stepIdx);
    const groupingAction = groupingRefId ? state.te.content.byRefId[groupingRefId] : undefined;
    const groupingFilter = groupingAction?.constraints?.groupingFilter;

    return [groupingFilter?.operator, groupingFilter?.filter.map((item) => `${item.key} is ${item.value}`)];
  });

  const operatorText = operator ? `${operator === 'AND' ? 'ALL' : 'ANY'} filter condition(s) must be true.` : '';

  return (
    <TouchpointEditorCard
      title="Content Grouping Filters"
      icon={InAppContentIcon}
      timelineIndex={timelineIndex}
      className={CLASSES.MAIN}
      isLocked={isJourneyLocked}
    >
      <>
        <div className={CLASSES.LIST}>
          <div className={CLASSES.CONTENT_LIST}>
            <div className={CLASSES.CONTENT}>
              <div className={CLASSES.CONTENT_TITLE}>Name</div>
            </div>
            {filters && filters.length > 0 ? (
              filters.map((filter, i) => (
                <div key={`s${stepIdx}-cgf-${i}`} className={CLASSES.CONTENT}>
                  {' '}
                  <div className={CLASSES.CONTENT_TITLE}>{filter}</div>
                </div>
              ))
            ) : (
              <div className={CLASSES.CONTENT}>
                <div className={CLASSES.CONTENT_TITLE}> No filters configured!! </div>
              </div>
            )}
          </div>
        </div>
        <p className={CLASSES.INSTRUCTIONS}>{operatorText}</p>
        <p className={CLASSES.INSTRUCTIONS}>Contents filtered by these labels may change.</p>
      </>
    </TouchpointEditorCard>
  );
}
