import React from 'react';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { numberWithCommas } from 'helpers/templated-experience.helper';
import STLoadingState from '../STLoadingState/STLoadingState';
import STExperienceFunnelGraph from './STExperienceFunnelGraph';
import STErrorState from '../STErrorState/STErrorState';
import './STExperienceFunnel.scss';

interface Metric {
  id: string;
  label: string;
  color: string;
  value?: number;
}

const JOURNEY_TYPES_METRICS: Record<string, Metric[]> = {
  IN_APP_CONTENT: [
    {
      id: 'targetAudience',
      label: 'Target Audience',
      color: '#A999FF',
    },
    {
      id: 'viewedContentInApp',
      label: 'Viewed Content',
      color: '#50E8BB',
    },
    {
      id: 'clickedOnContentInApp',
      label: 'Clicked On Content',
      color: '#F65D7E',
    },
  ],
  PUSH_BROADCAST: [
    {
      id: 'targetAudience',
      label: 'Target Audience',
      color: '#A999FF',
    },
    {
      id: 'pushSent',
      label: 'Push Sent',
      color: '#7CB4FF',
    },
    {
      id: 'pushDelivered',
      label: 'Push Delivered',
      color: '#72DAFF',
    },
    {
      id: 'openedPush',
      label: 'Opened Push',
      color: '#51E7BB',
    },
  ],
  PUSH_BROADCAST_CONTENT: [
    {
      id: 'targetAudience',
      label: 'Target Audience',
      color: '#A999FF',
    },
    {
      id: 'pushSent',
      label: 'Push Sent',
      color: '#7CB4FF',
    },
    {
      id: 'pushDelivered',
      label: 'Push Delivered',
      color: '#72DAFF',
    },
    {
      id: 'openedPush',
      label: 'Opened Push',
      color: '#51E7BB',
    },
    {
      id: 'clickedOnContentFromPush',
      label: 'Clicked On Content',
      color: '#F65D7E',
    },
  ],
  TRIGGERED_PUSH: [
    {
      id: 'targetAudience',
      label: 'Target Audience',
      color: '#A999FF',
    },
    {
      id: 'pushSent',
      label: 'Push Sent',
      color: '#7CB4FF',
    },
    {
      id: 'pushDelivered',
      label: 'Push Delivered',
      color: '#72DAFF',
    },
    {
      id: 'openedPush',
      label: 'Opened Push',
      color: '#51E7BB',
    },
  ],
  TRIGGERED_PUSH_CONTENT: [
    {
      id: 'targetAudience',
      label: 'Target Audience',
      color: '#A999FF',
    },
    {
      id: 'pushSent',
      label: 'Push Sent',
      color: '#7CB4FF',
    },
    {
      id: 'pushDelivered',
      label: 'Push Delivered',
      color: '#72DAFF',
    },
    {
      id: 'openedPush',
      label: 'Opened Push',
      color: '#51E7BB',
    },
    {
      id: 'clickedOnContentFromPush',
      label: 'Clicked On Content',
      color: '#F65D7E',
    },
  ],
};

export default function STExperienceFunnel(props: { journeyType: string }) {
  const reduxAnalyticsState = useSelector((state) => state.analytics.analyticsFunnel);
  type FunnelDataKey = keyof typeof reduxAnalyticsState;

  const metrics = [...JOURNEY_TYPES_METRICS[props.journeyType]];
  let maxMetricValue = 0;
  metrics.forEach((metric) => {
    const dataKey = metric.id as FunnelDataKey;
    metric.value = reduxAnalyticsState[dataKey]?.value || 0;
    maxMetricValue = Math.max(maxMetricValue, metric.value || 0);
  });
  const funnelSpacingValue = maxMetricValue ? maxMetricValue : 10;
  // Value used in the right side is different positions the labels should be placed in the Graph
  const userLabelYAxis = metrics.length ? -funnelSpacingValue / 5 : 0;
  const targetAudiencePercentLabel = metrics.length ? funnelSpacingValue + funnelSpacingValue / 20 : 0;
  const titleLabelYAxis = metrics.length ? -funnelSpacingValue / 8 : 0;
  const differencePercentLabelAxis = metrics.length ? funnelSpacingValue / 5 : 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const barGraphData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labelPercentData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labelTitleData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const differencePercentData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const labelUserData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const areaSeriesData: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emptyFunnelLabelData: any[] = [];

  for (let i = 0; i < metrics.length; i++) {
    if (i === 0) {
      labelPercentData.push({
        x: i,
        y: targetAudiencePercentLabel,
        label: metrics[0].value ? '100%' : '',
        yOffset: 5,
      });
      emptyFunnelLabelData.push({
        x: 0,
        y: 0,
        label: metrics[0].value ? '' : '0%',
        yOffset: -10,
      });
    } else {
      const labelPercentValue = metrics[0].value
        ? `${parseFloat((((metrics[i]?.value || 0) / metrics[0].value) * 100).toFixed(1))}%`
        : '0%';
      labelPercentData.push({
        x: i,
        y: metrics[i].value,
        label: labelPercentValue,
        yOffset: -10,
      });
      differencePercentData.push({
        x: i,
        y: differencePercentLabelAxis,
        label: metrics[i - 1].value
          ? `${parseFloat((((metrics[i]?.value || 0) / (metrics[i - 1]?.value || 0)) * 100 || 0).toFixed(1))}%`
          : '0%',
      });
    }
    labelTitleData.push({
      x: i,
      y: titleLabelYAxis,
      label: metrics[i].label,
    });
    labelUserData.push({
      x: i,
      y: userLabelYAxis,
      label: numberWithCommas(metrics[i]?.value || 0) + ' users',
    });
    barGraphData.push({
      x: i,
      y: metrics[i].value,
      color: metrics[i].color,
    });
    //Area Series in our Graph requies two points per instance and value of x will depend up the width of the bar.
    areaSeriesData.push({
      x: i - 0.13,
      y: metrics[i].value,
    });
    areaSeriesData.push({
      x: i + 0.13,
      y: metrics[i].value,
    });
  }

  return (
    <div className="st-experience-funnel-container">
      <p className="headline"> Experience Funnel </p>
      {!!reduxAnalyticsState.errorMessage ? (
        <STErrorState errorMessage={reduxAnalyticsState.errorMessage} />
      ) : reduxAnalyticsState.isTargetAudienceLoading ||
        reduxAnalyticsState.isPushSentLoading ||
        reduxAnalyticsState.isOpenedPushLoading ||
        reduxAnalyticsState.isPushDeliveredLoading ||
        reduxAnalyticsState.isClickedOnContentFromPushLoading ||
        reduxAnalyticsState.isViewedContentInAppLoading ||
        reduxAnalyticsState.isClickedOnContentInAppLoading ? (
        <STLoadingState />
      ) : (
        <STExperienceFunnelGraph
          areaSeriesData={areaSeriesData}
          barGraphData={barGraphData}
          labelTitleData={labelTitleData}
          labelUserData={labelUserData}
          labelPercentData={labelPercentData}
          differencePercentData={differencePercentData}
          emptyFunnelLabelData={emptyFunnelLabelData}
        />
      )}
    </div>
  );
}
