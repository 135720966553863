import React, { useState } from 'react';

import { useAppSelector as useSelector } from 'hooks/reduxHooks';

import { StepMetadata } from 'interface/experience/experience.interface';

import TouchpointCard from 'components/ExperienceCanvas/TouchpointCard/TouchpointCard';
import { FlightButton } from '@flybits/design-system';

import './TouchpointCardCarousel.scss';

const MAIN_CLASS = 'touchpoint-card-carousel';
const CLASSES = {
  CONTROLS: `${MAIN_CLASS}__controls`,
  PAGINATION: `${MAIN_CLASS}__controls__pagination`,
  PAGINATION_BULLET: `${MAIN_CLASS}__controls__pagination__bullet`,
};

// TODO: consider adding a no-actions state, making the callbacks optional,
// and adding an error state
const TouchpointCardCarousel: React.FC = () => {
  const journeyInstance = useSelector((state) => state.te.journey);
  const parsedJourneyMetadata = JSON.parse(journeyInstance.metadata?.stepMetadata ?? '[]') as StepMetadata[];

  const [currentStep, setCurrentStep] = useState(0);

  return (
    <div className={MAIN_CLASS}>
      <TouchpointCard
        stepIdx={currentStep}
        title={journeyInstance.steps[currentStep]?.name ?? `Touchpoint ${currentStep + 1}`}
        subtitle={journeyInstance.steps[currentStep]?.desc}
        showFooter={false}
        iconUrl={parsedJourneyMetadata[currentStep]?.iconUrl}
        onEdit={() => null}
        onDelete={() => null}
        onClickFooter={() => null}
      />
      <div className={CLASSES.CONTROLS}>
        <FlightButton
          iconLeft="baselineKeyboardArrowLeft"
          onClick={() =>
            setCurrentStep((prev) => (journeyInstance.steps.length ? (prev - 1) % journeyInstance.steps.length : 0))
          }
          theme="secondary"
          label=""
          ariaLabel="view previous touchpoint"
        />
        {/*TODO: Paginate this better*/}
        <div className={CLASSES.PAGINATION}>
          {journeyInstance.steps.map((step, idx) => (
            <div
              key={`tpc-pagination-${idx}`}
              className={`${CLASSES.PAGINATION_BULLET}${idx === currentStep ? ' selected' : ''}`}
            ></div>
          ))}
        </div>
        <FlightButton
          iconLeft="baselineKeyboardArrowRight"
          onClick={() =>
            setCurrentStep((prev) => (journeyInstance.steps.length ? (prev + 1) % journeyInstance.steps.length : 0))
          }
          theme="secondary"
          ariaLabel="view next touchpoint"
        />
      </div>
    </div>
  );
};

export default TouchpointCardCarousel;
