/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './Graphs.scss';
import { ResponsiveBar } from '@nivo/bar';
import { EngagementModuleData } from 'interface/templated-experience/analytics.interface';
type TGraphProps = {
  data: Record<string, never>[] | EngagementModuleData[];
  keys: string[];
  indexBy: string;
  axisBottom?: string;
  axisLeft?: string;
  graphMargin?: { top: number; right: number; bottom: number; left: number };
  layout?: 'horizontal' | 'vertical' | undefined;
  reverse?: boolean;
  grouped?: boolean;
  isLegendVisible?: boolean;
};
const minMax = (arr: Record<string, never>[], limit = 6) => {
  if (arr.some((item: Record<string, never>) => typeof item.value !== 'number')) return false;
  const min = Math.min(...arr.map((item: Record<string, never>) => item.value));
  const max = Math.max(...arr.map((item: Record<string, never>) => item.value));
  const returnArray = range(0, max, (max - min) / (limit - 1));
  returnArray.push(max);
  return returnArray;
};
const range = (start: number, stop: number, step = 5) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => Math.floor(start + i * step));
const roundToX = (num = 0, decimals = 2) => Math.round(num * 10 ** decimals) / 10 ** decimals;

export default function BarGraph(props: TGraphProps) {
  const maxChars = 140;
  const formattedData: Record<string, string | number>[] = props.data.map(({ name, value }) => ({
    name: name || props.axisBottom || '',
    value: typeof value === 'number' ? roundToX(value) : value,
  }));
  const renderResponsiveBar = () => (
    <ResponsiveBar
      data={formattedData}
      keys={props.keys}
      indexBy={props.indexBy}
      margin={{ top: 8, right: 20, bottom: 30, left: maxChars }}
      padding={0.3}
      layout={props.layout ? props.layout : 'vertical'}
      reverse={props.reverse ? props.reverse : false}
      groupMode={props.grouped ? 'grouped' : undefined}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      labelSkipWidth={30}
      labelSkipHeight={30}
      colors={{ scheme: 'paired' }}
      colorBy="indexValue"
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: props.axisBottom,
        legendPosition: 'middle',
        legendOffset: 40,
        truncateTickAt: 2,
        tickValues: 6,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: props.axisLeft,
        legendPosition: 'middle',
        legendOffset: maxChars * -1 + 5,
        truncateTickAt: 25,
        tickValues: 6,
      }}
      tooltipLabel={(d) => `${d.indexValue}`}
      enableLabel
      enableGridX
      enableGridY
      isInteractive
      role="application"
      animate
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      ariaLabel={`Bar chart: ${props.axisLeft} by ${props.axisBottom}`}
      barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in ${props.axisBottom}: ${e.indexValue}`}
    />
  );
  return <div className="stream-chart">{renderResponsiveBar()}</div>;
}
