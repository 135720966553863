/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';

import Slider from '@material-ui/core/Slider';

import AOApi from 'services/api/ao.api';
import { AudienceOptimization } from 'interface/experience/experience.interface';
import { SmartTargetingMetrics, OptimizationPoint } from 'interface/smart-targeting/smart-targeting.interface';
import { updateJourneyAO } from 'store/journey/journey.thunk';

import SmartTargetingIcon from 'pages/ExperienceCanvas/assets/icons/SmartTargetingIcon';

import { FlightButton, FlightSnackbar, getIcon } from '@flybits/design-system';
import {
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { trackEvent } from 'helpers/analytics.helper';
import Skeleton from 'react-loading-skeleton';
import { isEmpty } from 'lodash';
import './ProjectedPerformance.scss';

type TProjectedPerformanceProps = {
  metrics: SmartTargetingMetrics;
  isTrainingNewModel: boolean;
  setIsTrainingNewModel: (val: boolean) => void;
  targetEngagementRate: number;
  setTargetEngagementRate: (val: number) => void;
};

const MAIN_CLASS = 'projected-performance';

export default function ProjectedPerformanceChart({
  metrics,
  isTrainingNewModel,
  setIsTrainingNewModel,
  targetEngagementRate,
  setTargetEngagementRate,
}: TProjectedPerformanceProps) {
  const AoAPI = useMemo(() => new AOApi(), []);

  const dispatch = useDispatch();
  const journeyInstance = useSelector((state) => state.te.journey);
  const [isLoading, setIsLoading] = useState(true);
  const [activeGraphData, setActiveGraphData] = useState<Array<OptimizationPoint>>([]);
  const [candidateGraphData, setCandidateGraphData] = useState<Array<OptimizationPoint>>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [sliderValue, setSliderValue] = useState(0);
  const [prevSliderValue, setPrevSliderValue] = useState(0);
  const [targetAudienceReach, setTargetAudienceReach] = useState(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  interface ChartData {
    x: number;
    y: number;
    xOptim: number;
    yOptim: number;
  }

  // initial load
  useEffect(() => {
    setActiveGraphData(metrics.activeModelMetrics.optimizationGraph || []);
    setCandidateGraphData(metrics.candidateModelMetrics.optimizationGraph || []);
    setChartData(
      generateDataPoints(metrics.activeModelMetrics.optimizationGraph, metrics.candidateModelMetrics.optimizationGraph),
    );
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics, isTrainingNewModel]);

  useEffect(() => {
    const sliderAudienceReach = journeyInstance?.audienceOptimization?.automationBudget?.cutOffPercentage || 0;
    setTargetAudienceReach(sliderAudienceReach);
    setTargetEngagementRate(getSliderEngagementRate(true, sliderAudienceReach));
    setSliderValue(sliderAudienceReach);
    setPrevSliderValue(sliderAudienceReach);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    if (isTrainingNewModel) setIsEditing(false);
  }, [isTrainingNewModel]);

  function scrollToCTA() {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.querySelector(`.${MAIN_CLASS}__control`)!.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSliderChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
  };

  // from this component, the journey mode can only be updated to automation
  async function updateJourneyMode() {
    // cutOffPercentage represents the audience reach
    const updatedAOPayload: AudienceOptimization = {
      ...journeyInstance.audienceOptimization,
      audienceOptimizationEligible: true,
      isEnabled: true,
      journeyMode: 'automation',
      automationBudget: {
        ...journeyInstance.audienceOptimization?.automationBudget,
        cutOffPercentage: getSliderAudienceReach(),
      },
    };

    const deployModel = async () => {
      if (!isEmpty(candidateGraphData) && !isTrainingNewModel && journeyInstance.id) {
        await AoAPI.deployModel(journeyInstance.id).then(() => {
          setTargetEngagementRate(getSliderEngagementRate(true));
          setChartData(generateDataPoints(candidateGraphData, []));
          setCandidateGraphData([]);
          setActiveGraphData(candidateGraphData);
          trackEvent({
            category: 'Audience Optimization',
            action: 'Apply Updated Model',
          });
        });
      }
    };

    await dispatch(updateJourneyAO(updatedAOPayload, deployModel));
  }

  function generateDataPoints(
    optimizationGraph: Array<OptimizationPoint> | null,
    candidateOptimizationGraph: Array<OptimizationPoint> | null,
  ) {
    if (!isEmpty(optimizationGraph)) {
      return optimizationGraph?.map((numb: { pointX: number; pointY: number }, idx: number) => {
        let xOptim = 0;
        let yOptim = 0;
        if (!isEmpty(candidateOptimizationGraph)) {
          const candPoint = candidateOptimizationGraph?.find(
            (candItem: { pointX: number; pointY: number }) => candItem.pointX === numb.pointX,
          );
          if (candPoint) {
            xOptim = Math.ceil(candPoint.pointX * 100);
            yOptim = Math.ceil(candPoint.pointY * 100);
          }
        }
        return {
          percentage: idx + 1,
          x: Math.ceil(optimizationGraph?.[idx]?.pointX * 100),
          y: Math.ceil(optimizationGraph?.[idx]?.pointY * 100),
          xOptim,
          yOptim,
        };
      });
    }
    if (!isEmpty(candidateOptimizationGraph) && isEmpty(optimizationGraph)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return candidateOptimizationGraph?.map((numb: any, idx: number) => {
        return {
          percentage: idx + 1,
          xOptim: Math.ceil(candidateOptimizationGraph?.[idx]?.pointX * 100),
          yOptim: Math.ceil(candidateOptimizationGraph?.[idx]?.pointY * 100),
        };
      });
    }
  }

  const renderCoordinateBox = () => {
    return (
      <div className="coordinate-container">
        <div className="row__bold">
          <p>Engagement Rate</p>
          <p>{getSliderEngagementRate()}%</p>
        </div>
        <div className="row">
          <p>Audience Reach</p>
          <p>{sliderValue}%</p>
        </div>
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTooltipLabel = (props: any) => {
    // eslint-disable-next-line react/prop-types
    const x = props.viewBox.x;
    // eslint-disable-next-line react/prop-types
    const y = props.viewBox.y;
    const points = `${x},${y - 8} ${x + 10},${y - 8} ${x + 5},${y}`;
    return (
      <g>
        <rect x={x - 15} y={y - 26} fill="#051336" width={40} height={20} rx={2} />
        <polygon points={points} fill="#051336" />
        <text x={x + 5} y={y - 24} fill="white" dy={12} fontSize={12} textAnchor="middle">
          {getSliderEngagementRate()}%
        </text>
      </g>
    );
  };

  const renderChart = (
    chartData: ChartData[],
    activeGraphData: OptimizationPoint[],
    candidateGraphData: OptimizationPoint[],
  ) => {
    return (
      <div className="chart">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData} margin={{ top: 30, left: 10, bottom: 20 }}>
            <XAxis
              label={{ value: 'Audience reach', position: 'bottom', className: 'label' }}
              dataKey={!isEmpty(activeGraphData) ? 'x' : 'xOptim'}
              tickLine={false}
              ticks={[0, 100]}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              tickFormatter={(e: any) => (e > 0 ? e + '%' : '')}
              type={'number'}
              dx={-5}
            />
            <YAxis
              label={{ value: 'Engagement rate', position: 'insideLeft', angle: -90, className: 'label' }}
              ticks={[0, 100]}
              width={80}
              dataKey={!isEmpty(activeGraphData) ? 'y' : 'yOptim'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              tickFormatter={(e: any) => e + '%'}
              tickLine={false}
              type={'number'}
            />
            <Legend
              align="left"
              verticalAlign="top"
              height={60}
              margin={{ top: 0 }}
              payload={[
                {
                  value: !!activeGraphData?.length && 'Active model',
                  type: 'line',
                  color: activeGraphData?.length ? (isEditing ? '#B9D9FF' : '#0851DF') : '',
                  id: 'legend1',
                },
                {
                  value:
                    !!candidateGraphData?.length &&
                    (activeGraphData?.length ? 'Updated model' : 'Engagement tradeoff frontier'),
                  type: 'line',
                  color: candidateGraphData?.length ? '#C405B9' : '',
                  id: 'legend2',
                },
                {
                  value: targetEngagementRate && !!activeGraphData?.length ? 'Target audience' : '',
                  type: 'circle',
                  color:
                    targetEngagementRate && !!activeGraphData?.length ? (isEditing ? '#6434DC' : '#0851DF') : '#FFFFFF',
                  id: 'legend3',
                },
              ]}
            />
            {targetAudienceReach && (
              <ReferenceArea
                x1={0}
                x2={targetAudienceReach}
                y1={0}
                y2={100}
                className="reference-area"
                fill="#DAF6FF"
                fillOpacity={1}
              />
            )}
            {targetAudienceReach && <ReferenceLine x={targetAudienceReach} stroke="#1194DA" strokeOpacity={1} />}
            <Line
              type="monotone"
              dataKey="y"
              stroke={isEditing ? '#B9D9FF' : '#0851DF'}
              fillOpacity={0}
              className="line"
              dot={false}
              strokeWidth={2}
            />
            {!isEmpty(candidateGraphData) && (
              <Line
                type="monotone"
                dataKey="yOptim"
                stroke="#C405B9"
                fillOpacity={0}
                className="line"
                dot={false}
                strokeWidth={2}
              />
            )}
            {/* Updated Audience Target Point */}
            {!!activeGraphData?.length && (
              <ReferenceDot
                y={targetEngagementRate}
                x={targetAudienceReach}
                r={5}
                fill={isEditing ? '#B9D9FF' : '#0851DF'}
                fillOpacity={1}
                stroke="#0851DF"
                strokeWidth="10"
                strokeOpacity={0.3}
              />
            )}
            {sliderValue && isEditing && (
              <ReferenceDot
                y={getSliderEngagementRate()}
                x={sliderValue}
                r={5}
                fill={'#6434DC'}
                fillOpacity={1}
                stroke="#6434DC"
                strokeWidth="10"
                strokeOpacity={0.5}
                label={isEditing ? renderTooltipLabel : undefined}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
        {isTrainingNewModel && (
          <div>
            <div className="chart__overlay"></div>
            <div className="chart__warning">
              <div className={'performance-loading-state__icon'}>{getIcon('loading', {})}</div>
              <div className="chart__warning__text">Updating performance curve</div>
              <FlightButton theme="link" label={'Cancel'} onClick={() => onCancelModelTraining()} />
            </div>
          </div>
        )}
      </div>
    );
  };

  const getSliderEngagementRate = (useActive?: boolean, reach?: number) => {
    const audienceReach = reach || getSliderAudienceReach();
    const isOptim = !isEmpty(candidateGraphData) && !useActive;
    const engagement = chartData?.find(
      (point: ChartData) => (isOptim && point.xOptim === audienceReach) || (!isOptim && point.x === audienceReach),
    );
    return engagement ? (isOptim ? engagement.yOptim : engagement.y) : 0;
  };

  const getSliderAudienceReach = () => {
    const reach = sliderValue || 0;
    return reach;
  };

  const onCancelModelTraining = async () => {
    if (journeyInstance.id) {
      await AoAPI.cancelTraining(journeyInstance.id).then(() => {
        setIsTrainingNewModel(false);
      });
    }
  };

  if (isLoading) {
    return <Skeleton width={'100%'} height={500} />;
  } else if (journeyInstance.status !== 'draft' && (!isEmpty(activeGraphData) || !isEmpty(candidateGraphData))) {
    return (
      <div className={`${MAIN_CLASS}`}>
        <div className={`${MAIN_CLASS}__main`}>
          {
            <div className="top">
              <p className="headline"> Projected smart targeting performance </p>
              <FlightButton
                theme="minor"
                iconLeft="target"
                label={'Modify Target'}
                className={`graph-button${isEditing || isTrainingNewModel ? `--disabled` : ''}`}
                disabled={isEditing || isTrainingNewModel}
                onClick={() => {
                  const sliderAudienceReach = getSliderAudienceReach();
                  setSliderValue(sliderAudienceReach);
                  setIsEditing(true);
                  scrollToCTA();
                }}
              />
            </div>
          }
          <div className="status">
            {/* If audience optimization has a value */}
            {journeyInstance?.audienceOptimization?.journeyMode === 'automation' &&
              !isEditing &&
              !isTrainingNewModel && (
                <FlightSnackbar
                  className="snackbar"
                  isVisible={true}
                  isFloating={false}
                  type="info"
                  content={`Actively optimizing audience targeting to reach a ${getSliderEngagementRate(
                    true,
                  )}% engagement rate`}
                  isAutoDismiss={true}
                  actionName="Modify"
                  action={() => {
                    setIsEditing(true);
                    const sliderAudienceReach = getSliderAudienceReach();
                    setSliderValue(sliderAudienceReach);
                    setPrevSliderValue(sliderAudienceReach);
                    scrollToCTA();
                  }}
                />
              )}
          </div>
          {isEditing && renderCoordinateBox()}
          {renderChart(chartData, activeGraphData, candidateGraphData)}
        </div>
        {isEditing && !isTrainingNewModel && (
          <div className={`${MAIN_CLASS}__control`}>
            <hr></hr>
            <div className={`${MAIN_CLASS}__control-header`}>
              <div className={`${MAIN_CLASS}__control-header-text`}>
                <SmartTargetingIcon />
                <p>Modify Target</p>
              </div>
              <FlightButton
                theme="minor"
                onClick={() => {
                  setIsEditing(false);
                  setSliderValue(prevSliderValue);
                }}
                iconLeft="clear"
                label=""
                ariaLabel="close modification panel"
              />
            </div>
            <p>
              <b>Drag</b> to modify your audience reach objective.
            </p>
            <div className="range-slider">
              <p>0%</p>
              <Slider
                onChange={handleSliderChange}
                min={0}
                max={100}
                value={sliderValue}
                aria-label="slider"
                marks={chartData.map((point: ChartData) => {
                  return {
                    value: !isEmpty(candidateGraphData) ? point.xOptim : point.x,
                    label: '',
                  };
                })}
                step={null}
              />
              <p>100%</p>
            </div>
            <div className="button-actions">
              <div>
                <FlightButton
                  theme="secondary"
                  label={'Cancel'}
                  onClick={() => {
                    setIsEditing(false);
                    setSliderValue(prevSliderValue);
                  }}
                />
                <FlightButton
                  label={`Confirm`}
                  onClick={() => {
                    setIsEditing(false);
                    setPrevSliderValue(sliderValue);
                    updateJourneyMode();
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="no-data">
        <div className={`${MAIN_CLASS}__no-data__center`}>
          <p>Check back soon as we are gathering the data for Smart Targeting.</p>
          <p>
            A minimum of <span>{journeyInstance.audienceOptimization?.explorationBudget?.populationSize || 100}</span>{' '}
            qualified user responses are required to generate accurate results.
          </p>
        </div>
      </div>
    );
  }
}
