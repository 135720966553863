import React from 'react';
import { IconProps } from './icon.types';

function IconSchedule(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame" clipPath="url(#clip0_1071_104339)">
        <g id="Group">
          <path
            id="Vector"
            d="M26 0H6C4.40978 0.00352209 2.88571 0.636794 1.76125 1.76125C0.636794 2.88571 0.00352209 4.40978 0 6L0 26C0.00352209 27.5902 0.636794 29.1143 1.76125 30.2388C2.88571 31.3632 4.40978 31.9965 6 32H26C27.5902 31.9965 29.1143 31.3632 30.2388 30.2388C31.3632 29.1143 31.9965 27.5902 32 26V6C31.9965 4.40978 31.3632 2.88571 30.2388 1.76125C29.1143 0.636794 27.5902 0.00352209 26 0ZM27.3333 16C27.3333 18.2415 26.6686 20.4327 25.4233 22.2965C24.178 24.1602 22.408 25.6128 20.3371 26.4706C18.2662 27.3284 15.9874 27.5529 13.789 27.1156C11.5905 26.6783 9.57112 25.5989 7.98612 24.0139C6.40113 22.4289 5.32173 20.4095 4.88443 18.211C4.44713 16.0126 4.67157 13.7338 5.52937 11.6629C6.38716 9.59202 7.83978 7.822 9.70354 6.57668C11.5673 5.33136 13.7585 4.66667 16 4.66667C19.0058 4.66667 21.8885 5.86071 24.0139 7.98612C26.1393 10.1115 27.3333 12.9942 27.3333 16Z"
            fill="#FF9C02"
          />
          <path
            id="Vector_2"
            d="M20 15.3333H16.6667V9.33333C16.6667 8.97971 16.5262 8.64057 16.2761 8.39052C16.0261 8.14048 15.687 8 15.3333 8C14.9797 8 14.6406 8.14048 14.3905 8.39052C14.1405 8.64057 14 8.97971 14 9.33333V16.6667C14 17.0203 14.1405 17.3594 14.3905 17.6095C14.6406 17.8595 14.9797 18 15.3333 18H20C20.3536 18 20.6928 17.8595 20.9428 17.6095C21.1929 17.3594 21.3333 17.0203 21.3333 16.6667C21.3333 16.313 21.1929 15.9739 20.9428 15.7239C20.6928 15.4738 20.3536 15.3333 20 15.3333Z"
            fill="#FF9C02"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1071_104339">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconSchedule;
