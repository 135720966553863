import {
  SettingActionType,
  SettingState,
  SetLanguagesAction,
  SetPreviewLanguageAction,
} from 'store/settings/settings.type';
import { ActionTypes } from 'store/actionTypes';

const initialState: SettingState = {
  languages: [],
  isInitialized: false,
  previewTextDirection: 'ltr',
  previewTextLanguage: 'en',
};

export default (state: SettingState = initialState, action: SetLanguagesAction & SetPreviewLanguageAction) => {
  switch (action.type) {
    case SettingActionType.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload.languages,
        isInitialized: true,
        previewTextDirection: action.payload.previewTextDirection,
        previewTextLanguage: action.payload.previewTextLanguage,
      };
    case SettingActionType.SET_PREVIEW_LANGUAGE:
      return {
        ...state,
        previewTextDirection: action.payload.previewTextDirection,
        previewTextLanguage: action.payload.previewTextLanguage,
      };
    case ActionTypes.CLEAR_JOURNEY:
      const defaultLang = state.languages.find((lang) => lang.isDefault);
      return {
        ...state,
        previewTextDirection: defaultLang?.direction || initialState.previewTextDirection,
        previewTextLanguage: defaultLang?.id || initialState.previewTextLanguage,
      };
    default:
      return {
        ...state,
      };
  }
};
