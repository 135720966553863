import { useCallback, useEffect, useState } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { fetchTemplate, fetchInstance, duplicateInstance } from 'store/journey/journey.thunk';
import { JourneyException } from 'types/errors';

/**
 * Selects the currently active journey instance on the canvas.
 * If it has not been fetched, fetches the instance and/or template.
 * */
export default function useJourneyInstance(journeyId: string, isTemplate: boolean, createFromId = '') {
  const journeyInstance = useSelector((state) => state.te.journey);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<JourneyException | null>(null);

  const fetch = useCallback(
    async (showLoading = true) => {
      setLoading(showLoading);

      try {
        // uninitialized journey and createFromId => duplicate
        if (!journeyInstance.id && createFromId) {
          const _journeyInstance = await dispatch(duplicateInstance(createFromId, journeyId));
          if (_journeyInstance?.templateId) {
            dispatch(fetchTemplate(_journeyInstance.templateId));
          }
          // uninitialized journey and isTemplate => create from template
        } else if (!journeyInstance.id && isTemplate === true) {
          await dispatch(fetchTemplate(journeyId, false));
          // edit
        } else {
          const _journeyInstance = await dispatch(fetchInstance(journeyInstance.id || journeyId));
          if (_journeyInstance?.templateId) {
            dispatch(fetchTemplate(_journeyInstance.templateId));
          }
        }
      } catch (e) {
        setError(e as JourneyException);
      } finally {
        setLoading(false);
      }
    },
    [isTemplate, journeyId, journeyInstance, dispatch, createFromId],
  );

  useEffect(() => {
    if (!journeyId || (!!journeyInstance.steps.length && !journeyInstance.id) || journeyId === journeyInstance.id)
      return;

    fetch();
  }, [journeyId, journeyInstance, fetch]);

  return { journeyInstance, isLoading, fetch, error };
}
