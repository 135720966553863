/* eslint-disable @typescript-eslint/no-explicit-any */

// Advanced
import React from 'react';
import { getDeliveryFormOptions } from 'store/push/push.selector';
import { selectStepRule } from 'store/rule/rule.selector';
import { convertPeriodToSeconds } from 'helpers/templated-experience.helper';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useForm from 'hooks/useSimpleForm';
import { FlightNumberInput, FlightSelect, FlightToggleSwitch } from '@flybits/design-system';
import { PERIODS, FORM_WIDTHS as WIDTH } from 'components/ExperienceCanvas/constants';

type TAdvancedFormProps = {
  classPrefix: string;
  stepIdx: number;
};

enum FieldKeys {
  MAX = 'max',
  FREQ = 'frequency',
  FREQ_P = 'frequencyPeriod',
  LIMIT = 'limit',
  LIMIT_EN = 'limitEnabled',
  REMAIN = 'remain',
  DELAY = 'delay',
  DELAY_P = 'delayPeriod',
}

export default function AdvancedForm({ classPrefix = '', stepIdx }: TAdvancedFormProps) {
  const dispatch = useDispatch();
  const pushRefId = useSelector((state) => state.te.journey.steps[stepIdx]?.push ?? '');
  const ruleRefId = useSelector((state) => selectStepRule(state.te, stepIdx)?.refId ?? '');
  const [deliveryValues, constraints] = useSelector((state) => {
    const pushRefId = state.te.journey.steps[stepIdx]?.push ?? '';

    return [getDeliveryFormOptions(state.te, pushRefId, stepIdx), state.te.push.byRefId[pushRefId]?.constraints];
  });

  const { formData, updateField } = useForm({
    data: {
      [FieldKeys.MAX]: { value: deliveryValues.max },
      [FieldKeys.FREQ]: { value: deliveryValues.frequency.value },
      [FieldKeys.FREQ_P]: { value: deliveryValues.frequency.period },
      [FieldKeys.LIMIT_EN]: { value: deliveryValues.limitCheck === 'SELECTED' },
      [FieldKeys.LIMIT]: { value: deliveryValues.limit.key },
      [FieldKeys.REMAIN]: { value: deliveryValues.remain === 'SELECTED' },
      [FieldKeys.DELAY]: { value: deliveryValues.delay.value },
      [FieldKeys.DELAY_P]: { value: deliveryValues.delay.period },
    },
  });

  const handleToggleSwitch = (key: FieldKeys.LIMIT_EN | FieldKeys.REMAIN) => {
    if (key === FieldKeys.LIMIT_EN) {
      if (formData[FieldKeys.LIMIT].value !== '') {
        dispatch({
          type: 'UPDATE_PUSH_SIMPLE',
          payload: {
            refId: pushRefId,
            fields: { limit: formData[key].value === true ? 0 : parseInt(formData[FieldKeys.LIMIT].value) },
          },
        });
      }
    } else {
      dispatch({
        type: 'UPDATE_RULE_SIMPLE',
        payload: {
          refId: ruleRefId,
          fields: { emitEveryEvaluationResult: !formData[key].value },
        },
      });
    }
    updateField(key, !formData[key].value);
  };

  const handleSelect = (key: FieldKeys.FREQ_P | FieldKeys.DELAY_P, option: any) => {
    let value: any;
    let stateAttrKey: string;

    switch (key) {
      case FieldKeys.FREQ_P:
        value = convertPeriodToSeconds(formData[FieldKeys.FREQ].value, option.key);
        stateAttrKey = 'cooldown';
        break;
      case FieldKeys.DELAY_P:
        value = convertPeriodToSeconds(formData[FieldKeys.DELAY].value, option.key);
        stateAttrKey = FieldKeys.DELAY;
        break;
    }
    if (value === undefined || stateAttrKey === undefined) return;
    updateField(key, option);
    dispatch({
      type: 'UPDATE_PUSH_SIMPLE',
      payload: {
        refId: pushRefId,
        fields: { [stateAttrKey]: value },
      },
    });
  };

  const handleInput = (key: FieldKeys.MAX | FieldKeys.LIMIT | FieldKeys.FREQ | FieldKeys.DELAY, value: any) => {
    const val = parseInt(value);
    const isBlank = value === '';
    if (!isBlank && isNaN(val)) return;
    updateField(key, isBlank ? '' : val);
  };

  const handleBlur = (key: FieldKeys.MAX | FieldKeys.LIMIT | FieldKeys.FREQ | FieldKeys.DELAY) => {
    if (formData[key].value === '') return;

    let value: any;
    let stateAttrKey = '';

    switch (key) {
      case FieldKeys.MAX:
      case FieldKeys.LIMIT:
        value = parseInt(formData[key].value);
        stateAttrKey = key;
        break;
      case FieldKeys.FREQ:
        value = convertPeriodToSeconds(formData[key].value, formData[FieldKeys.FREQ_P].value.key);
        stateAttrKey = 'cooldown';
        break;
      case FieldKeys.DELAY:
        value = convertPeriodToSeconds(formData[key].value, formData[FieldKeys.DELAY_P].value.key);
        stateAttrKey = key;
        break;
    }

    if (stateAttrKey === '') return;

    dispatch({
      type: 'UPDATE_PUSH_SIMPLE',
      payload: {
        refId: pushRefId,
        fields: { [stateAttrKey]: value },
      },
    });
  };

  // toggle swtiches will wipe the fields (for now)
  return (
    <>
      <div className={`${classPrefix}__advanced-title`}> Notification Limit </div>
      <div className={`${classPrefix}__advanced-desc`}>
        Limit the number of times a triggered notification is sent to the same user
      </div>
      <div className={`${classPrefix}__advanced-row`}>
        <span className={`${classPrefix}__advanced-label`}>Send maximum notification(s) of</span>
        <div className={`${classPrefix}__advanced-fields`}>
          <FlightNumberInput
            className={`${classPrefix}__advanced-input`}
            width={WIDTH.SMALL}
            placeholderText={'1'}
            maxValue={999}
            minValue={0}
            onChange={(e: any) => {
              handleInput(FieldKeys.MAX, e.target.value);
            }}
            onBlur={() => handleBlur(FieldKeys.MAX)}
            value={`${formData[FieldKeys.MAX].value}`}
            disabled={constraints?.max?.isReadOnly}
          />
        </div>
      </div>
      <div className={`${classPrefix}__advanced-row`}>
        <span className={`${classPrefix}__advanced-label`}>
          Don&apos;t send more than{' '}
          <span className={`${classPrefix}__advanced-label__highlight`}>{`${formData[FieldKeys.MAX].value}`}</span>{' '}
          notification{formData[FieldKeys.MAX].value > 1 ? 's' : ''} every
        </span>
        <div className={`${classPrefix}__advanced-fields`}>
          <FlightNumberInput
            className={`${classPrefix}__advanced-input`}
            width={WIDTH.SMALL}
            placeholderText={'1'}
            maxValue={999}
            minValue={0}
            onChange={(e: any) => {
              handleInput(FieldKeys.FREQ, e.target.value);
            }}
            onBlur={() => handleBlur(FieldKeys.FREQ)}
            value={formData.frequency.value.toString()}
            disabled={constraints?.cooldown?.isReadOnly}
          />
          <FlightSelect
            label=""
            options={PERIODS.slice(2)}
            hasLabelAnimation={true}
            handleOptionClick={(option: any) => {
              handleSelect(FieldKeys.FREQ_P, option);
            }}
            dropdownMaxHeight={'200px'}
            className={`${classPrefix}__advanced-dropdown`}
            width={WIDTH.MEDIUM}
            dropdownDirection={'bottom'}
            selected={formData.frequencyPeriod.value}
            disabled={constraints?.cooldown?.isReadOnly}
          />
        </div>
      </div>
      <div className={`${classPrefix}__advanced-row`}>
        <span className={`${classPrefix}__advanced-label`}> Total maximum notifications per user</span>
        <div className={`${classPrefix}__advanced-fields`}>
          <FlightToggleSwitch
            className={`${classPrefix}__advanced-switch`}
            checked={formData.limitEnabled.value}
            onChange={() => {
              handleToggleSwitch(FieldKeys.LIMIT_EN);
            }}
            disabled={constraints?.limit?.isReadOnly}
          />
          <FlightNumberInput
            className={`${classPrefix}__advanced-input`}
            placeholderText={'1'}
            width={WIDTH.MEDIUM}
            maxValue={999}
            minValue={0}
            disabled={constraints?.limit?.isReadOnly || !formData.limitEnabled.value}
            onChange={(e: any) => {
              handleInput(FieldKeys.LIMIT, e.target.value);
            }}
            onBlur={() => handleBlur(FieldKeys.LIMIT)}
            value={formData.limit.value.toString()}
          />
        </div>
      </div>
      <div className={`${classPrefix}__advanced-row`}>
        <span className={`${classPrefix}__advanced-label`}> Include user who remain in the target audience </span>
        <div className={`${classPrefix}__advanced-fields`}>
          <FlightToggleSwitch
            className={`${classPrefix}__advanced-switch`}
            checked={formData.remain.value}
            disabled={!ruleRefId}
            onChange={() => {
              handleToggleSwitch(FieldKeys.REMAIN);
            }}
          />
        </div>
      </div>
      <div className={`${classPrefix}__advanced-title`}> Notification Delay </div>
      <div className={`${classPrefix}__advanced-desc`}>
        Allows you to delay the notification delivery to your audience
      </div>
      <div className={`${classPrefix}__advanced-row`}>
        <span className={`${classPrefix}__advanced-label`}> Delay each notification by </span>
        <div className={`${classPrefix}__advanced-fields`}>
          <FlightNumberInput
            className={`${classPrefix}__advanced-input`}
            placeholderText={'0'}
            width={WIDTH.SMALL}
            maxValue={999}
            minValue={0}
            onChange={(e: any) => {
              handleInput(FieldKeys.DELAY, e.target.value);
            }}
            onBlur={() => handleBlur(FieldKeys.DELAY)}
            value={formData.delay.value.toString()}
            disabled={constraints?.delay?.isReadOnly}
          />
          <FlightSelect
            label=""
            options={PERIODS.slice(1)}
            hasLabelAnimation={true}
            handleOptionClick={(option: any) => {
              handleSelect(FieldKeys.DELAY_P, option);
            }}
            dropdownMaxHeight={'200px'}
            className={`${classPrefix}__advanced-dropdown`}
            width={WIDTH.MEDIUM}
            dropdownDirection={'bottom'}
            selected={formData.delayPeriod.value}
            disabled={constraints?.delay?.isReadOnly}
          />
        </div>
      </div>
    </>
  );
}
