import React from 'react';
import { IconProps } from '../../../pages/ZonesV2/Icons/icon.types';

function IconPublishChanges(props: IconProps) {
  const { customClassName = '', customStyle = {} } = props;

  return (
    <svg
      className={customClassName}
      style={customStyle}
      width="148"
      height="123"
      viewBox="0 0 148 123"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 427320766">
        <g id="No Results">
          <g id="search">
            <g id="documents">
              <path
                id="Fill 1"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M104.902 103.915H58.0082C54.7076 103.915 52.0059 101.205 52.0059 97.8935V31.0201C52.0059 27.7084 54.7076 25 58.0082 25H104.902C108.204 25 110.906 27.7084 110.906 31.0201V97.8935C110.906 101.205 108.204 103.915 104.902 103.915"
                fill="#B4B8C3"
              />
              <path
                id="Fill 3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.899 106.774H54.0051C50.7044 106.774 48.0027 104.065 48.0027 100.753V33.8795C48.0027 30.5678 50.7044 27.8594 54.0051 27.8594H100.899C104.201 27.8594 106.903 30.5678 106.903 33.8795V100.753C106.903 104.065 104.201 106.774 100.899 106.774"
                fill="#CDD0D7"
              />
              <path
                id="Fill 5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M97.0059 109.031H50.0148C46.7073 109.031 44 106.332 44 103.034V36.4376C44 33.1395 46.7073 30.4424 50.0148 30.4424H97.0059C100.315 30.4424 103.022 33.1395 103.022 36.4376V103.034C103.022 106.332 100.315 109.031 97.0059 109.031"
                fill="#D8DBEA"
              />
              <path
                id="Fill 8"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M97.0059 109.031H50.0148C46.7073 109.031 44 106.332 44 103.034V36.4376C44 33.1395 46.7073 30.4424 50.0148 30.4424H97.0059C100.315 30.4424 103.022 33.1395 103.022 36.4376V103.034C103.022 106.332 100.315 109.031 97.0059 109.031"
                fill="#F2F3F5"
              />
              <path
                id="Fill 11"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M89.0239 50.7328H54.3153C53.3241 50.7328 52.5127 49.9609 52.5127 49.0179C52.5127 48.0737 53.3241 47.3018 54.3153 47.3018H89.0239C90.0151 47.3018 90.8265 48.0737 90.8265 49.0179C90.8265 49.9609 90.0151 50.7328 89.0239 50.7328"
                fill="#CDD0D7"
              />
              <path
                id="Fill 13"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M89.0239 61.0268H54.3153C53.3241 61.0268 52.5127 60.2549 52.5127 59.3119C52.5127 58.3676 53.3241 57.5957 54.3153 57.5957H89.0239C90.0151 57.5957 90.8265 58.3676 90.8265 59.3119C90.8265 60.2549 90.0151 61.0268 89.0239 61.0268"
                fill="#CDD0D7"
              />
              <path
                id="Fill 15"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M89.0239 71.3188H54.3153C53.3241 71.3188 52.5127 70.5469 52.5127 69.6039C52.5127 68.6609 53.3241 67.8877 54.3153 67.8877H89.0239C90.0151 67.8877 90.8265 68.6609 90.8265 69.6039C90.8265 70.5469 90.0151 71.3188 89.0239 71.3188"
                fill="#CDD0D7"
              />
              <path
                id="Fill 17"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M89.0239 81.6127H54.3153C53.3241 81.6127 52.5127 80.8408 52.5127 79.8978C52.5127 78.9535 53.3241 78.1816 54.3153 78.1816H89.0239C90.0151 78.1816 90.8265 78.9535 90.8265 79.8978C90.8265 80.8408 90.0151 81.6127 89.0239 81.6127"
                fill="#CDD0D7"
              />
              <path
                id="Fill 19"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72.4326 91.9062H54.323C53.3275 91.9062 52.5127 91.1343 52.5127 90.1913C52.5127 89.247 53.3275 88.4751 54.323 88.4751H72.4326C73.4281 88.4751 74.2429 89.247 74.2429 90.1913C74.2429 91.1343 73.4281 91.9062 72.4326 91.9062"
                fill="#CDD0D7"
              />
            </g>
          </g>
        </g>
        <g id="Group 427319087" opacity="0.5">
          <rect id="Rectangle 5637" x="121" width="7" height="22" fill="url(#paint0_linear_4465_28539)" />
          <rect id="Rectangle 5638" x="128" y="27" width="7" height="22" fill="url(#paint1_linear_4465_28539)" />
          <rect
            id="Rectangle 5639"
            width="5"
            height="16"
            transform="matrix(1 0 0 -1 143 30)"
            fill="url(#paint2_linear_4465_28539)"
          />
        </g>
        <g id="Group 427319086" opacity="0.5">
          <rect
            id="Rectangle 5640"
            x="27"
            y="123"
            width="7"
            height="22"
            transform="rotate(-180 27 123)"
            fill="url(#paint3_linear_4465_28539)"
          />
          <rect
            id="Rectangle 5641"
            x="20"
            y="96"
            width="7"
            height="22"
            transform="rotate(-180 20 96)"
            fill="url(#paint4_linear_4465_28539)"
          />
          <rect
            id="Rectangle 5642"
            width="5"
            height="16"
            transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 5 93)"
            fill="url(#paint5_linear_4465_28539)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4465_28539"
          x1="124.5"
          y1="0"
          x2="124.5"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4CEFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4465_28539"
          x1="131.5"
          y1="27"
          x2="131.5"
          y2="49"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC6BB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint2_linear_4465_28539" x1="2.5" y1="16" x2="2.5" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CDF8EE" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4465_28539"
          x1="30.5"
          y1="145"
          x2="30.5"
          y2="123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9D9FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4465_28539"
          x1="23.5"
          y1="118"
          x2="23.5"
          y2="96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDF5AC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient id="paint5_linear_4465_28539" x1="2.5" y1="0" x2="2.5" y2="16" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC6BB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconPublishChanges;
