import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlightSelect } from '@flybits/design-system';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { getStepRuleSyntheticId } from 'store/helper';
import { saveContextualSchedulerAction } from 'store/rule/rule.action';
import { FullTime, Option, SelectOption } from 'types/common';
import { AbsoluteErrorReturnValue } from 'validators/ExperienceCanvas/rule.validator';
import { CLASSES } from '../classes';
import EndScheduleSection from '../EndScheduleSection/EndScheduleSection';
import EventSelectorSection from '../EventSelectorSection/EventSelectorSection';
import RepeatSection from '../RepeatSection/RepeatSection';
import '../ScheduleEditorBox.scss';

type TEventsScheduleProps = {
  stepIdx: number;
  dateTime: { start: FullTime; end: FullTime; timezone: Option | undefined };
  errors: AbsoluteErrorReturnValue;
  setDateTime: React.Dispatch<
    React.SetStateAction<{
      start: FullTime;
      end: FullTime;
      timezone: Option | undefined;
    }>
  >;
};

const repeatOrEndScheduleOptions: SelectOption[] = [
  { key: 'off', name: 'Off' },
  { key: 'repeat-schedule', name: 'Repeat schedule' },
  { key: 'end-schedule', name: 'End Schedule' },
];
const MAIN_CLASS = 'events-schedule';

const EventsSchedule: React.FC<TEventsScheduleProps> = ({ stepIdx, dateTime, errors, setDateTime }) => {
  const dispatch = useDispatch();

  const { startScheduler, endScheduler } = useSelector((state) => {
    const synthId = getStepRuleSyntheticId(stepIdx);
    return {
      startScheduler: state.te.rule.startSchedulerMap[synthId],
      endScheduler: state.te.rule.endSchedulerMap[synthId],
    };
  });

  const getRepeatOrEndScheduleInitialValue = () => {
    if (Object.keys(endScheduler?.predicates || {}).length > 0 || !!dateTime.end.date) {
      return repeatOrEndScheduleOptions[2];
    }
    if (startScheduler?.frequencyType && startScheduler.frequencyType !== 'once') return repeatOrEndScheduleOptions[1];
    return repeatOrEndScheduleOptions[0];
  };

  const [repeatOrEndSchedule, setRepeatOrEndSchedule] = useState<SelectOption>(getRepeatOrEndScheduleInitialValue);

  const handleRepeatOrEndScheduleChange = (option: SelectOption) => {
    if (option.key === 'off' || option.key === 'repeat-schedule') {
      dispatch({
        type: 'UPDATE_STEP',
        payload: {
          stepIdx,
          fields: {
            omitEnd: true,
          },
        },
      });
      dispatch(
        saveContextualSchedulerAction({
          stepIdx,
          type: 'end',
          modifier: '',
          predicates: null,
          target: 'now',
          logic: '',
        }),
      );
      dispatch({
        type: 'CLEAR_SCHEDULE',
        payload: {
          stepIdx,
          type: 'end',
        },
      });
      setDateTime((prevState) => ({
        ...prevState,
        end: {
          isActive: false,
          date: undefined,
          time: undefined,
        },
      }));
    }
    setRepeatOrEndSchedule(option);
  };

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.SECTION}>
        <EventSelectorSection stepIdx={stepIdx} startOrEnd="start" />
      </div>
      <div className={CLASSES.SECTION_SECONDARY}>
        <div className={CLASSES.FIELD}>
          Turn on Repeat Schedule/End Schedule
          <FlightSelect
            options={repeatOrEndScheduleOptions}
            handleOptionClick={handleRepeatOrEndScheduleChange}
            selected={repeatOrEndSchedule || repeatOrEndScheduleOptions[0]}
          />
        </div>
      </div>
      <RepeatSection
        stepIdx={stepIdx}
        startScheduler={startScheduler}
        endScheduler={endScheduler}
        hasEndDate={false}
        isEnabled={repeatOrEndSchedule?.key === 'repeat-schedule'}
      />
      <EndScheduleSection
        stepIdx={stepIdx}
        dateTime={dateTime}
        errors={errors}
        isEnabled={repeatOrEndSchedule?.key === 'end-schedule'}
        endScheduler={endScheduler}
        setDateTime={setDateTime}
      />
    </div>
  );
};

export default EventsSchedule;
