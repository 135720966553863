import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FlightSelect, FlightTable, FlightTextInput, getIcon } from '@flybits/design-system';
import { formatBytes } from 'helpers/common.helper';
import { useAppSelector } from 'hooks/reduxHooks';
import useDebounce from 'hooks/useDebounce';
import { FILE_STATUSES } from 'components/ExperienceCanvas/constants';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import FileAPI from 'services/api/file.api';
import { PaginationResponse } from 'interface/shared/api.interface';
import { CtxDataFile } from 'interface/file/file.interface';
import { FILE_STATUS } from 'pages/ExperienceCanvas/types';
import InfoIcon from 'pages/ExperienceCanvas/assets/icons/InfoIcon';
import ProcessIcon from 'pages/ExperienceCanvas/assets/icons/ProcessIcon';
import { ReactComponent as EmptyImage } from 'pages/ExperienceCanvas/assets/images/no-files-to-show.svg';
import './FileUploadHistory.scss';
import ProcessContextFileButton from 'components/ExperienceCanvas/ProcessContextFileButton/ProcessContextFileButton';

const MAIN_CLASS = 'file-upload-history';
const CLASSES = {
  FILTER_FIELDS: `${MAIN_CLASS}__filter-fields`,
  TABLE_WRAPPER: `${MAIN_CLASS}__table-wrapper`,
  STATUS: `${MAIN_CLASS}__status`,
  EMPTY_STATE: `${MAIN_CLASS}__empty-state`,
  ACTION_BUTTON_WRAPPER: `${MAIN_CLASS}__action-button-wrapper`,
  ACTION_BUTTON_WRAPPER_DISABLED: `${MAIN_CLASS}__action-button-wrapper ${MAIN_CLASS}__action-button-wrapper--is-disabled`,
  ACTION_BUTTON_PROCESS_NOW: `${MAIN_CLASS}__action-button ${MAIN_CLASS}__action-button--is-process-now`,
  ACTION_BUTTON_ERROR: `${MAIN_CLASS}__action-button ${MAIN_CLASS}__action-button--is-error`,
  ACTION_HAS_ERROR: `${MAIN_CLASS}__action-button ${MAIN_CLASS}__action--has-error`,
  ERROR_CONTAINER: `${MAIN_CLASS}__error-container`,
};

const FileUploadHistory: React.FC = () => {
  const fileAPI = useMemo(() => new FileAPI(), []);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [busyFiles, setBusyFiles] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<{ key: string; name: string }>({ key: '', name: 'All' });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const queryClient = useQueryClient();
  const filesUUID = useAppSelector((state) => state.te.journey.metadata?.filesUUID || '');
  const dispatch = useDispatch();

  const {
    isLoading,
    data: res,
    isFetching,
  } = useQuery<PaginationResponse<CtxDataFile>>({
    queryKey: ['ctx-data-file', filesUUID, currentPage, debouncedSearchTerm, statusFilter?.key, perPage],
    queryFn: () =>
      fileAPI.getAllCtxDataFiles({
        offset: perPage * (currentPage - 1),
        limit: perPage,
        tag: filesUUID,
        search: debouncedSearchTerm,
        status: statusFilter?.key,
      }),
    keepPreviousData: true,
    enabled: !!filesUUID,
  });

  const { data: processingRes } = useQuery<PaginationResponse<CtxDataFile>>({
    queryKey: ['ctx-data-file', filesUUID, FILE_STATUS.PROCESSING],
    queryFn: async () =>
      await fileAPI.getAllCtxDataFiles({
        offset: 0,
        limit: 1,
        tag: filesUUID,
        status: FILE_STATUS.PROCESSING,
      }),
    keepPreviousData: true,
    enabled: !!filesUUID,
  });
  const isFileProcessing = processingRes?.data?.length;

  const handleSearchTermChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setSearchTerm(value);
  };

  const handleRowsPerPageChange = (value: number) => {
    setPerPage(value);
  };

  const handleStatusFilter = (option: { key: string; name: string }) => {
    setStatusFilter(option);
  };

  const handleProcessClick = async (fileId: string) => {
    setBusyFiles((prevState) => [...prevState, fileId]);
    try {
      dispatch(
        showSnackbar({
          content: 'Your file will be processed shortly',
          type: 'success',
        }),
      );
      await fileAPI.processFileById(fileId);
      await queryClient.invalidateQueries(['ctx-data-file']);
    } catch {
      dispatch(
        showSnackbar({
          content: 'An error occurred while processing your file',
          type: 'error',
        }),
      );
    } finally {
      setBusyFiles((prevState) => prevState.filter((id) => id !== fileId));
    }
  };

  // reset to page 1 when search term or rows per page changes
  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearchTerm, perPage]);

  const renderStatusColumn = (file: CtxDataFile) => {
    const { status, id } = file;
    const column = FILE_STATUSES[status] || FILE_STATUSES.UNKNOWN;
    const { Icon } = column;

    if (status === FILE_STATUS.UPLOADED && !isFileProcessing) {
      return <ProcessContextFileButton fileId={id} size="large" />;
    }

    if (status === FILE_STATUS.PROCESSING_FAILED) {
      return (
        <div className={CLASSES.ACTION_HAS_ERROR}>
          {!isFileProcessing ? (
            <button
              type="button"
              className={CLASSES.ACTION_BUTTON_ERROR}
              onClick={() => handleProcessClick(id)}
              disabled={busyFiles.includes(id)}
            >
              <ProcessIcon />
              Process Again
            </button>
          ) : (
            <div className={`${CLASSES.STATUS}`} style={{ color: column.fgColor }}>
              <Icon />
              <span>{column.text}</span>
            </div>
          )}

          <input type="checkbox" id={`checkbox-${file.id}`} />
          <label htmlFor={`checkbox-${file.id}`}>{getIcon('baselineKeyboardArrowDown', {})}</label>
          <div className={CLASSES.ERROR_CONTAINER}>
            <p>
              <InfoIcon />
              {FILE_STATUSES[file.status].text} - {moment(file.updatedAt * 1000).fromNow()}
            </p>
            <p>{file.statusMessage}</p>
          </div>
        </div>
      );
    }

    return (
      <div className={`${CLASSES.STATUS}`} style={{ color: column.fgColor }}>
        <Icon />
        <span>{column.text}</span>
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS}>
      <h2>Upload History</h2>
      {((res && res.data.length > 0) || debouncedSearchTerm || isFetching || !!statusFilter) && (
        <div className={CLASSES.FILTER_FIELDS}>
          <FlightTextInput
            label="Search by name"
            iconInput="search"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <FlightSelect
            options={[
              {
                key: '',
                name: 'All',
              },
              {
                key: 'PROCESSING_SUCCESS',
                name: 'Processed',
              },
              {
                key: 'PROCESSING',
                name: 'Processing',
              },
              {
                key: 'PROCESSING_FAILED',
                name: 'Processing Failed',
              },
              {
                key: 'UPLOADED',
                name: 'Ready to process',
              },
              {
                key: 'UPLOADING_FAILED',
                name: 'Uploading Failed',
              },
            ]}
            label="Status"
            selected={statusFilter}
            handleOptionClick={handleStatusFilter}
          />
        </div>
      )}
      <div className={CLASSES.TABLE_WRAPPER}>
        <FlightTable
          tableHeaders={[
            {
              name: 'File Name',
              key: 'fileName',
              isVisible: true,
            },
            {
              name: 'Size',
              key: 'size',
              isVisible: true,
            },
            {
              name: 'Uploaded On',
              key: 'uploadedOn',
              isVisible: true,
            },
            {
              name: 'Status',
              key: 'status',
              isVisible: true,
            },
          ]}
          tableData={res?.data
            .map((file) => ({
              key: file.fileID,
              fileName: <div>{file.name}</div>,
              size: <div>{formatBytes(file.size)}</div>,
              uploadedOn: (
                <div title={moment(file.createdAt * 1000).format('MMMM, DD, YYYY, hh:mm:ss A')}>
                  {moment(file.createdAt * 1000).fromNow()}
                </div>
              ),
              status: renderStatusColumn(file),
            }))
            .flat()}
          isLoading={isLoading}
          paginationProps={{
            totalPageNumber: res ? Math.ceil(res.pagination.totalRecords / res.pagination.limit) : 0,
            currentPageNumber: currentPage,
            currentRowsPerPage: perPage,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handlePageChange: (page: any) => setCurrentPage(page),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            handleRowsPerPageChange: (rowsPerPage: any) => handleRowsPerPageChange(rowsPerPage),
          }}
          isShowHeader={res && res.data.length > 0}
          hasPaginationBeforeTable={false}
          hasPaginationAfterTable={res && res.data.length > 0}
          emptyState={
            <div className={CLASSES.EMPTY_STATE}>
              <EmptyImage />
              {statusFilter.key === '' && !searchTerm ? (
                <>
                  <strong>No context data file yet</strong>
                  <p>
                    You can import more than one CSV file at once.
                    <br />
                    Let&apos;s start uploading the file(s).
                  </p>
                </>
              ) : (
                <>
                  <strong>No results found</strong>
                  <p>You can try again with a different search terms.</p>
                </>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default FileUploadHistory;
