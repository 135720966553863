import React from 'react';
import { TIconProps } from '../types';

function InAppContentIcon({ fill = '#000000', className = '' }: TIconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3616_6229)">
        <path
          d="M15.5263 2.09961H4.78947C3.80526 2.09961 3 2.91779 3 3.91779V16.6451H4.78947V3.91779H15.5263V2.09961ZM18.2105 5.73597H8.36842C7.38421 5.73597 6.57895 6.55415 6.57895 7.55415V20.2814C6.57895 21.2814 7.38421 22.0996 8.36842 22.0996H18.2105C19.1947 22.0996 20 21.2814 20 20.2814V7.55415C20 6.55415 19.1947 5.73597 18.2105 5.73597ZM18.2105 20.2814H8.36842V7.55415H18.2105V20.2814Z"
          fill={fill}
        />
        <path d="M17 13.7663L12 17.0996V11.0996L17 13.7663Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_3616_6229">
          <rect width="24" height="24" fill="white" transform="translate(0 0.0996094)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InAppContentIcon;
