// Remove this when the new canvas ui replaces the existing ui

import ReactGA from 'react-ga';
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactGA.initialize(process.env.REACT_APP_GA_ID!);

/**
 * sends the Google Analytics event data
 * @param props : object{type, category, action, label, value}
 */
export function trackEvent({ category = '', action = '', label = '', value = 0 }) {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
}
