import { Attribute } from 'interface/attribute.interface';

export enum SMART_TARGET_STATUS {
  INACTIVE = 'inactive',
  IN_TRAINING = 'in-training',
  READY_ACTIVATE = 'ready-activate',
  ACTIVE = 'active',
}

export type SmartTargetStatusType =
  | SMART_TARGET_STATUS.INACTIVE
  | SMART_TARGET_STATUS.IN_TRAINING
  | SMART_TARGET_STATUS.READY_ACTIVATE
  | SMART_TARGET_STATUS.ACTIVE
  | '';

export interface OptimizationPoint {
  pointX: number;
  pointY: number;
}

interface ModelMetrics {
  tenantId: string;
  journeyId: string;
  optimizationGraph: OptimizationPoint[] | null;
  featureMetrics: Attribute[] | null;
  updatedAt: number;
  isValidation: boolean;
  createdByJobName: string;
  trainingJobName: string;
  modelVersion: number;
  modelFile: string;
  dataFile: string;
  metricsFile: string;
}

export interface SmartTargetingMetrics {
  activeModelMetrics: ModelMetrics;
  candidateModelMetrics: ModelMetrics;
}

interface SmartTargetingModelStatus {
  status: string;
  modelVersion: number;
  updatedAt: number;
}

export interface SmartTargetingMetadata {
  tenantId: string;
  journeyId: string;
  journeyName: string;
  engagementRateBasedOnRules: number;
  populationReachBasedOnRules: number;
  scheduleId: string;
  prodModelStatus: SmartTargetingModelStatus;
  statingModelStatus: SmartTargetingModelStatus;
  devModelStatus: SmartTargetingModelStatus;
  updatedAt: number;
}
