import { AuthState, AuthActionType } from 'store/auth/auth.type';

const initialState: AuthState = {
  user: undefined,
  token: undefined,
  authenticated: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    case AuthActionType.AUTHORIZE_USER:
      return {
        ...state,
        ...action.payload,
        authenticated: true,
      };
    case AuthActionType.UNAUTHORIZE_USER:
      return {
        ...state,
        user: undefined,
        token: undefined,
        authenticated: false,
      };
    default:
      return {
        ...state,
      };
  }
};
